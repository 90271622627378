import { useNavigate } from 'react-router-dom';
import { getRoutes } from '../../../utils';
import { Box } from '../../../components';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_JOB_TYPES } from '../../../api';
import useEmployerOnboarding from '../../../hooks/useEmployerOnboarding';
import FlexRow from '../../../components/shared/FlexRow';
import { Button, Spinner } from '../../../components/shared';
import ResourceCard from '../../../components/employer/onboarding/ResourceCard';
import DocumentCard from '../../../components/employer/onboarding/DocumentCard';
import {
  Controller,
  FieldValues,
  useForm,
} from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import isEmpty from 'lodash/isEmpty';
import {
  IOnboardingDocument,
  IOnboardingResource,
} from '../../../api/types/queries/onboarding.types';

const OnboardingNew = () => {
  const [pickedResources, setPickedResources] = useState<
    IOnboardingResource[]
  >([]);

  const [pickedDocuments, setPickedDocuments] = useState<
    IOnboardingDocument[]
  >([]);
  const navigate = useNavigate();
  const routes = getRoutes();

  const {
    setValue,
    handleSubmit,
    register,
    reset,
    formState,
    control,
  } = useForm({
    defaultValues: {
      id: null,
      name: '',
      jobTypeId: null,
    },
  });
  const { errors } = formState;

  const [
    getJobTypes,
    {
      data: getJobTypesData,
      loading: getJobTypesLoading,
      error: getJobTypesError,
    },
  ] = useLazyQuery(GET_JOB_TYPES, {
    fetchPolicy: 'cache-first',
  });

  const { resources, documents } = useEmployerOnboarding();

  useEffect(() => {
    getJobTypes();
    resources.all.get();
    documents.all.get();
  }, []);

  const handleResourceRemove = (id: string) => {
    console.log('resource id to remove');
    console.log(id);
    setPickedResources(
      [...pickedResources].filter((res) => res.id !== id),
    );
  };
  const handleDocumentRemove = (id: number) => {
    setPickedDocuments(
      [...pickedDocuments].filter((doc) => doc.id !== id),
    );
  };

  const handleSave = async (formValues: FieldValues) => {
    console.log('formValues');
    console.log(formValues);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSave)}>
        <Typography variant={'h2'}>New Onboarding</Typography>
        <FormLabel>
          <Typography variant={'h3'}>Name</Typography>
          <TextField
            {...register('name', {
              required: 'Name is required',
            })}
            error={errors.name != null}
            helperText={errors.name?.message}
          />
        </FormLabel>
        <FormLabel>
          <Typography variant={'h3'}>Job Type:</Typography>
          <Controller
            name="jobTypeId"
            control={control}
            render={({ field }) => (
              <Select
                label="Job Type"
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={errors.jobTypeId != null}
                sx={{ width: 100 }}
              >
                {getJobTypesData?.getJobsTypes.map((jobType) => (
                  <MenuItem key={jobType.id} value={jobType.id}>
                    {jobType.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormLabel>
        <Box>
          <FlexRow justifyContent={'space-between!important'}>
            <Typography variant="h3">Resources</Typography>
            <FlexRow>
              <Button
                variant={'outlined-primary'}
                onClick={() =>
                  navigate(
                    routes.employer.onboarding.resources.create,
                  )
                }
              >
                New
              </Button>
              <Button
                variant={'filled-primary'}
                onClick={() =>
                  navigate(
                    routes.employer.onboarding.resources.list,
                  )
                }
              >
                All Resources
              </Button>
            </FlexRow>
          </FlexRow>
          <Box>
            {isEmpty(pickedResources) ? (
              <Typography>
                No resources assigned yet, create a new one or
                use existing ones below by clicking them to add
                to this onboarding
              </Typography>
            ) : (
              pickedResources.map((resource) => (
                <ResourceCard
                  variant={'outlined'}
                  {...resource}
                  handleDelete={handleResourceRemove}
                />
              ))
            )}
          </Box>
          <Box>
            <Typography>Common Resources</Typography>
            {!resources.all.data || resources.all.loading ? (
              <Spinner />
            ) : (
              <FlexRow>
                {resources.all.data.onboardingResources.results.map(
                  (resource) => (
                    <ResourceCard
                      {...resource}
                      handleClick={(resource) =>
                        setPickedResources([
                          ...pickedResources,
                          resource,
                        ])
                      }
                    />
                  ),
                )}
              </FlexRow>
            )}
          </Box>
        </Box>
        <Box>
          <FlexRow justifyContent={'space-between!important'}>
            <Typography variant={'h3'}>Documents</Typography>
            <FlexRow>
              <Button
                variant={'outlined-primary'}
                onClick={() =>
                  navigate(
                    routes.employer.onboarding.documents.create,
                  )
                }
              >
                New
              </Button>
              <Button
                variant={'filled-primary'}
                onClick={() =>
                  navigate(
                    routes.employer.onboarding.documents.list,
                  )
                }
              >
                All Documents
              </Button>
            </FlexRow>
          </FlexRow>
          <FlexRow>
            {isEmpty(pickedDocuments) ? (
              <Typography>
                No documents assigned yet, create new ones or use
                existing ones below by click them to add to this
                onboarding
              </Typography>
            ) : (
              pickedDocuments.map((document) => (
                <DocumentCard
                  variant={'outlined'}
                  {...document}
                  handleDelete={handleDocumentRemove}
                />
              ))
            )}
          </FlexRow>
          <Box>
            <Typography>Common Documents</Typography>
            {!documents.all.data || documents.all.loading ? (
              <Spinner />
            ) : (
              <FlexRow>
                {documents.all.data.onboardingDocuments.results.map(
                  (document) => (
                    <DocumentCard
                      {...document}
                      handleClick={(document) =>
                        setPickedDocuments([
                          ...pickedDocuments,
                          document,
                        ])
                      }
                    />
                  ),
                )}
              </FlexRow>
            )}
          </Box>
        </Box>
        <Box>
          <FlexRow>
            <Typography variant={'h3'}>Assignees</Typography>
          </FlexRow>
          <Box>
            <Typography>No employees assigned yet</Typography>
          </Box>
          <Box>
            <Button variant={'filled-primary'}>Assign</Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default OnboardingNew;
