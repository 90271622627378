import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import { nanoid } from 'nanoid/non-secure';
import {
  fileNameFromUrl,
  getProfileId,
  getExperiencePeriodStr,
  getEducationPeriodStr,
  getLocaleTimeZone,
  sortDateRanges
} from 'utils';
import { useMediaQueryMatches, useEmployerProfileQuery, useEmployeeTrainings } from 'hooks';
import { Box, Dialog, styled } from 'components';
import { useForm } from 'components/form';
import { Button, IconButton, Input, Spinner, Video } from 'components/shared';
import {
  MdAdd,
  MdClose,
  MdCheck,
  MdEdit,
  MdGetApp,
  MdPermContactCalendar,
  MdSave,
  MdStarBorder,
  MdTrendingUp
} from 'components/icons';
import defaultImg from 'assets/img/employee_default.png';
import {
  client,
  GET_EMPLOYEE_PROFILE_INFO,
  GET_EMPLOYEE_NOTES,
  GET_EMPLOYEE_NOTES_TO_JOB,
  GET_HIRED_BY_EMPLOYEE,
  GET_USER_PROFILE,
  GET_USER_PROFILE_VIDEO,
  GET_INTERVIEW_SCHEDULE_FOR_JOB,
  SET_EMPLOYEE_NOTES,
  ADD_CERTIFICATE,
} from 'api';
import styles from 'styles/dialogs/ApplicantProfileDialog';
import ConfirmationDialog from '../ConfirmationDialog';
import CourseItem from './CourseItem';

const StyledRoot = styled(Dialog)(styles);

const INTERVIEW_FORMAT = 'MMMM dd, yyyy @ hh:mm a';
const APPROVED_STATUS = 'approved';
const REJECTED_STATUS = 'rejected';

function getCandidateActionName(webUrl) {
  return {
    reject: { title: webUrl ? 'Remove' : 'Reject' },
    accept: { title: webUrl ? 'Accept' : 'Hire' },
    star: { title: webUrl ? 'Star' : 'Save' }
  };
}

const DEFAULT_PROFILE = {
  createdAt: '',
  education: [],
  email: '',
  experience: [],
  // gender: '',
  imageUrl: '',
  location: '',
  motto: '',
  name: '',
  notes: '',
  phone: '',
  skills: '',
  resumeUrl: '',
  videoUrl: '',
  zip: ''
};

const ApplicantProfileDialog = (props) => {
  const {
    approveStatus,
    id,
    jobId,
    jobLink,
    isOpen,
    isQhMatch,
    isStarred,
    onAccept,
    onReject,
    onStar,
    onClose,
    withActions,
    withEmployerCourses,
    ...dialogProps
  } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const { profile } = useEmployerProfileQuery();
  const { timeZone = getLocaleTimeZone(), restrictions } = profile || {};
  const allowEmployeeActions = restrictions?.allowEmployeeActions || false;

  const actionNames = getCandidateActionName(jobLink);

  const [formattedInterviewDate, setFormattedInterviewDate] = useState('');
  const [pdfLink, setPdfLink] = useState('');
  const [isNotesEditMode, setIsNotesEditMode] = useState(false);
  const [editableCourses, setEditableCourses] = useState([]);
  const [courseDeleteAlertId, setCourseDeleteAlertId] = useState(null);
  const [listOfReasons, setListOfReasons] = useState([]);

  const { attrs, $, set } = useForm({
    initial: {
      employeeNotes: '',
      prevNotes: '',
      notes: ''
    }
    // validations: {}
  });

  const { fetchTrainings, editTraining, deleteTraining, trainings, setTrainings } =
    useEmployeeTrainings();

  const [fetchProfile, { data: profileData, loading = true }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  const [fetchReasons, { data: reasonsData }] = useLazyQuery(
    GET_HIRED_BY_EMPLOYEE,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );

  const [fetchJobInterviews, { data: jobInterviewsData }] = useLazyQuery(
    GET_INTERVIEW_SCHEDULE_FOR_JOB,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );

  const [fetchPDFLink] = useMutation(GET_EMPLOYEE_PROFILE_INFO, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data?.downloadEmployeeProfileInfo?.analytic) {
        setPdfLink(`${process.env.URI_BASE}/${data.downloadEmployeeProfileInfo.analytic}`);
      }
    }
  });
  const [getEmployeeNotes, { loading: notesLoading = true }] = useMutation(GET_EMPLOYEE_NOTES, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      const notes = data?.getEmployeeNotes?.notes || '';
      set({ notes, prevNotes: notes });
    }
  });
  const [getEmployeeNotesToJob] = useMutation(
    GET_EMPLOYEE_NOTES_TO_JOB,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onCompleted: (data) => {
        const notes = data?.getEmployeeNotesToJob?.notes || '';
        set({ employeeNotes: notes });
      }
    }
  );
  const [postEmployeeNotes, { loading: notesPostLoading = false }] = useMutation(
    SET_EMPLOYEE_NOTES,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onCompleted: (data) => {
        const notes = data?.setEmployeeNotes?.notes || attrs.employeeNotes || '';
        set({ notes, prevNotes: notes });
      }
    }
  );

  const [addCertificate] = useMutation(ADD_CERTIFICATE);

  const {
    createdAt,
    education,
    email,
    experience,
    imageUrl,
    motto,
    name,
    phone,
    skills,
    profile_id: candidateProfId,
    resumeUrl,
    videoUrl,
    zip
  } = profileData?.employeeProfile || DEFAULT_PROFILE;

  useEffect(() => {
    setListOfReasons(reasonsData || []);
  }, [reasonsData]);

  const RenderListOfReasons = () => {
    const hiredData = listOfReasons?.getHiredByEmployee;
    if (!hiredData) return null;
    const myReasons = hiredData?.hiredReasons;
    const isHired = hiredData?.hired;
    const reasonsText = isHired ? 'Hired Reasons' : 'Rejected Reasons';
    return (
      <div className="rejectedReasonsWrapper">
        <div className={isHired ? 'hiredReasonsTitle' : 'rejectReasonsTitle'}>{reasonsText}</div>
        <ul className="rejectReasonList">
          {myReasons.map((reason) => <li key={reason.id}>{reason.reason}</li>)}
        </ul>
      </div>
    );
  };

  const educationYears = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(sortDateRanges(education), ({ __typename, ...rest }) => ({
        ...rest,
        startDate: rest.startDate ? `${new Date(rest.startDate).getFullYear()}` : '',
        endDate: rest.endDate ? `${new Date(rest.endDate).getFullYear()}` : ''
      })),
    [JSON.stringify(education)]
  );

  const sortedExperience = useMemo(() => sortDateRanges(experience), [JSON.stringify(experience)]);

  const getAppliedDate = (createdAtParam) => {
    if (createdAtParam && approveStatus !== REJECTED_STATUS && !isQhMatch) {
      const date = parseInt(createdAtParam);
      const appliedToday = isToday(date);
      const appliedYesterday = isYesterday(date);
      if (appliedToday) return 'Applied today';
      if (appliedYesterday) return 'Applied yesterday';
      return format(date, "'Applied' MMM dd");
    }
    return '';
  };

  const getVideo = useCallback(async () => {
    const res = await client.query({
      query: GET_USER_PROFILE_VIDEO,
      variables: {
        userId: parseInt(id)
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    return res?.data?.fetchProfile?.videoUrl;
  }, [id]);

  const changeNotes = (e, { name: fieldName }) => {
    set(fieldName, e.target.value);
  };

  const manageEmployerNotesState = async () => {
    if (!isNotesEditMode) {
      setIsNotesEditMode(true);
    } else if (attrs.prevNotes !== attrs.notes) {
      const profileId = localStorage.getItem('profileId');

      if (candidateProfId && profileId) {
        await postEmployeeNotes({
          variables: {
            employeeProfileId: Number(candidateProfId),
            employerProfileId: Number(profileId),
            notes: attrs.notes
          }
        });
      }
      setIsNotesEditMode(false);
    } else {
      setIsNotesEditMode(false);
    }
  };

  const RenderSkillsList = () => {
    if (!skills) return null;
    //order the array by name length so display wraps inline objects visually better
    const orderedSkillsList = skills.sort((a, b) => b.name.length - a.name.length);
    return (
      <div className="mainText">
        {orderedSkillsList.map((skill) => (
            <span className="skillChip" id={skill.skill_id}>
              {skill.name}
            </span>
          ))}
      </div>
    );
  };

  useEffect(() => {
    if (isOpen) {
      setIsNotesEditMode(false);
      setFormattedInterviewDate('');
      setPdfLink('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (id) {
      fetchProfile({ variables: { userId: parseInt(id) } });
      fetchPDFLink({ variables: { user_id: parseInt(id) } });
    }
  }, [id]);

  useEffect(() => {
    const profileId = getProfileId();

    if (candidateProfId && profileId) {
      if (withEmployerCourses) {
        fetchTrainings(Number(candidateProfId), Number(profileId));
      }

      getEmployeeNotes({
        variables: {
          employeeProfileId: Number(candidateProfId),
          employerProfileId: Number(profileId)
        }
      });
    }

    if (candidateProfId && jobId) {
      fetchJobInterviews({
        variables: {
          employeeProfileId: Number(candidateProfId),
          jobId: Number(jobId)
        }
      });

      getEmployeeNotesToJob({
        variables: {
          employeeProfileId: Number(candidateProfId),
          jobId: Number(jobId)
        }
      });

      fetchReasons({
        variables: {
          employeeId: Number(parseInt(id)),
          jobId: Number(jobId)
        }
      });
    }
  }, [candidateProfId]);

  useEffect(() => {
    if (jobInterviewsData?.getScheduleTimesForJob) {
      const candidateInterviews = jobInterviewsData.getScheduleTimesForJob.filter(
        (o) => o.employeeProfileId === Number(candidateProfId)
      );

      if (candidateInterviews[0]) {
        const { interviewDate } = candidateInterviews[0];
        const zonedDate = utcToZonedTime(interviewDate, timeZone);
        setFormattedInterviewDate(format(zonedDate, INTERVIEW_FORMAT));
      }
    }
  }, [JSON.stringify(jobInterviewsData), timeZone]);

  const addCourse = () => setEditableCourses((prev) => [{ id: nanoid() }, ...prev]);

  const handleAssignFinish = (courseId, courseData, isEdit) => {
    const editableCoursesArr = [...editableCourses].filter((obj) => obj.id !== courseId);
    setEditableCourses(editableCoursesArr);

    if (isEdit) {
      const idx = findIndex(trainings, { id: courseId });

      if (idx !== -1) {
        setTrainings((prev) => {
          const trainingsArr = [...prev];
          trainingsArr[idx] = { ...courseData };
          return trainingsArr;
        });
      }
    } else {
      const trainingsArr = [...trainings, courseData];
      setTrainings(trainingsArr);
    }
  };

  const confirmCourseClose = async (courseId) => {
    if (courseId) {
      await deleteTraining(courseId);
      setCourseDeleteAlertId(null);
    }
  };

  const handleCourseClose = (courseId) => {
    setCourseDeleteAlertId(courseId);
  };

  const handleCourseComplete = async (courseId, courseData) => {
    try {
      const training = await editTraining({ ...courseData, complete: true });

      if (training) {
        const pid = getProfileId();

        if (pid && candidateProfId) {
          addCertificate({
            variables: {
              name: courseData.name,
              verified: true,
              complete: true,
              taken: true,
              completionDate: format(new Date(), 'yyyy-MM-dd'),
              validatedByEmployerProfileId: Number(pid),
              employeeProfileId: Number(candidateProfId)
            }
          });
        } else {
          throw new Error('failed creating certificate');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderEmployerCourses = () => {
    const pid = Number(getProfileId());
    const numCandidatePID = Number(candidateProfId);

    return (
      <Box mb="20px">
        <Box mb="10px" display="flex" alignItems="center" justifyContent="space-between">
          <div className="secondaryText">Assigned Courses</div>
          <IconButton
            aria-label="add course"
            variant="filled-primary"
            className="addCourseBtn"
            onClick={addCourse}
          >
            <MdAdd />
          </IconButton>
        </Box>
        <ol className="courseList">
          {map(trainings, ({ name: courseName, id: courseId, complete }, i) => (
            <li key={`course__${courseId}`} className="course__item">
              <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <span className="course__name">{courseName}</span>
                {complete ? (
                  <Box
                    component="span"
                    ml="10px"
                    display="flex"
                    alignItems="center"
                    color="success.main"
                  >
                    <MdCheck fontSize="inherit" color="inherit" />
                    Complete
                  </Box>
                ) : (
                  <Box component="span" ml="10px">
                    <IconButton
                      aria-label="close course"
                      className="course__closeBtn"
                      onClick={() => handleCourseClose(courseId)}
                    >
                      <MdClose fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="finish course"
                      className="course__completeBtn"
                      onClick={() => handleCourseComplete(courseId, trainings[i])}
                    >
                      <MdCheck fontSize="inherit" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </li>
          ))}
        </ol>
        {map(editableCourses, ({ id: courseId, initial = {}, isEdit = false }) => (
          <CourseItem
            key={`courseEdit__${courseId}`}
            id={courseId}
            initial={initial}
            isEdit={isEdit}
            employeeProfileId={numCandidatePID}
            employerProfileId={pid}
            afterAssign={handleAssignFinish}
            className="courseContainer"
          />
        ))}

        <ConfirmationDialog
          isOpen={!!courseDeleteAlertId}
          title="Delete course?"
          confirmBtnName="Delete"
          onConfirm={() => confirmCourseClose(courseDeleteAlertId)}
          onCancel={() => setCourseDeleteAlertId(null)}
          onClose={() => setCourseDeleteAlertId(null)}
        />
      </Box>
    );
  };

  const renderNotesIcon = () => {
    if (notesPostLoading || notesLoading) return <Spinner size={20} />;
    if (isNotesEditMode) return <MdSave />;
    return <MdEdit />;
  };

  return (
    <StyledRoot
      open={isOpen}
      fullWidth
      classes={{ paper: 'paper', container: 'container' }}
      BackdropProps={{
        classes: { root: 'backdrop' }
      }}
      scroll="paper"
      onClose={onClose}
      {...dialogProps}
    >
      {loading && (
        <div className="loaderOverlay">
          <Spinner />
        </div>
      )}
      <Box width="100%">
        <div
          style={{ backgroundImage: `url("${imageUrl || defaultImg}")` }}
          className="imagePreview"
        >
          {!isDesktop && (
            <IconButton
              aria-label="close"
              color="primary"
              sx={{ position: 'absolute', zIndex: 1 }}
              onClick={onClose}
              testID="applicant-profile-modal-close-button"
            >
              <MdClose style={{ color: '#fff' }} />
            </IconButton>
          )}
          <div className="imagePreview__overlay" />
        </div>
        <div className="dialogContent">
          {isQhMatch && (
            <Box mb="16px" display="flex" alignItems="center">
              <div className="trendIcon">
                <MdTrendingUp fontSize="inherit" color="inherit" />
              </div>
              <Box ml="12px" className="secondaryBoldText">
                Match
              </Box>
            </Box>
          )}

          <Box mb="16px">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box component="h2" mb="8px" className="userName">
                {name}
              </Box>
              {!!pdfLink && (
                <Button
                  component="a"
                  href={pdfLink}
                  target="_blank"
                  download
                  variant="filled-primary"
                  startIcon={<MdGetApp fontSize="inherit" />}
                  className="pdfLink"
                  testID="pdf-button"
                >
                  <span>PDF</span>
                </Button>
              )}
            </div>
            <div className="secondaryBoldText">
              {/*{location}*/} {/* @todo: add location */}
              {/*{' '}*/}
              {/*&bull;*/} {getAppliedDate(createdAt)}
            </div>

            <div className="secondaryBoldText" style={{ marginTop: 12 }}>
              <RenderListOfReasons />
            </div>
          </Box>

          {formattedInterviewDate && (
            <div className="interview">
              <span>
                <MdPermContactCalendar className="interviewIcon" fontSize="inherit" />
                {`Interview Time: ${formattedInterviewDate}`}
              </span>
            </div>
          )}

          {withEmployerCourses && renderEmployerCourses()}

          <Box mb="20px">
            <Box mb="12px" className="secondaryText">
              Intro Video
            </Box>
            {videoUrl && (
              <Video
                initUrl={videoUrl}
                variant={isDesktop ? 'modal' : 'default'}
                refetch={getVideo}
                className="videoPreview"
              />
            )}
          </Box>
          <Box mb="20px">
            <Box mb="6px" className="secondaryText">
              Motto
            </Box>
            <div className="mainText">{motto}</div>
          </Box>
          <Box mb="20px">
            <Box mb="6px" className="secondaryText">
              Skills
            </Box>
            <RenderSkillsList />
          </Box>
          <Box mb="20px">
            <Box mb="6px" className="secondaryText">
              Education
            </Box>
            {educationYears.length > 0 &&
              map(educationYears, (o, i) => (
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  key={`educationItem__${i}`}
                >
                  <Box maxWidth={182} className="mainText">
                    {o.name}
                  </Box>
                  <Box textAlign="right" color="grey['300']" fontSize={16} lineHeight="17px">
                    {getEducationPeriodStr(o.startDate, o.endDate, o.currentJob)}
                  </Box>
                </Box>
              ))}
          </Box>
          <Box mb="20px">
            <Box mb="6px" className="secondaryText">
              Job Experience
            </Box>
            {sortedExperience.length ? (
              map(sortedExperience, (o, i, arr) => (
                <div
                  key={`experience__${i}`}
                  className="expItem"
                  style={{ marginBottom: i !== arr.length - 1 ? 10 : 0 }}
                >
                  <div>
                    <div className="mainText expItem__name">{o.name}</div>
                    <div className="secondaryBoldText expItem__pos">{o.pos}</div>
                  </div>
                  {o.startDate && o.endDate && (
                    <Box
                      textAlign="right"
                      color="grey['300']"
                      fontSize={16}
                      lineHeight="17px"
                    >
                      {getExperiencePeriodStr(o.startDate, o.endDate, o.currentJob)}
                    </Box>
                  )}
                </div>
              ))
            ) : (
              <div>No experience</div>
            )}
          </Box>
          <Box mb="20px">
            <Box mb="6px" className="secondaryText">
              Resume
            </Box>
            {resumeUrl && (
              <a href={resumeUrl} className="resumeLink">
                {fileNameFromUrl(resumeUrl)}
              </a>
            )}
          </Box>
          <Box mb="20px">
            <Box mb="6px" className="secondaryText">
              Email
            </Box>
            <div className="mainText">{email}</div>
          </Box>
          <Box mb="20px">
            <Box mb="6px" className="secondaryText">
              Phone Number
            </Box>
            <div className="mainText">{phone}</div>
          </Box>
          <Box mb="20px">
            <Box width="100%" display="flex">
              <Box>
                <Box mb="6px" className="secondaryText">
                  ZIP Code
                </Box>
                <div className="mainText">{zip}</div>
              </Box>
            </Box>
          </Box>
          <Box mb="20px">
            <Input
              {...$('notes', changeNotes)}
              variant={isNotesEditMode ? 'textfield' : 'text'}
              id="notes"
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <span className="secondaryText">Notes</span>
                  <IconButton
                    aria-label={isNotesEditMode ? 'save changes' : 'edit notes'}
                    color="primary"
                    className="editableInputTrigger"
                    disabled={notesLoading || notesPostLoading}
                    onClick={manageEmployerNotesState}
                    testID={`applicant-profile-modal-notes-${
                      isNotesEditMode ? 'submit' : 'edit'
                    }-button`}
                  >
                    {renderNotesIcon()}
                  </IconButton>
                </Box>
              }
              multiline
              disabled={!isNotesEditMode || notesPostLoading || notesLoading}
              InputLabelProps={{ sx: { marginBottom: '8px' } }}
              inputClassName="mainText"
              analyticParams={{
                key: 'Applicant notes focused',
                trigger: 'focus'
              }}
              testID="applicant-profile-modal-notes-input"
            />
          </Box>
          {attrs.employeeNotes && (
            <Box mb="24px">
              <Box mb="6px" className="secondaryText">
                Pre-Screen Interview
              </Box>
              <div className="mainText" style={{ wordBreak: 'break-word' }}>
                {attrs.employeeNotes}
              </div>
            </Box>
          )}
          {withActions && approveStatus !== APPROVED_STATUS && approveStatus !== REJECTED_STATUS && (
            <Box width="100%" display="flex" alignItems="center" justifyContent="center">
              <IconButton
                variant="outlined"
                color="primary"
                aria-label={actionNames.reject.title}
                disabled={!allowEmployeeActions}
                withTooltip
                toolTipProps={{ title: actionNames.reject.title }}
                sx={{ marginLeft: '24px', padding: '12px', fontSize: 32 }}
                onClick={onReject}
                testID="applicant-profile-modal-reject-button"
              >
                <MdClose fontSize="inherit" />
              </IconButton>
              <IconButton
                variant="outlined"
                color="primary"
                aria-label={actionNames.accept.title}
                disabled={!allowEmployeeActions}
                withTooltip
                toolTipProps={{ title: actionNames.accept.title }}
                sx={{ marginLeft: '24px', padding: '12px', fontSize: 32 }}
                onClick={onAccept}
                testID="applicant-profile-modal-accept-button"
              >
                <MdCheck fontSize="inherit" />
              </IconButton>
              <IconButton
                variant="outlined"
                color="primary"
                aria-label={isStarred ? 'unstar' : actionNames.star.title}
                disabled={!allowEmployeeActions}
                withTooltip
                toolTipProps={{
                  title: isStarred ? 'Unstar' : actionNames.star.title
                }}
                sx={{ marginLeft: '24px', padding: '8px' }}
                onClick={() => onStar(!isStarred)}
                testID="applicant-profile-modal-star-button"
              >
                <MdStarBorder />
              </IconButton>
            </Box>
          )}
        </div>
      </Box>
    </StyledRoot>
  );
}

ApplicantProfileDialog.propTypes = {
  approveStatus: PropTypes.oneOf(['', APPROVED_STATUS, REJECTED_STATUS]),
  id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  isQhMatch: PropTypes.bool,
  isStarred: PropTypes.bool,
  jobId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  jobLink: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onStar: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  withActions: PropTypes.bool,
  withEmployerCourses: PropTypes.bool
};

ApplicantProfileDialog.defaultProps = {
  approveStatus: '',
  isQhMatch: false,
  isStarred: false,
  jobId: '',
  jobLink: '',
  onAccept: () => {},
  onReject: () => {},
  onStar: () => {},
  withActions: true,
  withEmployerCourses: false
};

export default ApplicantProfileDialog;
