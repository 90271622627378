import React from 'react';
import { Box, styled } from 'components';
import { MdSearch } from 'components/icons';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.noJobs': {
    padding: '76px 16px 20px',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '30px 16px 20px'
    }
  },
  '& .noJobs__icon': {
    width: 111,
    height: 111,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: 58,
    backgroundColor: '#F1F2F2',
    color: theme.palette.background.default,
    [theme.breakpoints.down('desktopApp')]: {
      padding: 16,
      backgroundColor: 'transparent'
    }
  },
  '& .noJobs__box': {
    maxWidth: 360,
    margin: '0 auto',
    borderRadius: 18,
    [theme.breakpoints.down('desktopApp')]: {
      padding: 16,
      backgroundColor: theme.palette.background.paper
    }
  },
  '& .noJobs__titleContainer': {
    '& .noJobs__title + div': {
      maxWidth: 300,
      margin: '0 auto 80px',
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 20
      }
    }
  },
  '& .noJobs__title': {
    marginBottom: 14,
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.common.white
  },
  '& .noJobs__text': {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '17px',
    color: theme.palette.common.white
  },
  '& .noJobs__subTitle': {
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 14,
      color: theme.palette.common.white
    }
  }
}));

const AllJobsEmpty = () => (
    <StyledRoot className="noJobs">
      <Box mb="25px" display="flex" justifyContent="center">
        <div className="noJobs__icon">
          <MdSearch fontSize="inherit" />
        </div>
      </Box>
      <div className="noJobs__titleContainer">
        <h1 className="noJobs__title">Check Back Soon!</h1>
        <div>
          <p className="noJobs__text">
            Looks like there are no jobs in your area at the moment, but we’re always expanding to
            new markets!
          </p>
        </div>
      </div>
      <Box maxWidth={360} mx="auto" className="noJobs__box">
        <Box component="p" mb="14px" className="noJobs__text noJobs__subTitle">
          Moving?
        </Box>
        <p className="noJobs__text">
          Change your zip code to the one your moving to and see what jobs await you at your new
          home!
        </p>
      </Box>
    </StyledRoot>
  )

AllJobsEmpty.propTypes = {};

export default AllJobsEmpty;
