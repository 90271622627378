import { useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Button, Spinner } from '../../shared';
import badgeCardStyles from '../../../styles/Badges/badgeCard';
import { IRequestedBadgesResponse } from '../../../api/types/queries/paths.types';
import {
  MARK_ABILITY_BADGE_COMPLETE,
  MARK_SKILL_BADGE_COMPLETE,
  REQUESTED_BADGES_BY_EMPLOYEE,
} from '../../../api';
import { badgeStatusEnum } from '../../../api/types/utils.types';

const StyledBadges = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  '.badgesWrapper': {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateColumns:
      'repeat(auto-fill, minmax(200px, calc(25% - 12px)))' /* Adjust the minmax values as needed */,
    gridGap: theme.spacing(1.5),
    minWidth: 500,
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
      '& > div': {
        width: '100%',
        display: 'block',
      },
    },
  },
  '.badgeTitle': {
    paddingBottom: 10,
  },
}));

const StyledBadgeCard = styled(Box)(badgeCardStyles);

interface IApproveBadge {
  employeeId: number;
}
const ApproveBadge = ({ employeeId }: IApproveBadge) => {
  const [savingBadgeId, setSavingBadgeId] = useState<
    string | null
  >(null);

  const {
    data: requestedBadges,
    loading,
    refetch,
  } = useQuery<IRequestedBadgesResponse>(
    REQUESTED_BADGES_BY_EMPLOYEE,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        employeeId,
      },
    },
  );

  const [markSkillBadgeComplete] = useMutation(
    MARK_SKILL_BADGE_COMPLETE,
    {
      fetchPolicy: 'network-only',
      onCompleted: async () => {
        await refetch();
        setSavingBadgeId(null);
      },
    },
  );

  const [markAbilityBadgeComplete] = useMutation(
    MARK_ABILITY_BADGE_COMPLETE,
    {
      fetchPolicy: 'network-only',
      onCompleted: async () => {
        await refetch();
        setSavingBadgeId(null);
      },
    },
  );

  const handleAbilityApprove = async ({
    employeeUserId,
    badgeId,
  }) => {
    // run approval mutation for abilities
    setSavingBadgeId(`abilities-${badgeId}`);
    await markAbilityBadgeComplete({
      variables: {
        abilityBadgeId: badgeId,
        employeeUserId,
        status: badgeStatusEnum.COMPLETE,
      },
    });
  };

  const handleSkillApprove = async ({
    employeeUserId,
    badgeId,
  }) => {
    setSavingBadgeId(`skills-${badgeId}`);
    await markSkillBadgeComplete({
      variables: {
        skillBadgeId: badgeId,
        employeeUserId,
        status: badgeStatusEnum.COMPLETE,
      },
    });
  };

  const { abilitiesBadges = [], skillsBadges = [] } = get(
    requestedBadges,
    'requestedBadgesByEmployee',
    {},
  );

  const BadgeCard = React.memo(
    ({
      name,
      badgeUrl,
      badgeId,
      approveBadge,
      employeeUserId,
    }) => (
      <StyledBadgeCard>
        <Box className="badgeTitleWrapper">
          <img alt="badge" src={badgeUrl} />
          <Box className="positioningWrapper">
            <Typography variant="h3">{name}</Typography>
          </Box>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() =>
              approveBadge({
                badgeId,
                employeeUserId,
              })
            }
            disabled={loading}
            startIcon={loading ? <Spinner /> : null}
          >
            Approve
          </Button>
        </Box>
      </StyledBadgeCard>
    ),
  );

  const AbilityBadges = React.memo(() => (
    <StyledBadges>
      <Typography className={'badgeTitle'} variant={'h3'}>
        Soft skill badges
      </Typography>
      <Box className={'badgesWrapper'}>
        {isEmpty(abilitiesBadges) ? (
          <Typography variant={'body1'}>
            No soft skill badges at the moment
          </Typography>
        ) : (
          abilitiesBadges.map((badge) => (
            <BadgeCard
              key={`abilities-${badge.requestedAbilitiesBadge.id}`}
              saving={
                savingBadgeId ===
                `abilities-${badge.requestedAbilitiesBadge.id}`
              }
              name={badge.requestedAbilitiesBadge.name}
              badgeUrl={badge.requestedAbilitiesBadge.badgeUrl}
              badgeId={badge.requestedAbilitiesBadge.id}
              employeeUserId={badge.employeeUserId}
              approveBadge={handleAbilityApprove}
            />
          ))
        )}
      </Box>
    </StyledBadges>
  ));

  const SkillBadges = React.memo(() => (
    <StyledBadges>
      <Typography className={'badgeTitle'} variant={'h3'}>
        Hard skill badges
      </Typography>
      <Box className={'badgesWrapper'}>
        {isEmpty(skillsBadges) ? (
          <Typography variant={'body1'}>
            No hard skill badges at the moment
          </Typography>
        ) : (
          skillsBadges.map((badge) => (
            <BadgeCard
              key={`skills-${badge.requestedSkillsBadge.id}`}
              saving={
                savingBadgeId ===
                `skills-${badge.requestedAbilitiesBadge.id}`
              }
              name={badge.requestedSkillsBadge.name}
              badgeUrl={badge.requestedSkillsBadge.badgeUrl}
              badgeId={badge.requestedSkillsBadge.id}
              employeeUserId={badge.employeeUserId}
              approveBadge={handleSkillApprove}
            />
          ))
        )}
      </Box>
    </StyledBadges>
  ));

  return (
    <Box>
      <AbilityBadges />
      <SkillBadges />
    </Box>
  );
};

export default ApproveBadge;
