import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import TeamChart from 'components/Grow/employer/teamStructure';
import CompanyCharts from 'components/Grow/employer/charts/CompanyCharts';
import Stores from 'components/Grow/employer/stores/Stores';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import map from 'lodash/map';
import { useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { ApplicantProfileDialog } from 'components/dialogs';
import { getProfileId } from 'utils';
import { client, GET_EMPLOYER_JOBS } from 'api';
import styles from 'styles/Grow/employer';
import growApi from '../api';
import BreakdownAndTenureContainer from './charts/BreakdownAndTenureContainer';
import EmployeeMood from './charts/EmployeeMood';
import GrowSettingsModal from './GrowSettingsModal';
import EmployerGrowContext from '../EmployerGrowContext';

const StyledRoot = styled('div')(styles);

const EmployerCareerDevelopment = () => {
  const [employerGrowCtx, setEmployerGrowCtx] = useState(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] =
    useState(false);
  const [hoveredEmployeeCardId, setHoveredEmployeeCardId] =
    useState(null);
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [fetchJobs, { data: employerJobsData = {} }] =
    useLazyQuery(GET_EMPLOYER_JOBS, {
      variables: {
        active: true,
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    });

  const [fetchGrowSettings, { data: growSettingsData = {} }] =
    useLazyQuery(growApi.query.EMPLOYER_CHART_SETTINGS, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    });

  const [postSettings] = useMutation(
    growApi.mutation.POST_EMPLOYER_CHART_SETTINGS,
  );

  const getEmployerAnalytic = async (userId) => {
    if (userId) {
      const res = await client.query({
        query: growApi.query.EMPLOYER_ANALYTIC,
        variables: { userId: Number(userId) },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      if (res?.data?.getEmployerAnalytic?.analytic) {
        const data = JSON.parse(
          res.data.getEmployerAnalytic.analytic,
        );
        const { jobStatistics, jobPostScores } = data;
        const {
          activeJobs = [],
          approved: applicants = [],
          qhMatches = [],
          candidate = [],
          rejected = [],
          starredJob = [],
        } = jobStatistics;

        const jobStatisticsFormatted = map(
          activeJobs,
          ({ createdAt, location, title }) => {
            const starredCount =
              find(starredJob, ['title', title])?.count || 0;
            const applicantsCount =
              find(applicants, ['title', title])?.count || 0;
            const qhMatchesCount =
              find(qhMatches, ['title', title])?.count || 0;
            const approvedCount =
              find(candidate, ['title', title])?.count || 0;
            const rejectedCount =
              find(rejected, ['title', title])?.count || 0;
            return {
              starred: Number(starredCount),
              applicants: Number(applicantsCount),
              qhMatches: Number(qhMatchesCount),
              approved: Number(approvedCount),
              rejected: Number(rejectedCount),
              createdAt,
              location,
              title,
            };
          },
        );
        return {
          jobStatistics: jobStatisticsFormatted,
          jobPostingScores: jobPostScores,
        };
      }
    }
    return null;
  };

  const getEmployeesByEmployerCode = async () => {
    const token = localStorage.getItem('token');

    if (token) {
      setEmployerGrowCtx((prev) => ({
        ...prev,
        isMainDataLoading: true,
      }));

      const employeeResp = await client.query({
        query: growApi.query.EMPLOYEES_BY_EMPLOYER_CODE,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });
      const employees = get(
        employeeResp,
        'data.employeesByEmployerCode',
      );
      const industries = uniq(
        compact(
          employees.map((employee) =>
            get(employee, 'careerDevelopment.industryId', null),
          ),
        ),
      );

      const jobTypes = flatten(
        await Promise.all(
          industries.map(async (industryId) => {
            const jobs = await client.query({
              query: growApi.query.GET_JOB_TYPES_BY_INDUSTRY,
              variables: { industryId },
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            });
            return get(jobs, 'data.jobTypesByIndustryId');
          }),
        ),
      ).reduce(
        (acc, jobType) => ({
          ...acc,
          [jobType.id]: { ...jobType },
        }),
        {},
      );

      setEmployerGrowCtx((prev) => ({
        ...prev,
        getEmployerAnalytic,
        employees,
        jobTypes,
        currentChartIdx: 0,
        employerCharts: [],
        isMainDataLoading: false,
      }));
    }
  };

  const updateSettings = async (settings) => {
    const employerProfileProfileId = Number(getProfileId());
    if (employerProfileProfileId) {
      await postSettings({
        variables: { employerProfileProfileId, ...settings },
      });
      fetchGrowSettings({
        variables: { employerProfileProfileId },
      });
    }
  };

  const updateEmployerCtx = useCallback((ctxUpdate) => {
    setEmployerGrowCtx((prev) => ({
      ...prev,
      ...ctxUpdate,
    }));
  }, []);

  useEffect(() => {
    (async () => {
      const employerProfileProfileId = Number(getProfileId());
      await getEmployeesByEmployerCode();
      fetchJobs();

      if (employerProfileProfileId)
        fetchGrowSettings({
          variables: { employerProfileProfileId },
        });
    })();
  }, []);

  useEffect(() => {
    updateEmployerCtx({
      jobs: employerJobsData?.employerJobs || [],
    });
  }, [JSON.stringify(employerJobsData)]);

  useEffect(() => {
    if (growSettingsData?.getChartSettings) {
      updateEmployerCtx({
        ...growSettingsData.getChartSettings,
      });
    }
  }, [JSON.stringify(growSettingsData)]);

  const showEmployeeProfile = useCallback(
    (employeeUserId) => updateEmployerCtx({ employeeUserId }),
    [updateEmployerCtx],
  );

  const closeEmployeeProfile = () =>
    updateEmployerCtx({ employeeUserId: null });

  const showSettings = useCallback(
    () => setIsSettingsModalOpen(true),
    [],
  );

  const closeSettings = () => setIsSettingsModalOpen(false);

  const { ...restState } = employerGrowCtx || {};
  const ctx = useMemo(
    () => ({
      updateEmployerCtx,
      showEmployeeProfile,
      showSettings,
      isAdmin: null, // true | false
      hoveredEmployeeCardId,
      setHoveredEmployeeCardId,
      ...restState,
    }),
    [
      updateEmployerCtx,
      showEmployeeProfile,
      showSettings,
      hoveredEmployeeCardId,
      setHoveredEmployeeCardId,
      JSON.stringify(restState),
    ],
  );

  const { employeeSatisfaction, companyBreakdown, avgTen } = ctx;

  return ctx?.employees ? (
    <EmployerGrowContext.Provider value={ctx}>
      <StyledRoot style={{ overflow: 'auto' }}>
        <div className="chartsTopContainer">
          {employeeSatisfaction && <EmployeeMood />}
          {(companyBreakdown || avgTen) && (
            <BreakdownAndTenureContainer />
          )}
        </div>
        {isDesktop && (
          <TeamChart />
        )}
        <Stores />
        <CompanyCharts />
      </StyledRoot>
      {employerGrowCtx.employeeUserId && (
        <ApplicantProfileDialog
          isOpen
          id={employerGrowCtx.employeeUserId}
          withActions={false}
          withEmployerCourses
          onClose={closeEmployeeProfile}
        />
      )}
      <GrowSettingsModal
        isOpen={isSettingsModalOpen}
        onConfirm={updateSettings}
        onClose={closeSettings}
      />
    </EmployerGrowContext.Provider>
  ) : null;
};

export default EmployerCareerDevelopment;
