import React, { useState } from 'react';
import PT from 'prop-types';
import { qaAttr } from 'utils';
import { Dialog, styled } from 'components';
import { ButtonBase } from '../Button';

const StyledDialog = styled(Dialog)(() => ({
  '& .dialogPaper': {
    position: 'relative',
    width: '100%',
    padding: 0,
    overflow: 'hidden',
    display: 'block',
    border: 0,
    backgroundColor: '#000',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '56.25%' // 16:9
    }
  },
  '& .dialogFrameWrapper': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '& > iframe': {
      width: '100%',
      height: '100%'
    }
  }
}));

const StyledRoot = styled(ButtonBase)(() => ({
  '& .triggerFrameWrapper': {
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  }
}));

const EmbedVideo = (props) => {
  const { src, iframeProps, modalIframeProps, triggerBtnContent, triggerBtnProps } = props;
  // const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <StyledRoot onClick={openVideo} {...qaAttr('embed-video-button')} {...triggerBtnProps}>
        {triggerBtnContent || (
          <div className="triggerFrameWrapper">
            <iframe
              width="100%"
              src={src}
              title="YouTube video player"
              frameBorder="0"
              allow=""
              allowFullScreen
              {...iframeProps}
            />
          </div>
        )}
      </StyledRoot>
      <StyledDialog
        open={isDialogOpen}
        maxWidth="laptop"
        disablePortal
        classes={{ paper: 'dialogPaper' }}
        onClose={closeDialog}
      >
        <div className="dialogFrameWrapper">
          <iframe
            src={src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            {...modalIframeProps}
          />
        </div>
      </StyledDialog>
    </>
  );
}

EmbedVideo.propTypes = {
  src: PT.string.isRequired,
  iframeProps: PT.objectOf(PT.any),
  modalIframeProps: PT.objectOf(PT.any),
  triggerBtnContent: PT.node,
  triggerBtnProps: PT.objectOf(PT.any)
};

EmbedVideo.defaultProps = {
  iframeProps: {},
  modalIframeProps: {},
  triggerBtnContent: null,
  triggerBtnProps: {}
};

export default EmbedVideo;
