import map from 'lodash/map';
import React, { useState } from 'react';
import { useMediaQueryMatches } from '../../../hooks';
import { emoji, qaAttr } from '../../../utils';
import { styled } from '../../index';
import { ButtonBase } from '../Button';

const {
  ANGRY_FACE,
  SAD_FACE,
  POKER_FACE,
  SMILING_FACE,
  STAR_EYES,
} = emoji;
const RATES = [
  { ...ANGRY_FACE, value: 1, withFeedback: true },
  { ...SAD_FACE, value: 2, withFeedback: true },
  { ...POKER_FACE, value: 3, withFeedback: true },
  { ...SMILING_FACE, value: 4, withFeedback: false },
  { ...STAR_EYES, value: 5, withFeedback: false },
];

const Rates = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& .ratesContainer': {
    width: '100%',
    marginBottom: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      margin: '0 16px 0',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  '& .rateBtnWrapper': {
    maxWidth: '50%',
    padding: '0 16px 10px',
  },
  '& .rateBtn': {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey['200']}`,
    fontSize: 40,
    [theme.breakpoints.down('desktopApp')]: {
      borderRadius: 10,
      boxShadow: '0 3px 4px rgba(255,255,255, 0.08)',
    },
  },
}));

interface ISmileRatingProps {
  onRateChange: React.Dispatch<number>;
}

const SmileRating = ({ onRateChange }: ISmileRatingProps) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const [selectedRating, setSelectedRating] = useState<
    number | null
  >(null);

  const changeRating = (rating) => {
    setSelectedRating(rating);
    onRateChange(rating);
  };

  return (
    <Rates style={{ maxWidth: isDesktop ? '100%' : 320 }}>
      <div className="ratesContainer">
        {map(RATES, ({ code, value }, i) => (
          <div key={`rate__${i}`} className="rateBtnWrapper">
            <ButtonBase
              key={`rateBtn__${i}`}
              className="rateBtn"
              style={{
                backgroundColor:
                  i + 1 === selectedRating
                    ? 'rgba(255, 195, 90, .9)'
                    : 'transparent',
                width: i + 1 === selectedRating ? 65 : 50,
                height: i + 1 === selectedRating ? 65 : 50,
                fontSize: i + 1 === selectedRating ? 55 : 40,
              }}
              onClick={() => changeRating(i + 1)}
              {...qaAttr(`rate-button-${value}`)}
            >
              {code}
            </ButtonBase>
          </div>
        ))}
      </div>
    </Rates>
  );
};

export default SmileRating;
