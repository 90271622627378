import { defValidation } from 're-use-form';
import { isNumber, isString, isEmpty, isInteger } from 'lodash';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import formatDate from 'date-fns/format';
import { isNANP, trimStr, getUrlRegex } from 'utils';

const USZipPattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
const CanadaZipPattern = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\s*?[0-9][A-Z][0-9]$/;
const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

defValidation('presence', (value, { message, allowEmpty = false }) => {
  if (allowEmpty ? !value : !trimStr(value)) {
    return message || 'Cannot be blank';
  }
  return null;
});

defValidation(
  'date',
  (
    value,
    {
      message,
      messages = {},
      earliest,
      earliestFormat = 'MM-dd-yyyy',
      latest,
      latestFormat = 'MM-dd-yyyy'
    }
  ) => {
    if (!value) return '';
    if (!isDate(value)) return message || 'Invalid date';
    if (!isValid(value)) return messages.notValid || message || 'Invalid date';
    if (earliest && isBefore(value, earliest)) {
      return (
        messages.tooEarly ||
        message ||
        `Must not be earlier than ${formatDate(earliest, earliestFormat)}`
      );
    }
    if (latest && isAfter(value, latest)) {
      return (
        messages.tooLate || message || `Must not be later than ${formatDate(latest, latestFormat)}`
      );
    }
    return '';
  }
);

defValidation('email', (value, { message }) => {
  if (!value) return null;
  try {
    if ( !emailPattern.test(value) ) {
      return message || 'Should be a valid email address';
    }
  } catch (error) {
    return 'Invalid email format';
  }
  return null;
});

defValidation('format', (value, { pattern, message }) => {
  if (!value) return null;;
  if (!pattern.test(value)) {
    return message || 'Invalid format';
  }
  return null;
});

defValidation('maxLength', (value, { max = 100, message }) => {
  if (value?.length > max) {
    return message || `Maximum characters are ${max}`;
  }
  return null;
});

defValidation('minLength', (value, { min = 1, message }) => {
  if (value?.length < min) {
    return message || `Minimum characters are ${min}`;
  }
  return null;
});

defValidation(
  'numericality',
  (
    value,
    {
      onlyInteger = true,
      greaterThan,
      greaterThanOrEqual,
      lessThan,
      lessThanOrEqual,
      leadingZeros = false,
      numberOfDecimals = 0,
      message
    }
  ) => {
    const {
      notValid = '',
      notNumber = '',
      notInteger = '',
      notGreaterThan = '',
      notGreaterThanOrEqual = '',
      notLessThan = '',
      notLessThanOrEqual = ''
    } = message || {};
    let num = value;

    if (num != null) {
      let pattern = `^-?(0|[${leadingZeros ? 0 : 1}-9]\\d*)`;
      if (!onlyInteger) {
        pattern += `(\\.\\d${numberOfDecimals > 0 ? `{0,${numberOfDecimals}}` : '+'})?`;
      }
      pattern += '$';
      if (!new RegExp(pattern).test(num)) return notValid || 'Must be a valid number';
      if (isString(num) && !isEmpty(num)) num = +num;
      if (!isNumber(num)) return notNumber || 'Is not a number';
      if (onlyInteger && !isInteger(num)) return notInteger || 'Must be an integer';
      if (typeof greaterThan !== 'undefined' && num <= greaterThan)
        return notGreaterThan || `Must be greater than ${greaterThan}`;
      if (typeof greaterThanOrEqual !== 'undefined' && num < greaterThanOrEqual)
        return notGreaterThanOrEqual || `Must be greater than or equal to ${greaterThanOrEqual}`;
      if (typeof lessThan !== 'undefined' && num >= lessThan)
        return notLessThan || `Must be less than ${lessThan}`;
      if (typeof lessThanOrEqual !== 'undefined' && num > lessThanOrEqual)
        return notLessThanOrEqual || `Must be less than or equal to ${lessThanOrEqual}`;

    }
    return null;
  }
);

defValidation('phone', (value) => {
  if (!value) return null;;

  if (!isNANP(value)) {
    return 'Please enter a valid phone number';
  }
  return null;
});

defValidation('url', (value, { message, protocol = true }) => {
  if (!value) return null;;
  if (!getUrlRegex({ protocol }).test(value)) return message || 'Invalid URL';
  return null;
});

defValidation('zip', (value, { message = '', countryCode = '' }) => {
  // use https://www.npmjs.com/package/postcode-validator if countries list will expand
  if (!value) return null;;

  if ((countryCode === 'US' || countryCode === 'USA') && !USZipPattern.test(value)) {
    return message || 'Please enter a valid US ZIP Code';
  }

  if (countryCode === 'CA' && !CanadaZipPattern.test(value)) {
    return message || 'Please enter a valid Canada ZIP Code';
  }

  if (!(USZipPattern.test(value) || CanadaZipPattern.test(value))) {
    return message || 'Please enter a valid US or Canada ZIP Code';
  }

  return null;
});

defValidation('equality', (value, { attribute, message, comparator, name, attrs }) => {
  if (!value) return '';

  if (comparator ? !comparator(value, attrs[attribute]) : value !== attrs[attribute]) {
    return message || `Is not equal to ${name}`;
  }

  return '';
});

export * from 're-use-form';
