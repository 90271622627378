import React, { useState, useEffect, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import { Box, Dialog, styled } from 'components';
import { IconButton, Spinner } from 'components/shared';
import { MdSave } from 'components/icons';
import { qaAttr } from 'utils';
import map from 'lodash/map';
import find from 'lodash/find';
import { GET_INDUSTRY, GET_SKILLS } from 'api';

const FiltersModal = (props) => {
  const {
    className,
    isOpen,
    onClose,
    onSubmit,
    skills,
    industries,
  } = props;
  const [industriesOptions, setIndustriesOptions] = useState([]);
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState(
    industries || [],
  );
  const [selectedSkills, setSelectedSkills] = useState(
    skills || [],
  );

  const [
    fetchIndustry,
    { data: industryData = {}, loading: industryLoading = true },
  ] = useLazyQuery(GET_INDUSTRY, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const [
    fetchSkills,
    { data: skillsData, loading: skillsLoading = true },
  ] = useLazyQuery(GET_SKILLS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  useEffect(() => {
    fetchSkills();
    fetchIndustry();
  }, []);

  useEffect(() => {
    setSelectedSkills(skills);
  }, [JSON.stringify(skills)]);

  useEffect(() => {
    setSelectedIndustries(industries);
  }, [JSON.stringify(industries)]);

  useEffect(() => {
    setIndustriesOptions(industryData?.getIndustry);
  }, [JSON.stringify(industryData)]);

  useEffect(() => {
    if (skillsData?.getSkills)
      setSkillsOptions(skillsData.getSkills || []);
  }, [JSON.stringify(skillsData)]);

  const select = (option, name, selected) => {
    let array = [];
    if (name === 'skills') array = [...selectedSkills];
    if (name === 'industries') array = [...selectedIndustries];

    if (selected)
      array = array.filter(
        ({ name: optName }) => option.name !== optName,
      );
    else array = [...array, option];

    if (name === 'skills') setSelectedSkills(array);
    if (name === 'industries') setSelectedIndustries(array);
  };

  const submit = () => {
    onSubmit({
      skills: selectedSkills,
      industries: selectedIndustries,
    });
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      className={className}
      scroll="paper"
      onClose={onClose}
    >
      <Box
        px="20px"
        pt="17px"
        pb="8px"
        display="flex"
        justifyContent="space-between"
      >
        <div className="title">Job Filters</div>
        <IconButton
          variant="outlined"
          color="primary"
          aria-label="save changes"
          onClick={submit}
          testID="jobs-filter-modal-submit-button"
        >
          <MdSave />
        </IconButton>
      </Box>
      <div className="body">
        <div className="subTitle">Industries</div>
        {industryLoading && (
          <Box display="flex" justifyContent="center">
            <Spinner size={24} />
          </Box>
        )}
        <Box mb="25px" className="chips">
          {map(industriesOptions, (opt, i) => {
            const selected = find(selectedIndustries, [
              'name',
              opt.name,
            ]);
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                key={`industry__${i}`}
                role="button"
                aria-pressed={selected}
                tabIndex="0"
                className={clsx(
                  'chips__item',
                  selected && 'selected',
                )}
                onClick={() =>
                  select(opt, 'industries', selected)
                }
                {...qaAttr(`jobs-filter-industry-${i}`)}
              >
                {opt.name}
              </div>
            );
          })}
        </Box>
        <div className="subTitle">Skills</div>
        <div className="chips">
          {skillsLoading && (
            <Box display="flex" justifyContent="center">
              <Spinner size={24} />
            </Box>
          )}
          {map(skillsOptions, (opt, i) => {
            const selected = find(selectedSkills, [
              'name',
              opt.name,
            ]);
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                key={`skills__${i}`}
                role="button"
                aria-pressed={selected}
                tabIndex="0"
                className={clsx(
                  'chips__item',
                  selected && 'selected',
                )}
                onClick={() => select(opt, 'skills', selected)}
                {...qaAttr(`jobs-filter-skill-${i}`)}
              >
                {opt.name}
              </div>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};

FiltersModal.propTypes = {
  className: PT.string.isRequired,
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  skills: PT.arrayOf(PT.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  industries: PT.arrayOf(PT.any).isRequired,
};

const Styled = styled(FiltersModal)(({ theme }) => ({
  '& .body': {
    flex: 1,
    overflow: 'auto',
    padding: '8px 20px 38px',
  },
  '& .title': {
    fontSize: 18,
    fontWeight: 'bold',
  },
  '& .subTitle': {
    marginBottom: 16,
  },
  '& .chips': {
    marginTop: -20,
    marginLeft: -14,
    display: 'flex',
    flexWrap: 'wrap',
  },
  '& .chips__item': {
    marginTop: 20,
    marginLeft: 14,
    padding: '3px 14px',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    borderColor: theme.palette.primary.text,
    border: `1px solid #ECECF5`,
    borderRadius: 14,
    cursor: 'pointer',
    '&.selected': {
      color: theme.palette.primary.text,
      borderColor: theme.palette.primary.light,
      backgroundColor:
        theme.palette.button.primary.contrast,
    },
  },
}));

export default memo(Styled);
