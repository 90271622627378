import React from 'react';
import PropTypes from 'prop-types';
import { PRIVACY_URL, qaAttr } from 'utils';
import { Box, Dialog, MuiIconButton, styled } from 'components';
import { MdClose } from 'components/icons';

const PrivacyClauseDialog = ({ isOpen, onClose, ...rest }) => (
    <Dialog open={isOpen} fullWidth classes={{ paper: 'paper' }} onClose={onClose} {...rest}>
      <Box p="25px 27px">
        <Box mb="20px" display="flex" alignItems="center">
          <Box flex="0 0 24px" />
          <h1 className="title" {...qaAttr('privacy-modal-title')}>
            Privacy Clause
          </h1>
          <Box flex="0 0 24px">
            <MuiIconButton className="closeBtn" aria-label="close dialog" onClick={onClose}>
              <MdClose fontSize="inherit" color="primary" />
            </MuiIconButton>
          </Box>
        </Box>
        <p className="content">
          We use this information to provide and support WorkTorch products and related services
        </p>
        <Box textAlign="center">
          <a
            href={PRIVACY_URL}
            target="_blank"
            rel="noreferrer noopener"
            {...qaAttr('privacy-modal-link')}
          >
            Read our Privacy Policy
          </a>
        </Box>
      </Box>
    </Dialog>
  )

PrivacyClauseDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const Styled = styled(PrivacyClauseDialog)(({ theme }) => ({
  '& .paper': {
    maxWidth: 416,
    [theme.breakpoints.down('desktopApp')]: {
      maxHeight: 'calc(100% - 32px)',
      width: 'calc(100% - 32px)',
      margin: 16
    }
  },
  '& .title': {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '18px',
    textAlign: 'center'
  },
  '& .closeBtn': {
    padding: 0,
    fontSize: 24
  },
  '& .content': {
    marginBottom: 20,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center'
  }
}));

export default Styled;
