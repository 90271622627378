import React, { useState, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import {
  InputAlert,
  SimpleAlert,
  Toast,
} from 'components/shared';
import { AlertsContext } from './context';

const AlertsProvider = ({ children, context }) => {
  const [simpleAlertProps, setSimpleAlertPropsRoot] = useState({
    isOpen: false,
  });
  const [inputAlertProps, setInputAlertPropsRoot] = useState({
    isOpen: false,
  });
  const [toastProps, setToastProps] = useState({
    isOpen: false,
  });

  const setSimpleAlert = useCallback((props) => {
    setSimpleAlertPropsRoot({ isOpen: false, ...props });
  }, []);

  const setInputAlert = useCallback((props) => {
    setInputAlertPropsRoot({ isOpen: false, ...props });
  }, []);

  const setToast = useCallback((props) => {
    setToastProps({ isOpen: false, ...props });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showToast = useCallback(async ({ isOpen, ...props }) => {
    const promise = new Promise((resolve) => {
      // hide last opened toast
      setToastProps({ isOpen: false });
      resolve();
    });
    await promise;
    setToastProps({ isOpen: true, ...props });
  }, []);

  const contextValue = useMemo(
    () => ({
      setSimpleAlert,
      setInputAlert,
      setToastProps,
      showToast,
    }),
    [],
  );

  const Context = context || AlertsContext;

  return (
    <Context.Provider value={contextValue}>
      {children}
      <SimpleAlert
        {...simpleAlertProps}
        setSimpleAlert={setSimpleAlert}
      />
      <InputAlert
        {...inputAlertProps}
        setInputAlert={setInputAlert}
      />
      <Toast {...toastProps} setToast={setToast} />
    </Context.Provider>
  );
}

AlertsProvider.propTypes = {
  children: PT.any,
  context: PT.object,
};

export default AlertsProvider;
