import React from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import { qaAttr } from 'utils';
import { Tabs, Tab, styled } from 'components';
import { LayoutViewController } from 'components/shared';
import { TABS } from './data';

const LinkTab = (props) => <Tab component={Link} {...props} />;

const StyledRoot = styled('div')(({ theme }) => ({
  '&.container': {
    marginBottom: 16,
    paddingLeft: 25,
    paddingRight: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .tabsRoot': {
    minHeight: 'auto',
  },
  '& .indicator': {
    display: 'none',
  },
  '& .tabRoot': {
    minHeight: 'auto',
    minWidth: 'auto',
    height: 38,
    padding: 0,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    textTransform: 'capitalize',
    opacity: 1,
    color: theme.palette.text.primary,
    borderRadius: 19,
    '&:not(:first-of-type)': {
      marginLeft: 20,
    },
    '&.Mui-selected': {
      padding: '0 24px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const TabsToolbar = (props) => {
  const { layoutType, tab, jobId, setTab, setLayout, webUrl } =
    props;

  return (
    <StyledRoot className="container">
      <Tabs
        value={tab}
        aria-label="applicants table tabs"
        classes={{
          root: 'tabsRoot',
          indicator: 'indicator',
        }}
        onChange={(e, tabIdx) => setTab(tabIdx)}
      >
        {map(TABS, ({ label, label_2, path, name }, i) => (
          <LinkTab
            to={path.replace(':id', jobId)}
            label={webUrl ? label : label_2}
            classes={{ root: 'tabRoot' }}
            key={`tab__${i}`}
            {...qaAttr(`candidates-table-tab-${name}`)}
          />
        ))}
      </Tabs>
      <LayoutViewController
        value={layoutType}
        onChange={setLayout}
      />
    </StyledRoot>
  );
};

TabsToolbar.propTypes = {
  layoutType: PT.string.isRequired,
  tab: PT.number.isRequired,
  jobId: PT.number.isRequired,
  setTab: PT.func.isRequired,
  setLayout: PT.func.isRequired,
  webUrl: PT.string.isRequired,
};

export default TabsToolbar;
