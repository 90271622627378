import {
  map,
  join,
  reduce,
  trim,
  isString,
  forEach,
} from 'lodash';
import { format, isSameYear } from 'date-fns';
import getSymbolFromCurrency from 'currency-symbol-map';

import numberToUsdFormatter from './numberToUsdFormatter';
import getEmployerSubscriptionInfo from './getEmployerSubscriptionInfo';
import getAgeByBirthDate from './getAgeByBirthDate';

export {
  numberToUsdFormatter,
  getEmployerSubscriptionInfo,
  getAgeByBirthDate,
};
export * from './app';
export * from './routes';
export * from './localStorage';
export * from './dates';
export * from './truncateText';

const ALLOWED_PLAN_IDS = [1, 2, 3];

export const INTERVIEW_TYPES = {
  inperson: 'IN_PERSON',
  web: 'WEB',
  phone: 'PHONE',
};

export const WEEK_DAYS_LONG = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const imageTypeHelperText =
  '** Accepts jpg and png files';

export const getCurrencySymbol = (name) =>
  getSymbolFromCurrency(name);

export const getPlanIdParamFromSearch = (search) => {
  const planIdParam = search
    ? getUrlParameter(search, 'planId')
    : '';
  const planId = planIdParam ? Number(planIdParam) : undefined;
  return planId && ALLOWED_PLAN_IDS.indexOf(planId) !== -1
    ? planId
    : undefined;
};

export const capitalizeFirstLetter = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : '';

export function fileNameFromUrl(url) {
  if (!url) return '';
  const matches = url.match(/\/([^/?#]+)[^/]*$/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return '';
}

export function getUrlParameter(search, incomingName) {
  const name = incomingName
    .replace(/[[]/, '\\[')
    .replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getDisplayName(WrappedComponent) {
  return (
    WrappedComponent.displayName ||
    WrappedComponent.name ||
    'Component'
  );
}

export function getMobileOS() {
  const userAgent =
    navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return null;
}

export function isIos() {
  return getMobileOS() === 'iOS';
}

export function getExperiencePeriodStr(start, end, currentJob) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  if (start && !end && !currentJob)
    return format(startDate, 'MMM yyyy');
  // eslint-disable-next-line no-nested-ternary
  return currentJob
    ? `${format(startDate, 'MMM yyyy')}-now`
    : isSameYear(startDate, endDate)
    ? `${format(startDate, 'MMM')}-${format(
        endDate,
        'MMM yyyy',
      )}`
    : `${format(startDate, 'MMM yyyy')}-${format(
        endDate,
        'MMM yyyy',
      )}`;
}

export function getEducationPeriodStr(start, end, currentJob) {
  return `${start}${
    end || currentJob ? `-${currentJob ? 'now' : end}` : ''
  }`;
}

export const emoji = {
  ANGRY_FACE: { code: '\u{1F621}', label: 'Hate' },
  SAD_FACE: { code: '\u{1F641}', label: 'Dislike' },
  POKER_FACE: { code: '\u{1F610}', label: 'Okay' },
  SMILING_FACE: { code: '\u{1F642}', label: 'Like It' },
  STAR_EYES: { code: '\u{1F929}', label: 'Love It' },
};
export const emojiRates = [
  emoji.ANGRY_FACE.code,
  emoji.SAD_FACE.code,
  emoji.POKER_FACE.code,
  emoji.SMILING_FACE.code,
  emoji.STAR_EYES.code,
];
export const textRates = [
  'Hate',
  'Dislike',
  'Okay',
  'Like It',
  'Love It',
];

export function unformatPhone(str) {
  return str.replace(/[^0-9]/g, '');
}

export function isNANP(phoneNumber) {
  // https://en.wikipedia.org/wiki/North_American_Numbering_Plan#Modern_plan
  return /^1[2-9]\d{2}[2-9]\d{6}$/.test(
    phoneNumber?.toString() || '',
  );
}

export function joinFieldsToStr(arr, field = '') {
  return arr.length > 0 && field
    ? join(
        map(arr, (o) => o[field]),
        ', ',
      )
    : '';
}

export function makeInitForm(fieldsObj, options = {}) {
  const {
    noPrevFields = [],
    exclude = [],
    prevFieldsObjKey = '',
  } = options || {};
  return reduce(
    fieldsObj,
    (res, val, key) => {
      if (exclude.indexOf(key) === -1) {
        res[key] = val;
        if (noPrevFields.indexOf(key) === -1) {
          if (prevFieldsObjKey) {
            res[prevFieldsObjKey] = {
              ...res[prevFieldsObjKey],
              [`prev${capitalizeFirstLetter(key)}`]: val,
            };
          } else {
            res[`prev${capitalizeFirstLetter(key)}`] = val;
          }
        }
      }
      return res;
    },
    {},
  );
}

export function getComparableFields(obj = {}, fields = []) {
  const result = { prev: {}, current: {} };

  if (fields.length) {
    forEach(fields, (key) => {
      const prevAnalogKey = `prev${capitalizeFirstLetter(key)}`;
      if (
        // eslint-disable-next-line no-prototype-builtins
        obj.hasOwnProperty(key) &&
        // eslint-disable-next-line no-prototype-builtins
        obj.hasOwnProperty(prevAnalogKey)
      ) {
        result.prev[key] = obj[prevAnalogKey];
        result.current[key] = obj[key];
      }
    });
  }

  return result;
}

export function qaAttr(name) {
  return { 'data-qa': name };
}

export function getUrlRegex(params = { protocol: true }) {
  return new RegExp(
    `^(${
      params.protocol ? 'https?://' : ''
    }(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?://(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})`,
  );
}

export function urlWithHttp(url) {
  if (url && !/(https?):\/\//.test(url)) return `http://${url}`;
  return url;
}

export function trimStr(val) {
  return isString(val) ? trim(val) : val;
}

export const formatJobAddress = ({
  city,
  country,
  zip,
  state,
}) =>
  city && country && state && zip
    ? `${city}, ${state}, ${zip}, ${country}`
    : '';

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(
        // eslint-disable-next-line prefer-template
        (c) =>
          `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`,
      )
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export const validateUUID = (uuid) => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return regex.test(uuid);
};

export const isValidUSZipCode = (zipCode) => {
  // Regular expression for both five-digit and nine-digit ZIP codes
  const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

  return zipCodeRegex.test(zipCode);
}

export const isValidCanadianPostalCode = (postalCode) => {
  // Regular expression for Canadian postal codes
  const postalCodeRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

  return postalCodeRegex.test(postalCode);
}
