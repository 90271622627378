import React, { useState, useEffect, memo } from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import Typography from '@mui/material/Typography';
import GrowLevels from './GrowLevels';
import PathComingSoon from './PathComingSoon';
import AvailableTracks from './AvailableTracks';
import GrowBadgeCard from '../../Employee/badges/GrowBadgeCard';

const StyledWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .badgesWrapper': {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateColumns:
      'repeat(auto-fill, minmax(200px, calc(25% - 12px)))' /* Adjust the minmax values as needed */,
    gridGap: theme.spacing(1.5),
    minWidth: 450,
    gridAutoRows: '1fr',
    [theme.breakpoints.down('desktop')]: {
      gridTemplateColumns: 'repeat(3, minmax(auto, 200px))',
      minWidth: 'inherit',
    },
    [theme.breakpoints.down('laptop')]: {
      gridTemplateColumns: 'repeat(2, minmax(auto, 200px))',
    },
  },
  '& .emptyBadgesContainer': {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& Typography': {
      color: 'red',
    },
  },
}));

const PathPage = (props) => {
  const {
    careerPath,
    careerPathLoading,
    careerPathCalled,
    futureJobTypeId,
    industryId,
    onCreateNewCareerPath,
  } = props;
  const levels = careerPath?.careerPathGroupItems || [];
  const showAvailableTracks =
    !levels.length && !careerPathLoading && careerPathCalled;

  const [activeLevel, setActiveLevel] = useState(0);

  const abilityBadges = levels[activeLevel]?.abilitiesBadges;
  const skillBadges = levels[activeLevel]?.skillsBadges;
  const combinedBadges = (abilityBadges ?? []).concat(
    skillBadges ?? [],
  );

  useEffect(() => {
    if (levels.length) {
      setActiveLevel(activeLevel);
    }
  }, [JSON.stringify(levels)]);

  return (
    <div>
      {!!levels.length && (
        <>
          <GrowLevels
            levels={levels}
            activeLevel={activeLevel}
            setActiveLevel={setActiveLevel}
          />
          <StyledWrapper>
            {combinedBadges?.length > 0 ? (
              <Box className={'badgesWrapper'}>
                {combinedBadges
                  .sort((a, b) =>
                    a.createdAt < b.createdAt ? -1 : 1,
                  )
                  ?.map((badge) => (
                    <GrowBadgeCard
                      key={`${combinedBadges.id}--${badge.name}`}
                      badge={badge}
                    />
                  ))}
              </Box>
            ) : (
              <Box className={'emptyBadgesContainer'}>
                <Typography variant={'h2'}>
                  There are currently no badges for this level
                </Typography>
              </Box>
            )}
          </StyledWrapper>
        </>
      )}
      {showAvailableTracks && (
        <>
          <Box mb="15px">
            <PathComingSoon futureJobTypeId={futureJobTypeId} />
          </Box>
          <Box maxWidth={550} mx="auto">
            <AvailableTracks
              industryId={industryId}
              onPathSelect={onCreateNewCareerPath}
            />
          </Box>
        </>
      )}
    </div>
  );
};

PathPage.propTypes = {
  careerPathLoading: PT.bool,
  careerPathCalled: PT.bool,
  careerPath: PT.shape({
    careerPathGroupItems: PT.arrayOf(
      PT.shape({
        active: PT.bool,
        completed: PT.bool,
        careerPathGroupId: PT.number,
        careerPathGroups: PT.shape({
          id: PT.number,
          startJobTypeId: PT.number,
          endJobTypeId: PT.number,
        }),
        id: PT.number,
        index: PT.number,
        jobTypeId: PT.number,
        jobTypeName: PT.string,
        abilitiesBadges: PT.arrayOf(
          PT.shape({
            abilitiesBadgeId: PT.number,
            abilitiesId: PT.number,
            badgeUrl: PT.string,
            complete: PT.bool,
            name: PT.string,
          }),
        ),
        skillsBadges: PT.arrayOf(
          PT.shape({
            skillsBadgeId: PT.number,
            skillsId: PT.number,
            badgeUrl: PT.string,
            complete: PT.bool,
            name: PT.string,
          }),
        ),
      }),
    ),
  }).isRequired,
  futureJobTypeId: PT.number.isRequired,
  industryId: PT.number.isRequired,
  onCreateNewCareerPath: PT.func.isRequired,
};

PathPage.defaultProps = {
  careerPathLoading: false,
  careerPathCalled: false,
};

export default memo(PathPage);
