import { Outlet } from 'react-router-dom';
import { Box } from 'components';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)<IStyledProps>(({ theme }) => ({
  [theme.breakpoints.down('desktopApp')]: {
    padding: theme.spacing(2),
  },
}));
const OnboardingPageLayout = () => {
  return (
    <StyledBox>
      <Outlet />
    </StyledBox>
  );
};

export default OnboardingPageLayout;
