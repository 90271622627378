import {
  backgroundBorderMixin,
  backgroundTransparentBorderMixin,
  baseGap,
  containerPaddingMixin,
  retentionNavContainer,
} from '../helpers';
import { addAlpha } from '../../utils';

export const dashboardStyles = ({ theme, hasAside }) => ({
  // employer-detail-container
  display: 'grid',
  gridTemplateColumns: hasAside ? '1fr 225px' : '1fr',
  maxWidth: hasAside ? '100%' : '1024px',
  columnGap: baseGap,
  [theme.breakpoints.down('desktopApp')]: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  '.employer-detail-content': {
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: '100%',
    },
  },
  '& .retention-navigation-wrapper': {
    height: 'auto',
  },
  '& .location-container': {},
  '& .location-content-container': {
    ...backgroundBorderMixin({ theme }),
    ...containerPaddingMixin(),
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(1),
    },
  },
  '& .location-graph-snapshot': {
    ...backgroundTransparentBorderMixin({ theme }),
    ...containerPaddingMixin(),
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(1),
    },
  },
  '& .location-light-font-weight': {
    fontWeight: '100 !important',
    display: 'flex',
    marginBottom: 0,
    '& > p': {
      marginLeft: 10,
      fontWeight: '800 !important',
    },
  },
  '& .location-title-row': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
      alignItems: 'start',
      h2: {
        display: 'block',
        marginBottom: theme.spacing(1.5),
        fontSize: 15,
        span: {
          fontSize: 20,
        },
      },
    },
  },
  '& .location-snapshot-stats': {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 425,
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
      alignItems: 'start',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },
    },
    '& p': {
      marginBottom: 7.5,
    },
  },
  '& .main-section-container': {
    display: 'grid',
    ...backgroundTransparentBorderMixin({ theme }),
    marginTop: baseGap,
    boxSizing: 'border-box',
  },
  '& .main-section-side-nav': {
    // display: 'flex',
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.primary.light}`,
    paddingTop: 7.5,
    button: {
      padding: 15,
      minWidth: 0,
    },
  },
  // aside styles
  '& .resource-container': {
    ...backgroundTransparentBorderMixin({ theme }),
    padding: `${baseGap}px`,
    marginBottom: `${baseGap}px`,
    position: 'relative',
  },
  '& .resourceAction': {
    position: 'absolute',
    top: 5,
    right: 12,
    svg: { fill: theme.palette.primary.light },
  },
  '& .resourceContent': {
    borderRadius: '7.5px',
    background: `linear-gradient(
          to bottom,
          ${addAlpha(theme.palette.primary.light, 0.75)} 0%,
          transparent 100%
        )`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    p: { textAlign: 'center', padding: '0 7px' },
    img: { padding: `calc(${baseGap}px * 0.66)`, width: 100 },
  },
  '& .employer-detail-aside': {
    marginTop: 48,
  },
  // overwrites
  // path overwrites
  '& .aside-root-path-override': {
    overflow: 'initial',
    ...backgroundTransparentBorderMixin({ theme }),
    ...containerPaddingMixin(0.5),
    boxSizing: 'border-box',
    height: 'max-content',
  },
  '& .content-root-path-override': {
    [theme.breakpoints.up('desktopApp')]: {
      overflow: 'hidden',
    },
  },
});

export const entryStyles = ({ theme }) => ({
  ...retentionNavContainer({ theme }),
});
