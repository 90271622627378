import React from 'react';
import { Icon, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { ConstructionIcon } from 'components/icons';
import { comingSoonStyles } from '../../../styles/Retention';

const StyledBox = styled(Box)(comingSoonStyles);

const ComingSoon = () => (
    <StyledBox>
      <Box className={'coming__soon__wrapper'}>
        <Box className="icon__wrapper">
          <Icon color="inherit" fontSize="inherit">
            <ConstructionIcon
              color="inherit"
              fontSize="inherit"
            />
          </Icon>
        </Box>
        <Box className={'coming__soon_text__wrapper'}>
          <Typography
            className={'coming__soon__text'}
            variant={'h1'}
          >
            This feature is coming soon
          </Typography>
          <Typography className={'coming__soon__text'}>
            <span>
              Chat with a specialist using the chat in the bottom
              right of this screen about integrating your HRIS or
              ATS&nbsp;system.
            </span>
          </Typography>
        </Box>
      </Box>
    </StyledBox>
  );

export default ComingSoon;
