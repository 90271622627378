import { styled } from 'components';
import { easePolyOut } from 'd3-ease';
import PT from 'prop-types';
import React from 'react';
import { animated, Spring } from 'react-spring';
import { qaAttr } from 'utils';
import Button from '../Button';
import { alpha, darken } from '@mui/material';

const StyledContainer = styled(animated.div)(
  ({ theme, variant }) => ({
    position: 'absolute',
    // backgroundColor: addAlpha(theme.palette.primary.light, 0.8),
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 1300,
    background: alpha(
      darken(theme.palette.background.default, 0.15),
      0.7,
    ),
    '& .content': {
      position: 'fixed',
      transform: 'translate(-50%, -50%)',
      background: theme.palette.background.paper,
      // background: addAlpha(theme.palette.background.default, 0.9),
      left: '50%',
      padding: '2rem',
      borderTop: `5px solid ${theme.palette.primary.light}`,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      textAlign: 'center',
      maxWidth: 550,
      minWidth: 300,
      ...(variant === 1 && {
        padding: '21px 26px 12px',
        borderRadius: 10,
        borderTop: 'none',
      }),
    },
    '& .title': {
      ...(variant === 1 && {
        paddingBottom: 14,
        fontSize: 21,
        textAlign: 'left',
        color: theme.palette.text.primary,
      }),
    },
    '& .subTitle': {
      paddingTop: 10,
      ...(variant === 1 && {
        padding: 0,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '25px',
        textAlign: 'left',
        letterSpacing: 0.19,
      }),
    },
    '& .actions': {
      display: 'flex',
      marginTop: '2.5rem',
      justifyContent: 'center',
      ...(variant === 1 && {
        marginTop: 27,
        justifyContent: 'flex-end',
      }),
    },
  }),
);

const SimpleAlert = (props) => {
  const {
    className,
    title,
    subtitle = '',
    onSuccess = () => null,
    onCancel,
    isOpen,
    setSimpleAlert = () => {},
    variant,
    modalContainerStyles = {},
    modalStyles = {},
    subTitleStyles = {},
    actionsStyles = {},
    showCancel,
  } = props;

  return isOpen ? (
    <Spring
      from={{
        top: '60%',
        boxShadow: 4,
        opacity: 0,
      }}
      to={{
        top: '45%',
        boxShadow: 80,
        opacity: 1,
      }}
      config={{
        duration: 500,
        easing: easePolyOut.exponent(2.0),
      }}
    >
      {(styles) => (
        <StyledContainer
          className={className}
          variant={variant}
          style={{
            opacity: styles.opacity,
            ...modalContainerStyles,
          }}
        >
          <animated.div
            className="content"
            style={{
              top: styles.top,
              opacity: styles.opacity,
              boxShadow: `0 0 ${styles.boxShadow}px rgb(0 0 0 / 25%)`,
              ...modalStyles,
            }}
          >
            <h1
              className="title"
              {...qaAttr('simple-alert-title')}
            >
              {title}
            </h1>
            <p
              className="subTitle"
              style={{ ...subTitleStyles }}
            >
              {subtitle}
            </p>
            <div
              className="actions"
              style={{ ...actionsStyles }}
            >
              {showCancel && (
                <Button
                  onClick={
                    !onCancel
                      ? () => setSimpleAlert({ isOpen: false })
                      : onCancel
                  }
                  variant="filled-secondary"
                  sx={{ width: 130, height: 44 }}
                  testID="simple-alert-cancel-button"
                >
                  Cancel
                </Button>
              )}
              <div style={{ marginLeft: 10 }}>
                <Button
                  onClick={() => {
                    onSuccess();
                    setSimpleAlert({ isOpen: false });
                  }}
                  variant="filled-secondary"
                  sx={{ width: 130, height: 44 }}
                  testID="simple-alert-ok-button"
                >
                  OK
                </Button>
              </div>
            </div>
          </animated.div>
        </StyledContainer>
      )}
    </Spring>
  ) : null;
};

SimpleAlert.propTypes = {
  className: PT.string,
  title: PT.string,
  subtitle: PT.string,
  onSuccess: PT.func,
  onCancel: PT.func,
  isOpen: PT.bool.isRequired,
  setSimpleAlert: PT.func,
  variant: PT.oneOf([0, 1]),
  modalContainerStyles: PT.objectOf(PT.any),
  modalStyles: PT.objectOf(PT.any),
  titleStyles: PT.objectOf(PT.any),
  subTitleStyles: PT.objectOf(PT.any),
  actionsStyles: PT.objectOf(PT.any),
  showCancel: PT.bool,
};

SimpleAlert.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  onSuccess: () => null,
  onCancel: () => null,
  setSimpleAlert: () => {},
  variant: 0,
  modalContainerStyles: {},
  modalStyles: {},
  titleStyles: {},
  subTitleStyles: {},
  actionsStyles: {},
  showCancel: true,
};

export default SimpleAlert;
