import { IEmployeeProfile } from '../../../api/types/queries/employeeProfile';
import { ISectionLayout } from '../../Profile/SectionLayout';
import ProfileLogic from './ProfileLogic';
import ProfileRenderer from './ProfileRenderer';

export interface IEmployeeProfileProps {
  fetchEmployeeProfile: () => void;
  profile: IEmployeeProfile;
  sectionLayoutProps: ISectionLayout;
  setExpandedSection: () => void;
}

const Profile = (props: IEmployeeProfileProps) => {
  return (
    <ProfileLogic
      {...props}
      render={(logicProps) => (
        <ProfileRenderer {...logicProps} />
      )}
    />
  );
};

export default Profile;
