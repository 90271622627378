import { NavigateFunction } from 'react-router-dom';
import {
  HealthSynopsis,
  PathByLocationsAPIResponse,
  PulseByLocationsAPIResponse,
} from './api.types';

export enum InteractionTypes {
  survey = 'survey',
  custom = 'custom',
  review = 'review',
  anniversary = 'anniversary',
}

export enum InteractionNames {
  RETENTION_WORK_ANNIVERSARY = "RETENTION_WORK_ANNIVERSARY",
  RETENTION_SURVEY = "RETENTION_SURVEY",
  RETENTION_REVIEW = "RETENTION_REVIEW",
  RETENTION_CUSTOM = "RETENTION_CUSTOM",
  ACCOUNT_VERIFY = "ACCOUNT_VERIFY",
  HIRED_ANNIVERSARY = "HIRED_ANNIVERSARY",
  BIRTHDAY = "BIRTHDAY",
  HIRED_30_DAYS = "HIRED_30_DAYS",
  HIRED_60_DAYS = "HIRED_60_DAYS",
  HIRED_90_DAYS = "HIRED_90_DAYS",
}

export const interactionNames = {
  RETENTION_WORK_ANNIVERSARY: InteractionNames.RETENTION_WORK_ANNIVERSARY,
  RETENTION_SURVEY: InteractionNames.RETENTION_SURVEY,
  RETENTION_REVIEW: InteractionNames.RETENTION_REVIEW,
  RETENTION_CUSTOM: InteractionNames.RETENTION_CUSTOM,
  ACCOUNT_VERIFY: InteractionNames.ACCOUNT_VERIFY,
  HIRED_ANNIVERSARY: InteractionNames.HIRED_ANNIVERSARY,
  BIRTHDAY: InteractionNames.BIRTHDAY,
  HIRED_30_DAYS: InteractionNames.HIRED_30_DAYS,
  HIRED_60_DAYS: InteractionNames.HIRED_60_DAYS,
  HIRED_90_DAYS: InteractionNames.HIRED_90_DAYS,
}

export const interactionTypes = {
  survey: InteractionTypes.survey,
  custom: InteractionTypes.custom,
  review: InteractionTypes.review,
  anniversary: InteractionTypes.anniversary,
};

export enum RetentionPages {
  pulse = 'pulse',
  path = 'path',
  health = 'health',
  workforce = 'workforce',
  surveys = 'surveys',
  pathmanager = 'pathmanager',
}

// NOTE: whenever updating this, we will need to update RetentionBreadcrumbs
// if the corresponding page does not exist on brand or location.
export const retentionPages = {
  pulse: RetentionPages.pulse,
  path: RetentionPages.path,
  pathmanager: RetentionPages.pathmanager,
  health: RetentionPages.health,
  workforce: RetentionPages.workforce,
  survey: RetentionPages.surveys,
};

export enum DashboardLevel {
  retention = 'retention',
  brand = 'brand',
  location = 'location',
}

export const dashboardLevels = {
  retention: DashboardLevel.retention,
  brand: DashboardLevel.brand,
  location: DashboardLevel.location,
};

export interface IRetentionContext {
  setRetentionContext: (ctx) => void;
  retentionPages;
  dashboardLevels;
  interactionTypes;
  interactionNames;
  organizationId?: number;
  organizationName?: string;
  accessOrganization?: boolean;
  brandId?: number;
  brandName?: string;
  accessBrand?: boolean;
  locationId?: number;
  locationName?: string;
  accessLocation?: boolean;
}

export interface IEmployerBrandRenderArgs {
  cardsData:
    | PulseByLocationsAPIResponse
    | PathByLocationsAPIResponse
    | HealthSynopsis[]
    | null;
  navigate: NavigateFunction;
  locationRoute: string;
}

export interface IEmployerBrandDashboard {
  title: string;
  data:
    | PulseByLocationsAPIResponse
    | PathByLocationsAPIResponse
    | HealthSynopsis[];
  loading: boolean;
  render: (a: IEmployerBrandRenderArgs) => JSX.Element[];
}

export interface ISurveyQuestionAnswer {
  answer: string;
}

export interface ISurveyQuestion {
  question: string;
  questionType: 'MULTIPLE_CHOICE' | 'TEXT';
  answers: ISurveyQuestionAnswer[];
}

export interface ISurveyRecipients {
  recipientIds: number[];
}

export interface ISurvey {
  id?: number;
  name: string;
  recipients: ISurveyRecipients;
  questions: ISurveyQuestion[];
}

export interface ISurveyContext {
  survey: ISurvey;
  setSurvey: React.Dispatch<React.SetStateAction<ISurvey>>;
}
