import React, { useContext } from 'react';
import { MdCheck, MdClose, MdLink, MdStarBorder, MdTouchApp, MdWork } from 'components/icons';
import { Spinner } from 'components/shared';
import EmployerGrowContext from '../../EmployerGrowContext';

const JobPostingScores = () => {
  const { isMainDataLoading, jobPostingScores = {} } = useContext(EmployerGrowContext);
  const {
    openedJobs = 0,
    totalApplicants = 0,
    starredJobs = 0,
    rejectedJobs = 0,
    hiredCount = 0,
    interviewCount = 0
  } = jobPostingScores;

  return (
    <div className="jobPostingScoresContainer">
      {isMainDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle jobPostingScoresTitle">Job Posting Scores</h4>
      <div className="jobPostingScoresContent">
        <div className="jobPostingScoresRow">
          <MdWork color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Open Jobs</div>
          <div className="jobPostingScoresValue">{openedJobs}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdCheck color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Total Applicants</div>
          <div className="jobPostingScoresValue">{totalApplicants}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdStarBorder color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Applicants Starred</div>
          <div className="jobPostingScoresValue">{starredJobs}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdClose color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Applicants Rejected</div>
          <div className="jobPostingScoresValue">{rejectedJobs}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdTouchApp color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Scheduled Interviews</div>
          <div className="jobPostingScoresValue">{interviewCount}</div>
        </div>
        <div className="jobPostingScoresRow">
          <MdLink color="primary" className="jobPostingScoresIcon" />
          <div className="jobPostingScoresLabel">Total Hires</div>
          <div className="jobPostingScoresValue">{hiredCount}</div>
        </div>
      </div>
    </div>
  );
}

JobPostingScores.propTypes = {};

export default JobPostingScores;
