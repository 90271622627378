import React from 'react';
import { Box, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { MdChevronRight } from 'components/icons';
import { getRoutes } from 'utils';
import { useEmployerProfileQuery } from 'hooks';
import defaultEmployerImg from 'assets/img/employer_default.png';

const StyledRoot = styled(Button)(({ theme }) => ({
  '&.container': {
    marginBottom: 15,
    padding: '15px 18px',
    borderTop: '1px solid #E5E5E5',
    borderBottom: '1px solid #E5E5E5',
    display: 'flex',
    borderRadius: 0
  },
  '& .companyLogo': {
    minWidth: 48,
    height: 48,
    marginRight: 24,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '50%',
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: 50,
      height: 50,
      marginRight: 16
    }
  },
  '& .companyName': {
    marginBottom: 12,
    lineHeight: '24px',
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 8,
      lineHeight: '18px',
      fontSize: 16,
      color: theme.palette.common.white
    }
  },
  '& .plan': {
    lineHeight: '17px',
    fontSize: 14,
    color: theme.palette.common.white
  },
  '& .plan__trial': {
    marginLeft: 12,
    opacity: 0.5
  }
}));

const ROUTES = getRoutes();

const ShortCompanyCard = () => {
  const { profile, loading, subscriptionInfo } = useEmployerProfileQuery({
    fetchPolicy: 'cache-and-network'
  });
  const { name, imageUrl } = profile || {};
  const { trialStatus, planName } = subscriptionInfo || {};

  return (
    <StyledRoot
      isRouterLink
      to={ROUTES.employer.plans}
      disableRipple
      className="container"
      testID="upgrade-plan-button"
    >
      <img className="companyLogo" src={imageUrl || defaultEmployerImg} alt="" />
      {!loading ? (
        <>
          <Box flex={1}>
            <div className="companyName">{name}</div>
            <div className="plan">
              <span>{`Plan: ${planName}`}</span>
              {trialStatus && <span className="plan__trial">{trialStatus}</span>}
            </div>
          </Box>
          <MdChevronRight color="primary" style={{ marginLeft: 'auto', marginRight: 0 }} />
        </>
      ) : (
        <Box flex={1}>
          <Spinner size={30} />
        </Box>
      )}
    </StyledRoot>
  );
}

export default ShortCompanyCard;
