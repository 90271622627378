import { gql } from '@apollo/client';

export const GET_COMMUNICATION_TEMPLATES = gql`
  query GetCommunicationTemplates($templateName: String) {
    getCommunicationTemplates(templateName: $templateName) {
      active
      createdAt
      id
      messageType
      name
      template
      updatedAt
    }
  }
`;

export const SEND_TEMPLATE_TEXT = gql`
  query SendTemplateText(
    $templateId: Int!
    $message: String
    $recipientIds: [Int]!
  ) {
    sendTemplateText(
      templateId: $templateId
      message: $message
      recipientIds: $recipientIds
    ) {
      errors {
        name
        profileId
        reason
      }
      success
    }
  }
`;

export const SEND_REVIEW_TEXT = gql`
  query SendReviewText(
    $employeeIdToReview: Int!
    $recipientIds: [Int]!
    $locationId: Int!
  ) {
    sendReviewText(
      employeeIdToReview: $employeeIdToReview
      recipientIds: $recipientIds
      locationId: $locationId
    ) {
      errors {
        name
        profileId
        reason
      }
      success
    }
  }
`;
