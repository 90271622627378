import React, { useContext, useEffect, useRef, useState} from 'react';
import PT from 'prop-types';
import { ArcElement, Chart, DoughnutController, Legend, Tooltip, Title } from 'chart.js';
import clsx from 'clsx';
import { get } from 'lodash';
import { Box, styled } from 'components';
import { Spinner } from 'components/shared';
import { CHART_COLORS } from './util';
import EmployerGrowContext from '../../EmployerGrowContext';

Chart.register(ArcElement, DoughnutController, Legend, Tooltip, Title);

const StyledRoot = styled('div')(() => ({
  '& .companyBreakdown': {
    width: '100%',
    display: 'grid',
    gridTemplateRows: '40px auto',
    gridTemplateColumns: '100%'
  },
  '& .companyBreakdown__content': {
    flex: 1,
    width: '100%',
    paddingTop: 20,
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'center',
    position: 'relative',
    gridColumnGap: 25,
    '& .doubleCol': {
      gridTemplateColumns: '51% 100px'
    }
  },
  '& .companyBreakdownChartPosition': { position: 'relative' },
  '& .companyBreakdownChartPercContainer': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  '& .companyBreakdownPercText1': { fontSize: 35 },
  '& .companyBreakdownPercText2': { fontSize: 14 },
  '& .companyBreakdownPercText3': { fontSize: 12 },
  '& .companyBreakdownLegendContainer': { alignSelf: 'center', justifySelf: 'flex-end' },
  '& .companyBreakdownLabelParent': { display: 'flex', alignItems: 'center' },
  '& .companyBreakdownLegendColor': { minHeight: 10, minWidth: 10, marginRight: 10 }
}));

const formatData = (labels, data) => ({
  labels,
  datasets: [
    {
      label: '# of Employees',
      data,
      borderWidth: 3,
      borderRadius: 20,
      backgroundColor: CHART_COLORS
    }
  ]
});

const CompanyBreakdown = ({ showLegend, chartLabels, chartData, onLegendReady }) => {
  const { isMainDataLoading } = useContext(EmployerGrowContext);
  const [chartActiveData, setChartActiveData] = useState(null);
  const chartCtxRef = useRef(null);
  const chartRef = useRef(null);

  const handleHover = (context) => {
    const tooltipModel = context.tooltip;
    const [label, value] = get(tooltipModel, 'body[0].lines[0]', '').split(':');
    if (label && value) {
      const percent = ((Number(value) / chartData.reduce((acc, cur) => acc + cur)) * 100).toFixed();
      setChartActiveData({ label, value, percent });
    }
  };

  useEffect(() => {
    if (chartCtxRef.current && chartData) {
      if (chartRef.current?.destroy) chartRef.current.destroy();

      chartRef.current = new Chart(chartCtxRef.current, {
        type: 'doughnut',
        data: {
          ...formatData(chartLabels, chartData)
        },
        options: {
          responsive: true,
          // maintainAspectRatio: false,
          cutout: '82%',
          plugins: {
            legend: {
              display: false,
              position: 'right',
              labels: {
                boxHeight: 10,
                boxWidth: 10,
                font: {
                  size: 12
                }
              }
            },
            tooltip: {
              enabled: false,
              external: handleHover
            }
          }
        }
      });

      const { legendItems } = chartRef.current?.legend || {};

      if (legendItems) {
        const formattedLegend = legendItems.map((o) => ({
          backgroundColor: o.fillStyle,
          label: o.text
        }));
        onLegendReady(formattedLegend);
      }
    }
  }, [chartCtxRef.current, chartData]);

  return (
    <StyledRoot className="companyBreakdown chartCard">
      {isMainDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Company Breakdown</h4>
      <div className={clsx('companyBreakdown__content', showLegend && 'doubleCol')}>
        <Box position="relative">
          {chartActiveData && (
            <div className="companyBreakdownChartPercContainer">
              <span className="companyBreakdownPercText1">
                {chartActiveData.percent}
                <sup className="companyBreakdownPercText2">%</sup>
              </span>
              <span className="companyBreakdownPercText3">
                {chartActiveData.value} {chartActiveData.label}
              </span>
            </div>
          )}
          <canvas id="breakDown-chart" height={175} width={175} ref={chartCtxRef} />
        </Box>
      </div>
    </StyledRoot>
  );
}

CompanyBreakdown.propTypes = {
  showLegend: PT.bool
};

CompanyBreakdown.defaultProps = {
  showLegend: true
};

export default CompanyBreakdown;
