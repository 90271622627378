import React from 'react';
import { Box } from 'components';
import { Button } from '../../../shared';

interface IMainSectionContainer {
  children: JSX.Element;
  classes?: {
    container?: string;
    sideNav?: string;
    content?: string;
    contentChild?: string;
  };
}
const MainSectionContainer = ({
  sideNavItems = [],
  children,
  classes,
}: IMainSectionContainer) => (
    <Box
      className={`main-section-container ${classes?.container}`}
    >
      <Box
        className={`main-section-side-nav ${classes?.sideNav}`}
      >
        {sideNavItems.map(({ action, icon: Icon }) => (
          <Button onClick={action}>
            <Icon />
          </Button>
        ))}
      </Box>
      <Box
        className={`main-section-content ${classes?.content}`}
      >
        <Box className={classes?.contentChild}>{children}</Box>
      </Box>
    </Box>
  );

export default MainSectionContainer;
