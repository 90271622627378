import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from 'components';
import { IconButton } from 'components/shared';
import { MdClose } from 'components/icons';
import ChartTitle from 'components/Grow/employer/teamStructure/ChartTitle';
import EmployeeCard from './EmployeeCard';
import EmployerGrowContext from '../../EmployerGrowContext';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.chartColumn': {
    height: '100%'
  },
  '& .chartColumn__name': {
    padding: '16px 0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .chartColumn__deleteBtn': {},
  '& .chartColumn__title-input': {
    textAlign: 'center',
    '&.Mui-disabled': {
      '-webkit-text-fill-color': `${theme.palette.primary.light} !important`
    },
    '&.edit': {
      textAlign: 'left'
    }
  }
}));

const TeamChartColumn = ({ name, employeeProfiles, index, id }) => {
  const {
    isEditable,
    deleteColumn,
    updateEmployerCtx,
    columnsToUpdate = {},
    currentChartIdx,
    employerCharts
  } = useContext(EmployerGrowContext);

  const handleOnBlur = (columnIdx, columnId) => (inputValue) => {
    updateEmployerCtx({
      columnsToUpdate: {
        ...columnsToUpdate,
        [columnId]: { name: inputValue, index: columnIdx, id: columnId }
      }
    });
  };

  return (
    <StyledRoot className="chartColumn">
      <div className="chartColumn__name">
        <ChartTitle
          titleValue={name}
          variant={isEditable ? 'textfield' : 'text'}
          htmlInputClassName={clsx('chartColumn__title-input', isEditable && 'edit')}
          endAdornment={
            isEditable && (
              <IconButton
                color="primary"
                className="chartColumn__deleteBtn"
                onClick={deleteColumn(index, currentChartIdx, employerCharts)}
                testID="chart-col-delete-col-button"
              >
                <MdClose />
              </IconButton>
            )
          }
          handleOnBlur={handleOnBlur(index, id)}
          testID="chart-col-input"
        />
      </div>
      {employeeProfiles &&
        employeeProfiles.map((employeeProfile, idx) => (
          <EmployeeCard
            employeeProfile={employeeProfile}
            index={idx}
            id={employeeProfile.id}
            key={employeeProfile.id}
            columnIndex={index}
          />
        ))}
    </StyledRoot>
  );
}

TeamChartColumn.propTypes = {
  name: PropTypes.string,
  employeeProfiles: PropTypes.arrayOf(PropTypes.any),
  index: PropTypes.number,
  id: PropTypes.number
};

TeamChartColumn.defaultProps = {
  name: '',
  employeeProfiles: [],
  index: 0,
  id: 0
};

export default TeamChartColumn;
