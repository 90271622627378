import { gql } from '@apollo/client';

export const EMPLOYEE_REVIEW_BY_CODE = gql`
  query EmployeeReviewByCode($code: String!) {
    employeeReviewByCode(code: $code) {
      reviewId
      reviewer {
        profile_id
        name
        imageUrl
      }
      reviewee {
        profile_id
        name
        imageUrl
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_REVIEW = gql`
  mutation UpdateEmployeeReviewEmployee(
    $reviewId: Int
    $rating: Int
    $ratingReasonId: Int
  ) {
    updateEmployeeReviewEmployee(
      reviewId: $reviewId
      rating: $rating
      ratingReasonId: $ratingReasonId
    ) {
      reviewId
    }
  }
`;
