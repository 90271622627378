import React from 'react';
import { Box } from 'components';

const EmptyApplicants = () => (
    <Box textAlign="center">
      <Box mb="12px" className="emptyApplicants__title">
        No New Applicants
      </Box>
    </Box>
  )

EmptyApplicants.propTypes = {};

export default EmptyApplicants;
