import { gql } from '@apollo/client';

export const GET_LINK_BY_CODE = gql`
  query GetLinkByCode($code: String) {
    getLinkByCode(code: $code) {
      code
      createdAt
      deletedAt
      id
      link
      updatedAt
    }
  }
`;
