import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Button, CheckBoxGroup, Spinner } from 'components/shared';
import { useForm } from 'components/form';
import { GET_SKILLS, POST_EMPLOYEE_SKILLS } from 'api';
import styles from 'styles/Onboarding';
import PrivacyClauseButton from './PrivacyClauseButton';

const NAME = 'skills';

const RequirementText = styled('p')(() => ({
  marginBottom: 24,
  textAlign: 'center',
  fontSize: 14,
  color: 'rgba(44,44,44,0.5)'
}));
const StyledBox = styled(Box)(styles);

const UserSkillsForm = ({ form, onChange, onGoToNextStep }) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [fetchSkills, { loading: skillsLoading = true, data: skillsData }] = useLazyQuery(
    GET_SKILLS,
    {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all'
    }
  );

  const [postUserSkills, { loading }] = useMutation(POST_EMPLOYEE_SKILLS);
  const { attrs, set, getError, withValidation } = useForm({
    initial: { [NAME]: form[NAME] },
    validations: {
      [NAME]: [
        'presence',
        (value) => {
          if (!value) return;
          if (filter(value, (o) => o.checked).length < 2) {
            // eslint-disable-next-line consistent-return
            return 'Please, select at least 2';
          }
        }
      ]
    }
  });

  // @todo: check if id is necessary here
  // @todo: add id for custom skills
  const [options, setOptions] = useState(() => []);

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    if (skillsData?.getSkills) setOptions(skillsData.getSkills || []);
  }, [JSON.stringify(skillsData)]);

  const handleChange = (data, checked) => {
    const prevState = { ...attrs[NAME] };
    if (!checked) prevState[data.name] = { ...prevState[data.name], checked: false };
    else prevState[data.name] = { skill_id: data.skill_id, name: data.name, checked: true };

    set(NAME, prevState);
    onChange({ ...form, [NAME]: prevState });
  };

  const next = withValidation(async (validAttrs) => {
    const postData = filter(
      validAttrs[NAME],
      (o) => o.checked,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ).map(({ checked, ...rest }) => ({
      ...rest,
    }));
    await postUserSkills({ variables: { [NAME]: postData } });
    onGoToNextStep();
  });

  return (
    <StyledBox>
      <div className="pageContent">
        <RequirementText>Select at least 2</RequirementText>
        {skillsLoading ? (
          <Box py="10px" display="flex" justifyContent="center">
            <Spinner size={48} />
          </Box>
        ) : (
          <CheckBoxGroup
            data={map(options, (opt) => ({
              ...opt,
              label: opt.name,
              value: opt.name,
              checked: form[NAME][opt.name]?.checked || false
            }))}
            aria-label="skills"
            disabled={loading}
            error={getError(NAME)}
            withHelperText
            sx={{
              width: '100%',
              minHeight: 150,
              maxHeight: 300,
              marginBottom: '16px',
              flex: 1,
              overflow: 'auto'
            }}
            checkboxesContainerProps={{
              sx: { overflow: 'auto' }
            }}
            analyticParams={{
              key: 'Employee skills focused (onboarding)',
              trigger: 'focus'
            }}
            onChange={handleChange}
          />
        )}
        <div style={{ maxWidth: 320 }}>
          <p className="primaryText" style={{ marginBottom: 22, fontStyle: 'italic' }}>
            You got this!
          </p>
          <p className="secondaryText" style={{ marginBottom: 16 }}>
            Your skills help WT give you customized results
          </p>
        </div>
        <Button
          variant="filled-primary"
          endIcon={loading && <Spinner size={24} />}
          disabled={loading}
          className="nextBtn"
          sx={{ mb: isDesktop ? '25px' : '13px' }}
          onClick={next}
          testID="onboarding-next-button"
        >
          Next
        </Button>
        <PrivacyClauseButton />
      </div>
    </StyledBox>
  );
}

UserSkillsForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default UserSkillsForm;
