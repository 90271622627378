import React from 'react';
import { styled } from 'components';
import { FAQ_URL } from 'utils';
import { WorkTorchLogo } from 'assets/workTorchLogo';
import Button from '../Button';

const { LANDING_ORIGIN } = process.env;

const Root = styled('nav')(({ theme }) => ({
  marginBottom: 61,
  padding: '10px 70px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('desktopApp')]: {
    display: 'none'
  },
  '& .companyName': {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: -0.08,
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.palette.common.white,
    height: 40,
    width: 140
  },
  '& .navBar__menu': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .navBar__menuItem': {
    '&:not(:first-of-type)': {
      marginLeft: 24
    },
    '&.btn': {
      fontSize: 14,
      lineHeight: '17px'
    },
    '&.link': {
      opacity: 0.5,
      color: theme.palette.grey['800'],
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '17px',
      '&.primary': {
        opacity: 1,
        color: theme.palette.common.white
      }
    }
  }
}));

const LandingHeader = () => (
    <Root>
      <a href={LANDING_ORIGIN} className="companyName">
        <WorkTorchLogo />
      </a>
      <div className="navBar__menu">
        {/* <a href="#" className={`${classes.navBar__menuItem} ${classes.link}`}>About</a> */}
        {/* <a href="#" className={`${classes.navBar__menuItem} ${classes.link}`}>How it Works?</a> */}
        {/* <a href="#" className={`${classes.navBar__menuItem} ${classes.link}`}>Employers</a> */}
        {/* <a href="#" className="navBar__menuItem link primary">
          Login
        </a> */}
        <Button
          href={FAQ_URL}
          variant="filled-secondary"
          width={90}
          height={44}
          className="navBar__menuItem btn faq"
        >
          FAQs
        </Button>
        <Button
          href="#"
          variant="filled-secondary"
          width={130}
          height={44}
          className="navBar__menuItem btn"
        >
          Get Started
        </Button>
      </div>
    </Root>
  )

export default LandingHeader;
