import React from 'react';
import { SvgIcon } from 'components';

const GrowSalaryIcon = ({ fill, ...props }) => (
    <SvgIcon viewBox="0 0 29.5 31.5" {...props}>
      <g>
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || '#EFB954'}
          points="11.1,21.2 11.1,15.6 5.5,21.2 5.5,15.4 13.1,7.8
		20.8,15.4 20.8,21.2 15.2,15.6 15.2,21.2 	"
        />
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || '#F7EEEF'}
          points="22.2,21.2 22.2,9.1 26.3,13.1 26.3,21.2 	"
        />
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || '#2D2B6F'}
          points="13.1,0 5.5,7.6 5.5,13.4 13.1,5.8 20.8,13.4 20.8,7.6
		"
        />
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || '#F7EEEF'}
          points="4.1,21.2 4.1,9.1 0,13.1 0,21.2 	"
        />
      </g>
    </SvgIcon>
  )

export default GrowSalaryIcon;
