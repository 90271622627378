import { GET_ABILITIES_BADGES, GET_SKILLS_BADGES } from 'api';
import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  IAbilitiesBadgeDataType,
  ISkillsBadgeDataType,
} from '../../components/Retention/components/Location/Path/types';

interface IQueryVariables {
  orderBy?: string;
  direction?: string;
  limit: number;
  offset?: number;
  phrase?: string;
}

export default function useBadgesQuery({
  abilityBadgeVars = {},
  skillBadgeVars = {},
}: {
  abilityBadgeVars: IQueryVariables;
  skillBadgeVars: IQueryVariables;
}) {
  const [
    fetchAbilityBadges,
    { data: abilityBadgeData, loading: abilitiesBadgesLoading },
  ] = useLazyQuery(GET_ABILITIES_BADGES, {
    variables: abilityBadgeVars,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const abilityMemoization: IAbilitiesBadgeDataType[] = useMemo(
    () => abilityBadgeData?.getAbilitiesBadges?.results || [],
    [abilityBadgeData?.getAbilitiesBadges?.results],
  );

  const [
    fetchSkillsBadges,
    { data: skillsBadgeData, loading: skillsBadgesLoading },
  ] = useLazyQuery(GET_SKILLS_BADGES, {
    variables: skillBadgeVars,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const skillsMemoization: ISkillsBadgeDataType[] = useMemo(
    () => skillsBadgeData?.getSkillsBadges?.results || [],
    [skillsBadgeData?.getSkillsBadges?.results],
  );

  return useMemo(
    () => ({
      fetchAbilityBadges,
      abilitiesBadges: abilityMemoization,
      abilitiesBadgesLoading,
      fetchSkillsBadges,
      skillsBadges: skillsMemoization,
      skillsBadgesLoading,
    }),
    [
      fetchAbilityBadges,
      abilityMemoization,
      abilitiesBadgesLoading,
      fetchSkillsBadges,
      skillsMemoization,
      skillsBadgesLoading,
    ],
  );
}
