export const memberOverviewStyles = ({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  '& .employeeCardWrapper': {
    width: `100%`,
    display: 'grid',
    gridTemplateColumns: `repeat(4, 1fr)`,
    gridTemplateRow: `repeat(4, 1fr)`,
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      '& > div': {
        flexBasis: `calc(50% - ${theme.spacing(1)})`,
      },
    },
  },
  '& .paginationBar': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
});
