import {
  alpha,
  darken,
  emphasize,
  getContrastRatio,
  lighten,
  PaletteOptions,
} from '@mui/material';
import { addAlpha, reverseObject } from './utils';


interface ICustomPalette {
  primary: string;
  // calculates info
  secondary: string;
  tertiary: string;
  quaternary: string;
  anomaly: string;
  error: string;
  // binary or trinary values. no calculation
  warning: string;
  // binary or trinary values. no calculation
  success: string;
  // binary or trinary values. no calculation
  // info: '#9972ec',
  background: string;
  // calculates greys
  // calculates divider
  // calculates secondary
  text: {
    primary: string;
    secondary: string;
    disabled: string;
    contrast: string;
  };
  // form: {};
  // modal: {};
  // action: {};
}

export const paletteDarkBase: ICustomPalette = {
  primary: '#9972ec',
  // calculates info
  secondary: '#121027',
  tertiary: '#FF743E',
  quaternary: '#FFC35A',
  anomaly: '#FF157F',
  error: '#FF157F',
  // binary or trinary values. no calculation
  warning: '#FF743E',
  // binary or trinary values. no calculation
  success: '#2bff73',
  // binary or trinary values. no calculation
  // info: '#9972ec',
  background: '#11112c',
  // calculates greys
  // calculates divider
  // calculates secondary
  text: {
    primary: '#FAFAFA',
    secondary: lighten('#9972ec', 0.35),
    disabled: alpha('#ffffff', 0.5),
    contrast: '#FF743E',
  },
  // form: {},
  // modal: {},
  // action: {},
};

export const paletteLightBase: ICustomPalette = {
  primary: '#7247ce',
  // calculates info
  secondary: '#9972ec',
  tertiary: '#FF743E',
  quaternary: '#FFC35A',
  anomaly: '#FF157F',
  error: '#FF157F',
  // binary or trinary values. no calculation
  warning: '#FF743E',
  // binary or trinary values. no calculation
  success: '#2bff73',
  // binary or trinary values. no calculation
  // info: '#9972ec',
  background: '#fff',
  // calculates greys
  // calculates divider
  // calculates secondary
  text: {
    primary: '#1e1e1e',
    secondary: '#9972ec',
    disabled: alpha('#1e1e1e', 0.5),
    contrast: '#FF743E',
  },
  // form: {},
  // modal: {},
  // action: {},
};

export interface ICustomButtonPalette {
  main: string;
  contrast: string;
  borderColor: string;
  contrastBorderColor: string;
  disabled: string;
  active: string;
  hover: string;
  hoverText: string;
  selected: string;
  text: string;
  textSelected: string;
  textDisabled: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    quaternary: Palette['primary'];
    anomaly: Palette['primary'];
    button: {
      primary: ICustomButtonPalette;
      secondary: ICustomButtonPalette;
      tertiary: ICustomButtonPalette;
      quaternary: ICustomButtonPalette;
    };
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    quaternary?: PaletteOptions['primary'];
    anomaly?: PaletteOptions['primary'];
    button: {
      primary: ICustomButtonPalette;
      secondary: ICustomButtonPalette;
      tertiary: ICustomButtonPalette;
      quaternary: ICustomButtonPalette;
    };
  }
}

const getContrastByThemeMode = (
  color: string,
  themeMode: 'dark' | 'light',
  bgGauge: string,
) => {
  if (themeMode === 'dark') {
    return getContrastRatio(color, bgGauge) > 4.5
      ? (coefficient: number = 0.75) =>
          darken(color, coefficient)
      : (coefficient: number = 0.75) =>
          lighten(color, coefficient);
  }

  return getContrastRatio(color, bgGauge) > 4.5
    ? (coefficient: number = 0.95) => lighten(color, coefficient)
    : (coefficient: number = 0.75) => darken(color, coefficient);
};

export const buildCustomTheme = (
  colors: ICustomPalette,
  themeMode: 'dark' | 'light',
): PaletteOptions => {
  const isDarkMode = themeMode === 'dark';
  const bgGauge = isDarkMode ? '#000' : '#fff';

  const greysBase = {
    '50': '#f1f1f1',
    '100': '#ebebeb',
    '200': '#e3e0e0',
    '300': '#c8c6c6',
    '400': '#abaaaa',
    '500': '#8b8b8b',
    '600': '#646464',
    '700': '#3b3b3b',
    '800': '#1e1e1e',
    '900': '#080808',
    A100: '#d9d4fd',
    A200: '#b7b3d6',
    A400: '#575566',
    A700: '#212027',
  };

  const greys = isDarkMode
    ? greysBase
    : reverseObject(greysBase);

  return {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    tertiary: {
      light: lighten(colors.tertiary, 0.5),
      main: colors.tertiary,
      dark: darken(colors.tertiary, 0.25),
      contrastText: getContrastByThemeMode(
        colors.tertiary,
        themeMode,
        bgGauge,
      )(),
    },
    quaternary: {
      light: lighten(colors.quaternary, 0.45),
      main: colors.quaternary,
      dark: darken(colors.quaternary, 0.45),
      contrastText: getContrastByThemeMode(
        colors.tertiary,
        themeMode,
        bgGauge,
      )(),
    },
    anomaly: {
      light: alpha(colors.anomaly, 0.5),
      main: colors.anomaly,
      dark: alpha(colors.anomaly, 0.9),
      contrastText: getContrastByThemeMode(
        colors.anomaly,
        themeMode,
        bgGauge,
      )(),
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
    },
    info: {
      main: colors.primary,
    },
    success: {
      main: colors.success,
    },
    grey: greys,
    common: {
      white: '#fff',
      black: '#11112c',
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
      disabled: colors.text.disabled,
    },
    divider: `rgba(126, 126, 126, 0.15)`,
    action: {
      active: colors.quaternary,
      disabled: addAlpha(colors.primary, 0.5),
      selected: addAlpha(colors.primary, 0.08),
      hover: addAlpha(colors.primary, 0.04),
      hoverOpacity: 0.75,
      selectedOpacity: 0.8,
      disabledOpacity: 0.5,
      // disabledBackground
      // focus
      focusOpacity: 1,
      activatedOpacity: 1,
    },
    background: {
      default: colors.background,
      paper:
        themeMode === 'light'
          ? darken(colors.background, 0.11)
          : lighten(colors.background, 0.07),
    },
    button: {
      primary: {
        main: colors.primary,
        contrast: getContrastByThemeMode(
          colors.primary,
          themeMode,
          bgGauge,
        )(0.25),
        borderColor: lighten(colors.primary, 0.5),
        contrastBorderColor: colors.primary,
        disabled: lighten(colors.primary, 0.17),
        active: emphasize(colors.primary, 0.5),
        hover: emphasize(colors.primary, 0.25),
        hoverText: emphasize(colors.primary, 0.85),
        selected: colors.primary,
        text: getContrastByThemeMode(
          colors.primary,
          themeMode,
          bgGauge,
        )(isDarkMode ? 0.5 : 0.55),
        textSelected: colors.text.primary,
        textDisabled: getContrastByThemeMode(
          colors.primary,
          themeMode,
          bgGauge,
        )(),
      },
      secondary: {
        main: colors.secondary,
        contrast: getContrastByThemeMode(
          colors.secondary,
          themeMode,
          bgGauge,
        )(0.5),
        borderColor: lighten(colors.secondary, 0.25),
        contrastBorderColor: colors.secondary,
        disabled: lighten(colors.secondary, 0.17),
        active: emphasize(colors.secondary, 0.15),
        hover: emphasize(colors.secondary, 0.25),
        hoverText: emphasize(colors.secondary, 0.85),
        selected: emphasize(colors.secondary, 0.15),
        text: getContrastByThemeMode(
          colors.secondary,
          themeMode,
          bgGauge,
        )(0.5),
        textSelected: getContrastByThemeMode(
          colors.secondary,
          themeMode,
          bgGauge,
        )(0.5),
        textDisabled: getContrastByThemeMode(
          colors.secondary,
          themeMode,
          bgGauge,
        )(0.5),
      },
      tertiary: {
        main: colors.tertiary,
        contrast:
          getContrastRatio(colors.tertiary, bgGauge) > 4.5
            ? lighten(colors.tertiary, 0.5)
            : darken(colors.tertiary, 0.5),
        borderColor: lighten(colors.tertiary, 0.25),
        contrastBorderColor: colors.tertiary,
        disabled: lighten(colors.tertiary, 0.17),
        active: emphasize(colors.tertiary, 0.15),
        hover: emphasize(colors.tertiary, 0.25),
        hoverText: emphasize(colors.tertiary, 0.85),
        selected: emphasize(colors.tertiary, 0.15),
        text: getContrastByThemeMode(
          colors.anomaly,
          themeMode,
          bgGauge,
        )(0.75),
        textSelected: getContrastByThemeMode(
          colors.anomaly,
          themeMode,
          bgGauge,
        )(0.75),
        textDisabled: getContrastByThemeMode(
          colors.anomaly,
          themeMode,
          bgGauge,
        )(0.75),
      },
      quaternary: {
        main: colors.quaternary,
        contrast:
          getContrastRatio(colors.quaternary, bgGauge) > 4.5
            ? lighten(colors.quaternary, 0.5)
            : darken(colors.quaternary, 0.5),
        borderColor: lighten(colors.quaternary, 0.25),
        contrastBorderColor: colors.quaternary,
        disabled: lighten(colors.quaternary, 0.17),
        active: emphasize(colors.quaternary, 0.15),
        hover: emphasize(colors.quaternary, 0.25),
        hoverText: emphasize(colors.quaternary, 0.85),
        selected: emphasize(colors.quaternary, 0.15),
        text: getContrastByThemeMode(
          colors.anomaly,
          themeMode,
          bgGauge,
        )(0.75),
        textSelected: getContrastByThemeMode(
          colors.anomaly,
          themeMode,
          bgGauge,
        )(0.85),
        textDisabled: getContrastByThemeMode(
          colors.anomaly,
          themeMode,
          bgGauge,
        )(0.85),
      },
    },
  };
};

const colors = {
  dark: buildCustomTheme(paletteDarkBase, 'dark'),
  light: buildCustomTheme(paletteLightBase, 'light'),
};
export default colors;
