import React from 'react';
import { SvgIcon } from 'components';

const GrowLevel3 = (props) => (
    <SvgIcon viewBox="0 0 470.6 464.1" {...props}>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M226.6,21.5v-5.2h13.8v-2.9c0-0.9-0.8-1.7-1.7-1.7H222v9.8c0,4.7,3.9,8.6,8.6,8.6h15
			c0.9,0,1.7-0.8,1.7-1.7v-2.9h-16.7C228.4,25.5,226.6,23.7,226.6,21.5z"
          />
          <path
            fill="#FFFFFF"
            d="M238.7,4.8h-15c-0.9,0-1.7,0.8-1.7,1.7v2.9h16.7c2.2,0,4,1.8,4,4v5.2h-13.8v2.9c0,0.9,0.8,1.7,1.7,1.7
			h16.7v-9.8C247.3,8.7,243.5,4.8,238.7,4.8z"
          />
        </g>
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          points="253.7,459.3 255.1,459.3 243.4,443.6 240,448
		233.1,438.7 217.7,459.3 229.1,459.3 238.1,447.3 239.3,449 231.6,459.3 243,459.3 245,456.6 247,459.3 248.5,459.3 245.7,455.6
		248.4,452.1 	"
        />
        <g>
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="22.5,234.4 22.5,244.6 27.6,244.6 27.6,239.5
			32.7,239.5 32.7,234.4 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="12.4,239.5 12.4,249.7 22.5,249.7 22.5,244.6
			17.4,244.6 17.4,239.5 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="2.2,234.4 2.2,244.6 7.3,244.6 7.3,239.5 12.4,239.5
			12.4,234.4 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="22.5,224.2 22.5,234.4 12.4,234.4 12.4,229.3
			17.4,229.3 17.4,224.2 		"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M70.2,382.2l13.7-7.9l13.7,7.9v11.9l-4.2,2.4v-2.4l2.1-1.2v-7
		l-4.2,2.4v16.1l-2.1-1.5V394v-4.5l-4.2-2.4v13.4h-2.1v-13.4l-4.2,2.4v3.9v9.5l-2.1,1.5v-16.1l-4.2-2.4v7l2.1,1.2v2.4l-4.2-2.4
		v-11.9H70.2z M95.4,383.4l-11.6-6.7l-11.6,6.7l6.3,3.6l5.3-3l5.3,3L95.4,383.4z"
        />
        <g>
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="90.9,87 79.1,75.2 83.1,68.2 94.9,80 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="68.4,75.4 72.4,68.5 79.1,75.2 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="86.9,93.9 80.2,87.2 90.9,87 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="76.2,94.1 64.4,82.3 68.4,75.4 80.2,87.2 		"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M399,377.3l-1.1,1.9h-15.4l-8.8,15.3h2.2l7.7-13.3h17.6l-1.1,1.9
		h-15.4l-6.6,11.4h2.2l5.4-9.4h17.6l-11.1,19.2h-17.6l1.1-1.9h15.4l9-15.4h-2.2l-7.7,13.3h-17.6l1.1-1.9h15.4l6.6-11.4h-2.2
		l-5.4,9.4h-17.6l11.1-19.2H399V377.3z M391.1,387H387l-4.3,7.5h4.1L391.1,387z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M395.7,89.1c1.1,0.5,2.3,0.8,3.6,0.8c4.5,0,8.1-3.6,8.1-8.1
		s-3.6-8.1-8.1-8.1c-1.2,0-2.3,0.3-3.4,0.7c-1-0.5-2.2-0.7-3.4-0.7c-1.3,0-2.5,0.3-3.6,0.8c-1.1-0.5-2.3-0.8-3.6-0.8
		c-4.5,0-8.1,3.6-8.1,8.1s3.6,8.1,8.1,8.1c1.2,0,2.3-0.3,3.4-0.7c1,0.5,2.2,0.7,3.4,0.7C393.5,89.9,394.7,89.6,395.7,89.1
		L395.7,89.1z M393.5,87.5c-0.4,0.1-0.9,0.2-1.4,0.2c-3.2,0-5.8-2.6-5.8-5.8c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1
		c0,2.2,0.9,4.3,2.4,5.7c-0.3,0.1-0.7,0.1-1,0.1c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8
		C391.3,84,392.1,86.1,393.5,87.5L393.5,87.5z M399.5,83c0.6,0,1.1-0.5,1.1-1.1c0-2.2-0.9-4.3-2.4-5.7c0.3-0.1,0.7-0.1,1-0.1
		c3.2,0,5.8,2.6,5.8,5.8c0,3.2-2.6,5.8-5.8,5.8c-3.2,0-5.8-2.6-5.8-5.8c0-2.2-0.9-4.2-2.3-5.6c0.4-0.1,0.9-0.2,1.4-0.2
		c3.2,0,5.8,2.6,5.8,5.8C398.4,82.5,398.9,83,399.5,83z"
        />
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M451.1,240.2c13.5-9.2,10.2,0.4,17.6,2.4c-3.5,4-10.1,3-12.6-2.1
			c0.3,1.8,1.1,3.3,2.2,4.5c-8.7,5.4-12.3,2.4-16.2-2.1C445.4,243.7,448.4,242.1,451.1,240.2z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M455,235.9c-13.5,9.2-10.2-0.4-17.6-2.4c3.5-4,10.1-3,12.6,2.1
			c-0.3-1.8-1.1-3.3-2.2-4.5c8.7-5.4,12.3-2.4,16.2,2.1C460.7,232.5,457.8,234.1,455,235.9z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M270.2,448.2c0.1,0.5,0.1,1,0.1,1.5c33.3-5.4,64.1-18.4,90.5-37.1c-0.3-0.4-0.7-0.8-1-1.2
			C333.7,429.9,303.2,442.8,270.2,448.2z"
          />
          <path
            fill="#FFFFFF"
            d="M57.1,109.7c-0.4-0.3-0.8-0.6-1.2-1c-19.2,27.4-32.3,59.3-37.3,93.8c0.5,0,1,0,1.5,0
			C25.1,168.5,38.1,136.8,57.1,109.7z"
          />
          <path
            fill="#FFFFFF"
            d="M110.3,411.6c-0.3,0.4-0.7,0.8-1,1.2c26.8,18.9,58,31.9,91.7,37.2c0-0.5,0.1-1,0.1-1.5
			C167.7,443.2,136.8,430.3,110.3,411.6z"
          />
          <path
            fill="#FFFFFF"
            d="M409.3,363.1c0.4,0.3,0.8,0.7,1.2,1c19.7-26.6,33.6-57.8,39.7-91.6c-0.5,0-1-0.1-1.5-0.1
			C442.6,305.9,428.9,336.8,409.3,363.1z"
          />
          <path
            fill="#FFFFFF"
            d="M201.3,19.6c-0.1-0.5-0.1-1-0.2-1.5c-35.9,5.6-69,20-96.9,40.9c0.3,0.4,0.7,0.8,1,1.1
			C132.9,39.4,165.7,25.2,201.3,19.6z"
          />
          <path
            fill="#FFFFFF"
            d="M21.2,271.9c-0.5,0-1,0.1-1.5,0.1c6,34.1,20,65.6,39.9,92.3c0.4-0.4,0.7-0.7,1.1-1
			C41,336.8,27.2,305.7,21.2,271.9z"
          />
          <path
            fill="#FFFFFF"
            d="M449.8,203.3c0.5,0,1-0.1,1.5-0.1c-5-35.1-18.3-67.5-37.9-95.2c-0.4,0.3-0.8,0.7-1.2,1
			C431.7,136.4,444.8,168.5,449.8,203.3z"
          />
          <path
            fill="#FFFFFF"
            d="M363.9,59.6c0.3-0.4,0.7-0.8,1-1.1c-27.4-20.3-59.6-34.4-94.7-40.1c-0.1,0.5-0.1,1-0.2,1.5
			C304.8,25.5,336.8,39.5,363.9,59.6z"
          />
        </g>
      </g>
    </SvgIcon>
  )

export default GrowLevel3;
