import React from 'react';
import { getDisplayName } from 'utils';
import { NotificationsProvider } from 'providers';

export default function withNotifications(WrappedComponent) {
  const WithNotifications = (props) => (
      <NotificationsProvider>
        <WrappedComponent {...props} />
      </NotificationsProvider>
    )
  WithNotifications.displayName = `WithNotifications(${getDisplayName(WrappedComponent)})`;
  return WithNotifications;
}
