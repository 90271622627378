import { gql } from '@apollo/client';

export const GET_CHAT = gql`
  query chat(
    $jobsId: Int!
    $messageTo: Int!
    $messageFrom: Int!
    $role: String!
  ) {
    chat(
      jobsId: $jobsId
      messageTo: $messageTo
      messageFrom: $messageFrom
      role: $role
    ) {
      createdAt
      id
      jobsId
      messageTo
      messageFrom
      message
      readed
      jobs {
        employerProfile {
          name
          imageUrl
        }
      }
      users {
        employeeProfile {
          name
          imageUrl
        }
      }
      usersUserId
    }
  }
`;

export const GET_APPROVED_APPLICANTS_MESSAGES = gql`
  query getApprovedEmployerJobMessages(
    $usersUserId: Int
    $orderBy: String
    $direction: String
    $limit: Int
    $offset: Int
    $phrase: String
  ) {
    getApprovedEmployerJobMessages(
      usersUserId: $usersUserId
      orderBy: $orderBy
      direction: $direction
      limit: $limit
      offset: $offset
      phrase: $phrase
    ) {
      data {
        createdAt
        id
        jobs {
          id
          imageUrl
          title
        }
        message
        messageTo
        messageFrom
        messagesCount
        users {
          employeeProfile {
            imageUrl
            name
            profile_id
            user_id
          }
        }
        updatedAt
        usersUserId
      }
      offset
      pages
      total
    }
  }
`;

export const GET_APPROVED_JOBS_MESSAGES = gql`
  query getApprovedJobMessages($usersUserId: Int!) {
    getApprovedJobMessages(usersUserId: $usersUserId) {
      createdAt
      id
      jobs {
        id
        imageUrl
        title
      }
      message
      messageTo
      messageFrom
      messagesCount
      users {
        employerProfile {
          imageUrl
          name
          profile_id
          user_id
        }
      }
      usersUserId
      updatedAt
    }
  }
`;
