import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Button, IconButton } from 'components/shared';
import { GrowRecentBadgeIcon, MdClose } from 'components/icons';

const StyledRoot = styled(Button)(({ theme }) => ({
  '&.recentBadgeCard': {
    width: '100%',
    // maxWidth: 262,
    padding: 10,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('desktopApp')]: {
      padding: 12,
      display: 'block',
      border: 'none',
      borderRadius: 16,
      backgroundColor: theme.palette.primary.light
    }
  },
  '& .recentBadgeCard__header': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .recentBadgeCard__label': {
    marginBottom: 15,
    padding: '3px 15px',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 50,
    display: 'inline-block',
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('desktopApp')]: {
      margin: 0,
      padding: 0,
      border: 'none',
      fontSize: 12,
      fontWeight: 700,
      color: theme.palette.common.white
    }
  },
  '& .recentBadgeCard__title': {
    marginBottom: 2,
    textAlign: 'left',
    fontSize: 15,
    lineHeight: '16px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      fontWeight: 800,
      color: theme.palette.common.white
    }
  },
  '& .recentBadgeCard__date': {
    fontSize: 10,
    lineHeight: '12px',
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .recentBadgeCard__icon': {
    fontSize: 50,
    color: theme.palette.quaternary.main,
    [theme.breakpoints.down('desktopApp')]: {
      marginRight: 10,
      fontSize: 32
    }
  },
  '& .recentBadgeCard__closeBtn': {
    width: 'auto',
    height: 'auto',
    padding: 0,
    color: theme.palette.primary.contrastText
  }
}));

const RecentBadgeCard = (props) => {
  const { className, title, onClick, onClose, ...rest } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const renderIcon = () => <GrowRecentBadgeIcon className="recentBadgeCard__icon" />;
  const renderLabel = () => <div className="recentBadgeCard__label">Recent Badge 🎉🎉</div>;
  const renderBadgeTitle = () => <div className="recentBadgeCard__title">{title}</div>;

  return (
    <StyledRoot
      className={clsx(className, 'recentBadgeCard')}
      onClick={onClick}
      testID="recent-badge-button"
      {...rest}
    >
      {isDesktop ? (
        <>
          <Box mr="10px">{renderIcon()}</Box>
          <div>
            {renderLabel()}
            {renderBadgeTitle()}
            {/* <div className="recentBadgeCard__date">Completed: 2/25/2022</div> */}
          </div>
        </>
      ) : (
        <>
          <div className="recentBadgeCard__header">
            {renderLabel()}
            <IconButton
              className="recentBadgeCard__closeBtn"
              onClick={(e) => {
                e.stopPropagation();
                onClose(e);
              }}
            >
              <MdClose color="inherit" />
            </IconButton>
          </div>
          <Box display="flex" alignItems="center">
            {renderIcon()}
            {renderBadgeTitle()}
          </Box>
        </>
      )}
    </StyledRoot>
  );
}

RecentBadgeCard.propTypes = {
  className: PT.string,
  title: PT.string.isRequired,
  onClick: PT.func,
  onClose: PT.func
};

RecentBadgeCard.defaultProps = {
  className: '',
  onClick: () => {},
  onClose: () => {}
};

export default RecentBadgeCard;
