import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'components/form';
import { Button, Input, Spinner } from 'components/shared';

const NAME = 'zip';

const ZipCodeForm = ({ form, onChange, onGoToNextStep }) => {
  const loading = false;
  const { $, set } = useForm({
    initial: { [NAME]: form[NAME] },
    validations: {
      [NAME]: ['presence', 'zip']
    }
  });

  const handleFieldChange = (field) => (e) => {
    const val = e.target.value.replace(/[^0-9]/, '');
    set(field, val);
    onChange({ ...form, [field]: val });
  };

  const next = () => {
    onGoToNextStep();
  };

  return (
    <>
      <Input
        id="zipCode"
        {...$(NAME)}
        placeholder="12345"
        autoComplete="off"
        required
        withHelperText
        highlightInputOnError={false}
        disabled={loading}
        sx={{ mb: '20px' }}
        analyticParams={{
          key: 'Employer zip focused (onboarding)',
          trigger: 'focus'
        }}
        onChange={handleFieldChange(NAME)}
        testID="onboarding-zip-input"
      />
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        sx={{ height: 50, width: '100%', mb: '25px' }}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
    </>
  );
}

ZipCodeForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ZipCodeForm;
