import React, { useMemo } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import Typography from '@mui/material/Typography';
import {
  GrowRecentBadgeIcon,
  MdArrowBack,
  MdArrowForward,
} from 'components/icons';
import GrowLevel from './GrowLevel';
import LevelNavigationButton from './LevelNavigationButton';
import {
  LEVELS_CONTAINER_WIDTH,
  LEVELS_CONTAINER_MOB_WIDTH,
} from './utils';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.growLevels': {
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 0,
    },
  },
  '& .growLevels__levelsContainer': {
    width: `${LEVELS_CONTAINER_WIDTH}vw`,
    height: `${LEVELS_CONTAINER_WIDTH}vw`,
    position: 'relative',
    margin: '0 auto',
    [theme.breakpoints.down('desktopApp')]: {
      width: `${LEVELS_CONTAINER_MOB_WIDTH}vw`,
      height: `${LEVELS_CONTAINER_MOB_WIDTH}vw`,
    },
  },
  '& .growLevels__levelContent': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  '& .growLevels__levelContent-levelIcon': {
    transition: 'transform 0.65s ease-in-out',
    transform: 'rotateY(0deg)',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 125,
    color: theme.palette.text.primary,
    '&.rotate': {
      transform: 'rotateY(180deg)',
    },
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 70,
    },
  },
  '& .growLevels__levelContent-level': {
    lineHeight: 1,
    fontSize: 40,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '130%',
    },
  },
  '& .growLevels__levelContent-steps': {
    paddingTop: 5,
    color: theme.palette.text.primary,
    fontSize: 12,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none',
    },
  },
  '& .growLevels__navigationContainer': {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    position: 'relative',
  },
  '& .buttonWrapper': {
    display: 'flex',
    width: '300px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .growLevels__curLevelDetails': {
    margin: '40px auto 0px',
    minHeight: '96px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: 0,
      marginBottom: 40,
    },
  },
  '& .growLevels__curLevelDetails-description': {
    maxWidth: 350,
    textAlign: 'center',
    marginBottom: theme.spacing(2.5),
  },
  '& .growLevels__curLevelDetails-title': {
    maxWidth: 310,
    margin: '0 auto',
    paddingBottom: 10,
    fontWeight: 100,
    fontSize: 30,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 39,
      fontWeight: 900,
      lineHeight: '130%',
      color: theme.palette.text.primary, // @TODO: get from theme (must be grey 2)
    },
  },
  '& .growLevels__curLevelDetails-currentlyTracking': {
    color: theme.palette.primary.light,
    fontSize: 18,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none',
    },
  },
  '& h3': {
    fontSize: '24px',
    letterSpacing: 0.7,
    color: theme.palette.secondary.main,
    marginBottom: '16px',
    paddingTop: '32px',
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  },
  '.growDisclaimer': {
    textAlign: 'center',
    width: 300,
    margin: '0 auto',
    marginTop: 10,
    fontSize: 11,
  },
}));

const GrowLevels = (props) => {
  const { activeLevel, setActiveLevel, levels = [] } = props;
  const displayLevel = activeLevel + 1;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const prevLevel = levels[activeLevel - 1];
  const nextLevel = levels[activeLevel + 1];

  const currentLevel = useMemo(
    () => levels[activeLevel] || {},
    [levels, activeLevel],
  );

  const renderJobTypeName = () =>
    currentLevel.jobTypeName && (
      <div className="growLevels__curLevelDetails">
        {!isDesktop && (
          <h3 className="levelPreTitle">Role {displayLevel}</h3>
        )}
        <h2 className="growLevels__curLevelDetails-title">
          {currentLevel.jobTypeName}
        </h2>
        <Typography
          className={'growLevels__curLevelDetails-description'}
          variant={'body2'}
        >
          {currentLevel.jobTypeDescription}
        </Typography>
      </div>
    );

  return (
    <StyledRoot className="growLevels">
      {isDesktop ? (
        <div className="growLevels__levelsContainer">
          <div className="growLevels__levelContent">
            <div
              className={clsx(
                'growLevels__levelContent-levelIcon',
                activeLevel % 2 === 0 && 'rotate',
              )}
            >
              <GrowRecentBadgeIcon
                fontSize="inherit"
                color="inherit"
              />
            </div>
            {levels.length > 0 && (
              <div className="growLevels__levelContent-level">
                Role {displayLevel}
              </div>
            )}
          </div>
          {levels.map((obj, i) => (
            <GrowLevel
              key={`level__${obj.id}`}
              level={i}
              abilitiesBadges={obj.abilitiesBadges}
              skillsBadges={obj.skillsBadges}
              active={activeLevel === i + 1}
              activeLevel={activeLevel}
            />
          ))}
        </div>
      ) : null}

      {renderJobTypeName()}
      {!!levels.length && (
        <div className="growLevels__navigationContainer">
          <Box className="buttonWrapper">
            {prevLevel ? (
              <LevelNavigationButton
                iconBefore
                icon={<MdArrowBack />}
                level={displayLevel - 1}
                onClick={() => setActiveLevel(activeLevel - 1)}
              />
            ) : (
              <Box visibility="hidden" width={80} height={32} />
            )}
            {nextLevel ? (
              <LevelNavigationButton
                iconBefore={false}
                icon={<MdArrowForward />}
                level={displayLevel + 1}
                onClick={() => setActiveLevel(activeLevel + 1)}
              />
            ) : (
              <LevelNavigationButton
                iconBefore={false}
                icon={<MdArrowForward />}
                title={'Next Steps'}
                onClick={() => console.log('next steps')}
              />
            )}
          </Box>
        </div>
      )}
      <div className={'growDisclaimer'}>
        Completion of a level does not guarantee promotion.
        Please discuss with your employer.
      </div>
    </StyledRoot>
  );
};

GrowLevels.propTypes = {
  activeLevel: PT.number.isRequired,
  setActiveLevel: PT.func.isRequired,
  levels: PT.arrayOf(
    PT.shape({
      active: PT.bool,
      careerPathGroupId: PT.number,
      completed: PT.bool,
      id: PT.number,
      index: PT.number,
      jobTypeId: PT.number,
      jobTypeName: PT.string,
      abilitiesBadges: PT.arrayOf(
        PT.shape({
          abilitiesBadgeId: PT.number,
          abilitiesId: PT.number,
          badgeUrl: PT.string,
          complete: PT.bool,
          name: PT.string,
        }),
      ),
      skillsBadges: PT.arrayOf(
        PT.shape({
          skillsBadgeId: PT.number,
          skillsId: PT.number,
          badgeUrl: PT.string,
          complete: PT.bool,
          name: PT.string,
        }),
      ),
    }),
  ).isRequired,
};

export default GrowLevels;
