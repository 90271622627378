import React from 'react';

export const WorkTorchLogo = (params) => (
  <svg
    className="filledLogo"
    width={187}
    height={48}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 405.91 67.79"
    {...params}
  >
    <g>
      <path d="M267,29.82c-3.01-1.69-6.48-2.51-10.38-2.51s-7.39,.82-10.42,2.51c-3.03,1.68-5.4,4.07-7.08,7.13h0s0,0,0,0c-1.65,3.04-2.45,6.58-2.45,10.57s.82,7.5,2.51,10.57c1.68,3.06,4.02,5.46,7.01,7.18h0s0,.01,0,.01c3.04,1.69,6.53,2.51,10.42,2.51s7.37-.82,10.38-2.51h0s0,0,0,0c3.03-1.72,5.39-4.12,7.07-7.18,1.69-3.07,2.51-6.61,2.51-10.57s-.82-7.53-2.51-10.58c-1.68-3.06-4.05-5.45-7.08-7.13Zm-5.18,24.83h0s0,0,0,0c-1.21,1.52-2.89,2.31-5.19,2.31s-4.03-.79-5.24-2.3c-1.25-1.6-1.97-3.92-1.97-7.13s.72-5.52,1.96-7.07c1.21-1.51,2.9-2.31,5.25-2.31s4.04,.8,5.25,2.31c1.25,1.56,1.96,3.85,1.96,7.07s-.73,5.53-2.01,7.12Z" />
      <path d="M315.2,54.75c-.69-1.26-1.53-2.31-2.51-3.12,1.46-.8,2.69-1.84,3.65-3.15,1.59-2.15,2.35-4.76,2.35-7.73,0-3.91-1.26-7.13-3.89-9.45-2.59-2.35-6.17-3.42-10.53-3.42h-18.98v39.35h12.39v-13.61h1.41c.65,0,1.15,.12,1.53,.31h.01s.01,.01,.01,.01c.35,.17,.68,.46,.97,.96h0s6.71,12.32,6.71,12.32h13.73l-6.86-12.47h0Zm-9.3-11.54c-.58,.46-1.61,.8-3.34,.8h-4.88v-5.98h4.88c1.73,0,2.77,.34,3.35,.8,.51,.4,.86,1.03,.86,2.17s-.35,1.81-.87,2.21Z" />
      <path d="M341.56,40.72h0c1.27-1.44,3.24-2.27,6.17-2.27,1.42,0,2.8,.25,4.17,.74h.01s.01,0,.01,0c1.36,.46,2.75,1.2,4.17,2.23l1.52,1.1,3.86-10.19-.75-.66c-1.56-1.36-3.55-2.41-5.93-3.18-2.39-.81-4.87-1.21-7.44-1.21-4.22,0-7.93,.81-11.08,2.5-3.14,1.68-5.57,4.07-7.25,7.15h0c-1.65,3.05-2.45,6.59-2.45,10.57s.8,7.54,2.45,10.61h0s0,.01,0,.01c1.69,3.08,4.12,5.46,7.25,7.15,3.15,1.69,6.86,2.5,11.08,2.5,2.56,0,5.04-.38,7.43-1.16h0s0,0,0,0c2.37-.8,4.36-1.87,5.92-3.23l.75-.66-3.86-10.19-1.52,1.1c-1.42,1.03-2.82,1.79-4.19,2.29-1.37,.46-2.76,.69-4.18,.69-2.94,0-4.91-.81-6.18-2.21-1.31-1.48-2.07-3.71-2.07-6.91s.76-5.36,2.06-6.8h0Z" />
      <polygon points="393.58 27.88 393.58 41.95 381.39 41.95 381.39 27.88 369 27.88 369 67.22 381.39 67.22 381.39 52.63 393.58 52.63 393.58 67.22 405.91 67.22 405.91 27.88 393.58 27.88" />
      <polygon points="49.93 27.94 42.46 49.82 35.08 27.94 26.85 27.94 19.13 49.4 11.99 27.94 0 27.94 14.08 67.18 23.2 67.18 30.68 46.58 38.02 67.18 47.14 67.18 61.34 27.94 49.93 27.94" />
      <path d="M94.33,29.86c-2.97-1.66-6.47-2.5-10.39-2.5s-7.43,.84-10.42,2.5c-3.01,1.67-5.37,4.05-7.02,7.07-1.64,3.01-2.47,6.57-2.47,10.6s.84,7.55,2.5,10.57c1.67,3.04,4.03,5.44,7.01,7.12,2.97,1.68,6.47,2.53,10.4,2.53s7.43-.85,10.4-2.53c2.99-1.69,5.34-4.08,7.01-7.12,1.66-3.02,2.5-6.58,2.5-10.57s-.84-7.59-2.5-10.6c-1.67-3.02-4.03-5.4-7.02-7.07Zm-4.65,25.26c-1.39,1.74-3.28,2.58-5.75,2.58s-4.39-.84-5.77-2.58c-1.41-1.78-2.13-4.33-2.13-7.59s.71-5.8,2.12-7.56c1.38-1.72,3.26-2.56,5.77-2.56s4.4,.84,5.77,2.56c1.41,1.76,2.12,4.3,2.12,7.56s-.72,5.81-2.15,7.59Z" />
      <path d="M137.86,51.65c1.79-.83,3.24-2,4.33-3.48,1.51-2.05,2.28-4.6,2.28-7.58,0-3.94-1.27-7.07-3.79-9.32-2.48-2.22-6-3.34-10.46-3.34h-18.91v39.24h11.65v-13.93h1.96c.7,0,1.32,.13,1.84,.38,.48,.23,.88,.63,1.2,1.19l6.73,12.36h12.87l-6.6-12c-.85-1.54-1.88-2.72-3.1-3.53Zm-14.89-14.35h5.51c4.28,0,4.82,1.9,4.82,3.55s-.55,3.6-4.82,3.6h-5.51v-7.16Z" />
      <polygon points="175.42 46.65 192.96 27.94 178.8 27.94 165.55 42.54 165.55 27.94 153.91 27.94 153.91 67.18 165.55 67.18 165.55 51.76 179.32 67.18 193.45 67.18 175.42 46.65" />
      <polygon points="233.95 27.98 198.38 27.98 198.38 34.23 211.96 39.03 208.07 41.46 209.87 67.2 222.45 67.2 224.25 41.46 220.36 39.03 233.95 34.23 233.95 27.98" />
    </g>
    <path d="M202.39,.85c1.04-.6,2.09-.8,3.14-.84,4.27-.16,7.53,1.57,9.66,5.33,.88,1.56,1.76,3.13,2.64,4.7,.08,.14,.19,.27,.31,.39,.55,.57,1.16,1.14,2.02,.82,.84-.32,1.29-1.05,1.43-1.93,.23-1.46-.39-2.67-1.2-3.79-1.12-1.55-2.52-2.8-4.18-3.74-.14-.08-.27-.2-.41-.29,0-.06,.02-.13,.03-.19,.79-.17,1.56-.4,2.36-.51,2.7-.36,5.23,.12,7.39,1.86,2.77,2.24,3.84,5.23,3.54,8.7-.54,6.28-6.17,9.65-11.44,8.59-3.91-.78-6.31-3.31-7.66-6.97-.88-2.39-1.72-4.81-2.74-7.14-.9-2.08-2.35-3.75-4.49-4.7-.11-.05-.2-.14-.41-.29Z" />
    <path d="M212.01,19.11c-.56,.19-1.01,.4-1.48,.49-3.22,.59-6.2-1.17-7.3-4.26-.51-1.42-1.03-2.83-1.57-4.23-.62-1.61-1.64-2.9-3.35-3.72,.24-.16,.36-.29,.52-.34,2.81-.9,5.98,.25,7.44,2.84,.71,1.26,1.21,2.65,1.74,4.02,.58,1.49,1.1,3,2.4,4.05,.45,.37,.94,.69,1.6,1.16Z" />
  </svg>
);

export default WorkTorchLogo;
