import { Typography } from '@mui/material';
import { Box } from 'components';
import { RetentionContext } from 'components/Retention/utils';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRoutes } from 'utils';
import { Button } from '../../../shared';
import { useMediaQueryMatches } from '../../../../hooks';

interface ILocationDetail {
  locationName: string;
  renderLocationSnapshot: () => JSX.Element | JSX.Element[];
  renderSnapshotChart?: () => JSX.Element;
}

const LocationDetailContainer = ({
  locationName,
  renderLocationSnapshot,
  renderSnapshotChart,
}: ILocationDetail) => {
  const navigate = useNavigate();
  const { isMobile } = useMediaQueryMatches();
  const location = useLocation();
  const routes = getRoutes();
  const ctx = useContext(RetentionContext);

  const changeLocation = () => {
    if (
      location.pathname.indexOf(ctx.retentionPages.path) >= 0
    ) {
      navigate(
        `${routes.employer.retention_portal.brand}/${ctx.retentionPages.path}`,
      );
    } else if (
      location.pathname.indexOf(ctx.retentionPages.pulse) >= 0
    ) {
      navigate(
        `${routes.employer.retention_portal.brand}/${ctx.retentionPages.pulse}`,
      );
    } else {
      navigate(
        `${routes.employer.retention_portal.brand}/${ctx.retentionPages.health}`,
      );
    }
  };

  return (
    <Box className={'location-container'}>
      <Box className={'location-content-container'}>
        <Box className={'location-title-row'}>
          <Typography
            variant={'h2'}
            className={'location-light-font-weight'}
          >
            Location:
            <span className={'location-light-font-weight'}>
              {locationName}
            </span>
          </Typography>
          <Button
            variant="filled-secondary"
            fullWidth={isMobile}
            onClick={changeLocation}
          >
            Change Location
          </Button>
        </Box>
        <Box className={'location-snapshot-stats'}>
          {renderLocationSnapshot()}
        </Box>
      </Box>
      <Box className={'location-graph-snapshot'}>
        {renderSnapshotChart ? renderSnapshotChart() : null}
      </Box>
    </Box>
  );
};

export default LocationDetailContainer;
