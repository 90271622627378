import React, { useState } from 'react';
import PT from 'prop-types';
import ShareJobModal from 'components/dialogs/ShareJobModal';

const { EMPLOYEE_JOB_PAGE_URL } = process.env;

const ShareJob = (props) => {
  const { children, jobTitle, jobId } = props;
  const [showModal, setShowModal] = useState(false);
  const jobUrl = `${EMPLOYEE_JOB_PAGE_URL}/${jobId}`;

  const share = async () => {
    // const canShare = navigator.canShare && navigator.canShare();
    const canShare = 'share' in navigator;
    try {
      if (canShare) {
        await navigator.share({
          title: '',
          text: '',
          url: jobUrl
        });
      } else {
        setShowModal(true);
      }
    } catch (err) {
      console.error('Share error: ', err);
    }
  };

  return (
    <>
      {children(share)}
      {showModal && (
        <ShareJobModal
          isOpen={showModal}
          jobTitle={jobTitle}
          jobId={jobId}
          jobUrl={jobUrl}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

ShareJob.propTypes = {
  children: PT.func.isRequired,
  jobTitle: PT.string.isRequired,
  jobId: PT.oneOfType([PT.string, PT.number]).isRequired
};

export default ShareJob;
