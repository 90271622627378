import { useLazyQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Pagination,
  styled,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { GET_MANAGERS_BY_BRAND } from 'api';
import { PersonAddIcon } from 'components/icons';
import EmployerCard from 'components/Retention/components/Brand/EmployerCard';
import EmployerDetailContainer from 'components/Retention/components/Location/LocationDashboardRenderer';
import { RetentionContext } from 'components/Retention/utils';
import { Button, IconButton, Input } from 'components/shared';
import useDebounce from 'hooks/useDebounce';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { workforceEntryStyles } from 'styles/Retention/Brand/workforce';

const StyledBox = styled(Box)(workforceEntryStyles);

const BrandWorkforceEntry = () => {
  const [activePageNumber, setActivePageNumber] =
    useState<number>(1);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const { brandId, brandName } = useContext(RetentionContext);
  const managersPerPage = 20;

  const debouncedSearchPhrase = useDebounce(searchPhrase, 500);

  const [
    fetchManagersByBrand,
    { data: managerData, loading: managerDataLoading = true },
  ] = useLazyQuery(GET_MANAGERS_BY_BRAND, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  useEffect(() => {
    fetchManagersByBrand({
      variables: {
        brandId,
        limit: managersPerPage,
        page: activePageNumber,
        phrase: debouncedSearchPhrase,
      },
    });
  }, [debouncedSearchPhrase]);

  const brandManagers = managerData?.managersByBrand?.results;
  const total = managerData?.managersByBrand?.total;

  const handlePageChange = async (e, pageNumber) => {
    setActivePageNumber(pageNumber);
    await fetchManagersByBrand({
      variables: {
        brandId,
        limit: managersPerPage,
        page: pageNumber,
        phrase: debouncedSearchPhrase,
      },
    });
  };

  const handleSearchChange = (e) => {
    setSearchPhrase(e.target.value);
  };

  return (
    <EmployerDetailContainer>
      <StyledBox>
        <Typography variant="h1" className="brandTitle">
          {brandName}
        </Typography>
        <Box className="workforceHeader">
          <Typography variant="h2">Brand Team</Typography>
          <Button
            variant="filled-primary"
            startIcon={<PersonAddIcon htmlColor="#FFF" />}
          >
            <Typography>Add team member</Typography>
          </Button>
        </Box>
        <Box className="searchContainer">
          <Input
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            inputClassName="search-input"
            placeholder="Search"
            onChange={handleSearchChange}
          />
          <Box />
        </Box>
        {!managerDataLoading && isEmpty(brandManagers) ? (
          <Typography variant="h3">
            You don't currently have any managers associated to
            this brand
          </Typography>
        ) : (
          <Box className="mainContent">
            <Box className="employerCardWrapper">
              {brandManagers?.map((manager) => (
                <EmployerCard
                  name={manager.name}
                  imageUrl={manager.imageUrl}
                  jobType="--Operator--"
                  location={manager.location}
                />
              ))}
            </Box>
            <Box className="pagination">
              {total > managersPerPage && (
                <Pagination
                  count={Math.ceil(total / managersPerPage)}
                  size="large"
                  page={
                    activePageNumber === 0
                      ? activePageNumber + 1
                      : activePageNumber
                  }
                  onChange={handlePageChange}
                  className="paginationBar"
                />
              )}
            </Box>
          </Box>
        )}
      </StyledBox>
    </EmployerDetailContainer>
  );
};

export default BrandWorkforceEntry;
