import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  styled,
} from 'components';
import React, { SyntheticEvent } from 'react';
import { qaAttr } from 'utils';
import { FormControlProps as IFormControlProps } from '@mui/material/FormControl/FormControl';
import { FormHelperTextProps as IFormHelperTextProps } from '@mui/material/FormHelperText/FormHelperText';
import { InputBaseComponentProps as IInputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { InputLabelProps as IInputLabelProps } from '@mui/material/InputLabel/InputLabel';

const PREFIX = 'StyledInput';
const classes = {
  inputBase: `${PREFIX}-inputBase`,
  inputBaseInput: `${PREFIX}-inputBase__input`,
  label: `${PREFIX}-label`,
  labelFormControl: `${PREFIX}-label__formControl`,
  helperText: `${PREFIX}-helperText`,
};

interface IInputProps {
  ariaLabel: string;
  className: string; // container className
  disabled: boolean;
  FormControlProps: Partial<IFormControlProps>; // container props (Mui FormControl)
  FormHelperTextProps: Partial<IFormHelperTextProps>; // Mui HelperText props
  error: string;
  helperText: string; // message (not error)
  helperTextClassName: string;
  htmlInputClassName: string;
  highlightInputOnError: boolean;
  id: string | number;
  inputProps: any; // html input
  InputProps: Partial<IInputBaseComponentProps>; // wrapper around html input (Mui InputBase)
  inputClassName: string;
  InputLabelProps: Partial<IInputLabelProps>;
  label: string | JSX.Element;
  labelClassName: string;
  name: string;
  onFocus: (e: SyntheticEvent) => void;
  required: boolean;
  testID: string;
  variant: 'text' | 'outlined' | 'textfield';
  withHelperText: boolean; // show message
}

interface IStyledLabel extends IInputLabelProps {
  htmlFor?: string | number;
}

const Label = styled<IStyledLabel>(({ className, ...props }) => (
  <InputLabel
    className={className}
    classes={{
      root: classes.label,
      formControl: classes.labelFormControl,
    }}
    {...props}
  />
))(({ theme }) => ({
  marginBottom: 4,
  position: 'static',
  transform: 'none',
  fontSize: 14,
  lineHeight: '17px',
  color: theme.palette.primary.text,
  [`&.${classes.label}.Mui-error`]: {
    // increased specificity to prevent overriding
    color: theme.palette.error.main,
  },
  [`& .${classes.labelFormControl}`]: {
    '&[data-shrink=false]': {
      '& + .MuiInputBase-root .MuiInputBase-input': {
        '&::placeholder': {
          opacity: '0.4 !important',
        },
      },
    },
  },
  '& .MuiInputLabel-asterisk': {
    color: theme.palette.error.main,
  },
}));

interface IStyledInputMessage extends IFormHelperTextProps {
  id: string;
  className: string;
}

const InputMessage = styled(
  ({ className, ...props }: IStyledInputMessage) => (
    <FormHelperText
      className={className}
      classes={{
        root: classes.helperText,
      }}
      {...props}
    />
  ),
)(() => ({}));

const InputComponent = styled(
  ({ className, ...props }) => (
    <InputBase
      className={className}
      classes={{
        root: classes.inputBase,
        input: classes.inputBaseInput,
      }}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'highlightInputOnError',
  },
)(({ theme, variant, highlightInputOnError }) => ({
  '&.Mui-error': {
    ...(highlightInputOnError && {
      borderColor: theme.palette.error.main,
    }),
  },
  [`& .${classes.inputBaseInput}`]: {
    height: 'auto',
    borderRadius: 7,
    ...(variant === 'outlined' && {
      padding: 16,
    }),
    ...(variant === 'textfield' && {
      padding: 5,
    }),
    ...(variant === 'text' && {
      padding: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    }),
  },
}));

const Input = React.forwardRef((props: IInputProps, ref) => {
  const {
    ariaLabel,
    className,
    disabled = false,
    FormControlProps = {},
    FormHelperTextProps = {},
    error,
    helperText,
    helperTextClassName,
    highlightInputOnError = true,
    htmlInputClassName,
    id,
    InputProps = {},
    inputClassName,
    inputProps,
    InputLabelProps = {},
    label,
    labelClassName,
    name,
    onFocus,
    required = false,
    testID,
    variant = 'outlined',
    withHelperText = false,
    ...rest
  } = props;
  const isError = !!error;
  const helperId = id || name ? `${id || name}-helper` : '';

  const handleFocus = (e) => {
    if (onFocus) onFocus(e);
  };

  return (
    <FormControl
      fullWidth
      required={required}
      disabled={disabled}
      error={isError}
      className={className}
      {...FormControlProps}
    >
      {label && (
        <Label
          shrink
          className={labelClassName}
          {...(id && { htmlFor: id })}
          {...InputLabelProps}
        >
          {label}
        </Label>
      )}
      <InputComponent
        id={id}
        name={name}
        className={inputClassName}
        inputProps={{
          className: htmlInputClassName,
          ...(testID && qaAttr(testID)),
          ...(name && !label && { 'aria-label': name }),
          ...(ariaLabel && { 'aria-label': ariaLabel }),
          ...inputProps,
        }}
        inputRef={ref}
        aria-describedby={withHelperText ? helperId : ''}
        onFocus={handleFocus}
        variant={variant}
        highlightInputOnError={highlightInputOnError}
        {...InputProps}
        {...rest}
      />
      {withHelperText && (helperText || error) && (
        <InputMessage
          id={helperId}
          className={helperTextClassName}
          {...FormHelperTextProps}
        >
          {helperText || error}
        </InputMessage>
      )}
    </FormControl>
  );
});

export default Input;
