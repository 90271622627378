import React, { useMemo } from 'react';
import { head } from 'lodash';
import { styled } from 'components';
import { GrowRecentBadgeIcon } from 'components/icons';
import BadgeCard from '../../components/Employee/badges/BadgeCard';
import PageHeader from '../../components/Employee/PageHeader';
import { withEmployeeGrow } from 'hocs';

const StyledRoot = styled('div')(() => ({
  '& .cardsContainer': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 10,
  },
}));

const Badges = ({ careerPath }) => {
  const {
    abilitiesBadges = [],
    skillsBadges = [],
    recentBadges,
  } = careerPath || {};

  const recentBadge = useMemo(() => {
    const recentAbilityBadge = head(
      recentBadges?.abilitiesBadges || [],
    );
    const recentSkillBadge = head(
      recentBadges?.skillsBadges || [],
    );
    return recentAbilityBadge || recentSkillBadge;
  }, [JSON.stringify(recentBadges)]);

  const isRecentBadge = ({
    abilitiesBadgeId,
    skillsBadgeId,
  }: {
    abilitiesBadgeId?: number;
    skillsBadgeId?: number;
  }) => {
    if (abilitiesBadgeId)
      return recentBadge?.abilitiesBadgeId === abilitiesBadgeId;
    if (skillsBadgeId)
      return recentBadge?.skillsBadgeId === skillsBadgeId;
    return false;
  };

  return (
    <StyledRoot>
      <PageHeader
        title="BADGES"
        subtitle="See the badges you've completed and can get"
        icon={<GrowRecentBadgeIcon />}
      />
      <div className="cardsContainer">
        {abilitiesBadges.map((badge) => (
          <BadgeCard
            key={badge.abilitiesBadgeId}
            title={badge.name}
            isActive={badge.complete}
            isRecent={isRecentBadge({
              abilitiesBadgeId: badge.abilitiesBadgeId,
            })}
            badgeUrl={badge.badgeUrl}
          />
        ))}
        {skillsBadges.map((badge) => (
          <BadgeCard
            key={badge.skillsBadgeId}
            title={badge.name}
            isActive={badge.complete}
            isRecent={isRecentBadge({
              skillsBadgeId: badge.skillsBadgeId,
            })}
            badgeUrl={badge.badgeUrl}
          />
        ))}
      </div>
    </StyledRoot>
  );
};

// const abilitiesBadgesPropTypes = PT.arrayOf(
//   PT.shape({
//     name: PT.string,
//     badgeUrl: PT.string,
//     abilitiesId: PT.number,
//     abilitiesBadgeId: PT.number,
//     complete: PT.bool
//   })
// );
// const skillsBadgesPropTypes = PT.arrayOf(
//   PT.shape({
//     name: PT.string,
//     badgeUrl: PT.string,
//     skillsId: PT.number,
//     skillsBadgeId: PT.number,
//     complete: PT.bool
//   })
// );
//
// BadgesPage.propTypes = {
//   abilitiesBadges: abilitiesBadgesPropTypes.isRequired,
//   skillsBadges: skillsBadgesPropTypes.isRequired,
//   recentBadges: PT.shape({
//     abilitiesBadges: abilitiesBadgesPropTypes,
//     skillsBadges: skillsBadgesPropTypes
//   }).isRequired
// };

export default withEmployeeGrow(Badges);
