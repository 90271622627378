import { useEffect, useMemo, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { find } from 'lodash';
import { GET_PAY_PERIODS } from 'api';

const FIELD_NAME = 'getAllPayPeriods';

export default function usePayPeriodsQuery(props) {
  const { autoFetch = true, ...queryOptions } = props || {};

  const [fetch, result] = useLazyQuery(GET_PAY_PERIODS, {
    ...queryOptions
  });
  const getResultFields = useCallback((res) => res?.data?.[FIELD_NAME], []);
  const payPeriods = getResultFields(result);

  useEffect(() => {
    if (autoFetch) fetch({ ...queryOptions });
  }, [autoFetch]);

  const getPayPeriodById = useCallback(
    (id) => {
      if (id) return find(payPeriods, ['id', Number(id)]) || {};
      return {};
    },
    [payPeriods]
  );

  return useMemo(
    () => ({
      fetch,
      payPeriods,
      fieldName: FIELD_NAME,
      getResultFields,
      getPayPeriodById,
      ...result
    }),
    [fetch, getPayPeriodById, getResultFields, payPeriods, result]
  );
}
