import { Box } from 'components';
import { styled } from '@mui/material/styles';
import SectionLayout from '../SectionLayout';
import { useEffect, useState } from 'react';
import FlexRow from '../../shared/FlexRow';
import Button from '../../shared/Button';
import { useAppStoreSelector } from '../../../store';
import FormLabel from '@mui/material/FormLabel';
import {
  paletteDarkBase,
  paletteLightBase,
} from '../../../styles/colors';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_COMPANY_THEME } from '../../../api/queries/companyTheme';
import { SET_COMPANY_THEME } from '../../../api/mutations/companyTheme';
import {
  ICompanyTheme,
  IGetCompanyTheme,
  ISetCompanyTheme,
} from '../../../api/types/queries/companyTheme';
import { buildAndSetTheme } from '../../../styles/utils';
import Typography from '@mui/material/Typography';

const StyledBox = styled(Box)(({ theme }) => ({
  '.colorSelectionRow': {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      marginRight: theme.spacing(1.5),
      fontSize: 14,
    },
  },
}));

interface ISectionProps {
  sectionName: string;
  jumpLink: string;
  expanded: boolean;
  onExpand: () => void;
}

const Theme = (props: ISectionProps) => {
  const themeMode = localStorage.getItem('themeMode') || 'dark';
  const basePalette =
    themeMode === 'dark' ? paletteDarkBase : paletteLightBase;
  const [colorScheme, setColorScheme] = useState([
    { name: 'Brand Main', color: basePalette.primary },
    { name: 'Brand Secondary', color: basePalette.secondary },
    { name: 'Brand Tertiary', color: basePalette.tertiary },
    { name: 'Brand Quaternary', color: basePalette.quaternary },
  ]);
  const { setTheme, revertToInitialTheme } = useAppStoreSelector(
    ({
      colors: c,
      setTheme: st,
      revertToInitialTheme: rtit,
    }) => ({
      colors: c,
      setTheme: st,
      revertToInitialTheme: rtit,
    }),
  );

  const handleSetCompanyTheme = (
    savedColorScheme: ICompanyTheme,
  ) => {
    const newColorScheme = [...colorScheme];
    newColorScheme[0].color = savedColorScheme.primaryColor;
    newColorScheme[1].color = savedColorScheme.secondaryColor;
    newColorScheme[2].color = savedColorScheme.tertiaryColor;
    newColorScheme[3].color = savedColorScheme.quaternaryColor;
    setColorScheme(newColorScheme);
  };

  const [getCompanyTheme] = useLazyQuery<IGetCompanyTheme>(
    GET_COMPANY_THEME,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
        const savedColors = data.getCompanyTheme;
        if (savedColors) {
          handleSetCompanyTheme(savedColors);
          const colorsToSet = [
            savedColors.primaryColor,
            savedColors.secondaryColor,
            savedColors.tertiaryColor,
            savedColors.quaternaryColor,
          ];
          buildAndSetTheme({
            colorsToBuildTheme: colorsToSet,
            colorScheme,
            setTheme,
          });
        }
      },
    },
  );

  const [saveCompanyTheme] = useMutation<ISetCompanyTheme>(
    SET_COMPANY_THEME,
    {
      onCompleted: async (companyTheme) => {
        const savedColors = companyTheme.setCompanyTheme;
        handleSetCompanyTheme(savedColors);
      },
    },
  );

  useEffect(() => {
    getCompanyTheme();
  }, []);

  const handleChange = async (color, index) => {
    const [...newColors] = colorScheme;

    newColors.splice(index, 1, {
      ...colorScheme[index],
      color,
    });

    setColorScheme(newColors);
  };

  const saveTheme = async () => {
    buildAndSetTheme({
      colorScheme,
      setTheme,
    });

    const primary = colorScheme[0].color;
    const secondary = colorScheme[1].color;
    const tertiary = colorScheme[2].color;
    const quaternary = colorScheme[3].color;

    await saveCompanyTheme({
      variables: {
        primaryColor: primary,
        secondaryColor: secondary,
        tertiaryColor: tertiary,
        quaternaryColor: quaternary,
      },
    });
  };

  return (
    <SectionLayout {...props}>
      <StyledBox>
        <FlexRow className={'colorSelectionRow'}>
          {colorScheme.map(({ name, color }, index) => (
            <Box mt={1.5}>
              <FormLabel>{name}</FormLabel>
              <input
                type="color"
                value={color}
                onChange={(e) =>
                  handleChange(e.target.value, index)
                }
              />
            </Box>
          ))}
        </FlexRow>
        <Box>
          <Typography variant={'h3'} color={'primary'}>
            Things to note about theming:
          </Typography>
          <Typography variant={'body1'} mt={1}>
            You can use up to 4 brand colors to change your
            theme. Each color affects different aspects of the
            application. You can change this at any time so don't
            be scared to try out some combinations.
          </Typography>
          <Typography variant={'body2'} mt={1}>
            The 'Brand Main' color will be the primary color of
            the application. It will affect things like the
            background, text colors and buttons
          </Typography>
          <Typography variant={'body2'} mt={1}>
            The 'Brand Secondary' color will be the other most
            important color and will change styles of buttons and
            text
          </Typography>
          <Typography variant={'body2'} mt={1} mb={2}>
            The 'Brand Tertiary' and 'Brand Quaternary' colors
            will be minor adjustments to text and icons where
            emphasis happens throughout the application
          </Typography>
        </Box>
      </StyledBox>
      <FlexRow>
        <Button variant="filled-primary" onClick={saveTheme}>
          Save Theme
        </Button>
        <Button
          variant="contained"
          onClick={revertToInitialTheme}
        >
          Revert Theme
        </Button>
      </FlexRow>
    </SectionLayout>
  );
};

export default Theme;
