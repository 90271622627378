import React from 'react';
import { Box, Dialog, styled } from 'components';
import { Button } from 'components/shared';

const StyledDialog = styled(Dialog)(() => ({
  '& .templateMessageModal__title': {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold'
  },
  '& .templateMessageModal__message': {
    marginBottom: 20,
    padding: 8,
    borderRadius: 6,
    backgroundColor: '#ECECF5'
  },
  '& .templateMessageModal__cancel': {
    padding: '6px 12px'
  },
  '& .templateMessageModal__confirm': {
    marginLeft: 10,
    padding: '6px 12px'
  }
}));

const SuggestedMessageModal = ({ isOpen, onClose, onConfirm, message }) => (
    <StyledDialog open={isOpen} maxWidth="tablet" onClose={onClose}>
      <Box px="16px" py="12px">
        <h2 className="templateMessageModal__title">Use this template message?</h2>
        <p className="templateMessageModal__message">{message}</p>
        <Box textAlign="right">
          <Button
            variant="filled-secondary"
            className="templateMessageModal__cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="filled-primary"
            className="templateMessageModal__confirm"
            onClick={onConfirm}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  )

export default SuggestedMessageModal;
