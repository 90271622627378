import { gql } from "@apollo/client";

export const SEARCH_CONNECTIONS = gql`
  fragment usersFragment on Users {
    employeeProfile {
      imageUrl
      phone
      name
      city
      countryId
      profile_id
      stateId
      zip
      careerPathGroupItems {
        careerPathGroupItemJobType {
          name
        }
        index
      }
    }
  }
  query SearchConnections {
    searchConnections {
      similarResults {
        user_id
        email
        ...usersFragment
      }
      nextStepResults {
        user_id
        email
        ...usersFragment
      }
    }
  }
`
