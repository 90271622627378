import React, { useState, useEffect, useCallback } from 'react';
import { map, findIndex, pullAt } from 'lodash';
import { Notification } from 'components/shared';
import { NotificationsContext } from './context';

export function useCreateNotificationsContextValue() {
  const [snackPack, setSnackPack] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [snackInfo, setSnackInfo] = useState(undefined);
  const snackBarConfig = {
    autoHideDuration: 6000,
    anchorOrigin: { horizontal: 'right', vertical: 'top' }
  };

  const closeAll = useCallback(() => {
    setSnackPack([]);
    setSnackInfo(undefined);
    setIsOpen(false);
  }, []);

  const closeSingle = useCallback(
    (notificationKey) => {
      if (notificationKey && snackPack.length) {
        const i = findIndex(snackPack, ['notificationKey', notificationKey]);
        if (i !== -1) {
          const nextPack = [...snackPack];
          pullAt(nextPack, i);
          setSnackPack(nextPack);
          if (i === 0) {
            setSnackInfo(undefined);
            setIsOpen(false);
          }
        }
      }
    },
    [JSON.stringify(snackPack)]
  );

  const onClose = useCallback(() => setIsOpen(false), []);

  const onExited = useCallback(() => {
    setSnackInfo(undefined);
    setSnackPack((prev) => prev.slice(1));
  }, []);

  const show = useCallback(
    (notifications = []) => {
      setSnackPack((prev) => [
        ...prev,
        ...map(notifications, (o) => {
          const key = new Date().getTime();
          return {
            // all fields will be passed to <Notification>
            ...snackBarConfig,
            ...o,
            key,
            notificationKey: o.notificationKey || key, // used to close single notification with closeSingle method
            onClose: o.onClose || onClose,
            TransitionProps: {
              onExited: o.onExited || onExited
            },
            onNotificationClick: o.onNotificationClick || undefined,
            onNotificationClose: o.onNotificationClose || undefined,
            sender: o.sender || '',
            image: o.image || '',
            type: o.type || '' // type: '' | 'newMessage'
          };
        })
      ]);
    },
    [onClose, onExited, closeSingle]
  );

  useEffect(() => {
    if (snackPack.length && !snackInfo) {
      // Set a new snack when we don't have an active one
      setSnackInfo({ ...snackPack[0] });
      // setSnackPack((prev) => prev.slice(1));
      setIsOpen(true);
    }
  }, [JSON.stringify(snackPack), snackInfo, isOpen]);

  return {
    closeAll,
    closeSingle,
    isOpen,
    show,
    onClose,
    onExited,
    snackInfo,
    snackPack,
    setSnackPack,
    setIsOpen,
    setSnackInfo
  };
}

const NotificationsProvider = ({ children }) => {
  const contextValue = useCreateNotificationsContextValue();
  const { isOpen, snackInfo } = contextValue;
  return (
    <NotificationsContext.Provider value={contextValue}>
      <div style={{ position: 'relative', maxWidth: 1024, width: '100%', margin: '0 auto' }}>
        <Notification open={isOpen} {...(snackInfo || {})} />
      </div>
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsProvider;
