import React, { useMemo, memo } from 'react';
import PT from 'prop-types';
import { last } from 'lodash';
import { styled } from 'components';
import { GrowRecentBadgeIcon } from 'components/icons';
import ProgressCard from './ProgressCard';
import PageHeader from '../../Employee/PageHeader';

const StyledRoot = styled('div')(() => ({
  '& .progressPage__cardsContainer': {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '1em'
  }
}));

const ProgressPage = ({ careerPath }) => {
  const state = useMemo(() => {
    const pathItems = careerPath?.careerPathGroupItems || [];
    const futureJob = last(pathItems)?.jobTypeName;
    let completedPoints = 0;
    const allBadges = pathItems.reduce((acc, item) => {
      const badges = [...acc, ...item.abilitiesBadges, ...item.skillsBadges];
      const completeAbilities = item.abilitiesBadges.filter((o) => o.complete);
      const completeSkills = item.skillsBadges.filter((o) => o.complete);
      completedPoints = completedPoints + completeSkills.length + completeAbilities.length;
      return badges;
    }, []);

    return { futureJob, completedPoints, totalPoints: allBadges.length, allBadges };
  }, [JSON.stringify(careerPath)]);

  const { futureJob, completedPoints, totalPoints, allBadges } = state;

  return (
    <StyledRoot>
      <PageHeader
        title="GROW PROGRESS"
        subtitle={
          futureJob
            ? `On your way to: ${futureJob} | ${completedPoints} of ${totalPoints} points completed`
            : ''
        }
        icon={<GrowRecentBadgeIcon />}
      />
      <div className="progressPage__cardsContainer">
        {allBadges.map((badge) => (
          <ProgressCard
            key={
              badge.abilitiesBadgeId
                ? `abil__${badge.abilitiesBadgeId}`
                : `skill__${badge.skillsBadgeId}`
            }
            name={badge.name}
            score={1}
            completed={badge.complete}
          />
        ))}
      </div>
    </StyledRoot>
  );
}

ProgressPage.propTypes = {
  careerPath: PT.shape({
    careerPathGroupItems: PT.arrayOf(
      PT.shape({
        active: PT.bool,
        completed: PT.bool,
        careerPathGroupId: PT.number,
        careerPathGroups: PT.shape({
          id: PT.number,
          startJobTypeId: PT.number,
          endJobTypeId: PT.number
        }),
        id: PT.number,
        index: PT.number,
        jobTypeId: PT.number,
        jobTypeName: PT.string,
        abilitiesBadges: PT.arrayOf(
          PT.shape({
            abilitiesBadgeId: PT.number,
            abilitiesId: PT.number,
            badgeUrl: PT.string,
            complete: PT.bool,
            name: PT.string
          })
        ),
        skillsBadges: PT.arrayOf(
          PT.shape({
            skillsBadgeId: PT.number,
            skillsId: PT.number,
            badgeUrl: PT.string,
            complete: PT.bool,
            name: PT.string
          })
        )
      })
    )
  }).isRequired
};

export default memo(ProgressPage);
