import React from 'react';
import { Box, Dialog } from 'components';
import { Button, Input } from 'components/shared';
import { useForm } from 'components/form';
import { usePayPeriodsQuery, useCurrenciesQuery } from 'hooks';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const PayRangeModal = (props) => {
  const { isOpen, onClose, onConfirm } = props;

  const { $, set, attrs, withValidation } = useForm({
    initial: {
      payMin: 10,
      payMax: 100,
      payPeriodId: '',
      currencyId: '',
    },
    validations: {
      payMin: [
        'presence',
        (value, { attrs: form }) => {
          if (value >= form.payMax)
            return 'Should be less than max pay';
          return null;
        },
      ],
      payMax: [
        'presence',
        (value, { attrs: form }) => {
          if (value <= form.payMin)
            return 'Should be more than min pay';
          return null;
        },
      ],
      payPeriodId: 'presence',
      currencyId: 'presence',
    },
  });

  const { currencies = [], getCurrencyById } =
    useCurrenciesQuery();
  const { payPeriods = [], getPayPeriodById } =
    usePayPeriodsQuery();

  const handleChange = (value, { name }) => {
    set(name, value);
  };

  const handleNumberChange = (e, { name }) => {
    set(
      name,
      e.target.value === '' ? '' : Number(e.target.value),
    );
  };

  const confirm = withValidation((validAttrs) => {
    const { payPeriodId, currencyId, payMin, payMax } =
      validAttrs;
    const currency = getCurrencyById(currencyId)?.name;
    const period = getPayPeriodById(payPeriodId)?.name;

    onConfirm({
      payPeriodId,
      currencyId,
      payMin,
      payMax,
      currency,
      period,
    });
    onClose();
  });

  return (
    <Dialog
      open={isOpen}
      maxWidth="tablet"
      fullWidth
      onClose={onClose}
    >
      <Box px="16px" py="20px">
        <Box mb="10px">
          <Select
            id="currency"
            {...$('currencyId', (obj, meta) =>
              handleChange(obj.value, meta),
            )}
            required
            label="Currency"
            variant="outlined"
            disabled={!currencies?.length}
          >
            {currencies.map((o) => (
              <MenuItem value={o.id}>{o.name}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box mb="10px">
          <Select
            id="period"
            {...$('payPeriodId', (obj, meta) =>
              handleChange(obj.value, meta),
            )}
            required
            label="Period"
            variant="outlined"
            disabled={!payPeriods?.length}
            size={'small'}
          >
            {payPeriods.map((o) => (
              <MenuItem value={o.id}>{o.name}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box mb="20px" display="flex">
          <Box flex={1}>
            <Input
              id="payMin"
              {...$('payMin', handleNumberChange)}
              label="Min pay"
              type="number"
              required
              inputProps={{
                min: 5,
                max: attrs.payMax - 1,
                step: 5,
              }}
              withHelperText
            />
          </Box>
          <Box flex={1} ml="10px">
            <Input
              id="payMax"
              {...$('payMax', handleNumberChange)}
              label="Max pay"
              type="number"
              required
              inputProps={{
                min: attrs.payMin + 1,
                max: 10000,
                step: 5,
              }}
              withHelperText
            />
          </Box>
        </Box>
        <Box textAlign="center">
          <Button variant="filled-primary" onClick={confirm}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

PayRangeModal.propTypes = {};

export default PayRangeModal;
