import { Box, Typography } from '@mui/material';
import { getRoutes } from 'utils';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { Button } from '../../../../shared';
import { styled } from '../../../../index';
import { pathEntryStyles } from '../../../../../styles/Retention/Location/path';
import { RetentionContext } from '../../../utils';
import ViewOrganizationPaths from './ViewOrganizationPaths';
import { ICareerPathGroupByOrganization } from './types';
import { useMediaQueryMatches } from '../../../../../hooks';

const StyledBox = styled(Box)(pathEntryStyles);

const PathManagement = ({
  setAddedEmployeeToPath,
  refetchPathStatsByLocation,
  refetchEmployeePathStats,
  refetchGetPathByLocation,
}: {
  setAddedEmployeeToPath: (a: boolean) => void;
  refetchPathStatsByLocation: () => void;
  refetchEmployeePathStats: () => void;
  refetchGetPathByLocation: () => void;
}) => {
  const ctx = useContext(RetentionContext);
  const [activePath, setActivePath] =
    useState<ICareerPathGroupByOrganization | null>(null);
  const navigate = useNavigate();
  const routes = getRoutes();
  const { isMobile } = useMediaQueryMatches();

  return (
    <StyledBox className={'path-detail-content-container'}>
      <Box className={'path-detail-title-container'}>
        {activePath != null ? (
          <Button
            variant={'filled-primary'}
            onClick={() => setActivePath(null)}
          >
            Back
          </Button>
        ) : (
          <Typography variant={'h3'}>Manage Paths</Typography>
        )}

        {ctx.accessOrganization && (
          <Button
            variant={'filled-primary'}
            onClick={() =>
              navigate(
                `${routes.employer.retention_portal.location}/${ctx.retentionPages.pathmanager}`,
              )
            }
            fullWidth={isMobile}
          >
            Create New Path
          </Button>
        )}
      </Box>
      <ViewOrganizationPaths
        setAddedEmployeeToPath={setAddedEmployeeToPath}
        activePath={activePath}
        setActivePath={setActivePath}
        refetchPathStatsByLocation={refetchPathStatsByLocation}
        refetchEmployeePathStats={refetchEmployeePathStats}
        refetchGetPathByLocation={refetchGetPathByLocation}
      />
    </StyledBox>
  );
};

export default PathManagement;
