/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import AvatarEditor from 'react-avatar-editor';
import { qaAttr, imageTypeHelperText } from 'utils';
import { styled } from 'components';

const PREFIX = 'jobImage';
const classes = {
  container: `${PREFIX}-container`,
  imgPreview: `${PREFIX}-imgPreview`
};
const StyledRoot = styled('div')(() => ({
  [`&.${classes.container}`]: {},
  [`&.${classes.imgPreview}`]: {}
}));

const EditableJobImage = (props) => {
  const {
    className,
    isEditMode,
    avatarEditorRef,
    editorClassName,
    renderError,
    renderUploadButton,
    imageUrl,
    onUpload
  } = props;

  const handleUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = async () => {
      onUpload(reader.result, file);
    };
    if (file) reader.readAsDataURL(file);
  };

  return (
    <StyledRoot className={clsx(className, classes.container)}>
      {isEditMode ? (
        <>
          <AvatarEditor
            ref={avatarEditorRef}
            image={imageUrl}
            width={230}
            height={230}
            border={0}
            borderRadius={0}
            color={[255, 255, 255, 0.6]}
            scale={1.1}
            rotate={0}
            className={editorClassName}
          />
          {renderError()}
          <p className="imageTypeTip">{imageTypeHelperText}</p>
          <input
            accept="image/*"
            id="image-input"
            type="file"
            onChange={handleUpload}
            style={{ display: 'none' }}
            {...qaAttr('image-input')}
          />
          <label htmlFor="image-input">{renderUploadButton()}</label>
        </>
      ) : (
        <img src={imageUrl} className={classes.imgPreview} alt="" />
      )}
    </StyledRoot>
  );
}

EditableJobImage.propTypes = {
  className: PT.string,
  editorClassName: PT.string,
  isEditMode: PT.bool.isRequired,
  avatarEditorRef: PT.objectOf(PT.any).isRequired,
  renderError: PT.func,
  renderUploadButton: PT.func,
  imageUrl: PT.any.isRequired,
  onUpload: PT.func
};

EditableJobImage.defaultProps = {
  className: '',
  editorClassName: '',
  renderError: () => {},
  renderUploadButton: () => {},
  onUpload: () => {}
};

export default EditableJobImage;
