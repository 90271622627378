import { Box } from 'components';
import { Button } from 'components/shared';
import PageHeader from '../../components/Employee/PageHeader';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import React, { useEffect } from 'react';
import { useMediaQueryMatches } from '../../hooks';
import { styled } from '@mui/material/styles';
import useConnections, {
  IEmployeeConnection,
} from '../../hooks/useConnections';
import Loading from '../../components/shared/Loading/Loading';
import Typography from '@mui/material/Typography';
import EmployeeCard from '../../components/Retention/components/Location/EmployeeCard';
import FlexRow from '../../components/shared/FlexRow';
import get from 'lodash/get';

interface IStyledProps {}

const StyledBox = styled(Box)<IStyledProps>(({ theme }) => ({
  '.sectionTitle': {
    marginBottom: theme.spacing(1.5),
  },
  '.cardContainer': {
    minWidth: 200,
    textAlign: 'center',
  },
  '.parentWrapperOverride': {
    minHeight: 270,
  },
  '.actionButton': {
    marginTop: theme.spacing(1.5),
  },
}));
const Connections = () => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { employeeConnections } = useConnections();

  useEffect(() => {
    employeeConnections.fetch();
  }, []);

  const AddConnectionRow = ({
    connection,
  }: {
    connection: IEmployeeConnection;
  }) => {
    return (
      <Button
        className={'actionButton'}
        variant={'contained'}
        color={'secondary'}
        onClick={() => console.log('hitting add employee')}
      >
        Add Employee
      </Button>
    );
  };

  return (
    <StyledBox>
      {isDesktop && (
        <PageHeader
          title="Connections"
          subtitle="Search for people like you!"
          icon={<GroupAddIcon />}
        />
      )}
      <Box>
        {employeeConnections.loading ? (
          <Loading
            title={'Loading Connection Data'}
            subTitle={
              'Hang tight, connections will be here soon'
            }
          />
        ) : (
          <Box>
            <Box>
              <Typography
                variant={'h2'}
                className={'sectionTitle'}
              >
                Similar Connections
              </Typography>
              <FlexRow
                flexWrap={'nowrap'}
                sx={{
                  overflowX: 'scroll',
                }}
              >
                {get(
                  employeeConnections,
                  'data.searchConnections.similarResults',
                  [],
                ).map((connection: IEmployeeConnection) => {
                  const profile = connection.employeeProfile;
                  const pathGroupItems =
                    profile.careerPathGroupItems;
                  return (
                    <EmployeeCard
                      employeeId={profile.profile_id}
                      name={profile.name}
                      imageUrl={profile.imageUrl}
                      currentJobType={
                        pathGroupItems[0]
                          .careerPathGroupItemJobType.name
                      }
                      futureJobType={
                        pathGroupItems[1]
                          .careerPathGroupItemJobType.name
                      }
                      verified // this may be false, we dont care in this context
                      classes={{
                        cardContainer: 'cardContainer',
                        parentWrapper: 'parentWrapperOverride',
                      }}
                      renderEmployeeActionRow={
                        <AddConnectionRow
                          connection={connection}
                        />
                      }
                    />
                  );
                })}
              </FlexRow>
            </Box>
            <Box>
              <Typography variant={'h2'}>
                Mentor Connections
              </Typography>
              <FlexRow
                flexWrap={'nowrap'}
                sx={{
                  overflowX: 'scroll',
                }}
              >
                {get(
                  employeeConnections,
                  'data.searchConnections.nextStepResults',
                  [],
                ).map((connection: IEmployeeConnection) => {
                  const profile = connection.employeeProfile;
                  const pathGroupItems =
                    profile.careerPathGroupItems;
                  return (
                    <EmployeeCard
                      employeeId={profile.profile_id}
                      name={profile.name}
                      imageUrl={profile.imageUrl}
                      currentJobType={
                        pathGroupItems[0]
                          .careerPathGroupItemJobType.name
                      }
                      futureJobType={
                        pathGroupItems[1]
                          .careerPathGroupItemJobType.name
                      }
                      verified // this may be false, we dont care in this context
                      classes={{
                        cardContainer: 'cardContainer',
                        parentWrapper: 'parentWrapperOverride',
                      }}
                      renderEmployeeActionRow={
                        <AddConnectionRow
                          connection={connection}
                        />
                      }
                    />
                  );
                })}
              </FlexRow>
            </Box>
          </Box>
        )}
      </Box>
    </StyledBox>
  );
};

export default Connections;
