import React, { useContext } from 'react';
import JobPostingScores from 'components/Grow/employer/charts/JobPostingScores';
import JobStatistics from 'components/Grow/employer/charts/JobStatistics';
import HiringRate from 'components/Grow/employer/charts/HiringRate';
import ChurnRate from 'components/Grow/employer/charts/ChurnRate';
import Competition from 'components/Grow/employer/charts/Competition';
import EmployerGrowContext from '../../EmployerGrowContext';

const CompanyCharts = () => {
  const {
    showJobsAnalytics,
    jobScores,
    jobStats,
    hiringRate,
    competitionBreakdown,
    employeeChurnRate
  } = useContext(EmployerGrowContext);
  const jobPostingScoresVisible = showJobsAnalytics && jobScores;
  const jobStatisticsVisible = showJobsAnalytics && jobStats;

  return (
    <div className="chartsContainer">
      {(jobStatisticsVisible || jobPostingScoresVisible) && (
        <div className="jobCharts">
          {jobPostingScoresVisible && <JobPostingScores />}
          {jobStatisticsVisible && <JobStatistics />}
        </div>
      )}
      <div className="generalCharts">
        {hiringRate && <HiringRate />}
        {competitionBreakdown && <Competition />}
        {employeeChurnRate && <ChurnRate />}
      </div>
    </div>
  );
}

export default CompanyCharts;
