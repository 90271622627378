import { Box } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { getRoutes } from '../../../utils';
import useEmployerOnboarding from '../../../hooks/useEmployerOnboarding';
import { Button, Spinner } from '../../../components/shared';
import {
  IOnboardingResource,
  IOnboardingResourceOnboarding,
} from '../../../api/types/queries/onboarding.types';
import FlexRow from '../../../components/shared/FlexRow';
import OnboardingFlowPage from '../../../components/employer/onboarding/OnboardingFlowPage';
import SearchableDialog from '../../../components/dialogs/SearchableDialog';
import { useAlerts } from '../../../hooks';

const StyledFlowWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('desktopApp')]: {
    padding: theme.spacing(2),
  },
  '.flowItemsWrapper': {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(1),
    [theme.breakpoints.down('desktopApp')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}));

const OnboardingFlow = () => {
  const [resourcePages, setResourcePages] = useState<
    Partial<IOnboardingResourceOnboarding>[]
  >([]);
  const [unusedResources, setUnusedResources] = useState<
    IOnboardingResource[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addToIndex, setAddToIndex] = useState<number | null>(
    null,
  );
  const { id: onboardingId } = useParams();
  const navigate = useNavigate();
  const routes = getRoutes();
  const { setSimpleAlert } = useAlerts();

  const {
    onboarding: { flow },
    resources: { allMinimal: allResourcesMinimal },
  } = useEmployerOnboarding();

  const manageUnusedPages = (
    allResources: IOnboardingResource[],
    currentResourcePages: Partial<
      IOnboardingResourceOnboarding[]
    >,
  ) => {
    const potentialPages = allResources.filter(
      (resource) => resource.isPage,
    );
    const usedResourceIds = currentResourcePages.map(
      (resource) => resource.resource.id,
    );

    const unusedPages = potentialPages.filter(
      (page) => !usedResourceIds.includes(page.id),
    );
    setUnusedResources(unusedPages);
  };

  const fetchData = async () => {
    try {
      const resourcesResp = await flow.get({
        variables: {
          onboardingId,
        },
      });
      const resources = resourcesResp.data.onboardingFlow.pages;
      setResourcePages(resources);
      const allResourcesResp = await allResourcesMinimal.get({
        variables: {
          limit: 100,
        },
      });

      manageUnusedPages(
        allResourcesResp.data.onboardingResources.results,
        resources,
      );
    } catch (error) {
      console.error('error');
      console.error(error);
    }
  };

  useEffect(() => {
    if (onboardingId) {
      fetchData();
    } else {
      navigate(routes.employer.onboarding.root);
    }
  }, []);

  useEffect(() => {
    if (flow?.data?.onboardingFlow?.pages) {
      // need to check if they're different
      setResourcePages(flow.data.onboardingFlow.pages);
    }
  }, [flow.data]);

  if (flow.loading || !resourcePages) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }

  // subtract count is either 2 or 0 denoting backwards and forward movement respectfully.
  const handleMove =
    (page, subtractCount = 0) =>
    () => {
      const actualIndex = page.index - 1;
      const tempResources = [...resourcePages];
      // remove item
      tempResources.splice(actualIndex, 1);
      // add it to new index
      tempResources.splice(page.index - subtractCount, 0, page);
      // redo indexes so they match current format
      const newFlow = tempResources.map((resource, idx) => ({
        ...resource,
        index: idx + 1,
      }));

      setResourcePages(newFlow);
    };

  const handleDelete = (page) => () => {
    const actualIndex = page.index - 1;
    const tempResources = [...resourcePages];
    // remove item
    tempResources.splice(actualIndex, 1);
    // redo indexes so they match current format
    const newFlow = tempResources.map((resource, idx) => ({
      ...resource,
      index: idx + 1,
    })) as Partial<IOnboardingResourceOnboarding[]>;

    setResourcePages(newFlow);
    manageUnusedPages(
      allResourcesMinimal.data.onboardingResources.results,
      newFlow,
    );
  };

  const handleAddNew = (page, index) => () => {
    setIsModalOpen(true);
    setAddToIndex(index + 1);
  };

  const addResourceToFlow = (row) => {
    if (!row) {
      setIsModalOpen(false);
      return null;
    }

    const newResourcePages = [...resourcePages];
    const newUnusedResources = [...unusedResources];

    const resourceToAdd = newUnusedResources.find(
      (resource) => resource.id === row.id,
    );

    // remove from unused resources;
    setUnusedResources(
      newUnusedResources.filter(
        (resource) => resource.id !== row.id,
      ),
    );

    // add to new resources in the index requested.
    newResourcePages.splice(addToIndex, 0, {
      onboardingId,
      index: null,
      resource: resourceToAdd,
    });

    setResourcePages(
      newResourcePages.map((resource, index) => ({
        ...resource,
        index: index + 1,
      })),
    );

    // reset addToIndex to null
    setAddToIndex(null);
    setIsModalOpen(false);
    return null;
  };

  const handleResetFlow = () => {
    const resetData = () => {
      manageUnusedPages(
        allResourcesMinimal.data.onboardingResources.results,
        flow.data.onboardingFlow.pages,
      );
      setResourcePages(flow.data.onboardingFlow.pages);
    };
    if (
      resourcePages.length !==
      flow.data.onboardingFlow.pages.length
    ) {
      setSimpleAlert({
        isOpen: true,
        title: 'Are you sure you want to discard your progress?',
        onSuccess: resetData,
        onCancel: () => setSimpleAlert({ isOpen: false }),
      });
      return null;
    }

    resetData();
    return null;
  };

  const handleSaveFlow = async () => {
    // save flow from id's and index
    const formattedVariables = resourcePages.map(
      ({ id, index, resource }) => ({
        onboardingResourceOnboardingId: id,
        index,
        onboardingResourceId: resource.id,
      }),
    );

    const newFlow = await flow.save({
      variables: {
        onboardingId,
        onboardingFlow: formattedVariables,
      },
    });
    if (!newFlow?.data?.saveOnboardingFlow?.pages) {
      const errorConfig = {
        title: 'There was an error saving your flow',
      };
      if (flow.saveError) {
        errorConfig.subtitle = flow.saveError;
      }
      setSimpleAlert({
        isOpen: true,
        ...errorConfig,
        onCancel: () => setSimpleAlert({ isOpen: false }),
        onSuccess: () => setSimpleAlert({ isOpen: false }),
      });
    }
  };

  const handleCancelFlow = () => {
    const routesWithId = getRoutes({
      id: onboardingId,
    });
    if (
      resourcePages.length !==
      flow.data.onboardingFlow.pages.length
    ) {
      setSimpleAlert({
        isOpen: true,
        title: 'You have unsaved changes',
        subtitle: 'Proceeding will discard those changes',
        onSuccess: () =>
          navigate(routesWithId.employer.onboarding.edit),
        onCancel: () => setSimpleAlert({ isOpen: false }),
      });
      return null;
    }
    // no changes, continue to nav away
    navigate(routesWithId.employer.onboarding.edit);
    return null;
  };

  return (
    <StyledFlowWrapper>
      <Box>
        <Typography variant={'h2'}>Onboarding Flow</Typography>
      </Box>
      <Box className={'flowItemsWrapper'}>
        <OnboardingFlowPage
          home
          page={
            {
              onboardingId,
              resource: {
                title: 'Welcome',
              },
            } as Partial<IOnboardingResourceOnboarding>
          }
          handleAddNew={handleAddNew}
        />
        {resourcePages.map((page, index) => (
          <OnboardingFlowPage
            key={page.id || `newResource-${index}`}
            index={index}
            handleMove={handleMove}
            handleDelete={handleDelete}
            handleAddNew={handleAddNew}
            page={page}
            hidePrevArrow={!(page.index === 1)}
            hideNextArrow={
              !(page.index === resourcePages.length)
            }
          />
        ))}
        <OnboardingFlowPage
          resources
          page={
            {
              onboardingId,
              resource: {
                title: 'Resources',
              },
            } as Partial<IOnboardingResourceOnboarding>
          }
        />
      </Box>
      <FlexRow mt={(theme) => theme.spacing(2)}>
        <Button
          variant={'outlined-primary'}
          onClick={handleCancelFlow}
        >
          Cancel
        </Button>
        <Button
          variant={'filled-secondary'}
          onClick={handleResetFlow}
        >
          Reset
        </Button>
        <Button
          variant={'filled-primary'}
          onClick={handleSaveFlow}
        >
          Save
        </Button>
      </FlexRow>
      <SearchableDialog
        title={'Choose Page to Add'}
        rows={unusedResources.map((resource) => ({
          name: `${resource.title} - ${resource.type}`,
          id: resource.id,
        }))}
        onClose={addResourceToFlow}
        isOpen={isModalOpen}
        hasSearch
        dataIsLoading={false}
        testKey={'onboarding-flow'}
      />
    </StyledFlowWrapper>
  );
};

export default OnboardingFlow;
