export default ({ theme }) => ({
  '& .container': {
    position: 'relative',
    maxWidth: 1024,
    margin: '0 auto'
  },
  '& .paper': {
    position: 'absolute',
    right: 0,
    top: 0,
    maxWidth: 414,
    minHeight: 'calc(100vh - 22px)',
    margin: '11px 13px',
    flexDirection: 'row',
    [theme.breakpoints.down('desktopApp')]: {
      position: 'relative',
      top: 'initial',
      right: 'initial',
      minHeight: 'calc(100% - 50px)',
      maxHeight: 'calc(100% - 50px)',
      width: 'calc(100% - 32px)',
      margin: '20px 16px 13px'
    }
  },
  '& .backdrop': {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('desktopApp')]: {
      backgroundColor: 'rgba(255,255,255,0.2)'
    }
  },
  '& .closeJobContainer': { width: '100%', padding: '2rem' },
  '& .header': {
    paddingBottom: '1rem',
    fontSize: 24,
    color: theme.palette.primary.light
  },
  '& .message': { paddingBottom: '.75rem' },
  '& .jobTitle': { color: theme.palette.primary.light },
  '& .hiredOnQH': {
    position: 'absolute',
    left: 10,
    top: 7,
    height: 35,
    width: 35,
    border: `1px solid ${theme.palette.grey['400']}`,
    borderRadius: 8,
    pointerEvents: 'none'
  },
  '& .ratingContainer': {
    fontSize: 40,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 350,
    justifyContent: 'space-between'
  },
  '& .rating': {
    cursor: 'pointer',
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .comments': {
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    borderRadius: 8,
    borderColor: theme.palette.grey['400']
  },
  '& .button': {
    [theme.breakpoints.down('desktopApp')]: {
      paddingBottom: '2rem'
    }
  },
  '& .checkbox': {
    border: 'none',
    '& .StyledCheckbox-label': {
      marginLeft: '13px'
    }
  }
});
