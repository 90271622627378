import React from 'react';
import { Box, styled } from 'components';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import { IconButton } from '../../shared';
import { dashboardHeaderStyles } from '../../../styles/Retention';

const StyledBox = styled(Box)(dashboardHeaderStyles);

interface IDashboardHeader {
  dashboardTitle: string;
  handleChangeLayout?: () => void;
  canChangeFormat?: boolean;
  handleFilterResults?: () => void;
  canFilterResults?: boolean;
  handleSearchResults?: () => void;
  canSearchResults?: boolean;
}

const DashboardHeader = ({
  dashboardTitle,
  handleChangeLayout = null,
  canChangeFormat = false,
  handleFilterResults = null,
  canFilterResults = false,
  handleSearchResults = null,
  canSearchResults = false,
}: IDashboardHeader) => (
    <StyledBox>
      <Box className={'dashboard-title'}>
        <Typography variant={'h2'} paragraph>
          {dashboardTitle}
        </Typography>
      </Box>
      <Box className={'dashboard-actions'}>
        {handleChangeLayout && (
          <IconButton
            disabled={!canChangeFormat}
            variant="filled-primary"
            aria-label="Change Visual Format"
            className="retention-filter-nav-button"
            onClick={handleChangeLayout}
          >
            <FormatListBulletedIcon />
          </IconButton>
        )}
        {handleFilterResults && (
          <IconButton
            disabled={!canFilterResults}
            variant="filled-primary"
            aria-label="Filter Results"
            className="retention-filter-nav-button"
            onClick={handleFilterResults}
          >
            <TuneIcon />
          </IconButton>
        )}
        {handleSearchResults && (
          <IconButton
            disabled={!canSearchResults}
            variant="filled-primary"
            aria-label="Search Results"
            className="retention-filter-nav-button"
            onClick={handleSearchResults}
          >
            <SearchIcon />
          </IconButton>
        )}
      </Box>
    </StyledBox>
  );

export default DashboardHeader;
