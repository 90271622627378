import { flexCenter } from '../../mixins';

const interactionsStyles = () => ({
  '& .interaction-title-container': {
    ...flexCenter,
    flexDirection: 'column',
  },
  '& .navButtonWrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .interaction-title-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .titleWrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 16,
    '& h1, h4': {
      marginBottom: 8
    }
  }
});

export default interactionsStyles;
