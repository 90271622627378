import React, { useEffect } from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import { useJobTypesQuery } from 'hooks';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.pathComingSoon': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.text
  },
  '& .pathComingSoon__title': {
    fontSize: 18,
    color: theme.palette.anomaly.main
  },
  '& .pathComingSoon__jobTitle': {
    maxWidth: 310,
    margin: '0 auto',
    paddingBottom: 10,
    fontWeight: 100,
    fontSize: 30,
    color: theme.palette.text.primary,
    textAlign: 'center'
  }
}));

const PathComingSoon = ({ futureJobTypeId }) => {
  const { getJobTypes, jobTypes, findJobTypeById } = useJobTypesQuery();
  const jobTitle = futureJobTypeId ? findJobTypeById(futureJobTypeId, jobTypes)?.name : '';

  useEffect(() => {
    getJobTypes();
  }, []);

  return (
    <StyledRoot className="pathComingSoon">
      <h2 className="pathComingSoon__title">Coming soon</h2>
      <h3 className="pathComingSoon__jobTitle">{jobTitle}</h3>
      <Box mb="10px">Get notified when this track becomes available</Box>
      <Button variant="filled-primary" sx={{ mb: '30px' }}>
        Notify Me
      </Button>
      <div>or try one of our available tracks</div>
    </StyledRoot>
  );
}

PathComingSoon.propTypes = {
  futureJobTypeId: PT.number.isRequired
};

export default PathComingSoon;
