import React, { useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import { Button } from 'components/shared';
import { MdChevronRight } from 'components/icons';
import { jobDefaultImg } from 'assets';
import { getRoutes, formatJobAddress } from 'utils';
import { useCountryStateQuery } from 'hooks';

const compName = 'SimpleJobCard';
const classes = {
  container: `${compName}-container`,
  image: `${compName}-img`,
  title: `${compName}-title`,
  text: `${compName}-text`
};
const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.container}`]: {
    width: '100%',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    borderRadius: 8,
    backgroundColor: theme.palette.grey['800'],
    color: theme.palette.common.white
  },
  [`& .${classes.image}`]: {
    objectFit: 'cover',
    width: 96,
    height: 96,
    borderRadius: 5
  },
  [`& .${classes.title}`]: {
    marginBottom: 3,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '130%'
  },
  [`& .${classes.text}`]: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '150%',
    color: theme.palette.grey['200']
  }
}));

const SimpleJobCard = (props) => {
  const { job } = props;
  const { id, title, payRange, city, countryId, stateId, zip, imageUrl, employerProfile } = job;

  const { fetchCountryState, getCountryById, getStateById } = useCountryStateQuery();
  const selectedState = useMemo(() => getStateById(stateId), [getStateById, stateId]);
  const selectedCountry = useMemo(() => getCountryById(countryId), [getCountryById, countryId]);

  useEffect(() => {
    fetchCountryState();
  }, []);

  const address = formatJobAddress({
    city,
    zip,
    country: selectedCountry.code,
    state: selectedState.code
  });

  return (
    <StyledButton isRouterLink to={getRoutes({ id }).employee.job} className={classes.container}>
      <img className={classes.image} src={imageUrl || jobDefaultImg} alt="" />
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.text}>{`@${employerProfile.name} | ${address}`}</div>
        <div className={classes.text}>{payRange}</div>
      </div>
      <MdChevronRight color="inherit" sx={{ mr: 0, ml: 'auto' }} />
    </StyledButton>
  );
}

SimpleJobCard.propTypes = {
  job: PT.shape({
    id: PT.number,
    title: PT.string,
    imageUrl: PT.string,
    payRange: PT.string,
    city: PT.string,
    countryId: PT.string,
    stateId: PT.string,
    zip: PT.string,
    employerProfile: PT.shape({
      name: PT.string
    })
  }).isRequired
};

export default SimpleJobCard;
