import React from 'react';
import PT from 'prop-types';
import { Box, Dialog, styled } from 'components';
import { Button } from 'components/shared';

const InterviewCancelAlert = (props) => {
  const { className, isOpen, onClose, onConfirm } = props;

  return (
    <Dialog open={isOpen} fullWidth className={className} onClose={onClose}>
      <Box py="36px" px="68px">
        <div className="title">You’ve been busy.</div>
        <Box mb="30px" className="text">
          You’ve canceled or re-scheduled too many times. If you choose to reschedule again, you
          will not be allowed to reschedule or cancel interviews for 7 days to prevent conflicting
          appointments for employers and employees. Do you want to continue?
        </Box>
        <Box display="flex" justifyContent="center">
          <Button className="action" variant="filled-primary" onClick={onClose}>
            No
          </Button>
          <Button className="action" variant="outlined-primary" onClick={onConfirm}>
            Yes
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

InterviewCancelAlert.propTypes = {
  className: PT.string.isRequired,
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired
};

const Styled = styled(InterviewCancelAlert)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: 490
  },
  '& .title': {
    marginBottom: 30,
    fontSize: 17,
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.primary
  },
  '& .text': {
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.primary.text
  },
  '& .action': {
    width: 164,
    height: 45,
    borderRadius: 15,
    '&:not(:first-of-type)': {
      marginLeft: 25
    }
  }
}));

export default Styled;
