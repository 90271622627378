import CustomizableChart from './CustomizableChart';
import { IChartBaseProps } from './chartUtils';

const LineChart = ({
  label,
  height,
  width,
  data: chartData,
  chartLabels,
  hasChartGrid,
  hasChartLabels,
}: IChartBaseProps) => (
    <CustomizableChart
      type={'line'}
      label={label}
      data={chartData}
      chartLabels={chartLabels}
      hasChartGrid={hasChartGrid}
      datasets={[
        {
          data: chartData,
          borderColor: '#9D7FE6',
          borderWidth: 1,
          tension: 0.1,
          pointBackgroundColor: '#bca7f1',
          pointRadius: 2,
        },
      ]}
      chartConfig={{}}
      hasChartLabels={hasChartLabels}
      height={height}
      width={width}
    />
  );

export default LineChart;
