import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ONBOARDING_ASSIGNMENT_BY_EMPLOYEE_ID,
  ONBOARDING_DOCUMENTS_BY_EMPLOYEE_ID,
  ONBOARDING_RESOURCES_BY_EMPLOYEE_ID,
  ONBOARDING_STATUS,
} from '../api/queries/onboarding';
import {
  IOnboardingAssignmentByEmployeeIdQuery,
  IOnboardingDocumentsByEmployeeId,
  IOnboardingResourcesByEmployeeIdResp,
  IOnboardingStatusQuery,
} from '../api/types/queries/onboarding.types';
import { UPDATE_ONBOARDING_STATUS } from '../api/mutations/onboarding';

const useEmployeeOnboarding = () => {
  const [
    getOnboardingStatus,
    {
      data: onboardingStatus,
      error: onboardingStatusError,
      loading: onboardingStatusLoading,
    },
  ] = useLazyQuery<IOnboardingStatusQuery>(ONBOARDING_STATUS, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getOnboardingAssignments,
    {
      data: onboardingAssignments,
      loading: onboardingAssignmentsLoading,
      error: onboardingAssignmentsError,
    },
  ] = useLazyQuery<IOnboardingAssignmentByEmployeeIdQuery>(
    ONBOARDING_ASSIGNMENT_BY_EMPLOYEE_ID,
    {
      initialFetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  // TODO Broken
  const [
    getOnboardingResources,
    {
      data: onboardingResources,
      loading: onboardingResourcesLoading,
      error: onboardingResourcesError,
    },
  ] = useLazyQuery<IOnboardingResourcesByEmployeeIdResp>(
    ONBOARDING_RESOURCES_BY_EMPLOYEE_ID,
    {
      initialFetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  const [
    getOnboardingDocuments,
    {
      data: onboardingDocuments,
      loading: onboardingDocumentsLoading,
      error: onboardingDocumentsError,
    },
  ] = useLazyQuery<IOnboardingDocumentsByEmployeeId>(
    ONBOARDING_DOCUMENTS_BY_EMPLOYEE_ID,
    {
      initialFetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  // end queries
  // start mutations

  const [
    updateOnboardingStatus,
    {
      data: updateOnboardingStatusData,
      loading: updateOnboardingStatusLoading,
      error: updateOnboardingStatusError,
    },
  ] = useMutation(UPDATE_ONBOARDING_STATUS, {
    fetchPolicy: 'no-cache',
  });

  return {
    status: {
      get: getOnboardingStatus,
      data: onboardingStatus,
      loading: onboardingStatusLoading,
      error: onboardingStatusError,
      save: updateOnboardingStatus,
      saveData: updateOnboardingStatusData,
      saveLoading: updateOnboardingStatusLoading,
      saveError: updateOnboardingStatusError,
    },
    assignments: {
      get: getOnboardingAssignments,
      data: onboardingAssignments,
      loading: onboardingAssignmentsLoading,
      error: onboardingAssignmentsError,
    },
    resources: {
      get: getOnboardingResources,
      data: onboardingResources,
      loading: onboardingResourcesLoading,
      error: onboardingResourcesError,
    },
    documents: {
      get: getOnboardingDocuments,
      data: onboardingDocuments,
      loading: onboardingDocumentsLoading,
      error: onboardingDocumentsError,
    },
  };
};

export default useEmployeeOnboarding;
