import React, {
  useCallback,
  useState,
  useEffect,
  memo,
} from 'react';
import { useMutation } from '@apollo/client';
import { qaAttr } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useMediaQueryMatches } from 'hooks';
import { styled, Switch } from 'components';
import { useForm } from 'components/form';
import { UPDATE_USER_NOTIFICATIONS } from 'api';
import styles from 'styles/Profile/EmployeeProfile';
import SectionLayout, {
  ISectionLayout,
} from '../../Profile/SectionLayout';

const StyledRoot = styled('div')(styles);

interface INotificationsProps {
  sectionLayoutProps: ISectionLayout;
  notifications: {
    inappNewApplicants: boolean;
    emailNewApplicants: boolean;
    inappInboxMessages: boolean;
    emailInboxMessages: boolean;
    inappTimeRespondEnds: boolean;
    emailTimeRespondEnds: boolean;
  };
}

const Notifications = (props: INotificationsProps) => {
  const { notifications, sectionLayoutProps } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [updateEmployeeNotification] = useMutation(
    UPDATE_USER_NOTIFICATIONS,
  );
  const [isDirty, setIsDirty] = useState(false);
  const { attrs, set, $ } = useForm();
  const {
    emailNewJobs,
    emailNewMessages,
    emailSchedUpdates,
    emailTips,
    inappNewJobs,
    inappNewMessages,
    inappSchedUpdates,
    inappTips,
    textNewJobs,
    textNewMessages,
    textSchedUpdates,
    textTips,
  } = attrs;

  useEffect(() => {
    set(notifications);
  }, [JSON.stringify(notifications)]);

  useEffect(() => {
    const update = async () => {
      await updateEmployeeNotification({
        variables: { ...attrs },
      });
    };
    if (isDirty) update();
  }, [attrs]);

  const handleSwitchChange = useCallback((event, { name }) => {
    setIsDirty(true);
    set(name, event.target.checked);
  }, []);

  const sectionHeader = isDesktop ? (
    <div className="notice__sectionHeader">
      <div className="notice__sectionHeader__item">Push</div>
      <div className="notice__sectionHeader__item">Email</div>
      <div className="notice__sectionHeader__item">Text</div>
    </div>
  ) : null;

  return (
    <SectionLayout
      {...sectionLayoutProps}
      sectionHeaderContent={sectionHeader}
      sectionHeaderProps={{
        style: {
          display: 'flex',
          justifyContent: 'space-between',
        },
      }}
    >
      <StyledRoot className="notice__container">
        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">
                New Messages
              </h3>
              <p className="label notice__descr__text">
                Receive notifications when you have received new
                applicants on your job&nbsp;posting.
              </p>
            </div>

            <div className="notice__actionsContainer">
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Push
                </div>
                <Switch
                  {...$('inappNewMessages', handleSwitchChange)}
                  checked={inappNewMessages || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'in-app new messages',
                    ...qaAttr('in-app-new-messages-checkbox'),
                    onFocus: () => {
                      track(
                        'In-app new messages focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>

              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Email
                </div>
                <Switch
                  {...$('emailNewMessages', handleSwitchChange)}
                  checked={emailNewMessages || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'email new messages',
                    ...qaAttr('email-new-messages-checkbox'),
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Text
                </div>
                <Switch
                  {...$('textNewMessages', handleSwitchChange)}
                  checked={textNewMessages || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'text new messages',
                    ...qaAttr('text-new-messages-checkbox'),
                    onFocus: () => {
                      track(
                        'Text new messages focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">
                New Job Matches
              </h3>
              <p className="label notice__descr__text">
                Receive notifications about daily job statistics,
                QH Matches and other items relative to
                your&nbsp;postings.
              </p>
            </div>
            <div className="notice__actionsContainer">
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Push
                </div>
                <Switch
                  {...$('inappNewJobs', handleSwitchChange)}
                  checked={inappNewJobs || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'in-app new job matches',
                    ...qaAttr('in-app-new-jobs-checkbox'),
                    onFocus: () => {
                      track(
                        'In-app new job matches focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Email
                </div>
                <Switch
                  {...$('emailNewJobs', handleSwitchChange)}
                  checked={emailNewJobs || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'email new job matches',
                    ...qaAttr('email-new-jobs-checkbox'),
                    onFocus: () => {
                      track(
                        'Email new job matches focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Text
                </div>
                <Switch
                  {...$('textNewJobs', handleSwitchChange)}
                  checked={textNewJobs || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'text new job matches',
                    ...qaAttr('text-new-jobs-checkbox'),
                    onFocus: () => {
                      track(
                        'Text new job matches focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">
                Scheduling Updates
              </h3>
              <p className="label notice__descr__text">
                Receive notifications about new interviews,
                cancellations, and scheduling&nbsp;changes.
              </p>
            </div>
            <div className="notice__actionsContainer">
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Push
                </div>
                <Switch
                  {...$('inappSchedUpdates', handleSwitchChange)}
                  checked={inappSchedUpdates || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'in-app Schedule Updates',
                    ...qaAttr('in-app-wt-tips-checkbox'),
                    onFocus: () => {
                      track(
                        'In-app Schedule Updates focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Email
                </div>
                <Switch
                  {...$('emailSchedUpdates', handleSwitchChange)}
                  checked={emailSchedUpdates || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'email Schedule Updates',
                    ...qaAttr('email-wt-tips-checkbox'),
                    onFocus: () => {
                      track(
                        'Email Schedule Updates focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Text
                </div>
                <Switch
                  {...$('textSchedUpdates', handleSwitchChange)}
                  checked={textSchedUpdates || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'email Schedule Updates',
                    ...qaAttr('text-wt-tips-checkbox'),
                    onFocus: () => {
                      track(
                        'Text Schedule Updates focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">Tips</h3>
              <p className="label notice__descr__text">
                This feature is coming&nbsp;soon
              </p>
            </div>
            <div className="notice__actionsContainer">
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Push
                </div>
                <Switch
                  {...$('inappTips', handleSwitchChange)}
                  checked={inappTips || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'in-app WT tips',
                    ...qaAttr('in-app-wt-tips-checkbox'),
                    onFocus: () => {
                      track(
                        'In-app WT tips focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Email
                </div>
                <Switch
                  {...$('emailTips', handleSwitchChange)}
                  checked={emailTips || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'email WT tips',
                    ...qaAttr('email-wt-tips-checkbox'),
                    onFocus: () => {
                      track(
                        'Email WT tips focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
              <div className="notice__switch__wrapper">
                <div className="notice__header__item hide__on__desktop">
                  Text
                </div>
                <Switch
                  {...$('textTips', handleSwitchChange)}
                  checked={textTips || false}
                  color="primary"
                  className="notif__switch"
                  inputProps={{
                    'aria-label': 'text WT tips',
                    ...qaAttr('text-wt-tips-checkbox'),
                    onFocus: () => {
                      track(
                        'Text WT tips focused (employee profile)',
                      );
                    },
                  }}
                  classes={{ root: 'notice__switchRoot' }}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledRoot>
    </SectionLayout>
  );
};

export default memo(Notifications);
