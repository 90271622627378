import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import {
  EMPLOYEE_PATH_STATS,
  GET_PATH_BY_LOCATION,
  PATH_STATS_BY_LOCATION,
} from 'api';
import EmployerDetailContainer from '../../../../components/Retention/components/Location/LocationDashboardRenderer';
import LocationDetailContainer from '../../../../components/Retention/components/Location/LocationDetailContainer';
import MainSectionContainer from '../../../../components/Retention/components/Location/MainSectionContainer';
import MetricContainer from '../../../../components/Retention/components/Location/MetricContainer';
import pathAside from '../../../../components/Retention/components/Location/Path/PathAside';
import PathManagement from '../../../../components/Retention/components/Location/Path/PathManagement';
import { RetentionContext } from '../../../../components/Retention/utils';
import { Spinner } from '../../../../components/shared';

const LocationPathEntry = () => {
  const ctx = useContext(RetentionContext);
  const { locationId, locationName: locName } = ctx;
  const [addedEmployeeToPath, setAddedEmployeeToPath] =
    useState(false);

  const [
    getPathsByLocation,
    {
      data: pathByLocationData,
      loading: pathByLocationDataIsLoading,
      refetch: refetchGetPathByLocation,
    },
  ] = useLazyQuery(GET_PATH_BY_LOCATION, {
    variables: { locationId },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const {
    refetch: refetchPathStatsByLocation,
    ...pathStatsByLocationQuery
  } = useQuery(PATH_STATS_BY_LOCATION, {
    variables: {
      locationId: ctx.locationId,
    },
    fetchPolicy: 'network-only',
  });

  const {
    refetch: refetchEmployeePathStats,
    ...employeePathStatsQuery
  } = useQuery(EMPLOYEE_PATH_STATS, {
    variables: {
      locationId: ctx.locationId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getPathsByLocation();
  }, []);

  useEffect(() => {
    if (addedEmployeeToPath) refetchGetPathByLocation();
  }, [addedEmployeeToPath]);

  if (
    pathByLocationDataIsLoading ||
    pathByLocationData == null
  ) {
    return <Spinner />;
  }

  const {
    locationName,
    completion = 0,
    mood = {},
    paths = {},
  } = pathByLocationData && pathByLocationData.getPathByLocation;

  const { count: moodCount = 'N/A', total: moodTotal = 'N/A' } =
    mood;
  const {
    active: activePaths = 'N/A',
    total: pathTotal = 'N/A',
  } = paths;

  return (
    <EmployerDetailContainer
      renderAsideResources={pathAside({
        addedEmployeeToPath,
        setAddedEmployeeToPath,
        pathStatsByLocationQuery: {
          ...pathStatsByLocationQuery,
          refetch: refetchPathStatsByLocation,
        },
        employeePathStatsQuery: {
          ...employeePathStatsQuery,
          refetch: refetchEmployeePathStats,
        },
      })}
      classes={{
        asideRoot: 'aside-root-path-override',
        contentRoot: 'content-root-path-override',
      }}
    >
      <LocationDetailContainer
        locationName={locationName || locName}
        renderLocationSnapshot={() => [
          <MetricContainer
            title="Active Paths"
            data={
              <>
                {activePaths} <sub>of</sub> {pathTotal}
              </>
            }
          />,
          <MetricContainer
            title="Skills Completion %"
            data={
              <>
                {(Math.round(completion * 100) * 1e2) / 1e2}
                <sup>%</sup>
              </>
            }
          />,
          <MetricContainer
            title="Employee Mood"
            data={
              <>
                {moodCount} <sub>of</sub> {moodTotal}
              </>
            }
          />,
        ]}
      />
      <MainSectionContainer>
        <PathManagement
          setAddedEmployeeToPath={setAddedEmployeeToPath}
          refetchPathStatsByLocation={refetchPathStatsByLocation}
          refetchEmployeePathStats={refetchEmployeePathStats}
          refetchGetPathByLocation={refetchGetPathByLocation}
        />
      </MainSectionContainer>
    </EmployerDetailContainer>
  );
};

export default LocationPathEntry;
