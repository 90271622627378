import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { format as formatDate } from 'date-fns';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_SURVEY_CAMPAIGNS } from 'api';
import {
  Box,
  FormHelperText,
  styled,
} from 'components';
import {
  MdArrowForward,
  MdDelete,
} from 'components/icons';
import { Button, Spinner } from 'components/shared';
import styles from 'styles/Retention/Survey/Surveys';
import { getRoutes } from 'utils';
import { RetentionContext } from '../../../../../components/Retention/utils';

const StyledBox = styled(Box)(styles);

const Surveys = () => {
  const ctx = useContext(RetentionContext);
  const navigate = useNavigate();
  const routes= getRoutes();
  const location = useLocation();
  const locationId = location?.state?.locationId || ctx.locationId;

  const {
    data,
    loading = false,
    error,
  } = useQuery(GET_SURVEY_CAMPAIGNS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      locationId,
    },
  });

  return (
    <StyledBox>
      <Box className="survey-item-container">
        {loading && <Spinner />}
        {error && (
          <FormHelperText error>{error.message}</FormHelperText>
        )}
        {!loading &&
          !error &&
          data.surveyCampaigns.map((s) => (
            <Box
              key={s.id}
              className="link-item"
              onClick={() =>
                navigate(
                  `${routes.employer.retention_portal.interactions.send_survey}/${s.id}`,
                  {
                    replace: true,
                    state: {
                      locationId,
                    },
                  },
                )
              }
            >
              <Box>
                <Typography variant="h2">{s.name}</Typography>
                <Typography variant="h4">
                  {formatDate(
                    new Date(s.createdAt),
                    'MM/dd/yyyy',
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  className="survey-usage"
                >
                  <Typography variant="caption">user</Typography>
                  0
                  <Typography variant="caption">
                    times
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Button
                  disabled
                  className="delete-survey-button"
                >
                  <MdDelete
                    sx={{
                      marginTop: '17px',
                    }}
                  />
                </Button>
              </Box>
              <Box>
                <MdArrowForward />
              </Box>
            </Box>
          ))}
      </Box>
    </StyledBox>
  );
};

export default Surveys;
