import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { getUserId } from 'utils';
import {
  useEmployerProfileQuery,
  useMediaQueryMatches,
} from 'hooks';
import { styled } from 'components';
import { Spinner } from 'components/shared';
import {
  Account,
  Profile,
  ManagePlan,
  ManageAccess,
  Notifications,
  Theme,
  Preferences,
} from 'components/Profile/employer';
import { GET_ADMIN_ACCOUNTS } from 'api';
import styles from 'styles/Profile/EmployerProfile';

const StyledRoot = styled('div')(styles);

const PROFILE_SEC = 'Profile';
const THEME = 'Theme';
const PREFERENCES = 'Preferences';
const NOTIFICATIONS_SEC = 'Notifications';
const ACCOUNT_SEC = 'Account';
const PLAN_SEC = 'Manage Plan';
const ADMIN_SEC = 'Manage Access';
const sections = [
  { name: PROFILE_SEC, Component: Profile, jumpLink: 'profile' },
  { name: THEME, Component: Theme, jumpLink: 'theme' },
  {
    name: PREFERENCES,
    Component: Preferences,
    jumpLink: 'preferences',
  },
  {
    name: ADMIN_SEC,
    Component: ManageAccess,
    jumpLink: 'manage-access',
  },
  {
    name: PLAN_SEC,
    Component: ManagePlan,
    jumpLink: 'manage-plan',
  },
  {
    name: NOTIFICATIONS_SEC,
    Component: Notifications,
    jumpLink: 'notifications',
  },
  { name: ACCOUNT_SEC, Component: Account, jumpLink: 'account' },
];

const INIT_FORM = {
  address: '',
  description: '',
  imageUrl: '',
  industry: [],
  name: '',
  newImageFile: null,
  phone: '',
  size: '',
  timeZone: '',
  website: '',
};

const notificationKeys = [
  'inappNewApplicants',
  'emailNewApplicants',
  'inappInboxMessages',
  'emailInboxMessages',
  'inappTimeRespondEnds',
  'emailTimeRespondEnds',
];

const EmployerProfile = () => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const [expandedSection, setExpandedSection] = useState(
    sections[0].name,
  );
  const [fullProfile, setFullProfile] = useState({});
  const [profile, setProfile] = useState<any>(INIT_FORM);
  const [subsData, setSubsData] = useState({});
  const [managedAccounts, setManagedAccounts] = useState([]);
  const [notifications, setNotifications] = useState(() =>
    reduce(
      notificationKeys,
      (res, val) => {
        res[val] = false;
        return res;
      },
      {},
    ),
  );

  const {
    profile: fetchedProfile,
    loading: profileLoading = true,
    refetch: refetchEmployerProfile,
  } = useEmployerProfileQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [fetchAdminAccounts, { loading: adminAccountsLoading }] =
    useLazyQuery(GET_ADMIN_ACCOUNTS, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      onCompleted: (data) => {
        const fetchedUsers = data?.getAdminAccounts || {};
        if (fetchedUsers) {
          setManagedAccounts(fetchedUsers);
        }
      },
    });

  useEffect(() => {
    const userId = parseInt(getUserId());
    if (userId) {
      fetchAdminAccounts({ variables: { userId } });
    }
  }, []);

  useEffect(() => {
    if (fetchedProfile) {
      const {
        allowPlan = false,
        address = '',
        city = '',
        countryId = 1, // default USA
        description = '',
        industry = [],
        imageUrl = '',
        name = '',
        newSubscriptionPlan,
        phone = '',
        size = '',
        showPlanModal = 'false',
        stateId = '',
        subscriptionPlan,
        timeZone = '',
        trialTimePlan,
        website = '',
        zip = '',
      } = fetchedProfile;
      const formattedIndustry = industry.length
        ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
          industry.map(({ __typename, ...rest }) => ({
            ...rest,
          }))
        : [];
      const profileNotifications = reduce(
        notificationKeys,
        (res, val) => {
          res[val] = fetchedProfile[val];
          return res;
        },
        {},
      );

      setFullProfile(fetchedProfile);
      setProfile((prev) => ({
        ...prev,
        address: address || '',
        city: city || '',
        countryId: countryId || '',
        description: description || '',
        industry: formattedIndustry,
        imageUrl,
        name: name || '',
        phone: phone || '',
        size,
        stateId: stateId || '',
        timeZone,
        website: website || '',
        zip: zip || '',
      }));
      setNotifications(profileNotifications);
      setSubsData({
        allowPlan,
        showPlanModal,
        subscriptionPlan,
        newSubscriptionPlan,
        trialTimePlan,
      });
    }
  }, [JSON.stringify(fetchedProfile)]);

  const handleSectionExpand = useCallback((toExpand) => {
    setExpandedSection(toExpand);
  }, []);

  const getFullProfile = useCallback(() => {
    refetchEmployerProfile();
  }, [refetchEmployerProfile]);

  const getSectionProps = (name) => {
    switch (name) {
      case PROFILE_SEC: {
        return {
          fetchEmployerProfile: getFullProfile,
          profile,
          setExpandedSection,
        };
      }
      case NOTIFICATIONS_SEC: {
        return { notifications };
      }
      case PLAN_SEC: {
        return {
          fetchEmployerProfile: getFullProfile,
          subsData,
        };
      }
      case ADMIN_SEC: {
        return {
          usersLoading: adminAccountsLoading,
          users: managedAccounts,
        };
      }
      default:
        return {};
    }
  };

  const renderSections = () =>
    map(sections, ({ Component, name, jumpLink }, i) => {
      const isSubEmployer =
        managedAccounts?.[0]?.adminProfileId !==
        fullProfile.profile_id;
      if (
        name === ADMIN_SEC &&
        ((managedAccounts.length && isSubEmployer) ||
          !fullProfile.isAdmin)
      ) {
        // hide "Manage Access" if employer isn't admin or is managed by another admin
        return null;
      }
      if (name === PLAN_SEC && !fullProfile.allowPlan)
        return null;
      return (
        <Component
          key={`section__${i}`}
          sectionName={name}
          jumpLink={jumpLink}
          expanded={expandedSection === name}
          onExpand={handleSectionExpand}
          {...getSectionProps(name)}
        />
      );
    });

  const renderDesktopLayout = () => (
    <div className="desktopLayout">
      <div className="column sticky-column">
        {map(sections, ({ name, jumpLink }, i) => (
          <div key={`contentItem__${i}`} className="contentItem">
            <a href={`#${jumpLink}`}>{name}</a>
          </div>
        ))}
      </div>
      <div className="column">{renderSections()}</div>
    </div>
  );

  const renderMobileLayout = () => (
    <div className="mobileLayout">{renderSections()}</div>
  );

  if (isEmpty(fullProfile)) {
    return (
      <StyledRoot className="container">
        <div className="loaderOverlay">
          <Spinner size={60} />
        </div>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot className="container">
      {profileLoading && (
        <div className="loaderOverlay">
          <Spinner size={60} />
        </div>
      )}
      {isDesktop ? renderDesktopLayout() : renderMobileLayout()}
    </StyledRoot>
  );
};

EmployerProfile.propTypes = {};

export default EmployerProfile;
