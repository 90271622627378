import { Box } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRoutes } from '../../../../utils';
import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useAlerts } from '../../../../hooks';
import useEmployerOnboarding from '../../../../hooks/useEmployerOnboarding';
import { FieldValues } from 'react-hook-form';
import { IOnboardingDocument } from '../../../../api/types/queries/onboarding.types';
import DocumentForm from '../../../../components/employer/onboarding/DocumentForm';
import { Spinner } from '../../../../components/shared';

const OnboardingDocumentEdit = () => {
  const navigate = useNavigate();
  const routes = getRoutes();
  const location = useLocation();
  const onboardingId = get(location, 'state.onboardingId', null);
  const documentIdViaState = get(
    location,
    'state.resourceId',
    null,
  );
  const [documentId, setDocumentId] = useState<string | null>(
    documentIdViaState,
  );
  const [document, setDocument] =
    useState<IOnboardingDocument | null>(null);
  const { showToast } = useAlerts();

  const {
    documents: { update, oneById },
  } = useEmployerOnboarding();

  const getDocumentById = (dId: string) => {
    oneById
      .get({
        variables: {
          id: dId,
        },
      })
      .then((data) => {
        setDocument(data.data.onboardingDocument);
      })
      .catch(() => {
        showToast({
          title:
            "We've encountered an error, please refresh the page",
          isOpen: true,
        });
      });
  };

  useEffect(() => {
    if (!documentId) {
      // documentId was not passed through state, someone landed on the page directly or via reload.
      const pathParts = location.pathname.split('/');
      const documentIdPath = pathParts[pathParts.length - 1];
      if (!documentIdPath) {
        navigate(routes.employer.onboarding.root);
      }
      setDocumentId(documentIdPath);
      getDocumentById(documentIdPath);
    } else {
      getDocumentById(documentId);
    }
  }, []);

  const handleSave = async (formValues: FieldValues) => {
    await update
      .save({
        variables: formValues,
      })
      .then((data) => {
        setDocument(data.data.updateOnboardingDocument);
      })
      .catch((error) => {
        showToast({
          isOpen: true,
          title: 'There was an error saving the document',
        });
      });
  };
  const handleDelete = () => {
    console.log('delete onboarding document');
  };
  const handleCancel = () => {
    if (onboardingId) {
      const routesWithId = getRoutes({
        id: onboardingId,
      });
      navigate(routesWithId.employer.onboarding.edit);
    } else {
      navigate(routes.employer.onboarding.documents.list);
    }
  };

  if (oneById.loading) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      <DocumentForm
        onboardingDocument={document}
        handleSave={handleSave}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
    </Box>
  );
};

export default OnboardingDocumentEdit;
