import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import AvatarEditor from 'react-avatar-editor';
import { qaAttr, imageTypeHelperText } from 'utils';
import { useAlerts, useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { MdExclamation } from 'components/icons';
import styles from 'styles/Onboarding';
import { UPDATE_EMPLOYEE_PROFILE_IMG } from 'api';


const UploadLabel = styled('label')({
  width: '100%',
  marginBottom: 10,
  display: 'flex',
  justifyContent: 'center'
});

const StyledBox = styled(Box)(styles);

const UserPhotoForm = ({ calledToasts, setCalledToasts, formKey, form, onChange, onGoToNextStep }) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { showToast } = useAlerts();
  const [loading, setLoading] = useState(false);
  const editorRef = useRef();

  const [postEmployeeProfileImg] = useMutation(
    UPDATE_EMPLOYEE_PROFILE_IMG
  );

  useEffect(() => {
    if (calledToasts.indexOf(formKey) === -1) {
      setCalledToasts([...calledToasts, formKey]);
      showToast({
        title: 'We have tools to help guide you on your career journey',
        icon: () => <MdExclamation />,
        flourishText: '',
        position: { top: false, right: false }
      });
    }
  }, []);

  const handleImageUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = async () => {
      setLoading(true);
      onChange({ ...form, photo: file /*, preview: reader.result*/ });
      setLoading(false);
    };
    reader.onerror = () => {
      setLoading(false);
    };
    if (file) reader.readAsDataURL(file);
  };

  const postImage = async () => {
    if (editorRef.current && form.photo) {
      setLoading(true);
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const fileName = form.photo.name;
      const ext = fileName.split('.').pop();

      canvasScaled.toBlob(async (blob) => {
        const blobToFile = new File([blob], fileName);
        await postEmployeeProfileImg({ variables: { file: blobToFile } });
        setLoading(false);
        onGoToNextStep();
      }, `image/${ext}`);
    }
  };

  return (
    <StyledBox>
      <div className="pageContent">
        {form.photo && (
          <AvatarEditor
            ref={editorRef}
            image={form.photo}
            width={288}
            height={288}
            border={0}
            borderRadius="10px"
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.1}
            rotate={0}
            style={{ margin: '0 auto 16px', borderRadius: 10, cursor: 'move' }}
          />
        )}
        <Box
          component="input"
          accept="image/*"
          display="none"
          id="user-photo-input"
          type="file"
          onChange={handleImageUpload}
          {...qaAttr('onboarding-photo-input')}
        />
        <UploadLabel htmlFor="user-photo-input">
          <Button
            variant="filled-secondary"
            component="span"
            className="nextBtn"
            {...qaAttr('onboarding-photo-upload-button')}
          >
            Select from Library...
          </Button>
        </UploadLabel>
        <p style={{ marginBottom: 20, fontSize: 12, textAlign: 'left' }}>{imageTypeHelperText}</p>
        <div>
          <p className="primaryText" style={{ marginBottom: 9, fontStyle: 'italic' }}>
            Did you know?
          </p>
          <p className="secondaryText" style={{ marginBottom: 29 }}>
            Applicants with photos get 30% more interviews?
          </p>
        </div>
        <Button
          variant="filled-primary"
          endIcon={loading && <Spinner width={24} height={24} />}
          disabled={!form.photo || loading}
          className="nextBtn"
          sx={{ mb: isDesktop ? '25px' : '13px' }}
          onClick={postImage}
          testID="onboarding-next-button"
        >
          Next
        </Button>
      </div>
    </StyledBox>
  );
}

UserPhotoForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default UserPhotoForm;
