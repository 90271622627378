export default ({ theme }) => ({
  '& .filledLogo': {
    fill: theme.palette.text.primary,
  },
  '& .content': {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .contentHeader': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      minHeight: 56,
      display: 'block',
      marginBottom: 37,
      padding: 4,
      paddingRight: 16,
    },
  },
  '& .centeredBlock': {
    maxWidth: 320,
    margin: '0 auto',
  },
  '& .flexColumn': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .flexFullSpace': {
    flex: 1,
  },
  '& .pageTitle': {
    marginBottom: 16,
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: '26px',
  },
  '& .mainButton': {
    width: '100%',
    marginBottom: 16,
  },
  '& .mainContent': {
    padding: '0 16px',
    [theme.breakpoints.down('desktopApp')]: {
      flex: 1,
    },
  },
  '& .helperText': {
    marginTop: 6,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '17px',
  },
  '& .subPageHeader': {
    width: '100%',
    maxWidth: 352,
    margin: '0 auto',
    marginBottom: 34,
    paddingRight: 16,
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: '100%',
      marginBottom: 0,
    },
  },
  '& .subPageFooter': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'block',
      minHeight: 56,
    },
  },
  '& .signUp-verifyText': {
    marginBottom: 32,
    textAlign: 'center',
    lineHeight: '24px',
  },
  '& .signUp-verifyContent__body': {
    maxWidth: 450,
    margin: '-45px auto 0',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '& .signUp-verifyIconWrapper': {
    marginBottom: 34,
    fontSize: 36,
    lineHeight: 0,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  '& .signUp-resendLink': {
    padding: 0,
    color: theme.palette.common.white,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: 'inherit',
    verticalAlign: 'baseline',
  },
  '& .signUp-mailClients': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .signUp-mailClients__link': {
    height: 40,
    padding: '0 10px',
    fontSize: 14,
  },
  '& .resetPwd-title': {
    marginBottom: 34,
  },
  '& .resetPwd-text': {
    marginBottom: 32,
    textAlign: 'center',
    lineHeight: '24px',
  },
  '& .resetPwd-content__body': {
    maxWidth: 352,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      flex: 1,
    },
  },
  '& .resetPwd-resetSuccess__iconWrapper': {
    marginBottom: 25,
    fontSize: 36,
    lineHeight: 0,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  '& .loginInput': {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
  },
});
