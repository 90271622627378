import React, { useState, useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { qaAttr, getRoutes } from 'utils';
import { useAppStoreSelector } from 'store';
import {
  useCountryStateQuery,
  useEmployerProfileQuery,
  useMediaQueryMatches,
} from 'hooks';
import {
  Box,
  Dialog,
  styled,
  Pagination,
  TextField,
} from 'components';
import { Button, Spinner, Select } from 'components/shared';
import { JobCard } from 'components/Dashboard/employer';
import {
  GET_EMPLOYER_JOB_METRICS,
  GET_EMPLOYER_JOBS,
  GET_GROW_INDUSTRIES,
} from 'api';
import styles from 'styles/Dashboard/EmployerJobs';
import JobMetrics from './JobMetrics';

const StyledRoot = styled('div')(styles);

const ROUTES = getRoutes();
// need to get permissions at root level and set to local storage perhaps
const AI_ACCESS = true;

const EmployerJobs = () => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const employerJobsFilter = useAppStoreSelector(
    (state) => state.employerJobsFilter,
  );
  const setEmployerJobsFilter = useAppStoreSelector(
    (state) => state.setEmployerJobsFilter,
  );
  const hirePromptWasShown = useAppStoreSelector(
    (state) => state.hirePromptWasShown,
  );
  const setHirePromptWasShown = useAppStoreSelector(
    (state) => state.setHirePromptWasShown,
  );
  const newJobPromptWasShown = useAppStoreSelector(
    (state) => state.newJobPromptWasShown,
  );
  const setNewJobPromptWasShown = useAppStoreSelector(
    (state) => state.setNewJobPromptWasShown,
  );
  const [hirePromptOpen, setHirePromptOpen] = useState(false);
  const [newJobPromptOpen, setNewJobPromptOpen] =
    useState(false);

  const { canPostJob } = useEmployerProfileQuery();
  const { fetchCountryState, getStateById, getCountryById } =
    useCountryStateQuery();

  const [
    fetchJobs,
    { data: jobsData = {}, loading: jobsLoading = true, called },
  ] = useLazyQuery(GET_EMPLOYER_JOBS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const { data: industries } = useQuery(GET_GROW_INDUSTRIES, {
    fetchPolicy: 'cache-first',
  });

  const [fetchJobMetrics, { data: jobMetricsData = {} }] =
    useLazyQuery(GET_EMPLOYER_JOB_METRICS, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    });

  const jobsFetchComplete = !jobsLoading && called;
  const jobs = useMemo(
    () => jobsData?.employerJobs || [],
    [JSON.stringify(jobsData)],
  );

  const oldestJob = useMemo(() => {
    const jobByDays = jobs
      .map((job) => {
        const diff = new Date().getTime() - +job.createdAt;
        return [Math.ceil(diff / (1000 * 3600 * 24)), job];
      })
      .filter(([days]) => days >= 7);
    const { length } = jobByDays;

    if (length > 0) {
      const idx = Math.floor(Math.random() * (length - 0) + 0);
      return jobByDays[idx][1];
    }

    return null;
  }, [JSON.stringify(jobs)]);

  const getJobAddress = (job) => {
    const { city, stateId, countryId, zip } = job;
    const selectedState = getStateById(stateId);
    const selectedCountry = getCountryById(countryId);

    return city &&
      selectedCountry.name &&
      selectedState.code &&
      zip
      ? `${city}, ${selectedState.code}, ${zip}, ${selectedCountry.code}`
      : '';
  };

  useEffect(() => {
    fetchJobs({
      variables: { active: employerJobsFilter === 0 },
    });
    fetchJobMetrics();
  }, [employerJobsFilter]);

  useEffect(() => {
    fetchCountryState();
  }, []);

  useEffect(() => {
    if (
      employerJobsFilter === 0 &&
      oldestJob &&
      !hirePromptWasShown
    ) {
      setHirePromptWasShown(true);
      setHirePromptOpen(true);
    }
  }, [employerJobsFilter, oldestJob, hirePromptWasShown]);

  useEffect(() => {
    if (
      employerJobsFilter === 0 &&
      !newJobPromptWasShown &&
      jobsFetchComplete &&
      !jobs.length
    ) {
      setNewJobPromptWasShown(true);
      setNewJobPromptOpen(true);
    }
  }, [employerJobsFilter, jobsFetchComplete, jobs.length]);

  const renderHirePrompt = () => (
    <Dialog
      open={hirePromptOpen}
      container={() => document.getElementById('modals-root')}
      classes={{ paper: 'hirePrompt' }}
      onClose={() => setHirePromptOpen(false)}
    >
      <div className="hirePrompt__content">
        <div className="hirePrompt__title">Hey There</div>
        <div className="hirePrompt__descr">
          Looks like this jobs has been open for awhile, have you
          hired someone for it yet?
        </div>
        <div className="hirePrompt__jobTitle">
          {oldestJob?.title}
        </div>
      </div>
      <div className="hirePrompt__actions">
        <Button
          isRouterLink
          to={
            getRoutes({ id: oldestJob?.id }).employer
              .job_approved
          }
          className="hirePrompt__btn"
        >
          Hired Someone
        </Button>
        <Button
          className="hirePrompt__btn"
          onClick={() => setHirePromptOpen(false)}
        >
          Not Yet
        </Button>
      </div>
    </Dialog>
  );

  const renderNewJobPrompt = () => (
    <Dialog
      open={newJobPromptOpen}
      container={() => document.getElementById('modals-root')}
      classes={{ paper: 'hirePrompt' }}
      onClose={() => setNewJobPromptOpen(false)}
    >
      <Box p="20px">
        <Box component="p" textAlign="center">
          Looks like you don't have any active jobs
        </Box>
      </Box>
      <Box pb="20px" textAlign="center">
        <Button
          variant="filled-primary"
          isRouterLink
          to={ROUTES.employer.job_new}
          className="newJobPrompt__btn"
          disabled={!canPostJob}
        >
          Post a Job
        </Button>
      </Box>
    </Dialog>
  );

  const renderFilter = () => (
    <Box px="16px" pb="13px">
      <Select
        value={employerJobsFilter}
        inputVariant="outlined"
        options={[
          {
            label: 'Current Jobs',
            value: 0,
            ...qaAttr('my-jobs-dropdown-option-button'),
          },
          {
            label: 'Archived Jobs',
            value: 1,
            ...qaAttr('archived-jobs-dropdown-option-button'),
          },
        ]}
        InputComponentProps={{
          htmlInputClassName: 'filterInput',
        }}
        onChange={(e) => setEmployerJobsFilter(e.target.value)}
        testID="jobs-dashboard-dropdown-button"
      />
    </Box>
  );

  const jobsPerPage = 9;
  const [indexOfFirstJob, setIndexOfFirstJob] = useState(0);
  const [currentJobs, setCurrentJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [chosenIndustry, setChosenIndustry] = useState(null);

  const filteredJobs = jobs.filter((job) => {
    if (isEmpty(searchTerm)) {
      return job;
    }
    if (
      job.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return job;
    }
    return null;
  });

  useEffect(() => {
    setCurrentJobs(
      jobs.slice(indexOfFirstJob, indexOfFirstJob + jobsPerPage),
    );
  }, [jobs]);

  const handleOnChange = (event, value) => {
    const onChangeCurrentPage = value;
    const onChangeIndexOfFirstJob =
      onChangeCurrentPage * jobsPerPage - jobsPerPage;
    setIndexOfFirstJob(onChangeIndexOfFirstJob);
    // eslint-disable-next-line no-unused-expressions
    searchTerm === ''
      ? setCurrentJobs(
          jobs.slice(
            onChangeIndexOfFirstJob,
            onChangeIndexOfFirstJob + jobsPerPage,
          ),
        )
      : setCurrentJobs(
          filteredJobs.slice(
            onChangeIndexOfFirstJob,
            onChangeIndexOfFirstJob + jobsPerPage,
          ),
        );
  };

  useEffect(() => {
    setCurrentJobs(
      filteredJobs.slice(
        indexOfFirstJob,
        indexOfFirstJob + jobsPerPage,
      ),
    );
  }, [searchTerm]);

  return (
    <StyledRoot className="container">
      <h2 className="jobsContainerTitle">My Jobs</h2>
      {/*employerJobsFilter === 0 denotes Current Jobs Tab*/}
      {jobMetricsData && employerJobsFilter === 0 && (
        <JobMetrics {...jobMetricsData?.employerJobMetrics} />
      )}
      <div className="employerJobsSearchContainer">
        <TextField
          id="outlined-basic"
          className="employerJobsSearch"
          label="Search Jobs"
          name={'searchJobs'}
          variant="outlined"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
      </div>
      <div className="resultsTitle">
        {searchTerm ? (
          <span>Showing results for '{searchTerm}'</span>
        ) : (
          <span> </span>
        )}
      </div>

      {!isDesktop && renderFilter()}
      <div className={`jobsContainerSmall`}>
        {jobsLoading && (
          <div className="jobsLoader">
            <Spinner size={30} />
          </div>
        )}
        {(searchTerm ? filteredJobs : currentJobs).map((job) => (
          <JobCard
            key={job.id}
            job={job}
            routeTo={
              getRoutes({ id: job.id }).employer[
                job.draft ? 'job_edit' : 'job'
              ]
            }
            address={getJobAddress(job)}
          />
        ))}
      </div>
      {renderHirePrompt()}
      {renderNewJobPrompt()}
      <div id="modals-root" />
      <div className="jobsPaginatorWrapper">
        <Pagination
          count={Math.ceil(
            searchTerm !== ''
              ? filteredJobs.length / jobsPerPage
              : jobs.length / jobsPerPage,
          )}
          boundaryCount={10}
          onChange={handleOnChange}
        />
      </div>
    </StyledRoot>
  );
};

export default EmployerJobs;
