import React from 'react';
import PT from 'prop-types';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useAppStoreSelector } from '../../../store';
import { darken } from '@mui/material';

const AnimatedProgress = animated(
  ({ value, pathColor, trailColor, ...rest }) => (
    <CircularProgressbar
      styles={{
        root: { transform: 'rotate(-135deg)' },
        path: {
          stroke: pathColor,
          strokeLinecap: 'round',
        },
        trail: { stroke: trailColor, strokeLinecap: 'round' },
      }}
      value={value}
      circleRatio={0.75}
      strokeWidth={8}
      {...rest}
    />
  ),
);

const CircularProgress = ({ score, barProps }) => {
  const { colors, colorMode } = useAppStoreSelector(
    ({ colors: c, colorMode: cm }) => ({
      colors: c,
      colorMode: cm,
    }),
  );
  return (
    <Spring
      config={{
        duration: 1250,
        tension: 300,
        friction: 0,
        easing: easePolyOut.exponent(2.0),
      }}
      from={{ score: 0 }}
      to={{ score }}
    >
      {({ score: animScore }) => (
        <AnimatedProgress
          pathColor={colors[colorMode].primary.main}
          trailColor={darken(
            colors[colorMode].primary.main,
            0.5,
          )}
          value={animScore}
          {...barProps}
        />
      )}
    </Spring>
  );
};

CircularProgress.propTypes = {
  score: PT.number,
  barProps: PT.objectOf(PT.any),
  pathColor: PT.string,
  trailColor: PT.string,
};

CircularProgress.defaultProps = {
  score: 0,
  barProps: {},
  pathColor: '#b9b7b7',
  trailColor: '#717070',
};

export default CircularProgress;
