function trackGtmEvent(event = '', options = {}) {
  if (event && window?.dataLayer?.push) {
    window.dataLayer.push({ event, ...options });
  }
}

function gtmTrackOnSignIn(options = {}) {
  trackGtmEvent('sign_in', options);
}

function gtmTrackOnSignUp(options = {}) {
  trackGtmEvent('sign_up', options);
}

function gtmTrackJobAccept(employerId, options = {}) {
  if (employerId) {
    trackGtmEvent('event', {
      event_category: 'jobs',
      event_label: employerId,
      value: 1,
      ...options
    });
  }
}

function gtmTrackJobReject(employerId, options = {}) {
  if (employerId) {
    trackGtmEvent('event', {
      event_category: 'jobs',
      event_label: employerId,
      value: 0,
      ...options
    });
  }
}

function gtmTrackJobStar(employerId, options = {}) {
  if (employerId) {
    trackGtmEvent('event', {
      event_category: 'jobs',
      event_label: employerId,
      value: 2,
      ...options
    });
  }
}

function htmTrackPaymentProcessing(planPrice, planName, stripePaymentId, employerId){
  if(stripePaymentId) {
    trackGtmEvent('payment', {
      employerId,
      transactionId: stripePaymentId,
      subscriptionType: planName,
      subscriptionValue: `${planPrice ? planPrice / 100 : '0'}.00`
    })
  }
}

export {
  trackGtmEvent,
  gtmTrackOnSignIn,
  gtmTrackOnSignUp,
  gtmTrackJobAccept,
  gtmTrackJobReject,
  gtmTrackJobStar,
  htmTrackPaymentProcessing
};
