export default ({ theme }) => ({
  '&.chatContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  '& .chat__messagesArea': {
    flex: 1,
    overflowY: 'auto',
    padding: '8px 17px'
  },
  '& .chat__inputArea': {
    padding: 17,
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .chat__jobCardContainer': {
    width: '100%',
    padding: '8px 17px 24px',
    flex: 0
  },
  '& .jobCard': {
    height: 64,
    width: '100%',
    padding: 10,
    paddingRight: 12,
    borderRadius: 10,
    display: 'flex',
    backgroundColor: 'transparent'
  },
  '& .jobCard__img': {
    minWidth: 44,
    width: 44,
    height: 44,
    objectFit: 'cover',
    borderRadius: 6,
    backgroundColor: theme.palette.background.default
  },
  '& .secondaryText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '15px',
    color: theme.palette.text.primary
  },
  '& .mainText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    letterSpacing: 0,
    color: theme.palette.text.primary,
    opacity: 0.4
  },
  '& .chat__input': {
    '&::placeholder': {
      color: theme.palette.grey['100'],
      opacity: 0.5,
      fontSize: 14
    }
  },
  '& .chat__sendButton': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '15px',
    color: theme.palette.common.white
  },
  '& .chat__messageDate': {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    color: theme.palette.text.primary,
    opacity: 0.4
  },
  '& .chat__message': {
    '&.userMessage': {
      '& .chatMessage__txtMessage': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper
      }
    }
  },
  '& .headerOptionsMenuItem': {
    width: '100%',
    height: 55,
    extAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '15px',
    letterSpacing: 0,
    color: theme.palette.text.primary,
    '&.Mui-disabled': {
      opacity: 0.5
    }
  }
});
