import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon11 = (props) => (
    <SvgIcon viewBox="0 0 31.55 40.81" {...props}>
      <path
        d="M16.94,0C5.54,5.06,4.32,25.12,8.63,35.14,11.81,22.62,22.4,13.13,16.94,0Z"
        fill="currentColor"
      />
      <path
        d="M4.6,21.62c.13-7.6,2.68-13.33-.6-21.25C-3,3.63,.49,14.04,4.6,21.62Z"
        fill="currentColor"
      />
      <path
        d="M9.46,40.81c9.61-8.75,25.35-13.14,21.49-28.49-11.3,1.34-20.68,17.09-21.49,28.49Z"
        fill="currentColor"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon11;
