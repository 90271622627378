import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import map from 'lodash/map';
import { qaAttr, trimStr } from 'utils';
import { useCountryStateQuery } from 'hooks';
import { styled } from 'components';
import { Button, Input, Spinner, Select } from 'components/shared';
import { useForm } from 'components/form';
import { SET_COMPANY_ADDRESS } from 'api';

const INIT_ADDRESS_FORM = {
  city: '',
  countryId: 1, // USA
  stateId: '',
  zip: ''
};

const FormGroup = styled('div')(() => ({
  marginBottom: 25,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 46%)',
  gridGap: 20
}));

const AddressForm = ({ form, onChange, onGoToNextStep }) => {
  const { fetchCountryState, countries, states, getCountryById } = useCountryStateQuery();
  const [postCompanyAddress, { loading }] = useMutation(SET_COMPANY_ADDRESS);

  const { $, set, attrs, withValidation, useConfig } = useForm({
    initial: INIT_ADDRESS_FORM,
    validations: {
      address: 'presence',
      city: 'presence',
      countryId: ['presence', 'numericality'],
      stateId: ['presence', 'numericality']
    }
  });
  const { countryId } = attrs;

  const selectedCountryOption = useMemo(
    () => getCountryById(countryId),
    [getCountryById, countryId]
  );

  const currentStates = states.filter(
    (stateObj) => stateObj.country.name === selectedCountryOption?.name
  );

  useConfig(() => {
    if (selectedCountryOption.code) {
      return {
        validations: {
          zip: ['presence', { zip: { countryCode: selectedCountryOption.code } }]
        }
      };
    }
    return {};
  }, [selectedCountryOption.code]);

  useEffect(() => {
    fetchCountryState();
  }, []);

  useEffect(() => {
    if (selectedCountryOption.name) set({ stateId: '' });
  }, [selectedCountryOption.name]);

  const handleFieldChange = (e, { name }) => {
    set(name, e.target.value);
    onChange({ ...form, [name]: e.target.value });
  };

  const next = () => {
    onGoToNextStep(
      withValidation((validAttrs) =>
        postCompanyAddress({
          variables: {
            address: trimStr(validAttrs.address),
            city: trimStr(validAttrs.city),
            countryId: Number(validAttrs.countryId),
            stateId: Number(validAttrs.stateId),
            zip: trimStr(validAttrs.zip)
          }
        })
      )
    );
  };

  const selectProps = {
    native: false,
    required: true,
    withHelperText: true,
    withEmptyOption: true
  };

  return (
    <>
      <Input
        {...$('address', handleFieldChange)}
        variant="outlined"
        id="address"
        label="Street Address"
        required
        placeholder="128 Sample Street"
        autoComplete="off"
        withHelperText
        sx={{ mb: '20px' }}
        analyticParams={{
          key: 'Employer street address focused (onboarding)',
          trigger: 'focus'
        }}
        testID="onboarding-address-input"
      />
      <FormGroup>
        <Input
          {...$('city', handleFieldChange)}
          variant="outlined"
          id="city-input"
          label="City"
          required
          withHelperText
          analyticParams={{
            key: 'Employer city focused (onboarding)',
            trigger: 'focus'
          }}
          testID="city-input"
        />
        <Select
          {...$('stateId', handleFieldChange)}
          {...selectProps}
          id="stateId-select"
          label="State"
          analyticParams={{
            key: 'Employer state focused (onboarding)',
            trigger: 'focus'
          }}
          options={map(currentStates, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`state-option-${i}`)
          }))}
          testID="stateId-input"
        />
        <Input
          {...$('zip', handleFieldChange)}
          variant="outlined"
          id="zip-input"
          label="Zip Code"
          required
          withHelperText
          analyticParams={{
            key: 'Employer zip focused (onboarding)',
            trigger: 'focus'
          }}
          testID="zip-input"
        />
        <Select
          {...$('countryId', handleFieldChange)}
          {...selectProps}
          id="countryId-select"
          label="Country"
          analyticParams={{
            key: 'Employer country focused (onboarding)',
            trigger: 'focus'
          }}
          options={map(countries, (o, i) => ({
            value: o.id,
            label: o.name,
            ...qaAttr(`country-option-${i}`)
          }))}
          testID="countryId-select"
        />
      </FormGroup>
      <Button
        variant="filled-primary"
        sx={{ height: 50, width: '100%', mb: '24px' }}
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
    </>
  );
}

AddressForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AddressForm;
