import { Box, Typography } from '@mui/material';

interface IEmployeePathStat {
  currentPathJobType?: string;
  name: string;
  percentComplete: number;
  profileImageUrl: string;
}

const EmployeePathStat = ({
  currentPathJobType,
  name,
  percentComplete,
  profileImageUrl,
}: IEmployeePathStat) => (
    <Box>
      <Box className={'employee-path-stat-profile'}>
        <img src={profileImageUrl} alt="" />
        <Typography
          variant={'body2'}
          className={'percent-complete'}
        >
          {Math.round(percentComplete * 100)} <sup>%</sup>
          <span>complete</span>
        </Typography>
      </Box>
      <Box className={'employee-path-content-container'}>
        <Box className={'employee-path-stat-content'}>
          <Box>
            <Typography variant={'body2'}>{name}</Typography>
            <Typography
              variant={'h5'}
              className={
                currentPathJobType ? '' : 'no-path-assigned'
              }
            >
              {currentPathJobType || 'No Path Assigned'}
            </Typography>
          </Box>
        </Box>
        <Box className={'path-progress-container'}>
          <Box
            style={{
              width: `${percentComplete * 100}%`,
            }}
            className={'path-progress'}
          />
        </Box>
      </Box>
    </Box>
  );

export default EmployeePathStat;
