import React from 'react';
import { styled } from 'components';
import { LandingHeader } from 'components/shared';
import LineBackground from 'assets/svg/lineBackground.svg';
import AppLayout from './AppLayout';

const StyledRoot = styled(AppLayout)(({ theme }) => ({
  '& #siteLineBackground': {
    [theme.breakpoints.up('desktopApp')]: {
      position: 'fixed',
      height: '270%',
      width: '280%',
    },
    opacity: 0.15,
    // opacity: 1,
    zIndex: -1,
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    [theme.breakpoints.down('desktopApp')]: {
      height: '100vh',
      width: '100vw',
      position: 'absolute',
      svg: {
        height: '200vh',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
      },
    },
  },
}));

// eslint-disable-next-line react/prop-types
const LoginLayout = ({ children, className }) => (
  <StyledRoot
    className={className}
    headerElement={<LandingHeader />}
  >
    <div id="siteLineBackground">
      <LineBackground />
    </div>
    {children}
  </StyledRoot>
);

export default LoginLayout;
