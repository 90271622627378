import React, { useEffect, useState } from 'react';
import { Box } from 'components';
import { styled } from '@mui/material/styles';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  employerStartPage,
  getLocaleTimeZone,
  getRoutes,
  saveRequiredUserValues,
} from 'utils';
import {
  useAuth,
  useEmployeeProfileQuery,
  useMatchedRoutes,
  useTimezoneCommands,
} from 'hooks';
import { trackUserOriginByUrlParam } from 'api';
import { Spinner } from 'components/shared';
import { EmployeeHeader } from 'components/Header';
import EmployeeNavigation from '../../components/Employee/EmployeeNavigation';

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
  [theme.breakpoints.down('tablet')]: {
    paddingTop: theme.spacing(5),
  },
  '.employee-dashboard': {
    [theme.breakpoints.up('desktopApp')]: {
      display: 'grid',
      gridTemplateColumns: '65px calc(100% - 65px)',
      columnGap: theme.spacing(2),
      padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },
  '.worktorchLogo': {
    margin: '12.5px auto',
    paddingBottom: 12.5,
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    width: '80%',
    svg: {
      height: 32,
      width: '100%',
      display: 'block',
    },
  },
  [theme.breakpoints.down('desktopApp')]: {
    '& .worktorchLogo': {
      display: 'none',
    },
  },
  nav: {
    [theme.breakpoints.up('desktopApp')]: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      // 80 is nav height, 30 (15x2) for padding added to parent container
      maxHeight: `calc(100vh - 110px)`,
      position: 'sticky',
      top: theme.spacing(2),
      zIndex: 1,
      button: {
        margin: `${theme.spacing(1)} 0`,
      },
    },
    [theme.breakpoints.down('desktopApp')]: {
      position: 'fixed',
      // 84 pixels to take into account the chat button
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const EmployeeDashboard = () => {
  const routes = getRoutes();
  const { authed, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { saveTimezone } = useTimezoneCommands(null);
  const { isPublicRoute } = useMatchedRoutes();
  const { fetch: fetchEmployeeProfile } =
    useEmployeeProfileQuery({ autoFetch: false });
  const [isUserRoleChecked, setIsUserRoleChecked] =
    useState(false);

  const checkProfileByRole = async () => {
    const { data } = await fetchEmployeeProfile();
    const profile = data?.employeeProfile;

    if (profile) {
      const { profileWizard } = profile;
      // !!! unlike employer, employee can't change timezone in profile settings, so we save current user timezone on every login
      saveTimezone(
        getLocaleTimeZone(),
        'employee',
        profile.user_id,
      );

      if (profileWizard) {
        saveRequiredUserValues(profile);
        if (idParam)
          trackUserOriginByUrlParam(
            location.search,
            Number(idParam),
            null,
          ); // track job id page with special param
        return { checked: true };
      }
      return {
        checked: false,
        cb: () => navigate(routes.onboarding.default),
      };
    }

    return {
      checked: false,
      cb: !location?.state?.notEmployer
        ? // check if user is an employer if he wasn't checked before
          () =>
            navigate(employerStartPage, {
              state: { notEmployee: true },
            })
        : logout,
    };
  };

  const authMiddleware = async () => {
    if (authed) {
      const { checked, cb } = await checkProfileByRole();
      setIsUserRoleChecked(checked);
      if (cb) cb();
    } else {
      if (isPublicRoute) {
        // job is publicly available
        setIsUserRoleChecked(true);
        return;
      }
      navigate(routes.login.default, {
        state: { from: { location } },
      });
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  if (!isUserRoleChecked) {
    return (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner size={30} />
      </div>
    );
  }

  if (authed || isPublicRoute) {
    return (
      <StyledBox>
        <EmployeeHeader />
        <Box className={'employee-dashboard'}>
          <EmployeeNavigation />
          <Box className={'content-container'}>
            <Outlet />
          </Box>
        </Box>
      </StyledBox>
    );
  }

  return <Navigate to={routes.login.default} />;
};

export default EmployeeDashboard;
