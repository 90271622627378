import { baseGap, shadowMixin } from '../helpers';

const gridWidth = `calc(100% / 3 - (${baseGap}px * 2 / 3))`;

export const pulseEntryStyles = ({ theme }) => ({
  '& .interaction-container': {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    padding: baseGap,
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(1),
    },
  },
  '& .action-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    [theme.breakpoints.down('desktopApp')]: {
      flexWrap: 'wrap',
    },
  },
  '& .action-button-container': {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      flexWrap: 'wrap',
      justifyContent: 'normal',
      rowGap: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
    },
  },
  '& .message-action-type': {
    marginRight: 15,
  },
  '& .upcoming-events': {
    padding: baseGap,
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(1),
    },
  },
  '& .employee-container': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 15,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  '& .chart-container': {
    display: 'grid',
    paddingBottom: baseGap,
    paddingLeft: baseGap,
    paddingRight: baseGap,
    columnGap: baseGap,
    gridTemplateColumns: `${gridWidth} ${gridWidth} ${gridWidth}`,
    maxHeight: 150,
  },
  '& .personality-container': { '& img': { maxWidth: 125 } },
  '& .affirmation-container': {},
  '& .affirmation-row': {
    paddingBottom: 10,
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 5,
      svg: { fontSize: 12, fill: theme.palette.primary.light },
    },
  },
  '& .employee-item': {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.grey['800'],
    ...shadowMixin({ theme, depth: 1 }),
    flexBasis: `calc(100% / 3 - ${baseGap}px)`,
    padding: `${baseGap / 2}px`,
    marginRight: baseGap,
    borderRadius: 7,
    position: 'relative',
    '&:last-of-type': { marginRight: 0 },
    img: {
      height: 35,
      width: 35,
      borderRadius: 50,
      marginRight: 15,
    },
  },
  '& .employee-item-chat-icon': {
    position: 'absolute',
    top: 5,
    right: 5,
    svg: { fill: theme.palette.primary.light, fontSize: 12 },
  },
  '& .upcomingEventsTitleWrapper': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 16,
    alignItems: 'center',
  },
  '& .parentWrapper': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 8,
    [theme.breakpoints.down('desktopApp')]: {
      flexBasis: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  '& .locationTitle': {
    marginBottom: 16,
  },
  '& .subTitle': {
    marginBottom: 32,
  },
  '& .moreIconWrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '& .memberImage': {
    height: 48,
    width: 48,
    borderRadius: 248,
    marginBottom: 12,
  },
  '& .eventName': {
    fontSize: 16,
    fontWeight: 700,
  },
  '& .eventTitle': {
    fontSize: 14,
  },
  '& .paginationBar': {
    marginTop: 24,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .cardStart': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .cardEnd': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  '& .textWrapper': {
    marginLeft: 8,
  },
  '& .messageIcon': {
    color: theme.palette.secondary.main,
  },
  '& .messageStatus': {
    color: theme.palette.text.secondary,
  },
  '& .viewMoreLink': {
    textTransform: 'none',
    color: theme.palette.secondary.main,
  },
});
