import { gql } from '@apollo/client';

export const GET_PULSE_BY_LOCATIONS = gql`
  query GetPulseByLocations(
    $brandId: Int
    $order: String
    $direction: String
  ) {
    getPulseByLocations(
      brandId: $brandId
      order: $order
      direction: $direction
    ) {
      locations {
        id
        name
        employees {
          id
          name
          imageUrl
        }
        employeeInfo {
          count
          profileImages
        }
        activity {
          currentCycle {
            startDate
            endDate
            data
          }
          previousCycle {
            startDate
            endDate
            data
          }
        }
        score
        employeeMood
      }
    }
  }
`;

export const GET_PULSE_BY_LOCATION = gql`
  query GetPulseByLocation($employerProfileId: Int) {
    getPulseByLocation(employerProfileId: $employerProfileId) {
      employeeCount
      interaction
      ratings {
        scoreCount
        score
      }
    }
  }
`;

export const GET_PATH_BY_LOCATIONS = gql`
  query GetPathByLocations($brandId: Int, $limit: Int) {
    getPathByLocations(brandId: $brandId, limit: $limit) {
      limit
      offset
      pages
      results {
        averageCompletionTime
        employees {
          profileImages
          count
        }
        employerName
        locationId
        estimatedCompletionRate
        skillsCompleted
        totalSkills
      }
      total
    }
  }
`;

export const GET_PATH_BY_LOCATION = gql`
  query GetPathByLocation($locationId: Int) {
    getPathByLocation(locationId: $locationId) {
      locationName
      completion
      mood {
        total
        count
      }
      paths {
        total
        active
      }
    }
  }
`;

export const GET_HEALTH_BY_LOCATIONS = gql`
  query getIndustry {
    getIndustry {
      industry_id
      name
    }
  }
`;

export const GET_EMPLOYEES_BY_LOCATION = gql`
  query EmployeesByLocation(
    $locationId: Int!
    $jobTypeId: Int
    $hiredInterval: Int
    $limit: Int
    $offset: Int
  ) {
    employeesByLocation(
      locationId: $locationId
      jobTypeId: $jobTypeId
      hiredInterval: $hiredInterval
      limit: $limit
      offset: $offset
    ) {
      offset
      pages
      total
      results {
        startDate
        name
        locationId
        location
        imageUrl
        id
        currentJobType
        employeeId
        futureJobType
        verified
      }
    }
  }
`;
export const GET_ORGANIZATION_PERMISSIONS = gql`
  query GetOrganizationPermissions {
    getOrganizationPermissions {
      accessBrand
      accessLocation
      accessOrganization
      brand {
        name
      }
      brandId
      createdAt
      deletedAt
      employerId
      id
      locationId
      organizationId
      updatedAt
      location {
        name
      }
      organization {
        name
      }
    }
  }
`;

export const GET_EMPLOYEE_PATH_STATS = gql`
  query EmployeePathStats(
    $locationId: Int!
    $careerPathGroupId: Int
    $limit: Int
    $offset: Int
  ) {
    employeePathStats(
      locationId: $locationId
      careerPathGroupId: $careerPathGroupId
      limit: $limit
      offset: $offset
    ) {
      limit
      offset
      pages
      results {
        profileImageUrl
        percentComplete
        name
        currentPathJobType
      }
      total
    }
  }
`;

export const LOCATION_EVENTS = gql`
  query LocationEvents(
    $locationId: Int
    $limit: Int
    $offset: Int
  ) {
    locationEvents(
      locationId: $locationId
      limit: $limit
      offset: $offset
    ) {
      limit
      offset
      pages
      results {
        eventDate
        eventTitle
        eventType
        name
        profileId
        profileUrl
      }
      total
    }
  }
`;

export const EMPLOYEE_DETAIL_BY_LOCATION = gql`
  query EmployeeDetailByLocation(
    $locationId: Int!
    $jobTypeId: Int
    $hiredInterval: Int
    $limit: Int
    $offset: Int
  ) {
    employeeDetailByLocation(
      locationId: $locationId
      jobTypeId: $jobTypeId
      hiredInterval: $hiredInterval
      limit: $limit
      offset: $offset
    ) {
      offset
      pages
      total
      results {
        startDate
        pendingSurveyCount
        name
        requestedBadgesCount
        locationId
        location
        imageUrl
        id
        healthScore
        currentJobType
        employeeId
        futureJobType
        pathCompletion
        verified
      }
    }
  }
`;

export const GET_MANAGERS_BY_BRAND = gql`
  query ManagersByBrand(
    $brandId: Int!
    $limit: Int
    $page: Int
    $phrase: String
  ) {
    managersByBrand(
      brandId: $brandId
      limit: $limit
      page: $page
      phrase: $phrase
    ) {
      results {
        name
        phone
        location
        image
        email
        address
      }
      pages
      limit
      offset
      total
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  query SendNotification(
    $notificationData: SendNotificationInputType
  ) {
    sendNotification(notificationData: $notificationData) {
      messagesAttempted
      results {
        name
        profileId
        reason
      }
    }
  }
`;

export const GET_COMMUNICATION_TEMPLATES = gql`
  query GetCommunicationTemplates($templateName: String) {
    getCommunicationTemplates(templateName: $templateName) {
      active
      createdAt
      id
      messageType
      name
      template
      updatedAt
    }
  }
`;
