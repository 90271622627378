import { Box } from 'components';
import { useNavigate } from 'react-router-dom';
import { getRoutes } from '../../../../utils';
import FlexRow from '../../../../components/shared/FlexRow';
import { Button, Spinner } from '../../../../components/shared';
import Typography from '@mui/material/Typography';
import useEmployerOnboarding from '../../../../hooks/useEmployerOnboarding';
import { useEffect } from 'react';
import ResourceCard from '../../../../components/employer/onboarding/ResourceCard';
import { styled } from '@mui/material/styles';
import { IOnboardingResource } from '../../../../api/types/queries/onboarding.types';

interface IStyledProps {}

const StyledFlexRow = styled(FlexRow)<IStyledProps>(
  ({ theme }) => ({
    [theme.breakpoints.down('desktopApp')]: {
      '> *': {
        maxWidth: `calc(50% - 8px)`,
        marginBottom: '1rem',
        '&:nth-of-type(2n)': {
          marginRight: `0!important`,
        },
      },
    },
    '& > div': {
      minWidth: 200,
      height: 175,
      marginRight: `0!important`,
      [theme.breakpoints.down('desktopApp')]: {
        minWidth: 'auto',
        height: 225,
        minHeight: 200,
      },
    },
  }),
);
const OnboardingResources = () => {
  const navigate = useNavigate();
  const routes = getRoutes();

  const {
    resources: { all: resourcesAPI },
  } = useEmployerOnboarding();

  useEffect(() => {
    resourcesAPI.get();
  }, []);

  const handleEditResource =
    (resource: IOnboardingResource) => () => {
      const routesWithId = getRoutes({ id: resource.id });
      navigate(routesWithId.employer.onboarding.resources.edit);
    };

  return (
    <Box>
      <FlexRow justifyContent={'space-between'}>
        <Typography variant={'h2'}>All Resources</Typography>
        <FlexRow>
          <Button
            variant={'outlined-primary'}
            onClick={() =>
              navigate(routes.employer.onboarding.create)
            }
          >
            New Onboarding
          </Button>
          <Button
            variant={'filled-primary'}
            onClick={() =>
              navigate(
                routes.employer.onboarding.resources.create,
              )
            }
          >
            New Resource
          </Button>
        </FlexRow>
      </FlexRow>
      <StyledFlexRow
        flexWrap={'wrap'}
        gap={(theme) => theme.spacing(1)}
      >
        {resourcesAPI.loading || !resourcesAPI.data ? (
          <Spinner />
        ) : (
          resourcesAPI.data.onboardingResources.results.map(
            (resource) => (
              <ResourceCard
                handleClick={handleEditResource(resource)}
                showDescription
                variant={'outlined'}
                {...resource}
              />
            ),
          )
        )}
      </StyledFlexRow>
    </Box>
  );
};

export default OnboardingResources;
