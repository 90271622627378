const styles = ({ theme }) => ({
  minWidth: '600px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('desktopApp')]: {
    minWidth: '100%',
  },
  '& > h1': {
    marginBottom: 20,
  },
  '& .choose-employee-container': {
    width: '100%',
  },
  '& .formContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 6,
    margin: '15px 0 15px 0',
    width: '100%',
  },
  '& .filters': {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
    '& > *': {
      marginRight: 15,
    },
  },
  '& .select-all-employees': {
    margin: '15px 0',
  },
  '& .choose-employee-row': {
    width: '100%',
    '& .employee-item': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: 6,
      padding: '10px',
      marginBottom: '10px',
      width: '100%',
    },
    '& .employee-identification': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: 20,
      '& > *': {
        marginRight: 10,
      },
    },
    '& .MuiFormControlLabel-root': {
      maxWidth: 50,
    },
  },
});

export default styles;
