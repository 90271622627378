import React from 'react';
import { truncate } from 'lodash';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import { MdChevronRight } from 'components/icons';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import defaultEmployerImg from 'assets/img/employer_default.png';

const StyledButton = styled(Button)(({ theme }) => ({
  '&.chatItem': {
    width: '100%',
    padding: '12px 0',
    justifyContent: 'flex-start',
    borderRadius: 0,
    textAlign: 'left',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '14px 0 12px',
    },
  },
  '& .chatItem__avatar': {
    minWidth: 76,
    width: 76,
    height: 76,
    objectFit: 'cover',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
  },
  '& .chatItem__counter': {
    width: 23,
    height: 23,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px',
    color: '#FFF',
    backgroundColor: theme.palette.tertiary.dark,
    borderRadius: '50%',
    '& > span': {
      marginTop: 2,
    },
  },
  '& .mainText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    letterSpacing: 0,
    color: theme.palette.text.primary,
    opacity: 0.4,
    wordBreak: 'break-word',
  },
  '& .secondaryText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '15px',
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
  },
}));

interface IChatItem {
  imageUrl: string,
  message: string,
  messagesCount: number,
  onClick: () => void,
  role: 'employee' | 'employer',
  subTitle: string,
  title: string,
};

const ChatItem: React.FC<IChatItem> = (props) => {
  const {
    role,
    imageUrl = '',
    title = '',
    subTitle = '',
    message = '',
    messagesCount = 0,
    onClick = () => null,
  } = props;
  const avatarUrl =
    imageUrl ||
    (role === 'employer'
      ? defaultEmployerImg
      : defaultEmployeeImg);


  return (
    <StyledButton className="chatItem" onClick={onClick}>
      <img
        src={avatarUrl}
        alt=""
        className="chatItem__avatar"
      />
      <Box ml="16px">
        <Box mb="6px" className="secondaryText">
          {title}
        </Box>
        <Box mb="6px" className="secondaryText">
          {subTitle}
        </Box>
        <div className="mainText">
          {truncate(message, { length: 38 })}
        </div>
      </Box>
      <Box ml="auto" mr={0} display="flex" alignItems="center">
        {messagesCount > 0 && (
          <div className="chatItem__counter">
            <span>{messagesCount}</span>
          </div>
        )}
        <MdChevronRight sx={{ ml: '22px' }} color="primary" />
      </Box>
    </StyledButton>
  );
};

export default ChatItem;
