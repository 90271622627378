import React from 'react';
import { qaAttr } from 'utils';
import { DatePicker as DatePickerComp } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

interface IStyledProps {}

const StyledDatePicker = styled(DatePickerComp)<IStyledProps>(
  ({ theme }) => ({
    '&.hasError': {
      label: {
        color: theme.palette.error.main,
      },
      '.MuiInputBase-root': {
        borderColor: theme.palette.error.main,
      },
    },
  }),
);
interface DatePickerProps {
  disableOpenPicker?: boolean;
  error?: string;
  InputComponentProps?: Record<string, unknown>;
  OpenPickerButtonProps?: Record<string, unknown>;
  onChange?: () => void;
  value: string | Date;
  inputFormat?: string;
}

const DatePicker = ({
  disableOpenPicker = false,
  error = '',
  InputComponentProps = {},
  onChange,
  OpenPickerButtonProps = {},
  value,
  inputFormat = 'MM/DD/YYYY',
  ...rest
}: DatePickerProps) => {
  const triggerTestProps = InputComponentProps?.id
    ? { ...qaAttr(`${InputComponentProps.id}-picker-button`) }
    : {};

  return (
    <>
      <StyledDatePicker
        className={[
          error && 'hasError',
          'datePickerContainer',
        ].join(' ')}
        inputFormat={inputFormat}
        onChange={onChange}
        disableOpenPicker={disableOpenPicker}
        OpenPickerButtonProps={{
          color: 'primary',
          fontSize: 20,
          ...triggerTestProps,
          ...OpenPickerButtonProps,
        }}
        {...rest}
        // this ensures that any date coming through is formatted correctly for the datepicker. Will get a stupid error otherwise that is hard to track down.
        value={dayjs(value)}
        defaultValue={value ? dayjs(value) : ''}
      />
      {error && (
        <Typography paragraph variant={'body2'} color={'error'}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default DatePicker;
