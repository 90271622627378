import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import JobFitCard from './JobFitCard';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.suggestedJobFIts': {},
  '& .jobFitCard:not(:last-of-type)': {
    marginBottom: 15
  },
  '& .sectionTitle': {
    marginBottom: 9,
    fontSize: 9,
    color: theme.palette.common.white
  }
}));

const SuggestedJobFits = ({ jobs }) => (
    <StyledRoot className="suggestedJobFIts">
      {jobs.map((job) => (
        <JobFitCard key={`job__${job.id}`} job={job} />
      ))}
    </StyledRoot>
  )

SuggestedJobFits.propTypes = {
  jobs: PT.arrayOf(
    PT.shape({
      id: PT.number,
      imageUrl: PT.string,
      title: PT.string
    })
  ).isRequired
};

export default SuggestedJobFits;
