import { Box } from 'components';
import get from 'lodash/get';
import { FieldValues } from 'react-hook-form';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ResourceForm from '../../../../components/employer/onboarding/ResourceForm';
import { getRoutes } from '../../../../utils';
import useEmployerOnboarding from '../../../../hooks/useEmployerOnboarding';
import { useAlerts } from '../../../../hooks';

const OnboardingResourceCreate = () => {
  const navigate = useNavigate();
  const routes = getRoutes();
  const location = useLocation();
  const onboardingId = get(location, 'state.onboardingId', null);
  const { showToast } = useAlerts();

  const {
    resources: { new: createResource },
  } = useEmployerOnboarding();

  const handleSave = async (formValues: FieldValues) => {
    const resource = await createResource.create({
      variables: formValues,
    });

    const resourceId = get(
      resource,
      'data.createOnboardingResource.id',
      null,
    );
    if (resourceId) {
      const routesWithId = getRoutes({ id: resourceId });
      navigate(routesWithId.employer.onboarding.resources.edit, {
        state: {
          onboardingId,
        },
      });
      return null;
    }

    showToast({
      title:
        'there was an error saving the document, please try again',
    });
    return null;
  };

  const handleCancel = () => {
    if (onboardingId) {
      const routesWithId = getRoutes({
        id: onboardingId,
      });
      navigate(routesWithId.employer.onboarding.edit);
    } else {
      navigate(routes.employer.onboarding.root);
    }
  };

  return (
    <Box>
      <ResourceForm
        handleSave={handleSave}
        handleCancel={handleCancel}
        loading={createResource.loading}
        error={createResource.error}
      />
    </Box>
  );
};

export default OnboardingResourceCreate;
