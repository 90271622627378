import { useCallback, useMemo } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getUserId, getLocaleTimeZone } from 'utils';
import {
  gtmTrackJobReject,
  gtmTrackJobAccept,
  gtmTrackJobStar,
} from 'utils/gtm';
import { APPROVE_JOB, POST_EMPLOYEE_REFERRAL } from 'api';
import { JOB_CORE_FIELDS_FRAGMENT } from 'api/fragments';
import { useAppStoreSelector } from 'store';
import useNotifications from '../useNotifications';
import useEmployeeProfileQuery from './useEmployeeProfileQuery';

function useEmployeeJobCommands() {
  const client = useApolloClient();
  const externalReferrer = useAppStoreSelector(
    (state) => state.externalReferrer,
  );
  const { show: showNotification } = useNotifications();

  const { profile } = useEmployeeProfileQuery();
  const { timeZone = getLocaleTimeZone() } = profile || {};

  const [postEmployeeReferral] = useMutation(
    POST_EMPLOYEE_REFERRAL,
  );
  const [reject, { loading: rejectLoading }] =
    useMutation(APPROVE_JOB);
  const [apply, { loading: applyLoading }] =
    useMutation(APPROVE_JOB);
  const [star, { loading: starLoading }] =
    useMutation(APPROVE_JOB);
  const [retract, { loading: retractLoading }] =
    useMutation(APPROVE_JOB);
  const [postInterview, { loading: interviewSaveLoading }] =
    useMutation(APPROVE_JOB);

  const updateCachedJob = useCallback(
    (objectToUpdate, updater) => {
      // objectToUpdate must have at least id and __typename
      const refId = client.cache.identify(objectToUpdate);

      if (refId) {
        client.cache.updateFragment(
          {
            id: refId,
            fragment: JOB_CORE_FIELDS_FRAGMENT,
            fragmentName: 'JobCoreFieldsFragment',
          },
          (cacheData) => updater(cacheData),
        );
      } else {
        throw new Error(
          'updateCachedJob: cannot identify cache object',
        );
      }
    },
    [client.cache],
  );

  const applyJob = useCallback(
    async (selectedJob, options) => {
      const {
        approveJobVariables = {},
        allowCachedJobUpdate = true,
        onSuccess,
        onError,
      } = options || {};
      const uid = getUserId();
      const { id, employerProfileProfileId } = selectedJob;

      try {
        const { data, errors } = await apply({
          variables: {
            jobId: Number(id),
            approved: true,
            passed: false,
            ...approveJobVariables,
          },
        });

        if (allowCachedJobUpdate) {
          updateCachedJob(selectedJob, (d) => ({
            ...d,
            approved: true,
          }));
        }

        if (
          !data?.errors &&
          !errors &&
          employerProfileProfileId
        ) {
          gtmTrackJobAccept(employerProfileProfileId);

          if (uid && externalReferrer) {
            try {
              postEmployeeReferral({
                variables: {
                  jobId: Number(id),
                  employeeUserId: Number(uid),
                  referralUrl: externalReferrer,
                },
              });
            } catch (postEmployeeReferralErr) {
              console.error(postEmployeeReferralErr);
            }
          }
        }

        if (onSuccess) onSuccess();
      } catch (error) {
        if (onError) onError(error);
      }
    },
    [
      apply,
      externalReferrer,
      postEmployeeReferral,
      updateCachedJob,
    ],
  );

  const starJob = useCallback(
    async (selectedJob, starred = true, options) => {
      const {
        approveJobVariables = {},
        allowCachedJobUpdate = true,
        onSuccess,
        onError,
      } = options || {};
      const { id, employerProfileProfileId } = selectedJob;

      try {
        await star({
          variables: {
            jobId: Number(id),
            passed: false,
            starred,
            ...approveJobVariables,
          },
        });

        if (allowCachedJobUpdate) {
          updateCachedJob(selectedJob, (d) => ({
            ...d,
            starred,
          }));
        }

        if (employerProfileProfileId) {
          gtmTrackJobStar(employerProfileProfileId);
        }

        if (onSuccess) onSuccess();
      } catch (error) {
        if (onError) onError();
      }
    },
    [star, updateCachedJob],
  );

  const rejectJob = useCallback(
    async (selectedJob, options) => {
      const {
        approveJobVariables = {},
        allowCachedJobUpdate = true,
        onSuccess,
        onError,
      } = options || {};
      const { id, employerProfileProfileId } = selectedJob;

      try {
        const { data, errors } = await reject({
          variables: {
            jobId: Number(id),
            approved: false,
            starred: false,
            passed: true,
            ...approveJobVariables,
          },
        });

        if (allowCachedJobUpdate) {
          updateCachedJob(selectedJob, (d) => ({
            ...d,
            passed: true,
          }));
        }

        if (
          !data?.errors &&
          !errors &&
          employerProfileProfileId
        ) {
          gtmTrackJobReject(employerProfileProfileId);
        }

        if (onSuccess) onSuccess();
      } catch (error) {
        if (onError) onError();
      }
    },
    [reject, updateCachedJob],
  );

  const retractJob = useCallback(
    async (selectedJob, options) => {
      const {
        approveJobVariables = {},
        allowCachedJobUpdate = true,
        onSuccess,
        onError,
      } = options || {};
      const { id } = selectedJob;

      try {
        const { data } = await retract({
          variables: {
            jobId: Number(id),
            approved: false,
            passed: false,
            ...approveJobVariables,
          },
        });
        const { scheduleStatusCount, isSchedule } =
          data.approveJob;

        if (allowCachedJobUpdate) {
          updateCachedJob(selectedJob, (d) => ({
            ...d,
            approved: false,
            isSchedule,
            scheduleStatusCount,
            userInterviewDate: null,
          }));
        }

        if (onSuccess) onSuccess();
      } catch (error) {
        if (onError) onError();
      }
    },
    [retract, updateCachedJob],
  );

  const saveInterview = useCallback(
    async (
      {
        date,
        employerScheduleId,
        employeeProfileId,
        employeeTimeZone,
        jobId,
      },
      options,
    ) => {
      const {
        allowCachedJobUpdate = true,
        onSuccess,
        onError,
      } = options || {};
      try {
        if (
          employeeProfileId &&
          jobId &&
          date &&
          employerScheduleId
        ) {
          const employeeTZ = employeeTimeZone || timeZone;
          // In case, if device local time and employee timeZone are different, we need to get proper utc based on zone
          const utcDate = zonedTimeToUtc(date, employeeTZ);

          const { data } = await postInterview({
            variables: {
              approved: true,
              passed: false,
              employeeProfileId: Number(employeeProfileId),
              jobId: Number(jobId),
              days: [
                {
                  employerScheduleId: Number(employerScheduleId),
                  interviewDate: utcDate.toISOString(),
                },
              ],
            },
          });
          const result = data?.approveJob || {};
          const {
            bookedDate = false, // selected date has already been booked
            countLimit = false, // limit has been exceeded
            scheduleStatusCount,
            isSchedule,
          } = result;

          if (
            allowCachedJobUpdate &&
            !bookedDate &&
            !countLimit
          ) {
            updateCachedJob(
              { id: jobId, __typename: 'Jobs' },
              (d) => ({
                ...d,
                approved: true,
                scheduleStatusCount,
                isSchedule,
                userInterviewDate: utcDate.toISOString(),
              }),
            );
          }

          if (onSuccess) onSuccess(result);

          return result;
        }
        throw new Error('Invalid parameters.');
      } catch (error) {
        if (onError) onError(error);
        console.error('saveInterview: ', error);
        showNotification([
          {
            type: 'error',
            message: `Error during interview saving. ${
              error?.message ?? ''
            }`,
          },
        ]);
        return null;
      }
    },
    [postInterview, showNotification, timeZone, updateCachedJob],
  );

  const memoReturn = useMemo(
    () => ({
      applyJob,
      applyLoading,
      interviewSaveLoading,
      rejectJob,
      rejectLoading,
      retractJob,
      retractLoading,
      saveInterview,
      starJob,
      starLoading,
    }),
    [
      applyJob,
      applyLoading,
      interviewSaveLoading,
      rejectJob,
      rejectLoading,
      retractJob,
      retractLoading,
      saveInterview,
      starJob,
      starLoading,
    ],
  );

  return memoReturn;
}

useEmployeeJobCommands.propTypes = {};

export default useEmployeeJobCommands;
