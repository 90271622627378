import {
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import React, { useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  ADD_EMPLOYEES_TO_SURVEY,
  CREATE_SURVEY_BY_CAMPAIGN,
  GET_SURVEY_CAMPAIGN_BY_ID,
  SEND_SURVEY_NOTIFICATION,
} from 'api';
import { Box, FormHelperText, styled } from 'components';
import { ISurvey } from 'components/Retention/types';
import { Button } from 'components/shared';
import { useAlerts } from 'hooks';
import newSurveyStyles from 'styles/Retention/Survey/NewSurvey';
import sendSurveyStyles from 'styles/Retention/Survey/SendSurvey';
import { getRoutes } from 'utils';
import ChooseEmployees from 'components/Retention/components/ChooseEmployees';
import Review from './Review';

const StyledBox = styled(Box)(({ theme }) => ({
  ...newSurveyStyles({ theme }),
  ...sendSurveyStyles({ theme }),
}));

interface IFetchSurveyCampaignById {
  surveyCampaignById: ISurvey;
}

const SendSurvey = () => {
  const { surveyCampaignId } = useParams();
  const [survey, setSurvey] = useState<ISurvey>();
  const [recipientIds, setRecipientIds] = useState([]);
  const [showReview, setShowReview] = useState<boolean>(false);
  const [sendSurveyError, setSendSurveyError] = useState<
    string | undefined
  >();

  const navigate = useNavigate();
  const location = useLocation();
  const routes = getRoutes();

  const { setSimpleAlert } = useAlerts();

  const surveySentModal = () => {
    setSimpleAlert({
      isOpen: true,
      title: 'Survey Sent',
      subtitle: 'Survey Sent Successfully',
      showCancel: false,
      onSuccess: () => setSimpleAlert({ isOpen: false }),
    });
  };

  useQuery<IFetchSurveyCampaignById>(GET_SURVEY_CAMPAIGN_BY_ID, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      id: parseInt(surveyCampaignId),
    },
    onCompleted(surveyData) {
      setSurvey({
        name: surveyData.surveyCampaignById.name,
        recipients: { recipientIds: [] },
        questions:
          surveyData.surveyCampaignById.surveyCampaignQuestions.map(
            (q) => ({
                id: q.id,
                question: q.question,
                answers: [{ answer: '' }],
              }),
          ),
      } as ISurvey);
    },
  });

  const [sendSurveyNotification, { error: notificationError }] =
    useLazyQuery(SEND_SURVEY_NOTIFICATION, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    });

  const [createSurveyByCampaign] =
    useMutation(CREATE_SURVEY_BY_CAMPAIGN, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });

  const [addEmployeesToSurvey, { error: addEmployeesError }] =
    useMutation(ADD_EMPLOYEES_TO_SURVEY, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });

  const showReviewClicked = () => {
    if (survey.recipients.recipientIds.length === 0) {
      setSendSurveyError('Choose at least 1 recipient');
    } else {
      setSendSurveyError(null);
      setShowReview(true);
    }
  };

  const createSurvey = async () => {
    const { data, errors } = await createSurveyByCampaign({
      variables: {
        surveyCampaignId: parseInt(surveyCampaignId),
      },
    });

    const surveyId = data.createSurveyByCampaign.id;

    if (errors) {
      setSendSurveyError(errors[0].message);
    }

    if (surveyId) {
      await addEmployeesToSurvey({
        variables: {
          surveyId,
          surveyCampaignId: parseInt(surveyCampaignId),
          employees: survey.recipients.recipientIds.map((e) => ({ employeeId: e })),
        },
      });

      if (!addEmployeesError) {
        await sendSurveyNotification({
          variables: { surveyId },
        });

        if (!notificationError) {
          surveySentModal();
          navigate(
            `${routes.employer.retention_portal.location}/pulse`,
            {
              replace: true,
            },
          );
        }
      }
    }
  };

  const setEmployees = (employeeIds: number[]) => {
    setRecipientIds(employeeIds);
    setSurvey({
      ...survey,
      recipients: { recipientIds: employeeIds },
    });
  };

  return (
    <StyledBox>
      <Box className="header">
        <h1>Send survey: {survey?.name}</h1>
      </Box>
      <Box className={'navigation-container'}>
        <Box className="navigation">
          <Button
            variant="outlined-secondary"
            onClick={() => {
              if(!showReview)
                navigate(
                    `${routes.employer.retention_portal.interactions.survey}`,
                    {
                      replace: true,
                      state: {
                        locationId: location.state.locationId,
                      },
                    },
                  )
                else setShowReview(false);
            }}
          >
            {!showReview ? 'Cancel' : 'Choose Recipients'}
          </Button>
          <Button
            variant="filled-primary"
            onClick={() =>
              !showReview ? showReviewClicked() : createSurvey()
            }
          >
            {!showReview ? 'Review' : 'Send'}
          </Button>
        </Box>
      </Box>
      {sendSurveyError && (
        <FormHelperText error>{sendSurveyError}</FormHelperText>
      )}
      {!showReview ? (
        <ChooseEmployees
          employeeIds={recipientIds}
          setEmployeeIds={setEmployees}
          locationId={location.state.locationId}
        />
      ) : (
        <Review survey={survey} />
      )}
    </StyledBox>
  );
};

export default SendSurvey;
