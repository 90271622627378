import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { map, find, debounce, isEmpty } from 'lodash';
import format from 'date-fns/format';
import parseDate from 'date-fns/parse';
import { qaAttr } from 'utils';
import { useMediaQueryMatches } from 'hooks';
import {
  Box,
  Dialog,
  MuiIconButton,
  InputAdornment,
  Popover,
  styled,
} from 'components';
import {
  Button,
  ButtonBase,
  Input,
  LocationAutocomplete,
  Spinner,
  Video,
} from 'components/shared';
import {
  CitiesForm,
  IndustriesForm,
  SkillsForm,
} from 'components/dialogs/components';
import {
  MdArrowBack,
  MdCheck,
  MdClose,
  MdExpandMore,
  MdSearch,
} from 'components/icons';
import { useForm } from 'components/form';
import defaultJobImg from 'assets/img/job_default.png';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import styles from 'styles/Dashboard/EnterpriseSearch';
import {
  GET_EMPLOYER_JOBS,
  GET_SEARCH_MATCH,
  SEND_INVITE,
} from 'api';
import Typography from '@mui/material/Typography';

const StyledRoot = styled('div')(styles);

const PAGINATION_LIMIT = 10;
const PAGINATION_OFFSET = 0;

function EnterpriseSearch() {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const [candidates, setCandidates] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [vacancyPopupConfig, setVacancyPopupConfig] = useState({
    anchorEl: null,
    id: null,
  });
  const [subForm, setSubForm] =
    useState(
      '',
    ); /* 'industry' || 'skills || 'location' || 'education' || 'experience' */
  const [pressedFilter, setPressedFilter] = useState('location');
  const [invited, setInvited] = useState([]);
  const debouncedSearch = useRef();
  const gradientElementRef = useRef();
  const [sendInvite] = useMutation(SEND_INVITE);
  const [activeEmployeeUserId, setActiveEmployeeUserId] =
    useState(null);
  const [paginationOffset, setPaginationOffset] = useState(
    PAGINATION_OFFSET,
  );
  const [showResults, setShowResults] = useState(false);
  const { attrs, set } = useForm({
    initial: {
      education: '',
      experience: '',
      industry: [],
      location: '',
      keywords: '',
      skills: [],
      // TODO THESE ARE FAKE, IGNORE THEM AND REMOVE THEM ONCE WE DEMO
      jobStage: '',
      jobTitle: '',
    },
    validations: {},
  });
  const [
    getSearchMatches,
    {
      data: searchMatches = {},
      loading: searchLoading,
      fetchMore,
    },
  ] = useLazyQuery(GET_SEARCH_MATCH, {
    // fetchPolicy: 'no-cache'
    onCompleted: (data) => {
      setActiveEmployeeUserId(null);
      setShowResults(true);
      if (data.searchmatch) {
        setCandidates(data.searchmatch || []);
      }
    },
  });
  const [employerJobs, { data: employerJobsData = {} }] =
    useLazyQuery(GET_EMPLOYER_JOBS, {
      variables: {
        active: true,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });

  const search = useCallback(
    (
      { jobStage, jobTitle, ...searchQuery },
      offset = PAGINATION_OFFSET,
      limit = PAGINATION_LIMIT,
    ) => {
      const query = { ...searchQuery };
      if (searchQuery.industry.length) {
        query.industry = searchQuery.industry.map(
          (industry) => industry.name,
        );
      }
      if (searchQuery.skills.length) {
        query.skills = searchQuery.skills.map(
          (skill) => skill.name,
        );
      }
      getSearchMatches({
        variables: { query, offset, limit },
      });
    },
    [getSearchMatches],
  );

  const handleMobileSearch = useCallback(
    (attrsArg) => {
      setVacancyPopupConfig({ anchorEl: null, id: null });
      setInvited([]);
      setPaginationOffset(PAGINATION_OFFSET);
      search(attrsArg);
    },
    [search],
  );

  const loadMore = () => {
    const nextOffset = paginationOffset + 1;
    setPaginationOffset(nextOffset);
    fetchMore({
      variables: {
        query: JSON.stringify(attrs),
        offset: nextOffset,
        limit: PAGINATION_LIMIT,
      },
    });
  };

  const handleLocationSuggestionSelect = useCallback(
    (e, { suggestionValue }) => {
      set('location', suggestionValue);
    },
    [],
  );

  const handleLocationChange = useCallback((value) => {
    set('location', value);
  }, []);

  useEffect(() => {
    debouncedSearch.current = debounce(handleMobileSearch, 500);
  }, [handleMobileSearch]);

  useEffect(() => {
    if (!isDesktop) debouncedSearch.current(attrs);
  }, [attrs.keywords, isDesktop]);

  useEffect(() => {
    employerJobs();
  }, []);

  // useEffect(() => {
  //   setCandidates(searchMatches?.searchmatch || []);
  // }, [JSON.stringify(searchMatches)]);

  useEffect(() => {
    setJobs(employerJobsData?.employerJobs || []);
  }, [JSON.stringify(employerJobsData)]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPaginationOffset(PAGINATION_OFFSET);
    setVacancyPopupConfig({ anchorEl: null, id: null });
    setInvited([]);
    search(attrs);
  };

  const closeVacancyPopup = () => {
    setVacancyPopupConfig({ anchorEl: null, id: null });
  };

  const handleInvite = (job, candidate) => () => {
    if (candidate.user_id) {
      const nextInvited = [...invited];
      nextInvited.push({
        userId: candidate.user_id,
        job: job.title || '',
      });
      sendInvite({
        variables: {
          employeeUserId: candidate.user_id,
          id: job.id,
        },
      });
      setInvited(nextInvited);
    }
    closeVacancyPopup();
  };

  const handleFieldChange = (field) => (e) => {
    set(field, e.target.value);
  };

  const handleSubFormChange = (nextForm) => {
    set({ ...attrs, ...nextForm });
  };

  const openVacancyPopup = (candidateId) => (e) =>
    setVacancyPopupConfig({
      anchorEl: e.currentTarget,
      id: candidateId,
    });

  const handleSubFormOpen = (type) => () => {
    setSubForm(type);
    setPressedFilter(type);
  };

  const handleSubFormClose = () => {
    setSubForm('');
    if (!isDesktop) {
      setInvited([]);
      search(attrs);
    }
  };

  const handleMobileFiltersScroll = (e) => {
    const elementMaxWidth = 40;
    const maxScroll =
      e.currentTarget.scrollWidth - e.currentTarget.clientWidth;
    const scrollProgress = Math.floor(
      (100 * e.currentTarget.scrollLeft) / maxScroll,
    );
    // during scrolling from left to right, element width will shrink from elementMaxWidth to 0 and reverse
    const gradientElementWidth = Math.floor(
      elementMaxWidth +
        (0 - elementMaxWidth) * (scrollProgress / 100),
    );
    window.requestAnimationFrame(() => {
      if (gradientElementRef.current)
        gradientElementRef.current.style.width = `${gradientElementWidth}px`;
    });
  };

  const renderIndustriesString = (industry) =>
    industry.length > 0 ? map(industry, 'name').join(', ') : '';

  const renderSkillsString = (skills) =>
    skills.length > 0 ? map(skills, 'name').join(', ') : '';

  const renderSearchForm = () => {
    const CheckBoxGroupProps = {
      checkboxesContainerProps: !isDesktop
        ? { sx: { borderRadius: 0, border: 'none' } }
        : {},
      CheckBoxProps: !isDesktop
        ? {
            labelPlacement: 'start',
            sx: {
              padding: 0,
              borderBottom:
                '1px solid rgba(255,255,255,0.2) !important',
              justifyContent: 'space-between',
              '& label': {
                marginLeft: 0,
                fontWeight: 'normal',
              },
            },
          }
        : {},
    };
    return (
      <div className="searchFormWrapper">
        <div className="searchFormContainer">
          <form
            className="searchForm"
            noValidate
            onSubmit={handleSubmit}
          >
            <Input
              value={attrs.keywords}
              variant="textfield"
              id="keywords"
              name="keywords"
              label="Keywords"
              FormControlProps={{
                sx: { marginBottom: '29px' },
              }}
              analyticParams={{
                key: 'Keywords focused (db search)',
                trigger: 'focus',
              }}
              onChange={handleFieldChange('keywords')}
              testID="search-db-keywords-input"
            />
            <Input
              value={attrs.jobStage}
              variant="textfield"
              name="jobStage"
              label="Job Stage"
              FormControlProps={{
                sx: { marginBottom: '29px' },
              }}
              onChange={handleFieldChange('jobStage')}
            />
            <Input
              value={attrs.jobTitle}
              variant="textfield"
              name="jobTitle"
              label="Job Title"
              FormControlProps={{
                sx: { marginBottom: '29px' },
              }}
              onChange={handleFieldChange('jobTitle')}
            />
            <LocationAutocomplete
              value={attrs.location}
              onChange={handleLocationChange}
              onSuggestionSelect={handleLocationSuggestionSelect}
              inputCompProps={{
                variant: 'textfield',
                id: 'location',
                label: 'Location (type in city, state or zip)',
                testID: 'search-db-location-search-input',
              }}
              className="locationAutoSuggest"
            />
            <Input
              value={renderIndustriesString(attrs.industry)}
              readOnly
              variant="textfield"
              id="industry"
              name="industry"
              label={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <span>Willing to Relocate</span>
                  {/*<MdExpandMore color="primary" />*/}
                </Box>
              }
              htmlInputClassName="ellipsisText"
              FormControlProps={{
                sx: { marginBottom: '29px' },
              }}
              analyticParams={{
                key: 'Industry focused (db search)',
                trigger: 'focus',
              }}
              onClick={handleSubFormOpen('industry')}
              testID="search-db-industry-input"
            />
            <Input
              value={renderSkillsString(attrs.skills)}
              readOnly
              variant="textfield"
              id="skills"
              name="skills"
              label={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <span>Years in Position</span>
                  {/*<MdExpandMore color="primary" />*/}
                </Box>
              }
              htmlInputClassName="ellipsisText"
              FormControlProps={{
                sx: { marginBottom: '29px' },
              }}
              analyticParams={{
                key: 'Skills focused (db search)',
                trigger: 'focus',
              }}
              onClick={handleSubFormOpen('skills')}
              testID="search-db-skills-input"
            />
            {/*<Input*/}
            {/*  value={attrs.education}*/}
            {/*  variant="textfield"*/}
            {/*  id="education"*/}
            {/*  name="education"*/}
            {/*  label="Education"*/}
            {/*  FormControlProps={{*/}
            {/*    sx: { marginBottom: '29px' },*/}
            {/*  }}*/}
            {/*  analyticParams={{*/}
            {/*    key: 'Education focused (db search)',*/}
            {/*    trigger: 'focus',*/}
            {/*  }}*/}
            {/*  onChange={handleFieldChange('education')}*/}
            {/*  testID="search-db-education-input"*/}
            {/*/>*/}
            <Input
              value={attrs.experience}
              variant="textfield"
              id="experience"
              name="experience"
              label="Years at Company"
              FormControlProps={{
                sx: { marginBottom: '24px' },
              }}
              analyticParams={{
                key: 'Experience focused (db search)',
                trigger: 'focus',
              }}
              onChange={handleFieldChange('experience')}
              testID="search-db-experience-input"
            />
            <Button
              type="submit"
              variant="filled-primary"
              sx={{ width: '100%', height: 50 }}
              testID="search-db-search-button"
            >
              Search
            </Button>
          </form>

          <div className="mobileSearchContainer">
            <Input
              value={attrs.keywords}
              placeholder=""
              startAdornment={
                <InputAdornment
                  position="start"
                  sx={{
                    minWidth: 24,
                    paddingLeft: '16px',
                    fontSize: 24,
                    color: 'rgba(255,255,255,0.5',
                  }}
                >
                  <MdSearch fontSize="inherit" color="inherit" />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{ minWidth: 30 }}
                >
                  {searchLoading && <Spinner size={20} />}
                </InputAdornment>
              }
              FormControlProps={{
                sx: { marginBottom: '16px', minHeight: 50 },
              }}
              onChange={handleFieldChange('keywords')}
              testID="search-db-mobile-keywords-input"
            />
            <div style={{ position: 'relative' }}>
              <div
                className="filtersContainer__gradient"
                ref={gradientElementRef}
              />
              <div
                className="filtersContainer"
                onScroll={handleMobileFiltersScroll}
              >
                <Button
                  variant={
                    pressedFilter === 'location'
                      ? 'filled-primary'
                      : 'outlined-secondary'
                  }
                  endIcon={
                    <MdExpandMore style={{ fontSize: 16 }} />
                  }
                  sx={{
                    height: 38,
                    padding: '0 16px',
                    borderRadius: 19,
                    '& .StyledButton-label': {
                      whiteSpace: 'nowrap',
                    },
                  }}
                  onClick={handleSubFormOpen('location')}
                  testID="search-db-location-filter-button"
                >
                  {attrs.location || 'Location'}
                </Button>
                <Button
                  variant={
                    pressedFilter === 'industry'
                      ? 'filled-primary'
                      : 'outlined-secondary'
                  }
                  endIcon={
                    <MdExpandMore style={{ fontSize: 16 }} />
                  }
                  sx={{
                    height: 38,
                    marginLeft: '10px',
                    padding: '0 16px',
                    borderRadius: 19,
                  }}
                  onClick={handleSubFormOpen('industry')}
                  testID="search-db-industry-filter-button"
                >
                  Industry
                </Button>
                <Button
                  variant={
                    pressedFilter === 'skills'
                      ? 'filled-primary'
                      : 'outlined-secondary'
                  }
                  endIcon={
                    <MdExpandMore style={{ fontSize: 16 }} />
                  }
                  sx={{
                    height: 38,
                    marginLeft: '10px',
                    padding: '0 16px',
                    borderRadius: 19,
                  }}
                  onClick={handleSubFormOpen('skills')}
                  testID="search-db-skills-filter-button"
                >
                  Skills
                </Button>
                <Button
                  variant={
                    pressedFilter === 'education'
                      ? 'filled-primary'
                      : 'outlined-secondary'
                  }
                  endIcon={
                    <MdExpandMore style={{ fontSize: 16 }} />
                  }
                  sx={{
                    height: 38,
                    marginLeft: '10px',
                    padding: '0 16px',
                    borderRadius: 19,
                  }}
                  onClick={handleSubFormOpen('education')}
                  testID="search-db-education-filter-button"
                >
                  Education
                </Button>
                <Button
                  variant={
                    pressedFilter === 'experience'
                      ? 'filled-primary'
                      : 'outlined-secondary'
                  }
                  endIcon={
                    <MdExpandMore style={{ fontSize: 16 }} />
                  }
                  sx={{
                    height: 38,
                    marginLeft: '10px',
                    padding: '0 16px',
                    borderRadius: 19,
                  }}
                  onClick={handleSubFormOpen('experience')}
                  testID="search-db-experience-filter-button"
                >
                  Experience
                </Button>
              </div>
            </div>
          </div>

          {subForm && (
            <Dialog
              open={!!subForm}
              scroll="paper"
              classes={{ paper: 'dialogPaper' }}
              container={() =>
                document.getElementById(
                  'enterprise-search-modals-root',
                )
              }
              onClose={handleSubFormClose}
            >
              <div className="dialogHeader">
                {isDesktop ? (
                  <MuiIconButton
                    color="primary"
                    edge="start"
                    aria-label="close"
                    onClick={handleSubFormClose}
                  >
                    <MdArrowBack />
                  </MuiIconButton>
                ) : (
                  <Box minWidth={36} />
                )}
                <h2
                  className="dialogTitle"
                  {...qaAttr('search-db-form-title')}
                >
                  {subForm === 'location' && 'Select City'}
                  {subForm === 'industry' && 'Industries'}
                  {subForm === 'skills' && 'Skills'}
                  {subForm === 'education' && 'Education'}
                  {subForm === 'experience' &&
                    'Years Of Experience'}
                </h2>
                {!isDesktop ? (
                  <MuiIconButton
                    color="primary"
                    edge="end"
                    aria-label="close"
                    onClick={handleSubFormClose}
                    {...qaAttr('search-db-form-close-button')}
                  >
                    <MdClose />
                  </MuiIconButton>
                ) : (
                  <Box minWidth={36} />
                )}
              </div>
              <div className="dialogContent">
                {subForm === 'location' && (
                  <CitiesForm
                    form={attrs}
                    withSubmitBtn={false}
                    withSkipBtn={false}
                    CheckBoxGroupProps={CheckBoxGroupProps}
                    onChange={handleSubFormChange}
                  />
                )}
                {subForm === 'industry' && (
                  <IndustriesForm
                    form={attrs}
                    withSubmitBtn={false}
                    withSkipBtn={false}
                    CheckBoxGroupProps={CheckBoxGroupProps}
                    onChange={handleSubFormChange}
                  />
                )}
                {subForm === 'skills' && (
                  <SkillsForm
                    form={attrs}
                    withSubmitBtn={false}
                    withSkipBtn={false}
                    CheckBoxGroupProps={CheckBoxGroupProps}
                    onChange={handleSubFormChange}
                  />
                )}
                {subForm === 'education' && (
                  <Box px="16px">
                    <Input
                      value={attrs.education}
                      placeholder=""
                      FormControlProps={{
                        sx: {
                          marginBottom: '16px',
                          minHeight: 50,
                        },
                      }}
                      onChange={handleFieldChange('education')}
                      testID="search-db-education-form-input"
                    />
                  </Box>
                )}
                {subForm === 'experience' && (
                  <Box px="16px">
                    <Input
                      value={attrs.experience}
                      placeholder=""
                      FormControlProps={{
                        sx: {
                          marginBottom: '16px',
                          minHeight: 50,
                        },
                      }}
                      onChange={handleFieldChange('experience')}
                      testID="search-db-experience-form-input"
                    />
                  </Box>
                )}
              </div>
            </Dialog>
          )}
        </div>
      </div>
    );
  };

  const renderVacancyPopup = (candidate) => {
    const { user_id } = candidate;
    return (
      <Popover
        open={
          !!vacancyPopupConfig.anchorEl &&
          vacancyPopupConfig.id === user_id
        }
        anchorEl={vacancyPopupConfig.anchorEl}
        classes={{ paper: 'vacancyPopupPaper' }}
        anchorOrigin={{
          vertical: 30,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disablePortal
        onClose={closeVacancyPopup}
      >
        <div className="vacancyPopupBody">
          {map(jobs, (job) => (
            <ButtonBase
              className="vacancyPopupBtn"
              key={`vacancy__${user_id}-${job.id}`}
              onClick={handleInvite(job, candidate)}
            >
              <div
                style={{
                  backgroundImage: `url("${
                    job.imageUrl || defaultJobImg
                  }")`,
                }}
                className="vacancyImg"
              />
              <div className="vacancyName">{job.title}</div>
            </ButtonBase>
          ))}
        </div>
      </Popover>
    );
  };

  const formatDate = (dateStr) =>
    dateStr
      ? format(
          parseDate(dateStr, 'yyyy-MM-dd', new Date()),
          'MM-dd-yyyy',
        )
      : '';

  const renderEmployeeDetails = (empDetails) => {
    const notAvailable = (
      <p className="employeeDetails_notAvailable">N/A</p>
    );
    const containerStyle = {
      columnGap: isDesktop ? '8%' : 20,
      gridTemplateColumns: isDesktop
        ? 'auto auto auto'
        : 'auto auto',
      padding: isDesktop ? '1rem' : '1rem 0 0 0',
    };

    return (
      <div
        style={containerStyle}
        className="employeeDetails_container"
      >
        {/*<div className="employeeDetails_content">*/}
        {/*  <h3 className="employeeDetails_header">*/}
        {/*    Current Industry*/}
        {/*  </h3>*/}
        {/*  {isEmpty(empDetails.industry) ? (*/}
        {/*    notAvailable*/}
        {/*  ) : (*/}
        {/*    <ul className="empList">*/}
        {/*      {empDetails.industry.map((ind) => (*/}
        {/*        <li className="employeeDetails_listItem">*/}
        {/*          {ind.name}*/}
        {/*        </li>*/}
        {/*      ))}*/}
        {/*    </ul>*/}
        {/*  )}*/}
        {/*</div>*/}
        <div className="employeeDetails_content">
          <h3 className="employeeDetails_header">Experience</h3>
          {isEmpty(empDetails.experience) ? (
            'No experience'
          ) : (
            <ul className="empList">
              {empDetails.experience.map((exp) => (
                <li>
                  <h4 className="employeeDetails_listHeader">
                    {exp.name}
                  </h4>
                  <p>{exp.pos}</p>
                  <p className="employeeDetails_listDate">
                    Start: {formatDate(exp.startDate)} End:{' '}
                    {exp.currentJob
                      ? 'present'
                      : formatDate(exp.endDate)}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="employeeDetails_content">
          <h3 className="employeeDetails_header">
            Video Introduction
          </h3>
          {isEmpty(empDetails.videoUrl) ? (
            notAvailable
          ) : (
            <div>
              <Video
                initUrl={empDetails.videoUrl}
                variant="default"
              />
            </div>
          )}
        </div>
        {/*<div className="employeeDetails_content">*/}
        {/*  <h3 className="employeeDetails_header">Education</h3>*/}
        {/*  {isEmpty(empDetails.education) ? (*/}
        {/*    notAvailable*/}
        {/*  ) : (*/}
        {/*    <ul className="empList">*/}
        {/*      {empDetails.education.map((edu) => (*/}
        {/*        <li>*/}
        {/*          <h4 className="employeeDetails_listHeader">*/}
        {/*            {edu.name}*/}
        {/*          </h4>*/}
        {/*          <p>{edu.pos}</p>*/}
        {/*          <p className="employeeDetails_listDate">*/}
        {/*            Start: {formatDate(edu.startDate)} End:{' '}*/}
        {/*            {edu.currentJob*/}
        {/*              ? 'present'*/}
        {/*              : formatDate(edu.endDate)}*/}
        {/*          </p>*/}
        {/*        </li>*/}
        {/*      ))}*/}
        {/*    </ul>*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*<div className="employeeDetails_content">*/}
        {/*  <h3 className="employeeDetails_header">Skills</h3>*/}
        {/*  {isEmpty(empDetails.skills) ? (*/}
        {/*    notAvailable*/}
        {/*  ) : (*/}
        {/*    <ul className="empList">*/}
        {/*      {empDetails.skills.map((skill) => (*/}
        {/*        <li className="employeeDetails_listItem">*/}
        {/*          {skill.name}*/}
        {/*        </li>*/}
        {/*      ))}*/}
        {/*    </ul>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    );
  };

  const renderListItem = (o, i) => {
    const { user_id } = o;
    const employeeProfile = o.employeeProfile || {};
    const {
      imageUrl = '',
      name = '',
      motto = '',
    } = employeeProfile;
    const invitedCandidate = find(invited, ['userId', user_id]);

    return isDesktop ? (
      <div key={`listRow__${i}-${user_id}`}>
        <div
          className={
            user_id === activeEmployeeUserId
              ? 'tableRowOpen'
              : 'tableRow'
          }
        >
          <div
            className="tableRowContainer"
            onClick={() =>
              setActiveEmployeeUserId(
                user_id === activeEmployeeUserId
                  ? null
                  : user_id,
              )
            }
            {...qaAttr(`applicant-${name}`)}
          >
            <div className="tableCell">
              <div
                style={{
                  backgroundImage: `url("${
                    imageUrl || defaultEmployeeImg
                  }")`,
                }}
                className="tableAvatar"
              />
            </div>
            <div className="tableCell textMain">{name}</div>
            <div className="tableCell textSecondary mottoExcerpt">
              {motto}
            </div>
          </div>
          {invitedCandidate ? (
            <div className="tableCell invitedMessage">
              <MdCheck color="inherit" fontSize="inherit" />{' '}
              {`Invited to ‘${invitedCandidate.job}’`}
            </div>
          ) : (
            <div className="tableCell tableCell__actions">
              <Button
                variant="text"
                endIcon={<MdExpandMore fontSize="inherit" />}
                sx={{ fontSize: 14 }}
                onClick={openVacancyPopup(user_id)}
              >
                Invite to apply
              </Button>
              {renderVacancyPopup(o, i)}
            </div>
          )}
        </div>
        {user_id === activeEmployeeUserId
          ? renderEmployeeDetails(employeeProfile)
          : null}
      </div>
    ) : (
      <>
        <div className="tableRow" key={`listRow__${user_id}`}>
          <div
            className="tableRowContainer"
            onClick={() =>
              setActiveEmployeeUserId(
                user_id === activeEmployeeUserId
                  ? null
                  : user_id,
              )
            }
          >
            <div
              style={{
                backgroundImage: `url("$imageUrl{imageUrl || defaultEmployeeImg}")`,
              }}
              className="tableAvatar"
            />
            <div style={{ marginLeft: 16 }}>
              <div
                className="textMain"
                style={{
                  ...((!!motto || !!invitedCandidate) && {
                    marginBottom: 6,
                  }),
                }}
              >
                {name}
              </div>
              {(() => {
                if (invitedCandidate) {
                  return (
                    <div
                      className="invitedMessage"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        whiteSpace: 'break-spaces',
                      }}
                    >
                      <MdCheck
                        style={{ marginRight: 6, fontSize: 14 }}
                      />
                      {`Invited to ‘${invitedCandidate.job}’`}
                    </div>
                  );
                }
                if (motto) {
                  return (
                    <div className="textSecondary">{motto}</div>
                  );
                }
                return null;
              })()}
            </div>
          </div>
          {invitedCandidate ? null : (
            <>
              <Button
                variant="text"
                endIcon={<MdExpandMore fontSize="inherit" />}
                sx={{
                  fontSize: 14,
                  marginRight: 0,
                  marginLeft: 'auto',
                  '& .StyledButton-endIcon': {
                    marginLeft: 0,
                  },
                }}
                onClick={openVacancyPopup(user_id)}
              >
                Invite
              </Button>
              {renderVacancyPopup(o, i)}
            </>
          )}
        </div>
        {user_id === activeEmployeeUserId
          ? renderEmployeeDetails(employeeProfile)
          : null}
      </>
    );
  };

  const renderSearchResults = () => {
    const totalCount = candidates?.[0]?.usersCount || 0;
    const outOfLimit =
      paginationOffset * PAGINATION_LIMIT >= totalCount;
    return (
      <div className="searchResultContainer">
        {totalCount > 0 && (
          <div className="candidatesCount">{`${totalCount} candidates found`}</div>
        )}
        <div className="candidatesContainer">
          {searchLoading && (
            <div className="candidatesSpinner">
              <Spinner />
            </div>
          )}
          {candidates != null && candidates.length > 0 ? (
            candidates.map((candidate) =>
              renderListItem(candidate),
            )
          ) : (
            <Box className={'candidatesEmpty'}>
              {showResults ? (
                <Box className={'emptyStateTextWrapper'}>
                  <Typography
                    className={'emptyStateText'}
                    variant={'h2'}
                  >
                    The search results did not match anything.
                  </Typography>
                  <Typography variant={'p'}>
                    Try to narrow your search or change some
                    settings.
                  </Typography>
                </Box>
              ) : (
                <Box className={'emptyStateTextWrapper'}>
                  <Typography
                    className={'emptyStateText'}
                    variant={'h2'}
                  >
                    Find your next hire.
                  </Typography>
                  <Typography variant={'p'}>
                    Use the search bar to find candidates
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          {candidates.length > 0 && !outOfLimit && (
            <Box mt="15px" textAlign="center">
              <Button
                variant="text"
                sx={{ padding: '12px 20px', fontSize: 20 }}
                endIcon={searchLoading && <Spinner width={20} />}
                disabled={searchLoading}
                onClick={loadMore}
                testID="load-more-button"
              >
                Load More
              </Button>
            </Box>
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => (
    <StyledRoot className="searchDashboardContainer">
      {renderSearchForm()}
      {renderSearchResults()}
      <div id="enterprise-search-modals-root" />
    </StyledRoot>
  );

  return renderContent();
}

EnterpriseSearch.propTypes = {};

export default EnterpriseSearch;
