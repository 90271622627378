import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { styled } from 'components';
import Typography from '@mui/material/Typography';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.pageHeader': {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    display: 'flex',
    alignItems: 'center',
  },
  '& .pageHeader__title': {
    marginBottom: 0,
  },
  '& .pageHeader__subTitle': {
  },
  svg: {
    marginRight: theme.spacing(2),
    fontSize: 50,
    color: theme.palette.common.white,
  },
}));

const PageHeader = (props) => {
  const { className, icon, title, subtitle } = props;
  return (
    <StyledRoot className={clsx(className, 'pageHeader')}>
      {icon && icon}
      <div>
        <Typography
          variant={'h1'}
          color={'primary'}
          className="pageHeader__title"
        >
          {title}
        </Typography>
        <Typography
          variant={'body1'}
          color={'secondary'}
          className="pageHeader__subTitle"
        >
          {subtitle}
        </Typography>
      </div>
    </StyledRoot>
  );
};

PageHeader.propTypes = {
  className: PT.string,
  icon: PT.node,
  title: PT.string,
  subtitle: PT.string,
};

PageHeader.defaultProps = {
  className: '',
  icon: null,
  title: '',
  subtitle: '',
};

export default PageHeader;
