import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon8 = (props) => (
    <SvgIcon viewBox="0 0 37.18 31.76" {...props}>
      <path
        d="M25.56,0L11.63,8.04c-1.04,.6-.1,2.22,.94,1.62L25.55,2.17c1.03-.6,2.36-.24,2.96,.79l4.79,22.6-6.47-11.21c-1.11-1.93-3.59-2.59-5.52-1.48l-10.26,5.92c-1.03,.6-2.36,.24-2.96-.79L2.17,7.75c-.6-1.03-.24-2.36,.79-2.96L25.56,0Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M7.74,33.3l13.93-8.04c1.04-.6,.1-2.22-.94-1.63l-12.98,7.49c-1.03,.6-2.36,.24-2.96-.79L0,7.74l6.47,11.21c1.11,1.93,3.59,2.59,5.52,1.48l10.26-5.92c1.03-.6,2.36-.24,2.96,.79l5.92,10.26c.59,1.03,.24,2.36-.79,2.96l-22.6,4.79Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon8;
