import { Box } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRoutes } from '../../../../utils';
import get from 'lodash/get';
import useEmployerOnboarding from '../../../../hooks/useEmployerOnboarding';
import { FieldValues } from 'react-hook-form';
import { useAlerts } from '../../../../hooks';
import DocumentForm from '../../../../components/employer/onboarding/DocumentForm';

const OnboardingDocumentCreate = () => {
  const navigate = useNavigate();
  const routes = getRoutes();
  const location = useLocation();
  const onboardingId = get(location, 'state.onboardingId', null);
  const { showToast } = useAlerts();

  const {
    documents: { new: createDocument },
  } = useEmployerOnboarding();

  const handleSave = async (formValues: FieldValues) => {
    // TODO NEED TO REQUIRE A FILE ON UPLOAD, ON CREATE
    const resource = await createDocument.create({
      variables: formValues,
    });

    const documentId = get(
      resource,
      'data.createOnboardingDocument.id',
      null,
    );
    if (documentId) {
      const routesWithId = getRoutes({ id: documentId });
      navigate(routesWithId.employer.onboarding.documents.edit, {
        state: {
          onboardingId,
        },
      });
      return null;
    }

    showToast({
      title: 'there was an error saving the document',
    });
    return null;
  };

  const handleCancel = () => {
    if (onboardingId) {
      const routesWithId = getRoutes({
        id: onboardingId,
      });
      navigate(routesWithId.employer.onboarding.edit);
    } else {
      navigate(routes.employer.onboarding.root);
    }
  };

  return (
    <Box>
      <DocumentForm
        handleSave={handleSave}
        handleCancel={handleCancel}
        loading={createDocument.loading}
        error={createDocument.error}
      />
    </Box>
  );
};

export default OnboardingDocumentCreate;
