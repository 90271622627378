import { QuizzesPage } from '../../components/EmployeeGrow';
import { useLazyQuery } from '@apollo/client';
import { GET_QUIZZES } from '../../api';
import { useEffect } from 'react';
import SchoolIcon from "@mui/icons-material/School";
import map from "lodash/map";
import ResourceCard from "../../components/EmployeeGrow/externalResources/ResourceCard";
import { styled } from "../../components";
import PageHeader from "../../components/Employee/PageHeader";

const StyledRoot = styled('div')(() => ({
  '& .quizzesContainer': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 18
  }
}));

const Quizzes = () => {
  const [fetchQuizzes, { data: quizzesData }] = useLazyQuery(
    GET_QUIZZES,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const quizzes = quizzesData?.getQuiz || [];

  useEffect(() => {
    fetchQuizzes();
  }, []);

  return (<StyledRoot>
      <PageHeader
        title="Learnings"
        subtitle="Jumpstart your career today"
        icon={<SchoolIcon />}
      />
      <div className="quizzesContainer">
        {map(quizzes, ({ name, value }, i) => (
          <ResourceCard key={`quiz__${i}`} title={name} link={value} />
        ))}
      </div>
    </StyledRoot>);
};

export default Quizzes;
