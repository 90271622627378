import { useLazyQuery } from '@apollo/client';
import { Box, Pagination, styled } from '@mui/material';
import { GET_EMPLOYEE_PATH_STATS } from 'api';
import { RetentionContext } from 'components/Retention/utils';
import { Spinner } from 'components/shared';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { memberOverviewStyles } from 'styles/Retention/Location/memberOverview';
import EmployeeCard from '../EmployeeCard';

const StyledBox = styled(Box)(memberOverviewStyles);

const MemberOverview = ({ activePath }) => {
  const { careerPathGroupId: pathId } = activePath;
  const [activePageNumber, setActivePageNumber] = useState(1);
  const employeesPerPage = 12;

  const { locationId: locId } = useContext(RetentionContext);
  // if navigating from brand, it should use the parameter passed through,
  //  but if navigating from url, user ctx because it will be the user's
  //  location, treating as a location user.
  const locationId = useLocation()?.state?.locationId || locId;

  const [
    fetchEmployeePathStats,
    {
      data: employeePathStats,
      loading: employeePathStatsLoading = true,
    },
  ] = useLazyQuery(GET_EMPLOYEE_PATH_STATS, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  useEffect(() => {
    fetchEmployeePathStats({
      variables: {
        locationId,
        careerPathGroupId: pathId,
        limit: employeesPerPage,
        offset: 0,
      },
    });
  }, []);

  if (employeePathStatsLoading) {
    return <Spinner />;
  }

  const pathStats = employeePathStats?.employeePathStats;
  const numOfEmployees = pathStats?.total;
  const employees = pathStats?.results;

  const handlePageChange = async (e, pageNumber) => {
    setActivePageNumber(pageNumber);
    await fetchEmployeePathStats({
      variables: {
        locationId,
        careerPathGroupId: pathId,
        limit: employeesPerPage,
        // pagination starts at 1 instead of 0 index, subtract 1 to get correct value
        offset: employeesPerPage * (pageNumber - 1),
      },
    });
  };

  return (
    <StyledBox>
      <Box className={'employeeCardWrapper'}>
        {employees?.map((employee) => (
            <EmployeeCard
              employeeId={employee.id}
              name={employee.name}
              imageUrl={employee.profileImageUrl}
              location={employee.location}
              currentJobType={employee.currentPathJobType}
              percentageComplete={employee.percentComplete}
              key={employee.id}
            />
          ))}
      </Box>
      {numOfEmployees / employeesPerPage > 1 ? (
        <Pagination
          count={Math.ceil(numOfEmployees / employeesPerPage)}
          size="medium"
          page={activePageNumber}
          onChange={handlePageChange}
          className="paginationBar"
        />
      ) : null}
    </StyledBox>
  );
};

export default MemberOverview;
