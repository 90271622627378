import { Box, styled } from 'components';
import React from 'react';
import { dashboardStyles } from '../../../../styles/Retention/Location';
import RetentionBreadcrumbs from '../RetentionBreadcrumbs';

interface IEmployerDetail {
  renderAsideResources?: () => JSX.Element[];
  children: JSX.Element;
  classes?: {
    root?: string;
    contentRoot?: string;
    asideRoot?: string;
  };
}

const StyledBox = styled(Box)(dashboardStyles);

const EmployerDetailContainer = ({
  renderAsideResources,
  children,
  classes = {},
}: IEmployerDetail) =>
  // sets up basic two column structure of the detail pages
   (
    <StyledBox
      hasAside={renderAsideResources}
      className={[
        classes.root,
        'employer-detail-container',
      ].join(' ')}
    >
      <Box
        className={[
          classes.contentRoot,
          'employer-detail-content',
        ].join(' ')}
      >
        <RetentionBreadcrumbs />
        {children}
      </Box>
      {renderAsideResources ? (
        <Box
          className={[
            classes.asideRoot,
            'employer-detail-aside',
          ].join(' ')}
        >
          {renderAsideResources()}
        </Box>
      ) : null}
    </StyledBox>
  )
;

export default EmployerDetailContainer;
