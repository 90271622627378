import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getRoutes, getUserId } from 'utils';
import { withEmployeeJobActions } from 'hocs';
import { useMediaQueryMatches } from 'hooks';
import { styled, Tabs, Tab } from 'components';
import {
  Button,
  Spinner,
  SimpleRating,
} from 'components/shared';
import {
  JobsFilter,
  CompanyProfile,
} from 'components/Dashboard/employee';
import JobCardsRenderer from 'containers/employee/JobCardsRenderer';
import {
  allJobsStyles,
  employerJobsStyles,
} from 'styles/Dashboard/EmployeeDashboard';
import { GET_EMPLOYEE_JOBS } from 'api';

const StyledRoot = styled('div')(({ theme }) => ({
  ...allJobsStyles({ theme }),
  ...employerJobsStyles({ theme }),
}));
const enhance = (Component) => withEmployeeJobActions(Component);
const ROUTES = getRoutes();
const PAGE_LIMIT = 10;
const OFFSET = 0;
const JOBS_TAB = 'jobs';
const ABOUT_TAB = 'about';
const REVIEWS_TAB = 'reviews';
const TABS = [
  {
    value: JOBS_TAB,
    label: 'Jobs',
    id: `tab-${JOBS_TAB}`,
    'aria-controls': `tabpanel-${JOBS_TAB}`,
  },
  {
    value: ABOUT_TAB,
    label: 'About',
    id: `tab-${ABOUT_TAB}`,
    'aria-controls': `tabpanel-${ABOUT_TAB}`,
  },
  {
    value: REVIEWS_TAB,
    label: 'Reviews',
    id: `tab-${REVIEWS_TAB}`,
    'aria-controls': `tabpanel-${REVIEWS_TAB}`,
  },
];

// eslint-disable-next-line react/prop-types
const TabPanel = ({ children, value, selected, ...rest }) => (
    <div
      role="tabpanel"
      hidden={!selected}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
    >
      {children}
    </div>
  )

const EmployerJobsView = (props) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { companyId } = useParams();
  const userId = getUserId();
  const [
    fetchAllJobs,
    {
      data: employerJobsData,
      loading: jobsLoading = true,
      called,
      fetchMore,
    },
  ] = useLazyQuery(GET_EMPLOYEE_JOBS, {
    variables: {
      offset: OFFSET,
      limit: PAGE_LIMIT,
      employerProfileId: Number(companyId),
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const employerJobs = employerJobsData?.employeeJobs || true;
  const incomingEmployerProfile =
    employerJobsData?.employeeJobs?.[0]?.employerProfile;

  const [tab, setTab] = useState(JOBS_TAB);
  const [employerProfile, setEmployerProfile] = useState({});
  const [openJobs, setOpenJobs] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (isEmpty(employerProfile) && incomingEmployerProfile) {
      setEmployerProfile(incomingEmployerProfile);
    }
  }, [incomingEmployerProfile]);

  useEffect(() => {
    if (employerJobs.length && openJobs == null)
      setOpenJobs(employerJobs.find((o) => o.total)?.total || 0);
  }, [employerJobs]);

  useEffect(() => {
    if (called) {
      setHasFetched(true);
    }
  }, [called]);

  const loginButton = (
    <Button
      isRouterLink
      to={ROUTES.login.default}
      variant="filled-primary"
      className="publicHeader__loginBtn"
    >
      Login
    </Button>
  );

  const renderAllJobsSeen = () => (
    <div className="employerJobs-mobile-finishedSwiping">
      <h2>
        These are all the jobs available for this location.
      </h2>
      <p>Please login to view jobs from other areas.</p>
      {loginButton}
    </div>
  );

  const noJobsText = {
    title: 'There are no jobs available for that company.',
    ctaNoUser: 'Please login to view jobs from other areas.',
    ctaActiveUser: 'Please check back again!',
  };

  const jobsLoadingText = {
    title: 'Jobs are loading, please wait.',
    ctaNoUser: 'Please login to view jobs from other areas.',
    ctaActiveUser: 'Please check back again!',
  };

  const renderNoJobsOrLoading = ({
    title,
    ctaActiveUser,
    ctaNoUser,
  }) =>
    userId ? (
      <div className="employerJobs-noneAvailable">
        <h2>{title}</h2>
        <p>{ctaActiveUser}</p>
        <Button
          isRouterLink
          to={ROUTES.employee.dashboard}
          variant="filled-primary"
          className="publicHeader__loginBtn"
        >
          View All Jobs
        </Button>
      </div>
    ) : (
      <div className="employerJobs-noneAvailable">
        <h2>{title}</h2>
        <p>{ctaNoUser}</p>
        {loginButton}
      </div>
    );

  const renderContent = ({
    renderJobsSection,
    renderSearch,
    setFilterSettings
  }) => {
    // call renderNoJobsOrLoading on initial render, otherwise it will reset inner components including filters
    if (jobsLoading && hasFetched)
      return renderNoJobsOrLoading(jobsLoadingText);
    if (isEmpty(employerJobs) && !openJobs)
      return renderNoJobsOrLoading(noJobsText);

    return (
      <div className="contentWrapper employerJobsView-container">
        <CompanyProfile
          className="employerDetails paddingX"
          name={employerProfile.name}
          imageUrl={employerProfile.imageUrl}
          openJobsCount={openJobs}
          address={`${employerProfile.address}, ${employerProfile.zip}`}
          industries={employerProfile.industry}
          rating={0}
          reviewsCount={0}
        />

        <Tabs
          value={tab}
          onChange={(e, newValue) => setTab(newValue)}
          classes={{
            root: 'tabsRoot paddingX',
            indicatorColor: 'tabsIndicator',
          }}
        >
          {TABS.map((o) => (
            <Tab
              key={o.value}
              {...o}
              disableRipple
              classes={{ root: 'tabRoot' }}
            />
          ))}
        </Tabs>
        <TabPanel
          value={JOBS_TAB}
          selected={tab === JOBS_TAB}
          className="tabPanel"
        >
          <div>
            {renderSearch({
              companyId: Number(companyId),
              showSettingsButton: false,
              showMobileGrowBanner: false,
              searchProps: {
                JobsSearchProps: {
                  nativeInputProps: {
                    placeholder: 'Search jobs',
                  },
                },
              },
            })}
            <div className="filterContainer paddingX">
              <JobsFilter
                className="jobsFilter"
                setFilters={setFilterSettings}
              />
            </div>
            <div className="paddingX">{renderJobsSection()}</div>
          </div>
        </TabPanel>
        <TabPanel
          value={ABOUT_TAB}
          selected={tab === ABOUT_TAB}
          className="tabPanel"
        >
          <p className="companyDescription paddingX">
            {employerProfile.description}
          </p>
        </TabPanel>
        <TabPanel
          value={REVIEWS_TAB}
          selected={tab === REVIEWS_TAB}
          className="tabPanel"
        >
          <div className="reviewsHeader paddingX">
            <span className="reviewsHeader__title">{`${0} Reviews`}</span>
            <SimpleRating rating={0} />
          </div>
          <div className="reviewsCards paddingX">
            {[5, 4, 3, 2, 1].map((mark) => (
              <div
                key={`reviewCard__${mark}`}
                className="reviewsCard"
              >
                <SimpleRating
                  className="reviewsCard__rating"
                  rating={mark}
                />
                <div className="reviewsCard__count">0</div>
                <div>Reviews</div>
              </div>
            ))}
          </div>
        </TabPanel>

        {jobsLoading && (
          <div className="contentLoader">
            <Spinner size={30} />
          </div>
        )}
      </div>
    );
  };

  return (
    <JobCardsRenderer
      {...props}
      jobs={employerJobs}
      fetchAllJobs={fetchAllJobs}
      jobsLoading={jobsLoading}
      fetchMore={fetchMore}
      initOffset={OFFSET}
      pageLimit={PAGE_LIMIT}
      mobileJobsView="list"
      paginationVariant="rePagination"
      showEmptyJobsMessage={false}
      render={(renderProps) => {
        const renderJobsSection =
          !isDesktop &&
          renderProps.swipeCount === employerJobs.length
            ? renderAllJobsSeen
            : renderProps.renderJobs;
        return (
          <StyledRoot className="container">
            {renderContent({
              ...renderProps,
              renderJobsSection,
            })}
          </StyledRoot>
        );
      }}
    />
  );
}

export default enhance(EmployerJobsView);
