import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { styled } from '../../../components';
import styles from '../../../styles/Dashboard/EmployerJobMetrics';
import { Button } from '../../../components/shared';
import { useMediaQueryMatches } from '../../../hooks';

const StyledRoot = styled(Paper)(styles);
const JobMetrics = ({
  applicantApplied = 'N/A',
  applicantHired = 'N/A',
  applicantNextSteps = 'N/A',
  applicantRejected = 'N/A',
  applicantStarred = 'N/A',
  jobCount = 'N/A',
  matchCount = 'N/A',
  todo = 'N/A'
}) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const [hideJobMetrics, setHideJobMetrics] = useState(!isDesktop);
  const Metric = ({ title, value }) => (
    <div className={'job_metric'}>
      <h2 className="job_metric_title">{title}</h2>
      <h2 className="job_metric_data">{value}</h2>
    </div>
  );

  const metricData = [
    { title: 'Applied', value: applicantApplied },
    { title: 'Matches', value: matchCount },
    { title: "Todo's", value: todo },
    { title: 'Jobs', value: jobCount },
    { title: 'Hired', value: applicantHired },
    { title: 'Next Steps', value: applicantNextSteps },
    { title: 'Rejected', value: applicantRejected },
    { title: 'Saved', value: applicantStarred }
  ];

  return (
    <StyledRoot className={'job_metrics_container'} elevation={1}>
      <div className={'job_metrics_header'}>
        <h3>Total Job Metrics</h3>
        <Button variant="filled-secondary" onClick={() => setHideJobMetrics(!hideJobMetrics)}>
          {hideJobMetrics ? 'Show' : 'Hide'}
        </Button>
      </div>
      {!hideJobMetrics && (
        <div className="job_metrics_data">
          {metricData.map(({ title, value }) => (
            <Metric key={`${title}-${value}`} title={title} value={value} />
          ))}
        </div>
      )}
    </StyledRoot>
  );
};


export default JobMetrics
