import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, styled } from '../../material-ui';

const StyledBox = styled(Box)(
  ({ theme, radius, healthscore, circumference }) => ({
    p: {
      zIndex: 1,
      position: 'relative',
      fontWeight: 800,
      top: radius * 0.25,
    },
    svg: {
      // steals positioning from Avatar/index.tsx
      transform: 'translate(-50%, -50%) rotate(90deg)!important',
      zIndex: 0,
    },
    '& circle': {
      fill: theme.palette.background.paper,
      stroke: healthscore
        ? theme.palette.primary.light
        : theme.palette.grey['400'],
      strokeWidth: 2,
      strokeDasharray:
        circumference + circumference * (healthscore / 100),
      strokeDashoffset: circumference,
    },
  }),
);
const AvatarHealthScoreFlourish = ({
  healthScore,
}: {
  healthScore: number;
}) => {
  const radius = 12;
  const circumference = Math.PI * radius * 2;
  return (
    <StyledBox
      radius={radius}
      healthscore={healthScore}
      circumference={circumference}
    >
      <Typography>{healthScore || 0}</Typography>
      <svg height="50" width="50">
        <circle cx="25" cy="25" r={radius} />
      </svg>
    </StyledBox>
  );
};

export default AvatarHealthScoreFlourish;
