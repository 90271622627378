import React, { useEffect } from 'react';
import PT from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import { getUserId } from 'utils';
import { Box, styled } from 'components';
import {
  Button,
  Spinner,
  StyledTooltip,
} from 'components/shared';
import { useForm } from 'components/form';
import { MdInfo } from 'components/icons';
import styles from 'styles/Onboarding';
import { useGrowJobTypes } from 'components/EmployeeGrow';
import {
  CREATE_CAREER_DEV_ON_REGISTRATION,
  GET_CAREER_DEV_WITH_HEALTH_SCORE,
} from 'api';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CareerPathStart = (props) => {
  const { onGoToNextStep, onChange, form } = props;
  const industryId = form.industryId || '';
  const curJobTypeId = form.curJobTypeId || '';

  const [createCareerDev, { loading: careerDevSaveLoading }] =
    useMutation(CREATE_CAREER_DEV_ON_REGISTRATION);
  const [fetchCareerDev] = useLazyQuery(
    GET_CAREER_DEV_WITH_HEALTH_SCORE,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    getJobTypesByIndustryId,
    getPathGroups,
    currentJobTypesOptions,
    typesLoading,
  } = useGrowJobTypes({ curJobTypeId, futureJobTypeId: '' });

  const { $, set, withValidation } = useForm({
    initial: { industryId, curJobTypeId },
  });

  useEffect(() => {
    if (industryId) {
      getJobTypesByIndustryId(Number(industryId));
      if (curJobTypeId) getPathGroups(curJobTypeId);
      set({ industryId });
    }
  }, [industryId]);

  useEffect(() => {
    set({ curJobTypeId });
  }, [curJobTypeId]);

  const next = withValidation(async () => {
    const userId = Number(getUserId());
    await createCareerDev({
      variables: { userId, curJobTypeId: Number(curJobTypeId) },
    });
    await fetchCareerDev({ variables: { userId } });
    onGoToNextStep();
  });

  const handleFieldChange = (value, { name }) => {
    const values = { industryId, curJobTypeId, [name]: value };
    set(values);
    onChange({ ...form, ...values });
  };

  return (
    <div
      className="pageContent"
      style={{ maxWidth: 461, alignItems: 'normal' }}
    >
      <Box component="p" mb="56px" className="text1">
        Defining your career helps us suggest connections,
        classes, and certifications to help you get where you
        want to go,{' '}
      </Box>
      <h2 className="title">Set your starting position</h2>
      <Box component="p" className="text1" mb="24px">
        Tell us where you currently are at in your career.
      </Box>

      <Box mb="24px">
        <p className="label">Where I'm at:</p>
        <Select
          id="currentJob"
          {...$('curJobTypeId', (e, meta) =>
            handleFieldChange(e.target.value, meta),
          )}
          required
          placeholder="Choose a position"
          variant="outlined"
          disabled={typesLoading || !industryId}
          testID="onboarding-grow-current-job-select"
          size={'small'}
          fullWidth
        >
          {currentJobTypesOptions.map((opt) => (
            <MenuItem
              disabled={opt.optionProps.disabled || false}
              value={opt.id}
            >
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mb="56px">
        <p className="text2">
          Don’t see your starting point?{' '}
          <StyledTooltip
            title="Select the closest position to your current role. Don’t worry, you can change this at
              any time under your account settings."
          >
            <MdInfo className="tooltipIcon" />
          </StyledTooltip>
        </p>
      </Box>

      <Box width="100%" maxWidth={224} mx="auto">
        <Button
          variant="filled-primary"
          disabled={
            !industryId ||
            !curJobTypeId ||
            careerDevSaveLoading ||
            typesLoading
          }
          endIcon={
            careerDevSaveLoading ? <Spinner size={24} /> : null
          }
          className="nextBtn"
          onClick={next}
          testID="onboarding-next-button"
        >
          Next
        </Button>
      </Box>
    </div>
  );
};

CareerPathStart.propTypes = {
  form: PT.objectOf(PT.any).isRequired,
  onGoToNextStep: PT.func.isRequired,
  onChange: PT.func.isRequired,
};

const StyledCareerPathStart = styled(CareerPathStart)(styles);

export default StyledCareerPathStart;
