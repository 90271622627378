import { cardBaseMixin } from '../../mixins';

const sendNotificationFlowStyles = ({ theme }) => ({
  '& .flow-container': {
    h2: {
      paddingBottom: 20,
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  '& .flow-message-template': {
    ...cardBaseMixin({ theme }),
    maxWidth: 350,
    h4: {
      paddingTop: 10,
      color: theme.palette.text.secondary,
      lineHeight: 1.65,
      span: {
        fontWeight: 800,
        color: theme.palette.text.primary,
        marginRight: 7.5,
      },
    },
  },
  '& .form-message-custom': {
    ...cardBaseMixin({ theme }),
    backgroundColor: theme.palette.background.default,
    '& div': {
      border: 'none',
    },
    '& textarea': {
      minWidth: 300,
      minHeight: 225,
      background: theme.palette.grey['800'],
      border: `1px solid ${theme.palette.primary.light}`,
      lineHeight: 1.5,
    },
  },
});

export default sendNotificationFlowStyles;
