import { useEffect } from 'react';
import { Box } from 'components';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import useEmployerOnboarding from '../../../../hooks/useEmployerOnboarding';
import { useNavigate } from 'react-router-dom';
import { getRoutes } from '../../../../utils';
import FlexRow from '../../../../components/shared/FlexRow';
import { Button, Spinner } from '../../../../components/shared';
import DocumentCard from '../../../../components/employer/onboarding/DocumentCard';
import { IOnboardingDocument } from '../../../../api/types/queries/onboarding.types';
import { styled } from '@mui/material/styles';

const StyledFlexRow = styled(FlexRow)<IStyledProps>(() => ({
  '& > div': {
    minWidth: 200,
    height: 125,
    marginRight: `0!important`,
  },
}));

const OnboardingDocuments = () => {
  const navigate = useNavigate();
  const routes = getRoutes();
  const {
    documents: { all: documentsAPI },
  } = useEmployerOnboarding();

  useEffect(() => {
    documentsAPI.get();
  }, []);

  const documents = get(
    documentsAPI,
    'data.onboardingDocuments.results',
    null,
  ) as IOnboardingDocument[];

  const handleDelete = async (id) => {
    console.log('handle delete');
    console.log('document id');
    console.log(id);
  };

  const handleEdit = (document: IOnboardingDocument) => {
    const routesWithId = getRoutes({ id: document.id });
    navigate(routesWithId.employer.onboarding.documents.edit, {
      state: {
        documentId: document.id,
      },
    });
  };

  return (
    <Box>
      <FlexRow justifyContent={'space-between'}>
        <Typography variant={'h2'}>All Documents</Typography>
        <FlexRow>
          <Button
            onClick={() =>
              navigate(routes.employer.onboarding.create)
            }
            variant={'outlined-primary'}
          >
            New Onboarding
          </Button>
          <Button
            onClick={() =>
              navigate(
                routes.employer.onboarding.documents.create,
              )
            }
            variant={'filled-primary'}
          >
            New Document
          </Button>
        </FlexRow>
      </FlexRow>
      <StyledFlexRow
        flexWrap={'wrap'}
        gap={(theme) => theme.spacing(1)}
      >
        {documentsAPI.loading || !documents ? (
          <Spinner />
        ) : (
          documents.map((document) => (
            <DocumentCard
              {...document}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              variant={'outlined'}
            />
          ))
        )}
      </StyledFlexRow>
    </Box>
  );
};

export default OnboardingDocuments;
