import { IGqlLazyQueryBaseResponse } from '../../../../api/types';

export const valueTypes = {
  INT: 'INT',
  PERCENT: 'PERCENT',
};

export interface IValueType {
  total: number;
  value: number;
  valueType: keyof typeof valueTypes;
}

export interface IBrandStats {
  brand: {
    id: number;
    logoUrl: string | null;
    name: string;
  };
  employeePulse: IValueType;
  interactionsScore: IValueType;
  locationCount: number;
  skillsCompleted: IValueType;
}

export interface ILocationStats {
  address: string;
  imageUrl: string;
  name: string;
  employeePulse: IValueType;
  interactionsScore: IValueType;
  skillsCompleted: IValueType;
}

export interface ILocationStatsLazyQuery
  extends IGqlLazyQueryBaseResponse {
  locationsStats: {
    offset: number;
    pages: number;
    total: number;
    results: ILocationStats[];
  };
}
