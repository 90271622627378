import Typography from '@mui/material/Typography';
import { Button } from '../../shared';
import { Box } from '../../material-ui';
import React, { useRef, useEffect } from 'react';
import confetti from 'canvas-confetti';
import colors from '../../../styles/colors';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(() => ({
  width: '75vw',
  height: '50vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  '.title': {
    fontSize: 45,
    marginBottom: 10,
  },
  '.companyDescription': {
    marginBottom: 10,
    fontSize: 15,
  },
  '.navigationButton': {},
}));
const Welcome = ({ next, companyName }) => {
  const myCanvas = useRef();
  const themeMode = localStorage.getItem('colorMode') || 'light';

  useEffect(() => {
    if (myCanvas && myCanvas.current) {
      const myConfetti = confetti.create(myCanvas.current, {
        resize: true,
        useWorker: true,
      });
      myConfetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        colors: [
          colors[themeMode].primary.main,
          colors[themeMode].text.secondary,
          colors[themeMode].primary.contrastText,
        ],
      });
      setTimeout(() => {
        myConfetti.reset();
      }, 5000);
    }
  }, []);

  return (
    <StyledBox>
      <canvas
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
        id="my-canvas"
        ref={myCanvas}
      />
      <Typography className={'title'} variant={'h1'}>
        WELCOME TO THE TEAM!
      </Typography>
      <Typography className={'companyDescription'}>
        from {companyName}
      </Typography>
      <Button
        className="navigationButton"
        variant={'filled-primary'}
        onClick={next}
      >
        Let's Start
      </Button>
    </StyledBox>
  );
};

export default Welcome;
