import { Typography } from '@mui/material';
import { Box } from 'components';
import React from 'react';
import { v4 } from 'uuid';
import { DonutChart } from '../../../shared/charts';
import { PathSynopsis } from '../../api.types';

const PathCard = (
  {
    handleNavigation,
    locationId,
    employerName,
    averageCompletionTime,
    totalSkills,
    skillsCompleted,
    estimatedCompletionRate,
    employees,
  }: {
    // eslint-disable-next-line no-unused-vars
    handleNavigation: (a: number, b: string) => void;
  } & PathSynopsis,
) => (
    <div
      className={'dashboard-card-container path-card-container'}
    >
      <Box
        onClick={() =>
          handleNavigation(
            locationId,
            employerName,
          )
        }
      >
        <Typography variant={'h2'} className={'location-title'}>
          <span className={'status-icon'} />
          {employerName}
        </Typography>
        <Box className={'card-chart-container'}>
          <Box className={'card-chart-item'}>
            <DonutChart
              width={60}
              height={60}
              label={''}
              data={[
                100 - averageCompletionTime,
                averageCompletionTime,
              ]}
              chartLabels={['incomplete', 'complete']}
            />
            <Typography variant={'h4'} paragraph>
              Avg Comp Time
            </Typography>
          </Box>
          <Box className={'card-chart-item'}>
            <DonutChart
              width={60}
              height={60}
              label={''}
              data={[
                // this will show the correct value or 100%
                // unavailable if no skills
                totalSkills > 0
                  ? totalSkills - skillsCompleted
                  : 100,
                skillsCompleted,
              ]}
              chartLabels={['incomplete', 'complete']}
            />
            <Typography variant={'h4'} paragraph>
              Skills Completed
            </Typography>
          </Box>
          <Box className={'card-chart-item'}>
            <DonutChart
              width={60}
              height={60}
              label={''}
              data={[
                100 - estimatedCompletionRate,
                estimatedCompletionRate,
              ]}
              chartLabels={['incomplete', 'complete']}
            />
            <Typography variant={'h4'} paragraph>
              Est Comp rate
            </Typography>
          </Box>
        </Box>
        <Box className={'team'}>
          {employees.profileImages != null ? (
            [
              <Typography
                key={`team__${v4()}`}
                className={'accent1'}
                variant={'body1'}
              >
                Enrolled Team
              </Typography>,
              <Box
                key={`team-members__${v4()}`}
                className={'team-member-container'}
              >
                <Box className={'team-members'}>
                  {employees.profileImages.map(
                    (teamMember) => (
                      <img
                        src={teamMember}
                        key={`emp-profile-img__${v4()}`}
                        alt=""
                      />
                    ),
                  )}
                </Box>
                <Box className={'team-count'}>
                  <Typography variant={'h4'}>
                    + {employees.count}
                  </Typography>
                </Box>
              </Box>,
            ]
          ) : (
            <Typography className={'accent1'} variant={'body1'}>
              No Employees Enrolled
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  );
export default PathCard;
