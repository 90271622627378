import { Outlet } from 'react-router-dom';
import flowRight from 'lodash/flowRight';
import {
  withCommonUI,
  withAlerts,
  withNotifications,
} from 'hocs';
import { Suspense } from 'react';
import Footer from "../../components/Footer";

const enhance = flowRight(
  withAlerts,
  withCommonUI,
  withNotifications,
);

const RootLayout = () => (
  <Suspense fallback={<div>Loading</div>}>
    <Outlet />
    <Footer />
  </Suspense>
);

export default enhance(RootLayout);
