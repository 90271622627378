import useEmployerOnboarding from 'hooks/useEmployerOnboarding';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getRoutes } from 'utils';
import { Button, Spinner } from 'components/shared';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import FlexRow from 'components/shared/FlexRow';
import { Box } from 'components';
import ResourceCard from '../../../components/employer/onboarding/ResourceCard';
import DocumentCard from '../../../components/employer/onboarding/DocumentCard';
import { IOnboardingDocument } from '../../../api/types/queries/onboarding.types';
import AssignmentCard from '../../../components/employer/onboarding/AssignmentCard';

interface IStyledProps {}

const StyledBox = styled(Box)<IStyledProps>(({ theme }) => ({
  [theme.breakpoints.down('desktopApp')]: {
    padding: theme.spacing(2),
    '.onboardingCardContainer': {
      '> *': {
        maxWidth: `calc(50% - 8px)`,
        marginBottom: '1rem',
        '&:nth-of-type(2n)': {
          marginRight: `0!important`,
        },
      },
    },
  },
}));

const OnboardingEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const routes = getRoutes();
  const {
    onboarding: { oneById: onboardingAPI },
  } = useEmployerOnboarding();

  const { data = {}, loading } = onboardingAPI;
  const { onboarding } = data;

  useEffect(() => {
    if (params.id) {
      onboardingAPI.get({
        variables: {
          onboardingId: params.id,
        },
      });
    } else {
      navigate(routes.employer.onboarding.root);
    }
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (!onboarding) {
    return (
      <Box>
        <Typography>
          Looks like something went wrong, please refresh the
          page
        </Typography>
      </Box>
    );
  }

  const deleteDocument = (documentId) => {
    console.log('deleteDocument');
    console.log('documentId');
    console.log(documentId);
  };

  const editDocument = (document: IOnboardingDocument) => {
    const routesWithId = getRoutes({
      id: document.id,
    });
    navigate(routesWithId.employer.onboarding.documents.edit, {
      state: {
        onboardingId: onboarding.id,
        documentId: document.id,
      },
    });
  };

  const manageFlow = () => {
    const routesWithId = getRoutes({
      id: onboarding.id,
    });
    navigate(routesWithId.employer.onboarding.flow, {
      state: {
        onboardingId: onboarding.id,
      },
    });
  };

  return (
    <StyledBox>
      <FlexRow
        justifyContent={'space-between'}
        mt={'1rem'}
        mb={'1.5rem'}
      >
        <Typography variant={'h2'}>{onboarding.name}</Typography>
      </FlexRow>
      <Box>
        <FlexRow justifyContent={'space-between'} mt={2}>
          <Typography variant={'h3'}>Pages</Typography>
          <Button
            variant={'outlined-primary'}
            onClick={manageFlow}
          >
            Manage Flow
          </Button>
        </FlexRow>
        <FlexRow>
          <FlexRow className={'onboardingCardContainer'}>
            {onboarding.resourceAssociations.map(
              (resource) =>
                resource.resource.isPage && (
                  <ResourceCard
                    variant={'outlined'}
                    {...resource.resource}
                  />
                ),
            )}
          </FlexRow>
        </FlexRow>
      </Box>
      <Box>
        <FlexRow justifyContent={'space-between'} mt={2}>
          <Typography variant={'h3'}>Resources</Typography>
          <Button
            variant={'filled-primary'}
            onClick={() =>
              navigate(
                routes.employer.onboarding.resources.create,
                {
                  state: {
                    onboardingId: onboarding.id,
                  },
                },
              )
            }
          >
            New
          </Button>
        </FlexRow>
        <FlexRow className={'onboardingCardContainer'}>
          {onboarding.resourceAssociations.map(
            (resource) =>
              !resource.resource.isPage && (
                <ResourceCard
                  variant={'outlined'}
                  {...resource.resource}
                />
              ),
          )}
        </FlexRow>
      </Box>
      <Box>
        <FlexRow justifyContent={'space-between'} mt={2}>
          <Typography variant={'h3'}>Documents</Typography>
          <Button
            variant={'filled-primary'}
            onClick={() =>
              navigate(
                routes.employer.onboarding.documents.create,
                {
                  state: {
                    onboardingId: onboarding.id,
                  },
                },
              )
            }
          >
            New
          </Button>
        </FlexRow>
        <FlexRow className={'onboardingCardContainer'}>
          {onboarding.documentAssociations.map((document) => (
            <DocumentCard
              variant={'outlined'}
              {...document.document}
              handleDelete={deleteDocument}
              handleEdit={editDocument}
            />
          ))}
        </FlexRow>
      </Box>
      <Box>
        <FlexRow justifyContent={'space-between'}>
          <Typography variant={'h3'}>Assignees</Typography>
          <Button variant={'filled-primary'}>New</Button>
        </FlexRow>
        <FlexRow className={'onboardingCardContainer'}>
          {onboarding.assignments.map((assignment) => (
            <AssignmentCard {...assignment} />
          ))}
        </FlexRow>
      </Box>
    </StyledBox>
  );
};

export default OnboardingEdit;
