import React, { useEffect } from 'react';
import PT from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import { getUserId } from 'utils';
import { Box, styled } from 'components';
import {
  Button,
  Spinner,
  StyledTooltip,
} from 'components/shared';
import { useForm } from 'components/form';
import { MdInfo } from 'components/icons';
import styles from 'styles/Onboarding';
import { useGrowJobTypes } from 'components/EmployeeGrow';
import {
  CREATE_CAREER_DEV_ON_REGISTRATION,
  GET_CAREER_DEV_WITH_HEALTH_SCORE,
} from 'api';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CareerPathEnd = (props) => {
  const { onGoToNextStep, onChange, form } = props;
  const industryId = form.industryId || '';
  const curJobTypeId = form.curJobTypeId || '';
  const futureJobTypeId = form.futureJobTypeId || '';

  const [createCareerDev, { loading: careerDevSaveLoading }] =
    useMutation(CREATE_CAREER_DEV_ON_REGISTRATION);
  const [fetchCareerDev] = useLazyQuery(
    GET_CAREER_DEV_WITH_HEALTH_SCORE,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    getJobTypesByIndustryId,
    getPathGroups,
    futureJobTypesOptions,
    typesLoading,
  } = useGrowJobTypes({ curJobTypeId, futureJobTypeId: '' });

  const { $, set, withValidation } = useForm({
    initial: { industryId, curJobTypeId, futureJobTypeId },
  });

  useEffect(() => {
    if (industryId) {
      getJobTypesByIndustryId(Number(industryId));
      if (curJobTypeId) getPathGroups(curJobTypeId);
      set({ industryId });
    }
  }, [industryId]);

  useEffect(() => {
    set({ futureJobTypeId });
  }, [futureJobTypeId]);

  const next = withValidation(async () => {
    const userId = Number(getUserId());
    await createCareerDev({
      variables: {
        userId,
        futureJobTypeId: Number(futureJobTypeId),
      },
    });
    await fetchCareerDev({ variables: { userId } });
    onGoToNextStep();
  });

  const handleFieldChange = (value, { name }) => {
    const values = { industryId, curJobTypeId, [name]: value };
    set(values);
    onChange({ ...form, ...values });
  };

  return (
    <div
      className="pageContent"
      style={{ maxWidth: 461, alignItems: 'normal' }}
    >
      <h2 className="title">
        What is your career goal position?
      </h2>
      <Box component="p" className="text1" mb="24px">
        Tell us where you would like to be in your career. (Don’t
        worry you can change this at any point)
      </Box>

      <Box mb="24px">
        <p className="label">Where I'm going:</p>
        <Select
          id="futureJob"
          {...$('futureJobTypeId', (e, meta) =>
            handleFieldChange(e.target.value, meta),
          )}
          required
          placeholder="Choose a position"
          variant="outlined"
          disabled={typesLoading || !industryId}
          testID="onboarding-grow-future-job-select"
          size={'small'}
          fullWidth
        >
          {futureJobTypesOptions.map((opt) => (
            <MenuItem
              disabled={opt.optionProps.disabled || false}
              value={opt.id}
            >
              {opt.optionProps.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mb="56px">
        <p className="text2">
          Don’t see your end point?{' '}
          <StyledTooltip
            title="Select from our currently available positions (you can change this at anytime under
              account settings). We’re adding new positions soon."
          >
            <MdInfo className="tooltipIcon" />
          </StyledTooltip>
        </p>
      </Box>

      <Box width="100%" maxWidth={224} mx="auto">
        <Button
          variant="filled-primary"
          disabled={
            (!industryId && !curJobTypeId && !futureJobTypeId) ||
            careerDevSaveLoading ||
            typesLoading
          }
          endIcon={
            careerDevSaveLoading ? <Spinner size={24} /> : null
          }
          className="nextBtn"
          onClick={next}
          testID="onboarding-next-button"
        >
          Next
        </Button>
      </Box>
    </div>
  );
};

CareerPathEnd.propTypes = {
  form: PT.objectOf(PT.any).isRequired,
  onGoToNextStep: PT.func.isRequired,
  onChange: PT.func.isRequired,
};

const StyledCareerPathEnd = styled(CareerPathEnd)(styles);

export default StyledCareerPathEnd;
