import { addAlpha } from 'styles/utils';

export default ({ theme }) => ({
  '&.container': {
    width: '100%',
    maxWidth: 307
  },
  '& .content': {
    position: 'relative',
    minHeight: 250,
    padding: '11px 9px 9px',
    border: `1px solid ${theme.palette.grey['200']}`,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    backgroundColor: theme.palette.background.default,
    '&.hired': {
      borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.background.paper
    },
    '&.denied': {
      borderColor: '#BB3737',
      backgroundColor: '#481E2E'
    }
  },
  '& .cardHeaderBtn': {
    position: 'absolute',
    right: 12,
    top: 12,
    width: 0,
    height: 0,
    color: theme.palette.text.primary
  },
  '& .cardTitle': {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.tertiary.light
  },
  '& .image': {
    width: 55,
    height: 55,
    padding: 0,
    borderRadius: '100%',
    objectFit: 'cover',
    '&.confirmed': {
      border: `2px solid ${theme.palette.success.main}`
    },
    '&.notComing': {
      border: `2px solid ${theme.palette.error.main}`
    },
    '&.hired': {
      width: 78,
      height: 78
    },
    '&.hired, &.denied': {
      border: `3px solid ${theme.palette.common.white}`
    }
  },
  '& .name': {
    marginBottom: 3,
    fontWeight: 800,
    fontSize: 18,
    textAlign: 'center',
    color: theme.palette.common.white
  },
  '& .time': {
    marginBottom: 6,
    fontWeight: 700,
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.grey['200']
  },
  '& .position': {
    marginBottom: 24,
    fontWeight: 700,
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.primary.light
  },
  '& .status': {
    width: '100%',
    marginBottom: 24,
    // padding: 5,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 700,
    // boxShadow: '0px 2px 3px #00000026',
    // background: addAlpha(theme.palette.primary.light, 0.1),
    // borderRadius: 2,
    color: theme.palette.quaternary.main,
    '&.confirmed': {
      color: theme.palette.success.main
    },
    '&.notComing': {
      color: '#BB3737'
    }
  },
  '& .cardAction': {
    width: '100%',
    padding: '8px 16px',
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: -0.5,
    borderRadius: 4,
    '&:not(:first-of-type)': {
      marginLeft: 7
    },
    '&.danger': {
      color: theme.palette.common.white,
      borderRadius: 4,
      border: '1px solid',
      borderColor: '#BB3737',
      backgroundColor: '#481E2E'
    }
  },
  '& .confirmAction': {
    padding: '5px 12px',
    fontSize: 10,
    borderRadius: 3
  },
  '& .messageAction': {
    width: '100%',
    padding: '9px 7px',
    fontSize: 10,
    borderRadius: 3
  },
  '& .undoAction': {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.light
  },
  '& .optionButton': {
    width: '100%',
    padding: '9px 0',
    borderTop: '1px solid #E5E5E5',
    borderRadius: 0,
    display: 'block',
    fontSize: 10,
    textAlign: 'center',
    pointer: 'cursor',
    '&.hired': {
      color: theme.palette.text.secondary,
      borderColor: theme.palette.grey['300']
    },
    '&.denied': {
      color: theme.palette.text.secondary,
      borderColor: theme.palette.grey['300']
    }
  },
  '& .calendarLinks': {
    marginBottom: 10,
    textAlign: 'center'
  },
  '& .calendarLinks__label': {
    fontWeight: 700,
    fontSize: 12,
    color: theme.palette.common.white
  },
  '& .calendarLinks__link': {
    marginLeft: 5,
    verticalAlign: 'middle',
    '&:first-of-type': {
      marginLeft: 12
    }
  },
  '& .reasonCheckbox': {
    visibility: 'hidden',
    position: 'absolute',
    zIndex: -1
  },
  '& .notesInput': {
    flex: 1,
    maxHeight: 160,
    overflow: 'auto',
    alignItems: 'flex-start',
    borderRadius: 0,
    fontSize: 14,
    '& textarea': {
      padding: 5
    }
  },
  '& .notesInput__label': {
    marginBottom: 3,
    fontSize: 9,
    color: theme.palette.text.secondary
  },
  '& .loaderOverlay': {
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: addAlpha(theme.palette.background.default, 0.7)
  },
  '& .interviewRank': {
    marginBottom: 32
  }
});
