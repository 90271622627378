import { addAlpha } from 'styles/utils';

export default ({ theme, variant }) => ({
  '&.container': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    // '&:hover': {
    //   '& .dataCell__withActions': {
    //     '& .dataCell__mainData': {
    //       display: 'none'
    //     },
    //     '& .dataCell__actions': {
    //       display: 'flex'
    //     }
    //   }
    // },
    [theme.breakpoints.down('desktopApp')]: {
      ...(variant === 'filled' && {
        border: 0,
        '&:not(:last-of-type)': {
          marginBottom: 10,
        },
        '& .innerContainer': {
          borderRadius: 10,
          backgroundColor: addAlpha(
            theme.palette.background.paper,
            0.5,
          ),
        },
      }),
    },
  },
  '& .dataCell': {
    // padding: '9px 16px 9px 0',
    marginRight: 16,
    textAlign: 'left',
    [theme.breakpoints.down('desktopApp')]: {
      marginRight: 10,
      ...(variant === 'filled' && {
        padding: 0,
      }),
    },
  },
  '& .dataCell_last': {
    marginLeft: 'auto',
    marginRight: 0,
    paddingRight: 0,
    // padding: '8px 0',
    display: 'flex',
    alignItems: 'center',
    // [theme.breakpoints.down('desktopApp')]: {
    //   display: 'none'
    // }
  },
  '& .dataCell__actions': {
    display: 'none',
  },
  '& .innerContainer': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '& .detailsContainer': {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: '40%',
      flexDirection: 'column',
      '& .dataCell': {
        margin: 0,
      },
    },
  },
  '& .clickablePart': {
    width: '100%',
    // maxWidth: '70%',
    padding: '9px 0',
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    [theme.breakpoints.down('desktopApp')]: {
      paddingLeft: 0,
      maxWidth: 'calc(100% - 35px)',
      // ...(variant === 'filled' && {
      // })
    },
  },
  '& .avatar': {
    position: 'relative',
    display: 'block',
    width: 38,
    height: 38,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down('desktopApp')]: {
      ...(variant === 'filled' && {
        height: 60,
        minWidth: 60,
        borderRadius: 6,
      }),
    },
    '& > img': {
      borderRadius: 'inherit',
      objectFit: 'cover',
      [theme.breakpoints.down('desktopApp')]: {
        width: '100%',
        height: '100%',
      },
    },
  },
  '& .textMain': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 12,
      lineHeight: '14px',
      ...(variant === 'filled' && {
        fontWeight: 'bold',
      }),
    },
  },
  '& .textSecondary': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    opacity: 1,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 12,
      lineHeight: '14px',
      // ...(variant === 'filled' && {
      // })
    },
  },
  '& .mottoExcerpt': {
    maxWidth: 290,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& .statusText': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '17px',
    color: theme.palette.text.secondary,
    '&.error': {
      color: theme.palette.error.main,
    },
  },
  '& .chevronIcon': {
    marginRight: 0,
    marginLeft: 'auto',
    display: 'none',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'block',
    },
  },
  '& .avatarIcon': {
    position: 'absolute',
    right: -6,
    bottom: -4,
    width: 24,
    height: 24,
    border: '2px solid #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: theme.palette.primary.light,
    borderRadius: '50%',
    backgroundColor: theme.palette.quaternary.main,
  },
  '& .questionsContainer': {
    width: '100%',
  },
  '& .questionsContainer__title': {
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  '& .questionItem': {
    paddingBottom: 5,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: theme.palette.background.paper,
  },
  '& .questionItem__q': {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
  '& .questionItem__a': {
    marginLeft: 30,
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  '& .notesInput': {
    marginBottom: 15,
    '& .StyledInput-inputBase__input': {
      padding: '8px 10px',
    },
  },
});
