import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon1 = (props) => (
    <SvgIcon viewBox="0 0 35.15 30.46" {...props}>
      <polygon
        points="10.22 16.2 14.8 8.27 20.35 8.27 15.77 16.2 10.22 16.2"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="21.03 9.å45 25.61 17.38 22.83 22.18 18.26 14.25 21.03 9.45"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="21.48 22.19 12.32 22.19 9.55 17.39 18.7 17.39 21.48 22.19"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="29.21 7.09 15.48 7.09 19.57 0 25.12 0 29.21 7.09"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="19.42 30.45 26.29 18.56 30.38 25.65 27.61 30.45 19.42 30.45"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="4.09 10.3 10.96 22.19 2.77 22.19 0 17.39 4.09 10.3"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="18.21 0 8.86 16.2 4.77 9.11 10.03 0 18.21 0"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="31.06 24.47 21.71 8.27 29.9 8.27 35.15 17.38 31.06 24.47"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="3.46 23.38 22.16 23.38 18.07 30.46 7.55 30.46 3.46 23.38"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon1;
