import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import isExists from 'date-fns/isExists';
import isBefore from 'date-fns/isBefore';
import subYears from 'date-fns/subYears';
import map from 'lodash/map';
import { PRIVACY_URL } from 'utils';
import { useAlerts, useMediaQueryMatches } from 'hooks';
import { Box, useTheme, styled } from 'components';
import { useForm } from 'components/form';
import { Button, Input, Select, Spinner } from 'components/shared';
import { MdExclamation } from 'components/icons';
import { POST_USER_BIRTHDATE, getOnboardingStatistics } from 'api';
import styles from 'styles/Onboarding';
import PrivacyClauseButton from './PrivacyClauseButton';

const DoubleInputRow = styled('div')({
  width: '100%',
  marginBottom: 16,
  display: 'flex',
  justifyContent: 'space-between'
});

const StyledBox = styled(Box)(styles);

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];
const NAME = 'birth_date';
const MIN_AGE = 15;

const isValidDate = ({ day, month, year }) =>
  !!(day && month && year) && isExists(parseInt(year), parseInt(month), parseInt(day));

const UserBirthdayForm = ({
  calledToasts,
  setCalledToasts,
  formKey,
  form,
  onChange,
  onGoToNextStep
}) => {
  const theme = useTheme();
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { showToast } = useAlerts();

  const getInitForm = () => {
    const birthdayDate = form[NAME] ? new Date(form[NAME]) : '';
    const parts = {
      day: birthdayDate ? birthdayDate.getDate().toString() : '',
      month: birthdayDate ? birthdayDate.getMonth().toString() : '0',
      year: birthdayDate ? birthdayDate.getFullYear().toString() : ''
    };
    return {
      ...parts,
      isValid: isValidDate(parts)
    };
  };

  const [postUserBirthDate, { loading }] = useMutation(POST_USER_BIRTHDATE);
  const { attrs, $, set, getError, withValidation } = useForm({
    initial: { ...getInitForm() },
    validations: {
      day: [
        'presence',
        { numericality: { leadingZeros: true, onlyInteger: true, greaterThan: 0, lessThan: 32 } }
      ],
      month: [{ presence: { message: 'Please select month' } }],
      year: [
        'presence',
        {
          numericality: {
            onlyInteger: true,
            greaterThan: 1900
          }
        }
      ],
      isValid: {
        rules: [
          (value, { attrs: a }) => {
            const { day, month, year } = a;
            const date = new Date(year, month, day);
            const maxDate = subYears(new Date(), MIN_AGE);

            if (!isValidDate({ day, month, year })) {
              return 'The information you entered is not valid';
            }
            if (!isBefore(date, maxDate)) {
              return `You must be ${MIN_AGE} years old or above`;
            }
            return '';
          }
        ],
        deps: ['day', 'month', 'year']
      }
    }
  });

  const showAnalyticToast = async () => {
    if (calledToasts.indexOf(formKey) === -1) {
      const info = (await getOnboardingStatistics(form.zip)) || {};
      if (info.randomPerson) {
        setCalledToasts([...calledToasts, formKey]);
        showToast({
          title: `${info.randomPerson} just got hired in your area! You can be next! 🙂`,
          icon: () => <MdExclamation />,
          flourishText: 'This could be you!',
          position: { top: false, right: false }
        });
      }
    }
  };

  useEffect(() => {
    showAnalyticToast();
  }, []);

  const next = withValidation(async ({ day, month, year }) => {
    const dateStr = `${year}-${Number(month) + 1}-${day}`;
    onChange({ ...form, [NAME]: dateStr });
    await postUserBirthDate({
      variables: { [NAME]: dateStr }
    });
    onGoToNextStep();
  });

  const handleDateChange = (field) => (e) => {
    let val = field === 'month' ? e.target.value : e.target.value.replace(/[^0-9]/, '');
    if (field === 'day' && val.length > 2) val = val.replace(/^0*/, '');
    if (field === 'month') {
      val = MONTHS.indexOf(val) >= 0 ? MONTHS.indexOf(val).toString() : '';
    }

    set(field, val);
  };

  return (
    <StyledBox>
      <div className="pageContent">
        <DoubleInputRow style={{ maxWidth: isDesktop ? '100%' : 286 }}>
          <Input
            id="day"
            {...$('day')}
            type="tel"
            placeholder="Day"
            autoComplete="off"
            required
            withHelperText
            highlightInputOnError={false}
            disabled={loading}
            className="input"
            FormControlProps={{ sx: { flex: 1 } }}
            analyticParams={{
              key: 'Employee day of birth focused (onboarding)',
              trigger: 'focus'
            }}
            onChange={handleDateChange('day')}
            testID="onboarding-day-input"
          />
          <Select
            id="month"
            value={MONTHS[attrs.month]}
            native
            required
            withHelperText
            inputVariant="outlined"
            containerProps={{ sx: { ml: '16px' } }}
            InputComponentProps={{ className: 'input', name: 'month' }}
            testID="onboarding-month-input"
            disabled={loading}
            analyticParams={{
              key: 'Employee month of birth focused (onboarding)',
              trigger: 'focus'
            }}
            onChange={handleDateChange('month')}
            emptyOption={
              <Box component="option" disabled value="" color="rgba(255,255,255, 0.5)">
                Month
              </Box>
            }
            options={map(MONTHS, (m) => ({ value: m, label: m }))}
          />
        </DoubleInputRow>
        <Input
          id="year"
          {...$('year')}
          placeholder="Year"
          autoComplete="off"
          required
          type="tel"
          error={getError('year') || getError('isValid')}
          withHelperText
          highlightInputOnError={false}
          disabled={loading}
          className="input"
          FormControlProps={{
            sx: { maxWidth: isDesktop ? '100%' : 286, mb: '16px' }
          }}
          analyticParams={{
            key: 'Employee year of birth focused (onboarding)',
            trigger: 'focus'
          }}
          onChange={handleDateChange('year')}
          testID="onboarding-year-input"
        />
        <div style={{ maxWidth: isDesktop ? 320 : 280 }}>
          <p className="primaryText" style={{ marginBottom: 22, fontStyle: 'italic' }}>
            Did you know?
          </p>
          <p className="secondaryText" style={{ marginBottom: 16 }}>
            By law, we're required to ask this, see{' '}
            <a href={PRIVACY_URL} style={{ color: theme.palette.common.white }}>
              here
            </a>{' '}
            for more details.
          </p>
        </div>
        <Button
          variant="filled-primary"
          endIcon={loading && <Spinner size={24} />}
          disabled={loading}
          className="nextBtn"
          sx={{ mb: isDesktop ? '25px' : '13px' }}
          onClick={next}
          testID="onboarding-next-button"
        >
          Next
        </Button>
        <PrivacyClauseButton />
      </div>
    </StyledBox>
  );
}

UserBirthdayForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default UserBirthdayForm;
