import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Avatar } from 'components/shared';
import EventMessageModal from '../../../shared/Modal/EventMessageModal';

export interface IEvent {
  eventDate: string;
  eventTitle: string;
  eventType: string;
  name: string;
  profileId: number;
  profileUrl: string;
}
const LocationUpcomingEventCard = ({
  event,
}: {
  event: IEvent;
}) => {
  const timeStamp = parseInt(event.eventDate);
  const date = new Date(timeStamp);
  const formattedEventDate = date.toLocaleDateString('en-US');

  const [showMessageModal, setShowMessageModal] =
    useState<boolean>(false);
  const [showResponse, setShowResponse] =
    useState<boolean>(false);

  const handleSubmit = () => {
    setShowMessageModal(false);
    setShowResponse(true);
  };

  return (
    <Box key={`${date}-${event.name}`} className="parentWrapper">
      <Dialog className="messageDialog" open={showMessageModal}>
        <DialogTitle id="form-dialog-title">Message</DialogTitle>
        <Typography>Send to {event.name}</Typography>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="multiline-text"
            label="Multiline Text"
            type="text"
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowMessageModal(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog className="messageDialog" open={showResponse}>
        <DialogContent>
          <Typography>Message Sent</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowResponse(false)}
            color="primary"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <Box className="cardStart">
        <Avatar
          height={48}
          width={48}
          url={event.profileUrl}
          type="EMPLOYEE"
          classes={{
            imgOverride: 'memberImage',
          }}
        />
        <Box className="textWrapper">
          <Typography className="eventName">
            {event.name}
          </Typography>
          <Typography className="eventTitle">
            {event.eventTitle}
          </Typography>
          <Typography className="eventTitle">
            {formattedEventDate}
          </Typography>
        </Box>
      </Box>
      <Box className="cardEnd">
        <EventMessageModal
          event={event}
          employeeName={event.name}
        />
      </Box>
    </Box>
  );
};

export default LocationUpcomingEventCard;
