import { useQuery } from '@apollo/client';
import { List, Typography } from '@mui/material';
import React, { useState } from 'react';
import { GET_EMPLOYEES_BY_LOCATION } from 'api';
import { Box, styled } from 'components';
import { CheckBox, Avatar } from 'components/shared';
import styles from 'styles/Retention/Survey/ChooseEmployee';

interface IEmployeeProfile {
  employeeId: number;
  name: string;
  imageUrl: string;
  jobType?: string;
  startDate: Date;
}

interface IEmployeeProfileResults {
  results: IEmployeeProfile[];
}

interface IGetEmployeesByLocation {
  employeesByLocation: IEmployeeProfileResults[];
}

interface IChooseEmployeeBase {
  locationId: number;
  limit?: number;
  title?: string;
}

interface IChooseEmployeesAllDataArgs
  extends IChooseEmployeeBase {
  employeeIds: IEmployeeProfile[];
  setEmployeeIds: (a: IEmployeeProfile[]) => void;
  // this is used to return the entire employee object if needed
  returnAllData?: true;
}

interface IChooseEmployeesArgs extends IChooseEmployeeBase {
  employeeIds: number[];
  setEmployeeIds: (a: number[]) => void;
  // this is used to return the entire employee object if needed
  returnAllData?: false;
}

const StyledBox = styled(Box)(styles);

const ChooseEmployees = ({
  employeeIds = [],
  setEmployeeIds,
  locationId,
  title,
  limit = null,
  returnAllData = false,
}: IChooseEmployeesArgs | IChooseEmployeesAllDataArgs) => {
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [limitReached, setLimitReached] =
    useState<boolean>(false);

  const { loading, data } = useQuery<IGetEmployeesByLocation>(
    GET_EMPLOYEES_BY_LOCATION,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: {
        locationId,
        limit: 10000,
      },
    },
  );

  const selectAll = (e) => {
    // survey.recipients.recipientIds = [];
    setAllChecked(e.target.checked);
    setEmployeeIds(
      e.target.checked
        ? data.employeesByLocation.results.map(
            ({ employeeId }) => employeeId,
          )
        : [],
    );
  };

  const onCheckboxChanged = (e, empId) => {
    setAllChecked(false);

    const uncheckEmployee = () => {
      const newEmployeeIds = [...employeeIds];

      newEmployeeIds.splice(
        newEmployeeIds.findIndex((element) =>
          returnAllData ? element.employeeId : element === empId,
        ),
        1,
      );
      setEmployeeIds(newEmployeeIds);
    };

    if (limit === employeeIds.length) {
      if (!e.target.checked) {
        uncheckEmployee();
        setLimitReached(false);
      } else {
        setLimitReached(true);
      }
      return null;
    }
    if (e.target.checked) {
      setEmployeeIds([...employeeIds, empId]);
    } else {
      uncheckEmployee();
    }
    return null;
  };

  return (
    <StyledBox>
      <Typography variant={'h1'}>
        {title || 'Choose recipients'}
      </Typography>
      <Box className={'choose-employee-container'}>
        <Box className="form-container">
          {limit == null && (
            <Box className={'select-all-employees'}>
              <CheckBox
                label="Select all"
                onChange={selectAll}
                checked={allChecked}
              />
            </Box>
          )}
          {limitReached && (
            <Typography variant={'h6'}>
              You can only select {limit} at a time
            </Typography>
          )}
          <Box className="employeeList">
            <List>
              {!loading &&
                data.employeesByLocation.results.map(
                  (employee) => (
                    <Box
                      key={employee.employeeId}
                      className={'choose-employee-row'}
                    >
                      <Box className="employee-item">
                        <CheckBox
                          value={employee.employeeId}
                          checked={(returnAllData
                            ? employeeIds.map(
                                (e) => e.employeeId,
                              )
                            : employeeIds
                          ).includes(employee.employeeId)}
                          onChange={(e) =>
                            onCheckboxChanged(
                              e,
                              returnAllData
                                ? employee
                                : employee.employeeId,
                            )
                          }
                        />
                        <Avatar
                          url={employee.imageUrl}
                          type={'EMPLOYEE'}
                          width={30}
                          height={30}
                        />
                        <Box
                          className={'employee-identification'}
                        >
                          <Typography variant={'h4'}>
                            {employee.name} {employee.jobType}{' '}
                          </Typography>
                          <Typography variant={'h5'}>
                            {employee.startDate}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ),
                )}
            </List>
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default ChooseEmployees;
