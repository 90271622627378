import React, { useState } from 'react';
import PT from 'prop-types';
import map from 'lodash/map';
import { MenuItem, Popover, styled } from 'components';
import Button from '../Button';

const StyledPopover = styled(({ className, ...props }) => (
  <Popover {...props} classes={{ root: className }} />
))(() => {});

const PopupMenu = (props) => {
  const {
    className,
    children,
    id,
    items,
    getTrigger,
    onItemClick,
    triggerTitle,
    triggerProps,
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const popupId = open ? id : undefined;

  const openPopup = (e) => setAnchorEl(e.currentTarget);
  const closePopup = () => setAnchorEl(null);

  const renderTrigger = () => {
    if (getTrigger) {
      return getTrigger({
        anchorEl,
        setAnchorEl,
        openPopup,
        triggerProps: {
          'aria-describedby': popupId,
          ...triggerProps
        }
      });
    }
    return (
      <Button aria-describedby={popupId} onClick={openPopup} disableRipple {...triggerProps}>
        {triggerTitle}
      </Button>
    );
  };

  const renderItems = () =>
    map(items, (item, i) => (
      <MenuItem
        key={`item__${i}`}
        {...(item?.itemProps || {})}
        onClick={(e) => onItemClick(e, i, item, { setAnchorEl, closePopup })}
      >
        {(item.renderContent && item.renderContent()) || item.label}
      </MenuItem>
    ));

  return (
    <>
      {renderTrigger()}
      <StyledPopover
        id={popupId}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={className}
        {...rest}
      >
        {(children && children({ anchorEl, setAnchorEl, closePopup })) || renderItems()}
      </StyledPopover>
    </>
  );
}

PopupMenu.propTypes = {
  children: PT.any,
  className: PT.string,
  getTrigger: PT.func,
  id: PT.any,
  items: PT.arrayOf(
    PT.shape({
      value: PT.any,
      label: PT.any,
      renderContent: PT.func,
      itemProps: PT.objectOf(PT.any)
    })
  ),
  onItemClick: PT.func,
  triggerTitle: PT.oneOfType([PT.string, PT.node]),
  triggerProps: PT.objectOf(PT.any)
};

PopupMenu.defaultProps = {
  children: undefined,
  className: '',
  getTrigger: undefined,
  id: undefined,
  items: [],
  triggerProps: {},
  triggerTitle: 'Popup'
};

export default PopupMenu;
