export const TabPanel = ({
  children,
  value,
  selected,
  ...rest
}) =>
  selected ? (
    <div
      role="tabpanel"
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
    >
      {children}
    </div>
  ) : null;
