import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useSpring, animated } from 'react-spring';
// eslint-disable-next-line import/no-cycle
import { Button } from '..';
import { styled } from '../../material-ui';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: never;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: never;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(
  (props, ref) => {
    const {
      children,
      in: open,
      onClick,
      onEnter,
      onExited,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ownerState,
      ...other
    } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter(null as never, true);
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited(null as never, true);
        }
      },
    }) as never;

    return (
      <animated.div ref={ref} style={style} {...other}>
        {React.cloneElement(children, { onClick })}
      </animated.div>
    );
  },
);

const StyledBox = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: 4,
  borderRadius: 7.5,
  padding: '1.5rem',
  maxHeight: '75vh',
  [theme.breakpoints.down('desktopApp')]: {
    width: `calc(100% - ${theme.spacing(4)})`,
  },
  '& .modal-children': {
    maxHeight: '65vh',
    overflowY: 'scroll',
  },
  '& Button': {
    marginTop: '16px',
    backgroundColor: 'none',
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.primary,
  },
}));

const SpringModal = ({
  buttonText,
  children,
  onSuccess,
  hideButtons = false,
  buttonProps = {}
}: {
  buttonText: string;
  children: React.ReactNode;
  onSuccess?: () => void;
  hideButtons?: boolean;
  buttonProps?: {size?: 'small', variant?: 'outlined' | 'contained', color?: 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warn'}
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSuccess = () => {
    handleClose();
    onSuccess();
  };

  return (
    <div>
      <Button onClick={handleOpen} variant={'outlined'} {...buttonProps}>
        {buttonText}
      </Button>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        className={'findMe'}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <StyledBox>
            <Box className={'modal-children'}>{children}</Box>
            {!hideButtons && (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-around'}
              >
                {onSuccess && (
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleSuccess}
                  >
                    Ok
                  </Button>
                )}
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </StyledBox>
        </Fade>
      </Modal>
    </div>
  );
};
export default SpringModal;
