import { useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getUserId } from 'utils';
import { GET_USER_PROFILE } from 'api';

function useEmployeeProfileQuery(props) {
  const { autoFetch = true, ...queryOptions } = props || {};

  const [fetch, result] = useLazyQuery(GET_USER_PROFILE, {
    variables: { userId: Number(getUserId()) },
    ...queryOptions
  });
  const profile = result?.data?.employeeProfile;

  useEffect(() => {
    if (autoFetch) fetch({ ...queryOptions });
  }, [autoFetch]);

  return useMemo(
    () => ({
      fetch,
      profile,
      ...result
    }),
    [fetch, profile, result]
  );
}

export default useEmployeeProfileQuery;
