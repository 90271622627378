import { addAlpha } from '../utils';

export default ({ theme }) => ({
  overflow: 'none',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: 8,
  [theme.breakpoints.down('desktopApp')]: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: 'none',
    marginBottom: 16,
    padding: 0,
    display: 'block',
    '&:last-of-type': {
      marginBottom: 50,
    },
  },
  '&.card': {
    width: '100%',
    padding: theme.spacing(1.5),
    border: `1px solid ${addAlpha(
      theme.palette.grey['200'],
      0.5,
    )}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: '16px !important',
    transition: 'box-shadow 300ms linear',
    '&:hover, &:focus': {
      boxShadow: `0 10px 20px 0 ${addAlpha(
        theme.palette.grey['100'],
        0.16,
      )}`,
    },
    flex: 1,
    borderColor: theme.palette.background.paper,
  },
  '.card__infoContainer': {
    flexWrap: 'noWrap',
  },
  '.card__infoContent': {
    alignItems: 'start',
  },
  '& .card__photoAreaSmall': {
    position: 'relative',
    minHeight: 71,
    width: '25%',
    objectFit: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16,
    float: 'left',
    marginRight: 7,
  },
  '& .card__name': {
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    wordBreak: 'break-word',
    textAlign: 'left',
  },
  '& .card__stats': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
  },
  '& .draftLabel': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: 3,
    lineHeight: 1,
    padding: '5px 10px',
  },
  '& .card__dateTitle': {
    fontSize: 12,
  },
  '& .card__date': {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
  '& .card__metrics__total__wrapper': {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    width: '95%',
    padding: '6px 6px 2px 6px',
    borderTop: `1px solid ${theme.palette.primary.light}`,
    borderBottom: 'none',
    '& .card__metrics__total': {
      minWidth: 48,
      position: 'absolute',
      padding: '0 8px',
      backgroundColor: theme.palette.background.default,
      translate: '0px -100%',
      '& span': {
        color: theme.palette.primary.light,
      },
    },
  },
  '& .card__metrics': {
    display: 'flex',
    width: '100%',
    color: theme.palette.primary.main,
    '& > span': {
      padding: '4px 8px',
      margin: 4,
      borderRadius: 4,
      display: 'flex',
      minWidth: 60,
      flexGrow: 1,
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      fontSize: 16,
      fontWeight: 800,
      border: `1px solid ${theme.palette.grey['200']}`,
      '> span': {
        fontSize: 10,
      },
    },
    '& > .anomaly': {
      display: 'flex',
      minWidth: 60,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        height: 7.5,
        width: 7.5,
        borderRadius: 20,
        opacity: 0,
        zIndex: 1,
        left: -3,
        top: -3,
      },
      '&.__success': {
        '&::before': {
          backgroundColor: theme.palette.common.white,
          opacity: 1,
        },
      },
      '&.__error': {
        '&::before': {
          backgroundColor: theme.palette.error.main,
          opacity: 1,
        },
      },
    },
  },
});
