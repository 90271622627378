import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { Box, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { getProfileId } from 'utils';
import { RATE_EMPLOYEE_BY_EMPLOYER } from 'api';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.interviewRank': {},
  '& .interviewRank__header': {
    position: 'relative',
    padding: '0 40px',
    marginBottom: 12,
    textAlign: 'center',
  },
  '& .interviewRank__title': {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  '& .interviewRank__undo': {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.light,
  },
  '& .interviewRank__rankingContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  '& .interviewRank__rankingLabel': {
    flexShrink: 0,
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.grey['200'],
    flexBasis: '50%',
    '&:nth-of-type(2)': {
      textAlign: 'right',
    },
  },
  '& .interviewRank__ranks': {
    width: '100%',
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .interviewRank__rank:not(:first-of-type)': {
      marginLeft: 12,
    },
  },
  '& .interviewRank__rank': {
    width: 24,
    height: 24,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.common.white,
    '&.selected': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.big': {
      width: 37,
      height: 37,
    },
  },
}));

const MARKS = [0, 1, 2, 3, 4];

const InterviewRanking = (props) => {
  const { rate, employeeProfileId, cacheObjectId } = props;

  const [postRank, { loading }] = useMutation(
    RATE_EMPLOYEE_BY_EMPLOYER,
    {
      fetchPolicy: 'no-cache',
      update(cache, { data: { rateByEmployerEmployee } }) {
        if (cacheObjectId) {
          cache.modify({
            id: cacheObjectId,
            fields: {
              rate(cachedRate) {
                return (
                  rateByEmployerEmployee?.rate ?? cachedRate
                );
              },
            },
          });
        }
      },
    },
  );

  const handleRateEmployee = async (selectedRate) => {
    postRank({
      variables: {
        rate: selectedRate,
        employeeProfileId: Number(employeeProfileId),
        employerProfileId: Number(getProfileId()),
      },
    });
  };

  const [rank, setRank] = useState(null);

  useEffect(() => {
    setRank(rate);
  }, [rate]);

  return (
    <StyledRoot className="interviewRank">
      <div className="interviewRank__header">
        <span className="interviewRank__title">
          {rank !== null
            ? 'Interview ranking'
            : 'Rank interview'}
        </span>
        {rank !== null && (
          <Button
            variant="text"
            className="interviewRank__undo"
            onClick={() => setRank(null)}
          >
            Undo
          </Button>
        )}
      </div>
      {(() => {
        if (loading) {
          return (
            <Box display="flex" justifyContent="center">
              <Spinner size={24} />
            </Box>
          );
        }

        return rank !== null ? (
          <Box textAlign="center">
            <div className="interviewRank__rank selected big">
              {rank + 1}
            </div>
          </Box>
        ) : (
          <div className="interviewRank__rankingContainer">
            <span className="interviewRank__rankingLabel">
              Very bad
            </span>
            <span className="interviewRank__rankingLabel">
              Great!
            </span>
            <span className="interviewRank__ranks">
              {MARKS.map((mark) => (
                <Button
                  key={`mark__${mark}`}
                  className="interviewRank__rank"
                  disabled={loading}
                  onClick={() => handleRateEmployee(mark)}
                >
                  {mark + 1}
                </Button>
              ))}
            </span>
          </div>
        );
      })()}
    </StyledRoot>
  );
};

InterviewRanking.propTypes = {
  rate: PT.number,
  employeeProfileId: PT.number.isRequired,
  cacheObjectId: PT.string.isRequired,
};

InterviewRanking.defaultProps = {
  rate: null,
};

export default InterviewRanking;
