import React, { useState } from 'react';
import clsx from 'clsx';
import { styled } from 'components';
import { Button } from 'components/shared';
import { SkillsModal } from 'components/dialogs';
import { MdChevronRight } from 'components/icons';
import Chip from '@mui/material/Chip';

const PREFIX = 'jobSkills';
const classes = {
  container: `${PREFIX}-container`,
  chips: `${PREFIX}-chips`,
  updateButton: `${PREFIX}-updateButton`,
};
const StyledRoot = styled('div')(() => ({
  [`&.${classes.container}`]: {},
  [`& .${classes.chips}`]: {
    marginTop: -13,
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`& .${classes.updateButton}`]: {
    marginTop: 10,
    fontSize: 12,
  },
}));

interface IEditableJobSkills {
  className: string;
  isEditMode: boolean;
  form: {
    skills: {name: string; skill_id: number}[]
  };
  onFormChange: () => void
}

const EditableJobSkills = ({
  className = '',
  isEditMode,
  form,
  onFormChange = () => null
}: IEditableJobSkills) => {
  const [isSkillsModalOpen, setIsSkillsModalOpen] =
    useState(false);

  return (
    <StyledRoot className={clsx(className, classes.container)}>
      <div className={classes.chips}>
        {form.skills.map(({ name, skill_id: skillId }) => (
          <Chip
            key={`skill__${skillId}`}
            label={name}
            color={'primary'}
            style={{ marginRight: 13 }}
          />
        ))}
      </div>
        <Button
          disabled={!isEditMode}
          variant="text"
          endIcon={<MdChevronRight color="primary" />}
          className={classes.updateButton}
          onClick={() => setIsSkillsModalOpen(true)}
          testID="skills-modal-trigger-button"
        >
          Update Skills
        </Button>
      <SkillsModal
        isOpen={isSkillsModalOpen}
        formAttrs={form}
        onChange={onFormChange}
        onClose={() => setIsSkillsModalOpen(false)}
      />
    </StyledRoot>
  );
};

export default EditableJobSkills;
