import React from 'react';
import { FormControlLabel, MuiCheckbox, styled } from 'components';
import { qaAttr } from 'utils';
import { MdCheck } from '../../icons';

interface CheckBoxProps {
  analyticParams?: {
    trigger: 'focus';
    key?: string;
  },
  className?: string,
  formControlLabelStyles?: {
    root?: Record<string, unknown>;
    label?: {[s:string]: string}
  },
  checkboxStyles?: {
    checkbox__root?: Record<string, unknown>;
  },
  checkboxProps?: Record<string, unknown>;
  checkedIconProps?: object;
  onFocus?: () => void;
  testID?: string;
}

const PREFIX = 'StyledCheckbox';
const classes = {
  checkbox: `${PREFIX}-checkbox`,
  formControl: `${PREFIX}-formControl`,
  label: `${PREFIX}-label`
};

const CheckBox = ({
  className = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formControlLabelStyles = {
    root: {},
    label: {}
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkboxStyles = {
    checkbox__root: {}
  },
  checkboxProps = {},
  checkedIconProps = {},
  onFocus = () => {},
  testID = '',
  ...rest
}: CheckBoxProps) => {

  const { inputProps, ...restCheckboxProps } = checkboxProps;
  const handleFocus = (e) => {
    const focusFunc = onFocus || inputProps?.onFocus;
    if (focusFunc) focusFunc(e);
  };

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          color="primary"
          icon={<svg style={{ display: 'none' }} />}
          checkedIcon={<MdCheck {...checkedIconProps} />}
          classes={{
            root: classes.checkbox
          }}
          inputProps={{
            onFocus: handleFocus,
            ...(testID && qaAttr(`checkbox-input-${testID}`)),
            ...inputProps
          }}
          {...restCheckboxProps}
        />
      }
      className={className}
      classes={{
        root: classes.formControl,
        label: classes.label
      }}
      {...(testID && qaAttr(`checkbox-${testID}`))}
      {...rest}
    />
  );
}

const StyledCheckBox = styled(CheckBox)(({ theme }) => ({
  width: '100%',
  margin: 0,
  display: 'inline-flex',
  [`&.${classes.label}`]: {
    marginLeft: 15,
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: '17px',
    color: theme.palette.text.primary
  },
  [`& .${classes.checkbox}`]: {
    minWidth: 24,
    width: 33,
    height: 33,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary
  },
  [`& .${classes.checkbox}.Mui-disabled`]: {
    color: theme.palette.text.primary
  },
  [`& .${classes.label}.Mui-disabled`]: {
    color: theme.palette.text.primary
  }
}));

export default StyledCheckBox;
