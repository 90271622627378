import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import {
  GET_COMMUNICATION_TEMPLATES,
  SEND_NOTIFICATION,
} from 'api';
import { IEvent } from '../../Retention/components/Location/LocationUpcomingEventCard';
import { MdChat, MdCheckCircle } from '../../icons';
import { styled } from '../../index';

const StyledModal = styled('div')(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& > div': {
    width: '100%',
    '& h3': {
      marginBottom: '8px',
    },
    '& span': {
      color: theme.palette.text.secondary,
    },
  },
  '& .messagePreview': {
    marginTop: '12px',
    padding: '8px',
    borderRadius: '8px',
    minWidth: '400px',
    maxWidth: '400px',
    minHeight: '100px',
    backgroundColor: theme.palette.button.primary.contrastText,
    color: theme.palette.text.secondary,
  },
  '& .buttonWrapper': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  '& .buttonPrimary': {
    backgroundColor:
      theme.palette.button.primary.main,
  },
  '& .buttonSecondary': {
    border: `1px solid ${theme.palette.button.primary.borderColor}`,
    marginRight: '8px',
  },
  '& .doneButton': {
    marginTop: '12px',
  },
  '& .errorModal': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '400px',
    maxWidth: '400px',
  },
  '& .successIcon': {
    color: theme.palette.secondary.main,
    marginBottom: '8px',
    height: '32px',
    width: '32px',
    '& svg ': {
      height: '32px',
      width: '32px',
    },
  },
}));

interface IEventMessageModalProps {
  event: IEvent;
  employeeName: string;
}
const EventMessageModal = ({
  event,
  employeeName,
}: IEventMessageModalProps) => {
  const { eventType, eventTitle, profileId } = event;
  const [open, setOpen] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const [
    sendNotificationToEmployee,
    { error: sendNotificationToEmployeeError },
  ] = useLazyQuery(SEND_NOTIFICATION, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const [
    getCommunicationTemplates,
    { data: getCommunicationTemplatesData },
  ] = useLazyQuery(GET_COMMUNICATION_TEMPLATES, {
    variables: { templateName: eventType }, //eventType
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  useEffect(() => {
    getCommunicationTemplates();
  }, []);

  const eventMessage =
    getCommunicationTemplatesData?.getCommunicationTemplates[0]
      .template;
  const eventMessageWithName = eventMessage?.replace(
    '[employeeName]',
    employeeName,
  );

  const sendNotification = async () => {
    if (!eventMessage) {
      return;
    }
    try {
      await sendNotificationToEmployee({
        variables: {
          notificationData: {
            employees: [{ profileId }],
            msg: eventMessageWithName,
          },
        },
      });
      setShowSuccess(true);
    } catch(err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Message = () => {
    if (sendNotificationToEmployeeError) {
      return (
        <Box className={'errorModal'}>
          <div>Error</div>
          <div>{`${sendNotificationToEmployeeError}`}</div>
          <Button
            className={'buttonPrimary doneButton'}
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Box>
      );
    }

    if (showSuccess) {
      return (
        <Box>
          <Icon className={'successIcon'}>
            <MdCheckCircle />
          </Icon>
          <h3>Message Sent Successfully</h3>
          <Button
            className={'buttonPrimary doneButton'}
            onClick={() => handleClose()}
          >
            Done
          </Button>
        </Box>
      );
    }

    return (
      <Box>
        <Box>
          <h3>Send {eventTitle} message</h3>
          <h4>
            <span>to:</span> {employeeName}
          </h4>
        </Box>
        <Box className={'messagePreview'}>
          <Typography paragraph>
            {eventMessageWithName}
          </Typography>
        </Box>
        <Box className={'buttonWrapper'}>
          <Button
            className={'buttonSecondary'}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={'buttonPrimary'}
            onClick={sendNotification}
          >
            Send
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {!showSuccess ? (
        <IconButton
          className="messageIcon"
          onClick={() => setOpen(true)}
        >
          <MdChat />
        </IconButton>
      ) : (
        <p>Sent</p>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <StyledModal>
          <Message />
        </StyledModal>
      </Dialog>
    </>
  );
};
export default EventMessageModal;
