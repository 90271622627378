import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlerts } from 'hooks';
import { getRoutes } from 'utils';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_COMMUNICATION_TEMPLATES,
  SEND_TEMPLATE_TEXT,
} from 'api/queries/notifications';
import get from 'lodash/get';
import Typography from '@mui/material/Typography';
import sendNotificationFlowStyles from 'styles/Retention/Interactions/sendNotificationFlow';
import { Box, styled } from '../../material-ui';
import {
  Input,
  Spinner,
  Step,
  StepNavigation,
} from '../../shared';
import ChooseEmployees from './ChooseEmployees';
import { RetentionContext } from '../utils';

const StyledBox = styled(Box)(sendNotificationFlowStyles);

const SendNotificationFlow = ({
  templateName,
  isCustomMessage = false,
}: {
  templateName: string;
  isCustomMessage?: boolean;
}) => {
  const [employeeIds, setEmployeeIds] = useState([]);
  const [message, setMessage] = useState<string | null>(null);

  const location = useLocation();
  const ctx = useContext(RetentionContext);
  const { setSimpleAlert } = useAlerts();
  const navigate = useNavigate();
  const routes = getRoutes();

  const { data, loading } = useQuery(
    GET_COMMUNICATION_TEMPLATES,
    {
      variables: {
        templateName,
      },
    },
  );

  const template = get(
    data,
    'getCommunicationTemplates[0]',
    null,
  );

  const [
    sendTemplateText,
    { loading: sendingMessage, called, error },
  ] = useLazyQuery(SEND_TEMPLATE_TEXT, {
    fetchPolicy: 'network-only',
  });

  if (loading || !template) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }

  if (called && !error) {
    setSimpleAlert({
      isOpen: true,
      title: 'Message Sent',
      onCancel: () => setSimpleAlert({ isOpen: false }),
    });
    navigate(
      `${routes.employer.retention_portal.location}/${ctx.retentionPages.pulse}`,
      { replace: true },
    );
  }

  return (
    <StyledBox>
      <StepNavigation
        submitCallback={async () => {
          await sendTemplateText({
            variables: {
              templateId: template.id,
              recipientIds: employeeIds,
              message,
            },
          });
        }}
        submitLoading={sendingMessage}
      >
        <Step>
          <Box className={'flow-container'}>
            <Typography variant={'h2'}>Message</Typography>
            {isCustomMessage ? (
              <Box className={'form-message-custom'}>
                <Input
                  onChange={(e) =>
                    message && message.length > 160
                      ? null
                      : setMessage(e.target.value)
                  }
                  value={message}
                  multiline
                />
              </Box>
            ) : (
              <Box className={'flow-message-template'}>
                <Typography variant={'h4'}>
                  <span>Title:</span> {template.name}
                </Typography>
                <Typography variant={'h4'}>
                  <span>Message:</span> {template.template}
                </Typography>
              </Box>
            )}
          </Box>
        </Step>
        <Step>
          <Box className={'flow-container'}>
            <ChooseEmployees
              setEmployeeIds={setEmployeeIds}
              employeeIds={employeeIds}
              locationId={location?.state?.locationId}
            />
          </Box>
        </Step>
        <Step>
          <Box className={'flow-container'}>
            <Typography variant={'h2'}>Review</Typography>
            <Box className={'flow-message-template'}>
              <Typography variant={'h4'}>
                <span>To:</span> {employeeIds.length} employees
              </Typography>
              <Typography variant={'h4'}>
                <span>Message:</span>{' '}
                {isCustomMessage ? message : template.template}
              </Typography>
            </Box>
          </Box>
        </Step>
      </StepNavigation>
    </StyledBox>
  );
};

export default SendNotificationFlow;
