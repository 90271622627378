import React from 'react';
import PT from 'prop-types';
import { qaAttr } from 'utils';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from 'components';
// import SimpleAlert from '../SimpleAlert';
import Button from '../Button';

const InputAlert = ({
  title,
  subtitle = '',
  onSuccess = () => null,
  onCancel,
  // onClose,
  isOpen,
  inputs,
  setInputAlert
}) => (
    <Dialog
      open={isOpen}
      onClose={() => (onCancel ? onCancel() : setInputAlert({ isOpen: false }))}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" {...qaAttr('input-alert-title')}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: '12px' }}>{subtitle}</DialogContentText>
        {inputs}
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', marginTop: '2.5rem', justifyContent: 'center' }}>
          <Button
            onClick={!onCancel ? () => setInputAlert({ isOpen: false }) : onCancel}
            variant="filled-secondary"
            sx={{ width: 130, height: 44 }}
            testID="input-alert-cancel-button"
          >
            Cancel
          </Button>
          <div style={{ marginLeft: 10 }}>
            <Button
              onClick={() => {
                onSuccess();
                if (setInputAlert) setInputAlert({ isOpen: false });
              }}
              variant="filled-secondary"
              sx={{ width: 130, height: 44 }}
              testID="input-alert-ok-button"
            >
              OK
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )

InputAlert.propTypes = {
  title: PT.string,
  subtitle: PT.string,
  onSuccess: PT.func,
  onCancel: PT.func,
  isOpen: PT.bool.isRequired,
  setInputAlert: PT.func,
  inputs: PT.node
};

InputAlert.defaultProps = {
  title: '',
  subtitle: '',
  onSuccess: () => null,
  onCancel: undefined,
  setInputAlert: () => {},
  inputs: null
};

export default InputAlert;
