import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = ({ className, size = 40, ...props }) => {
  return (
    <CircularProgress
      className={className}
      color={'secondary'}
      size={size}
      {...props}
    />
  );
};

export default Spinner;
