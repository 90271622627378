import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon9 = (props) => (
    <SvgIcon viewBox="0 0 32.02 33.94" {...props}>
      <path
        d="M17.33,0c-6.99,0-8.68,9.01-2.25,7.29l6.09-1.63c5.25-1.41,8.4,7.11-.78,10.2-6.31,2.12-8.42,10.29-1.92,8.55,2.33-.62,6.6-1.69,5.48,2.55-1.68,6.26,1.06,3.94,3.96,.28,2.26-2.85,3.75-6.41,4.05-10.26C32.69,7.6,26.14,0,17.33,0Z"
        fill="currentColor"
      />
      <path
        d="M16.94,26.65l-6.09,1.63c-5.25,1.41-8.4-7.1,.78-10.2,6.31-2.13,8.42-10.29,1.92-8.55-2.33,.63-6.6,1.69-5.48-2.55,1.68-6.26-1.06-3.94-3.96-.28C1.84,9.56,.36,13.12,.06,16.97c-.73,9.37,5.82,16.97,14.63,16.97,6.99,0,8.68-9.01,2.25-7.29Z"
        fill="currentColor"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon9;
