const styles = ({ theme }) => ({
  maxWidth: 600,
  margin: '0 auto',
  width: '100%',
  '& .navigation-container': {
    padding: 24,
  },
  '& .navigation': {
    width: '100%',
    padding: `24px 0px`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .header': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .new-survey-form': {},
  '& .question-container': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 8,
    margin: '16px 0 16px 0',
    width: '100%',
  },
  '& .question-item': {
    width: '100%',
  },
  '& .question': {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
  },
  '& .answer': {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '& .question-options': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '16px',
    padding: '16px',
  },
  '& .add-answer': {
    display: 'flex',
    alignItems: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  '& .delete-answer-button, & .delete-question-button': {
    svg: {
      fill: theme.palette.error.main,
    },
  },
  '& .label-row': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
    '.label': {
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
  '& .question-input': {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.light,
    borderRadius: 8,
  },
});
export default styles;
