import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
  useCommonUI,
  useNotifications,
  useEmployeeTrainings,
  useMatchedRoutes,
  useEmployerProfileQuery,
  useMediaQueryMatches,
} from 'hooks';
import {
  getEmployerSubscriptionInfo,
  isPaymentEnabled,
} from 'utils';
import { trackPage } from 'utils/segmentAnalytics';
import { styled } from 'components';
import {
  EmployerHeader,
  EmployeeHeader,
} from 'components/Header';
import { ShortCompanyCard } from 'components/Dashboard/employer';
import AppLayout from './AppLayout';

const StyledHeaderContainer = styled('div')({
  width: '100%',
  maxWidth: 1024,
  margin: '0 auto',
  '&.absolute': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  '&.employee-grow': {
    maxWidth: 1380,
  },
});

const PrivateLayout = ({ children, userRole }) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const location = useLocation();
  const { openUpdatePlanModal, openPaymentModal } =
    useCommonUI();
  const { show: showNotification } = useNotifications();

  const { profile: employerProfile, subscriptionInfo } =
    useEmployerProfileQuery({
      autoFetch: userRole === 'employer',
    });

  const {
    isEmployerProfilePage,
    isEmployerPlansPage,
    isEmployerNewJobPage,
    isEmployerEditJobPage,
    isEmployeeGrowPage,
    isEmployerRetentionPage,
  } = useMatchedRoutes();

  const showShortCompanyCard =
    userRole === 'employer' &&
    !isDesktop &&
    !isEmployerNewJobPage &&
    !isEmployerEditJobPage &&
    !isEmployerProfilePage &&
    !isEmployerPlansPage;

  const { fetchTrainings, trainings } = useEmployeeTrainings();
  const unTakenTrainings = trainings.filter(
    (o) => !o.complete && !o.taken,
  );

  useEffect(() => {
    if (userRole === 'employer') {
      if (isEmployerProfilePage) {
        const { planId } = location?.state || {};
        const planIdToNum = Number(planId);
        const { currentPlanId } = subscriptionInfo;
        if (planIdToNum && currentPlanId !== planIdToNum) {
          openUpdatePlanModal({
            planId: planIdToNum,
            leadToPlans: false,
          });
        }
      }
    } else {
      fetchTrainings();
    }
  }, []);

  useEffect(() => {
    if (employerProfile) {
      const {
        allowPlan,
        profile_id = null,
        mergeApiId,
        mergeAccountToken,
      } = employerProfile;

      localStorage.setItem('profileId', profile_id);
      // SETTING MERGE COOKIE ***********************************************************
      const hasMerge = mergeApiId && mergeAccountToken ? 1 : 0;
      localStorage.setItem('hasMerge', hasMerge);
      // SETTING MERGE COOKIE ***********************************************************

      const { isUpgradeRequired, isPaymentRequired } =
        getEmployerSubscriptionInfo(employerProfile);

      if (isPaymentEnabled() && allowPlan) {
        const { planId } = location?.state || {};
        const selectedPlan =
          isEmployerProfilePage && Number(planId);
        if (!selectedPlan) {
          if (!isEmployerPlansPage && isUpgradeRequired) {
            openUpdatePlanModal({ leadToPlans: true });
          } else if (isPaymentRequired) {
            openPaymentModal({ type: 'checkout' });
          }
        }
      }
    }
  }, [JSON.stringify(employerProfile)]);

  useEffect(() => {
    if (unTakenTrainings.length) {
      showNotification([
        {
          type: 'newTraining',
          notificationKey: 'trainingsNotification',
        },
      ]);
    }
  }, [JSON.stringify(unTakenTrainings)]);

  useEffect(() => {
    if (location?.pathname) trackPage([location.pathname]);
  }, [location]);

  const renderHeader = () => {
    if (userRole === 'employer') return <EmployerHeader />;
    if (userRole === 'employee') return <EmployeeHeader />;
    return null;
  };

  const fullWidthContainer =
    isEmployerPlansPage ||
    isEmployeeGrowPage ||
    isEmployerRetentionPage;
  const headerContainerCl = clsx(
    isEmployeeGrowPage && 'absolute employee-grow',
  );

  return (
    <AppLayout
      fullWidth={fullWidthContainer}
      headerElement={
        <StyledHeaderContainer className={headerContainerCl}>
          {renderHeader()}
          {showShortCompanyCard && <ShortCompanyCard />}
        </StyledHeaderContainer>
      }
    >
      {children}
    </AppLayout>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default PrivateLayout;
