import { Box } from 'components';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';

interface IStyledProps {
  fullHeight: boolean;
  fullWidth: boolean;
}

const StyledBox = styled(Box)<IStyledProps>(
  ({ theme, fullHeight, fullWidth }) => ({
    height: fullHeight ? '100%' : 450,
    width: fullWidth ? '100%' : 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.loaderWrapper': {
      width: fullWidth ? '100%' : 230,
      minHeight: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: fullWidth && !fullHeight ? 'row' : 'column',
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    '.loaderTitle': {
      marginTop: theme.spacing(2.5),
    },
    '.loaderSubTitle': {
      marginTop: theme.spacing(1.5),
    },
  }),
);

interface ILoadingProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
  title: string;
  subTitle?: string;
  onCancel?: () => void;
}

const Loading = ({
  fullWidth = false,
  fullHeight = false,
  title = 'Loading',
  subTitle,
  onCancel,
}: ILoadingProps) => {
  return (
    <StyledBox fullHeight={fullHeight} fullWidth={fullWidth}>
      <Paper className={'loaderWrapper'}>
        <CircularProgress />
        <Typography
          className={'loaderTitle'}
          variant={'h2'}
          color={'primary'}
        >
          {title}
        </Typography>
        <Typography
          className={'loaderSubTitle'}
          variant={'body1'}
        >
          {subTitle}
        </Typography>
      </Paper>
    </StyledBox>
  );
};

export default Loading;
