import { Box, styled } from 'components';
import {
  Button,
  Spinner,
  Step,
  StepNavigation,
} from 'components/shared';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_COMMUNICATION_TEMPLATES,
  SEND_REVIEW_TEXT,
} from 'api/queries/notifications';
import get from 'lodash/get';
import { useAlerts } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import ChooseEmployees from '../../../../../components/Retention/components/ChooseEmployees';
import { RetentionContext } from '../../../../../components/Retention/utils';
import sendNotificationFlowStyles from '../../../../../styles/Retention/Interactions/sendNotificationFlow';
import CenteredError from '../../../../../components/Error/CenteredError';
import { getRoutes } from '../../../../../utils';

const StyledBox = styled(Box)(({ theme }) => ({
  ...sendNotificationFlowStyles({ theme }),
  '& .inline-error-box': {
    marginTop: 10,
    padding: '1rem',
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: 7,
  },
  '& .inline-error-row': {
    paddingTop: 10,
    h4: {
      paddingTop: 0,
    },
  },
  '&.success-message-sent': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '50%',
    h2: {
      paddingBottom: 10,
    },
  },
}));

const Review = () => {
  const [rateeEmployee, setRateeEmployee] = useState([]);
  const [raterEmployeeIds, setRaterEmployeeIds] = useState([]);

  const ctx = useContext(RetentionContext);
  const location = useLocation();
  const navigate = useNavigate();
  const routes = getRoutes();
  const { setSimpleAlert } = useAlerts();

  const { data, loading: templatesLoading } = useQuery(
    GET_COMMUNICATION_TEMPLATES,
    {
      variables: {
        templateName: ctx.interactionNames.RETENTION_REVIEW,
      },
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  );

  const template = get(
    data,
    'getCommunicationTemplates[0]',
    null,
  );

  const [
    sendReviewText,
    {
      loading: sendReviewTextLoading,
      error: errorSendReviewText,
      data: sendReviewTextData = {},
    },
  ] = useLazyQuery(SEND_REVIEW_TEXT, {
    errorPolicy: 'all',
  });

  const handleSendReviewText = async () => {
    const simpleAlertBaseProps = {
      title: 'Not Enough info',
      isOpen: true,
      onCancel: () => setSimpleAlert({ isOpen: false }),
    };
    if (isEmpty(rateeEmployee)) {
      simpleAlertBaseProps.subtitle =
        'You need an employee to rate';
      setSimpleAlert(simpleAlertBaseProps);
    }
    if (isEmpty(raterEmployeeIds)) {
      simpleAlertBaseProps.subtitle =
        'You need employees to send the review to';
      setSimpleAlert(simpleAlertBaseProps);
    }
    await sendReviewText({
      variables: {
        employeeIdToReview: rateeEmployee[0].employeeId,
        recipientIds: raterEmployeeIds,
        locationId: location?.state?.locationId,
      },
    });
  };

  const { sendReviewText: responseData = null } =
    sendReviewTextData;

  const handleErrorResponse = () => {
    if (responseData && !responseData.success) {
      // call has failed for N users, display that infor and a CTA
      const errors = sendReviewTextData?.sendReviewText?.errors;
      return (
        <Box className="flow-message-template inline-error-box">
          <Typography variant={'h3'}>
            Error sending To the following Recipients
          </Typography>
          {errors.map((error) => (
            <Box className="inline-error-row">
              <Typography variant={'h4'}>
                Name: <span>{error.name}</span>
              </Typography>
              <Typography variant={'h4'}>
                Reason: <span>{error.reason}</span>
              </Typography>
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  };

  if (templatesLoading) {
    return <CenteredError RenderComponent={Spinner} />;
  }

  if (responseData && responseData.success) {
    return (
      <StyledBox className={'success-message-sent'}>
        <Typography variant={'h2'}>Message Sent</Typography>
        <Button
          variant="filled-primary"
          onClick={() =>
            navigate(
              `${routes.employer.retention_portal.location}/${ctx.retentionPages.pulse}`,
            )
          }
        >
          Done
        </Button>
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      <StepNavigation
        submitCallback={handleSendReviewText}
        submitLoading={sendReviewTextLoading}
      >
        <Step>
          <ChooseEmployees
            setEmployeeIds={setRateeEmployee}
            employeeIds={rateeEmployee}
            locationId={location?.state?.locationId}
            limit={1}
            returnAllData
            title={'Choose person to be reviewed'}
          />
        </Step>
        <Step>
          <ChooseEmployees
            setEmployeeIds={setRaterEmployeeIds}
            employeeIds={raterEmployeeIds}
            locationId={location?.state?.locationId}
            title="Choose people to review previous selection"
          />
        </Step>
        <Step>
          <Typography>Review</Typography>
          <Box className={'flow-message-template'}>
            <Typography variant={'h4'}>
              To: <span>{raterEmployeeIds.length} members</span>
            </Typography>
            {template && !isEmpty(rateeEmployee) && (
              <Box>
                <Typography variant={'h4'}>
                  Title:{' '}
                  <span>Rate {rateeEmployee[0].name}</span>
                </Typography>
                <Typography variant={'h4'}>
                  Message:{' '}
                  <span>
                    {template.template.replace(
                      '[employeeProfileReviewName]',
                      rateeEmployee[0].name,
                    )}
                  </span>
                </Typography>
              </Box>
            )}
            {errorSendReviewText && (
              <Box className="inline-error-box">
                <Typography variant={'h4'}>
                  There was an error sending the review message.
                </Typography>
                <Typography variant={'h4'}>
                  If this problem persists, please contact
                  support in the bottom right.
                </Typography>
              </Box>
            )}
            {sendReviewTextData && handleErrorResponse()}
          </Box>
        </Step>
      </StepNavigation>
    </StyledBox>
  );
};

export default Review;
