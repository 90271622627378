import React from 'react';
import { SvgIcon } from 'components';

const NewColumn = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props} enableBackground="new 0 0 24 24">
      <path
        d="M0.2,10.1h3.4V22H0.2V10.1z M13.7,15.2h3.4V22h-3.4V15.2z M6.9,5h3.4v17H6.9V5z M20.3,5.3V1.6h-2.5v3.7h-3.7v2.5h3.7v3.7
	h2.5V7.8H24V5.3H20.3z"
      />
    </SvgIcon>
  )

export default NewColumn;
