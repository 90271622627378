import React from 'react';
import PT from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { styled } from 'components';
import TeamChartColumn from './ChartColumn';

const StyledColumn = styled('div')(({ theme }) => ({
  '&.chartColumns__column': {
    width: 240,
    minWidth: 240,
    // height: 320,
    padding: '0 5px',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper
  }
}));

const TeamChartColumns = ({ className, columns, chartId }) =>
  columns.map((column, idx) => (
    <Droppable
      key={`${chartId}_${columns[idx].id}`}
      droppableId={JSON.stringify({ index: column.index, id: column.id })}
    >
      {(provided) => (
        <StyledColumn
          ref={provided.innerRef}
          className={clsx('chartColumns__column', className)}
          {...provided.droppableProps}
        >
          <TeamChartColumn {...column} />
        </StyledColumn>
      )}
    </Droppable>
  ));

TeamChartColumns.propTypes = {
  className: PT.string.isRequired,
  columns: PT.arrayOf(PT.any)
};

export default TeamChartColumns;
