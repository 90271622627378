export default ({ theme }) => ({
  '&.container': {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .toolbarContainer': {
    width: '100%',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none',
    },
  },
  '& .scrollable': {
    overflow: 'auto',
  },
  '& .hr': {
    height: 1,
    marginRight: 30,
    marginLeft: 25,
    backgroundColor: theme.palette.grey['300'],
    [theme.breakpoints.down('desktopApp')]: {
      marginRight: 16,
      marginLeft: 16,
    },
  },
  '& .dropList': {
    marginBottom: 32,
  },
  '&.boardContainer': {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  '& .boardTitleBar': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 16,
    marginRight: 30,
    [theme.breakpoints.down('desktopApp')]: {
      marginRight: 10,
    },
  },
  '& ._BPaddingReducer': {
    paddingBottom: 8,
  },
  '& ._TopPaddingCollapse': {
    paddingTop: 0,
  },
  '& .applicantShowToggle': {
    display: 'flex',
    padding: 0,
    border: 'none',
    background: 'none',
    '& svg': {
      height: 24,
      width: 24,
    },
  },
  '& .boardTitle': {
    paddingLeft: 25,
    paddingRight: 8,
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: '17px',
    letterSpacing: 0,
    color: theme.palette.grey['300'],
    '& ._withIcon': {
      display: 'flex',
    },
    [theme.breakpoints.down('desktopApp')]: {
      paddingRight: 16,
      // display: 'none',
      fontSize: 16,
      lineHeight: '18px',
    },
  },
  '& .boardLoader': {
    width: '100%',
    height: 72,
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .searchContainer': {
    marginBottom: 28,
    paddingLeft: 25,
    paddingRight: 30,
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 382,
      width: '100%',
      padding: '0 16px',
      margin: '0 auto 12px',
    },
  },
  '& .boardListContainer': {
    paddingLeft: 25,
    paddingRight: 30,
    paddingBottom: 30,
    overflow: 'auto',
    flex: 1,
    '&.boardListContainer_grid': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: 30,
    },
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 16px 20px',
    },
  },
  '& .emptyApplicants__title': {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '26px',
    letterSpacing: 1,
  },
});
