import React from 'react';
import ManageOrganizationPaths from 'components/Retention/components/Location/Path/ManageOrganizationPaths';
import EditPathGroup from 'components/Retention/components/Location/Path/EditPathGroup';
import { useLocation } from 'react-router-dom';

const LocationPathManager = () => {
  const location = useLocation();
  const { state } = location;
  return state == null ? <ManageOrganizationPaths /> : <EditPathGroup activePath={state} />
};

export default LocationPathManager;
