import React, { memo } from 'react';
import { styled } from 'components';
import clsx from 'clsx';
import parseHTML from 'html-react-parser';

const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) =>
      `<a target="_blank" noopener noreferrer href="${url}">${url}</a>`,
  );
};

const StyledRoot = styled('div')(({ theme }) => ({
  '&.chatMessage__container': {
    display: 'flex',
    alignItems: 'flex-start',
  },
  '& .chatMessage__txtMessage': {
    maxWidth: 258,
    padding: '11px 9px 11px 13px',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0,
    color: theme.palette.common.white,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.light,
    wordBreak: 'break-word',
  },
  '& .chatMessage__senderImg': {
    width: 32,
    height: 32,
    marginRight: 16,
    marginTop: 6,
    objectFit: 'cover',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
  },
}));

interface IChatMessage {
  className: string;
  senderImageUrl: string;
  textMessage: string;
  openProfile: () => void;
}

const ChatMessage: React.FC<IChatMessage> = ({
  className = '',
  textMessage = '',
  senderImageUrl = '',
  openProfile,
}) => {
  const htmlText = textMessage ? urlify(textMessage) : '';
  const parsedHtml = htmlText ? parseHTML(htmlText) : '';
  return (
    <StyledRoot
      className={clsx('chatMessage__container', className)}
    >
      {senderImageUrl && (
        <div onClick={openProfile}>
          <img
            src={senderImageUrl}
            alt=""
            className="chatMessage__senderImg"
          />
        </div>
      )}
      <div className="chatMessage__txtMessage">{parsedHtml}</div>
    </StyledRoot>
  );
};

export default memo(ChatMessage);
