import {
  backgroundBorderMixin,
  baseGap,
  dashboardImageWidth,
  retentionNavContainer,
} from '../helpers';

const baseIndex = 7;
export const entryStyles = ({ theme }) => ({
  ...retentionNavContainer({ theme }),
});

export const dashboardStyles = ({ theme }) => ({
  [theme.breakpoints.down('desktopApp')]: {
    padding: theme.spacing(2),
  },
  '& .dashboard-title': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .dashboard-actions': {
    svg: {
      fill: theme.palette.primary.main,
      marginLeft: '15px',
    },
  },
  '& .dashboard-cards': {
    display: 'grid',
    gridTemplateColumns: Array(3)
      .fill(`calc(33.33% - ${baseGap}px)`)
      .join(' '),
    columnGap: baseGap,
    rowGap: baseGap,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  },
  '& .dashboard-card-container': {
    ...backgroundBorderMixin({ theme }),
    padding: `calc(${baseGap}px / 2)`,
    cursor: 'pointer',
  },
  '& .locationTitleContainer': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .location-title': {
    marginLeft: 15,
  },
  '& .card-chart-container': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  '& .card-chart-item': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: 'calc(100% / 3)',
    '& p': {
      textAlign: 'center',
    },
    '& img': {
      maxWidth: 50,
      marginBottom: 10,
    },
  },
  '& .team-member-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .team-members': {
    display: 'flex',
    maxWidth: '75%',
    '& img': {
      borderRadius: '50%',
      height: dashboardImageWidth,
      width: dashboardImageWidth,
      border: `2px solid ${theme.palette.background.paper}`,
      '&:not(:first-of-type)': {
        marginLeft: (dashboardImageWidth / 2) * -1,
      },
      // sass for loop conversion to reverse the index of elements by child count
      ...(() =>
        Array(7)
          .fill('')
          .reduce(
            (acc, cur, idx) => ({
              ...acc,
              [`&:nth-of-type(${idx + 1})`]: {
                zIndex: `${baseIndex - idx}px`,
              },
            }),
            {},
          ))(),
    },
  },
  '& .team-count': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    height: dashboardImageWidth,
    width: dashboardImageWidth,
    WebkitBorderRadius: '50%',
    MozBorderRadius: '50%',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // pulse styles
  '& .pulse-card-graph': {},
  '& .pulse-card-stats': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    '& > div': {
      flexBasis: '40%',
    },
  },
  '& .pulse-card-chart': {},
});
