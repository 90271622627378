import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isEmpty, head } from 'lodash';
import { emojiRates, qaAttr } from 'utils';
import { Box, Dialog, MuiButtonBase, styled } from 'components';
import { Button, CheckBox, RadioGroup, Select, Spinner } from 'components/shared';
import styles from 'styles/dialogs/CloseJobDialog';
import {
  CLOSE_JOB_WITH_FEEDBACK,
  CLOSE_REASONS,
  CLOSE_JOB_TO_JOB_BY_JOB_ID,
  GET_ALL_APPLICANTS,
  HIRING_PLATFORMS
} from 'api';

const StyledRoot = styled(Dialog)(styles);

const CloseJobDialog = ({ isOpen, onClose, onSuccess, jobId, jobTitle, loading }) => {
  const [platformId, setPlatformId] = useState(null);
  const [reasonId, setReasonId] = useState(null);
  const [hiringPlatformReasonId, setHiringPlatformReasonId] = useState(null);
  const [hiredOnQH, setHiredOnQH] = useState(false);
  const [chosenApplicant, setChosenApplicant] = useState('');
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');

  const [postCloseFeedback, { loading: postCloseFeedbackLoading }] =
    useMutation(CLOSE_JOB_WITH_FEEDBACK);

  const [loadReasons, { loading: loadingReasons, data: { closeReasons: reasons = [] } = {} }] =
    useLazyQuery(CLOSE_REASONS, {
      onCompleted: (data) => {
        const reasonTrigger = data.closeReasons.filter(
          (item) => item.name === 'Hired Someone for the Job'
        )[0];
        setHiringPlatformReasonId(reasonTrigger.id);
      }
    });

  const [loadPlatforms, { data: { hiringPlatforms: platforms = [] } = {} }] =
    useLazyQuery(HIRING_PLATFORMS);

  const [
    loadCloseJobToJobByJobId,
    { data: { closeJobToJobByJobId: savedCloseReasonsByJob = {} } = {} }
  ] = useLazyQuery(CLOSE_JOB_TO_JOB_BY_JOB_ID, {
    fetchPolicy: 'cache-and-network',
    variables: { jobId }
  });

  const [loadApplicants, { loading: loadingApplicants, data: { allApplicants = [] } = {} }] =
    useLazyQuery(GET_ALL_APPLICANTS, {
      variables: { jobId },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });

  const fetchDefaultData = async () => {
    loadReasons();
    loadPlatforms();
  };

  useEffect(() => {
    if (hiredOnQH && isEmpty(allApplicants)) {
      loadApplicants();
    }
  }, [hiredOnQH]);

  useEffect(() => {
    if (isOpen) {
      fetchDefaultData();
      loadCloseJobToJobByJobId();
    }
  }, [isOpen]);

  useEffect(() => {
    if (savedCloseReasonsByJob?.closeJob && !isEmpty(savedCloseReasonsByJob.closeJob)) {
      const { closeJob } = savedCloseReasonsByJob;

      (async () => {
        const {
          hiringPlatformId = null,
          closeReasonId = null,
          hiredEmployeeProfileId = null,
          rating: savedRating,
          feedback: savedFeedback
        } = closeJob || {};

        if (hiringPlatformId !== 1) setPlatformId(hiringPlatformId);

        setReasonId(closeReasonId ?? Number(closeReasonId));
        setHiredOnQH(hiringPlatformId === 1);

        if (hiredEmployeeProfileId) {
          setChosenApplicant(hiredEmployeeProfileId);
          await loadApplicants();
        }

        setRating(savedRating);
        setFeedback(savedFeedback);
      })();
    }
  }, [JSON.stringify(savedCloseReasonsByJob)]);

  const closeJob = async () => {
    const variables = {
      jobId,
      closeReasonId: Number(reasonId) || null,
      hiringPlatformId: hiredOnQH
        ? head(platforms.filter((platform) => platform.name === 'WorkTorch'))?.id
        : Number(platformId) || null,
      rating,
      feedback,
      hiredEmployeeProfileId: Number(chosenApplicant) || null
    };

    await postCloseFeedback({ variables });
    onSuccess();
  };

  const isCloseJobDisabled = () => {
    if (!reasonId) return true;
    // if user selects "Hired Someone for the Job"
    // && selects a platform || selects hired on WT
    if (hiringPlatformReasonId === reasonId) {
      if (hiredOnQH) return false;
      return !platformId;
    }
    return false;
  };

  return (
    <StyledRoot
      open={isOpen}
      fullWidth
      scroll="paper"
      onClose={onClose}
      classes={{ container: 'container', paper: 'paper' }}
    >
      <Box className="closeJobContainer">
        <h1 className="header" {...qaAttr('close-job-modal-title')}>
          Help Us Help You
        </h1>
        <p className="message">
          {savedCloseReasonsByJob ? 'Is this still accurate for the ' : 'Why are you closing the '}
          <span className="jobTitle">{jobTitle}</span> job?
        </p>
        {loadingReasons ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <Box paddingBottom={2}>
            <RadioGroup
              value={reasonId}
              onChange={(e) => setReasonId(Number(e.target.value))}
              data={reasons.map(({ name, id }) => ({ label: name, value: id }))}
              analyticParams={{
                key: 'Close reason focused (Close job)',
                trigger: 'focus'
              }}
              RadioProps={{ testID: `close-job-modal-reason-radio-${reasonId}` }}
            />
          </Box>
        )}
        {hiringPlatformReasonId === reasonId && (
          <>
            <Box paddingBottom={2} position="relative">
              <CheckBox
                checked={hiredOnQH}
                label={<span>Hired on WorkTorch</span>}
                onChange={() => {
                  setHiredOnQH(!hiredOnQH);
                  setPlatformId(null);
                }}
                className="checkbox"
                analyticParams={{
                  key: 'Hired on WT focused (Close job)',
                  trigger: 'focus'
                }}
                testID="close-job-modal-wt-platform"
              />
            </Box>
            {!hiredOnQH ? (
              <Box paddingBottom={2}>
                <Select
                  value={platformId || ''}
                  native
                  ariaLabel="Other reasons"
                  inputVariant="outlined"
                  emptyOption={
                    <Box component="option" disabled value="" color="rgba(255,255,255, 0.5)">
                      Other
                    </Box>
                  }
                  options={platforms.map(({ name, id }) => ({
                    value: id,
                    label: name,
                    ...qaAttr(`close-job-modal-hired-on-wt-${name}-option`)
                  }))}
                  onChange={(e) => setPlatformId(Number(e.target.value))}
                  analyticParams={{
                    key: 'Other reasons focused (Close job)',
                    trigger: 'focus'
                  }}
                  testID="close-job-modal-hired-on-wt-select"
                />
              </Box>
            ) : (
              <>
                {loadingApplicants ? (
                  <div>
                    <Spinner />
                  </div>
                ) : (
                  <Box paddingBottom={2}>
                    <Select
                      value={chosenApplicant}
                      native
                      inputVariant="outlined"
                      ariaLabel="Who Did you Hire?"
                      onChange={(e) => setChosenApplicant(e.target.value)}
                      analyticParams={{
                        key: 'Who hired focused (Close job)',
                        trigger: 'focus'
                      }}
                      testID="close-job-modal-who-hired-select"
                    >
                      <Box component="option" disabled value="" color="rgba(255,255,255, 0.5)">
                        Who Did You Hire?
                      </Box>
                      {allApplicants.map((applicant) => {
                        if (!applicant?.users?.employeeProfile) return null;
                        const name = applicant.users.employeeProfile?.name;
                        return (
                          <option
                            key={applicant.users.employeeProfile?.profile_id}
                            value={applicant.users.employeeProfile?.profile_id}
                            {...qaAttr(`close-job-modal-who-hired-${name}-option`)}
                          >
                            {name}
                          </option>
                        );
                      })}
                    </Select>
                  </Box>
                )}
                <Box paddingBottom={2} paddingTop={2}>
                  <Box value="" color="rgba(255,255,255, 0.5)">
                    Rate your experience
                  </Box>
                  <div className="ratingContainer">
                    {emojiRates.map((rate, idx) => (
                      <MuiButtonBase
                        key={rate}
                        className="rating"
                        style={{
                          filter:
                            rating === idx + 1
                              ? 'drop-shadow(0 3px 10px rgba(71, 67, 162, .5))'
                              : ''
                        }}
                        onClick={() => setRating(idx + 1)}
                        {...qaAttr(`close-job-modal-emoji-${rate}`)}
                      >
                        <span
                          style={{ fontSize: rating === idx + 1 ? 50 : 40, lineHeight: '50px' }}
                        >
                          {rate}
                        </span>
                      </MuiButtonBase>
                    ))}
                  </div>
                </Box>
              </>
            )}
          </>
        )}
        {((hiringPlatformReasonId !== reasonId && !!reasonId) || !!platformId || !!hiredOnQH) && (
          <Box width="100%" paddingBottom={2}>
            <Box paddingBottom={1.5}>{hiredOnQH ? 'Feedback' : 'How can we improve?'}</Box>
            <textarea
              className="comments"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              name=""
              id=""
              cols="30"
              rows="10"
              {...qaAttr('close-job-modal-textarea')}
            />
          </Box>
        )}
        <Box paddingTop={2} className="button">
          <Button
            disabled={isCloseJobDisabled() || postCloseFeedbackLoading || loading}
            variant="filled-primary"
            sx={{ height: 50, width: '100%' }}
            onClick={closeJob}
            testID="close-job-modal-submit-button"
          >
            {postCloseFeedbackLoading || loading ? <Spinner /> : 'Close Job'}
          </Button>
        </Box>
      </Box>
    </StyledRoot>
  );
}

CloseJobDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  jobId: PropTypes.number.isRequired,
  jobTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

CloseJobDialog.defaultProps = {
  loading: false
};

export default CloseJobDialog;
