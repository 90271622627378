import { Box } from 'components';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getRoutes, validateUUID } from '../../../../utils';
import get from 'lodash/get';
import ResourceForm from '../../../../components/employer/onboarding/ResourceForm';
import { useEffect, useState } from 'react';
import useEmployerOnboarding from '../../../../hooks/useEmployerOnboarding';
import { Spinner } from '../../../../components/shared';
import { useAlerts } from '../../../../hooks';
import { FieldValues } from 'react-hook-form';
import { IOnboardingResource } from '../../../../api/types/queries/onboarding.types';

const OnboardingResourceEdit = () => {
  const [onboardingResource, setOnboardingResource] =
    useState<IOnboardingResource | null>(null);
  const navigate = useNavigate();
  const routes = getRoutes();
  const location = useLocation();
  const { id: resourceId = null } = useParams();
  const onboardingId = get(location, 'state.onboardingId', null);
  const { showToast } = useAlerts();

  const {
    resources: { update: updateResource, oneById: getResource },
  } = useEmployerOnboarding();

  useEffect(() => {
    // TODO: not the best way to do this but on a time crunch. Sorry future me (uuid check from url).
    // resource UUID is not on the url, redirect to the root of onboarding
    if (!validateUUID(resourceId)) {
      navigate(routes.employer.onboarding.root);
    } else {
      getResource
        .get({
          variables: {
            id: resourceId,
          },
        })
        .then((data) =>
          setOnboardingResource(data.data.onboardingResource),
        )
        .catch((error) => {
          showToast({
            title: 'There was an error getting the resource.',
            isOpen: true,
          });
          console.error({
            error,
            message: 'Error fetching onboardingResource',
          });
        });
    }
  }, []);

  const handleSave = async (formValues: FieldValues) => {
    if (formValues.id) {
      updateResource
        .save({
          variables: formValues,
        })
        .then((data) => {
          showToast({
            title: 'Resource Updated',
            isOpen: true,
          });
          setOnboardingResource(
            data.data.updateOnboardingResource,
          );
        })
        .catch((error) => {
          console.error({ error, key: 'saveResourceError' });
          showToast({
            title: 'There was an error saving your resource',
            isOpen: true,
          });
        });
    }
  };

  const handleCancel = () => {
    const routesWithId = getRoutes({ id: onboardingId });
    navigate(routesWithId.employer.onboarding.edit);
  };

  const handleDelete = async () => {};

  if (getResource.loading) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }

  return onboardingResource ? (
    <Box>
      <ResourceForm
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={handleDelete}
        onboardingResource={onboardingResource}
      />
    </Box>
  ) : null;
};

export default OnboardingResourceEdit;
