import { useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { find, filter } from 'lodash';
import { utcToZonedTime } from 'date-fns-tz';
import { getLocaleTimeZone } from 'utils';
import { formatEmployerSchedule } from 'utils/schedules/schedules';
import { GET_EMPLOYER_INTERVIEW_SCHEDULE, GET_INTERVIEW_SCHEDULE_FOR_JOB } from 'api';

const useJobSchedule = () => {

  const [schedules, setSchedules] = useState([]);
  const [interviewDate, setInterviewDate] = useState(null);
  const [inactiveDates, setInactiveDates] = useState([]);
  const [employerTimeZone, setEmployerTimeZone] = useState('');

  const [fetchJobSchedule, { loading: jobScheduleLoading = true }] = useLazyQuery(
    GET_EMPLOYER_INTERVIEW_SCHEDULE,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );

  const [fetchJobInterviews, { loading: jobInterviewsLoading = true }] = useLazyQuery(
    GET_INTERVIEW_SCHEDULE_FOR_JOB,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );

  const getJobSchedule = useCallback(
    async (employerProfileId) => {
      try {
        if (employerProfileId) {
          const numEmployerPID = Number(employerProfileId);
          const { data } = await fetchJobSchedule({
            variables: { employerProfileId: numEmployerPID }
          });
          const initSchedules = data?.getScheduleTimesEmployer;

          if (initSchedules) {
            const employerTZ = find(initSchedules, 'timeZone')?.timeZone;
            const formattedInitSchedules = initSchedules.map((o) =>
              formatEmployerSchedule(o, employerTZ)
            );
            setSchedules(formattedInitSchedules);
            setEmployerTimeZone(employerTZ || '');
          }
        } else {
          throw new Error('Missed required parameter.');
        }
      } catch (error) {
        console.error(error);
      }
    },
    [fetchJobSchedule]
  );

  const getJobInterviews = useCallback(
    async (jobId, employeeProfileId, employeeTimeZone = '') => {
      try {
        if (!employeeProfileId) {
          throw new Error('Missed `employeeProfileId` parameter in `getJobInterviews`');
        }
        if (!jobId) {
          throw new Error('Missed `jobId` parameter in `getJobInterviews`');
        }
        const employeeTZ = employeeTimeZone || getLocaleTimeZone();
        const numEmployeePID = Number(employeeProfileId);
        const { data } = await fetchJobInterviews({
          variables: { employeeProfileId: numEmployeePID, jobId: Number(jobId) }
        });
        const interviews = data?.getScheduleTimesForJob;

        if (interviews) {
          const employeeDates = filter(interviews, {
            employeeProfileId: numEmployeePID,
            jobId
          });
          const disabledDates = filter(
            interviews,
            (o) =>
              // scheduleCount === 'true' if date was booked more times then "count" allows
              (o.jobId === jobId &&
                o.employeeProfileId !== numEmployeePID &&
                o.scheduleCount === 'true') ||
              (o.jobId !== jobId && o.scheduleCount === 'true')
          );
          const formattedDisabledDates = disabledDates.map((o) =>
            utcToZonedTime(o.interviewDate, employeeTZ)
          );

          if (employeeDates?.[0]?.interviewDate) {
            const { interviewDate: fetchedInterviewDate } = employeeDates[0];
            const date = utcToZonedTime(fetchedInterviewDate, employeeTZ);
            formattedDisabledDates.push(date);
            setInterviewDate(date);
          }

          setInactiveDates(formattedDisabledDates);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [fetchJobInterviews]
  );

  return {
    employerTimeZone,
    getJobInterviews,
    getJobSchedule,
    inactiveDates,
    interviewDate,
    interviewsLoading: jobInterviewsLoading,
    scheduleLoading: jobScheduleLoading,
    schedules,
    setInterviewDate,
    setInactiveDates
  };
};

export default useJobSchedule;
