import { flexRow } from '../../mixins';
import { lighten } from '@mui/material';

const styles = ({ theme }) => ({
  width: '100%',
  '& .stat-location-row': {
    ...flexRow,
    padding: '20px 0 10px',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    [theme.breakpoints.down('desktopApp')]: {
      flexWrap: 'wrap',
      '& > div': {
        width: '100%',
        flexGrow: 1,
      },
    },
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  '& .location-stat-container': {
    ...flexRow,
    flexBasis: '50%',
    [theme.breakpoints.down('desktopApp')]: {
      flexGrow: 1,
      flexWrap: 'wrap',
    },
    '& > div': {
      flexBasis: `${(100 / 3).toFixed(2)}%`,
      ...flexRow,
      flexDirection: 'column',
      [theme.breakpoints.down('desktopApp')]: {
        flexGrow: 1,
        flexDirection: 'row',
        flexBasis: '100%',
      },
    },
  },
  '& .stat-location-company': {
    ...flexRow,
    [theme.breakpoints.down('desktopApp')]: {
      background: lighten(theme.palette.background.default, 0.1),
      padding: theme.spacing(1),
      borderRadius: 7,
      marginBottom: theme.spacing(1),
    },
    '> div:last-of-type': {
      marginLeft: 20,
      [theme.breakpoints.down('desktopApp')]: {
        flexGrow: 1,
        p: {
          fontSize: 14,
        },
      },
    },
  },
  '& .location-stat-value span': {
    fontSize: 20,
    fontWeight: 'bold',
  },
  '& .location-stat-title-row': {
    ...flexRow,
  },
  '& .location-stat-icon': {
    fill: theme.palette.primary.light,
    marginRight: 10,
  },
  '& .status-good': {
    color: theme.palette.success.main,
  },
  '& .status-bad': {
    color: theme.palette.anomaly.main,
  },
  '& .location-pagination': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
});

export default styles;
