import React from 'react';
import { Person, CorporateFare } from '@mui/icons-material';
import { Box, styled } from '../../material-ui';
import { flexCenter } from '../../../styles/mixins';

const StyledBox = styled(Box)(
  ({
    theme,
    flourishWidth,
    flourishHeight,
    height,
    width,
    bdrds,
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height,
    width,
    position: 'relative',
    overflow: 'visible',
    '& .avatar-image-container': {
      height,
      width,
      borderRadius: bdrds,
      position: 'relative',
      overflow: 'hidden',
    },
    '& .avatar-default': {
      background: theme.palette.common.white,
      height: '100%',
      width: '100%',
      position: 'relative',
    },
    '& img': {
      width: '100%',
      height: 'auto',
    },
    '& svg': {
      fill: theme.palette.primary.light,
      position: 'absolute',
      left: '50%',
      top: '60%',
      transform: 'translate(-50%, -50%)',
    },
    '& .employee-default-avatar': {
      width: '125%',
      height: '125%',
    },
    '& .company-default-avatar': {
      width: '75%',
      height: '75%',
      top: '50%',
    },
    '& .avatar-flourish': {
      position: 'absolute',
      height: flourishHeight,
      width: flourishWidth,
      borderRadius: 225,
      ...flexCenter,
      '&.TOP': {
        left: '50%',
        top: (flourishHeight / 2) * -1,
        transform: 'translateX(-50%)',
      },
      '&.TOP_RIGHT': {
        left: `calc(100% - (${flourishHeight}px / 2))`,
        top: (flourishHeight / 2) * -1,
      },
      '&.RIGHT': {
        left: `calc(100% - (${flourishHeight}px / 2))`,
        top: `calc(50% - (${flourishHeight}px / 2))`,
      },
      '&.BOTTOM_RIGHT': {
        left: `calc(100% - (${flourishHeight}px / 2))`,
        top: `calc(100% - (${flourishHeight}px / 2))`,
      },
      '&.BOTTOM': {
        left: '50%',
        bottom: (flourishHeight / 2) * -1,
        transform: 'translateX(-50%)',
      },
      '&.BOTTOM_LEFT': {
        left: (flourishHeight / 2) * -1,
        top: `calc(100% - (${flourishHeight}px / 2))`,
      },
      '&.LEFT': {
        left: `calc(0% - (${flourishHeight}px / 2))`,
        top: `calc(50% - (${flourishHeight}px / 2))`,
      },
      '&.TOP_LEFT': {
        left: (flourishHeight / 2) * -1,
        top: (flourishHeight / 2) * -1,
      },
    },
  }),
);

const avatarType = {
  COMPANY: 'COMPANY',
  EMPLOYEE: 'EMPLOYEE',
};
const Avatar = ({
  height = 50,
  width = 50,
  borderRadius,
  url,
  alt = '',
  type,
  classes = {},
  flourishLocation,
  flourishRenderer,
}: {
  url?: string;
  height?: number;
  width?: number;
  alt?: string;
  borderRadius?: number;
  type: 'COMPANY' | 'EMPLOYEE';
  classes?: {
    containerOverride?: string;
    imgOverride?: string;
  };
  flourishLocation?:
    | 'TOP'
    | 'TOP_RIGHT'
    | 'RIGHT'
    | 'BOTTOM_RIGHT'
    | 'BOTTOM'
    | 'BOTTOM_LEFT'
    | 'LEFT'
    | 'TOP_LEFT';
  flourishRenderer?: JSX.Element;
}) => {
  const bdrds = borderRadius || width;

  return (
    <StyledBox
      className={classes.containerOverride}
      height={height}
      width={width}
      flourishWidth={width * 0.25}
      flourishHeight={height * 0.25}
      bdrds={bdrds}
    >
      <Box className={'avatar-image-container'}>
        {url ? (
          <img
            className={classes.imgOverride}
            src={url}
            alt={alt}
          />
        ) : (
          <Box
            className={`avatar-default ${classes.imgOverride}`}
          >
            {type === avatarType.EMPLOYEE ? (
              <Person className={'employee-default-avatar'} />
            ) : (
              <CorporateFare
                className={'company-default-avatar'}
              />
            )}
          </Box>
        )}
      </Box>
      {flourishRenderer ? (
        <Box className={`avatar-flourish ${flourishLocation}`}>
          {flourishRenderer}
        </Box>
      ) : null}
    </StyledBox>
  );
};

export default Avatar;
