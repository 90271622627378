import { useLazyQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import get from 'lodash/get';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_LINK_BY_CODE } from '../api/queries/shortlink';
import { Box } from '../components';
import { PublicHeader } from '../components/Header';
import { Spinner } from '../components/shared';
import { getRoutes } from '../utils';

const ShortLinkRouter = () => {
  const { codeId } = useParams();
  const navigate = useNavigate();
  const routes = getRoutes();

  const [getLinkByCode, { data, loading }] = useLazyQuery(
    GET_LINK_BY_CODE,
    {
      variables: { code: codeId },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (codeId) {
      getLinkByCode({
        variables: {
          code: codeId,
        },
      });
    }
  }, []);

  if (!loading) {
    const link = get(
      data,
      'getLinkByCode.link',
      routes.login.default,
    );

    navigate(link);
  }

  return [
    <PublicHeader />,
    <Box
      sx={{
        width: '100%',
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          marginBottom: 1.5,
        }}
      >
        We're working on it, please hang tight!
      </Typography>
      <Spinner />
    </Box>,
  ];
};

export default ShortLinkRouter;
