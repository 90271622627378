import React, { useState } from 'react';
import clsx from 'clsx';
import { styled } from 'components';
import { Button } from 'components/shared';
import { IndustryModal } from 'components/dialogs';
import { MdChevronRight } from 'components/icons';
import Chip from '@mui/material/Chip';

const PREFIX = 'jobIndustries';
const classes = {
  container: `${PREFIX}-container`,
  chips: `${PREFIX}-chips`,
  updateButton: `${PREFIX}-updateButton`,
};
const StyledRoot = styled('div')(() => ({
  [`&.${classes.container}`]: {},
  [`& .${classes.chips}`]: {
    marginTop: -13,
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`& .${classes.updateButton}`]: {
    marginTop: 10,
    fontSize: 12,
  },
}));

interface IEditableJobIndustries {
  className: string;
  isEditMode: boolean;
  form: {
    industry: { name: string; industry_id: number }[];
  };
  onFormChange: () => void;
}

const EditableJobIndustries = ({
  className = '',
  isEditMode,
  form,
  onFormChange = () => null,
}: IEditableJobIndustries) => {
  const [isIndustryModalOpen, setIsIndustryModalOpen] =
    useState(false);

  return (
    <StyledRoot className={clsx(className, classes.container)}>
      <div className={classes.chips}>
        {form.industry.map(({ name, industry_id: indId }) => (
          <Chip
            key={`industry__${indId}`}
            label={name}
            color={'primary'}
            style={{ marginRight: 13 }}
          />
        ))}
      </div>
      <Button
        variant="text"
        disabled={!isEditMode}
        endIcon={<MdChevronRight color="primary" />}
        className={classes.updateButton}
        onClick={() => setIsIndustryModalOpen(true)}
        testID="industry-modal-trigger-button"
      >
        Update Industries
      </Button>
      <IndustryModal
        isOpen={isIndustryModalOpen}
        formAttrs={form}
        onChange={onFormChange}
        onClose={() => setIsIndustryModalOpen(false)}
      />
    </StyledRoot>
  );
};

export default EditableJobIndustries;
