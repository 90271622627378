import { Avatar } from '../../shared';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { IOnboardingAssignment } from '../../../api/types/queries/onboarding.types';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const AssignmentCard = ({
  employeeId,
  status,
  employeeProfile,
}: IOnboardingAssignment) => (
  <StyledPaper variant={'outlined'}>
    <Avatar type={'EMPLOYEE'} url={employeeProfile.imageUrl} />
    <Typography variant={'h3'}>{employeeId}</Typography>
    <Chip variant={'outlined'} label={status} />
  </StyledPaper>
);

export default AssignmentCard;
