import { addAlpha } from '../utils';

export default ({ theme }) => ({
  '&.backdrop__root': {
    zIndex: 2000,
    // '-webkit-filter': 'blur(3px)',
    // filter: 'blur(3px)',
    alignItems: 'normal',
    justifyContent: 'normal',
    backdropFilter: 'blur(2px)',
    backgroundColor: addAlpha(
      theme.palette.background.paper,
      0.25,
    ),
  },
  '& .contentContainer': {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  '& .cardContainer': {
    maxWidth: 300,
    width: '100%',
    position: 'relative',
  },
  '& .text': {
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0.3,
    color: theme.palette.common.white,
    marginBottom: 24,
    textShadow: `0 0 14px 0 ${addAlpha(
      theme.palette.common.white,
      0.5,
    )}`,
  },
  '& .largeText': {
    fontSize: 24,
  },
  '& .tipsContainer': {
    width: '100%',
    padding: '0 20px',
    position: 'absolute',
    bottom: -50,
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .tip': {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    letterSpacing: 0,
    color: theme.palette.common.white,
    textShadow: '0 0 14px 0 rgba(255,255,255, 0.5)',
  },
  '& .pointerIcon': {
    position: 'absolute',
    right: -8,
    bottom: -12,
  },
});
