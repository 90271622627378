import { flexRow } from '../../mixins';

export const employeeCardStyles = ({ theme, verified }) => ({
  '& .parentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 12px 24px 12px',
    border: `1px solid ${
      verified
        ? theme.palette.secondary.main
        : theme.palette.quaternary.main
    }`,
    borderRadius: 8,
    height: '100%',
    position: 'relative',
  },
  '.notVerified': {
    position: 'absolute',
    top: 5,
    left: 5,
    fontSize: 10,
    color: theme.palette.quaternary.main,
  },
  '& .moreIconWrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '& .memberImage': {
    height: 48,
    width: 48,
    borderRadius: 248,
    marginBottom: 12,
  },
  '& .memberName': {
    fontSize: 16,
    fontWeight: 700,
  },
  '& .memberTitle': {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  '& .paginationBar': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  '& .avatar-containerOverride': {
    marginBottom: 25,
  },
  // start enhanced
  '& .enhanced-container': {
    marginTop: 10,
    textAlign: 'center',
    '& .enhanced-path': {
      fontWeight: 800,
      ...flexRow,
      justifyContent: 'center',
      '& svg': {
        fill: theme.palette.primary.light,
      },
      '& p': {
        '&:first-of-type': {
          marginRight: 10,
          color: theme.palette.primary.light,
        },
        '&:last-of-type': {
          color: theme.palette.text.primary,
        },
      },
    },
    '& .enhanced-survey': {
      ...flexRow,
      justifyContent: 'center',
      marginTop: 5,
      '& p': {
        color: theme.palette.text.primary,
        fontWeight: 800,
        '&:last-of-type': {
          marginLeft: 10,
          color: theme.palette.text.secondary,
        },
      },
    },
    '& .enhanced-badges': {
      marginTop: 5,
      '& p': {
        color: theme.palette.quaternary.main,
        fontWeight: 800,
      },
    },
  },
});
