import React from 'react';
import PT from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
import { parseJwt } from '../../utils';

const GoogleAuthButton = ({ onSuccess, onFailure }) => {
  const handleSuccess = ({ credential }) => {
    const data = parseJwt(credential) || {};
    const { email } = data;

    if (!email) {
      throw new Error('User email does not exist at Google');
    }
    onSuccess({ googleToken: credential, email });
  };

  const handleFailure = () => {
    if (onFailure) onFailure();
  };

  return (
    <div style={{ marginBottom: 18 }}>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleFailure}
        size="large"
        shape="rectangular"
        logo_alignment="center"
        width={320}
      />
    </div>
  );
};

GoogleAuthButton.propTypes = {
  onSuccess: PT.func.isRequired,
  onFailure: PT.func,
};

GoogleAuthButton.defaultProps = {
  onFailure: () => {},
};

export default GoogleAuthButton;
