import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useAlerts, useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Button, RadioGroup, Spinner } from 'components/shared';
import { MdExclamation } from 'components/icons';
import { POST_USER_GENDER, getOnboardingStatistics } from 'api';
import styles from 'styles/Onboarding';
import PrivacyClauseButton from './PrivacyClauseButton';

const NAME = 'gender';

const StyledBox = styled(Box)(styles);

const GenderForm = ({ calledToasts, setCalledToasts, formKey, form, onChange, onGoToNextStep }) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { showToast } = useAlerts();
  const [postUserGender, { loading }] = useMutation(POST_USER_GENDER);

  const showAnalyticToast = async () => {
    if (calledToasts.indexOf(formKey) === -1) {
      const info = (await getOnboardingStatistics(form.zip)) || {};
      if (info.numberOfJobsInZip) {
        const single = info.numberOfJobsInZip === 1;
        setCalledToasts([...calledToasts, formKey]);
        showToast({
          title: `There ${single ? 'is' : 'are'} ${info.numberOfJobsInZip} job${
            single ? '' : 's'
          } waiting for you`,
          icon: () => <MdExclamation />,
          flourishText: '',
          position: { top: false, right: false }
        });
      }
    }
  };

  useEffect(() => {
    showAnalyticToast();
  }, []);

  const handleFieldChange = (field) => (e) => onChange({ ...form, [field]: e.target.value });

  const next = () => {
    onGoToNextStep(() => postUserGender({ variables: { [NAME]: form[NAME] } }));
  };

  return (
    <StyledBox className="pageContent">
      <Box width="100%" flex={1} display="flex" overflow="auto">
        <RadioGroup
          data={[
            { label: 'Female', value: 'female' },
            { label: 'Male', value: 'male' },
            { label: 'Non-Binary', value: 'non-binary' },
            { label: 'I Prefer Not to Answer', value: 'no answer' }
          ]}
          disabled={loading}
          value={form[NAME]}
          defaultValue="female"
          aria-label="gender"
          analyticParams={{
            key: 'Employee gender focused (onboarding)',
            trigger: 'focus'
          }}
          sx={{ mb: '20px' }}
          onChange={handleFieldChange(NAME)}
        />
      </Box>
      <div style={{ maxWidth: 320 }}>
        <p className="primaryText" style={{ marginBottom: 22, fontStyle: 'italic' }}>
          Did you know?
        </p>
        <p className="secondaryText" style={{ marginBottom: 16 }}>
          By law, we're required to ask this, see our privacy clause for more details.
        </p>
      </div>
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        className="nextBtn"
        sx={{ mb: isDesktop ? '25px' : '13px' }}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
      <PrivacyClauseButton />
    </StyledBox>
  );
}

GenderForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default GenderForm;
