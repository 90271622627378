import { darken } from '@mui/material';

const styles = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 600,
  margin: '0 auto',
  width: '100%',
  '& > h1': {
    paddingBottom: 25,
  },
  '& .form-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 6,
    padding: 15,
    width: '100%',
  },
  '& .review-questions': {
    width: '100%',
  },
  '& .to-recipients, & .survey-title': {
    alignSelf: 'flex-start',
    paddingTop: 5,
  },
  '& .question-container': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 0,
    '& .header': {
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: theme.palette.primary.light,
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      fontSize: 18,
      padding: 7.5,
      width: '100%',
    },
    '& .answers': {
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: darken(
        theme.palette.background.default,
        0.15,
      ),
      borderBottomRightRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1),
      padding: 10,
      width: '100%',
    },
  },
});
export default styles;
