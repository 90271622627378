import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Box } from 'components';
import { Button, RadioGroup, Spinner } from 'components/shared';
import { SET_COMPANY_SIZE } from 'api';

const NAME = 'size';

const EmployeesCountForm = ({ form, onChange, onGoToNextStep }) => {
  const [postCompanySize, { loading }] = useMutation(SET_COMPANY_SIZE);

  const handleFieldChange = (field) => (e) => onChange({ ...form, [field]: e.target.value });

  const next = () => {
    onGoToNextStep(() => postCompanySize({ variables: { [NAME]: form[NAME] } }));
  };

  return (
    <>
      <Box flex={1} display="flex" overflow="auto">
        <RadioGroup
          data={[
            { label: '1-49', value: '1-49' },
            { label: '50-999', value: '50-999' },
            { label: '1,000-4,999', value: '1,000-4,999' },
            { label: '5,000 or more', value: '5,000 or more' },
            { label: "Don't know", value: "Don't know" }
          ]}
          disabled={loading}
          value={form[NAME]}
          defaultValue="1-49"
          aria-label="employees count"
          sx={{ marginBottom: '16px' }}
          analyticParams={{
            key: 'Employer employees amount focused (onboarding)',
            trigger: 'focus'
          }}
          onChange={handleFieldChange(NAME)}
        />
      </Box>
      <Button
        variant="filled-primary"
        width="100%"
        height={50}
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        onClick={next}
        testID="onboarding-next-button"
      >
        Next
      </Button>
    </>
  );
}

EmployeesCountForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default EmployeesCountForm;
