import React, { useEffect } from 'react';
import PT from 'prop-types';
import {
  useQuery,
  useMutation,
  useLazyQuery,
} from '@apollo/client';
import { getUserId } from 'utils';
import { Box, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { useForm } from 'components/form';
import styles from 'styles/Onboarding';
import {
  GET_GROW_INDUSTRIES,
  CREATE_CAREER_DEV_ON_REGISTRATION,
  GET_CAREER_DEV_WITH_HEALTH_SCORE,
} from 'api';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CareerIndustry = (props) => {
  const { onGoToNextStep, onChange, form } = props;
  const industryId = form.industryId || '';
  const curJobTypeId = form.curJobTypeId || '';

  const [createCareerDev, { loading: careerDevSaveLoading }] =
    useMutation(CREATE_CAREER_DEV_ON_REGISTRATION);
  const [fetchCareerDev] = useLazyQuery(
    GET_CAREER_DEV_WITH_HEALTH_SCORE,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: industriesData, loading: industriesLoading } =
    useQuery(GET_GROW_INDUSTRIES);
  const industries = industriesData?.industries || [];

  const { $, set, withValidation } = useForm({
    initial: { industryId },
  });

  useEffect(() => {
    set({ industryId });
  }, [industryId]);

  const next = withValidation(async () => {
    const userId = Number(getUserId());
    await createCareerDev({
      variables: { userId, industryId: Number(industryId) },
    });
    await fetchCareerDev({ variables: { userId } });
    onGoToNextStep();
  });

  const handleFieldChange = (e, { name }) => {
    const values = {
      curJobTypeId,
      industryId,
      [name]: e.target.value,
    };
    if (name === 'industryId') values.curJobTypeId = '';
    set(values);
    onChange({ ...form, ...values });
  };

  return (
    <div
      className="pageContent"
      style={{ maxWidth: 461, alignItems: 'normal' }}
    >
      <Box component="p" mb="56px" className="text1">
        By choosing an industry, it helps us connect you with
        relevant jobs and make more personalized suggestions. You
        can always change this later.
      </Box>

      <Box mb="56px">
        <p className="label">Industry:</p>
        <Select
          id="industry"
          {...$('industryId', (obj, meta) =>
            handleFieldChange(obj, meta)
          )}
          required
          placeholder="Choose an industry"
          variant="outlined"
          testID="onboarding-grow-industry-select"
          size={'small'}
          fullWidth
        >
          {industries.map((ind) => (
            <MenuItem value={ind.industry_id}>
              {ind.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box width="100%" maxWidth={224} mx="auto">
        <Button
          variant="filled-primary"
          disabled={
            !industryId ||
            careerDevSaveLoading ||
            industriesLoading
          }
          endIcon={
            careerDevSaveLoading ? <Spinner size={24} /> : null
          }
          className="nextBtn"
          onClick={next}
          testID="onboarding-next-button"
        >
          Next
        </Button>
      </Box>
    </div>
  );
};

CareerIndustry.propTypes = {
  form: PT.objectOf(PT.any).isRequired,
  onGoToNextStep: PT.func.isRequired,
  onChange: PT.func.isRequired,
};

const StyledCareerIndustry = styled(CareerIndustry)(styles);

export default StyledCareerIndustry;
