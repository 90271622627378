import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import CustomizableChart from './CustomizableChart';
import { IChartBaseProps, IChartColors } from './chartUtils';
import { useAppStoreSelector } from '../../../store';
import { darken } from '@mui/material';

const styles = ({ theme }) => ({
  '& .chart__chartWrapper_override': {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 3,
  },
});

const VerticalBarChart = ({
  label,
  height,
  width,
  data,
  chartLabels,
  hasChartGrid,
  hasChartLabels,
}: IChartBaseProps) => {
  const [chartColors, setChartColors] =
    useState<IChartColors | null>(null);
  const [chartDataOffset, setChartDataOffset] = useState<{
    data: number[];
    backgroundColor: string[];
  }>(null);
  const { colors, colorMode } = useAppStoreSelector(
    ({ colorMode: baseColorMode, colors: baseColors }) => ({
      colorMode: baseColorMode,
      colors: baseColors,
    }),
  );

  const colorRanges = {
    low: {
      background: colors[colorMode].primary.main,
      backgroundHover: colors[colorMode].primary.light,
    },
    mid: {
      background: colors[colorMode].secondary.main,
      backgroundHover: colors[colorMode].secondary.light,
    },
    high: {
      background: colors[colorMode].tertiary.main,
      backgroundHover: colors[colorMode].tertiary.light,
    },
  };

  useEffect(() => {
    // set backgroundColors and hover colors
    const max = Math.max(...data) || 5;
    const min = Math.min(...data);
    const thresholdSectionValue = (max - min) / 3;
    const lowThreshold =
      Math.round((thresholdSectionValue + min) * 1e2) / 1e2;
    const midThreshold =
      Math.round((thresholdSectionValue * 2 + min) * 1e2) / 1e2;

    // set backgroundColors and hover colors
    const chartColorsTemp = data.reduce(
      (acc, cur) => {
        if (cur <= lowThreshold) {
          // low threshold color
          acc.backgroundColor.push(colorRanges.low.background);
          acc.hoverBackgroundColor.push(
            colorRanges.low.backgroundHover,
          );
        } else if (cur <= midThreshold) {
          // mid threshold color
          acc.backgroundColor.push(colorRanges.mid.background);
          acc.hoverBackgroundColor.push(
            colorRanges.mid.backgroundHover,
          );
        } else {
          // high threshold color
          acc.backgroundColor.push(colorRanges.high.background);
          acc.hoverBackgroundColor.push(
            colorRanges.high.backgroundHover,
          );
        }
        return acc;
      },
      {
        backgroundColor: [],
        hoverBackgroundColor: [],
      } as IChartColors,
    );

    setChartDataOffset(
      data.reduce(
        (acc, dataPoint) => ({
          data: [...acc.data, max - dataPoint],
          backgroundColor: [
            ...acc.backgroundColor,
            darken(colors[colorMode].background.default, 0.2),
          ],
        }),
        {
          data: [],
          backgroundColor: [],
        },
      ),
    );

    setChartColors(chartColorsTemp);
  }, []);

  if (chartLabels.length !== data.length) {
    return <Box>The data and labels do not match</Box>;
  }

  if (!chartColors || !chartDataOffset) return null;

  return (
    <CustomizableChart
      type={'bar'}
      label={label}
      datasets={[
        {
          data,
          ...chartColors,
          hoverOffset: 4,
        },
        {
          ...chartDataOffset,
          hoverOffset: 4,
        },
      ]}
      isStacked
      chartLabels={chartLabels}
      hasChartGrid={hasChartGrid}
      hasChartLabels={hasChartLabels}
      height={height}
      width={width}
      styles={styles}
    />
  );
};

export default VerticalBarChart;
