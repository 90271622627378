import React from 'react';
import { getRoutes } from 'utils';
import { styled } from 'components';
import { Button } from 'components/shared';
import { MdCareerDevInsights } from 'components/icons';

const ROUTES = getRoutes();

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 53,
  fontWeight: 'bold',
  fontSize: 17,
  lineHeight: '19px',
  color: theme.palette.common.white,
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: 25,
  backgroundColor: theme.palette.background.default
}));

const GrowLink = (props) => (
    <StyledButton
      isRouterLink
      to={ROUTES.employee.grow}
      endIcon={<MdCareerDevInsights />}
      className="qhGrowBanner"
      testID="wt-grow-button"
      {...props}
    >
      New! WT Grow
    </StyledButton>
  )

GrowLink.propTypes = {};

export default GrowLink;
