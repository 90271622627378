import React from 'react';
import { useMediaQueryMatches } from 'hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from 'components';
import { MdExpandMore } from 'components/icons';
import { BoxProps } from '@mui/material/Box/Box';
import FlexRow from '../shared/FlexRow';
import { alpha } from '@mui/material';

const StyledRoot = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .sectionHeader': {
    minHeight: 'auto',
    padding: `${theme.spacing(1)} 0`,
    borderBottom: `1px solid ${alpha(
      theme.palette.background.paper,
      0.75,
    )}`,
    marginBottom: theme.spacing(1),
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
    '&.sticky-header': {
      position: 'sticky',
      top: 0,
      zIndex: 20,
    },
    [theme.breakpoints.down('desktopApp')]: {
      position: 'sticky',
      top: 0,
      zIndex: 20,
    },
  },
  '& .sectionHeader__content': {
    margin: '4px 0',
    '&.Mui-expanded': {
      margin: '4px 0',
    },
  },
  '& .sectionHeader__expandIcon': {
    marginRight: 0,
    padding: 0,
    color: theme.palette.primary.light,
  },
  '& .sectionHeader__title': {
    display: 'inline',
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '25px',
    color: theme.palette.primary.text,
    '& > a': {
      color: 'inherit',
    },
    [theme.breakpoints.down('desktopApp')]: {
      color: theme.palette.primary.light,
    },
  },
  '& .sectionHeader__action': {
    marginLeft: 12,
    fontSize: 13,
    lineHeight: '14px',
    [theme.breakpoints.down('desktopApp')]: {
      marginLeft: 15,
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  '& .sectionBody': {
    padding: 0,
  },
}));

export interface ISectionLayout {
  children: React.ReactNode;
  expanded: Required<boolean>;
  jumpLink: string;
  jumpLinkProps?: Partial<HTMLAnchorElement>;
  sectionName: Required<string>;
  sectionHeaderContent?: JSX.Element;
  sectionHeaderProps?: BoxProps;
  onExpand: (
    sectionName: null | string,
    sectionNameOpt: string,
    expand: boolean,
  ) => void;
}

function SectionLayout(props: ISectionLayout) {
  const {
    children = null,
    expanded = false,
    jumpLink = '',
    jumpLinkProps = {},
    sectionHeaderContent = null,
    sectionHeaderProps,
    onExpand,
    sectionName,
  } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const renderSectionHeader = () => (
    <FlexRow
      inline
      {...(sectionHeaderProps || {})}
      justifyContent={'space-between'}
    >
      <h2 className="sectionHeader__title">
        {jumpLink ? (
          <a
            id={jumpLink}
            href={`#${jumpLink}`}
            {...jumpLinkProps}
          >
            {sectionName}
          </a>
        ) : (
          sectionName
        )}
      </h2>
      {sectionHeaderContent && sectionHeaderContent}
    </FlexRow>
  );

  const renderDesktopLayout = () => (
    <StyledRoot>
      <div
        className="sectionHeader"
        id={`${jumpLink}-section-header`}
      >
        {renderSectionHeader()}
      </div>
      <div>{children}</div>
    </StyledRoot>
  );

  const renderMobileLayout = () => (
    <StyledRoot>
      <Accordion
        expanded={expanded}
        square
        elevation={0}
        classes={{
          root: 'sectionContainer',
        }}
        onChange={(e, expand) =>
          onExpand(
            expand ? sectionName : null,
            sectionName,
            expand,
          )
        }
      >
        <AccordionSummary
          id={`${jumpLink}-section-header`}
          expandIcon={
            <MdExpandMore fontSize="inherit" color="inherit" />
          }
          classes={{
            root: 'sectionHeader',
            content: 'sectionHeader__content',
            expandIconWrapper: 'sectionHeader__expandIcon',
          }}
        >
          {renderSectionHeader()}
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'sectionBody' }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </StyledRoot>
  );

  return isDesktop
    ? renderDesktopLayout()
    : renderMobileLayout();
}

export default SectionLayout;
