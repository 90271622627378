import { addAlpha } from 'styles/utils';

const sideNavWidth = 260;
const wrappers = ({ theme }) => ({
  '&.container': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  '& .contentWrapper': {
    padding: '25px 30px 30px',
    overflow: 'auto',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '24px 18px 20px',
    },
  },
  '& .layoutColumn': {
    width: '100%',
    '&:first-of-type': {
      maxWidth: sideNavWidth,
      padding: 15,
      backgroundColor: theme.palette.background.paper,
    },
    '&:not(:first-of-type)': {
      width: '100%',
      marginLeft: 30,
      maxWidth: `calc(100% - ${sideNavWidth}px)`,
    },
  },
  '& .header': {
    padding: '0 30px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 18px 6px',
    },
  },
  '& .imageContainer': {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 25,
    },
  },
  '& .questionsContainer': {
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 30,
    },
    '.StyledInput-label': {
      color: theme.palette.primary.light,
    },
  },
  '& .formItem': {
    marginBottom: 25,
    '&.row': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('desktopApp')]: {
        flexWrap: 'wrap',
        marginLeft: -20,
        marginTop: -20,
      },
      '& .rowInput:not(:first-of-type)': {
        marginLeft: 25,
        [theme.breakpoints.down('desktopApp')]: {
          marginLeft: 0,
        },
      },
      '& .rowInput': {
        width: '100%',
        '&.col25': {
          width: '25%',
          [theme.breakpoints.down('desktopApp')]: {
            width: '50%',
          },
        },
        [theme.breakpoints.down('desktopApp')]: {
          width: '50%',
          paddingLeft: 20,
          paddingTop: 20,
          '&.mobOrder-1': {
            order: 1,
          },
          '&.mobOrder-2': {
            order: 2,
          },
          '&.mobOrder-3': {
            order: 3,
          },
          '&.mobOrder-4': {
            order: 4,
          },
          '&.mobFullWidth': {
            width: '100%',
          },
        },
      },
    },
  },
  '& .loaderOverlay': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: addAlpha(
      theme.palette.background.default,
      0.7,
    ),
  },
  '& .avatarEditor': {
    border: `1px solid ${theme.palette.grey['200']}`,
    cursor: 'move',
    '&.error': {
      borderColor: theme.palette.error.main,
    },
  },
  '& .imagePreview': {
    height: 200,
    width: 200,
    objectFit: 'cover',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('desktopApp')]: {
      width: 227,
      height: 227,
    },
  },
});

const buttons = ({ theme }) => ({
  '& .jobAction': {
    minWidth: 137,
    height: 43,
    padding: '0 12px',
    marginTop: 8,
    borderRadius: 22,
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: 88,
      height: 28,
      fontSize: 11,
    },
    '&:not(:last-child)': {
      marginRight: 25,
      [theme.breakpoints.down('desktopApp')]: {
        marginRight: 15,
      },
    },
    '&.danger': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
  },
  '& .mainAction': {
    padding: '15px 19px',
    fontSize: 14,
    lineHeight: '16px',
    borderRadius: 3,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 13,
      lineHeight: '14px',
      borderRadius: 7,
    },
  },
});

const form = ({ theme }) => ({
  '& .label': {
    marginBottom: 13,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 17,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
  '& .value': {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
  },
  '& .select': {
    borderRadius: 5,
  },
  '& .select__input': {
    '&.StyledInput-inputBase__input': {
      padding: '6px 0',
      paddingLeft: 6,
      paddingRight: 24,
    },
  },
  '& .textarea': {
    minHeight: 125,
    alignItems: 'flex-start',
    borderRadius: 5,
  },
  '& .textareaInput': {
    '&.StyledInput-inputBase__input': {
      padding: 6,
    },
  },
  '& .currencyInput': {
    maxWidth: 80,
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 'initial',
    },
  },
  '.generateSelect': {
    minWidth: 250,
  },
});

const text = ({ theme }) => ({
  '& .title': {
    fontSize: 22,
    lineHeight: '26px',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none',
    },
  },
  '& .imageTypeTip': {
    marginTop: 4,
    marginBottom: 20,
    fontSize: 9,
    fontWeight: 'bold',
    lineHeight: '10px',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: 14,
      marginBottom: 24,
      fontSize: 10,
      lineHeight: '11px',
    },
  },
  '& .disclaimerText': {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '19px',
    color: theme.palette.text.secondary,
  },
  '& .questionsTitle': {
    marginBottom: 23,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '13px',
    color: theme.palette.text.primary,
  },
  '.generateJobContainer': {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.secondary.main}`,
  },
});

export default ({ theme }) => ({
  ...wrappers({ theme }),
  ...buttons({ theme }),
  ...form({ theme }),
  ...text({ theme }),
});
