import { addAlpha } from 'styles/utils';
import { lighten } from '@mui/material';

const common = ({ theme }) => ({
  '& .formItem': {
    marginBottom: 25,
    '&.row': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('desktopApp')]: {
        flexWrap: 'wrap',
        marginLeft: -20,
        marginTop: -20,
      },
      '& .rowInput:not(:first-of-type)': {
        marginLeft: 25,
        [theme.breakpoints.down('desktopApp')]: {
          marginLeft: 0,
        },
      },
      '& .rowInput': {
        width: '25%',
        [theme.breakpoints.down('desktopApp')]: {
          width: '50%',
          paddingLeft: 20,
          paddingTop: 20,
          '&.mobFullWidth': {
            width: '100%',
          },
        },
      },
    },
  },
  '& .select': {
    '& .StyledInput-inputBase': {
      borderRadius: 5,
    },
    '& .MuiSelect-select': {
      padding: '6px 0 6px',
      paddingLeft: 6,
      paddingRight: 24,
    },
  },
  '& .label': {
    marginBottom: 13,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
    color: lighten(theme.palette.text.primary, 0.65),
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 17,
    },
  },
  '& .value': {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
  },
  '& .mainAction': {
    padding: '15px 19px',
    fontSize: 14,
    lineHeight: '16px',
    borderRadius: 7,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 13,
      lineHeight: '14px',
      borderRadius: 7,
    },
  },
  '& .ellipsisText': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& .dialogHeader': {
    padding: '13px 13px 12px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '5px 17px',
    },
  },
  '& .dialogTitle': {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      lineHeight: '18px',
      color: theme.palette.text.primary,
    },
  },
  '& .headerAction_start': {
    flex: '0 0 44px',
  },
  '& .headerAction_end': {
    flex: '0 0 44px',
  },
  '& .dialogContent': {
    padding: '0 17px 33px',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
});

const container = ({ theme }) => ({
  '&.container': {
    position: 'relative',
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('desktopApp')]: {
      overflow: 'auto',
    },
  },
  '& .loaderOverlay': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: addAlpha(
      theme.palette.background.default,
      0.7,
    ),
  },
  '& .column': {
    width: '100%',
    overflow: 'auto',
    '&:first-of-type': {
      maxWidth: 155,
      marginRight: 32,
      paddingLeft: 30,
    },
    '&:last-of-type': {
      paddingRight: 30,
    },
  },
  '& .sticky-column': {
    position: 'sticky',
    top: 50,
    alignSelf: 'flex-start',
  },
  '& .contentItem': {
    padding: '10px 0',
    '& > a': {
      color: 'inherit',
    },
    '&:not(:last-of-type)': {
      borderBottom: '1px solid #ECECF5',
    },
  },
  '& .sectionContainer': {
    '&.Mui-expanded': {
      margin: 0,
    },
    '&::before': {
      display: 'none',
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 24,
    },
  },
  '& .desktopLayout': {
    width: '100%',
    display: 'flex',
  },
  '& .mobileLayout': {
    width: '100%',
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(2),
      // this is so stupid, but only way to access all profile sections at once.
      '> div > div': {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
      },
    },
  },
});

const profile = ({ theme }) => ({
  '&.profile__container': {
    width: '100%',
    padding: '16px 0 58px',
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
    },
  },
  '& .profile__headerAction': {
    marginLeft: 12,
    fontSize: 13,
    lineHeight: '14px',
    // color: theme.palette.tertiary.dark,
    '&.profile_saveBtn': {
      padding: '4px 14px',
      borderRadius: 12,
      // color: theme.palette.tertiary.dark
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginLeft: 15,
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  '& .profile__formGroup': {
    minWidth: 226,
    '&:not(:first-of-type)': {
      width: '100%',
      marginLeft: 60,
      [theme.breakpoints.down('desktopApp')]: {
        marginLeft: 0,
      },
    },
  },
  '& .profile__imageTypeTip': {
    marginTop: 4,
    marginBottom: 20,
    fontSize: 9,
    fontWeight: 'bold',
    lineHeight: '10px',
    color: theme.palette.grey['100'],
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: 14,
      marginBottom: 24,
      fontSize: 10,
      lineHeight: '11px',
    },
  },
  '& .profile__imagePreview': {
    height: 200,
    width: 200,
    objectFit: 'cover',
    borderRadius: 14,
    [theme.breakpoints.down('desktopApp')]: {
      width: 227,
      height: 227,
    },
  },
});

const notice = ({ theme }) => ({
  '&.notice__container': {
    width: '100%',
    padding: '29px 0 70px',
    [theme.breakpoints.down('desktopApp')]: {
      paddingTop: 13,
    },
  },
  '& .notice__sectionHeader': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .notice__sectionHeader__item': {
    width: 60,
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
    color: theme.palette.grey['100'],
    '&:not(:first-of-type)': {
      marginLeft: 51,
      [theme.breakpoints.down('desktopApp')]: {
        marginLeft: 12,
      },
    },
  },
  '& .notice__header': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 17,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  '& .notice__header__item': {
    width: 60,
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
    color: theme.palette.grey['300'],
    '&:not(:first-of-type)': {
      marginLeft: 51,
      [theme.breakpoints.down('desktopApp')]: {
        marginLeft: 12,
      },
    },
  },
  '& .notice__group': {
    '&:not(:last-of-type)': {
      marginBottom: 10,
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 20,
      },
    },
  },
  '& .notice__groupHeader': {
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 8,
    },
  },
  '& .notice__labelContainer': {
    width: '100%',
  },
  '& .notice__actionsContainer': {
    position: 'relative',
    top: 12,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      position: 'static',
    },
  },
  '& .notice__group__name': {
    width: '100%',
    marginBottom: 7,
  },
  '& .notice__descr': {
    maxWidth: '60%',
    position: 'relative',
    top: -7,
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: '100%',
      position: 'static',
    },
  },
  '& .notice__descr__text': {
    marginBottom: 0,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 12,
      lineHeight: '13px',
    },
  },
  '& .notice__switchRoot': {
    '&:not(:first-of-type)': {
      marginLeft: 51,
      [theme.breakpoints.down('desktopApp')]: {
        marginLeft: 12,
      },
    },
  },
});

const account = ({ theme }) => ({
  '&.account__container': {
    width: '100%',
    padding: '29px 0 50px',
  },
  '& .account__pwdForm': {
    marginBottom: 45,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 41,
    },
  },
  '& .account__title': {
    marginBottom: 18,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 23,
    },
  },
  '& .account__formItem': {
    width: '100%',
    marginBottom: 27,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 24,
    },
  },
  '& .account__doubleCol': {
    width: '100%',
    display: 'flex',
    '& .account__formItem:last-of-type': {
      marginBottom: 19,
      marginLeft: 20,
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 19,
        marginLeft: 0,
      },
    },
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
    },
  },
});

const managePlan = ({ theme }) => ({
  '&.managePlan__container': {
    width: '100%',
    padding: '25px 0 47px',
  },
  '& .managePlan__headerActions': {
    display: 'flex',
  },
  '& .managePlan__headerAction': {
    marginLeft: 27,
    fontSize: 11,
    lineHeight: '12px',
    [theme.breakpoints.down('desktopApp')]: {
      marginLeft: 15,
      fontSize: 14,
      lineHeight: '16px',
    },
    '&.managePlan__cancel': {
      color: theme.palette.error.main,
    },
  },
  '& .managePlan__planLabelContainer': {
    marginBottom: 13,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 17,
    },
  },
  '& .managePlan__plansSubSection': {
    marginBottom: 37,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 28,
    },
  },
  '& .managePlan__doubleCol': {
    marginBottom: 16,
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 23,
      flexDirection: 'column',
    },
  },
  '& .managePlan__col': {
    minWidth: 200,
    '&:not(:first-of-type)': {
      width: '100%',
      marginLeft: 60,
      [theme.breakpoints.down('desktopApp')]: {
        marginLeft: 0,
      },
    },
    '&:not(:last-of-type)': {
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 26,
      },
    },
  },
  '& .managePlan__curPlan': {
    marginBottom: 12,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 13,
      fontSize: 12,
      lineHeight: '13px',
    },
  },
  '& .managePlan__price': {
    color: theme.palette.grey['100'],
  },
  '& .managePlan__choosePlan': {
    borderRadius: 5,
    backgroundColor: theme.palette.quaternary.main,
    color: theme.palette.background.paper,
    padding: 10,
    marginTop: 10,
    width: 150,
    textAlign: 'center',
    display: 'inline-block',
  },
  '& .managePlan__payDay': {
    fontSize: 13,
    fontWeight: 'regular',
    lineHeight: '14px',
    color: theme.palette.text.primary,
  },
  '& .managePlan__features': {
    marginBottom: -21,
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
    },
    '& > div': {
      width: '33.33%',
      marginBottom: 21,
      fontSize: 11,
      fontWeight: 'bold',
      lineHeight: '12px',
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('desktopApp')]: {
        width: '100%',
      },
    },
  },
  '& .managePlan__paymentAction': {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
  },
  '& .managePlan__paymentSubSectionTitle': {
    marginBottom: 20,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 14,
    },
  },
  '& .managePlan__paymentMethods': {
    position: 'relative',
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    [theme.breakpoints.down('desktopApp')]: {
      paddingBottom: 0,
      flexDirection: 'column',
      overflowX: 'hidden',
      alignItems: 'center',
    },
  },
  '& .managePlan__cards__loaderOverlay': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: addAlpha(
      theme.palette.background.default,
      0.7,
    ),
  },
  '& .managePlan__paymentItem': {
    width: '100%',
    minWidth: 232,
    maxWidth: 232,
    '&:not(:last-of-type)': {
      marginRight: 36,
      [theme.breakpoints.down('desktopApp')]: {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 276,
      marginBottom: 38,
    },
  },
  '& .managePlan__paymentItem__header': {
    marginBottom: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .managePlan__cardStatus': {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
    color: theme.palette.common.white,
    '&.managePlan__expired': {
      color: theme.palette.error.main,
    },
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 9,
      lineHeight: '10px',
    },
  },
  '& .managePlan__cardAction': {
    marginLeft: 0,
    borderRadius: 0,
    '&:not(:last-of-type)': {
      marginRight: 9,
      paddingRight: 8,
      borderRight: '1px solid #A4A3A4',
    },
    '& .endIcon': {
      marginRight: 0,
      marginLeft: 4,
    },
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 9,
      lineHeight: '10px',
    },
  },
  '& .managePlan__card': {
    '& .rccs, .rccs__card': {
      width: '100%',
    },
    '& .rccs__card': {
      height: 148,
      [theme.breakpoints.down('desktopApp')]: {
        height: 174,
      },
    },
    '& .rccs__number, .rccs__expiry__value, .rccs__name': {
      fontSize: 12,
    },
    '& .rccs__expiry__valid': {
      fontSize: 9,
    },
    '& .rccs__card--front': {
      boxShadow: 'none',
    },
  },
});

const manageAccess = ({ theme }) => ({
  '& .manageAccess__sectionHeader': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .manageAccess__container': {
    width: '100%',
    padding: '25px 0 47px',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0,
    },
  },
  '& .manageAccess__list': {
    marginBottom: 10,
  },
  '& .manageAccess__list-item': {
    width: '100%',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.grey['100'],
    justifyContent: 'space-between',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.paper,
    },
    '&.active': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0,
      paddingBottom: theme.spacing(2),
      '.userDetails': {
        p: {
          fontSize: 14,
        },
      },
    },
  },
  '& .manageAccess__actionBtn': {
    fontSize: 10,
    borderRadius: 12,
    [theme.breakpoints.down('desktopApp')]: {},
  },
  '& .manageAccess__switch': {
    [theme.breakpoints.down('desktopApp')]: {},
  },
  '& .manageAccess__input-label': {
    marginBottom: 5,
    [theme.breakpoints.down('desktopApp')]: {},
  },
});

export default ({ theme }) => ({
  ...common({ theme }),
  ...container({ theme }),
  ...profile({ theme }),
  ...notice({ theme }),
  ...account({ theme }),
  ...managePlan({ theme }),
  ...manageAccess({ theme }),
});
