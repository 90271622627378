import { gql } from '@apollo/client';

export const ONBOARDING_DOCUMENTS = gql`
  query OnboardingDocuments {
    onboardingDocuments {
      limit
      offset
      pages
      total
      results {
        name
        uuidReference
        allPositions
        organizationUUID
        id
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const ONBOARDING_DOCUMENT = gql`
  query OnboardingDocument($id: String!) {
    onboardingDocument(id: $id) {
      name
      uuidReference
      allPositions
      organizationUUID
      numberOfSignatures
      numberOfInitials
      numberOfDates
      id
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const ONBOARDING_ASSIGNMENT = gql`
  query OnboardingAssignment($id: String!) {
    onboardingAssignment(id: $id) {
      onboardingId
      employeeId
      onboardingDocumentId
      status
      deletedAt
      id
      createdAt
      updatedAt
    }
  }
`;

export const ONBOARDING_RESOURCE = gql`
  query OnboardingResource($id: String!) {
    onboardingResource(id: $id) {
      organizationUUID
      allPositions
      isPage
      type
      title
      description
      url
      id
    }
  }
`;

export const ONBOARDING_RESOURCES = gql`
  query OnboardingResources(
    $orderBy: String
    $direction: String
    $limit: Int
    $offset: Int
    $phrase: String
  ) {
    onboardingResources(
      orderBy: $orderBy
      direction: $direction
      limit: $limit
      offset: $offset
      phrase: $phrase
    ) {
      limit
      offset
      pages
      total
      results {
        organizationUUID
        allPositions
        isPage
        type
        title
        description
        url
        id
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
export const ONBOARDING_RESOURCES_MINIMAL_DATA = gql`
  query OnboardingResources($limit: Int) {
    onboardingResources(limit: $limit) {
      total
      results {
        isPage
        title
        id
        type
      }
    }
  }
`;

export const ONBOARDING = gql`
  query Onboarding($onboardingId: String) {
    onboarding(onboardingId: $onboardingId) {
      name
      organizationId
      jobTypeId
      allLocations
      id
      resourceAssociations {
        onboardingId
        onboardingResourceId
        index
        id
        resource {
          organizationUUID
          allPositions
          isPage
          type
          title
          description
          url
          id
        }
      }
      assignments {
        onboardingId
        employeeId
        onboardingDocumentId
        status
        id
        employeeProfile {
          user_id
          profile_id
          imageUrl
        }
      }
      documentAssociations {
        onboardingId
        onboardingDocumentId
        id
        document {
          name
          uuidReference
          allPositions
          organizationUUID
          numberOfSignatures
          numberOfInitials
          numberOfDates
          id
        }
      }
    }
  }
`;

export const ONBOARDINGS = gql`
  query Onboardings(
    $orderBy: String
    $direction: String
    $limit: Int
    $offset: Int
    $phrase: String
  ) {
    onboardings(
      orderBy: $orderBy
      direction: $direction
      limit: $limit
      offset: $offset
      phrase: $phrase
    ) {
      limit
      offset
      pages
      total
      results {
        name
        jobTypeId
        allLocations
        id
        jobType {
          name
          industryId
        }
        resourceAssociations {
          id
          resource {
            id
          }
        }
        assignments {
          status
          id
        }
        documentAssociations {
          id
          document {
            name
            allPositions
            id
          }
        }
      }
    }
  }
`;

export const ONBOARDING_DOCUMENTS_BY_EMPLOYEE_ID = gql`
  query OnboardingDocumentsByEmployeeId(
    $orderBy: String
    $direction: String
    $limit: Int
    $offset: Int
    $phrase: String
  ) {
    onboardingDocumentsByEmployeeId(
      orderBy: $orderBy
      direction: $direction
      limit: $limit
      offset: $offset
      phrase: $phrase
    ) {
      limit
      offset
      pages
      total
      results {
        name
        uuidReference
        allPositions
        organizationUUID
        numberOfSignatures
        numberOfInitials
        numberOfDates
        id
      }
    }
  }
`;

export const ONBOARDING_RESOURCES_BY_EMPLOYEE_ID = gql`
  query OnboardingResourcesByEmployeeId(
    $orderBy: String
    $direction: String
    $limit: Int
    $offset: Int
    $phrase: String
  ) {
    onboardingResourcesByEmployeeId(
      orderBy: $orderBy
      direction: $direction
      limit: $limit
      offset: $offset
      phrase: $phrase
    ) {
      limit
      offset
      pages
      total
      results {
        onboardingId
        onboardingResourceId
        index
        id
        resource {
          organizationUUID
          allPositions
          isPage
          type
          title
          description
          url
          id
        }
      }
    }
  }
`;

export const ONBOARDING_ASSIGNMENT_BY_EMPLOYEE_ID = gql`
  query OnboardingAssignmentByEmployeeId {
    onboardingAssignmentByEmployeeId {
      onboardingId
      employeeId
      onboardingDocumentId
      status
      id
      location {
        city
        address
        id
        name
        zip
        legacyProfileId
      }
      organization {
        id
        name
      }
    }
  }
`;

export const ONBOARDING_STATUS = gql`
  query OnboardingStatus($employeeId: Int) {
    onboardingStatus(employeeId: $employeeId) {
      complete
      hasOnboarding
      status
    }
  }
`;

export const ONBOARDING_FLOW = gql`
  query OnboardingFlow($onboardingId: String!) {
    onboardingFlow(onboardingId: $onboardingId) {
      resources {
        onboardingId
        onboardingResourceId
        index
        id
        resource {
          organizationUUID
          allPositions
          isPage
          type
          title
          description
          url
          id
        }
      }
      pages {
        onboardingId
        onboardingResourceId
        index
        id
        resource {
          organizationUUID
          allPositions
          isPage
          type
          title
          description
          url
          id
        }
      }
    }
  }
`;

export const ONBOARDING_RESOURCE_ONBOARDING = gql`
  query OnboardingResourceOnboarding($onboardingId: String) {
    onboardingResourceOnboarding(onboardingId: $onboardingId) {
      onboardingId
      onboardingResourceId
      index
      id
      createdAt
      updatedAt
      deletedAt
      resource {
        organizationUUID
        allPositions
        isPage
        type
        title
        description
        url
        id
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
