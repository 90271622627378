import {
  alpha,
  darken,
  emphasize,
  lighten,
} from '@mui/material';
import { Capacitor } from '@capacitor/core';

export const isNative = Capacitor.isNativePlatform();
const isMobile = window.innerWidth < 1024;
export const scaleFont = (fontSize: number) => {
  if (isNative || isMobile) return fontSize * 1.15;
  return fontSize;
};

export const addAlpha = (color, opacity) => {
  if (opacity === 0) return `${color}00`;
  const _opacity = Math.round(
    Math.min(Math.max(opacity || 1, 0), 1) * 255,
  );
  return color + _opacity.toString(16).toUpperCase();
};

export const getStatusColorClass = (value) => {
  if (value < 25) return 'needsAttention';
  if (value < 50) return 'decentWork';
  return 'inGoodShape';
};

const PALETTE_THRESHOLD_MIDDLE = 125;
export const getLuminescence = (
  color: string,
): { value: number; scale: 'light' | 'dark' } => {
  const hex = color.substring(1);
  const rgb = parseInt(hex, 16);
  // eslint-disable-next-line no-bitwise
  const r = (rgb >> 16) & 0xff;
  // eslint-disable-next-line no-bitwise
  const g = (rgb >> 8) & 0xff;
  // eslint-disable-next-line no-bitwise
  const b = (rgb >> 0) & 0xff;

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  // luma value is 0 to 255 where 0 is black and 255 is white
  // greater than 125 is considered light
  return {
    value: luma,
    scale: luma > PALETTE_THRESHOLD_MIDDLE ? 'light' : 'dark',
  };
};

export const reverseObject = (obj) => {
  const reversedObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      reversedObj[obj[key]] = key;
    }
  }
  return reversedObj;
};

export const buildAndSetTheme = ({
  colorsToBuildTheme,
  colorScheme,
  setTheme,
}: {
  colorsToBuildTheme?: string[];
  colorScheme: { name: string; color: string }[];
  setTheme: (any) => void;
}) => {
  const localColorScheme =
    colorsToBuildTheme ||
    colorScheme.map((color) => color.color);
  const constantDarkColors = {
    error: '#ed1d5d',
    // binary or trinary values. no calculation
    warning: '#f38e2a',
    // binary or trinary values. no calculation
    success: '#77ea46',
    // binary or trinary values. no calculation
    info: '#20a0f1',
  };

  const constantLightColors = {
    error: '#ed6c94',
    // binary or trinary values. no calculation
    warning: '#f3bc86',
    // binary or trinary values. no calculation
    success: '#b0f492',
    // binary or trinary values. no calculation
    info: '#84c3ea',
  };

  const primary = localColorScheme[0];
  const secondary = localColorScheme[1];
  const tertiary = localColorScheme[2];
  const quaternary = localColorScheme[3];

  const baseColors = {
    dark: {
      primary,
      secondary,
      tertiary,
      quaternary,
    },
    light: {
      primary,
      secondary,
      tertiary,
      quaternary,
    },
  };

  const backgroundColorDark = darken(primary, 0.9);
  const backgroundColorLight = lighten(primary, 0.95);

  const themeColors = {
    dark: {
      primary: baseColors.dark.primary,
      secondary: baseColors.dark.secondary,
      tertiary: baseColors.dark.tertiary,
      quaternary: baseColors.dark.quaternary,
      anomaly: emphasize(baseColors.dark.primary, 0.5),
      ...constantDarkColors,
      background: backgroundColorDark,
      text: {
        primary: emphasize(backgroundColorDark, 0.9),
        secondary: lighten(baseColors.dark.secondary, 0.35),
        disabled: alpha(
          emphasize(baseColors.dark.primary, 0.95),
          0.5,
        ),
        contrast: emphasize(baseColors.dark.primary, 0.7),
      },
    },
    light: {
      primary: baseColors.light.primary,
      secondary: baseColors.light.secondary,
      tertiary: baseColors.light.tertiary,
      quaternary: baseColors.light.quaternary,
      anomaly: emphasize(primary, 0.5),
      ...constantLightColors,
      background: backgroundColorLight,
      text: {
        primary: emphasize(backgroundColorLight, 0.9),
        secondary: lighten(baseColors.light.secondary, 0.35),
        disabled: alpha(
          emphasize(baseColors.light.primary, 0.95),
          0.5,
        ),
        contrast: emphasize(baseColors.light.primary, 0.7),
      },
    },
  };

  setTheme(themeColors);
};
