import { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import findIndex from 'lodash/findIndex';
import { getProfileId } from 'utils';
import {
  GET_TRAININGS,
  POST_TRAININGS,
  DELETE_TRAININGS,
} from 'api';

function useEmployeeTrainings() {
  const [trainings, setTrainings] = useState([]);

  const [fetch, { data: trainingsData }] = useLazyQuery(
    GET_TRAININGS,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  const [postTraining] = useMutation(POST_TRAININGS);
  const [removeTraining] = useMutation(DELETE_TRAININGS);

  useEffect(() => {
    if (trainingsData?.getTrainings) {
      setTrainings(
        trainingsData.getTrainings.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ __typename, ...rest }) => ({ ...rest }),
        ),
      );
    }
  }, [JSON.stringify(trainingsData)]);

  const fetchTrainings = useCallback(
    (employeeProfileId, employerProfileId) => {
      const pid = employeeProfileId || getProfileId();

      if (pid) {
        fetch({
          variables: {
            employeeProfileId: Number(pid),
            employerProfileId,
          },
        });
      }
    },
    [],
  );

  const editTraining = useCallback(
    async (variables) => {
      try {
        const res = await postTraining({ variables });
        const training = res?.data?.postTrainings;

        if (training?.id) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { __typename, ...rest } = training;
          const idx = findIndex(trainings, { id: training.id });

          if (idx !== -1) {
            setTrainings((prev) => {
              const trainingsArr = [...prev];
              trainingsArr[idx] = { ...rest };
              return trainingsArr;
            });
          }

          return training;
        }
      } catch (error) {
        console.error(error);
      }

      return null;
    },
    [JSON.stringify(trainings)],
  );

  const deleteTraining = useCallback(
    async (courseId) => {
      try {
        if (courseId) {
          await removeTraining({ variables: { id: courseId } });
          const trainingsArr = [...trainings].filter(
            (obj) => obj.id !== courseId,
          );
          setTrainings(trainingsArr);

          return { complete: true };
        }
      } catch (error) {
        console.error(error);
      }

      return { complete: false };
    },
    [JSON.stringify(trainings)],
  );

  return {
    fetchTrainings,
    editTraining,
    deleteTraining,
    trainings,
    setTrainings,
  };
}

export default useEmployeeTrainings;
