import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import React from 'react';
import { employeeCardStyles } from 'styles/Retention/Location/employeeCard';
import Avatar from '../../../shared/Avatar';

const StyledBox = styled(Box)(employeeCardStyles);

export interface IEmployerCardProps {
  name: string;
  imageUrl: string;
  location: string;
  jobType: string;
}

const EmployerCard = ({
  name,
  imageUrl,
  location,
  jobType,
}: IEmployerCardProps) => (
  <StyledBox>
    <Box key={name} className="parentWrapper">
      <Avatar
        type={'EMPLOYEE'}
        alt={`profile photo of ${name}`}
        url={imageUrl}
      />
      <Typography className="memberName">{name}</Typography>
      <Typography className="memberTitle">{jobType}</Typography>
      <Typography className="memberLocation">
        {location}
      </Typography>
    </Box>
  </StyledBox>
);

export default EmployerCard;
