import React, {
  Children,
  memo,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { Box, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import ComponentStep from './Step';
import { useMediaQueryMatches } from '../../../hooks';

interface IStepNavigationProps {
  children: ReactElement<typeof ComponentStep>[];
  nextButtonVisible?: boolean;
  nextButtonLabel?: string;
  prevButtonLabel?: string;
  submitButtonLabel?: string;
  buttonPosition: 'TOP' | 'BOTTOM' | 'HIDE';
  submitCallback?: React.Dispatch<void>;
  submitLoading: boolean;
  stepperFlourish?: boolean;
  flourishLabels?: string[];
  startingStep?: number;
  resetInput?: () => null;
}

const StyledRoot = styled('div')(({ theme }) => ({
  '&.stepNavigation': {
    padding: theme.spacing(3),
    [theme.breakpoints.down('desktopApp')]: {
      padding: 'initial',
    },
    '.steps': {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('desktopApp')]: {
        padding: 'initial',
      },
    },
    '.navigation': {
      width: '100%',
      padding: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('desktopApp')]: {
        padding: theme.spacing(1),
      },
    },
  },
  '.mobileStepFlourish': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    '*': {
      width: 'calc(100% / 3)',
      textAlign: 'center',
      lineHeight: 1.25,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      '&:not(nth-of-type(2)': {
        fontSize: 10,
        opacity: 0.5,
      },
    },
  },
  // '.mobileStepFlourish > *:not(nth-of-type(2)': {
  //   fontSize: 10,
  //   opacity: 0.5,
  // },
}));

const StepNavigation = ({
  children,
  nextButtonVisible = true,
  nextButtonLabel = 'Next',
  prevButtonLabel = 'Previous',
  submitButtonLabel = 'Submit',
  buttonPosition = 'BOTTOM',
  submitCallback,
  submitLoading = false,
  stepperFlourish = false,
  flourishLabels = [],
  resetInput = () => null,
  startingStep = 0,
}: IStepNavigationProps) => {
  const [currentStep, setCurrentStep] =
    useState<number>(startingStep);
  const count = Children.count(children);
  const [buttonState, setButtonState] = useState<boolean>(false);
  const { isDesktop } = useMediaQueryMatches();

  const prevButtonPressed = () => {
    if (currentStep !== 0) {
      resetInput();
      setCurrentStep(currentStep - 1);
      setButtonState(false);
    }
  };

  useEffect(() => {
    if (!submitLoading) {
      setButtonState(false);
    }
  }, [submitLoading]);

  const nextButtonPressed = async () => {
    // TODO maybe look at a hook solution for this in the future
    const stepComp: Exclude<
      React.ReactNode,
      boolean | null | undefined
    > = Children.toArray(children)[currentStep];
    const { stepCallback } = stepComp.props;
    if (stepCallback) await stepCallback();

    if (currentStep === count - 1) {
      // I am submitting the whatever that needs to be submitted
      setButtonState(true);
      await submitCallback();
    } else {
      setButtonState(false);
      setCurrentStep(currentStep + 1);
    }
  };

  const renderButtons = () =>
    nextButtonVisible ? (
      <div className="navigation">
        <Button
          variant="filled-secondary"
          className="buttonLeft"
          onClick={prevButtonPressed}
          disabled={currentStep === 0}
        >
          {prevButtonLabel}
        </Button>
        <Button
          startIcon={submitLoading ? <Spinner /> : null}
          variant="filled-primary"
          className="buttonRight"
          onClick={nextButtonPressed}
          disabled={buttonState}
        >
          {currentStep === count - 1
            ? submitButtonLabel
            : nextButtonLabel}
        </Button>
      </div>
    ) : null;

  const renderStepperFlourish = () => {
    if (!stepperFlourish) return null;
    return (
      <Stepper activeStep={currentStep} alternativeLabel>
        {Array(count)
          .fill('')
          .map((_, idx) => {
            const index = `step-${idx}`;
            return (
              <Step key={flourishLabels[idx] || index}>
                <StepLabel>
                  {isDesktop && (
                    <Typography variant={'body1'}>
                      {flourishLabels[idx] || index + 1}
                    </Typography>
                  )}
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
    );
  };

  // This is used to get current file props on the step component
  // for varying prev/next button usage
  const ChildStep = () =>
    isEmpty(children.flat())
      ? null
      : React.cloneElement(children.flat()[currentStep], {
          next: nextButtonPressed,
          prev: prevButtonPressed,
        });

  // const ChildStep = children[currentStep];

  const showButtons = buttonPosition !== 'HIDE';
  return (
    <StyledRoot className="stepNavigation">
      {renderStepperFlourish()}
      {buttonPosition === 'TOP' &&
        showButtons &&
        renderButtons()}
      {!isDesktop && stepperFlourish && (
        <Box className={'mobileStepFlourish'}>
          <Typography>
            {flourishLabels[currentStep - 1]}
          </Typography>
          <Typography variant={'body1'}>
            {flourishLabels[currentStep]}
          </Typography>
          <Typography>
            {flourishLabels[currentStep + 2]}
          </Typography>
        </Box>
      )}
      <div className="steps">
        <ChildStep />
      </div>
      {buttonPosition === 'BOTTOM' &&
        showButtons &&
        renderButtons()}
    </StyledRoot>
  );
};

export default memo(StepNavigation);
