import React, { useState, useEffect, useCallback } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import map from 'lodash/map';
import { withApplicantProfileDialog } from 'hocs';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Spinner } from 'components/shared';
import { GET_APPROVED } from 'api';
import styles from 'styles/ApplicantsDashboard';
import ApplicantsSearch from './ApplicantsSearch';
import ListRowItem from './ListRowItem';
import ListCardItem from './ListCardItem';
import { APPROVED_TAB, GRID_LAYOUT } from './data';
import { filterActiveApplicants, getApplicantUserId } from './utils';

const StyledRoot = styled('div')(styles);

const ApprovedApplicants = (props) => {
  const { jobId, layoutType, openApplicantProfileDialog, webUrl } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [list, setList] = useState([]);

  const [getApproved, { data, loading = true }] = useLazyQuery(GET_APPROVED, {
    variables: { jobId: parseInt(jobId) },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    getApproved();
  }, []);

  useEffect(() => {
    if (data?.approved) {
      setList(filterActiveApplicants(data.approved));
    }
  }, [JSON.stringify(data)]);

  const onSuggestionSelect = (suggestion) => {
    if (suggestion) {
      const { data: applicantData } = suggestion;
      setList([{ ...applicantData }]);
    }
  };

  const onSearchClear = () => {
    getApproved();
  };

  const openApplicantProfile = useCallback(
    (applicant, extraParams = {}) => {
      const { dialogProps } = extraParams;
      const applicantUID = getApplicantUserId(applicant);

      openApplicantProfileDialog({
        id: applicantUID,
        jobId,
        jobLink: webUrl,
        withActions: false,
        ...dialogProps
      });
    },
    [jobId, webUrl, openApplicantProfileDialog]
  );

  const applicantsProps = {
    jobId,
    webUrl,
    type: APPROVED_TAB,
    onSelect: openApplicantProfile
  };

  return (
    <StyledRoot role="tabpanel" className="boardContainer">
      <div className="searchContainer">
        <ApplicantsSearch
          jobId={jobId}
          type={APPROVED_TAB}
          onSelect={onSuggestionSelect}
          onClear={onSearchClear}
        />
      </div>
      <h2 className="boardTitle">Approved Applicants</h2>
      <Box position="relative">
        {loading && (
          <div className="boardLoader">
            <Spinner />
          </div>
        )}
        {isDesktop ? (
          <div
            className={clsx(
              'boardListContainer',
              layoutType === GRID_LAYOUT && 'boardListContainer_grid'
            )}
          >
            {map(list, (applicant, i) =>
              layoutType === GRID_LAYOUT ? (
                <ListCardItem key={`applicant__${i}`} data={applicant} {...applicantsProps} />
              ) : (
                <ListRowItem key={`applicant__${i}`} data={applicant} {...applicantsProps} />
              )
            )}
          </div>
        ) : (
          <div className="boardListContainer">
            {map(list, (applicant, i) => (
              <ListRowItem key={`applicant__${i}`} data={applicant} {...applicantsProps} />
            ))}
          </div>
        )}
      </Box>
    </StyledRoot>
  );
}

ApprovedApplicants.propTypes = {
  jobId: PT.number.isRequired,
  layoutType: PT.string.isRequired,
  openApplicantProfileDialog: PT.func.isRequired,
  webUrl: PT.string.isRequired
};

export default withApplicantProfileDialog(ApprovedApplicants);
