import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getUserId } from 'utils';
import { Box, styled } from 'components';
import { Button, IconButton, Input } from 'components/shared';
import { useForm } from 'components/form';
import { MdClose } from 'components/icons';
import {
  GET_EMAILS_FOR_NOTIFICATIONS,
  ADD_EMAIL_FOR_NOTIFICATIONS,
  REMOVE_EMAIL_FOR_NOTIFICATIONS
} from 'api';

const StyledRoot = styled('div')(({ theme }) => ({
  '& .title': {
    marginBottom: 10,
    fontSize: 12
  },
  '& .submitBtn': {
    width: 110,
    alignSelf: 'flex-start',
    fontSize: 13,
    color: theme.palette.common.white
  },
  '& .emailItem': {
    padding: '12px 24px',
    paddingRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.common.white,
    borderRadius: 20,
    backgroundColor: theme.palette.grey['600']
  },
  '& .emailItem__deleteBtn': {
    width: 'auto',
    height: 'auto',
    marginLeft: 38,
    padding: 0,
    fontSize: 19,
    color: theme.palette.common.white
  }
}));

const ManageEmails = () => {
  const employerUserId = Number(getUserId());
  const { $, set, withValidation } = useForm({
    initial: { email: '' },
    validations: { email: ['presence', 'email'] }
  });

  const [fetchEmails, { data: emailsData }] = useLazyQuery(GET_EMAILS_FOR_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network'
  });
  const allEmails = emailsData?.getNotificationsType || [];

  const [postEmail] = useMutation(ADD_EMAIL_FOR_NOTIFICATIONS, {
    update(cache, { data: { postEmailsForNotifications } }) {
      cache.modify({
        fields: {
          getNotificationsType(refs) {
            return [...refs, { __ref: cache.identify(postEmailsForNotifications) }];
          }
        }
      });
    }
  });
  const [deleteEmail] = useMutation(REMOVE_EMAIL_FOR_NOTIFICATIONS, {
    refetchQueries: [GET_EMAILS_FOR_NOTIFICATIONS]
  });

  const addNewEmail = withValidation(({ email }) => {
    postEmail({ variables: { email, employerUserId, notificationsType: 'schedule' } });
    set({ email: '' });
  });

  const removeEmail = (email) => {
    deleteEmail({ variables: { email, employerUserId } });
  };

  useEffect(() => {
    fetchEmails({ variables: { employerUserId } });
  }, []);

  const change = (e, { name }) => set(name, e.target.value);

  return (
    <StyledRoot>
      <h3 className="title">
        Add any emails that you wish to send out scheduling notifications to. You may add up to 3
        additional emails.
      </h3>
      {allEmails.length < 3 && (
        <Box mb="34px" display="flex">
          <Input
            id="email-input"
            {...$('email', change)}
            label=""
            type="email"
            variant="textfield"
            withHelperText
            testID="manage-emails-input"
          />
          <Button
            className="submitBtn"
            // disabled={allEmails.length >= 3}
            onClick={addNewEmail}
            testID="manage-emails-submit-button"
          >
            Add Email
          </Button>
        </Box>
      )}
      <Box display="flex" gap="25px" flexWrap="wrap">
        {allEmails.map(({ email, id }) => (
          <div key={`item__${id}`} className="emailItem">
            {email}
            <IconButton
              className="emailItem__deleteBtn"
              onClick={() => removeEmail(email)}
              testID={`manage-emails-delete-button-${email}`}
            >
              <MdClose fontSize="inherit" color="inherit" />
            </IconButton>
          </div>
        ))}
      </Box>
    </StyledRoot>
  );
}

ManageEmails.propTypes = {};

export default ManageEmails;
