import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import map from 'lodash/map';
import find from 'lodash/find';
import { CONTACT_EMAIL, numberToUsdFormatter } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useCommonUI, useEmployerProfileQuery } from 'hooks';
import { Box, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import styles from 'styles/Dashboard/EmployerPlans';
import { GET_BASE_PLANS } from 'api';

const StyledRoot = styled('div')(styles);

const plans = [
  {
    id: 1,
    descr: 'For organizations with less than 500 employees',
    features: [
      { jobPostings: true, descr: 'Our candidates are vetted and specialists in their field.' },
      {
        title: 'WT Matching',
        descr: 'We match candidates to your needs like no other job board.'
      },
      {
        title: 'Team Management',
        descr: 'Manage your team and their responsibilities easier.'
      },
      {
        title: 'Candidate Screening',
        descr: 'Actual human candidate screening for the best results possible.'
      }
    ]
  },
  {
    id: 2,
    descr: 'For organizations with less than 3k employees',
    bestValue: true,
    features: [
      { jobPostings: true, descr: 'Our candidates are vetted and specialists in their field.' },
      {
        title: 'WT Matching',
        descr: 'We match candidates to your needs like no other job board.'
      },
      {
        title: 'Team Management',
        descr: 'Manage your team and their responsibilities easier.'
      },
      {
        title: 'Candidate Screening',
        descr: 'Actual human candidate screening for the best results possible.'
      }
    ]
  },
  {
    id: 3,
    descr: 'For organizations with more than 3k employees',
    features: [
      {
        title: 'Everything from Premium',
        descr: ''
      },
      { jobPostings: true, descr: 'We match candidates to your needs like no other job board.' },
      {
        title: 'Dedicated WT Specialist',
        descr: 'Get the help you need from one of our specialists to manage your account.'
      }
    ]
  }
];

const ALLOWED_IDS = [1, 2, 3];

const EmployerPlans = () => {
  const { openUpdatePlanModal } = useCommonUI();

  const { subscriptionInfo } = useEmployerProfileQuery();
  const { currentPlanId } = subscriptionInfo || {};

  const [basePlans, setBasePlans] = useState([]);

  const [fetchBasePlans, { loading: plansLoading = true }] = useMutation(GET_BASE_PLANS, {
    onCompleted: (data) => {
      if (data?.basePlans?.length)
        setBasePlans(data?.basePlans.filter(({ id }) => ALLOWED_IDS.indexOf(id) !== -1));
    }
  });

  useEffect(() => {
    fetchBasePlans();
  }, []);

  if (plansLoading || !basePlans.length) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Spinner size={24} />
      </Box>
    );
  }

  return (
    <StyledRoot className="container">
      <div className="bgPurple">
        <div className="section titleSection">
          <h2 className="plans__title">Get your company in shape. Quickly</h2>
          <p className="plans__text">Plans start at $199/mo and grow with you</p>
        </div>
      </div>
      <div className="section">
        <div className="plansItems">
          {map(plans, ({ id, descr, features, bestValue }, i) => {
            const basePlan = find(basePlans, { id });
            return basePlan ? (
              <div key={`plan__${i}`} className="planItem">
                {bestValue && (
                  <div className="planItem__bestLabelContainer">
                    <div className="planItem__bestLabel">Best Value</div>
                  </div>
                )}
                <div className="planItem__header">
                  <h3 className="planItem__title">{basePlan.name}</h3>
                  <p className="planItem__use">{descr}</p>
                </div>
                <div className="planItem__body">
                  <div className="planItem__price">{`${numberToUsdFormatter.format(
                    basePlan.price / 100
                  )}/month`}</div>
                  <div className="planItem__features">
                    {map(
                      features,
                      ({ title: featureTitle, descr: featureDescr, jobPostings = false }, key) => {
                        if (jobPostings) {
                          if (basePlan?.restrictions?.numberOfJobPosting) {
                            return (
                              <div key={`feature__${key}`} className="planItem__feature">
                                <h4 className="planItem__featureTitle">{`${
                                  basePlan.restrictions.numberOfJobPosting
                                } Job Posting${
                                  basePlan.restrictions.numberOfJobPosting > 1 ? 's' : ''
                                }`}</h4>
                                <p className="planItem__featureDescr">{featureDescr}</p>
                              </div>
                            );
                          }
                          return null;
                        }
                        return (
                          <div key={`feature__${key}`} className="planItem__feature">
                            <h4 className="planItem__featureTitle">{featureTitle}</h4>
                            <p className="planItem__featureDescr">{featureDescr}</p>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <Box textAlign="center">
                    <Button
                      variant="filled-primary"
                      className="planItem__btn"
                      disabled={currentPlanId === id}
                      onClick={() => openUpdatePlanModal({ planId: id })}
                      testID={`plan-button-${i}`}
                    >
                      {currentPlanId === id ? "Your Plan" : "Select Plan"}
                    </Button>
                  </Box>
                </div>
              </div>
            ) : null;
          })}
        </div>
        <div className="plansContact">
          <h2 className="plansContact__title">Not seeing what you need?</h2>
          <p className="plansContact__text">Let's have a conversation</p>
          <Box textAlign="center">
            <Button
              variant="filled-primary"
              component="a"
              href={`mailto:${CONTACT_EMAIL}`}
              className="planItem__btn"
              onClick={() => {
                track('Contact Us Clicked');
              }}
            >
              Contact Us
            </Button>
          </Box>
        </div>
      </div>
    </StyledRoot>
  );
}

export default EmployerPlans;
