import useEmployerOnboarding from '../../../hooks/useEmployerOnboarding';
import { useEffect } from 'react';
import { Button, Spinner } from 'components/shared';
import { Box } from 'components';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { getRoutes } from '../../../utils';
import { IOnboarding } from '../../../api/types/queries/onboarding.types';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FlexRow from '../../../components/shared/FlexRow';
import CheckMark from '../../../components/icons/CheckmarkIcon';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('desktopApp')]: {
    padding: theme.spacing(2),
  },
  '.onboardingContainer': {
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
      justifyContent: 'inherit',
      '> button:last-of-type': {
        marginTop: theme.spacing(1),
      },
    },
  },
  '.onboardingCards': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    margin: `${theme.spacing(2)} 0`,
  },
  '.onboardingCard': {
    padding: theme.spacing(2),
    maxWidth: '25%',
    flexGrow: 1,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: '100%',
    },
  },
  '.onboardingStats': {
    padding: '10px 0 20px',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  '.onboardingStatsTitle': {
    flexGrow: 1,
  },
  '.statLine': {
    fontSize: 18,
    '& > span:first-of-type': {
      fontWeight: 800,
    },
    '& > span:last-of-type': {
      color: theme.palette.anomaly.main,
    },
  },
  '.checkActive': {
    height: 17.5,
    width: 17.5,
    svg: {
      fill: theme.palette.anomaly.main,
    },
  },
  '.checkInactive': {
    height: 17.5,
    width: 17.5,
    svg: {
      fill: theme.palette.grey['300'],
    },
  },
}));

const Onboarding = () => {
  const navigate = useNavigate();
  const routes = getRoutes();

  const {
    onboarding: { all: onboardingAPI },
  } = useEmployerOnboarding();

  useEffect(() => {
    onboardingAPI.get();
  }, []);

  const { data = {} } = onboardingAPI;

  const { onboardings = {} } = data;

  const {
    results,
  } = onboardings;

  if (onboardingAPI.loading) {
    return <Spinner />;
  }

  if (!results) {
    return (
      <Box>
        <Typography variant={'h3'} color={'primary'}>
          Nothing here!
        </Typography>
        <Typography variant={'body1'} color={'secondary'}>
          You do not have any onboardings at this time, please
          create one in the top right.
        </Typography>
      </Box>
    );
  }

  const handleViewDetail = (onboarding: IOnboarding) => () => {
    const routesWithId = getRoutes({
      id: onboarding.id,
    });
    navigate(routesWithId.employer.onboarding.edit);
  };

  return (
    <StyledBox>
      <FlexRow
        justifyContent={'flex-end'}
        className={'onboardingContainer'}
      >
        <Button
          variant={'filled-primary'}
          onClick={() =>
            navigate(routes.employer.onboarding.documents.list)
          }
        >
          Documents
        </Button>
        <Button
          variant={'filled-primary'}
          onClick={() =>
            navigate(routes.employer.onboarding.resources.list)
          }
        >
          Resources
        </Button>
        <Button
          variant={'filled-primary'}
          onClick={() =>
            navigate(routes.employer.onboarding.create)
          }
        >
          New Onboarding
        </Button>
      </FlexRow>
      <Box className={'onboardingCards'}>
        {results.map((onboarding: IOnboarding) => (
          <Paper
            className={'onboardingCard'}
            variant={'outlined'}
          >
            <Typography
              variant={'h2'}
              className={'onboardingStatsTitle'}
            >
              {onboarding.name}
            </Typography>
            <Box className={'onboardingStats'}>
              <Typography className={'statLine'}>
                <span>Job Type:</span>
                <span>{onboarding.jobType.name}</span>
              </Typography>
              <Typography className={'statLine'}>
                <span>All Locations:</span>
                {onboarding.allLocations ? (
                  <span className={'checkActive'}>
                    <CheckMark />
                  </span>
                ) : (
                  <span className={'checkInactive'}>
                    <CheckMark />
                  </span>
                )}
              </Typography>
              <Typography className={'statLine'}>
                <span>Assigned:</span>
                <span> {onboarding.assignments.length}</span>
              </Typography>
              <Typography className={'statLine'}>
                <span>Resources:</span>
                <span>
                  {' '}
                  {onboarding.resourceAssociations.length}
                </span>
              </Typography>
              <Typography className={'statLine'}>
                <span>Documents:</span>
                <span>
                  {' '}
                  {onboarding.documentAssociations.length}
                </span>
              </Typography>
            </Box>
            <Button
              onClick={handleViewDetail(onboarding)}
              variant={'filled-primary'}
            >
              View Details
            </Button>
          </Paper>
        ))}
      </Box>
    </StyledBox>
  );
};

export default Onboarding;
