import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon7 = (props) => (
    <SvgIcon viewBox="0 0 37.18 31.76" {...props}>
      <polygon
        points="33.58 0 22.94 10.21 37.18 20.55 6.67 11.01 33.58 0"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="3.6 31.76 14.24 21.55 0 11.21 30.51 20.75 3.6 31.76"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon7;
