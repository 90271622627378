import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { map, reduce, filter, forEach } from 'lodash';
import { Box } from 'components';
import { Button, CheckBoxGroup, Spinner } from 'components/shared';
import { useForm } from 'components/form';
import { GET_SKILLS } from 'api';

const formatInitList = (checked = []) => reduce(
    checked,
    (res, { name, skill_id }) => {
      if (name) {
        res[name] = { name, skill_id, checked: true };
        return res;
      }
      return res;
    },
    {}
  );

const prepareDataForSubmit = (obj) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filter(obj, (o) => o.checked).map(({ checked, ...rest }) => ({ ...rest }));

const SkillsForm = ({
  CheckBoxGroupProps,
  form,
  fieldName,
  onChange,
  onSubmit,
  onSkip,
  withSkipBtn,
  withSubmitBtn
}) => {
  const listProp = form[fieldName] || [];
  const stringifiedArr = JSON.stringify(listProp);
  const initCheckedList = useMemo(() => formatInitList(listProp), [stringifiedArr]);
  const [options, setOptions] = useState(() => [
  ]);

  const [fetchSkills, { loading: skillsLoading = true, data: skillsData }] = useLazyQuery(
    GET_SKILLS,
    {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all'
    }
  );

  const { attrs, set } = useForm({
    initial: { ...initCheckedList },
    validations: {}
  });

  useEffect(() => {
    fetchSkills();
  }, []);

  const stringifiedSkillsData = JSON.stringify(skillsData);
  useEffect(() => {
    if (skillsData?.getSkills) setOptions(skillsData.getSkills || []);
  }, [stringifiedSkillsData]);

  useEffect(() => {
    const obj = { ...attrs };

    forEach(obj, (_, key) => {
      obj[key] = { ...obj[key], checked: false };
    });

    forEach(initCheckedList, (_, key) => {
      obj[key] = { ...initCheckedList[key] };
    });

    set(obj);
  }, [initCheckedList]);

  const stringifiedOptions = JSON.stringify(options);
  const stringifiedAttrs = JSON.stringify(attrs);
  const checkBoxesData = useMemo(
    () =>
      map(options, (opt) => ({
        ...opt,
        value: opt.name,
        label: opt.name,
        checked: attrs[opt.name]?.checked || false
      })),
    [stringifiedOptions, stringifiedAttrs]
  );

  const submit = () => {
    onSubmit({ ...form, [fieldName]: prepareDataForSubmit(attrs) });
  };

  const skip = () => {
    onSkip();
  };

  const handleSelect = (data, checked) => {
    const nextList = { ...attrs };
    if (!checked) nextList[data.name] = { ...nextList[data.name], checked: false };
    else nextList[data.name] = { skill_id: data.skill_id, name: data.name, checked: true };
    set(nextList);

    if (onChange) onChange({ [fieldName]: prepareDataForSubmit(nextList) });
  };

  // function handleValueChange(e) {
  //   setVal(e.target.value);
  // }

  // function handleSkillAdd(e) {
  //   e.preventDefault();
  //   if (trim(val).length > 0) {
  //       const prevState = { ...listAttr };
  //       if (!prevState[val]) {
  //         prevState[val] = { name: val, skill_id: null, checked: true, custom: true };
  //         setOptions([ { name: val, skill_id: null }, ...options ]);
  //       }
  //       set('list', prevState);
  //       if (onChange) onChange({ [fieldName]: prepareDataForSubmit(prevState) });
  //       setVal('');
  //   }
  // }

  return (
    <>
      {/*<form onSubmit={handleSkillAdd}>*/}
      {/*<Input*/}
      {/*id="skill-input"*/}
      {/*value={val}*/}
      {/*placeholder="Type in a skill"*/}
      {/*variant="outlined"*/}
      {/*endAdornment={(*/}
      {/*<InputAdornment>*/}
      {/*<ButtonBase type="submit" className={classes.addBtn}>Add</ButtonBase>*/}
      {/*</InputAdornment>*/}
      {/*)}*/}
      {/*className={classes.input}*/}
      {/*onChange={handleValueChange}*/}
      {/*{...InputProps}*/}
      {/*/>*/}
      {/*</form>*/}
      {skillsLoading ? (
        <Box py="10px" display="flex" justifyContent="center">
          <Spinner size={48} />
        </Box>
      ) : (
        <CheckBoxGroup
          data={checkBoxesData}
          aria-label="skills"
          sx={{ marginBottom: '16px', overflow: 'auto' }}
          checkboxesContainerProps={{
            sx: { overflowY: 'auto' }
          }}
          onChange={handleSelect}
          analyticParams={{
            key: 'Skills focused',
            trigger: 'focus'
          }}
          {...CheckBoxGroupProps}
        />
      )}
      {withSubmitBtn && (
        <Button
          variant="filled-primary"
          width="100%"
          height={50}
          onClick={submit}
          testID="next-button"
        >
          Next
        </Button>
      )}
      {withSkipBtn && (
        <Button
          variant="text"
          sx={{ width: '100%', height: 50, fontSize: 14, lineHeight: '22px' }}
          onClick={skip}
          testID="skip-button"
        >
          Skip
        </Button>
      )}
    </>
  );
}

SkillsForm.propTypes = {
  CheckBoxGroupProps: PropTypes.objectOf(PropTypes.any),
  fieldName: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  InputProps: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSkip: PropTypes.func,
  withSkipBtn: PropTypes.bool,
  withSubmitBtn: PropTypes.bool
};

SkillsForm.defaultProps = {
  CheckBoxGroupProps: {},
  fieldName: 'skills',
  InputProps: {},
  onChange: () => {},
  onSubmit: () => {},
  onSkip: () => {},
  withSkipBtn: true,
  withSubmitBtn: true
};

export default SkillsForm;
