import React from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import DownloadIcon from '@mui/icons-material/Download';
import { PAGES } from '../data';
import { RetentionNavigation } from '../../Retention/components/RetentionNavigation';

const CategoriesNavigator = (props: {
  currentPage: string;
  onSelect: (a: string) => void;
}) => {
  const { currentPage, onSelect } = props;

  return (
    <RetentionNavigation
      buttons={[
        {
          disabled: false,
          label: 'Path',
          onClick: () => onSelect(PAGES.path),
          testId: 'navigate-to-employee-path',
          Icon: FlagIcon,
          selected: currentPage === PAGES.path,
        },
        {
          disabled: false,
          label: 'Trends',
          onClick: () => onSelect(PAGES.trends),
          testId: 'navigate-to-employee-trends',
          Icon: TrendingUpIcon,
          selected: currentPage === PAGES.trends,
        },
        {
          disabled: false,
          label: 'Ratings',
          onClick: () => onSelect(PAGES.ratings),
          testId: 'navigate-to-employee-ratings',
          Icon: ThumbsUpDownIcon,
          selected: currentPage === PAGES.ratings,
        },
        {
          disabled: false,
          label: 'Salary',
          onClick: () => onSelect(PAGES.salary),
          testId: 'navigate-to-employee-salary',
          Icon: PriceChangeIcon,
          selected: currentPage === PAGES.salary,
        },
        {
          disabled: false,
          label: 'Connections',
          onClick: () => onSelect(PAGES.connections),
          testId: 'navigate-to-employee-connections',
          Icon: GroupAddIcon,
          selected: currentPage === PAGES.connections,
        },
        {
          disabled: false,
          label: 'Classes',
          onClick: () => onSelect(PAGES.classes),
          testId: 'navigate-to-employee-classes',
          Icon: LocalLibraryIcon,
          selected: currentPage === PAGES.classes,
        },
        {
          disabled: false,
          label: 'Jobs',
          onClick: () => onSelect(PAGES.jobs),
          testId: 'navigate-to-employee-jobs',
          Icon: WorkIcon,
          selected: currentPage === PAGES.jobs,
        },
        {
          disabled: false,
          label: 'Learnings',
          onClick: () => onSelect(PAGES.quizzes),
          testId: 'navigate-to-employee-quizzes',
          Icon: SchoolIcon,
          selected: currentPage === PAGES.quizzes,
        },
        {
          disabled: false,
          label: 'Onboarding',
          onClick: () => onSelect(PAGES.onboarding),
          testId: 'navigate-to-employee-onboarding',
          Icon: DownloadIcon,
          selected: currentPage === PAGES.onboarding,
        },
      ]}
    />
  );
};
export default CategoriesNavigator;
