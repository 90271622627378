import React from 'react';
import FlexRow from '../FlexRow';
import { Button } from '../index';

const CustomStepperButtonRow = React.memo(({ prev, next }) => (
  <FlexRow>
    {prev && (
      <Button variant={'filled-primary'} onClick={prev}>
        Prev
      </Button>
    )}
    {next && (
      <Button variant={'filled-primary'} onClick={next}>
        Next
      </Button>
    )}
  </FlexRow>
));

export default CustomStepperButtonRow;
