import { Icon, Typography } from '@mui/material';
import { Box, styled } from 'components';
import React from 'react';
import { employeeCardStyles } from 'styles/Retention/Location/employeeCard';
import ForkRight from '@mui/icons-material/ForkRight';
import {
  Avatar,
  AvatarHealthScoreFlourish,
  SpringModal,
} from 'components/shared';
import ApproveBadge from '../ApproveBadge';

interface StyledBoxProps {
  verified: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>(
  employeeCardStyles,
);

interface IEmployeeCardArgs {
  employeeId: number;
  pendingSurveyCount?: number;
  name: string;
  requestedBadgesCount?: number;
  imageUrl: string;
  healthScore?: number;
  currentJobType: string;
  futureJobType?: string;
  verified: boolean;
  // used to show additional information to employers
  enhanced?: boolean;
  // used to show pertinent information to employees
  renderEmployeeActionRow?: JSX.Element;
  classes?: {
    cardContainer?: 'cardContainer';
    parentWrapper?: 'parentWrapperOverride';
  };
}

const EmployeeCard = ({
  employeeId,
  pendingSurveyCount,
  pathCompletion,
  name,
  requestedBadgesCount,
  imageUrl,
  healthScore,
  currentJobType,
  futureJobType,
  verified,
  enhanced = false,
  renderEmployeeActionRow,
  classes = {},
}: IEmployeeCardArgs) => {
  const renderEnhancedContent = () =>
    enhanced ? (
      <Box className={'enhanced-container'}>
        <Box className={'enhanced-path'}>
          <Icon>
            <ForkRight />
          </Icon>
          <Typography>
            {(pathCompletion * 100).toFixed(0)} %
          </Typography>
        </Box>
        <Box className={'enhanced-survey'}>
          <Typography>Pending Surveys: </Typography>
          <Typography>{pendingSurveyCount}</Typography>
        </Box>
        <Box className={'enhanced-badges'}>
          {requestedBadgesCount > 0 ? (
            <SpringModal
              buttonText={`Badge Requests ${requestedBadgesCount}`}
            >
              <ApproveBadge employeeId={employeeId} />
            </SpringModal>
          ) : (
            <Typography>
              Badge Requests {requestedBadgesCount}
            </Typography>
          )}
        </Box>
      </Box>
    ) : null;

  return (
    <StyledBox
      verified={verified}
      className={classes.cardContainer}
    >
      <Box
        key={name}
        className={`parentWrapper ${classes.parentWrapper}`}
      >
        {/* more options button is commented out until functionality is included */}
        {/* <Icon className={'moreIconWrapper'}>
            <MdMoreHoriz />
          </Icon> */}
        {!verified && (
          <Typography
            className={'notVerified'}
            variant={'subtitle1'}
          >
            Not Verified
          </Typography>
        )}
        <Box>
          <Avatar
            url={imageUrl}
            height={96}
            width={96}
            alt={`profile image of ${name}`}
            type={'EMPLOYEE'}
            flourishLocation={enhanced && 'BOTTOM'}
            flourishRenderer={
              enhanced && (
                <AvatarHealthScoreFlourish
                  healthScore={healthScore}
                />
              )
            }
            classes={{
              containerOverride: 'avatar-containerOverride',
            }}
          />
        </Box>
        <Typography className="memberName">{name}</Typography>
        <Typography className="memberTitle">
          Start: {currentJobType}
        </Typography>
        <Typography>Goal: {futureJobType || 'N/A'}</Typography>
        {renderEnhancedContent()}
        {renderEmployeeActionRow}
      </Box>
    </StyledBox>
  );
};

export default EmployeeCard;
