import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { useAlerts, useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import Typography from '@mui/material/Typography';
import {
  FieldValues,
  useFieldArray,
  useForm,
  Controller,
} from 'react-hook-form';
import {
  Button,
  CheckBox,
  DatePicker,
  Input,
  Spinner,
} from 'components/shared';
import { MdExclamation } from 'components/icons';
import { UPDATE_USER_EXP, getOnboardingStatistics } from 'api';
import styles from 'styles/Onboarding';
import get from 'lodash/get';
import PrivacyClauseButton from './PrivacyClauseButton';
import FlexRow from '../../shared/FlexRow';

const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

const StyledBox = styled(Box)(styles);

interface FormValue {
  currentJob: boolean;
  name: string;
  pos: string;
  startDate: Date | null;
  endDate: Date | null;
}
interface FormValues {
  items: FormValue[];
}

const commonPickerProps = {
  inputFormat: 'MM/yyyy',
  views: ['year', 'month'],
  mask: '__/____',
  disableMaskedInput: false, // fix broken mask for month/year view
  disableFuture: true,
};

const Experience = ({
  calledToasts,
  setCalledToasts,
  formKey,
  form,
  onGoToNextStep,
}) => {
  // form
  // onGoToNextStep
  const [rootError, setRootError] = useState(null);
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { showToast } = useAlerts();

  const [postEmployeeExperience, { loading = false }] =
    useMutation(UPDATE_USER_EXP);

  const getDataShowToast = async () => {
    if (calledToasts.indexOf(formKey) === -1) {
      const info =
        (await getOnboardingStatistics(form.zip)) || {};
      if (info.randomPerson) {
        setCalledToasts([...calledToasts, formKey]);
        showToast({
          title: `${info.randomPerson} just got hired in your area! You can be next! 🙂`,
          icon: () => <MdExclamation />,
          flourishText: 'This could be you!',
          position: { top: false, right: false },
        });
      }
    }
  };

  useEffect(() => {
    getDataShowToast();
  }, []);

  const formRowBase: FormValue = {
    currentJob: false,
    name: '',
    pos: '',
    startDate: null,
    endDate: null,
  };

  const {
    register,
    control,
    formState,
    handleSubmit,
    setValue,
    resetField,
  } = useForm<FormValues>({
    defaultValues: {
      items: [formRowBase],
    },
  });

  const { errors } = formState;

  const { fields, append } = useFieldArray({
    name: 'items',
    control,
  });

  const addItem = () => append(formRowBase);

  const validateDate =
    (type: 'start' | 'end', index: number) =>
    (date, { items }: FieldValues) => {
      if (
        items[index].endDate == null &&
        !items[index].currentJob
      ) {
        setRootError(
          'Either End Date or Current Job is required',
        );
      } else {
        setRootError(null);
      }
      if (type === 'start') {
        if (date == null) return 'Start Date is required';
        if (!items[index].endDate && !items[index].currentJob)
          return null;
        if (
          get(items, `${index}.endDate`, null) &&
          dayjs(date.$d).isAfter(
            dayjs(items[index].endDate, null),
          )
        ) {
          return 'Start Date must be before End Date';
        }
      }
      if (type === 'end') {
        // reset current job checkbox
        setValue(`items.${index}.currentJob`, false);
        if (get(items, `${index}.startDate`, null) == null)
          return 'Start Date is required';
        if (dayjs(date).isBefore(dayjs(items[index].startDate)))
          return 'End Date must be after Start Date';
      }
      return null;
    };

  const validateCurrentJob = (index) => (isCurrentJob) => {
    // reset end date when clicking current job
    if (isCurrentJob) {
      resetField(`items.${index}.endDate`);
    }
  };

  const doSubmit = async (fieldValues: FieldValues) => {
    if (rootError) {
      return null;
    }

    const experience = fieldValues.items.reduce(
      (acc, cur) => [
        ...acc,
        {
          name: cur.name.trim(),
          pos: cur.pos.trim(),
          startDate: dayjs(cur.startDate).format(
            SERVER_DATE_FORMAT,
          ),
          endDate: cur.currentJob
            ? null
            : dayjs(cur.endDate).format(SERVER_DATE_FORMAT),
          currentJob:
            cur.endDate != null ? false : cur.currentJob,
        },
      ],
      [],
    );

    if (experience.length) {
      await postEmployeeExperience({
        variables: { experience },
      });
      onGoToNextStep();
    } else {
      onGoToNextStep();
    }
    return null;
  };

  const skip = async () => {
    onGoToNextStep(undefined, { skipped: true });
  };

  return (
    <StyledBox>
      <form
        className="pageContent"
        style={{ width: '100%', marginBottom: 40 }}
      >
        {fields.map((field, index) => (
          <Box key={field.id}>
            <Controller
              name={`items.${index}.currentJob`}
              control={control}
              rules={{
                validate: validateCurrentJob(index),
              }}
              render={({ field: rowField }) => (
                <CheckBox
                  {...rowField}
                  // {...register(`items.${index}.currentJob`)}
                  label="Current Job"
                  labelPlacement="start"
                  checked={rowField.value}
                  sx={{
                    '& .MuiCheckbox-root': {
                      width: 25,
                      height: 25,
                    },
                    '& .MuiFormControlLabel-label': {
                      marginLeft: 0,
                      marginRight: '15px',
                      fontSize: 14,
                    },
                  }}
                  testID={`current-job-${index}`}
                />
              )}
            />
            <Input
              {...register(`items.${index}.name`, {
                required: 'Company Name is required',
              })}
              variant="textfield"
              id={`work-name-input-${index}`}
              label={'Company'}
              inputProps={{ required: true }}
              FormControlProps={{
                sx: { marginBottom: '20px' },
              }}
              testID={`work-name-input-${index}`}
              withHelperText
              error={get(
                errors,
                `items[${index}].name.message`,
                '',
              )}
              helperText={get(
                errors,
                `items[${index}].name.message`,
                '',
              )}
            />
            <Input
              {...register(`items.${index}.pos`, {
                required: 'Position Title is required',
              })}
              variant="textfield"
              id={`work-name-input-${index}`}
              label={'Position'}
              inputProps={{ required: true }}
              FormControlProps={{
                sx: { marginBottom: '20px' },
              }}
              testID={`work-position-input-${index}`}
              withHelperText
              error={get(
                errors,
                `items[${index}].pos.message`,
                '',
              )}
              helperText={get(
                errors,
                `items[${index}].pos.message`,
                '',
              )}
            />
            <FlexRow>
              <Controller
                name={`items.${index}.startDate`}
                control={control}
                rules={{
                  required:
                    'Start date is required and should be before the end date if not current job',
                  validate: validateDate('start', index),
                }}
                render={({ field: rowField }) => (
                  <DatePicker
                    {...rowField}
                    value={rowField.value}
                    label="Start Month"
                    {...commonPickerProps}
                    error={get(
                      errors,
                      `items[${index}].startDate.message`,
                      '',
                    )}
                  />
                )}
              />
            </FlexRow>
            <FlexRow>
              <Controller
                name={`items.${index}.endDate`}
                control={control}
                // this field is validated conditionally
                // inside validateDate
                render={({ field: rowField }) => (
                  <DatePicker
                    {...rowField}
                    value={rowField.value}
                    onChange={(date) => {
                      setValue(`items[${index}].endDate`, date);
                      resetField(`items[${index}].currentJob`);
                    }}
                    label="End Month"
                    {...commonPickerProps}
                    error={get(
                      errors,
                      `items[${index}].endDate.message`,
                      '',
                    )}
                  />
                )}
              />
            </FlexRow>
          </Box>
        ))}
        <div>
          <FlexRow>
            {rootError && (
              <Typography variant={'body2'} color={'error'}>
                {rootError}
              </Typography>
            )}
          </FlexRow>
          <FlexRow justifyContent={'center'}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={addItem}
              fullWidth
              sx={(theme) => ({
                marginBottom: theme.spacing(2),
              })}
            >
              Add Another
            </Button>
          </FlexRow>
          <p
            className="primaryText"
            style={{ marginBottom: 22, fontStyle: 'italic' }}
          >
            Look at me now!
          </p>
          <p
            className="secondaryText"
            style={{ marginBottom: 16 }}
          >
            Experience boosts your chances of getting a job by
            45%, don’t worry if you don’t have any, we have tools
            to help you get some!
          </p>
          <Button
            variant="filled-primary"
            endIcon={loading && <Spinner size={24} />}
            disabled={loading}
            className="nextBtn"
            sx={{ mb: isDesktop ? '25px' : '13px' }}
            onClick={handleSubmit(doSubmit)}
            testID="onboarding-next-button"
          >
            Next
          </Button>
          <Button
            variant="filled-primary"
            endIcon={loading && <Spinner size={24} />}
            disabled={loading}
            className="nextBtn"
            sx={{ mb: isDesktop ? '25px' : '13px' }}
            onClick={skip}
            testID="onboarding-next-button"
          >
            No Experience Yet
          </Button>
          <PrivacyClauseButton />
        </div>
      </form>
    </StyledBox>
  );
};

export default Experience;
