import React from 'react';
import { SvgIcon } from 'components';


const GrowRecentBadgeIcon = (props) => (
    <SvgIcon className="growBadgeIcon" viewBox="0 0 43.3 33.5" {...props}>
      <g>
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          points="21.7,0 28.9,12.5 43.3,12.5 33.1,30.3 30,25 34.2,17.8
		25.8,17.8 21.7,10.5 17.5,17.8 9.1,17.8 13.3,25 10.3,30.3 0,12.5 14.4,12.5 	"
        />
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          points="21.7,15.8 31.9,33.5 25.8,33.5 21.7,26.3 17.5,33.5
		11.4,33.5 	"
        />
      </g>
    </SvgIcon>
  )

export default GrowRecentBadgeIcon;
