import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { styled } from 'components';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.stateSalaryCard': {
    padding: 15,
    borderRadius: 5,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('desktopApp')]: {
      padding: 12,
      borderRadius: 8,
    },
  },
  '& .stateSalaryCard__title': {
    marginBottom: 20,
    fontSize: 22,
    fontWeight: 100,
    color: 'inherit',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 8,
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '130%',
    },
  },
  '& .stateSalaryCard__rate': {
    fontSize: 30,
    color: theme.palette.quaternary.main,
    '& sup': {
      fontSize: 20,
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 4,
      fontSize: 35,
      fontWeight: 700,
      lineHeight: '130%',
      '& sup': {
        fontSize: 35,
        verticalAlign: 'initial',
      },
    },
  },
  '& .stateSalaryCard__avg': {
    marginBottom: 5,
    color: 'inherit',
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '150%',
    },
  },
  '& .stateSalaryCard__salary': {
    marginBottom: 5,
    color: 'inherit',
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '150%',
    },
  },
  '& .best': {
    color: theme.palette.success.main,
  },
  '& .worst': {
    color: theme.palette.error.main,
  },
}));

const StateSalaryCard = (props) => {
  const { hourSalary, isAboveAvg, rate, state } = props;
  return (
    <StyledRoot className="stateSalaryCard">
      <h5 className="stateSalaryCard__title">{state}</h5>
      <div className="stateSalaryCard__rate">
        {rate}
        <sup>%</sup>
      </div>
      <div className="stateSalaryCard__salary">
        <span className={clsx(isAboveAvg ? 'best' : 'worst')}>
          {isAboveAvg ? 'ABOVE' : 'BELOW'}
        </span>{' '}
        Natl Avg
      </div>
      <div className="stateSalaryCard__salary">{`about ${hourSalary}/hr`}</div>
    </StyledRoot>
  );
};

StateSalaryCard.propTypes = {
  isAboveAvg: PT.bool,
  hourSalary: PT.oneOfType([PT.string, PT.number]),
  rate: PT.number,
  state: PT.string.isRequired,
};

StateSalaryCard.defaultProps = {
  isAboveAvg: false,
  hourSalary: 0,
  rate: 0,
};

export default StateSalaryCard;
