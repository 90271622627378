import React, {
  useCallback,
  useRef,
  useImperativeHandle,
  useMemo,
  memo,
  forwardRef,
} from 'react';
import PT from 'prop-types';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import format from 'date-fns/format';
import { useEmployerProfileQuery, useCommonUI } from 'hooks';
import { Box, styled } from 'components';
import {
  Button,
  Deck,
  IconButton,
  Spinner,
} from 'components/shared';
import {
  MdClose,
  MdCheck,
  MdArrowBack,
  MdArrowForward,
  MdStarBorder,
  MdTrendingUp,
} from 'components/icons';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import Typography from '@mui/material/Typography';
import ApplicantActionsPopup from './ApplicantActionsPopup';
import { CHAT_MESSAGE_TEMPLATE } from './data';
import {
  getCandidateActionName,
  getApplicantProfile,
  getApplicantUserId,
} from './utils';
import { useAppStoreSelector } from '../../store';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.container': {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .swiperLabel': {
    position: 'absolute',
    top: 12,
    zIndex: 2,
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    borderRadius: 10,
    backgroundColor: '#fff',
  },
  '& .pass': {
    right: 12,
    color: '#BC2626',
  },
  '& .apply': {
    left: 12,
    color: '#529C59',
  },
  '& .mobileApplicantCard__name': {
    marginBottom: 6,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.primary.text,
  },
  '& .mobileApplicantCard__details': {
    // marginBottom: 6,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    // opacity: 0.8,
    color: '#6A6A6A',
  },
  '& .deckWrapper': {
    marginBottom: 15,
  },
  '& .card': {
    width: '100%',
    maxWidth: 382,
  },
  '& .cardButtonContainer': {
    position: 'relative',
    width: '100%',
  },
  '& .cardButton': {
    width: '100%',
    minHeight: 280,
    maxHeight: 600,
    height: 'calc(100vh - 260px)',
    padding: 35,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'grey',
  },
  '& .employeeName': {
    marginBottom: 6,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    color: theme.palette.primary.text,
  },
  '& .employee__details': {
    // marginBottom: 6,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: 0,
    textAlign: 'center',
    // opacity: 0.8,
    color: '#6A6A6A',
  },
  '& .mobileControllers': {
    width: '100%',
    padding: '0 16px 80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .applicantPopupTrigger': {
    position: 'absolute',
    right: 4,
    top: 4,
    zIndex: 1,
    width: 45,
    height: 45,
    fontSize: 34,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.grey['800'],
    opacity: 0.6,
  },
  '.avatarIconContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: -50,
    bottom: 10,
    width: 100,
    height: 70,
    h3: {
      textShadow: '0 2px 2px rgba(0, 0, 0, .25)',
      textAlign: 'center',
    },
  },
  '.avatarIcon': {
    position: 'absolute',
    bottom: -35,
    width: 50,
    height: 50,
    border: '2px solid #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 30,
    color: theme.palette.primary.light,
    borderRadius: '50%',
    backgroundColor: theme.palette.quaternary.main,
  },
}));

const candidateMissingInfoMsg = `Dear Candidate,
Thank you for your interest. I noticed that you do not have any work history listed in
your profile on Worktorch and/or did not post a resume. Once you make an update, I would be happy to review your profile and see if we are a good fit. Alternatively, feel free to apply to any available job on our career site. Just be sure to select Worktorch as your source.
Once again, thank you for your interest.`;

const ApplicantsSwiper = forwardRef((props, ref) => {
  const {
    cancelLoading,
    cards,
    cardIndex,
    jobId,
    onReject,
    onAccept,
    onStar,
    onSkip,
    onReturn,
    onSelect,
    onRejectFromChatSuccess,
    onMoveToNext,
    starredApplicantsByUID,
    webUrl,
  } = props;
  const { openChatModal } = useCommonUI();
  const { profile } = useEmployerProfileQuery();
  const allowEmployeeActions =
    profile?.restrictions?.allowEmployeeActions || false;
  const actionNames = getCandidateActionName(webUrl);
  const { preferences } = useAppStoreSelector(
    ({ preferences: p }) => ({
      preferences: p,
    }),
  );

  const swiperRef = useRef();

  const openChat = useCallback(
    (userId, msg) => {
      openChatModal({
        role: 'employer',
        initialView: 'chat',
        initialChatViewProps: {
          jobId,
          receiverUserId: userId,
          afterApplicantReject: onRejectFromChatSuccess,
          suggestMessageTemplate: CHAT_MESSAGE_TEMPLATE,
          automatedMessage: msg ? candidateMissingInfoMsg : '',
        },
      });
    },
    [jobId, onRejectFromChatSuccess, openChatModal],
  );

  const handleReturn = () => {
    const { item, index } = swiperRef.current
      ? swiperRef.current.swipeBack()
      : {};
    onReturn(item, { index });
  };

  const handleSkip = () => {
    const nextIdx = cardIndex + 1;
    swiperRef.current?.jumpToCardIndex(nextIdx);
    onSkip(nextIdx);
  };

  const handleMoveToNextSteps = () => {
    const { item, index } = swiperRef.current
      ? swiperRef.current.swipeRight()
      : {};
    onMoveToNext(item, { index });
  };

  const handleAccept = () => {
    const { item, index } = swiperRef.current
      ? swiperRef.current.swipeRight()
      : {};
    onAccept(item, { index });
  };

  const handleReject = () => {
    const { item, index } = swiperRef.current
      ? swiperRef.current.swipeLeft()
      : {};
    onReject(item, { index });
  };

  const handleStar = () => {
    const applicant = cards[cardIndex];
    const applicantUID = getApplicantUserId(applicant);
    const isStarred =
      starredApplicantsByUID[applicantUID] ||
      applicant.starredJob;
    onStar(cards[cardIndex], !isStarred);
  };

  const handleSelect = useCallback(
    (item, index) => {
      onSelect(item, { index });
    },
    [onSelect],
  );

  const handleSwipeLeft = useCallback(
    (item, index) => {
      if (allowEmployeeActions) onReject(item, { index });
    },
    [onReject, allowEmployeeActions],
  );

  const handleSwipeRight = useCallback(
    (item, index) => {
      if (allowEmployeeActions) onAccept(item, { index });
    },
    [onAccept, allowEmployeeActions],
  );

  useImperativeHandle(ref, () => ({
    swipeBack: () => swiperRef.current.swipeBack(),
    handleAccept: () => handleAccept(),
    handleReject: () => handleReject(),
  }));

  const deckLeftLabel = useMemo(
    () => <div className="swiperLabel pass">Pass</div>,
    [],
  );
  const deckRightLabel = useMemo(
    () => <div className="swiperLabel apply">Apply</div>,
    [],
  );
  const deckClassNames = useMemo(
    () => ({
      deckWrapper: 'deckWrapper',
      card: 'card',
    }),
    [],
  );

  const getAppliedDate = (createdAt) => {
    if (createdAt) {
      const date = new Date(createdAt);
      const appliedToday = isToday(date);
      const appliedYesterday = isYesterday(date);
      if (appliedToday) return 'Applied today';
      if (appliedYesterday) return 'Applied yesterday';
      return format(date, "'Applied' MMM dd");
    }
    return '';
  };

  const renderDeckItem = useCallback(
    (applicant, { index }) => {
      const applicantProfile =
        getApplicantProfile(applicant) || {};
      const { imageUrl = '', name = '' } = applicantProfile;
      const userId = getApplicantUserId(applicant);

      return (
        <div className="cardButtonContainer">
          <ApplicantActionsPopup
            id={name || userId}
            onChat={() => openChat(userId, false)}
            onMissingInfo={() => openChat(userId, true)}
            onMoveToNext={handleMoveToNextSteps}
            withAccept={false}
            withReject={false}
            withStar={false}
            withMissingInfo
            disableMoveToNext={!allowEmployeeActions}
          />
          <Button
            className="cardButton"
            style={{
              backgroundImage: `url("${
                (preferences.showProfileImages && imageUrl) ||
                defaultEmployeeImg
              }")`,
            }}
            onClick={() => handleSelect(applicant, index)}
            testID="candidates-table-deck-card-button"
          >
            <Box
              position="relative"
              zIndex={1}
              textAlign="center"
            >
              {!preferences.showProfileImages && (
                <Typography color={'primary'} variant={'p'}>
                  Profile image hidden by employer
                </Typography>
              )}
              {applicant?.isWTMatch && (
                <div className="avatarIconContainer">
                  <div className="avatarIcon">
                    <MdTrendingUp
                      fontSize="inherit"
                      color="inherit"
                    />
                  </div>
                  <Typography variant={'h3'} color={'white'}>
                    WT Match
                  </Typography>
                </div>
              )}
              {/*<div className={classes.mobileApplicantCard__trendIcon} style={{ marginBottom: 10 }}>*/}
              {/*<MdTrendingUp fontSize="inherit" color="inherit" />*/}
              {/*</div>*/}
              {/*<div className={classes.mobileApplicantCard__label}>WorkTorch Match</div>*/}
              {/* <div className={classes.mobileApplicantCard__name}>{name}</div>
          <div className={classes.mobileApplicantCard__details}>
            {zip && <span>{zip} &bull;</span>} <span>{getAppliedDate(createdAt)}</span>
          </div> */}
              {/* <div className={classes.mobileApplicantCard__motto}>{motto}</div> */}
            </Box>
            {/* <div className={classes.mobileApplicantCard__overlay} /> */}
          </Button>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleSelect,
      openChat,
      allowEmployeeActions,
      onMoveToNext,
      handleMoveToNextSteps,
    ],
  );

  const controllersCommonProps = {
    variant: 'outlined',
    color: 'primary',
    withTooltip: true,
  };

  const { createdAt } = cards[cardIndex] || {};
  const { name, zip } =
    getApplicantProfile(cards[cardIndex]) || {};

  return (
    <StyledRoot
      className="container"
      // justifyContent={!deck.length && !applicantsLoading ? 'center' : 'flex-start'}
    >
      <div style={{ display: !cards.length ? 'none' : 'block' }}>
        <Deck
          ref={swiperRef}
          items={cards}
          initIndex={cardIndex}
          renderItem={renderDeckItem}
          leftLabel={deckLeftLabel}
          rightLabel={deckRightLabel}
          classNames={deckClassNames}
          onSwipeLeft={handleSwipeLeft}
          onSwipeRight={handleSwipeRight}
        />
        <Box mb="40px">
          <div className="employeeName">{name}</div>
          <div className="employee__details">
            {zip && <span>{zip} &bull;</span>}{' '}
            <span>{getAppliedDate(createdAt)}</span>
          </div>
        </Box>
        {cards.length ? (
          <div className="mobileControllers">
            <IconButton
              {...controllersCommonProps}
              aria-label="previous"
              toolTipProps={{ title: 'Previous' }}
              disabled={
                cancelLoading ||
                !allowEmployeeActions ||
                cardIndex === 0
              }
              sx={{ width: 42, height: 42 }}
              onClick={handleReturn}
              testID="candidates-table-mobile-retract-button"
            >
              {cancelLoading ? (
                <Spinner size={24} />
              ) : (
                <MdArrowBack />
              )}
            </IconButton>
            <IconButton
              {...controllersCommonProps}
              aria-label={actionNames.reject.title}
              toolTipProps={{ title: actionNames.reject.title }}
              disabled={!allowEmployeeActions}
              className="deckAction"
              sx={{ ml: '14px', width: 42, height: 42 }}
              onClick={handleReject}
              testID="candidates-table-mobile-reject-button"
            >
              <MdClose fontSize="inherit" />
            </IconButton>
            <IconButton
              {...controllersCommonProps}
              aria-label={actionNames.accept.title}
              toolTipProps={{ title: actionNames.accept.title }}
              disabled={!allowEmployeeActions}
              classes="deckAction big"
              sx={{ ml: '14px', fontSize: 32 }}
              onClick={handleAccept}
              testID="candidates-table-mobile-accept-button"
            >
              <MdCheck fontSize="inherit" />
            </IconButton>
            <IconButton
              {...controllersCommonProps}
              aria-label={actionNames.star.title}
              toolTipProps={{ title: actionNames.star.title }}
              disabled={!allowEmployeeActions}
              sx={{ ml: '14px', width: 42, height: 42 }}
              onClick={handleStar}
              testID="candidates-table-mobile-star-button"
            >
              <MdStarBorder />
            </IconButton>
            <IconButton
              {...controllersCommonProps}
              aria-label="review later"
              toolTipProps={{ title: 'Review Later' }}
              disabled={cardIndex === cards.length - 1}
              sx={{ ml: '14px', width: 42, height: 42 }}
              onClick={handleSkip}
              testID="candidates-table-mobile-review-later-button"
            >
              <MdArrowForward />
            </IconButton>
          </div>
        ) : null}
      </div>
    </StyledRoot>
  );
});

ApplicantsSwiper.propTypes = {
  cancelLoading: PT.bool.isRequired,
  cards: PT.arrayOf(PT.any).isRequired,
  cardIndex: PT.number.isRequired,
  jobId: PT.number.isRequired,
  onReject: PT.func.isRequired,
  onAccept: PT.func.isRequired,
  onStar: PT.func.isRequired,
  onSkip: PT.func.isRequired,
  onReturn: PT.func.isRequired,
  onSelect: PT.func.isRequired,
  onRejectFromChatSuccess: PT.func,
  onMoveToNext: PT.func.isRequired,
  starredApplicantsByUID: PT.objectOf(PT.bool),
  webUrl: PT.string.isRequired,
};

ApplicantsSwiper.defaultProps = {
  onRejectFromChatSuccess: () => {},
  starredApplicantsByUID: {},
};

export default memo(ApplicantsSwiper);
