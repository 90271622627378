import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon10 = (props) => (
    <SvgIcon viewBox="0 0 32.7 30.22" {...props}>
      <path
        d="M17.62,9.63c-.11-3.2-.43-6.39-1.05-9.56-3.58,.19-7.17,.48-10.56,.84L.78,12.63c4.73-.52,9.89-.91,14.86-1.05,1.09-.03,1.97-.84,1.98-1.95Z"
        fill="#8682be"
      />
      <path
        d="M17.9,0c1.07,3.04,1.63,9.69,1.59,14.89,0,1.15,.78,2.11,1.98,2.11,3.58-.18,7.18-.46,10.59-.82C34.48,5.01,30.08,0,17.9,0Z"
        fill="#8682be"
      />
      <path
        d="M11.23,13.22c-3.58,.18-7.18,.46-10.59,.82-2.42,11.17,1.98,16.17,14.16,16.17-1.07-3.04-1.63-9.69-1.59-14.89,.01-1.15-.78-2.11-1.98-2.11Z"
        fill="#8682be"
      />
      <path
        d="M15.07,20.59c.11,3.2,.43,6.39,1.05,9.56,3.58-.18,7.17-.48,10.56-.84l5.24-11.72c-4.73,.52-9.89,.91-14.86,1.05-1.09,.03-1.97,.84-1.99,1.95Z"
        fill="#8682be"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon10;
