import React, { useContext } from 'react';
import { RetentionContext } from '../../../../components/Retention/utils';
import SendNotificationFlow from '../../../../components/Retention/components/SendNotificationFlow';

const Custom = () => {
  const ctx = useContext(RetentionContext);
  return (
    <SendNotificationFlow
      templateName={ctx.interactionNames.RETENTION_CUSTOM}
      isCustomMessage
    />
  );
};

export default Custom;
