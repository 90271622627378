import React from 'react';
import { styled } from 'components';
import { qaAttr } from 'utils';

interface IHeaderTemplate {
  children?: JSX.Element;
  className?: string;
  centerElements?: JSX.Element[];
  leftElements?: JSX.Element[];
  rightElements?: JSX.Element[];
  title?: string | JSX.Element;
  titleQaId?: string;
}

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('desktopApp')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    border: 'none',
  },
  '& .headerCenter': {
    flex: 1,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(theme.desktopBreakPoint)]: {
      justifyContent: 'center',
    },
  },
  '& .headerRight': {
    display: 'flex',
    alignItems: 'center',
    '& .headerElement': {
      '&:not(:last-child)': {
        marginRight: theme.spacing(2.5),
        [theme.breakpoints.down('desktopApp')]: {
          marginRight: theme.spacing(1),
        },
      },
    },
  },
  '& .title': {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0.67,
    lineHeight: '26px',
    textAlign: 'left',
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: 16,
      lineHeight: '18px',
      // color: '#000'
    },
  },
}));

const HeaderTemplate = ({
  children,
  className,
  centerElements,
  leftElements,
  rightElements,
  title,
  titleQaId,
}: IHeaderTemplate) => (
  <StyledDiv className={className}>
    {leftElements && (
      <div className="headerLeft">{leftElements}</div>
    )}
    <div className="headerCenter">
      {title && (
        <h1
          {...qaAttr(titleQaId)}
          className="headerElement title"
        >
          {title}
        </h1>
      )}
      {centerElements}
      {children}
    </div>
    {rightElements && (
      <div className="headerRight">{rightElements}</div>
    )}
  </StyledDiv>
);

export default HeaderTemplate;
