import { Box, Switch } from 'components';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { GET_PREFERENCES, UPDATE_PREFERENCES } from 'api';
import SectionLayout from '../SectionLayout';
import FlexRow from '../../shared/FlexRow';
import Button from '../../shared/Button';
import { useAppStoreSelector } from '../../../store';
import {
  IGetPreferences,
  IPreferencesFeatures,
  IUpdatePreferences,
} from '../../../api/types/queries/preferences';

const StyledBox = styled(Box)(({ theme }) => ({
  '.colorSelectionRow': {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      marginRight: theme.spacing(1.5),
      fontSize: 14,
    },
  },
}));

interface ISectionProps {
  sectionName: string;
  jumpLink: string;
  expanded: boolean;
  onExpand: () => void;
}

const Preferences = (props: ISectionProps) => {
  const { setPreferences, preferences } =
    useAppStoreSelector<IPreferencesFeatures>(
      ({ preferences: p, setPreferences: sp }) => ({
        preferences: p,
        setPreferences: sp,
      }),
    );

  const { loading: preferencesLoading, preferencesError } =
    useQuery<IGetPreferences>(GET_PREFERENCES, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getPreferences) {
          // only set if fetch exists, otherwise let the default preferences manage itself until acted on.
          setPreferences(data.getPreferences.preferences);
        }
      },
    });

  const [
    savePreferences,
    { savePreferencesLoading, savePreferencesError },
  ] = useMutation<IUpdatePreferences>(UPDATE_PREFERENCES, {
    onCompleted: (data) => {
      setPreferences(data.updatePreferences.preferences);
    },
  });

  const updatePreferenceState =
    (key: keyof IPreferencesFeatures) => (e) => {
      setPreferences({
        ...preferences,
        [key]: e.target.checked,
      });
    };

  const handleSavePreferences = async () => {
    savePreferences({
      variables: {
        preferences,
      },
    });
  };

  const isLoading = preferencesLoading || savePreferencesLoading;

  return (
    <SectionLayout {...props}>
      <StyledBox>
        <FlexRow className={'colorSelectionRow'}>
          <Typography variant={'h4'} color={'white'}>
            Show Applicant Profile Images
          </Typography>
          <Switch
            checked={preferences.showProfileImages}
            color="primary"
            onClick={updatePreferenceState('showProfileImages')}
            style={{
              marginRight: 10,
            }}
            inputProps={{
              'aria-label': 'Theme',
            }}
            disabled={isLoading}
            classes={{
              root: 'themeToggle',
            }}
          />
        </FlexRow>
      </StyledBox>
      <FlexRow>
        {(preferencesError || savePreferencesError) && (
          <Typography variant={'h3'} color={'error'}>
            {preferencesError}
            {savePreferencesError}
          </Typography>
        )}
        <Button
          disabled={isLoading}
          variant="filled-primary"
          onClick={handleSavePreferences}
        >
          Save Preferences
        </Button>
      </FlexRow>
    </SectionLayout>
  );
};

export default Preferences;
