import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import ConnectionProfileDialog from 'components/dialogs/ConnectionProfileDialog';
import { getDisplayName } from 'utils';
import {
  GET_EMPLOYEE_JOBS,
  GET_EMPLOYEES_BY_JOB_TYPE_ID,
  GET_EMPLOYEES_BY_INDUSTRY_ID,
  GET_EMPLOYEE_STARRED,
  POST_EMPLOYEE_STARRED,
  REMOVE_EMPLOYEE_STARRED,
  CAREER_DEV_BY_USER_ID
} from 'api';

export default (WrappedComponent) => {
  const WithConnections = (props) => {
    const [sameConnections, setSameConnections] = useState([]);
    const [futureConnections, setFutureConnections] = useState([]);
    const [starredConnections, setStarredConnections] = useState([]);
    const [connectionCareerDevModal, setConnectionCareerDevModal] = useState({ ids: [], index: 0 });
    const [connectionsLoading, setConnectionsLoading] = useState(false);
    const [connectionByProfileIdLoading, setConnectionByProfileIdLoading] = useState(null);

    const [fetchEmployeeJobs, { data: employeeJobsData }] = useLazyQuery(GET_EMPLOYEE_JOBS, {
      fetchPolicy: 'cache-and-network'
    });
    const suggestedJobs = employeeJobsData?.employeeJobs || [];

    const [fetchConnectionsByJobTypeId] = useLazyQuery(GET_EMPLOYEES_BY_JOB_TYPE_ID);
    const [fetchConnectionsByIndustryId] = useLazyQuery(GET_EMPLOYEES_BY_INDUSTRY_ID);
    const [fetchConnectionCareerDev] = useLazyQuery(CAREER_DEV_BY_USER_ID);
    const [fetchStarred] = useMutation(GET_EMPLOYEE_STARRED);
    const [starEmployee] = useMutation(POST_EMPLOYEE_STARRED);
    const [unstarEmployee] = useMutation(REMOVE_EMPLOYEE_STARRED);

    useEffect(() => {
      if (!suggestedJobs.length && connectionCareerDevModal.ids.length)
        fetchEmployeeJobs({ variables: { offset: 0, limit: 10 } });
    }, [connectionCareerDevModal.ids]);

    const formatConnections = (arr) =>
      arr.map((o) => {
        const { user_id, profile_id, name, imageUrl, experience } = o;
        const currentJob = experience.filter((exp) => exp?.currentJob)?.[0] || {};
        const companyInfo = {
          companyName: currentJob?.name || '',
          companyPosition: currentJob?.pos || ''
        };

        return {
          user_id,
          profile_id,
          name,
          imageUrl,
          experience,
          starred: false,
          ...companyInfo
        };
      });

    const formatConnectionsStarredField = (connectionsArr, starredArr) => {
      const starredIds = starredArr.map((o) => o.profile_id);
      return connectionsArr.map((o) => ({ ...o, starred: starredIds.includes(o.profile_id) }));
    };

    const getEmployeesByJobTypeId = async (jobTypeId) => {
      const response = await fetchConnectionsByJobTypeId({ variables: { jobTypeId } });
      const employees = response?.data?.employeesByJobTypeId || [];
      return [employees, formatConnections(employees)];
    };

    const getEmployeesByIndustryId = async (industryId) => {
      const response = await fetchConnectionsByIndustryId({ variables: { industryId } });
      const employees = response?.data?.employeesByIndustryId || [];
      return [employees, formatConnections(employees)];
    };

    const getStarredEmployeesByCareerId = async (careerDevelopmentId) => {
      const response = await fetchStarred({ variables: { careerDevelopmentId } });
      const employees = (response?.data?.getEmployeeStarred || [])
        .map((o) => (o.employeeProfile ? { ...o.employeeProfile } : null))
        .filter(Boolean);
      return [employees, formatConnections(employees)];
    };

    const getConnections = async ({
      careerDevId,
      currentJobTypeId,
      futureJobTypeId,
      careerDevIndustryId
    }) => {
      try {
        setConnectionsLoading(true);
        const [, sameCurJobEmployees] = await getEmployeesByJobTypeId(currentJobTypeId);
        const [, sameFutureJobEmployees] = await getEmployeesByJobTypeId(futureJobTypeId);
        const [, starredEmployees] = await getStarredEmployeesByCareerId(careerDevId);

        if (!sameFutureJobEmployees.length) {
          const [, employeesByIndustryId] = await getEmployeesByIndustryId(careerDevIndustryId);
          setFutureConnections(
            formatConnectionsStarredField(employeesByIndustryId, starredEmployees)
          );
        } else {
          setFutureConnections(
            formatConnectionsStarredField(sameFutureJobEmployees, starredEmployees)
          );
        }

        setSameConnections(formatConnectionsStarredField(sameCurJobEmployees, starredEmployees));
        setStarredConnections(starredEmployees);
      } catch (error) {
        console.error('getConnections: ', error);
      } finally {
        setConnectionsLoading(false);
      }
    };

    const getConnectionCareerDev = async (userId) => {
      if (userId) {
        try {
          const response = await fetchConnectionCareerDev({
            variables: { connectionUserId: Number(userId) }
          });
          const careerDev = response?.data?.connectionCareerDevelopmentByUserId;
          return careerDev;
        } catch (error) {
          console.error('getConnectionCareerDev: ', error);
          return null;
        }
      }
      return null;
    };

    const addConnection = async (connection, careerDevId) => {
      const { profile_id } = connection;

      if (profile_id && careerDevId) {
        setConnectionByProfileIdLoading(profile_id);

        try {
          await starEmployee({
            variables: {
              careerDevelopmentId: Number(careerDevId),
              employeeProfileId: Number(profile_id)
            }
          });

          const newStarred = [...starredConnections, connection];
          const newSameConnections = formatConnectionsStarredField(sameConnections, newStarred);
          const newFutureConnections = formatConnectionsStarredField(futureConnections, newStarred);
          setSameConnections(newSameConnections);
          setFutureConnections(newFutureConnections);
          setStarredConnections(newStarred);
        } catch (error) {
          console.error('addConnection: ', error);
        } finally {
          setConnectionByProfileIdLoading(null);
        }
      } else {
        throw new Error('addConnection: missed required param');
      }
    };

    const removeConnection = async (connection, careerDevId) => {
      const { profile_id } = connection;

      if (profile_id && careerDevId) {
        setConnectionByProfileIdLoading(profile_id);

        try {
          await unstarEmployee({
            variables: {
              careerDevelopmentId: Number(careerDevId),
              employeeProfileId: Number(profile_id)
            }
          });

          const newStarred = [...starredConnections].filter((o) => o.profile_id !== profile_id);
          const newSameConnections = formatConnectionsStarredField(sameConnections, newStarred);
          const newFutureConnections = formatConnectionsStarredField(futureConnections, newStarred);
          setSameConnections(newSameConnections);
          setFutureConnections(newFutureConnections);
          setStarredConnections(newStarred);
        } catch (error) {
          console.error('removeConnection: ', error);
        } finally {
          setConnectionByProfileIdLoading(null);
        }
      } else {
        throw new Error('removeConnection: missed required param');
      }
    };

    const showConnectionProfile = (userIds, index = 0, meta) => {
      setConnectionCareerDevModal({ ids: userIds, index, ...meta });
    };

    const closeConnectionProfile = () => {
      setConnectionCareerDevModal({ ids: [], index: 0 });
    };

    const { ids, index, careerDevId, ...rest } = connectionCareerDevModal;

    return (
      <>
        <WrappedComponent
          {...props}
          addConnection={addConnection}
          connectionsLoading={connectionsLoading}
          connectionByProfileIdLoading={connectionByProfileIdLoading}
          formatConnections={formatConnections}
          formatConnectionsStarredField={formatConnectionsStarredField}
          futureConnections={futureConnections}
          getConnections={getConnections}
          getConnectionCareerDev={getConnectionCareerDev}
          removeConnection={removeConnection}
          sameConnections={sameConnections}
          starredConnections={starredConnections}
          showConnectionProfile={showConnectionProfile}
        />
        {!!ids.length && (
          <ConnectionProfileDialog
            isOpen
            initIndex={index}
            userIds={ids}
            starredUserIds={starredConnections.map((o) => o.user_id)}
            starredProfileIdLoading={connectionByProfileIdLoading}
            suggestedJobs={suggestedJobs}
            onAdd={(careerDev) => {
              if (careerDev?.employeeProfile) {
                const connection = formatConnections([careerDev.employeeProfile])[0];
                addConnection(connection, careerDevId);
              }
            }}
            onRemove={(careerDev) => {
              if (careerDev?.employeeProfile) {
                const connection = formatConnections([careerDev.employeeProfile])[0];
                removeConnection(connection, careerDevId);
              }
            }}
            onClose={closeConnectionProfile}
            {...rest}
          />
        )}
      </>
    );
  }

  WithConnections.propTypes = {};

  WithConnections.displayName = `WithConnections(${getDisplayName(WrappedComponent)})`;

  return WithConnections;
};
