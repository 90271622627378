import { gql } from '@apollo/client';
export const GET_PREFERENCES = gql`
  query GetPreferences {
    getPreferences {
      id
      createdAt
      updatedAt
      userId
      preferences
      deletedAt
    }
  }
`
