import React, { useState, useEffect, useMemo, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate, Link } from 'react-router-dom';
import Cards from 'react-credit-cards';
import clsx from 'clsx';
import map from 'lodash/map';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { getUserId, getRoutes, isPaymentEnabled } from 'utils';
import { getMaskedCardNumber, getCommonCardBrandNames } from 'utils/payment';
import { useEmployerProfileQuery, useCommonUI, useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { ConfirmationDialog } from 'components/dialogs';
import { GET_ALL_CC, DELETE_CC, PAUSE_PLAN, CANCEL_PLAN } from 'api';
import styles from 'styles/Profile/EmployerProfile';
import SectionLayout from '../SectionLayout';

const StyledRoot = styled('div')(styles);

const confirmationModalTypes = {
  PLAN_CANCEL: 'planCancel',
  PLAN_PAUSE: 'planPause',
  CARD_DELETE: 'cardDelete'
};

const ROUTES = getRoutes();

const planFeatures = [
  'Team Management',
  'Job Post Analytics',
  'Automatic Scheduling',
  'Employee Analytics',
  'WT Matching'
];

const ManagePlan = (props) => {
  const { expanded, fetchEmployerProfile, jumpLink, onExpand, sectionName } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const navigate = useNavigate();
  const { openPaymentModal } = useCommonUI();

  const { profile, subscriptionInfo } = useEmployerProfileQuery();
  const numberOfJobPosting = profile?.restrictions?.numberOfJobPosting || 0;
  const {
    autorenew,
    endDate,
    isFreePlan,
    isPaymentRequired,
    isPlanPaused,
    isPlanCancelable,
    paid,
    planId,
    planName,
    planPrice,
  } = subscriptionInfo || {};
  const planEndDate = endDate ? format(parseISO(endDate), 'MMM dd, yyyy') : null;

  const [[isConfirmDialogOpen, confirmationType, confirmationArgs], setIsConfirmDialogOpen] =
    useState([false, '', {}]);

  const [fetchCards, { data, loading: cardsLoading, refetch: refetchCards }] = useLazyQuery(
    GET_ALL_CC,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const cards = useMemo(() => {
    if (data?.getUserCards?.cardInfo) {
      const parsedData = JSON.parse(data.getUserCards.cardInfo);
      return parsedData?.data || [];
    }
    return [];
  }, [data?.getUserCards]);

  const getCards = () => {
    const userId = Number(getUserId());
    if (userId) {
      fetchCards({ variables: { userId } });
    }
  };

  useEffect(() => {
    getCards();
  }, []);

  const [deleteCard, { loading: deleteCardLoading }] = useMutation(DELETE_CC, {
    onCompleted: () => {
      refetchCards();
    }
  });

  const [pausePlan, { loading: pausePlanLoading }] = useMutation(PAUSE_PLAN, {
    onCompleted: () => {
      fetchEmployerProfile();
    }
  });

  const [cancelPlan, { loading: cancelPlanLoading }] = useMutation(CANCEL_PLAN, {
    onCompleted: () => {
      fetchEmployerProfile();
    }
  });

  const handlePlanUpgrade = () => {
    if (isPaymentRequired) openPaymentModal({ type: 'checkout' });
    else navigate(ROUTES.employer.plans);
  };

  const handlePlanPause = () => {
    pausePlan({ variables: { isPaused: true, planId } });
    setIsConfirmDialogOpen([false, '', {}]);
  };

  const handlePlanUnPause = () => {
    pausePlan({ variables: { isPaused: false, planId } });
  };

  const handlePlanCancel = () => {
    cancelPlan({ variables: { planId } });
    setIsConfirmDialogOpen([false, '', {}]);
  };

  const addPaymentMethod = () => {
    openPaymentModal({ type: 'card_add' });
  };

  const editPaymentMethod = (cardId) => {
    openPaymentModal({ type: 'card_edit', cardId });
  };

  const deletePaymentMethod = () => {
    const userId = parseInt(getUserId());
    if (userId && confirmationArgs.sourceId) {
      deleteCard({ variables: { userId, sourceId: confirmationArgs.sourceId } });
      setIsConfirmDialogOpen([false, '', {}]);
    }
  };

  const confirmationDialogProps = () => {
    switch (confirmationType) {
      case confirmationModalTypes.PLAN_PAUSE: {
        return {
          title: 'Pause plan?',
          cancelBtnName: 'Cancel',
          confirmBtnName: 'Pause',
          onConfirm: handlePlanPause
        };
      }
      case confirmationModalTypes.PLAN_CANCEL: {
        return {
          title: 'Cancel plan?',
          cancelBtnName: 'Back',
          confirmBtnName: 'Confirm',
          onConfirm: handlePlanCancel
        };
      }
      case confirmationModalTypes.CARD_DELETE: {
        return {
          title: 'Delete selected card?',
          cancelBtnName: 'Cancel',
          confirmBtnName: 'Confirm',
          onConfirm: deletePaymentMethod
        };
      }
      default: {
        return {
          title: '',
          cancelBtnName: '',
          confirmBtnName: '',
          onConfirm: () => {}
        };
      }
    }
  };

  const sectionHeader = (
    <div className="managePlan__headerActions">
      {paid && (
        <Button
          variant="text"
          disabled={pausePlanLoading}
          endIcon={pausePlanLoading ? <Spinner size={12} /> : null}
          className="managePlan__headerAction"
          onClick={() =>
            isPlanPaused
              ? handlePlanUnPause()
              : setIsConfirmDialogOpen([true, confirmationModalTypes.PLAN_PAUSE])
          }
          testID="pause-plan-button"
        >
          {isPlanPaused ? 'Unpause Plan' : 'Pause Plan'}
        </Button>
      )}
      {isPlanCancelable && (
        <Button
          variant="text"
          disabled={cancelPlanLoading}
          endIcon={cancelPlanLoading ? <Spinner size={12} /> : null}
          className="managePlan__headerAction managePlan__cancel"
          onClick={() => setIsConfirmDialogOpen([true, confirmationModalTypes.PLAN_CANCEL])}
          testID="cancel-plan-button"
        >
          Cancel Plan
        </Button>
      )}
    </div>
  );

  const supportLink = (
    <Link to={ROUTES.employer.plans} label="Choose A Plan!" className="managePlan__choosePlan">
      Choose A Plan!
    </Link>
  );

  return (
    <SectionLayout
      expanded={expanded}
      onExpand={onExpand}
      jumpLink={jumpLink}
      sectionName={sectionName}
      sectionHeaderContent={isDesktop ? sectionHeader : null}
      sectionHeaderProps={{
        style: { display: 'flex', justifyContent: 'space-between' }
      }}
    >
      <StyledRoot className="section managePlan__container">
        <div className="managePlan__plansSubSection">
          <div className="managePlan__doubleCol">
            <div className="managePlan__col">
              <div className="managePlan__planLabelContainer">
                <div className="label" style={{ marginBottom: 0 }}>
                  Your Plan
                </div>
                {!isDesktop ? sectionHeader : null}
              </div>
              <div className="value managePlan__curPlan">
                <span>{isPlanPaused ? 'No Plan' : planName}</span>
                {isFreePlan ? supportLink : ''}
                {!isPlanPaused && !isFreePlan && (
                  <span className="managePlan__price">{` | ${planPrice}/mo`}</span>
                )}
              </div>
              {!isFreePlan && autorenew && planEndDate && (
                <div className="managePlan__payDay">{`Next Payment on: ${planEndDate}`}</div>
              )}
            </div>
            <div className="managePlan__col">
              <div className="label">{isFreePlan ? 'Common ' : ''}Features</div>
              <div className="managePlan__features">
                {!isFreePlan && numberOfJobPosting && (
                  <div>{`${numberOfJobPosting} Job Postings`}</div>
                )}
                {map(planFeatures, (str, i) => (
                  <div key={`feature__${i}`}>{str}</div>
                ))}
              </div>
            </div>
          </div>
          {isPaymentEnabled() && (
            <Button
              variant="filled-primary"
              className="mainAction"
              onClick={handlePlanUpgrade}
              testID="employer-profile-change-plan-button"
            >
              Upgrade Plan
            </Button>
          )}
        </div>

        <div>
          <div className="managePlan__paymentSubSectionTitle">
            <span className="label">Your Payment Methods | </span>
            <Button
              variant="text"
              className="managePlan__paymentAction"
              onClick={addPaymentMethod}
              testID="employer-profile-add-payment-button"
            >
              add new
            </Button>
          </div>
          <div className="managePlan__paymentMethods">
            {cardsLoading && (
              <div className="managePlan__cards__loaderOverlay">
                <Spinner size={24} />
              </div>
            )}
            {map(cards, ({ id, brand, exp_month, exp_year, last4, name, primary }, i) => {
              const curYear = new Date().getFullYear();
              const curMonth = new Date().getMonth() + 1;
              const isExpired =
                exp_year < curYear || (exp_year === curYear && curMonth > exp_month);
              let status = '';
              if (primary) status = 'Primary';
              else if (isExpired) status = 'Expired';

              return (
                <div key={`paymentItem__${i}`} className="managePlan__paymentItem">
                  <div className="managePlan__paymentItem__header">
                    <div
                      className={clsx('managePlan__cardStatus', isExpired && 'managePlan__expired')}
                    >
                      {status}
                    </div>
                    <div>
                      {!isExpired && (
                        <Button
                          variant="text"
                          className="managePlan__headerAction managePlan__cardAction"
                          onClick={() => editPaymentMethod(id)}
                          testID={`edit-card-button-${i}`}
                        >
                          edit
                        </Button>
                      )}
                      <Button
                        variant="text"
                        disabled={deleteCardLoading}
                        endIcon={deleteCardLoading ? <Spinner size={12} /> : null}
                        className="managePlan__headerAction managePlan__cardAction"
                        onClick={() =>
                          setIsConfirmDialogOpen([
                            true,
                            confirmationModalTypes.CARD_DELETE,
                            { sourceId: id }
                          ])
                        }
                        testID={`delete-card-button-${i}`}
                      >
                        delete
                      </Button>
                    </div>
                  </div>
                  <div className="managePlan__card">
                    <Cards
                      name={name}
                      number={getMaskedCardNumber(brand, last4)}
                      expiry={`${exp_month < 10 ? `0${exp_month}` : exp_month}/${exp_year}`}
                      cvc="***"
                      preview
                      issuer={getCommonCardBrandNames(brand).paymentJs}
                      locale={{ valid: 'MONTH/YEAR' }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </StyledRoot>

      <ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        {...confirmationDialogProps()}
        onClose={() => setIsConfirmDialogOpen([false, '', {}])}
        onCancel={() => setIsConfirmDialogOpen([false, '', {}])}
      />
    </SectionLayout>
  );
}

ManagePlan.propTypes = {
  expanded: PT.bool.isRequired,
  fetchEmployerProfile: PT.func.isRequired,
  jumpLink: PT.string,
  onExpand: PT.func.isRequired,
  sectionName: PT.string.isRequired,
  subsData: PT.object.isRequired
};

ManagePlan.defaultProps = {
  jumpLink: ''
};

export default memo(ManagePlan);
