import { Box, Typography } from '@mui/material';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { styled } from '../../../..';
import { pathAsideStyles } from '../../../../../styles/Retention/Location/path';
import { Spinner } from '../../../../shared';
import { DonutChart } from '../../../../shared/charts';
import EmployeePathStat from './EmployeePathStat';

const StyledBox = styled(Box)(pathAsideStyles);

const pathAside =
  ({ addedEmployeeToPath, setAddedEmployeeToPath, pathStatsByLocationQuery, employeePathStatsQuery }) =>
  () => {

    const {
      data: pathStatsData = {},
      error: pathStatsError,
      loading: pathStatsLoading,
    } = pathStatsByLocationQuery;

    const {
      data: employeePathStats = {},
      error: employeePathStatsError,
      loading: employeePathStatsLoading,
      refetch,
    } = employeePathStatsQuery;

    useEffect(() => {
      if (addedEmployeeToPath) {
        refetch().then(() => {
          setAddedEmployeeToPath(false);
        });
      }
    }, [addedEmployeeToPath]);

    const pathStatsByLocation =
      pathStatsData?.pathStatsByLocation || {};

    const {
      averageCompletionTime = 0,
      estimatedCompletionRate = 0,
      skillsCompleted = 0,
      totalSkills = 0,
    } = pathStatsByLocation;

    const PathStats = () => {
      if (pathStatsLoading) {
        return <Spinner />;
      }
      if (pathStatsError)
        return (
          <Box>
            <Typography>
              There was an error loading your path stats
            </Typography>
          </Box>
        );

      return (
        <Box
          className="path-stat-chart-container"
        >
          <Box>
            <DonutChart
              chartLabels={['incomplete', 'complete']}
              label=""
              data={[averageCompletionTime, 1]}
              height={50}
              width={50}
            />
            <Typography variant="h5">Avg Comp Time</Typography>
          </Box>
          <Box>
            <DonutChart
              chartLabels={['incomplete', 'complete']}
              label=""
              data={[
                skillsCompleted || 0,
                (totalSkills || 1) - skillsCompleted,
              ]}
              height={50}
              width={50}
            />
            <Typography variant="h5">
              Skills Completed
            </Typography>
          </Box>
          <Box>
            <DonutChart
              chartLabels={['incomplete', 'complete']}
              label=""
              data={[estimatedCompletionRate, 1]}
              height={50}
              width={50}
            />
            <Typography variant="h5">Est Comp Rate</Typography>
          </Box>
        </Box>
      );
    };

    const EmployeePathStats = () => {
      if (employeePathStatsLoading) {
        return <Spinner />;
      }
      if (employeePathStatsError)
        return (
          <Box>
            <Typography>
              There was an error loading your employee stats
            </Typography>
          </Box>
        );
      return (
        <Box>
          <Box className="employee-path-stat-title">
            <Typography
              variant="h3"
              sx={{
                marginBottom: 1,
              }}
            >
              Employees
            </Typography>
          </Box>
          {employeePathStatsLoading ? (
            <Spinner />
          ) : (
            <Box
              className="employee-path-row-container"
            >
              {get(
                employeePathStats,
                'employeePathStats.results',
                [],
              ).map((employee) => (
                <EmployeePathStat
                  key={employee.profileImageUrl}
                  {...employee}
                />
              ))}
            </Box>
          )}
        </Box>
      );
    };

    return [
      <StyledBox>
        <Typography variant="h3">Path Statistics</Typography>
        <PathStats />
        <EmployeePathStats />
      </StyledBox>,
    ];
  };
export default pathAside;
