import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION_PERMISSIONS } from 'api';
import { Box, styled } from 'components';
import React, { useEffect, useState, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Spinner } from 'components/shared';
import { useAlerts } from 'hooks';
import styles from 'styles/Retention';
import { getRoutes } from 'utils';
import {
  RetentionContext,
  baseRetentionContext,
} from '../../../components/Retention/utils';
import { IRetentionContext } from '../../../components/Retention/types';

const StyledBox = styled(Box)(styles);

// use this to route and manage context/permissions
const RetentionEntry = () => {
  const [retentionContext, updRetentionContext] =
    useState<IRetentionContext>(baseRetentionContext);
  const [
    permissionsContextLoading,
    setPermissionsContextLoading,
  ] = useState(true);
  const routes = getRoutes();
  const navigate = useNavigate();
  const { setSimpleAlert } = useAlerts();

  const {
    data: organizationPermissions,
    loading: organizationPermissionsLoading,
    error: organizationPermissionsError,
  } = useQuery(GET_ORGANIZATION_PERMISSIONS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const handleNavigationFromPerms = (perms) => {
    if (!perms.accessOrganization) {
      if (perms.accessBrand) {
        navigate(
          `${routes.employer.retention_portal.brand}/${retentionContext.retentionPages.path}`,
        );
      }
      // should not be able to access org, navigate to the nearest access point
      if (!perms.accessBrand && perms.accessLocation) {
        navigate(
          `${routes.employer.retention_portal.location}/${retentionContext.retentionPages.path}`,
        );
      }
      if (!perms.accessLocation) {
        // something weird happened and no access is available for this user nav to employer dashboard
        navigate(routes.employer.dashboard);
      }
    }
  };

  useEffect(() => {
    // because of the cache policy, this will not fetch on
    // every load, watch context to load, when it is, use
    // it to navigate away if the user does not have permissions
    if (retentionContext.organizationId != null) {
      handleNavigationFromPerms(retentionContext);
    }
  }, [retentionContext]);

  const setRetentionContext = (
    ctx: Partial<IRetentionContext>,
  ) => {
    updRetentionContext({ ...retentionContext, ...ctx });
  };

  const contextValue = useMemo(
    () => ({
      ...retentionContext,
      setRetentionContext,
    }),
    [retentionContext],
  );

  useEffect(() => {
    const perms =
      organizationPermissions?.getOrganizationPermissions;
    if (perms) {
      const {
        organization,
        brand,
        location,
        ...orgPermissions
      } = perms;
      setRetentionContext({
        ...orgPermissions,
        organizationName: organization.name,
        brandName: brand.name,
        locationName: location.name,
      });
      setPermissionsContextLoading(false);
    }
  }, [organizationPermissions]);

  if (organizationPermissionsError) {
    setSimpleAlert({
      isOpen: true,
      title: 'There was an Error',
      subtitle:
        'Please contact support to get your organization setup',
      onCancel: () =>
        setSimpleAlert({
          isOpen: false,
        }),
    });
    navigate(routes.employer.dashboard);
  }

  if (
    organizationPermissionsLoading ||
    permissionsContextLoading
  ) {
    return (
      <StyledBox>
        <Spinner />
      </StyledBox>
    );
  }

  if (process.env.ALLOW_RETENTION_EMPLOYER === 'false') {
    // retention is not yet allowable APP WIDE, return to employer dashboard
    navigate(routes.employer.dashboard);
    return null;
  }

  return (
    <RetentionContext.Provider value={contextValue}>
      <StyledBox className={'retention-container-root'}>
        <Outlet />
      </StyledBox>
    </RetentionContext.Provider>
  );
};

export default RetentionEntry;
