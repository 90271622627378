import React from 'react';
import { styled } from 'components';
import { Button } from 'components/shared';

const StyledRoot = styled(Button)(() => ({
  '&.levelBtn': {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .levelBtn__level': {
    lineHeight: 1,
    fontSize: 16,
  },
}));

interface ILevelNavProps {
  level: number;
  onClick: () => void;
  icon?: React.FC;
  iconBefore?: React.FC;
  title?: string;
}

const LevelNavigationButton = (props: ILevelNavProps) => {
  const { level, onClick, icon, iconBefore, title } = props;
  return (
    <StyledRoot
      className="levelBtn"
      onClick={onClick}
      variant={'contained'}
      color={'primary'}
    >
      {iconBefore ? icon : null}
      <span className="levelBtn__level">
        {title || `Role ${level}`}
      </span>
      {!iconBefore ? icon : null}
    </StyledRoot>
  );
};

export default LevelNavigationButton;
