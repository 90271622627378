import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import pullAt from 'lodash/pullAt';
import { Box } from 'components';
import { Button, CheckBoxGroup, Spinner } from 'components/shared';
import { useForm } from 'components/form';
import { GET_INDUSTRY, UPDATE_EMPLOYER_INDUSTRY } from 'api';

const NAME = 'industry';

const IndustryForm = ({ form, onChange, onFinish }) => {
  const [postCompanyIndustry, { loading }] = useMutation(UPDATE_EMPLOYER_INDUSTRY);
  const [
    getIndustry,
    { data: industryData = {}, loading: industryLoading = true }
  ] = useLazyQuery(GET_INDUSTRY, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });
  const { attrs, set, getError, withValidation } = useForm({
    initial: { [NAME]: form[NAME] || [] }
  });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getIndustry();
  }, []);

  useEffect(() => {
    setOptions(industryData?.getIndustry);
  }, [JSON.stringify(industryData)]);

  const handleSelect = (data, checked) => {
    const nextList = [...attrs[NAME]];
    if (!checked) {
      pullAt(nextList, findIndex(nextList, ['name', data.value]));
    } else {
      nextList.push({ id: data.id, name: data.value });
    }
    set(NAME, nextList);
    onChange({ ...form, [NAME]: nextList });
  };

  const next = () => {
    onFinish(
      withValidation((validAttrs) =>
        postCompanyIndustry({ variables: { [NAME]: validAttrs[NAME] } })
      )
    );
  };

  return (
    <>
      {industryLoading ? (
        <Box py="10px" display="flex" justifyContent="center">
          <Spinner size={48} />
        </Box>
      ) : (
        <CheckBoxGroup
          data={map(options, (opt) => ({
            id: opt.industry_id,
            industry_id: opt.industry_id, // for backwards compatibility
            label: opt.name,
            value: opt.name,
            checked: findIndex(form[NAME], ['name', opt.name]) !== -1
          }))}
          disabled={loading}
          error={getError(NAME)}
          withHelperText
          aria-label="industry"
          sx={{ mb: '16px', flex: 1, overflow: 'auto' }}
          checkboxesContainerProps={{
            sx: { overflow: 'auto' }
          }}
          analyticParams={{
            key: 'Employer industry focused (onboarding)',
            trigger: 'focus'
          }}
          onChange={handleSelect}
        />
      )}
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        width="100%"
        height={50}
        onClick={next}
        testID="onboarding-finish-button"
      >
        Finish
      </Button>
    </>
  );
}

IndustryForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired
};

export default IndustryForm;
