import React, { useState } from 'react';
import { Box, styled } from 'components';
import styles from 'styles/Retention/Organization/OverviewAccordion';
import { MdExpandMore, MdExpandLess } from 'components/icons';
import { Typography } from '@mui/material';
import { Button } from '../../../shared';

const StyledRoot = styled(Box)(styles);

const OverviewAccordion = ({ title, children }) => {
  const [showExpandableContent, setShowExpandableContent] =
    useState(true);

  return (
    <StyledRoot className={'wrapper'}>
      <Box className={'sectionHeader'}>
        <Typography variant={'h1'}>{title}</Typography>
        <Button
          onClick={() =>
            setShowExpandableContent(!showExpandableContent)
          }
        >
          {showExpandableContent ? (
            <MdExpandLess />
          ) : (
            <MdExpandMore />
          )}
        </Button>
      </Box>
      {showExpandableContent ? children : null}
    </StyledRoot>
  );
};

export default OverviewAccordion;
