import React from 'react';
import PT from 'prop-types';
import { MdPhone, MdOndemandVideo, MdApartment } from 'components/icons';
import { INTERVIEW_TYPES } from 'utils';

const InterviewTypeIcon = ({ interviewType, iconProps }) => {
  switch (interviewType) {
    case INTERVIEW_TYPES.web:
      return <MdOndemandVideo {...iconProps} />;
    case INTERVIEW_TYPES.inperson:
      return <MdApartment {...iconProps} />;
    case INTERVIEW_TYPES.phone:
      return <MdPhone {...iconProps} />;
    default:
      return null;
  }
}

InterviewTypeIcon.propTypes = {
  interviewType: PT.oneOf([...Object.values(INTERVIEW_TYPES), '']).isRequired,
  iconProps: PT.objectOf(PT.any)
};

InterviewTypeIcon.defaultProps = {
  iconProps: {}
};

export default InterviewTypeIcon;
