import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Pagination,
  Typography,
  styled,
} from '@mui/material';
import { ReturnLink } from 'components/Header';
import { LOCATION_EVENTS } from 'api';
import LocationUpcomingEventCard from 'components/Retention/components/Location/LocationUpcomingEventCard';
import { RetentionContext } from 'components/Retention/utils';
import { Spinner } from 'components/shared';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { upcomingEventsEntryStyles } from 'styles/Retention/Location/upcomingEvents';
import { getRoutes } from 'utils';
import EmployerDetailContainer from '../../../../components/Retention/components/Location/LocationDashboardRenderer';

const ROUTES = getRoutes();

const StyledBox = styled(Box)(upcomingEventsEntryStyles);

const LocationUpcomingEventsEntry = () => {
  const [activePageNumber, setActivePageNumber] = useState(1);
  const eventsPerPage = 12;
  const { locationId: locId } = useContext(RetentionContext);

  const locationId = useLocation()?.state?.locationId || locId;

  const [
    fetchLocationEvents,
    {
      data: locationEventsData,
      loading: locationEventsDataLoading = true,
    },
  ] = useLazyQuery(LOCATION_EVENTS, {
    variables: {
      locationId,
      limit: eventsPerPage,
      offset: 0,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  useEffect(() => {
    fetchLocationEvents();
  }, []);

  if (locationEventsDataLoading) {
    return <Spinner />;
  }

  const upcomingEventsData = locationEventsData?.locationEvents;
  const upcomingEvents = upcomingEventsData?.results;

  const handlePageChange = (e, pageNumber) => {
    fetchLocationEvents({
      variables: {
        locationId,
        limit: eventsPerPage,
        offset: pageNumber - 1,
      },
    });
    setActivePageNumber(pageNumber);
  };

  return (
    <EmployerDetailContainer>
      <StyledBox>
        <ReturnLink
          className="returnButton"
          key="body-back"
          title="back"
          isRouterLink
          to={`${ROUTES.employer.retention_portal.location}/pulse`}
        />

        <Box className="mainContent">
          <Typography variant="h1" className="locationTitle">
            Upcoming Events
          </Typography>
          <Box className="employeeCardWrapper">
            {upcomingEvents?.map((event) => (
              <LocationUpcomingEventCard
                key={`${event.name}${event.eventDate}`}
                event={event}
              />
            ))}
          </Box>
          {upcomingEventsData && upcomingEventsData.total / eventsPerPage > 1 ? (
            <Pagination
              count={Math.ceil(
                upcomingEventsData.total / eventsPerPage,
              )}
              size="large"
              page={
                activePageNumber === 0
                  ? activePageNumber + 1
                  : activePageNumber
              }
              onChange={handlePageChange}
              className="paginationBar"
            />
          ) : null}
        </Box>
      </StyledBox>
    </EmployerDetailContainer>
  );
};

export default LocationUpcomingEventsEntry;
