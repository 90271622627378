import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import ResourceCard from './ResourceCard';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.suggestedQuizzes': {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('desktopApp')]: {
      gridTemplateColumns: '1fr',
      gridGap: 12,
    },
  },
}));

const SuggestedQuizzes = ({ quizzes }) => (
    <StyledRoot className="suggestedQuizzes">
      {quizzes.map(({ name, value }) => (
        <ResourceCard
          key={`sugQuiz__${name}`}
          title={name}
          link={value}
        />
      ))}
    </StyledRoot>
  );

SuggestedQuizzes.propTypes = {
  quizzes: PT.arrayOf(
    PT.shape({
      name: PT.string,
      value: PT.string,
    }),
  ).isRequired,
};

export default SuggestedQuizzes;
