import { gql } from '@apollo/client';

export const GET_BRAND_STATS = gql`
  query GetBrandsStats(
    $organizationId: Int
    $startDate: String
    $endDate: String
  ) {
    getBrandsStats(
      organizationId: $organizationId
      startDate: $startDate
      endDate: $endDate
    ) {
      brands {
        brand {
          id
          logoUrl
          name
        }
        employeePulse {
          total
          value
          valueType
        }
        interactionsScore {
          total
          value
          valueType
        }
        locationCount
        skillsCompleted {
          total
          value
          valueType
        }
      }
    }
  }
`;

export const LOCATION_STATS = gql`
  query LocationsStats(
    $organizationId: Int!
    $brandId: Int
    $startDate: String
    $endDate: String
    $limit: Int
    $offset: Int
  ) {
    locationsStats(
      organizationId: $organizationId
      brandId: $brandId
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
    ) {
      limit
      offset
      pages
      results {
        address
        employeePulse {
          valueType
          value
          total
        }
        interactionsScore {
          total
          value
          valueType
        }
        imageUrl
        name
        skillsCompleted {
          total
          value
          valueType
        }
      }
      total
    }
  }
`;
