import React from 'react';
import { Box } from '@mui/material';
import ComingSoon from '../../../../components/Retention/utils/ComingSoon';

const LocationHealthEntry = () => (
    <Box>
      <ComingSoon />
    </Box>
  );

export default LocationHealthEntry;
