import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import styles from 'styles/Onboarding';
import { employee_steps } from '../data';

const Stepper = ({ step }) => {
  const stepIdx = employee_steps.indexOf(step);
  return (
    <div className="commonStepper">
      {stepIdx === employee_steps.length - 1
        ? 'Last One!'
        : `${stepIdx} of ${employee_steps.length - 1} complete`}
    </div>
  );
};

Stepper.propTypes = {
  step: PT.string.isRequired,
};

const StyledStepper = styled(Stepper)(styles);

export default StyledStepper;
