import React from 'react';
import { styled } from 'components';
import Typography from '@mui/material/Typography';
import MdArrowForward from '@mui/icons-material/ArrowForward';

const StyledRoot = styled('a')(({ theme }) => ({
  '&.resourceCard': {
    display: 'flex',
    background: theme.palette.background.paper,
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: 7,
    [theme.breakpoints.down('desktopApp')]: {
      padding: 15,
      borderRadius: 8,
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.default,
    },
  },
  '& .resourceCard__img': {
    display: 'none',
    minWidth: 130,
    height: 80,
    margin: theme.spacing(1.5),
    objectFit: 'cover',
    borderRadius: 15,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('desktopApp')]: {
      width: 48,
      height: 48,
      minWidth: 'auto',
      borderRadius: 15,
      backgroundColor: theme.palette.grey['200'],
    },
  },
  '& .resourceCard__socialLink': {
    marginRight: 8,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontSize: 20,
  },
}));

interface ResourceCard {
  title: string;
  link: string;
}

const ResourceCard = ({ title, link = '' }: ResourceCard) => (
    <StyledRoot href={link} className="resourceCard">
      <Typography
        variant={'body1'}
        // color={'primary'}
        className="resourceCard__title"
      >
        {title}
      </Typography>
      <MdArrowForward />
    </StyledRoot>
  );

export default ResourceCard;
