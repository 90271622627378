import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'components';
import { qaAttr } from 'utils';
import ButtonBase from './ButtonBase';

type Variant =
  | ''
  | 'filled-primary'
  | 'filled-secondary'
  | 'outlined-secondary'
  | 'outlined-primary'
  | 'text'
  // added in the generic mui types as well to
  // move back towards MUI standards for quicker
  // reference and remembrance
  | 'contained'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

interface ButtonProps {
  component?: string | JSX.Element;
  children?: JSX.Element;
  className?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  isRouterLink?: boolean;
  testID?: string;
  variant?: Variant;
}

const PREFIX = 'StyledButton';
const classes = {
  label: `${PREFIX}-label`,
  startIcon: `${PREFIX}-startIcon`,
  endIcon: `${PREFIX}-endIcon`,
};

const Button = ({
  component = 'button',
  children = null,
  className,
  endIcon = null,
  isRouterLink = false,
  startIcon = null,
  testID,
  ...buttonBaseProps
}: ButtonProps) => {
  return (
    <ButtonBase
      component={isRouterLink ? Link : component}
      className={className}
      {...(testID && qaAttr(testID))}
      {...buttonBaseProps}
    >
      {startIcon || endIcon ? (
        <span className={classes.label}>
          {startIcon && (
            <span className={classes.startIcon}>
              {startIcon}
            </span>
          )}
          {children}
          {endIcon && (
            <span className={classes.endIcon}>{endIcon}</span>
          )}
        </span>
      ) : (
        children
      )}
    </ButtonBase>
  );
};

const StyledButton = styled(Button)(
  ({ theme, variant, height, width }) => ({
    ...(width !== undefined && { width }),
    ...(height !== undefined && { height }),
    '&.Mui-disabled': {
      opacity: 1,
    },
    [`& .${classes.label}`]: {
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
    },
    [`& .${classes.startIcon}`]: {
      marginRight: 8,
      marginLeft: -4,
      display: 'inherit',
      fontSize: 24,
      color: 'inherit',
    },
    [`& .${classes.endIcon}`]: {
      marginRight: -4,
      marginLeft: 0,
      display: 'inherit',
      fontSize: 24,
      color: 'inherit',
    },
    ...(variant === 'text' && {
      padding: 0,
      color: theme.palette.text.primary,
      '&.Mui-disabled': {
        color: theme.palette.grey['400'],
        backgroundColor: 'transparent',
      },
    }),
  }),
);

export default StyledButton;
