import React from 'react';
import dayjs from 'dayjs';
import { truncateText } from 'utils';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import styles from 'styles/Dashboard/JobCard';
import defaultImg from 'assets/img/job_default.png';
import Typography from '@mui/material/Typography';
import { IEmployerJobsAttrs } from '../../../api/types/queries/jobs.types';
import FlexRow from '../../shared/FlexRow';

const StyledRoot = styled(Button)(styles);

interface IJobCardProps {
  job: IEmployerJobsAttrs;
  address?: string;
  routeTo: string;
}

const JobCard = ({ job, address, routeTo }: IJobCardProps) => {
  const {
    active,
    draft,
    createdAt,
    updatedAt,
    closedDate,
    imageUrl = '',
    title = '',
    applicants = {},
  } = job;

  const {
    qmCount = 'N/A',
    total = 'N/A',
    needsActionCount = 'N/A',
    last7DaysApps = 'N/A',
  } = applicants;

  return (
    <StyledRoot
      isRouterLink
      to={routeTo}
      className="card"
      testID={`job-link-${title}`}
    >
      <FlexRow inline className={'card__infoContainer'}>
        <img
          src={imageUrl || defaultImg}
          alt={title}
          className="card__photoAreaSmall"
        />
        <FlexRow
          flexDirection={'column'}
          className={'card__infoContent'}
        >
          <h2 className="card__name">
            {truncateText(title, 12, 0, 40)}
          </h2>
          <Typography variant={'body2'}>
            Job Posted:{' '}
            {dayjs(Number(createdAt)).format('MM/DD/YYYY')}
          </Typography>
          {address && (
            <Typography variant={'body2'}>{address}</Typography>
          )}
        </FlexRow>
      </FlexRow>
      <div className="card__stats">
        {!active ? (
          <Box width="100%">
            <Box mb="5px">Position Closed</Box>
            <Box
              width="100%"
              mb="5px"
              display="flex"
              justifyContent="space-between"
            >
              <div>
                <div className="card__dateTitle">
                  Opening date:
                </div>
                <div className="card__date">
                  {dayjs(Number(createdAt)).format('MM/DD/YYYY')}
                </div>
              </div>
              <div>
                <div className="card__dateTitle">
                  Closing date:
                </div>
                <div className="card__date">
                  {dayjs(Number(closedDate || updatedAt)).format(
                    'MM/DD/YYYY',
                  )}
                </div>
              </div>
            </Box>
            <Box>{`${total || 0} applicant${
              total !== 1 || total !== 'N/A' ? 's' : ''
            }`}</Box>
          </Box>
        ) : (
          <>
            <Box className="card__metrics__total__wrapper">
              <div className="card__metrics__total">
                <span>Total:</span> {total + qmCount}
              </div>
            </Box>
            <Box className={'card__metrics'}>
              <span>
                {total} <span>applied</span>
              </span>
              <span
                className={`anomaly ${
                  qmCount >= 10 ? '__success' : ''
                }`}
              >
                {qmCount} <span>matches</span>
              </span>
              <span
                className={`anomaly ${
                  needsActionCount > 15 ? '__error' : ''
                }`}
              >
                {needsActionCount} <span>todo</span>
              </span>
              <span>
                {last7DaysApps} <span>7 days</span>
              </span>
            </Box>
            {draft && <div className="draftLabel">Draft</div>}
          </>
        )}
      </div>
    </StyledRoot>
  );
};

export default JobCard;
