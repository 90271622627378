import { styled } from '@mui/material/styles';
import { Box } from '../../material-ui';
import {
  IOnboardingResourceOnboarding,
} from '../../../api/types/queries/onboarding.types';
import { SyntheticEvent } from 'react';
import ResourceDefaultPageBackground from './ResourceDefaultPageBackground';
import ResourceTypeTemplateBackground from './ResourceTypeTemplateBackground';
import Typography from '@mui/material/Typography';
import { IconButton } from '../../shared';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

interface IStyledFlowItem {
  lastSlide: boolean;
  firstSlide: boolean;
  hidePrevArrow: boolean;
}

const propertyKeysToExclude: PropertyKey[] = [
  'firstSlide',
  'lastSlide',
  'hidePrevArrow',
];

const StyledFlowItem = styled<IStyledFlowItem>(Box, {
  shouldForwardProp: (prop) =>
    !propertyKeysToExclude.includes(prop),
})(({ theme, lastSlide, firstSlide, hidePrevArrow }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:hover': {
    '.buttonWrapper': {
      opacity: 1,
      bottom: theme.spacing(2),
    },
  },
  '.flowContentContainer': {
    position: 'relative',
    height: 200,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('desktopApp')]: {
      height: 'auto',
      minHeight: 100,
    },
  },
  '.flowBackground': {
    position: 'absolute',
    width: '100%',
    height: 200,
    left: 0,
    top: 0,
    zIndex: -1,
    ...(firstSlide
      ? {
          background: 'rgb(20, 19, 21);',
          border: `2px solid rgb(135, 131, 190)`,
          borderRadius: theme.spacing(1),
          opacity: 0.35,
        }
      : {}),
    ...(lastSlide ? { opacity: 0.35 } : {}),
    [theme.breakpoints.down('desktopApp')]: {
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      height: 'auto',
      minHeight: 85,
    },
  },
  '.sectionActions': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.handleAddNewIcon': {
    fill: lastSlide ? 'transparent' : theme.palette.anomaly.main,
    cursor: 'pointer',
  },
  '.flowArrowIcon': {
    fill: lastSlide ? 'transparent' : theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  '.flowTitle': {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      textAlign: 'center',
    },
  },
  '.buttonWrapper': {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    opacity: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: theme.transitions.create(['bottom', 'opacity'], {
      duration: theme.transitions.duration.standard,
    }),
    ...(!hidePrevArrow
      ? {
          justifyContent: 'end',
        }
      : {}),
  },
  '.deleteIcon': {
    margin: `0 ${theme.spacing(1)}`,
  },
}));

const OnboardingFlowPage = ({
  page,
  index,
  resources,
  home,
  hidePrevArrow,
  hideNextArrow,
  handleMove,
  handleDelete,
  handleAddNew,
}: {
  page: Partial<IOnboardingResourceOnboarding>;
  index?: number;
  resources?: boolean;
  home?: boolean;
  hidePrevArrow?: boolean;
  hideNextArrow?: boolean;
  handleMove?: (
    page: Partial<IOnboardingResourceOnboarding>,
    subtractCount: 0 | 2,
  ) => (event: SyntheticEvent) => void;
  handleDelete?: (
    page: Partial<IOnboardingResourceOnboarding>,
  ) => (event: SyntheticEvent) => void;
  handleAddNew?: (
    page: Partial<IOnboardingResourceOnboarding>,
    index: number,
  ) => (event: SyntheticEvent) => void;
}) => (
  <StyledFlowItem
    lastSlide={resources}
    firstSlide={home}
    hidePrevArrow={hidePrevArrow}
  >
    <Box className={'flowContentContainer'}>
      <Box className={'flowBackground'}>
        {resources && <ResourceDefaultPageBackground />}
        {home && <Box className="homeStyled" />}
        {!home && !resources && (
          <ResourceTypeTemplateBackground />
        )}
      </Box>
      <Box className={'flowTitle'}>
        <Typography variant={'h3'}>
          {page.resource.title}
        </Typography>
        {!home && !resources && (
          <Box className={'buttonWrapper'}>
            {hidePrevArrow && (
              <IconButton
                variant={'filled-primary'}
                toolTipProps={{
                  title: 'move page to previous spot',
                  placement: 'bottom',
                }}
                withTooltip
                onClick={handleMove && handleMove(page, 2)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
            <IconButton
              className={'deleteIcon'}
              toolTipProps={{
                title: 'Delete Page from flow',
                placement: 'bottom',
              }}
              withTooltip
              onClick={handleDelete && handleDelete(page)}
              variant={'filled-primary'}
            >
              <ClearIcon />
            </IconButton>
            {hideNextArrow && (
              <IconButton
                toolTipProps={{
                  title: 'move page to next spot',
                  placement: 'bottom',
                }}
                withTooltip
                onClick={handleMove && handleMove(page, 0)}
                variant={'filled-primary'}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    </Box>
    <Box className={'sectionActions'}>
      <ArrowForwardIcon className={'flowArrowIcon'} />
      <AddIcon
        className={'handleAddNewIcon'}
        onClick={handleAddNew && handleAddNew(page, index)}
      />
    </Box>
  </StyledFlowItem>
);

export default OnboardingFlowPage;
