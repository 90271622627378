import { Box } from 'components/index';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';
import React from 'react';
import FlexRow from '../../../shared/FlexRow';
import { Button, CheckBox } from '../../../shared';
import { IWTFieldArrayHelper } from '../types';
import { ICertificates } from '../../../../api/types/queries/employeeProfile';

const RenderCertificates = ({
  certificates,
  errors,
  control,
  RenderFormInput,
}) => {
  const baseCertificate = {
    completionDate: new Date(),
    complete: false,
    name: '',
    validatedByEmployerProfileId: false,
  } as IWTFieldArrayHelper<ICertificates>;

  return (
    <Box className={'sectionContainer'}>
      <Typography variant={'h2'}>Certificates</Typography>
      <Typography
        variant={'h5'}
        className={'sectionDescription'}
      >
        You can add certificates you’ve trained for here. Classes
        to get certificates will also populate here to complete
        them. Ask your employer to verify your certificates!
      </Typography>
      <FlexRow className={'history-container'}>
        {certificates.fields.length === 0 && (
          <Typography variant={'body1'}>
            No Certificates
          </Typography>
        )}
        {certificates.fields.map((field, index) => (
          <Paper
            elevation={3}
            key={field.id}
            className={'history-item'}
          >
            <Button
              onClick={() => certificates.remove(index)}
              size={'small'}
              variant={'outlined'}
              color={'primary'}
            >
              Delete
            </Button>
            <Controller
              name={`certificates.${index}.complete`}
              control={control}
              render={({ field: controllerField }) => (
                <CheckBox
                  {...controllerField}
                  label={'Complete'}
                  labelPlacement={'start'}
                  checked={controllerField.value}
                  type={'checkbox'}
                />
              )}
            />
            <RenderFormInput
              name={`certificates.${index}.name`}
              label={'Name'}
              registerOptions={{
                required: 'Certificate Name is required',
              }}
              error={get(
                errors,
                `certificates.${index}.name.message`,
                null,
              )}
            />
          </Paper>
        ))}
      </FlexRow>
      <Button
        onClick={() => certificates.append(baseCertificate)}
        variant={'outlined'}
        color={'secondary'}
        size={'small'}
      >
        Add Certificates
      </Button>
    </Box>
  );
};

export default RenderCertificates;
