import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isIos } from 'utils';
import { useMediaQueryMatches } from 'hooks';
import { Box, Dialog, styled } from 'components';
import { ButtonBase, IconButton } from '../Button';
import { MdClose, MdPlayArrow } from '../../icons';
import { lighten } from '@mui/material';
import Typography from '@mui/material/Typography';

const MEDIA_ERROR = {
  1: 'MEDIA_ERR_ABORTED',
  2: 'MEDIA_ERR_NETWORK',
  3: 'MEDIA_ERR_DECODE',
  4: 'MEDIA_ERR_SRC_NOT_SUPPORTED',
};

const StyledRoot = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'testID',
})(({ theme, url, ...rest }) => ({
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,
  width: '100%',
  height: 87,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 6,
  backgroundColor: '#000',
  '& video': {
    position: 'absolute',
    width: '100%',
    zIndex: 0,
  },
  '& .videoPreview__overlay': {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 'inherit',
    backgroundColor: url
      ? 'transparent'
      : lighten(theme.palette.background.default, 0.07),
  },
  '& .videoPreview__play': {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: 22,
  },
  '&.videoPreview_open': {
    height: 255,
    '& video': {
      position: 'static',
      height: 'auto',
      width: '100%',
      outline: 'none',
    },
  },
  '& .videoPreview__closeBtn': {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 10,
  },
}));

const StyledDialog = styled(Dialog)(() => ({
  '& .dialogPaper': {
    position: 'relative',
    height: '100vh',
    maxHeight: 614,
    maxWidth: 920,
    width: '100%',
    border: 'none',
    flexDirection: 'row',
    backgroundColor: '#000',
    '@media screen and (max-height: 615px)': {
      maxHeight: '100%',
    },
  },
  '& .closeDialogBtn': {
    position: 'absolute',
    top: 24,
    right: 24,
    zIndex: 10,
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
}));

const Video = (props) => {
  const {
    className,
    disabled,
    isFullScreenOnMobile,
    initUrl,
    refetch,
    variant,
  } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const [url, setUrl] = useState(initUrl);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const videoRef = useRef();
  const dialogVideoRef = useRef();

  useEffect(() => {
    function handleFullScreenChange() {
      if (!document.fullscreenElement && videoRef.current)
        videoRef.current.pause();
    }
    document.addEventListener(
      'fullscreenchange',
      handleFullScreenChange,
    );
    return () => {
      document.removeEventListener(
        'fullscreenchange',
        handleFullScreenChange,
      );
    };
  }, []);

  useEffect(() => {
    if (initUrl) setUrl(initUrl);
  }, [initUrl]);

  function fullScreenIsSupported(element) {
    return (
      element.requestFullscreen ||
      element.mozRequestFullScreen ||
      element.webkitRequestFullscreen ||
      element.msRequestFullscreen
    );
  }

  function openFullScreen(element) {
    if (element.requestFullscreen) element.requestFullscreen();
    else if (element.mozRequestFullScreen)
      element.mozRequestFullScreen();
    else if (element.webkitRequestFullscreen)
      element.webkitRequestFullscreen();
    else if (element.msRequestFullScreen)
      element.msRequestFullScreen();
  }

  function openVideo() {
    if (
      isFullScreenOnMobile &&
      !isDesktop &&
      videoRef.current &&
      (isIos() || fullScreenIsSupported(videoRef.current))
    ) {
      // On iOS full-screen mode is only supported on the iPad, but on iPhone video will play in full-screen automatically
      videoRef.current.play();
      openFullScreen(videoRef.current);
    } else if (variant === 'modal') {
      setIsVideoOpen(false);
      setIsDialogOpen(true);
    } else if (variant === 'default') {
      setIsVideoOpen(true);
    }
  }

  function closeVideo() {
    setIsVideoOpen(false);
    setIsDialogOpen(false);
  }

  async function handleVideoError() {
    // @todo: Not working on iPhone if video url expired. Maybe should use timer to refetch new url
    if (videoRef.current && videoRef?.current?.error?.code) {
      console.error(
        'Video error: ',
        MEDIA_ERROR[videoRef.current.error.code],
      );
      if (
        refetch &&
        (videoRef.current.error.code === 2 ||
          videoRef.current.error.code === 4)
      ) {
        // Refetch video url if it has expired
        const freshVideoUrl = await refetch();
        if (freshVideoUrl) setUrl(freshVideoUrl);
      }
    }
  }

  async function handleDialogVideoError() {
    if (
      dialogVideoRef.current &&
      dialogVideoRef?.current?.error?.code
    ) {
      console.error(
        'Video error: ',
        MEDIA_ERROR[dialogVideoRef.current.error.code],
      );
      if (
        refetch &&
        (dialogVideoRef.current.error.code === 2 ||
          dialogVideoRef.current.error.code === 4)
      ) {
        // Refetch video url if it has expired
        const freshVideoUrl = await refetch();
        if (freshVideoUrl) setUrl(freshVideoUrl);
      }
    }
  }

  function renderBtnContent() {
    if (isVideoOpen && variant === 'default') {
      return (
        <IconButton
          variant="filled-primary"
          aria-label="close video"
          className="videoPreview__closeBtn"
          onClick={closeVideo}
          testID="video-close-button"
        >
          <MdClose />
        </IconButton>
      );
    }
    return (
      <div className="videoPreview__overlay">
        <Box className="videoPreview__play">
          <MdPlayArrow color="inherit" fontSize="inherit" />
          <Box
            ml="8px"
            fontSize={16}
            fontWeight="bold"
            lineHeight={0}
          >
            Play
          </Box>
        </Box>
        {!url && (
          <Typography variant={'body2'}>
            Video not available
          </Typography>
        )}
      </div>
    );
  }

  return (
    <>
      <StyledRoot
        component={!isVideoOpen ? 'button' : 'div'}
        className={clsx(
          isVideoOpen &&
            variant === 'default' &&
            'videoPreview_open',
          className,
        )}
        onClick={
          isVideoOpen && variant === 'default'
            ? undefined
            : openVideo
        }
        disabled={disabled}
        url={url}
        testID="video-button"
      >
        {renderBtnContent()}
        <video
          ref={videoRef}
          src={url}
          {...(isVideoOpen && variant === 'default'
            ? { controls: true }
            : {})}
          onError={handleVideoError}
        />
      </StyledRoot>
      <StyledDialog
        open={isDialogOpen}
        disablePortal
        classes={{ paper: 'dialogPaper' }}
        onClose={closeVideo}
      >
        <IconButton
          className="closeDialogBtn"
          onClick={closeVideo}
          testID="video-modal-close-button"
        >
          <MdClose style={{ color: '#fff' }} />
        </IconButton>
        <video
          ref={dialogVideoRef}
          src={url}
          controls
          style={{
            width: '100%',
            height: 'auto',
            outline: 'none',
          }}
          onError={handleDialogVideoError}
        />
      </StyledDialog>
    </>
  );
};

Video.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isFullScreenOnMobile: PropTypes.bool,
  initUrl: PropTypes.string.isRequired,
  refetch: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'modal']),
};

Video.defaultProps = {
  className: '',
  disabled: false,
  isFullScreenOnMobile: true,
  refetch: () => {},
  variant: 'default',
};

export default memo(Video);
