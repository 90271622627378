import React from 'react';
import { Box } from 'components';
import {
  Spinner,
  Video,
  Button,
  Select,
} from 'components/shared';
import Typography from '@mui/material/Typography';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import {
  MdCheckCircle,
  MdClose,
  MdFilePresent,
} from 'components/icons';
import { styled } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import FlexRow from '../../shared/FlexRow';
import SectionLayout from '../../Profile/SectionLayout';
import { IProfileRendererProps } from './types';
import styles from '../../../styles/Employee/profile';
import {
  RenderSkills,
  RenderIndustries,
  RenderEducation,
  RenderExperience,
  RenderCertificates,
} from './renderers';
import { fileNameFromUrl } from '../../../utils';

const genders = [
  {
    name: 'Male',
    id: 'male',
  },
  {
    name: 'Female',
    id: 'female',
  },
  {
    name: 'Non-Binary',
    id: 'non-binary',
  },
  {
    name: 'Prefer to not answer',
    id: 'no answer',
  },
];

interface IStyledProps {}

const StyledBox = styled(Box)<IStyledProps>(styles);

const ProfileRenderer = ({
  sectionLayoutProps,
  sectionHeader,
  form,
  employeeProfile,
  employeeProfileLoading,
  isDesktop,
  RenderFormInput,
  states,
  countries,
  image,
  video,
  resume,
}: IProfileRendererProps) => {
  const {
    register,
    errors,
    certificates,
    education,
    experience,
    industries,
    skills,
    control,
    validate,
  } = form;

  return (
    <StyledBox>
      <form>
        <SectionLayout
          {...sectionLayoutProps}
          sectionHeaderContent={sectionHeader}
        >
          {employeeProfileLoading ? (
            <Spinner />
          ) : (
            <>
              {!isEmpty(errors) && (
                <Typography variant={'body1'} color={'error'}>
                  There are validation errors in the following
                  fields: <b>{Object.keys(errors).join(', ')}</b>
                  . Please fix them and save again.
                </Typography>
              )}
              <FlexRow mb={5} mt={2}>
                <Typography variant={'h1'}>
                  Welcome back, {employeeProfile.name}!
                </Typography>
              </FlexRow>
              <FlexRow alignItems={'start'} inline>
                <FlexRow
                  className="formItem"
                  flexDirection={'column'}
                  alignItems={'start'}
                  inline
                >
                  <Typography variant={'h2'} className="label">
                    Image
                  </Typography>
                  <img
                    src={image.value || defaultEmployeeImg}
                    className="profile__imagePreview"
                    alt="your profile"
                  />
                  <input
                    id="image-upload"
                    style={{ display: 'none' }}
                    type="file"
                    {...register('imageUrl', {
                      onChange: image.set,
                    })}
                    accept={'image/*'}
                  />
                  <label htmlFor="image-upload">
                    <Button
                      sx={(theme) => ({
                        mt: theme.spacing(2.5),
                      })}
                      component="span"
                      variant={'outlined'}
                      color={'secondary'}
                      size={'small'}
                    >
                      Upload Profile Image
                    </Button>
                  </label>
                </FlexRow>
                <FlexRow
                  className="formItem"
                  flexDirection={'column'}
                  alignItems={'start'}
                  inline
                >
                  <Typography variant={'h2'} className="label">
                    Video
                  </Typography>
                  <Video
                    initUrl={video.value}
                    variant={isDesktop ? 'modal' : 'default'}
                    disabled={!video.value}
                    className="profile__videoPreview"
                  />
                  <input
                    id="video-upload"
                    style={{ display: 'none' }}
                    type="file"
                    {...register('videoUrl', {
                      onChange: video.set,
                    })}
                    accept={
                      'video/mp4,video/x-m4v,video/quicktime,video/*'
                    }
                  />
                  <label htmlFor="video-upload">
                    <Button
                      sx={(theme) => ({
                        mt: theme.spacing(2.5),
                      })}
                      component="span"
                      variant={'outlined'}
                      color={'secondary'}
                      size={'small'}
                    >
                      Upload Profile Video
                    </Button>
                  </label>
                </FlexRow>
              </FlexRow>
              <FlexRow className={'profile-inputs'}>
                <RenderFormInput
                  name={'name'}
                  registerOptions={{
                    required: 'Name is Required',
                  }}
                  label={'name'}
                  error={errors.name?.message}
                />
                <RenderFormInput
                  name={'address'}
                  label={'Address'}
                  registerOptions={{
                    required: 'Address is Required',
                  }}
                  error={errors.address?.message}
                />
                <RenderFormInput
                  name={'city'}
                  registerOptions={{
                    required: 'City is Required',
                  }}
                  label={'City'}
                  error={errors.city?.message}
                />
                <Controller
                  name={'stateId'}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={'State'}
                      size={'large'}
                      options={states.map((s) => ({
                        label: s.name,
                        value: s.id,
                      }))}
                    />
                  )}
                />
                <RenderFormInput
                  name={'zip'}
                  registerOptions={{
                    required: 'Zip is Required',
                    validate: validate.zip,
                  }}
                  label={'Zip Code'}
                  error={errors.zip?.message}
                />
                <Controller
                  name={'countryId'}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={'Country'}
                      size={'large'}
                      options={countries.map((c) => ({
                        label: c.name,
                        value: c.id,
                      }))}
                    />
                  )}
                />
                <RenderFormInput
                  name={'motto'}
                  label={'Motto'}
                />
                <Controller
                  name={'gender'}
                  control={control}
                  rules={{
                    required: 'Gender is required',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={'Gender'}
                      withHelperText
                      size={'large'}
                      error={errors.gender?.message}
                      options={genders.map((g) => ({
                        label: g.name,
                        value: g.id,
                      }))}
                    />
                  )}
                />
                <RenderFormInput
                  name={'phone'}
                  registerOptions={{
                    required: 'Phone is required',
                    validate: validate.phone,
                  }}
                  label={'Phone Number'}
                  error={errors.phone?.message}
                />
              </FlexRow>
              <Box
                className={'resumeContainer sectionContainer'}
              >
                <Typography variant={'h2'}>Resume</Typography>
                <Box
                  component={resume.value ? 'a' : 'div'}
                  {...(resume.value
                    ? { href: resume.value, download: true }
                    : {})}
                >
                  {resume.value ? (
                    <MdCheckCircle
                      color={'success'}
                      className="cvCheckIcon"
                    />
                  ) : (
                    <MdClose
                      color={'error'}
                      className="cvCheckIcon"
                    />
                  )}
                  <MdFilePresent
                    color="info"
                    fontSize="inherit"
                  />
                </Box>
                <Typography variant={'h3'} color={'primary'}>
                  {(resume.value || '').indexOf('amazonaws') > 0
                    ? fileNameFromUrl(resume.value)
                    : resume.value}
                </Typography>
                <input
                  id="resume-upload"
                  style={{ display: 'none' }}
                  type="file"
                  {...register('resumeUrl', {
                    onChange: resume.set,
                  })}
                  accept=".doc,.docx,.txt,.pdf"
                />
                <label htmlFor="resume-upload">
                  <Button
                    sx={(theme) => ({
                      mt: theme.spacing(2.5),
                    })}
                    component="span"
                    variant={'outlined'}
                    color={'secondary'}
                    size={'small'}
                  >
                    Upload Resume
                  </Button>
                </label>
              </Box>
              <RenderSkills
                skills={skills}
                register={register}
              />
              <RenderIndustries
                industries={industries}
                register={register}
              />
              <RenderEducation
                education={education}
                errors={errors}
                control={control}
                validate={validate}
                RenderFormInput={RenderFormInput}
              />
              <RenderExperience
                experience={experience}
                errors={errors}
                control={control}
                validate={validate}
                RenderFormInput={RenderFormInput}
              />
              <RenderCertificates
                certificates={certificates}
                errors={errors}
                control={control}
                RenderFormInput={RenderFormInput}
              />
            </>
          )}
        </SectionLayout>
      </form>
    </StyledBox>
  );
};

export default ProfileRenderer;
