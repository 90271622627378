import React, { useState } from 'react';
import { useMediaQueryMatches } from 'hooks';
import { Button } from 'components/shared';
import { MdLock } from 'components/icons';
import { PrivacyClauseDialog } from 'components/dialogs';

const PrivacyClauseButton = () => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        variant="text"
        startIcon={<MdLock fontSize="inherit" />}
        width="100%"
        sx={{
          lineHeight: isDesktop ? '18px' : '12px',
          fontSize: isDesktop ? 16 : 11,
          '& .StyledButton-startIcon': {
            marginTop: isDesktop ? 0 : '-2px',
            fontSize: isDesktop ? 16 : 13
          },
          '& .StyledButton-label': {
            alignItems: 'flex-start'
          }
        }}
        onClick={() => setModalOpen(true)}
        testID="onboarding-read-privacy-button"
      >
        Read Privacy Clause
      </Button>
      <PrivacyClauseDialog isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
}

PrivacyClauseButton.propTypes = {};

export default PrivacyClauseButton;
