import { addAlpha } from 'styles/utils';

const teamStructureBase = () => ({
  '& .teemStructureContainer': {
    position: 'relative',
  },
  '& .notYetAvailable': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    alignSelf: 'center',
    margin: '0 auto',
    textAlign: 'center',
    alignItems: 'center',
  },
});

const jobPostingScores = ({ theme }) => ({
  '& .jobPostingScoresContainer': {
    position: 'relative',
    padding: '22px 46px 25px',
    border: `1px solid ${theme.palette.grey['100']}`,
    borderRadius: 12,
  },
  '& .jobPostingScoresTitle': {
    height: 'auto',
    marginBottom: 24,
    padding: 0,
    textAlign: 'center',
  },
  '& .jobPostingScoresRow': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 12,
    },
  },
  '& .jobPostingScoresLabel': {
    flex: 1,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 12,
  },
  '& .jobPostingScoresValue': {
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  '& .jobPostingScoresIcon': {
    marginRight: 33,
    fontSize: 21,
  },
});

const jobStatistics = ({ theme }) => ({
  '& .jobStatisticsContainer': {
    position: 'relative',
    gridArea: 'doubleColArea',
    padding: '18px 22px 35px',
    border: `1px solid ${theme.palette.grey['100']}`,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  '& .jobStatisticsHeader': {
    marginBottom: 27,
    paddingLeft: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .jobStatisticsTitle': {
    height: 'auto',
    marginBottom: 0,
    padding: 0,
  },
  '& .sortControllersContainer': {
    margin: 0,
    padding: 0,
    border: 0,
    display: 'flex',
    alignItems: 'center',
  },
  '& .sortControllersContainerTitle': {
    marginRight: 29,
    fontSize: 10,
    color: theme.palette.text.primary,
  },
  '& .sortController': {
    marginRight: 8,
    padding: '6px 19px 7px',
    borderRadius: 19,
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    '&.checked': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
    },
  },
  '& .sortControllerLabel': {
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: 1.1,
  },
  '& .jobStatisticsTableContainer': {
    flex: 1,
    overflow: 'auto',
  },
  '& .jobStatisticsTable': {
    width: '100%',
    borderCollapse: 'collapse',
    border: 'none',
    fontSize: 12,
    color: theme.palette.text.primary,
    '& > thead > tr > th': {
      position: 'sticky',
      top: 0,
      paddingBottom: 18,
      fontSize: 8,
      backgroundColor: theme.palette.background.default,
    },
    '& > tbody th, & > tbody td': {
      paddingBottom: 13,
    },
  },
  '& .rowHeadCell': {
    fontSize: 13,
  },
  '& .accentedCell': {
    fontSize: 10,
    color: theme.palette.primary.light,
    fontWeight: 800,
  },
});

const equityScore = ({ theme }) => ({
  '& .equityScoreContainerWrapper': {
    height: 176,
    width: 176,
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  '& .equityScoreScoreProgress': {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  '& .equityScoreScoreDetails': {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
  },
  '& .equityScoreScoreProgressGradient': {
    position: 'absolute',
    width: '98%',
    height: '98%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background:
      'linear-gradient(to bottom, rgba(135,135,249,0.09) 0%,rgba(135,135,249,0.09) 1%,rgba(135,135,249,0) 100%)',
    borderRadius: '50%',
    zIndex: '-1',
  },
  '& .equityScoreScoreTitle': {
    fontSize: 62,
    color: theme.palette.common.white,
  },
  '& .equityScoreScoreIcon': { position: 'absolute', top: 12 },
  '& .equityScoreScoreLabel': {
    fontWeight: 800,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  '& .equityScoreScoreLevel': {
    position: 'absolute',
    bottom: 15,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '5px 20px',
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    borderRadius: 10,
    fontSize: 10,
  },
});

const competition = ({ theme }) => ({
  '& .competitionItem': {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .competitionScoreText': {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px',
    color: theme.palette.text.primary,
  },
  '& .competitionScoreValue': {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  '& .competitionScoreFireIcon': {
    fontSize: 16,
    color: theme.palette.error.main,
  },
  '& .competitionHotJobs': {
    maxWidth: 230,
    margin: '0 auto',
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.primary.light,
  },
});

const charts = ({ theme }) => ({
  '& .chartsTopContainer': {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridColumnGap: theme.spacing(2),
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  '& .chartCard': {
    position: 'relative',
    padding: '18px 22px 12px',
    border: `1px solid ${theme.palette.grey['100']}`,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .chartsContainer': {
    paddingBottom: 25,
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(2),
    },
    '& .jobCharts': {
      display: 'grid',
      marginTop: '1rem',
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(2),
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: '273px',
      gridTemplateAreas: '". doubleColArea doubleColArea"',
      [theme.breakpoints.down('desktopApp')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      },
    },
    '& .generalCharts': {
      display: 'grid',
      marginTop: '1rem',
      gridColumnGap: '1rem',
      gridRowGap: '1rem',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: '250px',
      [theme.breakpoints.down('desktopApp')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  '& .chartContainerNames': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: addAlpha(theme.palette.grey['300'], 0.7),
  },
  '& .chart': {
    position: 'relative',
    padding: '18px 22px',
    border: `1px solid ${theme.palette.grey['300']}`,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .chartTitle': {
    // marginBottom: 30,
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  '& .chartDescription': {
    maxWidth: 230,
    margin: '0 auto 20px',
    fontSize: 11,
    lineHeight: '13px',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  '& .chartValue_big': {
    textAlign: 'center',
    fontSize: 68,
    color: theme.palette.text.primary,
  },
  // ...moodChart({ theme }),
  ...jobPostingScores({ theme }),
  ...jobStatistics({ theme }),
  ...equityScore({ theme }),
  ...competition({ theme }),
});

const columnGap = 15;
const columns = 4;
const columnWidth = 100 / columns; // percentage(5/100);
const column = `calc(${columnWidth}% - ${
  columnGap + columnGap / columns
}px)`;

const stores = ({ theme }) => ({
  '& .storesTopSection': {
    padding: '20px 0',
    borderBottom: '3px solid #f1f2f2',
    '& h1': {
      fontSize: 18,
      fontWeight: 100,
      color: theme.palette.grey['100'],
    },
  },
  '& .storesTopBar': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(2),
      flexWrap: 'wrap',
    },
    '& .storesTitle': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 310,
      [theme.breakpoints.down('desktopApp')]: {
        width: '100%',
        flexGrow: 1,
        justifyContent: 'space-between',
      },
      '& svg': {
        maxWidth: 22,
        width: 22,
      },
      '.filterContainer': {
        [theme.breakpoints.down('desktopApp')]: {
          '&.closed': {
            display: 'none',
          },
          '&.open': {
            display: 'block',
          },
        },
      },
    },
  },
  '& .storesRightBar': {
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      flexGrow: 1,
      width: '100%',
    },
    '& button': {
      marginRight: 10,
      border: 'none',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.error.main,
      cursor: 'pointer',
    },
  },
  '& .storesSearch': {
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%',
    },
    '& label': {
      fontSize: 12,
      position: 'absolute',
      left: 5,
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme.palette.text.primary,
    },
    '& input': {
      padding: 5,
      paddingLeft: 60,
      width: 200,
      border: 'none',
      outline: 'none',
      borderBottom: `1px solid ${theme.palette.grey['600']}`,
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      [theme.breakpoints.down('desktopApp')]: {
        width: '100%',
      },
    },
    '& button:last-of-type': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.light,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      height: 27,
      width: 27,
      color: theme.palette.text.primary,
      outline: 'none',
      border: 'none',
    },
  },
  '& .storesPagination': {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(2),
      margin: 0,
    },
    '& .paginationButtonContainer': {
      display: 'flex',
      position: 'relative',
      '& .paginationButton': {
        width: 30,
        height: 30,
        '&:not(:last-of-type)': {
          marginRight: 10,
        },
      },
      '& .activePage': {
        color: theme.palette.text.primary,
      },
      '& .activePageOverlay': {
        display: 'block',
        height: 30,
        width: 30,
        backgroundColor: theme.palette.primary.light,
        borderRadius: '50%',
        position: 'absolute',
        left: 0,
        zIndex: -1,
        transition: 'transform 0.2s ease',
      },
    },
    '& > div:not(.paginationButtonContainer), & .paginationButtonContainer > div':
      {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 10px',
        width: 30,
        height: 30,
        borderRadius: 50,
        marginRight: 10,
        color: theme.palette.grey['100'],
        lineHeight: 0,
        fontSize: 12,
        cursor: 'pointer',
        transition: 'color 0.2s ease',
        '&.active': {
          color: theme.palette.primary.contrastText,
        },
        '& .forwardBackButton': {
          color: theme.palette.common.white,
          fontSize: 17,
          fontWeight: 800,
          '&.disabled': {
            opacity: 0.7,
            pointerEvents: 'none',
            color: theme.palette.primary.contrastText,
          },
        },
      },
  },
  '& .pills': {
    display: 'grid',
    gridTemplateColumns: `${column} ${column} ${column} ${column}`,
    gridTemplateRows: 'auto auto auto',
    columnGap,
    rowGap: columnGap,
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'grid',
      gridTemplateColumns: `auto auto`,
      padding: theme.spacing(2),
    },
    '& .pill': {
      padding: '15px 10px',
      backgroundColor: theme.palette.common.white,
      borderRadius: 7.5,
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.095)',
      position: 'relative',
      overflow: 'hidden',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        zIndex: 1,
        borderRadius: 7.5,
      },
      '&::after': {
        backgroundColor: theme.palette.common.white,
      },
      '&::before': {
        width: 'calc(100% - 2.5px)',
        height: 'calc(100% - 2.5px)',
        transition:
          'width .2s ease, height .2s ease, background-color .2s ease',
        backgroundColor: theme.palette.background.default,
        zIndex: 2,
        borderRadius: 6,
      },
      '&.active': {
        '&::before': {
          width: 'calc(100% - 7.5px)',
          height: 'calc(100% - 7.5px)',
          backgroundColor: theme.palette.background.paper,
        },
      },
      '&.empty': {
        boxShadow: 'none',
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: 3,
      },
    },
  },
  '& .pillsLoaderContainer': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: addAlpha(theme.palette.background.default),
  },
  '& .pillStore': {
    width: 'calc(100% - 60px)',
    paddingLeft: 15,
    '& .pillName': {
      fontSize: 11,
      letterSpacing: 0.5,
      lineHeight: 1,
      fontWeight: 800,
      paddingBottom: 7.5,
      textAlign: 'left',
    },
    '& .pillApplicants': {
      color: theme.palette.primary.light,
      fontWeight: 800,
      fontSize: 10,
      paddingBottom: 7.5,
    },
    '& > div:not(.pillName)': {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        borderRight: '1px solid #ececf5',
        padding: '0 7px',
        display: 'flex',
        width: 25,
        height: 15,
        fontSize: 12,
        boxSizing: 'content-box',
        '&:last-of-type': {
          borderRight: 'none',
          '& svg': {
            maxWidth: 4,
          },
        },
        '&:first-of-type': {
          paddingLeft: 0,
        },
      },
      '& svg': {
        maxWidth: 12,
        marginLeft: 5,
        width: 12,
      },
    },
  },
  '& .pillImg': {
    width: 45,
    height: 45,
    display: 'block',
    borderRadius: 50,
    border: `2px solid ${theme.palette.common.white}`,
    objectFit: 'cover',
  },
  '& .storesFilterModal__paper': {
    position: 'absolute',
    left: 0,
    top: 0,
    maxWidth: 300,
    minHeight: 'calc(100vh - 22px)',
    margin: 0,
    flexDirection: 'row',
  },
  '& .storesFilterContainer': {
    width: '100%',
    padding: 16,
  },
});

export default ({ theme }) => ({
  '& .chartLoaderContainer': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  ...teamStructureBase({ theme }),
  ...charts({ theme }),
  ...stores({ theme }),
});
