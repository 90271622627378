import React from 'react';
import PT from 'prop-types';
import { useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { IconButton } from 'components/shared';
import { MdPersonOutline, MdPerson } from 'components/icons';
import { getRoutes } from 'utils';
import { useLocation } from 'react-router-dom';

const MobileIconButton = styled(IconButton)(() => ({
  width: 'auto',
  height: 'auto',
  padding: 7,
}));

const ROUTES = getRoutes();

const ProfileLink = (props) => {
  const { userRole } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const location = useLocation();
  const selected = location.pathname.indexOf('profile') > 0;

  return isDesktop ? (
    <IconButton
      isRouterLink
      to={ROUTES[userRole].profile}
      variant="outlined"
      color="primary"
      aria-label="profile"
      className="headerElement profileLink"
      testID={`${userRole}-profile-button`}
      selected={selected}
    >
      <MdPersonOutline />
    </IconButton>
  ) : (
    <MobileIconButton
      isRouterLink
      to={ROUTES[userRole].profile}
      edge="start"
      color="primary"
      aria-label="profile"
      className="headerElement profileLink"
      testID={`${userRole}-profile-button`}
    >
      <MdPerson />
    </MobileIconButton>
  );
};

ProfileLink.propTypes = {
  userRole: PT.string.isRequired,
};

export default ProfileLink;
