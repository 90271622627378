export const commonStyles = ({ theme }) => ({
  '&.container': {
    overflow: 'auto',
    paddingTop: 3,
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('desktopApp')]: {
      overflow: 'initial',
    },
  },
  '& .autocompleteContainer': {
    width: '100%',
    marginBottom: 16,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 12,
      padding: '0 16px',
    },
  },
  '& .contentWrapper': {
    position: 'relative',
    overflow: 'auto',
    padding: 0,
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0,
      overflow: 'initial',
    },
  },
  '& .contentLoader': {
    position: 'absolute',
    inset: 0,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .jobsDeckWrapper': {
    marginBottom: 5,
  },
  '& .deckAction': {
    '&.big': {
      width: 58,
      height: 58,
    },
  },
  '& .deckLabel__pass': {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 200,
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    borderRadius: 10,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.error.main,
  },
  '& .deckLabel__apply': {
    position: 'absolute',
    top: 12,
    left: 12,
    zIndex: 200,
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    borderRadius: 10,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.success.main,
  },
  '& .mobileJobCardWrapper': {
    padding: '0px 16px',
  },
  '& .mobileJobCard': {
    width: '100%',
    maxWidth: 382,
  },
  '& .mobileControllers': {
    width: '100%',
    padding: '0px 16px 90px',
    display: 'none',
    // alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
    },
  },
  '& .mobileControllerWrapper': {
    maxWidth: 64,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:not(:first-of-type)': {
      marginLeft: 24,
    },
  },
  '& .mobileControllerBtnWrapper': {
    height: 58,
    // marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .mobileControllerLabel': {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  '& .ellipsisText': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& .optionsPopupPaper': {
    minWidth: 255,
  },
  '& .optionsPopupBtn': {
    width: '100%',
    height: 55,
    textAlign: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '15px',
    letterSpacing: 0,
    color: theme.palette.grey['800'],
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
  },
});

export const allJobsStyles = ({ theme }) => ({
  ...commonStyles({ theme }),
  '& .jobsContainer': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 30,
    [theme.breakpoints.down('desktopApp')]: {
      '&.list': {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
      },
      '&.swiper': {
        flex: 1,
        margin: 0,
        flexFlow: 'column',
        overflow: 'hidden',
        display: 'flex',
        gridGap: 0,
      },
    },
  },
  '& .jobCol': {
    width: '100%',
    display: 'flex',
  },
});

export const myJobsStyles = ({ theme }) => ({
  ...commonStyles({ theme }),
  '& .jobsContainer': {
    '&.gridView': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: 16,
    },
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 16px',
    },
  },
  '& .listItemContainer': {
    [theme.breakpoints.down('desktopApp')]: {
      '&:not(:last-of-type)': {
        marginBottom: 15,
      },
    },
  },
  '& .filtersContainer': {
    marginLeft: 30,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      marginLeft: 24,
    },
  },
  '& .layoutController': {
    marginLeft: 30,
  },
  '& .filterPopupTrigger': {
    width: 91,
    padding: 0,
    color: theme.palette.common.white,
    '& .StyledButton-endIcon': { marginLeft: 0, marginRight: 0 },
  },
});

const sectionsPadding = '0 24px';

export const employerJobsStyles = ({ theme }) => ({
  '& .employerJobsView-container': {
    padding: 0,
  },
  '& .paddingX': {
    padding: sectionsPadding,
  },
  '& .employerDetails': {
    marginBottom: 65,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 48,
    },
  },
  '& .tabsRoot': {
    minHeight: 'auto',
  },
  '& .tabsIndicator': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .tabRoot': {
    minWidth: 'auto',
    minHeight: 34,
    padding: 0,
    textTransform: 'initial',
    fontWeight: 700,
    fontSize: 25,
    lineHeight: '130%',
    '&:not(:first-child)': {
      marginLeft: 24,
    },
  },
  '& .tabPanel': {
    paddingTop: 32,
  },
  '& .autocompleteContainer': {
    // in JobsCardsRenderer
    width: '100%',
    marginBottom: 32,
    padding: sectionsPadding,
  },
  '& .filterContainer': {
    marginBottom: 32,
    [theme.breakpoints.down('desktopApp')]: {
      position: 'relative',
      padding: 0,
    },
  },
  '& .jobsFilter': {
    [theme.breakpoints.down('desktopApp')]: {
      overflowX: 'auto',
      padding: '0 24px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  '& .employerContentContainer': {
    '& h3': {
      fontSize: 30,
      marginBottom: 10,
      [theme.breakpoints.down('desktopApp')]: {
        fontSize: 19,
        marginBottom: 6,
      },
    },
  },
  '& .employerContentOpenJobs': {
    color: theme.palette.primary.light,
  },
  '& .employerJobs-mobile-finishedSwiping': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '80vw',
    margin: '0 auto',
    textAlign: 'center',
    height: 'calc(100vh - 250px)',
    '& p, h2': {
      marginBottom: 15,
    },
  },
  '& .employerJobs-noneAvailable': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 20,
    [theme.breakpoints.down('desktopApp')]: {
      height: 'calc(100vh - 100px)',
    },
    '& h2': {
      marginBottom: 10,
    },
    '& p': {
      marginBottom: 20,
    },
  },
  '& .companyDescription': {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '150%',
  },
  '& .reviewsHeader': {
    marginBottom: 32,
    display: 'flex',
    alignItems: 'center',
  },
  '& .reviewsHeader__title': {
    marginRight: 24,
    fontWeight: 700,
    fontSize: 31,
    lineHeight: '130%',
  },
  '& .reviewsCards': {
    display: 'flex',
    gap: 16,
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
    },
  },
  '& .reviewsCard': {
    width: '100%',
    maxWidth: 176,
    padding: 16,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 12,
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '150%',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: '100%',
    },
  },
  '& .reviewsCard__rating': {
    marginBottom: 10,
  },
  '& .reviewsCard__count': {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 25,
    lineHeight: '130%',
  },
});
