import { forEach, orderBy } from 'lodash';
import {
  addDays,
  addWeeks,
  differenceInCalendarDays,
  eachDayOfInterval,
  endOfDay,
  endOfWeek,
  getDay,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfWeek,
  subDays,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
  convertUTCToZonedDate,
  convertDateToTZ,
} from '../index';

const DEFAULT_SCHEDULE_WEEK = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
};

const DEFAULT_SCHEDULE_CRITERIA = {
  industry: null,
  skills: null,
  careerHealthScore: null,
  experience: null,
};

const DEFAULT_COUNT = 1;
const MIN_COUNT = 1;

const parseZonedISO = (isoDate, zone) =>
  zone
    ? convertUTCToZonedDate(isoDate, zone)
    : parseISO(isoDate);

// const toZonedISO = (date, zone) =>
//   zone ? convertZonedDateToUTC(date, zone).toISOString() : date.toISOString();

const getInitialEmployerSchedule = () => {
  const now = new Date();
  const startDate = startOfDay(now);
  return {
    ...DEFAULT_SCHEDULE_CRITERIA,
    count: DEFAULT_COUNT,
    length: 1,
    startDate,
    endDate: addWeeks(startDate, 1),
    schedule: { ...DEFAULT_SCHEDULE_WEEK },
  };
};

const formatEmployerSchedule = (
  schedule,
  employerTimezone = '',
) => {
  try {
    const startDate = parseZonedISO(
      schedule.startDate,
      employerTimezone,
    );
    if (!isValid(startDate))
      throw new Error(
        'Invalid start date: check date or timezone',
      );
    const endDate = addWeeks(startDate, schedule.length || 1);
    const selectedCriteria = {
      industry: schedule.industry || null,
      skills: schedule.skills || null,
      careerHealthScore: schedule.careerHealthScore || null,
      experience: schedule.experience || null,
    };
    let scheduleSlots = schedule.schedule || {};
    if (Object.keys(scheduleSlots).length < 7) {
      scheduleSlots = {
        ...DEFAULT_SCHEDULE_WEEK,
        ...scheduleSlots,
      };
    }
    return {
      id: schedule.id,
      startDate,
      endDate,
      length: schedule.length || 1,
      count: schedule.count || DEFAULT_COUNT,
      schedule: scheduleSlots,
      ...selectedCriteria,
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};

const getClosestScheduleIdx = (formattedSchedules, date) => {
  const validDates = formattedSchedules.filter(
    (schedule) => schedule.startDate && schedule.endDate,
  );
  let closestNextScheduleIdx = -1;
  let currentScheduleIdx = -1;

  // eslint-disable-next-line consistent-return
  forEach(validDates, ({ startDate, endDate }, i) => {
    const interval = {
      start: startDate,
      end: endDate,
    };
    const isWithin = isWithinInterval(date, interval);
    if (isWithin) {
      currentScheduleIdx = i;
      closestNextScheduleIdx = validDates[i + 1] ? i + 1 : 0;
      return false;
    }
    if (
      closestNextScheduleIdx === -1 &&
      isBefore(date, interval.start)
    )
      closestNextScheduleIdx = i;
  });

  return [currentScheduleIdx, closestNextScheduleIdx];
};

const getActualSchedules = (
  formattedSchedules,
  closestStartDate,
  currentDate,
) => {
  const newSchedules = [];
  let closestNextScheduleIdx = -1;
  let currentScheduleIdx = -1;

  forEach(formattedSchedules, (schedule, i) => {
    const { startDate, length, ...rest } = schedule;
    let obj;

    if (i === 0) {
      obj = {
        ...rest,
        length,
        startDate: closestStartDate,
        endDate: addWeeks(closestStartDate, length),
      };
    } else {
      const prevSchedule = newSchedules[i - 1];
      const originPrevEnd = formattedSchedules[i - 1].endDate;
      const gap = Math.floor(
        differenceInCalendarDays(startDate, originPrevEnd),
      );
      const newStartDate = addDays(prevSchedule.endDate, gap);
      obj = {
        ...rest,
        length,
        startDate: newStartDate,
        endDate: addWeeks(newStartDate, length),
      };
    }

    if (currentScheduleIdx === -1) {
      const [currentIdx] = getClosestScheduleIdx(
        [obj],
        currentDate,
      );
      currentScheduleIdx = currentIdx;
      closestNextScheduleIdx =
        currentScheduleIdx !== -1 &&
        formattedSchedules[currentScheduleIdx + 1]
          ? currentScheduleIdx + 1
          : 0;
    }

    newSchedules.push(obj);
  });

  return [
    newSchedules,
    currentScheduleIdx,
    closestNextScheduleIdx,
  ];
};

const checkSchedulesActuality = (
  formattedSchedules,
  currentDate,
) => {
  if (!formattedSchedules || !formattedSchedules.length) {
    return [[], -1, -1, true];
  }
  const now = currentDate || new Date();
  const scheduleStart = formattedSchedules[0].startDate;
  const scheduleEnd =
    formattedSchedules[formattedSchedules.length - 1].endDate;
  let schedules = [...formattedSchedules];
  let currentScheduleIdx = -1;
  let closestNextScheduleIdx = -1;
  let isSameSchedule = false;

  if (isBefore(scheduleEnd, now)) {
    // if all schedules are passed, generate actual schedule
    const totalDaysInSchedule = differenceInCalendarDays(
      scheduleEnd,
      scheduleStart,
    );
    const totalPassedDays = differenceInCalendarDays(
      now,
      scheduleEnd,
    );
    const totalPassedSchedules = Math.floor(
      totalPassedDays / totalDaysInSchedule,
    );
    let closestStartDate;

    if (totalPassedSchedules < 1) {
      closestStartDate = scheduleEnd;
    } else {
      const diff =
        totalPassedDays -
        totalPassedSchedules * totalDaysInSchedule;
      closestStartDate = subDays(now, diff);
    }

    const [newSchedules, currentIdx, closestIdx] =
      getActualSchedules(
        formattedSchedules,
        closestStartDate,
        now,
      );
    schedules = newSchedules;
    currentScheduleIdx = currentIdx;
    closestNextScheduleIdx = closestIdx;
    isSameSchedule = false;
  } else {
    const [currentIdx, closestIdx] = getClosestScheduleIdx(
      formattedSchedules,
      now,
    );
    currentScheduleIdx = currentIdx;
    closestNextScheduleIdx = closestIdx;
    isSameSchedule = true;
  }

  return [
    schedules,
    currentScheduleIdx,
    closestNextScheduleIdx,
    isSameSchedule,
  ];
};

const getCurrentWeekDates = (date = new Date()) => {
  const interval = {
    start: startOfWeek(date),
    end: endOfWeek(date),
  };
  const weekDates = eachDayOfInterval(interval);
  weekDates[weekDates.length - 1] = interval.end;
  return { ...interval, weekDates };
};

const filterSlotsForCurrentDay = (date, formattedSlots) =>
  formattedSlots.filter(({ date: slotDate }) =>
    isSameDay(date, slotDate),
  );

const filterSlotsBeforeDate = (date, formattedSlots) =>
  formattedSlots.filter(({ date: slotDate }) =>
    isBefore(slotDate, date),
  );

const filterSlotsSameOrAfterDate = (date, formattedSlots) =>
  formattedSlots.filter(
    ({ date: slotDate }) => !isBefore(slotDate, date),
  );

const parseScheduleTimeSlots = (
  formattedSchedule,
  employerTimezone = '',
  userTimezone = '',
) => {
  if (!formattedSchedule) return [];

  const {
    startDate,
    endDate,
    schedule: weekDaysWithTimeSlots,
    id,
    count,
  } = formattedSchedule;
  const allDatesInSchedule = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });
  // set endDate as it has more correct time which may differ from 00:00
  allDatesInSchedule[allDatesInSchedule.length - 1] = endDate;
  const scheduleEndInUserTimezone = convertDateToTZ(
    endDate,
    employerTimezone,
    userTimezone,
  );
  let formattedTimeSlots = [];

  allDatesInSchedule.forEach((date, i, arr) => {
    // all dates are expected to be in employer timezone
    const day = getDay(date);
    const convertedDaySlots = weekDaysWithTimeSlots[day].map(
      (strTimeSlot) => {
        const hoursStr = strTimeSlot.slice(0, 2);
        const minutesStr = strTimeSlot.slice(2);
        const strWithDelimiter = [
          hoursStr,
          '-',
          minutesStr,
        ].join('');
        const timeToDate = new Date(date);
        // convert employer string time to full date
        timeToDate.setHours(+hoursStr, +minutesStr, 0, 0);
        // then convert it to employee timezone
        const dateInUserTimezone = convertDateToTZ(
          timeToDate,
          employerTimezone,
          userTimezone,
        );
        const isPast = isBefore(date, new Date()); // @TODO: maybe today must be in user timezone
        // create slots only before schedule end date
        if (
          i === arr.length - 1 &&
          !isBefore(
            dateInUserTimezone,
            scheduleEndInUserTimezone,
          )
        )
          return;
        // eslint-disable-next-line consistent-return
        return {
          scheduleId: id,
          scheduleCount: count,
          key: strTimeSlot,
          _key: strWithDelimiter,
          date: dateInUserTimezone,
          dates: {
            utcFormattedDate: formatInTimeZone(
              dateInUserTimezone,
              'UTC',
              'yyyy-MM-dd',
            ),
            utcFormattedTime: formatInTimeZone(
              dateInUserTimezone,
              'UTC',
              'HH:mm:ss',
            ),
          },
          originWeekDayIdx: day,
          booked: false,
          outOfDayLimit: false,
          isPast,
        };
      },
    );
    const truthy = convertedDaySlots.filter(Boolean);
    const ordered = orderBy(
      truthy,
      [(o) => o.date.valueOf()],
      ['asc'],
    );
    formattedTimeSlots = [...formattedTimeSlots, ...ordered];
  });

  return formattedTimeSlots;
};

const makeInterviewSlotsByWeek = (
  formattedSchedules,
  startDate,
  employerTZ,
  userTZ,
) => {
  if (
    !formattedSchedules ||
    !formattedSchedules.length ||
    !startDate
  ) {
    return [];
  }

  const { weekDates } = getCurrentWeekDates(startDate);
  let actualSchedules = [...formattedSchedules];
  let currSlotsReCalcCounter = 0;
  let currentSlots = [];
  let initCurrIdx = -1;

  return weekDates.map((weekDayDate) => {
    const initCounter = currSlotsReCalcCounter;
    const date = startOfDay(weekDayDate);
    const [
      newActualSchedules,
      currentIdx,
      closestScheduleIdx,
      isSameSchedule,
    ] = checkSchedulesActuality(actualSchedules, date);
    let currDaySlots = [];
    actualSchedules = [...newActualSchedules];

    if (!isSameSchedule) currSlotsReCalcCounter += 1;

    if (currentIdx !== -1) {
      const currentSchedule = actualSchedules[currentIdx];
      const currentEndDate = currentSchedule.endDate;
      const nextStartDate =
        closestScheduleIdx !== -1
          ? actualSchedules[closestScheduleIdx].startDate
          : null;

      if (
        nextStartDate &&
        isSameDay(currentEndDate, nextStartDate)
      ) {
        // day could be on the edge of the two inner schedules (Schedule A and Schedule B)
        const currSlots = parseScheduleTimeSlots(
          currentSchedule,
          employerTZ,
          userTZ,
        );
        const nextSlots = parseScheduleTimeSlots(
          actualSchedules[closestScheduleIdx],
          employerTZ,
          userTZ,
        );
        currDaySlots = filterSlotsForCurrentDay(date, [
          ...currSlots,
          ...nextSlots,
        ]);
        const scheduleSlotsForCurrentDay = filterSlotsBeforeDate(
          currentEndDate,
          currDaySlots,
        );
        const nextScheduleSlotsForCurrentDay =
          filterSlotsSameOrAfterDate(
            nextStartDate,
            currDaySlots,
          );
        currDaySlots = [
          ...scheduleSlotsForCurrentDay,
          ...nextScheduleSlotsForCurrentDay,
        ];
      } else if (
        currentIdx > closestScheduleIdx &&
        isSameDay(currentEndDate, date)
      ) {
        // day could be on the edge of passed schedules and next one (Schedule B and Schedule A)
        const dateEnd = endOfDay(currentEndDate);
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const [newActualSchedules, currentIdx] =
          checkSchedulesActuality(actualSchedules, dateEnd);
        const nextSlots = parseScheduleTimeSlots(
          newActualSchedules[currentIdx],
          employerTZ,
          userTZ,
        );
        const nextScheduleSlotsForCurrentDay =
          filterSlotsSameOrAfterDate(
            newActualSchedules[currentIdx].startDate,
            filterSlotsForCurrentDay(date, nextSlots),
          );
        currDaySlots = [
          ...currDaySlots,
          ...nextScheduleSlotsForCurrentDay,
        ];
      } else {
        let formattedSlots = currentSlots;

        if (
          !currentSlots.length ||
          initCounter !== currSlotsReCalcCounter ||
          initCurrIdx !== currentIdx
        ) {
          // initCounter and currSlotsReCalcCounter are to prevent multiple formatInterviewSlotsBySchedule calls
          formattedSlots = parseScheduleTimeSlots(
            actualSchedules[currentIdx],
            employerTZ,
            userTZ,
          );
          currentSlots = formattedSlots;
          initCurrIdx = currentIdx;
        }

        currDaySlots = filterSlotsForCurrentDay(
          date,
          formattedSlots,
        );
      }
    }

    return currDaySlots;
  });
};

export {
  DEFAULT_SCHEDULE_WEEK,
  DEFAULT_SCHEDULE_CRITERIA,
  DEFAULT_COUNT,
  MIN_COUNT,
  formatEmployerSchedule,
  parseScheduleTimeSlots,
  makeInterviewSlotsByWeek,
  checkSchedulesActuality,
  getClosestScheduleIdx,
  getInitialEmployerSchedule,
  getCurrentWeekDates,
};
