import { MobileAppBanner } from 'components/shared';
import {
  BrandEntry,
  BrandHealthEntry,
  BrandPathEntry,
  BrandPulseEntry,
  EmployeeCareerProgress,
  EmployeeDashboard,
  EmployeeGrow,
  EmployeeJob,
  EmployeeProfile,
  EmployeeProgressConnections,
  EmployeeProgressSkills,
  EmployeeStillHired,
  EmployerJobDetails,
  EmployerJobs,
  EmployerJobsView,
  EmployerPlans,
  EmployerProfile,
  EmployerSchedule,
  EnterpriseDashboard,
  EnterpriseSearch,
  InterviewConfirmation,
  JobPosting,
  LocationEntry,
  LocationHealthEntry,
  LocationPathEntry,
  LocationPathManager,
  LocationPulseEntry,
  LocationUpcomingEventsEntry,
  LocationWorkforceEntry,
  NotFound,
  Onboarding,
  OrganizationEntry,
  PasswordReset,
  RetentionEntry,
  RootLayout,
  ShortLinkRouter,
  SignIn,
  SignUp,
  SurveyView,
  OnboardingNew,
  OnboardingPageLayout,
  OnboardingEdit,
  OnboardingFlow,
  OnboardingSetup,
  OnboardingAssignment,
  OnboardingDocuments,
  OnboardingResources,
  OnboardingResourceCreate,
  OnboardingAssignments,
  OnboardingDocumentCreate,
  OnboardingResourceEdit,
  OnboardingAssignmentCreate,
  OnboardingAssignmentEdit,
  OnboardingDocumentEdit,
} from 'containers';
import React, { memo, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate as Redirect,
} from 'react-router-dom';
import { useAppStoreSelector } from 'store';
import EmployerCareerDevelopment from '../components/Grow/employer';
import ReviewView from '../containers/employee/ReviewView';
import {
  Anniversary,
  Custom,
  Interactions,
  NewSurvey,
  Review,
  SendSurvey,
  Surveys,
} from '../containers/employer/retention';
import BrandWorkforceEntry from '../containers/employer/retention/Brand/BrandWorkforceEntry';
// import ProtectedEmployeeRoute from './ProtectedEmployeeRoute';
import ProtectedEmployerRoute from './ProtectedEmployerRoute';
import ProtectedOnboardingRoute from './ProtectedOnboardingRoute';
import ScrollToTop from './ScrollToTop';
import AllJobs from '../containers/employee/AllJobs';
import MyJobs from '../containers/employee/MyJobs';
import Trends from '../containers/employee/Trends';
import Badges from '../containers/employee/Badges';
import Ratings from '../containers/employee/Ratings';
import Salary from '../containers/employee/Salary';
import Quizzes from '../containers/employee/Quizzes';
import EmployeeOnboarding from '../containers/employee/Onboarding';
import Classes from '../containers/employee/Classes';
import Connections from '../containers/employee/Connections';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<RootLayout />}>
      <Route index element={<SignIn />} />
      <Route path="s">
        <Route path=":codeId" element={<ShortLinkRouter />} />
      </Route>
      <Route path="login" element={<SignIn />} />
      <Route path="verify" element={<SignIn />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="new-password" element={<PasswordReset />} />
      <Route
        path="onboarding"
        element={<ProtectedOnboardingRoute />}
      >
        <Route index element={<Onboarding />} />
      </Route>
      {/*// redirect employer-dashboard and enterprise-dashboard*/}
      <Route
        path="dashboard"
        element={<ProtectedEmployerRoute />}
      >
        <Route index element={<EnterpriseDashboard />} />
        <Route path="profile" element={<EmployerProfile />} />
        <Route path="plans" element={<EmployerPlans />} />
        <Route path="schedule" element={<EmployerSchedule />} />
        <Route
          path="grow"
          element={<EmployerCareerDevelopment />}
        />
        <Route path="search" element={<EnterpriseSearch />} />
        <Route path="jobs">
          <Route index element={<EmployerJobs />} />
          <Route path=":id" element={<EmployerJobDetails />}>
            <Route path="match" />
            <Route path="approved" />
            <Route path="starred" />
            <Route path="rejected" />
            <Route path="next-steps" />
          </Route>
          <Route path=":id/edit" element={<JobPosting />} />
          <Route path="new-job" element={<JobPosting />} />
        </Route>
        <Route
          path="onboarding"
          element={<OnboardingPageLayout />}
        >
          <Route index element={<OnboardingSetup />} />
          <Route path="create" element={<OnboardingNew />} />
          <Route path="edit/:id">
            <Route index element={<OnboardingEdit />} />
            <Route path={'flow'} element={<OnboardingFlow />} />
          </Route>
          <Route
            path="assign"
            element={<OnboardingAssignment />}
          />
          <Route path={'resources'}>
            <Route index element={<OnboardingResources />} />
            <Route
              path={'create'}
              element={<OnboardingResourceCreate />}
            />
            <Route
              path={'edit/:id'}
              element={<OnboardingResourceEdit />}
            />
          </Route>
          <Route path={'documents'}>
            <Route index element={<OnboardingDocuments />} />
            <Route
              path={'create'}
              element={<OnboardingDocumentCreate />}
            />
            <Route
              path={'edit/:id'}
              element={<OnboardingDocumentEdit />}
            />
          </Route>
          <Route path={'assignment'}>
            <Route index element={<OnboardingAssignments />} />
            <Route
              path={'create'}
              element={<OnboardingAssignmentCreate />}
            />
            <Route
              path={'edit/:id'}
              element={<OnboardingAssignmentEdit />}
            />
          </Route>
        </Route>
        <Route path="retention" element={<RetentionEntry />}>
          <Route index element={<OrganizationEntry />} />
          <Route path="brand" element={<BrandEntry />}>
            <Route path="path" element={<BrandPathEntry />} />
            <Route path="pulse" element={<BrandPulseEntry />} />
            <Route
              path="health"
              element={<BrandHealthEntry />}
            />
            <Route
              path="workforce"
              element={<BrandWorkforceEntry />}
            />
            <Route path="location" element={<LocationEntry />}>
              <Route
                path="path"
                element={<LocationPathEntry />}
              />
              <Route
                path="pulse"
                element={<LocationPulseEntry />}
              />
              <Route
                path="health"
                element={<LocationHealthEntry />}
              />
              <Route
                path="workforce"
                element={<LocationWorkforceEntry />}
              />
              <Route
                path="pathmanager"
                element={<LocationPathManager />}
              />
              <Route
                path="interactions"
                element={<Interactions />}
              >
                <Route path="surveys">
                  <Route index element={<Surveys />} />
                  <Route
                    path="new-survey"
                    element={<NewSurvey />}
                  />
                  <Route
                    path="send-survey/:surveyCampaignId"
                    element={<SendSurvey />}
                  />
                  <Route
                    path="upcoming-events"
                    element={<LocationUpcomingEventsEntry />}
                  />
                </Route>
                <Route
                  path="anniversary"
                  element={<Anniversary />}
                />
                <Route path="custom" element={<Custom />} />
                <Route path="review" element={<Review />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          paths={[
            '/employer-dashboard',
            '/enterprise-dashboard',
          ]}
          element={<Redirect to={'/dashboard'} />}
        />
      </Route>
      <Route
        path="employee-dashboard"
        element={<EmployeeDashboard />}
      >
        <Route index element={<EmployeeGrow />} />
        <Route
          path={'career-development'}
          element={<Redirect to={'/employee-dashboard'} />}
        />
        <Route path="profile" element={<EmployeeProfile />} />
        {/*redirect from previous route so no broken links*/}
        <Route
          path="employer-jobs/:companyId"
          element={
            <Redirect
              to={'/employee-dashboard/jobs/company/:companyId'}
            />
          }
        />
        {/*redirect from previous route so no broken links*/}
        <Route
          path=":id"
          element={
            <Redirect to={'/employee-dashboard/jobs/:id'} />
          }
        />
        <Route path={'jobs'}>
          <Route index element={<AllJobs />} />
          <Route path={':id'} element={<EmployeeJob />} />
          <Route
            path={'company/:companyId'}
            element={<EmployerJobsView />}
          />
        </Route>
        <Route path={'my-jobs'} element={<MyJobs />} />
        <Route path={'grow'} element={<EmployeeGrow />} />

        <Route path="trends" element={<Trends />} />
        <Route path="badges" element={<Badges />} />
        <Route path="ratings" element={<Ratings />} />
        <Route path="salary" element={<Salary />} />
        <Route path="connections" element={<Connections />} />
        <Route path="classes" element={<Classes />} />
        <Route path="quizzes" element={<Quizzes />} />
        <Route
          path="onboarding"
          element={<EmployeeOnboarding />}
        />

        <Route
          path="career-progress"
          element={<EmployeeCareerProgress />}
        >
          <Route
            path="skills"
            element={<EmployeeProgressSkills />}
          />
          <Route
            path="connections"
            element={<EmployeeProgressConnections />}
          />
          <Route
            path="still-hired"
            element={<EmployeeStillHired />}
          />
        </Route>
        <Route
          path="interview-confirmation"
          element={<InterviewConfirmation />}
        >
          <Route
            path=":confirmationId"
            element={<InterviewConfirmation />}
          />
        </Route>
        <Route path="survey/:code" element={<SurveyView />} />
        <Route path="review/:code" element={<ReviewView />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

const AppRouter = () => {
  const setExternalReferrer = useAppStoreSelector(
    (state) => state.setExternalReferrer,
  );

  useEffect(() => {
    setExternalReferrer(document.referrer);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRoutes />
      <MobileAppBanner />
    </BrowserRouter>
  );
};

AppRouter.propTypes = {};

export default memo(AppRouter);
