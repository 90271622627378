import React, { useEffect, useState } from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEmployeeOnboarding } from "hooks";
import get from 'lodash/get';
import { useLazyQuery } from '@apollo/client';
import { RetentionNavigation } from '../Retention/components/RetentionNavigation';
import { getRoutes } from '../../utils';
import { GET_COMPANY_THEME_BY_EMPLOYEE } from '../../api/queries/companyTheme';
import { buildAndSetTheme } from '../../styles/utils';
import {
  paletteDarkBase,
  paletteLightBase,
} from '../../styles/colors';
import { useAppStoreSelector } from '../../store';

const EmployeeNavigation = () => {
  const isOnboarding = localStorage.getItem('isOnboarding');
  const [showOnboarding, setShowOnboarding] = useState<boolean>(
    isOnboarding === 'true',
  );
  const [disableNav, setDisableNav] = useState(false);
  const navigate = useNavigate();
  const routes = getRoutes();
  const location = useLocation();

  const { status } = useEmployeeOnboarding();
  const themeMode = localStorage.getItem('themeMode') || 'dark';
  const basePalette =
    themeMode === 'dark' ? paletteDarkBase : paletteLightBase;
  const { setTheme } = useAppStoreSelector(
    ({ setTheme: st }) => ({
      setTheme: st,
    }),
  );

  const [getEmployeeTheme] = useLazyQuery(
    GET_COMPANY_THEME_BY_EMPLOYEE,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
        const savedColors = data.getCompanyThemeByEmployee;
        if (savedColors) {
          const colorsToSet = [
            savedColors.primaryColor,
            savedColors.secondaryColor,
            savedColors.tertiaryColor,
            savedColors.quaternaryColor,
          ];
          buildAndSetTheme({
            colorsToBuildTheme: colorsToSet,
            colorScheme: [
              { name: 'Brand Main', color: basePalette.primary },
              {
                name: 'Brand Secondary',
                color: basePalette.secondary,
              },
              {
                name: 'Brand Tertiary',
                color: basePalette.tertiary,
              },
              {
                name: 'Brand Quaternary',
                color: basePalette.quaternary,
              },
            ],
            setTheme,
          });
        }
      },
    },
  );

  useEffect(() => {
    // fetch onboarding status on initial load to see if
    // navigation is necessary
    status.get();
    getEmployeeTheme();
  }, []);

  useEffect(() => {
    if (status.data) {
      const { onboardingStatus } = status.data;
      if (onboardingStatus.status === 'DOES_NOT_EXIST') {
        setShowOnboarding(false);
        setDisableNav(false);
      }
      if (onboardingStatus.complete) {
        // onboarding is complete. or does not exist, let them navigate freely.
        setDisableNav(false);
        if (!isOnboarding) {
          localStorage.setItem('isOnboarding', 'true');
        }
        setShowOnboarding(true);
      }
      if (
        onboardingStatus.status === 'REQUESTED' ||
        onboardingStatus.status === 'IN_PROGRESS'
      ) {
        setShowOnboarding(true);
        setDisableNav(true);
        if (!isOnboarding) {
          localStorage.setItem('isOnboarding', 'true');
        }
        if (location.pathname.indexOf('onboarding') < 0) {
          navigate(routes.employee.onboarding);
        }
      }
    }
  }, [status.data, isOnboarding]);

  useEffect(() => {
    if (get(location, 'state.refetchNavigation', null)) {
      setDisableNav(false);
      // set nav boundaries.
      // so basically we need to rework how the nav is managed. We will need a management api by organization to discern what buttons are available. If we hardcode that ability now we can save 15 hours down the road.
      // create a table, (employeeId, route, access, name)
    }
  }, [location.pathname]);

  const buttons = [
    {
      disabled: disableNav,
      label: 'Path',
      onClick: () => navigate(routes.employee.dashboard),
      testId: 'navigate-to-employee-path',
      Icon: FlagIcon,
      selected: location.pathname === routes.employee.dashboard,
    },
    {
      disabled: disableNav,
      label: 'Trends',
      onClick: () => navigate(routes.employee.trends),
      testId: 'navigate-to-employee-trends',
      Icon: TrendingUpIcon,
      selected: location.pathname === routes.employee.trends,
    },
    {
      disabled: disableNav,
      label: 'Ratings',
      onClick: () => navigate(routes.employee.ratings),
      testId: 'navigate-to-employee-ratings',
      Icon: ThumbsUpDownIcon,
      selected: location.pathname === routes.employee.ratings,
    },
    {
      disabled: disableNav,
      label: 'Salary',
      onClick: () => navigate(routes.employee.salary),
      testId: 'navigate-to-employee-salary',
      Icon: PriceChangeIcon,
      selected: location.pathname === routes.employee.salary,
    },
    {
      disabled: disableNav,
      label: 'Connections',
      onClick: () => navigate(routes.employee.connections),
      testId: 'navigate-to-employee-connections',
      Icon: GroupAddIcon,
      selected:
        location.pathname === routes.employee.connections,
    },
    {
      disabled: disableNav,
      label: 'Classes',
      onClick: () => navigate(routes.employee.classes),
      testId: 'navigate-to-employee-classes',
      Icon: LocalLibraryIcon,
      selected: location.pathname === routes.employee.classes,
    },
    {
      disabled: disableNav,
      label: 'Jobs',
      onClick: () => navigate(routes.employee.jobs),
      testId: 'navigate-to-employee-jobs',
      Icon: WorkIcon,
      selected: location.pathname.indexOf('jobs') >= 1,
    },
    {
      disabled: disableNav,
      label: 'Learnings',
      onClick: () => navigate(routes.employee.quizzes),
      testId: 'navigate-to-employee-quizzes',
      Icon: SchoolIcon,
      selected: location.pathname === routes.employee.quizzes,
    },
  ];

  if (showOnboarding) {
    buttons.push({
      disabled: false,
      label: 'Onboarding',
      onClick: () => navigate(routes.employee.onboarding),
      testId: 'navigate-to-employee-onboarding',
      Icon: DownloadIcon,
      selected: location.pathname === routes.employee.onboarding,
    });
  }

  return (
    <RetentionNavigation showLogo={false} buttons={buttons} />
  );
};
export default EmployeeNavigation;
