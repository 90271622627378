import { gql } from '@apollo/client';

export const POST_EMPLOYEE_TO_LOCATION_BY_EMAIL = gql`
  mutation AddEmployeeToLocation(
    $locationId: Int!
    $employeeEmail: String
    $employeePhone: String
  ) {
    addEmployeeToLocation(
      locationId: $locationId
      employeeEmail: $employeeEmail
      employeePhone: $employeePhone
    ) {
      employeeId
      locationEmployeeId
      locationId
      userId
    }
  }
`;

export const POST_EMPLOYEE_TO_LOCATION_BY_CSV = gql`
  mutation CreateEmployeesFromCSV(
    $locationId: Int!
    $csvFile: Upload
  ) {
    createEmployeesFromCSV(
      locationId: $locationId
      csvFile: $csvFile
    ) {
      messageId
      status
    }
  }
`;
