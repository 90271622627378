const WorkTorchTIcon = ({
  fill = 'none',
}: {
  fill?: string;
}) => (
  <svg
    width="250"
    height="250"
    viewBox="0 0 250 250"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.65 80.6829C103.145 55.694 105.537 61.1555 104.05 58.0555C102.116 54.0254 97.0488 45.6024 88.1524 42.7242C90.9073 41.3615 92.5685 41.0574 92.5685 41.0574C106.103 37.3617 121.836 43.2128 128.765 57.3458C130.882 59.9616 138.868 83.205 147.04 72.5046C153.712 62.8425 137.998 47.7467 129.429 44.465C139.604 41.3253 149.538 40.8167 158.832 46.9153C177.631 59.3597 175.749 89.9954 155.334 99.3739C138.886 107.356 119.185 99.7075 112.65 80.6829Z"
      fill={'inherit'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.687 75.8175C101.615 75.6717 101.546 75.5293 101.478 75.3908C100.945 74.2985 100.531 73.4503 100.265 73.1216C95.9184 64.2552 86.0484 60.5846 77.5574 62.9031C77.5574 62.9031 76.5152 63.0938 74.7869 63.9487C80.368 65.7544 83.5471 71.0385 84.76 73.5668C84.9685 74.0014 85.0554 74.1678 85.1313 74.3391C85.395 74.9343 85.5249 75.5887 90.1555 87.7621C94.2553 99.6971 106.614 104.495 116.933 99.4878C116.977 99.4674 117.022 99.4469 117.066 99.4262C112.148 97.3693 108.009 93.1766 105.951 87.1857C103.491 80.7167 102.301 77.5005 101.687 75.8175Z"
      fill={'inherit'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.892 216.734L146.94 150.677L139.365 146.027L172.607 134.514V118.394H83.7435V134.271L117.686 146.027L108.353 151.756L112.851 216.734H143.892Z"
      fill={'inherit'}
    />
  </svg>
);

export default WorkTorchTIcon;
