import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { Dialog, Pagination, styled } from 'components';
import { IconButton, Spinner } from 'components/shared';
import { MdClose, MdErrorOutline } from 'components/icons';
import { getUserId } from 'utils';
import {
  GET_APPROVED_APPLICANTS_MESSAGES,
  GET_APPROVED_JOBS_MESSAGES,
} from 'api';
import styles from 'styles/Chat/ChatDialog';
import Chat from './Chat';
import ChatItem from './ChatItem';
import ChatDialogHeader from './ChatDialogHeader';
import { ApplicantProfileDialog } from '../dialogs';
import { IEmployeeProfile } from '../../api/types/queries/employeeProfile.types';

const StyledDialog = styled(Dialog)(styles);

const VIEWS_MAP = {
  messages: 'matches',
  chat: 'chat',
};

const sortMessages = (messages) => {
  const fullChats = messages.filter((o) => o.message);
  const sortedByDate = sortBy(fullChats, (o) =>
    new Date(o.updatedAt).valueOf(),
  );
  const sortedByNew = sortBy(
    sortedByDate,
    (o) => o.messagesCount > 0,
  ).reverse();
  return sortedByNew;
};

interface IChatDialog {
  dialogProps: typeof Object;
  isOpen: boolean;
  initialView: keyof typeof VIEWS_MAP;
  initialChatViewProps: {
    jobId: number;
    receiverUserId: number;
  };
  onClose: () => void;
  role: 'employee' | 'employer';
}

interface IChatMessage {
  createdAt: string;
  id: number;
  jobs: {
    id: number;
    imageUrl: string;
    title: string;
  };
  message: string;
  messageFrom: number;
  messageTo: number;
  messagesCount: number;
  updatedAt: string;
  users: {
    employeeProfile: null | Partial<IEmployeeProfile>;
  };
  usersUserId: number;
}

const ChatDialog: React.FC<IChatDialog> = (props) => {
  const {
    dialogProps = {},
    isOpen = false,
    initialView = VIEWS_MAP.messages,
    initialChatViewProps = {},
    onClose,
    role,
  } = props;
  const messageLimit = 30;
  const [employeeUserId, setEmployeeUserId] = useState<
    number | null
  >(null);
  const uid = getUserId();
  const [view, setView] = useState(initialView);
  const [chatViewProps, setChatViewProps] = useState(
    initialChatViewProps,
  );
  const [isTipOpen, setIsTipOpen] = useState(
    role === 'employee',
  );

  const [
    fetchEmployeeMessages,
    {
      data: employeeMessagesData,
      loading: employeeMessagesLoading,
    },
  ] = useLazyQuery(GET_APPROVED_JOBS_MESSAGES, {
    fetchPolicy: 'no-cache',
  });
  const employeeMessages = useMemo(() => {
    const messages =
      employeeMessagesData?.getApprovedJobMessages || [];
    return sortMessages(messages);
  }, [JSON.stringify(employeeMessagesData)]);

  const [
    fetchEmployerMessages,
    {
      data: employerMessagesData,
      loading: employerMessagesLoading,
      refetch: employerMessagesRefetch,
    },
  ] = useLazyQuery(GET_APPROVED_APPLICANTS_MESSAGES, {
    fetchPolicy: 'no-cache',
  });
  const employerMessages = useMemo(() => {
    const messages =
      employerMessagesData?.getApprovedEmployerJobMessages
        .data || [];
    return sortMessages(messages);
  }, [JSON.stringify(employerMessagesData)]) as IChatMessage[];

  useEffect(() => {
    if (view === VIEWS_MAP.messages) {
      if (role === 'employer')
        fetchEmployerMessages({
          variables: {
            usersUserId: Number(uid),
            limit: messageLimit,
          },
        });
      else
        fetchEmployeeMessages({
          variables: { usersUserId: Number(uid) },
        });
    }
  }, [view, role]);

  const handleOpenProfile = (userId: number) => () => {
    setEmployeeUserId(userId);
  };

  const handleFetchNewMessages = (event, value) => {
    employerMessagesRefetch({
      offset: value * messageLimit - messageLimit,
    });
  };

  const renderMessagesList = () => {
    const messages =
      role === 'employer' ? employerMessages : employeeMessages;
    const isLoading =
      employeeMessagesLoading || employerMessagesLoading;

    return (
      <>
        <ChatDialogHeader
          title="Inbox"
          withCloseButton
          onClose={onClose}
        />
        {isLoading || !messages.length ? (
          <div className="messagesContent empty">
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="emptyMessagesMessage">{`No matches yet. ${
                role === 'employee'
                  ? 'Apply for jobs first to get some matches'
                  : ''
              }`}</div>
            )}
          </div>
        ) : (
          <div className="messagesContent">
            {messages.map((obj) => (
              <ChatItem
                key={obj.id}
                role={role}
                imageUrl={
                  obj?.users?.employeeProfile?.imageUrl || ''
                }
                title={obj?.jobs?.title || ''}
                subTitle={
                  obj?.users?.employeeProfile?.name || ''
                }
                message={obj.message}
                messagesCount={obj.messagesCount}
                onClick={() => {
                  setChatViewProps({
                    jobId: obj?.jobs?.id,
                    receiverUserId:
                      role === 'employer'
                        ? obj.messageFrom
                        : obj.messageTo,
                    openProfile:
                      role === 'employer' &&
                      handleOpenProfile(obj.messageFrom),
                  });
                  setView(VIEWS_MAP.chat);
                }}
              />
            ))}
            {isTipOpen && (
              <div className="tipMessage">
                <MdErrorOutline color="inherit" />
                <p className="tipMessage__text">
                  A response within 48 hours will increase your
                  chances for hire
                </p>
                <IconButton
                  edge="end"
                  color="primary"
                  sx={{ ml: '20px', mr: '-10px', p: '10px' }}
                  onClick={() => setIsTipOpen(false)}
                >
                  <MdClose color="inherit" />
                </IconButton>
              </div>
            )}
          </div>
        )}
        <Pagination
          boundaryCount={1}
          count={
            employerMessagesData?.getApprovedEmployerJobMessages
              .pages || 0
          }
          onChange={handleFetchNewMessages}
        />
      </>
    );
  };

  return (
    <StyledDialog
      open={isOpen}
      fullWidth
      classes={{ paper: 'paper', container: 'container' }}
      BackdropProps={{
        classes: { root: 'backdrop' },
      }}
      scroll="paper"
      onClose={onClose}
      {...dialogProps}
    >
      {view === VIEWS_MAP.messages ? (
        renderMessagesList()
      ) : (
        <Chat
          role={role}
          onClose={() => setView(VIEWS_MAP.messages)}
          {...chatViewProps}
        />
      )}
      <ApplicantProfileDialog
        isOpen={employeeUserId != null}
        id={employeeUserId}
        withActions
        onClose={() => setEmployeeUserId(null)}
        className={'secondaryProfileDialog'}
      />
    </StyledDialog>
  );
};

export default ChatDialog;
