import { SyntheticEvent, useEffect, useState } from 'react';
import {
  FieldValues,
  useForm,
  useFieldArray,
} from 'react-hook-form';
import { Button, Spinner, Input } from 'components/shared';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import {
  UPDATE_CERTIFICATES,
  UPDATE_EMPLOYEE_PROFILE_CV,
  UPDATE_EMPLOYEE_PROFILE_IMG,
  UPDATE_EMPLOYEE_PROFILE_VIDEO,
  UPDATE_USER_EDUCATION,
  UPDATE_USER_EXP,
  UPDATE_USER_INDUSTRY,
  UPDATE_USER_PROFILE,
  UPDATE_USER_SKILLS,
} from 'api';
import {
  useCountryStateQuery,
  useEmployeeTrainings,
  useMediaQueryMatches,
} from '../../../hooks';
import {
  IEmployeeProfileLogicProps,
  IRenderWithControllerArgs,
} from './types';
import {
  formatCertificates,
  formatEducation,
  formatExperience,
  formatIndustries,
  validateCurrent,
  validateDateAfter,
  validateDateBefore,
  validatePhone,
  validateZip,
} from './utils';
import { getProfileId } from '../../../utils';
import { IEmployeeProfile } from '../../../api/types/queries/employeeProfile.types';

const ProfileLogic = ({
  profile,
  sectionLayoutProps,
  render,
}: IEmployeeProfileLogicProps) => {
  const [employeeProfile, setEmployeeProfile] =
    useState<IEmployeeProfile | null>(null);
  const profileId = Number(getProfileId());
  const [activeImageUrl, setActiveImageUrl] = useState<
    string | null
  >(null);
  const [activeVideoUrl, setActiveVideoUrl] = useState<
    string | null
  >(null);
  const [activeResumeUrl, setActiveResumeUrl] = useState<
    string | null
  >(null);
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { fetchCountryState, countries, states } =
    useCountryStateQuery();

  const { fetchTrainings } = useEmployeeTrainings();

  const [
    postEmployeeUpdate,
    { loading: postProfileLoading = false },
  ] = useMutation(UPDATE_USER_PROFILE);
  const [
    postEmployeeEducationUpdate,
    { loading: postEducationLoading = false },
  ] = useMutation(UPDATE_USER_EDUCATION);
  const [
    postEmployeeExperienceUpdate,
    { loading: postExperienceLoading = false },
  ] = useMutation(UPDATE_USER_EXP);
  const [
    postEmployeeIndustriesUpdate,
    { loading: postIndustriesLoading = false },
  ] = useMutation(UPDATE_USER_INDUSTRY);
  const [
    postEmployeeSkillsUpdate,
    { loading: postSkillsLoading = false },
  ] = useMutation(UPDATE_USER_SKILLS);
  const [
    updateCertificates,
    { loading: updateCertificatesLoading = false },
  ] = useMutation(UPDATE_CERTIFICATES);
  const [postEmployeeProfileImg] = useMutation(
    UPDATE_EMPLOYEE_PROFILE_IMG,
  );
  const [postEmployeeProfileVideo] = useMutation(
    UPDATE_EMPLOYEE_PROFILE_VIDEO,
  );
  const [postEmployeeProfileCV] = useMutation(
    UPDATE_EMPLOYEE_PROFILE_CV,
  );

  const profileUpdateLoading =
    updateCertificatesLoading ||
    postProfileLoading ||
    postIndustriesLoading ||
    postEducationLoading ||
    postExperienceLoading ||
    postSkillsLoading;

  useEffect(() => {
    fetchCountryState();
    fetchTrainings();
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    getValues,
  } = useForm<IEmployeeProfile>({
    defaultValues: {
      address: profile.address,
      age: profile.age,
      countryId: profile.countryId,
      city: profile.city,
      gender: profile.gender,
      imageUrl: profile.imageUrl,
      name: profile.name,
      newImageFile: profile.newImageFile,
      newVideoFile: profile.newVideoFile,
      newResumeFile: profile.newResumeFile,
      motto: profile.motto,
      phone: profile.phone,
      stateId: profile.stateId,
      resumeUrl: profile.resumeUrl,
      videoUrl: profile.videoUrl,
      zip: profile.zip,
      race: profile.race,
      website: profile.website,
      certificates: [],
      education: [],
      experience: [],
      industry: [],
      skills: [],
    },
  });
  const { errors } = formState;
  const skillsFields = useFieldArray({
    name: 'skills',
    control,
  });
  const certificateFields = useFieldArray({
    name: 'certificates',
    control,
  });
  const educationFields = useFieldArray({
    name: 'education',
    control,
  });
  const experienceFields = useFieldArray({
    name: 'experience',
    control,
  });
  const industryFields = useFieldArray({
    name: 'industry',
    control,
  });

  useEffect(() => {
    if (profile && profile.name) {
      setEmployeeProfile(profile);
      setValue('address', profile.address);
      setValue('age', profile.age);
      setValue('countryId', profile.countryId);
      setValue('city', profile.city);
      setValue('gender', profile.gender);
      setValue('imageUrl', profile.imageUrl);
      setValue('name', profile.name);
      setValue('newImageFile', profile.newImageFile);
      setValue('newVideoFile', profile.newVideoFile);
      setValue('newResumeFile', profile.newResumeFile);
      setValue('motto', profile.motto);
      setValue('phone', profile.phone);
      setValue('stateId', profile.stateId);
      setValue('resumeUrl', profile.resumeUrl);
      setValue('videoUrl', profile.videoUrl);
      setValue('zip', profile.zip);
      setValue('race', profile.race);
      setValue('website', profile.website);

      certificateFields.replace(profile.certificates);
      educationFields.replace(profile.education);
      experienceFields.replace(profile.experience);
      industryFields.replace(profile.industry);
      skillsFields.replace(profile.skills);

      setActiveImageUrl(profile.imageUrl);
      setActiveVideoUrl(profile.videoUrl);
      setActiveResumeUrl(profile.resumeUrl);
    }
  }, [profile]);

  const handleFile = (file, set) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      set(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleImageChange = (e: SyntheticEvent) => {
    const file = e.target.files?.[0];
    handleFile(file, setActiveImageUrl);
  };

  const handleVideoChange = (e: SyntheticEvent) => {
    const file = e.target.files?.[0];
    handleFile(file, setActiveVideoUrl);
  };

  const handleResumeChange = (e: SyntheticEvent) => {
    const file = e.target.files?.[0];
    setActiveResumeUrl(file.name as string);
  };

  const handleSave = async (fieldValues: FieldValues) => {
    const {
      imageUrl: image,
      videoUrl: video,
      resumeUrl: resume,
      address,
      name,
      gender,
      motto,
      phone,
      city,
      countryId,
      stateId,
      zip,
      experience,
      education,
      industry,
      skills,
      certificates,
    } = fieldValues;
    const {
      education: educationDirty,
      experience: experienceDirty,
      certificates: certificatesDirty,
    } = formState.touchedFields;
    if (educationDirty) {
      await postEmployeeEducationUpdate({
        variables: {
          education: formatEducation(education),
        },
      });
    }
    if (experienceDirty) {
      await postEmployeeExperienceUpdate({
        variables: {
          experience: formatExperience(experience),
        },
      });
    }
    if (certificatesDirty) {
      await updateCertificates({
        variables: {
          certificates: formatCertificates(certificates),
          employeeProfileId: profileId,
        },
      });
    }
    if (image && typeof image !== 'string') {
      await postEmployeeProfileImg({
        variables: {
          file: image[0],
        },
      });
    }
    if (video && typeof video !== 'string') {
      await postEmployeeProfileVideo({
        variables: {
          file: video[0],
        },
      });
    }
    if (resume && typeof resume !== 'string') {
      await postEmployeeProfileCV({
        variables: {
          file: resume[0],
        },
      });
    }
    await postEmployeeIndustriesUpdate({
      variables: {
        industry: formatIndustries(industry),
      },
    });
    await postEmployeeSkillsUpdate({
      variables: {
        skills,
      },
    });
    await postEmployeeUpdate({
      variables: {
        address,
        name,
        gender,
        motto,
        phone,
        city,
        countryId,
        stateId,
        zip,
      },
    });
  };

  const sectionHeader =
    isDesktop || sectionLayoutProps.expanded ? (
      <Button
        variant={'contained'}
        color={'primary'}
        className={clsx('profile__headerAction')}
        disabled={profileUpdateLoading}
        endIcon={
          profileUpdateLoading ? <Spinner size={12} /> : null
        }
        onClick={handleSubmit(handleSave)}
        testID={`employee-profile-${'save'}-button`}
      >
        Save
      </Button>
    ) : null;

  const RenderFormInput = ({
    name: fieldName,
    registerOptions = {},
    label,
    type = 'text',
    disabled,
    error,
  }: IRenderWithControllerArgs) => (
    <Input
      {...register(fieldName, registerOptions)}
      type={type}
      label={label}
      error={error}
      withHelperText
      disabled={disabled}
      FormControlProps={{
        fullWidth: !isDesktop,
      }}
    />
  );

  return render({
    form: {
      certificates: certificateFields,
      education: educationFields,
      experience: experienceFields,
      industries: industryFields,
      skills: skillsFields,
      register,
      errors,
      control,
      validate: {
        // TS Should notify you, but these are three curried functions () => () => () => to get the required info at different steps
        zip: validateZip(getValues()),
        phone: validatePhone,
        dateBefore: validateDateBefore(getValues()),
        dateAfter: validateDateAfter(getValues()),
        current: validateCurrent(getValues()),
      },
    },
    loading: profileUpdateLoading,
    countries,
    states,
    sectionLayoutProps,
    sectionHeader,
    employeeProfile,
    employeeProfileLoading: employeeProfile == null,
    isDesktop,
    RenderFormInput,
    video: {
      value: activeVideoUrl,
      set: handleVideoChange,
    },
    image: {
      value: activeImageUrl,
      set: handleImageChange,
    },
    resume: {
      value: activeResumeUrl,
      set: handleResumeChange,
    },
  });
};

export default ProfileLogic;
