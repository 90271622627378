import React, { useContext } from 'react';
import { Box, styled } from 'components';
import { useQuery } from '@apollo/client';
import { GET_BRAND_STATS } from 'api';
import { Avatar, Button, Spinner } from 'components/shared';
import { Typography } from '@mui/material';
import Favorite from '@mui/icons-material/Favorite';
import ForkRight from '@mui/icons-material/ForkRight';
import MonitorHeart from '@mui/icons-material/MonitorHeart';
import styles from 'styles/Retention/Organization/OrganizationBrandCard';
import { getStatusColorClass } from 'styles/utils';
import { useNavigate } from 'react-router-dom';
import { getRoutes } from 'utils';
import { IValueType, valueTypes } from './types';
import { RetentionContext } from '../../utils';

const StyledBox = styled(Box)(styles);

const OrganizationBrands = () => {
  const ctx = useContext(RetentionContext);
  const navigate = useNavigate();
  const routes = getRoutes();

  const { data, errors, loading } = useQuery(GET_BRAND_STATS, {
    variables: { organizationId: ctx.organizationId },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  if (loading) {
    return <Spinner />;
  }

  if (errors) {
    return <Typography>{errors[0].message}</Typography>;
  }

  const handleNumberFormat = ({
    total,
    value,
    valueType,
  }: IValueType) => {
    if (valueType === valueTypes.INT) {
      return (
        <Typography variant={'h3'}>
          <span className={getStatusColorClass(value)}>
            {value}
          </span>
          /{total}
        </Typography>
      );
    }

    const percent = value / total || 0;

    return (
      <Typography
        variant={'h3'}
        className={getStatusColorClass(percent * 100)}
      >
        {(percent * 100).toFixed()}%
      </Typography>
    );
  };

  return (
    <StyledBox>
      {data?.getBrandsStats?.brands.map((brand) => (
        <Box
          key={brand.brand.id}
          className={'brand__cardContainer'}
        >
          <Box className={'brand__imageName'}>
            <Box className={'brand__profileImage'}>
              <Avatar
                url={brand.brand.logoUrl}
                type={'COMPANY'}
              />
            </Box>
            <Typography variant={'h2'}>
              {brand.brand.name}
            </Typography>
          </Box>
          <Box className={'brand__locations'}>
            <Typography variant={'h3'}>
              {brand.locationCount} locations
            </Typography>
          </Box>
          <Box className={'brand__scoreContainer'}>
            <Box className={'brand__scoreRow'}>
              <MonitorHeart />{' '}
              {handleNumberFormat(brand.interactionsScore)}{' '}
              <Typography
                variant={'h4'}
                className={'brand__scoreTitle'}
              >
                Interaction score
              </Typography>
            </Box>
            <Box className={'brand__scoreRow'}>
              <ForkRight />{' '}
              {handleNumberFormat(brand.skillsCompleted)}{' '}
              <Typography
                variant={'h4'}
                className={'brand__scoreTitle'}
              >
                Skills completed
              </Typography>
            </Box>
            <Box className={'brand__scoreRow'}>
              <Favorite />{' '}
              {handleNumberFormat(brand.employeePulse)}{' '}
              <Typography
                variant={'h4'}
                className={'brand__scoreTitle'}
              >
                Employee Pulse
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 5,
            }}
          >
            <Button
              variant={'filled-secondary'}
              onClick={() => {
                ctx.setRetentionContext({
                  brandId: brand.brand.id,
                  brandName: brand.brand.name,
                });
                navigate(
                  `${routes.employer.retention_portal.brand}/${ctx.retentionPages.path}`,
                );
              }}
            >
              View Brand Locations
            </Button>
          </Box>
        </Box>
      ))}
    </StyledBox>
  );
};

export default OrganizationBrands;
