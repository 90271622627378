import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUrlParameter, isIos } from 'utils';
import { APP_STORE_URL, PLAY_MARKET_URL, TRACKING_PARAM } from 'utils/app';
import { useMediaQueryMatches } from 'hooks';
import { MuiIconButton, styled } from 'components';
import { MdArrowForward, MdClose } from '../../icons';

const STORE_LINK = isIos() ? APP_STORE_URL : PLAY_MARKET_URL;

const StyledRoot = styled('div')(({ theme }) => ({
  '&.container': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999999999,
    padding: '5px 18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.quaternary.main,
    transform: 'translateY(100%)',
    transition: 'transform 1s',
    '&.visible': {
      transform: 'translateY(0)'
    }
  },
  '& .title': {
    flex: 1,
    textAlign: 'center',
    fontSize: 19,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '21px',
    color: '#FFF'
  },
  '& .link': {
    flex: 1,
    padding: '10px 0',
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const MobileAppBanner = ({ isMock = false }) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = !isMock ? useLocation() : {};
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isMock || (TRACKING_PARAM && getUrlParameter(location?.search, TRACKING_PARAM)))
      setVisible(true);
  }, []);

  return (
    (!isDesktop || isMock) && (
      <StyledRoot className={`container ${visible ? 'visible' : ''}`}>
        <MuiIconButton edge="start" onClick={() => setVisible(false)}>
          <MdClose style={{ color: '#FFF' }} />
        </MuiIconButton>
        <a href={STORE_LINK} target="_blank" rel="noopener noreferrer" className="link">
          <div className="title">Download Our App!</div>
          <MdArrowForward color="primary" />
        </a>
      </StyledRoot>
    )
  );
}

MobileAppBanner.propTypes = {};

export default MobileAppBanner;
