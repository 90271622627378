import values from 'lodash/values';

interface IGetRoutesParams {
  id?: string | number;
  companyId?: string;
  confirmationId?: string;
  code?: string;
}

export const getRoutes = (
  params: IGetRoutesParams = {
    id: null,
    companyId: null,
    confirmationId: null,
    code: null,
  },
) => {
  const idParam = params?.id || ':id';
  const companyIdParam = params?.companyId || ':companyId';
  const confirmationIdParam =
    params?.confirmationId || ':confirmationId';
  const surveyParam = params?.code || ':code';
  const employerDashboard = '/dashboard';
  const employeeDashboard = '/employee-dashboard';

  return {
    employer: {
      dashboard: `${employerDashboard}`,
      profile: `${employerDashboard}/profile`,
      plans: `${employerDashboard}/plans`,
      enterprise: `${employerDashboard}`,
      enterprise_search: `${employerDashboard}/search`,
      enterprise_schedule: `${employerDashboard}/schedule`,
      enterprise_grow: `${employerDashboard}/grow`,
      // !place all ${employerDashboard}/... paths before job
      jobs: `${employerDashboard}/jobs`,
      job: `${employerDashboard}/jobs/${idParam}`,
      job_new: `${employerDashboard}/jobs/new-job`,
      job_edit: `${employerDashboard}/jobs/${idParam}/edit`,
      job_match: `${employerDashboard}/jobs/${idParam}/match`,
      job_approved: `${employerDashboard}/jobs/${idParam}/approved`,
      job_starred: `${employerDashboard}/jobs/${idParam}/starred`,
      job_rejected: `${employerDashboard}/jobs/${idParam}/rejected`,
      job_next_steps: `${employerDashboard}/jobs/${idParam}/next-steps`,
      onboarding: {
        root: `${employerDashboard}/onboarding`,
        create: `${employerDashboard}/onboarding/create`,
        edit: `${employerDashboard}/onboarding/edit/${idParam}`,
        flow: `${employerDashboard}/onboarding/edit/${idParam}/flow`,
        assign: `${employerDashboard}/onboarding/assign`,
        resources: {
          list: `${employerDashboard}/onboarding/resources`,
          edit: `${employerDashboard}/onboarding/resources/edit/${idParam}`,
          create: `${employerDashboard}/onboarding/resources/create`,
        },
        documents: {
          list: `${employerDashboard}/onboarding/documents`,
          edit: `${employerDashboard}/onboarding/documents/edit/${idParam}`,
          create: `${employerDashboard}/onboarding/documents/create`,
        },
      },
      retention_portal: {
        root: `${employerDashboard}/retention`,
        brand: `${employerDashboard}/retention/brand`,
        location: `${employerDashboard}/retention/brand/location`,
        interactions: {
          root: `${employerDashboard}/retention/brand/location/interactions`,
          survey: `${employerDashboard}/retention/brand/location/interactions/surveys`,
          new_survey: `${employerDashboard}/retention/brand/location/interactions/surveys/new-survey`,
          send_survey: `${employerDashboard}/retention/brand/location/interactions/surveys/send-survey`,
          anniversary: `${employerDashboard}/retention/brand/location/interactions/anniversary`,
          custom: `${employerDashboard}/retention/brand/location/interactions/custom`,
          review: `${employerDashboard}/retention/brand/location/interactions/review`,
        },
      },
    },
    employee: {
      dashboard: employeeDashboard,
      profile: `${employeeDashboard}/profile`,
      // TODO need redirect here
      growOld: `${employeeDashboard}/career-development`,
      trends: `${employeeDashboard}/trends`,
      ratings: `${employeeDashboard}/ratings`,
      badges: `${employeeDashboard}/badges`,
      salary: `${employeeDashboard}/salary`,
      connections: `${employeeDashboard}/connections`,
      classes: `${employeeDashboard}/classes`,
      jobs: `${employeeDashboard}/jobs`,
      myJobs: `${employeeDashboard}/my-jobs`,
      companyJobs: `${employeeDashboard}/jobs/company/${companyIdParam}`,
      // TODO need redirect here
      companyJobsOld: `${employeeDashboard}/employer-jobs/${companyIdParam}`,
      quizzes: `${employeeDashboard}/quizzes`,
      onboarding: `${employeeDashboard}/onboarding`,
      // root navigation complete, randoms following
      interviewConfirmation: `${employeeDashboard}/interview-confirmation/${confirmationIdParam}`,
      survey: `${employeeDashboard}/survey/${surveyParam}`,
      // !place all /employee-dashboard/... paths before job
      // TODO need redirect here
      job: `${employeeDashboard}/jobs/${idParam}`,
      jobOld: `${employeeDashboard}/${idParam}`,
      progressSkills: `${employeeDashboard}/career-progress/skills`,
      progressConnections: `${employeeDashboard}/career-progress/connections`,
      progressHired: `${employeeDashboard}/career-progress/still-hired`,
    },
    login: {
      default: '/login',
      signUp: '/sign-up',
      verify: '/verify',
      newPwd: '/new-password',
    },
    onboarding: {
      default: '/onboarding',
    },
    blog: {
      default: '/blog',
      post: '/blog/:id',
    },
  };
};

export const dashboardRoutes = {
  employer: [...values(getRoutes().employer)],
  employee: [...values(getRoutes().employee)],
};

export const employeeStartPage = getRoutes().employee.dashboard;
export const employerStartPage = getRoutes().employer.dashboard;

export function getEmployerJobPaths() {
  return values(getRoutes().employer).filter((s) =>
    s.includes('/:id'),
  );
}

export function isEmployerJobPage(path) {
  const employerJobPaths = getEmployerJobPaths();
  return employerJobPaths.some((s) => s === path);
}

export function isEmployerNewJobPage(path) {
  return path === getRoutes().employer.job_new;
}
