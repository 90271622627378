import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon12 = (props) => (
    <SvgIcon viewBox="0 0 29.87 32.97" {...props}>
      <path
        d="M14.93,22.64c-2.55,1.78-6.3,3.59-7.09,6.73l1.42,3.59c1.19-2.05,3.59-3.33,5.67-4.63,2.08,1.31,4.48,2.59,5.67,4.63l1.42-3.59c-.79-3.14-4.54-4.95-7.09-6.73Z"
        fill="currentColor"
      />
      <path
        d="M14.93,0C10.39,3.17,1.43,8.23,0,13.89l2.92,7.38c1.94-5.55,7.39-8.71,12.02-11.94,4.62,3.23,10.08,6.39,12.02,11.94l2.92-7.38C28.44,8.23,19.48,3.17,14.93,0Z"
        fill="currentColor"
      />
      <path
        d="M14.93,11.63c-3.91,2.73-9.65,5.9-10.86,10.71l2.51,6.34c1.33-3.88,5.13-6.08,8.36-8.33,3.22,2.25,7.02,4.45,8.36,8.33l2.51-6.34c-1.21-4.81-6.95-7.98-10.86-10.71Z"
        fill="currentColor"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon12;
