import Typography from '@mui/material/Typography';
import { Button } from '../../shared';
import Paper from '@mui/material/Paper';
import { IOnboardingDocument } from '../../../api/types/queries/onboarding.types';
import { styled } from '@mui/material/styles';
import FlexRow from '../../shared/FlexRow';

interface StyledPaperProps {
  onClick: (
    document: Partial<IOnboardingDocument>,
  ) => void | null;
}

const StyledPaper = styled(Paper)<StyledPaperProps>(
  ({ theme, onClick }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    transition: theme.transitions.create(
      ['transform', 'background-color'],
      {
        duration: theme.transitions.duration.standard,
      },
    ),
    '&:hover': onClick
      ? {
          transform: 'scale(1.03)',
          cursor: 'pointer',
          backgroundColor: theme.palette.grey['600'],
        }
      : {},
  }),
);

const DocumentCard = ({
  name,
  id,
  handleDelete,
  handleEdit,
  handleClick,
  variant = 'elevation',
  ...documentPartial
}: IOnboardingDocument & {
  handleDelete?: (id: string | number) => void;
  handleEdit?: (document: Partial<IOnboardingDocument>) => void;
  handleClick?: (document: Partial<IOnboardingDocument>) => void;
  variant: 'elevation' | 'outlined';
}) => (
  <StyledPaper
    variant={variant}
    onClick={
      handleClick
        ? () =>
            handleClick({
              id,
              name,
              ...documentPartial,
            })
        : null
    }
  >
    <Typography variant={'h3'}>{name}</Typography>
    <FlexRow mt={2} mb={2}>
      {handleEdit && (
        <Button
          variant={'filled-primary'}
          onClick={() =>
            handleEdit({
              id,
              name,
              ...documentPartial,
            })
          }
        >
          Edit
        </Button>
      )}
      {handleDelete && (
        <Button
          variant={'filled-primary'}
          onClick={() => handleDelete(id)}
        >
          Delete
        </Button>
      )}
    </FlexRow>
  </StyledPaper>
);
export default DocumentCard;
