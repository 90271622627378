import {
  addStatusStylesMixin,
  maxWidthMixin,
} from '../../mixins';
import { darken } from '@mui/material';

export default ({ theme }) => ({
  '&.wrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'none',
    ...addStatusStylesMixin(theme, true),
    '& .overviewSection': {
      //this one needs to shrink on mobile
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      ...maxWidthMixin,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 8,
      [theme.breakpoints.down('desktopApp')]: {
        maxWidth: '100vw',
        margin: '0 16px',
      },
      '& .titleBlock': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
        width: '100%',
        color: theme.palette.text.primary,
      },
      '& .statBlockRow': {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridAutoRows: 'minmax(64px, auto)',
        gridColumnGap: 8,
        gridRowGap: 8,
        gridAutoFlow: 'dense',
        marginBottom: 26,
        [theme.breakpoints.down('desktopApp')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.down('tablet')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '& .statBlock': {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: 12,
          borderRadius: 8,
          backgroundColor: darken(theme.palette.background.paper, 0.15),
          '& .statTitle': {
            fontSize: 18,
            fontWeight: 700,
            color: theme.palette.text.secondary,
            marginBottom: 8,
          },
          '& .stats': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 18,
            fontWeight: 700,
            '& .overallStat': {
              color: theme.palette.text.primary,
            },
            '& .statChange': {
              display: 'flex',
              alignItems: 'center',
              fontSize: 14,
              color: theme.palette.text.primary,
              '& :first-of-type': {
                color: theme.palette.anomaly.main,
              },
            },
          },
        },
        '& .jobStatBlock': {
          minWidth: 10,
        },
        '& :last-child': {
          borderRight: 'none',
        },
        '& .divider': {
          display: 'flex',
          minWidth: 1,
          maxWidth: 1,
          backgroundColor: theme.palette.grey['200'],
        },
      },
    },
  },
});
