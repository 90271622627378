import { gql } from '@apollo/client';

export const CREATE_ONBOARDING_RESOURCE = gql`
  mutation CreateOnboardingResource(
    $allPositions: Boolean!
    $isPage: Boolean!
    $type: onboarding_resource_type!
    $title: String!
    $description: String
    $file: Upload
    $url: String
  ) {
    createOnboardingResource(
      allPositions: $allPositions
      isPage: $isPage
      type: $type
      title: $title
      description: $description
      file: $file
      url: $url
    ) {
      organizationUUID
      allPositions
      isPage
      type
      title
      description
      url
      id
    }
  }
`;

export const UPDATE_ONBOARDING_RESOURCE = gql`
  mutation UpdateOnboardingResource(
    $id: String!
    $allPositions: Boolean
    $isPage: Boolean
    $type: onboarding_resource_type
    $title: String
    $description: String
    $file: Upload
    $url: String
  ) {
    updateOnboardingResource(
      id: $id
      allPositions: $allPositions
      isPage: $isPage
      type: $type
      title: $title
      description: $description
      file: $file
      url: $url
    ) {
      organizationUUID
      allPositions
      isPage
      type
      title
      description
      url
      id
    }
  }
`;

export const CREATE_ONBOARDING_DOCUMENT = gql`
  mutation CreateOnboardingDocument(
    $file: Upload
    $allPositions: Boolean!
    $name: String!
    $numberOfSignatures: Int!
    $numberOfInitials: Int!
    $numberOfDates: Int!
  ) {
    createOnboardingDocument(
      file: $file
      allPositions: $allPositions
      name: $name
      numberOfSignatures: $numberOfSignatures
      numberOfInitials: $numberOfInitials
      numberOfDates: $numberOfDates
    ) {
      name
      uuidReference
      allPositions
      organizationUUID
      numberOfSignatures
      numberOfInitials
      numberOfDates
      id
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_ONBOARDING_DOCUMENT = gql`
  mutation UpdateOnboardingDocument(
    $updateOnboardingDocumentId: String!
    $name: String
    $allPositions: Boolean
  ) {
    updateOnboardingDocument(
      id: $updateOnboardingDocumentId
      name: $name
      allPositions: $allPositions
    ) {
      name
      uuidReference
      allPositions
      organizationUUID
      numberOfSignatures
      numberOfInitials
      numberOfDates
      id
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const SAVE_ONBOARDING_FLOW = gql`
  mutation SaveOnboardingFlow(
    $onboardingId: String!
    $onboardingFlow: [onboardingFlowArgsObjectType]
  ) {
    saveOnboardingFlow(
      onboardingId: $onboardingId
      onboardingFlow: $onboardingFlow
    ) {
      resources {
        onboardingId
        onboardingResourceId
        index
        id
        resource {
          title
        }
      }
      pages {
        onboardingId
        onboardingResourceId
        index
        id
        resource {
          title
        }
      }
    }
  }
`;

export const UPDATE_ONBOARDING_STATUS = gql`
  mutation UpdateOnboardingStatus($employeeId: Int) {
    updateOnboardingStatus(employeeId: $employeeId) {
      status
      hasOnboarding
      complete
    }
  }
`
