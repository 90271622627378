import { gql } from '@apollo/client';

export const GET_CAREER_PATH_GROUPS_BY_ORGANIZATION = gql`
  query GetCareerPathGroupsByOrganization(
    $organizationId: Int
    $brandId: Int
  ) {
    getCareerPathGroupsByOrganization(
      organizationId: $organizationId
      brandId: $brandId
    ) {
      careerPathGroups {
        careerPathGroupId
        endJobType
        startJobType
        assignedUserCount
        name
        careerPathGroupItems {
          brandId
          brandName
          careerPathGroupId
          careerPathGroupItemId
          careerPathGroupItemIndex
          description
          industryId
          jobTypeId
          jobTypeName
          abilities {
            description
            abilitiesBadgeName
            badgeUrl
            jobTypeId
            name
          }
          skills {
            description
            badgeUrl
            jobTypeId
            name
            skillsBadgeName
          }
        }
      }
    }
  }
`;

// TODO update this with the pagination
export const GET_ABILITIES_BADGES = gql`
  query GetAbilitiesBadges($limit: Int) {
    getAbilitiesBadges(limit: $limit) {
      results {
        abilitiesId
        badgeUrl
        createdAt
        id
        updatedAt
        name
      }
      total
    }
  }
`;

// TODO update this with the pagination
export const GET_SKILLS_BADGES = gql`
  query GetSkillsBadges($limit: Int) {
    getSkillsBadges(limit: $limit) {
      results {
        badgeUrl
        createdAt
        id
        name
        skillsId
        updatedAt
      }
    }
  }
`;

export const EMPLOYEES_BY_LOCATION = gql`
  query EmployeesByLocation($locationId: Int!) {
    employeesByLocation(locationId: $locationId) {
      results {
        imageUrl
        currentJobType
        futureJobType
        location
        name
        startDate
        employeeId
        locationId
        id
        verified
      }
      offset
      pages
      total
    }
  }
`;

export const PATH_STATS_BY_LOCATION = gql`
  query PathStatsByLocation($locationId: Int!) {
    pathStatsByLocation(locationId: $locationId) {
      averageCompletionTime
      estimatedCompletionRate
      locationId
      skillsCompleted
      totalSkills
    }
  }
`;

export const EMPLOYEE_PATH_STATS = gql`
  query EmployeePathStats(
    $locationId: Int!
    $limit: Int
    $offset: Int
  ) {
    employeePathStats(
      locationId: $locationId
      limit: $limit
      offset: $offset
    ) {
      limit
      offset
      pages
      total
      results {
        currentPathJobType
        percentComplete
        profileImageUrl
        name
      }
    }
  }
`;

export const REQUESTED_BADGES_BY_EMPLOYEE = gql`
  query RequestedBadgesByEmployee($employeeId: Int) {
    requestedBadgesByEmployee(employeeId: $employeeId) {
      abilitiesBadges {
        id
        status
        completedAt
        createdAt
        employeeUserId
        requestedAt
        requestedAbilitiesBadge {
          badgeUrl
          name
          id
          updatedAt
          createdAt
        }
        updatedAt
      }
      skillsBadges {
        completedAt
        employeeUserId
        id
        requestedAt
        requestedSkillsBadge {
          id
          name
          badgeUrl
          skillsId
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;
