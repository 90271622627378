import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { IChartBaseProps, IChartColors } from './chartUtils';
import CustomizableChart from './CustomizableChart';
import { useAppStoreSelector } from '../../../store';
import { darken } from '@mui/material';

const DonutChart = ({
  label,
  height,
  width,
  data: chartData,
  chartLabels,
  hasChartGrid = {
    x: false,
    y: false,
  },
  hasChartLabels = {
    x: false,
    y: false,
  },
}: IChartBaseProps) => {
  const [chartColors, setChartColors] =
    useState<IChartColors | null>(null);
  const { colors, colorMode } = useAppStoreSelector(
    ({ colorMode: baseColorMode, colors: baseColors }) => ({
      colorMode: baseColorMode,
      colors: baseColors,
    }),
  );

  const colorRanges = [
    [
      colors[colorMode].primary.main,
      darken(colors[colorMode].primary.main, 0.2),
    ],
    [
      colors[colorMode].secondary.main,
      darken(colors[colorMode].secondary.main, 0.2),
    ],
    [
      colors[colorMode].tertiary.main,
      darken(colors[colorMode].tertiary.main, 0.2),
    ],
  ];

  useEffect(() => {
    // set backgroundColors and hover colors
    // create a new array with length of data point colors
    const chartColorArray = Array(
      Math.ceil(chartData.length / colorRanges.length),
    )
      .fill(colorRanges)
      .flat();

    setChartColors({
      backgroundColor: chartData.map(
        (_, idx) => chartColorArray[idx][0],
      ),
      hoverBackgroundColor: chartData.map(
        (_, idx) => chartColorArray[idx][1],
      ),
    });
  }, []);

  const chartTypeConfigOptions = {
    cutout: width * 0.3,
  };
  const chartTypeDatasetOptions = {
    borderRadius: 0,
    borderWidth: 0,
    ...chartColors,
  };
  const chartTypePlugins = [
    // this adds a background inside the donut chart
    // can add text as well for metrics (NOT SETUP)
    {
      id: 'fillDonutHole',
      beforeDraw: (chart, args, options) => {
        const {
          ctx,
          // chartArea: { top, right, bottom, left, width, height },
        } = chart;
        const radius = chart._metasets[0].controller.innerRadius;
        const { x } = chart.getDatasetMeta(0).data[0];
        const { y } = chart.getDatasetMeta(0).data[0];
        ctx.arc(x, y, radius - 2.5, 0, 2 * Math.PI);
        ctx.fillStyle = options.backgroundColor;
        // TODO need to update this with height/width values
        // ctx.fillText('Text', height / 2, width / 2);
        // ctx.textAlign = 'center';

        ctx.fill();
      },
    },
  ];

  if (chartLabels.length !== chartData.length) {
    return <Box>The data and labels do not match</Box>;
  }

  if (!chartColors) {
    return null;
  }

  return (
    <CustomizableChart
      type={'doughnut'}
      label={label}
      datasets={[
        {
          data: chartData,
          ...chartTypeDatasetOptions,
        },
      ]}
      height={height}
      width={width}
      chartLabels={chartLabels}
      chartConfig={{
        chartTypeConfigOptions,
        chartTypePlugins,
      }}
      hasChartGrid={hasChartGrid}
      hasChartLabels={hasChartLabels}
    />
  );
};

export default DonutChart;
