import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import WorkTorchTIcon from '../../icons/WorkTorchTIcon';
import { Box } from '../../material-ui';
import { Button, IconButton } from '../../shared';
import {
  useMediaQueryMatches,
  useNotificationsSubscribe,
} from '../../../hooks';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ChatButton from '../../Header/ChatButton';
import LogoutButton from '../../Header/LogoutButton';
import { ToggleTheme } from '../../Header/ToggleTheme';
import FlexRow from '../../shared/FlexRow';
import ProfileLink from '../../Header/ProfileLink';
import FaqLink from '../../Header/FaqLink';

const colorMode = localStorage.getItem('colorMode');

const StyledNav = styled('nav')(({ theme }) => ({
  zIndex: 100,
  '.mobileNavigation': {
    position: 'fixed',
    top: 0,
    right: `-100vw`,
    // used to add a bump to the right to cover screen
    width: `calc(${theme.spacing(1)} + 90vw)`,
    // this takes into account additional width
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    height: '100vh',
    background: theme.palette.background.paper,
    zIndex: 101,
    transition: theme.transitions.create(['right'], {
      duration: theme.transitions.duration.standard,
    }),
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    '&.open': {
      right: `-${theme.spacing(1)}`,
    },
  },
}));

interface IRetentionNavButtons {
  onClick: () => void;
  disabled: boolean;
  label: string;
  testId: string;
  Icon: React.FC;
  selected: boolean;
}

interface IRetentionNavigation {
  buttons: IRetentionNavButtons[];
  showLogo?: boolean;
}

export const RetentionNavigation = ({
  buttons,
  showLogo = true,
}: IRetentionNavigation) => {
  const { isMobile, isDesktop } = useMediaQueryMatches();
  const [menuIsOpen, toggleMenu] = useState(false);
  const { isNewMessage } = useNotificationsSubscribe({
    role: 'employee',
  });

  return (
    <StyledNav className={'nav'}>
      {!isDesktop && (
        <IconButton
          onClick={() => toggleMenu(true)}
          variant={'contained'}
          color={'primary'}
        >
          <MenuIcon />
        </IconButton>
      )}
      {(!isMobile || showLogo) && (
        <Box className={'worktorchLogo'}>
          <WorkTorchTIcon
            fill={colorMode === 'light' ? '#312E38' : '#fff'}
          />
        </Box>
      )}
      <Box
        className={[
          !isDesktop && 'mobileNavigation',
          menuIsOpen && 'open',
          isDesktop && 'desktopNavigation',
        ].join(' ')}
      >
        {!isDesktop && (
          <FlexRow>
            <ToggleTheme />
            <ChatButton
              userRole="employee"
              isNewMessage={isNewMessage}
            />
            <ProfileLink
              key="header-profile"
              userRole="employee"
            />
            <FaqLink key="header-faq" userRole="employee" />
            <LogoutButton key="header-logout" />
          </FlexRow>
        )}
        {buttons.map(
          ({
            onClick,
            disabled,
            testId,
            Icon,
            label,
            selected,
          }) =>
            isMobile ? (
              <Button
                disabled={disabled}
                aria-label={label}
                className="retention-nav-button"
                variant={selected ? 'outlined' : 'contained'}
                color={'primary'}
                onClick={() => {
                  toggleMenu(false);
                  onClick();
                }}
                width={'100%'}
                sx={(theme) => ({
                  marginBottom: theme.spacing(1.5),
                })}
                testID={testId}
                key={label}
              >
                {label}
              </Button>
            ) : (
              <IconButton
                disabled={disabled}
                variant="filled-primary"
                aria-label={label}
                className="retention-nav-button"
                onClick={onClick}
                testID={testId}
                key={label}
                selected={selected}
                toolTipProps={{
                  title: label,
                  placement: 'right',
                }}
                withTooltip
                size={'small'}
              >
                <Icon />
              </IconButton>
            ),
        )}
        {!isDesktop && (
          <IconButton
            onClick={() => toggleMenu(false)}
            variant={'contained'}
            color={'primary'}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </StyledNav>
  );
};
