import { Box } from '../../material-ui';
import React from 'react';
import Assignments from './Assignments';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 250px',
  gridGap: theme.spacing(2),
  [theme.breakpoints.down('desktopApp')]: {
    display: 'flex',
    flexDirection: 'column',
    '.mainContent': {
      paddingBottom: theme.spacing(7),
      paddingTop: theme.spacing(7),
    },
  },
}));
const OnboardingLayout = ({ children }) => {
  return (
    <StyledBox>
      <Box className={'mainContent'}>{children}</Box>
      <Box>
        <Assignments />
      </Box>
    </StyledBox>
  );
};

export default OnboardingLayout;
