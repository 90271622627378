import { alpha, lighten } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { Components } from '@mui/material/styles/components';
import { addAlpha, scaleFont } from './utils';

declare module '@mui/material/ButtonBase' {
  interface ButtonPropsVariantOverrides {
    neutral: true;
    destructive: true;
    success: true;
  }
}

const primaryButtonStyles = ({ theme }) => ({
  color: theme.palette.button.primary.text,
  fill: theme.palette.button.primary.text,
  backgroundColor: theme.palette.button.primary.main,
  '&:hover': {
    color: theme.palette.button.primary.hoverText,
    fill: theme.palette.button.primary.hoverText,
    backgroundColor: lighten(
      theme.palette.button.primary.main,
      0.2,
    ),
  },
  '&.Mui-disabled': {
    opacity: 0.7,
    backgroundColor: theme.palette.button.primary.disabled,
    color: theme.palette.button.primary.textDisabled,
    fill: theme.palette.button.primary.textDisabled,
    border: 'none',
  },
  '&.active': {
    backgroundColor: theme.palette.button.primary.active,
  },
});

const primaryOutlinedButtonStyles = ({ theme }) => ({
  color: theme.palette.button.primary.text,
  fill: theme.palette.button.primary.text,
  background: 'transparent',
  border: `1px solid ${theme.palette.button.primary.main}`,
  '&:hover': {
    color: theme.palette.button.primary.hoverText,
    backgroundColor: alpha(
      theme.palette.button.primary.main,
      0.2,
    ),
  },
  '&.Mui-disabled': {
    opacity: 0.7,
    borderColor: theme.palette.button.primary.disabled,
    color: theme.palette.button.primary.textDisabled,
    fill: theme.palette.button.primary.textDisabled,
    background: 'transparent',
  },
  '&.active': {
    backgroundColor: alpha(
      theme.palette.button.primary.active,
      0.5,
    ),
  },
});

const secondaryButtonStyles = ({ theme }) => ({
  color: theme.palette.button.secondary.text,
  fill: theme.palette.button.secondary.text,
  backgroundColor: theme.palette.button.secondary.main,
  '&:hover': {
    color: theme.palette.button.secondary.hoverText,
    backgroundColor: lighten(
      theme.palette.button.secondary.main,
      0.2,
    ),
  },
  '&.Mui-disabled': {
    opacity: 0.7,
    backgroundColor: theme.palette.button.secondary.disabled,
    color: theme.palette.button.secondary.textDisabled,
    fill: theme.palette.button.secondary.textDisabled,
    border: 'none',
  },
  '&.active': {
    backgroundColor: theme.palette.button.secondary.active,
  },
});

const secondaryOutlinedButtonStyles = ({ theme }) => ({
  color: theme.palette.button.secondary.text,
  fill: theme.palette.button.secondary.text,
  background: 'transparent',
  border: `1px solid ${theme.palette.button.secondary.main}`,
  '&:hover': {
    color: theme.palette.button.secondary.hoverText,
    backgroundColor: alpha(
      theme.palette.button.secondary.main,
      0.2,
    ),
  },
  '&.Mui-disabled': {
    opacity: 0.7,
    borderColor: theme.palette.button.secondary.disabled,
    color: theme.palette.button.secondary.disabledText,
    fill: theme.palette.button.secondary.disabledText,
    background: 'transparent',
  },
  '&.active': {
    backgroundColor: alpha(
      theme.palette.button.secondary.active,
      0.5,
    ),
  },
});

const themeComponents = () =>
  ({
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        '#chat-widget-container': {
          [theme.breakpoints.down('desktopApp')]: {
            zIndex: '99!important',
          },
        },
        // [...fontFaces.join(' ')],
        body: {
          fontSize: `${scaleFont(1)}rem`,
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
          fontFamily: "'Nunito Sans', sans-serif",
          color: theme.palette.text.primary,
          overflowX: 'hidden',
          background: `${theme.palette.background.default}!important`,
        },
        'p, h1, h2, h3, h4, h5, h6': {
          margin: 0,
        },
        a: {
          textDecoration: 'none',
          color: theme.palette.tertiary.light,
        },
        button: {
          fontFamily: 'inherit',
        },
        'input, textarea': {
          caretColor: theme.palette.text.primary,
        },
        '::-webkit-scrollbar': {
          width: 5,
          height: 5,
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: '#4f4e4e',
          borderRadius: 10,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#464545',
          borderRadius: 10,
        },
        'input:-webkit-autofill, input:-webkit-autofill:focus, input:-webkit-autofill:invalid':
          {
            transition:
              'background-color 600000s 0s, color 600000s 0s',
            boxShadow: `transparent`,
            WebkitTextFillColor: theme.palette.primary.main,
          },
        'input[data-autocompleted]': {
          backgroundColor: 'transparent !important',
        },
        // overrides do not work on the new datepicker for some reason
        '.MuiPickersYear-yearButton, .MuiPickersMonth-monthButton, .MuiPickersMonth-dayButton':
          {
            fontSize: `21px!important`,
          },
      }),
    },
    MuiDatePicker: {
      defaultProps: {
        PaperProps: {
          sx: {
            borderRadius: '10px',
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& > .MuiTypography-root': {
            marginBottom: theme.spacing(1),
          },
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 800,
          lineHeight: `${scaleFont(1.3)}em`,
          letterSpacing: 1.2,
          borderRadius: 6.5,
          padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
          fontSize: scaleFont(16),
        }),
      },
      variants: [
        {
          props: { variant: 'neutral' },
          style: ({ theme }) => ({
            color: theme.palette.button.primary.text,
            backgroundColor: theme.palette.button.primary.main,
            border: `1px solid ${theme.palette.button.primary.borderColor}`,
          }),
        },
        {
          props: { variant: 'destructive' },
          style: ({ theme }) => ({
            color: theme.palette.common.white,
            backgroundColor: theme.palette.error.main,
          }),
        },
        {
          props: { variant: 'success' },
          style: ({ theme }) => ({
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.main,
          }),
        },
        {
          props: { color: 'primary', variant: 'contained' },
          style: primaryButtonStyles,
        },
        {
          props: { variant: 'filled-primary' },
          style: primaryButtonStyles,
        },
        {
          props: { variant: 'outlined-primary' },
          style: primaryOutlinedButtonStyles,
        },
        {
          props: { variant: 'outlined' },
          style: primaryOutlinedButtonStyles,
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: primaryOutlinedButtonStyles,
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: secondaryButtonStyles,
        },
        {
          props: { variant: 'filled-secondary' },
          style: secondaryButtonStyles,
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: secondaryOutlinedButtonStyles,
        },
        {
          props: { variant: 'outlined-secondary' },
          style: secondaryOutlinedButtonStyles,
        },
        {
          props: { size: 'small' },
          style: ({ theme }) => ({
            fontSize: scaleFont(12),
            padding: `${theme.spacing(0.6)} ${theme.spacing(
              1.5,
            )}`,
          }),
        },
        {
          props: { size: 'medium' },
          style: ({ theme }) => ({
            fontSize: scaleFont(16),
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          }),
        },
        {
          props: { size: 'large' },
          style: ({ theme }) => ({
            fontSize: scaleFont(20),
            padding: `${theme.spacing(1.25)} ${theme.spacing(
              2.5,
            )}`,
          }),
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1),
          '&.Mui-disabled': {
            color: theme.palette.button.primary.contrastText,
            backgroundColor:
              theme.palette.button.primary.disabled,
          },
        }),
      },
      variants: [
        {
          props: { variant: 'neutral' },
          style: ({ theme }) => ({
            color: theme.palette.button.primary.text,
            fill: theme.palette.button.primary.text,
            backgroundColor: theme.palette.button.primary.main,
            border: `1px solid ${theme.palette.button.primary.borderColor}`,
          }),
        },
        {
          props: { variant: 'destructive' },
          style: ({ theme }) => ({
            color: theme.palette.common.white,
            fill: theme.palette.common.white,
            backgroundColor: theme.palette.error.main,
          }),
        },
        {
          props: { variant: 'success' },
          style: ({ theme }) => ({
            fill: theme.palette.common.white,
            backgroundColor: theme.palette.success.main,
          }),
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: primaryButtonStyles,
        },
        {
          props: { variant: 'filled-primary' },
          style: primaryButtonStyles,
        },
        {
          props: { variant: 'outlined-primary' },
          style: primaryOutlinedButtonStyles,
        },
        {
          props: { variant: 'outlined' },
          style: primaryOutlinedButtonStyles,
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: primaryOutlinedButtonStyles,
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: secondaryButtonStyles,
        },
        {
          props: { variant: 'filled-secondary' },
          style: secondaryButtonStyles,
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: secondaryOutlinedButtonStyles,
        },
        {
          props: { variant: 'outlined-secondary' },
          style: secondaryOutlinedButtonStyles,
        },
      ],
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(2)} ${theme.spacing(2.25)}`,
          borderRadius: 4,
          [theme.breakpoints.up('desktopApp')]: {
            minWidth: 350,
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.grey['300'],
        }),
      },
    },
    MuiDialog: {
      styleOverrides: (theme) => ({
        paper: {
          border: `1px solid ${lighten(
            theme.palette.primary.main,
            0.85,
          )}`,
          borderRadius: 15,
          backgroundColor: theme.palette.background.paper,
        },
      }),
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: addAlpha(
            theme.palette.background.default,
            0.2,
          ),
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          border: `1px solid ${theme.palette.grey['300']}`,
          borderRadius: 15,
          boxShadow: `0 8px 16px 0 ${addAlpha(
            theme.palette.background.paper,
            0.45,
          )}`,
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
        outlined: ({ theme }) => ({
          background: theme.palette.grey['800'],
          border: `2px solid ${theme.palette.primary.light}`,
          padding: 15,
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.primary.contrastText,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 59,
          height: 32,
          padding: 0,
          borderRadius: 16,
        },
        switchBase: ({ theme }) => ({
          color: theme.palette.background.default,
          padding: 2,
          '& + .MuiSwitch-track': {
            backgroundColor: lighten(
              theme.palette.primary.light,
              0.7,
            ),
          },
          '&.Mui-checked': {
            transform: 'translateX(90%)',
            color: theme.palette.background.default,
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.primary.main,
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: theme.palette.common.white,
            },
          },
        }),
        thumb: ({ theme }) => ({
          width: 26,
          height: 26,
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 2px 13px rgba(0, 0, 0, 64%)',
        }),
        track: {
          opacity: 1,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          '&.Mui-disabled': {
            color: lighten(theme.palette.primary.main, 0.2),
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: scaleFont(17),
          border: `1px solid ${theme.palette.primary.light}`,
          borderRadius: 4,
          '&.Mui-disabled': {
            color: alpha(theme.palette.primary.main, 0.75),
            '-webkit-text-fill-color': theme.palette.grey['400'],
          },
          input: {
            padding: `${theme.spacing(2)} ${theme.spacing(
              2.25,
            )}`,
            '&.Mui-disabled': {
              color: theme.palette.grey['400'],
            },
          },
        }),
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 10,
          }),
        },
        {
          props: {
            variant: 'textfield',
            style: ({ theme }) => ({
              borderBottom: `1px solid ${theme.palette.primary.light}`,
            }),
          },
        },
        {
          props: {
            variant: 'text',
          },
          style: ({ theme }) => ({
            padding: 0,
            border: 'none',
            '&.Mui-disabled': {
              color: theme.palette.grey['800'],
            },
          }),
        },
      ],
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.light,
          '&.Mui-completed': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('desktopApp')]: {
            minWidth: 350,
          },
        }),
      },
    },
  }) as Components;

export default themeComponents;
