import React, { useRef, useState, useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import isBefore from 'date-fns/isBefore';
import { head, get, isEmpty, filter } from 'lodash';
import { Box, Dialog, styled } from 'components';
import { MdClose, MdStar, MdChevronRight } from 'components/icons';
import { IconButton, Button, Spinner } from 'components/shared';
import EmployeeJobCard from 'components/Job/EmployeeJobCard/EmployeeJobCard';
import withEmployeeJobActions from 'hocs/withEmployeeJobActions';
import { CAREER_DEV_BY_USER_ID, GET_JOB_TYPES_BY_INDUSTRY } from 'api';
import styles from 'styles/dialogs/ConnectionProfileDialog';

const enhance = (WrappedComponent) => withEmployeeJobActions(WrappedComponent);

const StyledRoot = styled(Dialog)(styles);

const orderExperience = (experience = []) =>
  experience.length
    ? [...experience].sort((a, b) => {
        if (a.endDate && isBefore(new Date(a.endDate), new Date(b.startDate))) return -1;
        return 1;
      })
    : [];

const ConnectionProfileDialog = ({
  // className,
  isOpen,
  initIndex,
  onClose,
  onAdd,
  onRemove,
  onApply,
  onReject,
  onRetract,
  onStar,
  onSaveInterview,
  starredUserIds,
  starredProfileIdLoading,
  suggestedJobs,
  userIds
}) => {
  // const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const scrollToImage = useRef(null);
  const withNavigateActions = userIds.length > 1;

  const [index, setIndex] = useState(initIndex);
  const [showJobCard, setShowJobCard] = useState(false);
  const [jobCardStyles, setJobCardStyles] = useState({});
  const [activeJobIdx, setActiveJobIdx] = useState(null);

  const [fetchJobTypes, { data: jobTypesData }] = useLazyQuery(GET_JOB_TYPES_BY_INDUSTRY);
  const jobTypes = jobTypesData?.jobTypesByIndustryId || [];

  const [fetchConnectionCareerDev, { data: connectionData, loading }] = useLazyQuery(
    CAREER_DEV_BY_USER_ID,
    {
      fetchPolicy: 'no-cache' // TODO: maybe better to enable caching
    }
  );
  const employeeCareerDev = connectionData?.connectionCareerDevelopmentByUserId || {};
  const { employeeProfile, industryId, curJobTypeId, futureJobTypeId } = employeeCareerDev;
  const {
    education = [],
    experience = [],
    industry = [],
    imageUrl,
    name,
    motto,
    profile_id,
    user_id
  } = employeeProfile || {};
  const currentJob = get(head(filter(jobTypes, { id: Number(curJobTypeId) })), 'name');
  const futureJob = get(head(filter(jobTypes, { id: Number(futureJobTypeId) })), 'name');
  const isStarred = starredUserIds.includes(user_id);
  const isStarredLoading = starredProfileIdLoading === profile_id;

  const orderedExperience = useMemo(
    () => (experience.length ? orderExperience(experience) : []),
    [experience]
  );

  const getCareerDev = (userId) => {
    fetchConnectionCareerDev({ variables: { connectionUserId: Number(userId) } });
  };

  useEffect(() => {
    if (userIds.length) {
      const userId = userIds[initIndex];
      getCareerDev(userId);
    }
  }, [userIds]);

  useEffect(() => {
    if (industryId) {
      fetchJobTypes({ variables: { industryId } });
    }
  }, [industryId]);

  const closeJobCard = () => {
    setShowJobCard(false);
    setActiveJobIdx(null);
  };

  const prev = () => {
    const prevIdx = index === 0 ? userIds.length - 1 : index - 1;
    const userId = userIds[prevIdx];
    closeJobCard();
    getCareerDev(userId);
    setIndex(prevIdx);
    scrollToImage.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  const next = () => {
    const nextIdx = index === userIds.length - 1 ? 0 : index + 1;
    const userId = userIds[nextIdx];
    closeJobCard();
    getCareerDev(userId);
    setIndex(nextIdx);
    scrollToImage.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  const modalHeight = 400;

  const openJob = (e, job, idx) => {
    setShowJobCard(true);
    setActiveJobIdx(idx);
    setJobCardStyles({
      top: e.pageY - modalHeight
    });
  };

  return (
    <StyledRoot
      open={isOpen}
      maxWidth="tablet"
      className="careerDevModal"
      PaperProps={{ className: 'careerDevModal__paper' }}
      onClose={onClose}
    >
      {showJobCard && (
        <Box position="relative">
          <div style={{ width: 300, ...jobCardStyles }} className="jobCardContainer">
            <IconButton
              variant="filled-primary"
              aria-label="Close this Job Card"
              className="profileCloseButton"
              onClick={closeJobCard}
              testID="wt-grow-job-close-button"
            >
              <MdClose />
            </IconButton>
            <EmployeeJobCard
              job={suggestedJobs[activeJobIdx]}
              onApply={onApply}
              onReject={onReject}
              onRetract={onRetract}
              onStar={onStar}
              onSaveInterview={onSaveInterview}
              onScheduleCall={onApply}
              withSchedule
            />
          </div>
        </Box>
      )}
      <div className="profile">
        <div className="scrollToTop" ref={scrollToImage} />
        {(() => {
          if (loading || isEmpty(employeeCareerDev))
            return (
              <div>
                <Spinner size={24} />
              </div>
            );

          return (
            <>
              <Box p="16px">
                <div className="profile__header">
                  <IconButton
                    variant="filled-primary"
                    aria-label="Close this connection"
                    className="profile__closeBtn"
                    onClick={onClose}
                    testID="wt-grow-connection-close-button"
                  >
                    <MdClose />
                  </IconButton>
                  <div className="profile__imageContainer">
                    <img className="profile__image" src={imageUrl} alt="" />
                  </div>
                </div>
                <div className="profile__nameContainer">
                  <p className="profile__name">{name}</p>
                  {motto && <p className="profile__motto">{motto}</p>}
                </div>
                <Box pt="10px" mb="16px" display="flex">
                  <IconButton
                    aria-label="Star this user"
                    className={clsx(
                      'profile__starredBtn',
                      isStarred && 'profile__starredBtn_active'
                    )}
                    disabled={isStarredLoading}
                    onClick={() =>
                      isStarred ? onRemove(employeeCareerDev) : onAdd(employeeCareerDev)
                    }
                  >
                    {isStarredLoading ? (
                      <Spinner width={24} height={24} />
                    ) : (
                      <MdStar
                        className={clsx(
                          'profile__starIcon',
                          isStarred && 'profile__starIcon_active'
                        )}
                      />
                    )}
                  </IconButton>
                  {/* <IconButton
                    variant="filled-primary"
                    aria-label="Favorite this user"
                    className="profile__mailBtn"
                    sx={{ marginLeft: '17px' }}
                    // onClick={() => chatWithConnection()}
                  >
                    <MdMail style={{ fontSize: 20 }} />
                  </IconButton> */}
                </Box>
                <div>
                  <h1 className="profile__sectionTitle">Current Industry</h1>
                  {industry.length ? (
                    <p>
                      {industry.map((ind) => (
                        <span key={ind.name}>{ind.name}, </span>
                      ))}
                    </p>
                  ) : (
                    <div>Information Not Available</div>
                  )}
                </div>
                <div className="profile__careerPathContainer">
                  <h1 className="profile__sectionTitle">Career Path</h1>
                  <div className="profile__sectionContainer">
                    {orderedExperience.length ? (
                      orderedExperience.map((exp) => (
                        <div
                          key={exp.experience_id}
                          className="profile__expContainer"
                          style={{
                            borderLeft: `1px solid ${exp.currentJob ? '#8787F9' : '#CCCCCC'}`
                          }}
                        >
                          <div
                            className="profile__expCircle"
                            style={{
                              backgroundColor: exp.currentJob ? '#8787F9' : '#CCCCCC'
                            }}
                          />
                          <Box color="#545454">{exp.name}</Box>
                          <Box color="#545454" fontSize={10}>
                            {exp.pos}
                          </Box>
                        </div>
                      ))
                    ) : (
                      <div>Information Not Available</div>
                    )}
                  </div>
                  <div className="profile__sectionContainer">
                    <h1 className="profile__sectionTitle">Career Goal</h1>
                    <Box pb="7px">
                      <Box fontSize={11}>I am a</Box>
                      <Box color="primary.light">{currentJob}</Box>
                    </Box>
                    <div>
                      <Box fontSize={11}>I want to be a</Box>
                      <Box color="primary.light">{futureJob}</Box>
                    </div>
                  </div>
                </div>
                <div className="profile__sectionContainer">
                  <h1 className="profile__sectionTitle">Education</h1>
                  {education.length ? (
                    education.map((edu) => (
                      <Box key={edu.education_id} py="5px">
                        <p>{edu.name}</p>
                        <Box component="span" fontSize={10}>
                          {edu.pos} | {edu.currentJob ? 'inProgress' : 'diploma'}
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <div>Information Not Available</div>
                  )}
                </div>
                <div className="profile__sectionContainer">
                  <h1 className="profile__sectionTitle">Certifications</h1>
                  <div>Information Not Available</div>
                </div>
              </Box>
              <Box p="16px">
                <div className="profile__sectionContainer">
                  <h1 className="profile__sectionTitle">Suggested Jobs</h1>
                  {suggestedJobs.length > 0 ? (
                    suggestedJobs.map((job, idx) =>
                      // only display three jobs here
                      idx < 3 ? (
                        <Button
                          key={job.id}
                          onClick={(e) => openJob(e, job, idx)}
                          className={clsx(
                            'profile__jobsContainer',
                            activeJobIdx === idx && 'profile__jobsContainer_active'
                          )}
                          testID={`wt-grow-suggested-job-button-${job.title}`}
                        >
                          <h5 className="profile__suggestedJobsTitle">{job.title}</h5>
                          <p className="profile__suggestedJobsCompany">
                            {job.employerProfile.name}
                          </p>
                        </Button>
                      ) : null
                    )
                  ) : (
                    <div>No Jobs Available</div>
                  )}
                </div>
              </Box>
              {withNavigateActions && (
                <div className="profile__navContainer">
                  <Button
                    variant="filled-primary"
                    aria-label="Star this user"
                    className="profile__navBtn"
                    onClick={prev}
                    disabled={loading}
                    startIcon={<MdChevronRight style={{ transform: 'rotate(180deg)' }} />}
                    testID="wt-grow-prev-connection-button"
                  >
                    <span>Prev</span>
                  </Button>
                  <Button
                    className="profile__navBtn"
                    variant="filled-primary"
                    aria-label="Star this user"
                    onClick={next}
                    disabled={loading}
                    endIcon={<MdChevronRight />}
                    testID="wt-grow-next-connection-button"
                  >
                    <span>Next</span>
                  </Button>
                </div>
              )}
            </>
          );
        })()}
      </div>
    </StyledRoot>
  );
}

ConnectionProfileDialog.propTypes = {
  onClose: PT.func.isRequired,
  suggestedJobs: PT.arrayOf(PT.any),
  isOpen: PT.bool.isRequired,
  initIndex: PT.number,
  onAdd: PT.func.isRequired,
  onRemove: PT.func.isRequired,
  starredUserIds: PT.arrayOf(PT.number),
  starredProfileIdLoading: PT.number,
  userIds: PT.arrayOf(PT.number).isRequired
};

ConnectionProfileDialog.defaultProps = {
  suggestedJobs: [],
  initIndex: 0,
  starredProfileIdLoading: null,
  starredUserIds: []
};

export default enhance(ConnectionProfileDialog);
