const styles = ({ theme }) => ({
  '.profile-inputs': {
    flexWrap: 'wrap',
    alignItems: 'start',
    '> *': {
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
      flexBasis: `calc(100% / 3 - ${theme.spacing(3)})`,
      [theme.breakpoints.down('desktopApp')]: {
        flexBasis: `100%`,
      },
      '&:nth-of-type(2n + 1)': {
        marginRight: 0,
      },
    },
  },
  '.sectionContainer': {
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('desktopApp')]: {
      '&:first-of-type': {
        marginBottom: 0,
        paddingBottom: 0,
        borderBottom: 'none',
      },
    },
  },
  '.sectionDescription': {
    maxWidth: 500,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
  '.resumeContainer': {
    'a, div': {
      fontSize: 55,
      lineHeight: '50px',
      position: 'relative',
      display: 'inline-block',
    },
    div: {
      color: theme.palette.button.primary.disabled,
    },
  },
  '.history-container': {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
  '.history-item': {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'column',
    maxWidth: 300,
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: '100%',
      width: '100%',
    },
    '> div': {
      width: '100%',
      marginBottom: theme.spacing(1.5),
      minWidth: 100,
    },
    '&.error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '.chipContainer': {
    [theme.breakpoints.down('desktopApp')]: {
      '.MuiChip-root': {
        marginBottom: theme.spacing(1.5),
      },
    },
  },
});

export default styles;
