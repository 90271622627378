import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import { Button } from '../../shared';
import { Box } from '../../material-ui';
import React from 'react';
import get from 'lodash/get';
import { styled } from '@mui/material/styles';
// import { useEmployeeOnboarding } from 'hooks';

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&.assignment-approved': {
    display: 'flex',
    alignItems: 'center',
  },
  '&.assignment-card': {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1.5),
    borderRadius: 4,
  },
  svg: {
    marginRight: theme.spacing(1),
    fill: theme.palette.success.main,
  },
}));

const onboardingAssignmentsMock = {
  onboardingAssignmentsMockKeyToReplace: {
    assignments: [
      {
        url: 'https://foo.com',
        title: 'Fill out I-9',
        status: 'approved',
      },
      {
        url: 'https://foo.com',
        title: 'Welcome packet',
        status: 'requested',
      },
      {
        url: 'https://foo.com',
        title: 'Get Badge',
        status: 'pending',
      },
    ],
  },
};
const Assignments = () => {
  // const { assignments: assignmentsAPI } =
  //   useEmployeeOnboarding();
  //
  // useEffect(() => {
  //   assignmentsAPI.get();
  // }, []);
  //
  // console.log('assignmentsAPI.data');
  // console.log(assignmentsAPI.data);

  // TODO need to figure this out better. Looks like assignments
  //  is correct but it is supposed to be an array of documents,
  //  but this means that there is no general assignment for the
  //  employee.
  // const assignments = get(
  //   assignmentsAPI,
  //   'data.onboardingDocumentsByEmployeeId.results',
  //   [],
  // );

  const assignments = get(
    onboardingAssignmentsMock,
    'onboardingAssignmentsMockKeyToReplace.assignments',
    [],
  );

  return assignments.map((assignment) => {
    const approved = assignment.status === 'approved';
    const pending = assignment.status === 'pending';
    return (
      <StyledBox
        className={
          approved ? 'assignment-approved' : 'assignment-card'
        }
      >
        {approved && <CheckCircleOutlineIcon />}
        <Typography variant={'h3'} mb={approved ? 0 : 2}>
          {assignment.title}
        </Typography>
        {!approved && (
          <Button
            disabled={pending}
            onClick={() => console.log('send mark assignment')}
            variant={'outlined-primary'}
          >
            {pending ? 'Pending' : 'Mark Complete'}
          </Button>
        )}
      </StyledBox>
    );
  });
};

export default Assignments;
