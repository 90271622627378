import { Typography } from '@mui/material';
import { Box } from 'components';
import { VerticalBarChart } from 'components/shared/charts';
import React from 'react';
import { v4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import { IPulseLocation } from '../../api.types';
import MetricContainer from '../Location/MetricContainer';

const PulseCard = (
  {
    id,
    name,
    status,
    score,
    employees = [],
    employeeMood,
    employeeInfo,
    handleNavigation
  }: {
    // eslint-disable-next-line no-shadow
    handleNavigation: (id: number, location: string) => void;
  } & IPulseLocation,
) => {
  const defaultChartData = [0, 0, 0, 0, 0];

  return (
    <div
      className={'dashboard-card-container pulse-card-container'}
    >
      <Box
        onClick={() =>
          handleNavigation(id, name)
        }
      >
        <Typography
          variant={'h2'}
          className={'location-title-container'}
        >
          <span className={`status-icon_${status}`} />
          <span className={'location-title'}>{name}</span>
        </Typography>
        <Box className={'pulse-card-stats'}>
          <MetricContainer
            title={'Interaction Score'}
            data={(() => (
              <span>
                {score}
                <sup>%</sup>
              </span>
            ))()}
          />
          <MetricContainer
            title={'Employee Mood'}
            chart={
              <VerticalBarChart
                label={''}
                height={60}
                width={100}
                data={
                  isEmpty(employeeMood)
                    ? defaultChartData
                    : employeeMood
                }
                chartLabels={(isEmpty(employeeMood)
                  ? defaultChartData
                  : employeeMood
                ).map((mood) => mood.toString())}
                hasChartLabels={{
                  x: true,
                  y: false,
                }}
              />
            }
          />
        </Box>
        <Box className={'team'}>
          <Typography className={'accent1'} variant={'body1'}>
            Team
          </Typography>
          <Box className={'team-member-container'}>
            <Box className={'team-members'}>
              {employees.slice(0, 7).map((employee) => (
                <img
                  key={`employee-img__${v4()}`}
                  src={employee.imageUrl}
                  alt=""
                />
              ))}
            </Box>
            <Box className={'team-count'}>
              <Typography variant={'h4'}>
                + {employeeInfo.count}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default PulseCard;
