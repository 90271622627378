import { Box, styled } from '../material-ui';

interface StyledBoxProps {
  isTwoColumn: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>(
  ({ theme, isTwoColumn }) => ({
    [theme.breakpoints.up('desktopApp')]: {
      display: isTwoColumn ? 'grid' : 'block',
      gridTemplateColumns: '1fr 225px',
      gridGap: theme.spacing(2),
      '.leftContentWrapper': {
        margin: '0 auto',
      },
      '.rightContentWrapper': {},
    },
    [theme.breakpoints.down('desktopApp')]: {
      display: 'block',
      padding: theme.spacing(2),
      '.leftContentWrapper': {
        marginBottom: theme.spacing(4),
      },
    },
  }),
);
const EmployeePage = ({
  leftContent,
  rightContent,
}: {
  leftContent: JSX.Element;
  rightContent?: JSX.Element;
}) => (
  <StyledBox isTwoColumn={rightContent != null}>
    <Box className={'leftContentWrapper'}>{leftContent}</Box>
    <Box className={'rightContentWrapper'}>{rightContent}</Box>
  </StyledBox>
);

export default EmployeePage;
