import React from 'react';
import { Box, styled } from 'components';
import { useNavigate } from 'react-router-dom';
import { IEmployerBrandDashboard } from '../../types';
import { dashboardStyles } from "../../../../styles/Retention/Brand";
import DashboardHeader from '../DashboardHeader';
import { Spinner } from '../../../shared';
import { getRoutes } from '../../../../utils';
import RetentionBreadcrumbs from '../RetentionBreadcrumbs';

const StyledBox = styled(Box)(dashboardStyles);

const BrandDashboardRenderer = ({
  title,
  render,
  data,
}: IEmployerBrandDashboard) => {
  const routes = getRoutes();
  const navigate = useNavigate();

  return data ? (
    <StyledBox className="dashboard-wrapper">
      <RetentionBreadcrumbs />
      <DashboardHeader dashboardTitle={title} />
      <Box className="dashboard-cards">
        {render({
          cardsData: data,
          locationRoute:
            routes.employer.retention_portal.location,
          navigate,
        })}
      </Box>
    </StyledBox>
  ) : (
    <Spinner />
  );
};

export default BrandDashboardRenderer;
