import { useMemo, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { find } from 'lodash';
import { GET_JOB_TYPES, GET_JOB_TYPES_BY_INDUSTRY } from 'api';

export default function useJobTypesQuery() {
  const [fetchJobTypes, { data, loading: jobTypesLoading }] = useLazyQuery(GET_JOB_TYPES, {
    fetchPolicy: 'cache-first'
  });
  const jobTypes = useMemo(() => data?.getJobsTypes || [], [data?.getJobsTypes]);

  const [fetchJobTypesByIndustry, { data: jobTypesByInd, loading: jobTypesByIndustryIdLoading }] =
    useLazyQuery(GET_JOB_TYPES_BY_INDUSTRY, {
      fetchPolicy: 'cache-first'
    });
  const jobTypesByIndustry = useMemo(
    () => jobTypesByInd?.jobTypesByIndustryId || [],
    [jobTypesByInd?.jobTypesByIndustryId]
  );

  const getJobTypesByIndustryId = useCallback(
    (industryId) => fetchJobTypesByIndustry({ variables: { industryId } }),
    [fetchJobTypesByIndustry]
  );

  const findJobTypeById = useCallback((id, jobTypesArr) => find(jobTypesArr, { id }), []);

  return useMemo(
    () => ({
      getJobTypes: fetchJobTypes,
      getJobTypesByIndustryId,
      findJobTypeById,
      jobTypes,
      jobTypesLoading,
      jobTypesByIndustry,
      jobTypesByIndustryIdLoading
    }),
    [
      fetchJobTypes,
      findJobTypeById,
      getJobTypesByIndustryId,
      jobTypes,
      jobTypesByIndustry,
      jobTypesLoading,
      jobTypesByIndustryIdLoading
    ]
  );
}
