import React, { useEffect } from 'react';
import {
  StepNavigation,
  Step,
  Spinner,
} from 'components/shared';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';
import { GrowInitialSetup } from '../../components/EmployeeGrow';
import Welcome from '../../components/Employee/onboarding/Welcome';
import OnboardingPage from '../../components/Employee/onboarding/OnboardingPage';
import Resources from '../../components/Employee/onboarding/Resources';
import OnboardingLayout from '../../components/Employee/onboarding/OnboardingLayout';
import CustomStepperButtonRow from '../../components/shared/StepNavigation/CustomStepperButtonRow';
import { useEmployeeOnboarding } from 'hooks';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { getRoutes } from '../../utils';

const StyledBox = styled(Box)(({ theme }) => ({
  '.stepBlock': {
    width: '100%',
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(2),
    },
  },
}));

const Onboarding = () => {
  const routes = getRoutes();
  const navigate = useNavigate();
  const {
    resources: resourcesAPI,
    documents,
    assignments,
  } = useEmployeeOnboarding();

  useEffect(() => {
    // resources generate pages and the last page of downloadable resources
    resourcesAPI.get({
      variables: {
        limit: 50,
      },
    });
    // documents are a to do list of aside items that the user needs to check off
    documents.get({
      variables: {
        limit: 25,
      },
    });
    assignments.get();
  }, []);

  const resources =
    resourcesAPI?.data?.onboardingResourcesByEmployeeId
      ?.results || [];

  const steps = resources.filter(
    (resource) => resource.resource.isPage,
  );
  const resourceCards = resources.filter(
    (resource) => !resource.resource.isPage,
  );

  const stepsComp = steps.map((step) => (
    <Step
      key={`resources-${step.id}`}
      renderProps={({ next, prev }) => (
        <OnboardingPage
          next={next}
          prev={prev}
          resource={step}
        />
      )}
    />
  ));

  const flourishLabels = [
    'Welcome',
    ...steps.map(({ resource }) => resource.title),
    'Resources',
    'Path',
  ];

  const handleSaveGrow = async () => {
    navigate(routes.employee.dashboard, {
      state: {
        refetchNavigation: true,
      },
    });
  };

  return (
    <StyledBox>
      <StepNavigation
        buttonPosition={'HIDE'}
        stepperFlourish
        flourishLabels={flourishLabels}
      >
        <Step
          renderProps={({ next }) =>
            assignments.loading ? (
              <Spinner />
            ) : (
              <Welcome
                next={next}
                companyName={get(
                  assignments,
                  'data.onboardingAssignmentByEmployeeId.location.name',
                  'Name is N/A',
                )}
              />
            )
          }
        />
        {stepsComp}
        <Step
          renderProps={({ next, prev }) => (
            <Resources
              resources={resourceCards}
              next={next}
              prev={prev}
            />
          )}
        />
        <Step
          renderProps={({ prev }) => (
            <OnboardingLayout>
              <GrowInitialSetup
                onSave={handleSaveGrow}
                loading={false}
                isSetupFailed={false}
              />
              <CustomStepperButtonRow prev={prev} />
            </OnboardingLayout>
          )}
        />
      </StepNavigation>
    </StyledBox>
  );
};

export default Onboarding;
