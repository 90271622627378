const styles = ({ theme }) => ({
  maxWidth: 400,
  width: '100%',
  margin: '0 auto',
  '& .header': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '15px 0 15px 0',
    '& > *': {
      marginBottom: 15,
    },
  },
  '& .survey-item-container > div': {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.light}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .link-item': {
    padding: 10,
    width: '100%',
    marginBottom: 10,
  },
  '& .survey-usage': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '& .delete-survey-button': {
    svg: {
      fill: theme.palette.error.main,
      marginTop: 0,
    },
  },
});
export default styles;
