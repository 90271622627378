import React from 'react';
import { styled } from 'components';
import RootTooltip from './RootTooltip';

const StyledTooltip = styled(({ className, classes, ...props }) => (
  <RootTooltip
    {...props}
    classes={{
      popper: `popper ${className}`,
      tooltip: 'tooltip',
      arrow: 'arrow',
      tooltipPlacementBottom: 'bottom',
      tooltipPlacementTop: 'top',
      ...classes
    }}
  />
))(({ theme }) => ({
  '&.popper[data-popper-placement*="bottom"]': {
    '& .tooltip': {
      marginTop: 25
    },
    '& .arrow': {
      marginBottom: 25
    }
  },
  '&.popper[data-popper-placement*="top"]': {
    '& .tooltip': {
      marginBottom: 25
    },
    '& .arrow': {
      marginBottom: 25
    }
  },
  '& .tooltip': {
    padding: 10,
    borderRadius: 12,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '150%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey['800']
  },
  '& .arrow': {
    fontSize: 30,
    color: theme.palette.grey['800']
  }
}));

const Tooltip = ({ children, ...props }) => (
    <StyledTooltip arrow enterTouchDelay={0} {...props}>
      {children}
    </StyledTooltip>
  )

Tooltip.propTypes = {};

export default Tooltip;
