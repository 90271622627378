import { Box } from 'components/index';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import get from 'lodash/get';
import { Controller } from 'react-hook-form';
import React from 'react';
import FlexRow from '../../../shared/FlexRow';
import { IEducation } from '../../../../api/types/queries/employeeProfile';
import { IWTFieldArrayHelper } from '../types';
import { Button, CheckBox, DatePicker } from '../../../shared';

const RenderEducation = ({
  education,
  errors,
  control,
  validate,
  RenderFormInput,
}) => {
  const educationBase = {
    name: '',
    pos: '',
    startDate: new Date(),
    endDate: new Date(),
    currentJob: false,
  } as IWTFieldArrayHelper<IEducation>;

  return (
    <Box className={'sectionContainer'}>
      <Typography variant={'h2'}>Education</Typography>
      <FlexRow className={'history-container'}>
        {education.fields.length === 0 && (
          <Typography variant={'body1'}>No Education</Typography>
        )}
        {education.fields.map((field, index) => (
          <Paper
            key={field.id}
            className={[
              'history-item',
              get(
                errors,
                `education.${index}.currentJob.message`,
                null,
              )
                ? 'error'
                : '',
            ].join(' ')}
          >
            <Button
              onClick={() => education.remove(index)}
              variant={'outlined'}
              color={'error'}
              size={'small'}
            >
              Delete
            </Button>
            <Controller
              name={`education.${index}.currentJob`}
              control={control}
              rules={{
                validate: validate.current('education', index),
              }}
              render={({ field: controllerField }) => (
                <CheckBox
                  {...controllerField}
                  label={'Currently Attending'}
                  labelPlacement={'start'}
                  checked={controllerField.value}
                  type={'checkbox'}
                />
              )}
            />
            <RenderFormInput
              name={`education.${index}.name`}
              label={'Institution Name'}
              registerOptions={{
                required: 'Institution name is required',
              }}
              error={get(
                errors,
                `education.${index}.name.message`,
                null,
              )}
            />
            <RenderFormInput
              name={`education.${index}.pos`}
              label={'Field of Study'}
              registerOptions={{
                required: 'Field of Study is required',
              }}
              error={get(
                errors,
                `education.${index}.pos.message`,
                null,
              )}
            />
            <Controller
              name={`education.${index}.startDate`}
              control={control}
              rules={{
                validate: validate.dateBefore(
                  'education',
                  index,
                ),
              }}
              render={({ field: controllerField }) => (
                <DatePicker
                  {...controllerField}
                  label={'Start Date'}
                  inputFormat="MM/YYYY"
                  views={['month', 'year']}
                />
              )}
            />
            <Controller
              name={`education.${index}.endDate`}
              control={control}
              rules={{
                validate: validate.dateAfter('education', index),
              }}
              render={({ field: controllerField }) => (
                <DatePicker
                  {...controllerField}
                  label={'Finish Date'}
                  inputFormat="MM/YYYY"
                  views={['month', 'year']}
                />
              )}
            />
            {/*show error for current job if exists*/}
            {get(
              errors,
              `education.${index}.currentJob.message`,
              null,
            ) && (
              <Typography
                paragraph
                variant={'body2'}
                color={'error'}
              >
                {get(
                  errors,
                  `education.${index}.currentJob.message`,
                  null,
                )}
              </Typography>
            )}
          </Paper>
        ))}
      </FlexRow>
      <Button
        onClick={() => education.append(educationBase)}
        variant={'outlined'}
        color={'secondary'}
        size={'small'}
      >
        Add Education
      </Button>
    </Box>
  );
};

export default RenderEducation;
