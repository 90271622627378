import React, { useState } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { keyframes } from '@emotion/react';
import { styled } from 'components';
import { IconButton } from 'components/shared';
import { MdClose, MdSearch } from 'components/icons';

const toggleIn = keyframes({
  '0%': {
    width: 53
  },
  '100%': {
    width: '100%'
  }
});
const toggleOut = keyframes({
  '0%': {
    width: '100%'
  },
  '100%': {
    width: 53
  }
});
const zoomIn = keyframes({
  '0%': {
    width: 53,
    height: 53,
    right: 0
  },
  '100%': {
    width: 47,
    height: 47,
    right: 3
  }
});
const zoomOut = keyframes({
  '0%': {
    width: 47,
    height: 47,
    right: 3
  },
  '100%': {
    width: 53,
    height: 53,
    right: 0
  }
});

const StyledRoot = styled('div')(({ theme }) => ({
  '&.animatedSearchBanner': {
    position: 'relative',
    marginBottom: 16,
    paddingRight: 68,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&.animatedSearchBanner_active': {
      '& .animatedSearchBanner__searchContainer': {
        animation: `${toggleIn} 200ms linear forwards`
      },
      '& .toggleBtnIn': {
        animation: `${zoomIn} 200ms linear forwards`
      }
    }
  },
  '& .animatedSearchBanner__searchContainer': {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 200,
    height: 53,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFF',
    border: `1px solid ${theme.palette.grey['600']}`,
    borderRadius: 25,
    animation: `${toggleOut} 200ms linear forwards`,
    '& .searchInput': {
      '&::placeholder': {
        fontSize: 14,
        lineHeight: '16px',
        color: theme.palette.grey['300']
      }
    },
    '& .animatedSearch__input': {
      '& .StyledInput-inputBase': {
        paddingLeft: 55,
        paddingRight: 53,
        border: 'none'
      }
    }
  },
  '& .toggleBtnIn': {
    position: 'absolute',
    right: 0,
    zIndex: 300,
    width: 53,
    height: 53,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    animation: `${zoomOut} 200ms linear forwards`
  },
  '& .toggleBtnOut': {
    position: 'absolute',
    zIndex: 300,
    width: 47,
    height: 47,
    marginLeft: 3,
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey['600']}`,
    color: theme.palette.grey['600'],
    borderRadius: 100
  }
}));

const JobSearchWithGrowBanner = (props) => {
  const { renderGrowBanner, renderSearchComponent, canClear, onClear } = props;
  const [searchVisible, setSearchVisible] = useState(false);

  return (
    <StyledRoot
      className={clsx('animatedSearchBanner', searchVisible && 'animatedSearchBanner_active')}
    >
      {renderGrowBanner()}
      <div className="animatedSearchBanner__searchContainer">
        <IconButton className="toggleBtnOut" onClick={() => setSearchVisible(false)}>
          <MdClose color="inherit" />
        </IconButton>
        {renderSearchComponent()}
      </div>
      <IconButton
        className="toggleBtnIn"
        onClick={() => {
          if (!searchVisible) setSearchVisible(true);
          else if (canClear) onClear();
        }}
      >
        {searchVisible && canClear ? <MdClose color="inherit" /> : <MdSearch color="inherit" />}
      </IconButton>
    </StyledRoot>
  );
}

JobSearchWithGrowBanner.propTypes = {
  renderGrowBanner: PT.func.isRequired,
  renderSearchComponent: PT.func.isRequired,
  canClear: PT.bool.isRequired,
  onClear: PT.func.isRequired
};

export default JobSearchWithGrowBanner;
