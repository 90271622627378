import { maxWidthMixin } from '../../mixins';

export default ({ theme }) => ({
  '&.wrapper': {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    marginBottom: 16,
    ...maxWidthMixin,
    '& .sectionHeader': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 16,
      marginBottom: 16,
      ...maxWidthMixin,
      borderBottom: `1px solid ${theme.palette.grey['400']}`,
      '& button': {
        padding: 8,
        background: 'none',
        color: theme.palette.text.primary,
        border: 'none',
        maxHeight: 40,
      },
    },
  },
});
