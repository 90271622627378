import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { forEach, get } from 'lodash';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import { Box, styled } from 'components';
import growApi from '../../api';
import { calculateAverageTenure } from './util';
import CompanyBreakdown from './CompanyBreakdown';
import EmployeeTenure from './EmployeeTenure';
import EmployerGrowContext from '../../EmployerGrowContext';

const { EMPLOYEE_TENURE_BY_EMPLOYER } = growApi.query;

const StyledRoot = styled('div')(() => ({
  '&.breakdownAndTenure': {
    '& .chartCard': {
      padding: 0,
      border: 'none',
      borderRadius: 0
    },
    '& .chartTitle': {
      textAlign: 'left'
    }
  },
  '& .breakdownAndTenure__legend': {
    display: 'flex'
  },
  '& .breakdownAndTenure__legend-item': {
    verticalAlign: 'middle',
    '&:not(:first-of-type)': {
      marginLeft: 20
    }
  },
  '& .breakdownAndTenure__legend-color': {
    width: 8,
    height: 8,
    marginRight: 10,
    borderRadius: '50%',
    display: 'inline-block'
  }
}));

const BreakdownAndTenureContainer = () => {
  const { avgTen, companyBreakdown, jobTypes } = useContext(EmployerGrowContext);
  const [legend, setLegend] = useState(null);

  const [fetchTenure, { data: tenureData }] = useLazyQuery(EMPLOYEE_TENURE_BY_EMPLOYER, {
    fetchPolicy: 'no-cache'
  });
  const tenure = tenureData?.employeeTenureByEmployer;

  useEffect(() => {
    fetchTenure();
  }, []);

  const { tenureChartData, breakdownChartData, labels } = useMemo(() => {
    if (tenure && jobTypes) {
      const res = {};

      forEach(tenure, (emp) => {
        const curJobType = get(jobTypes, `${[emp.curJobTypeId]}.name`, 'N/A');
        res[curJobType] = {
          count: res[curJobType]?.count ? res[curJobType].count + 1 : 1,
          tenureMonths: [
            ...(res[curJobType]?.tenureMonths || []),
            differenceInCalendarMonths(new Date(), new Date(emp.startDate))
          ]
        };
      });

      const labelsArr = Object.keys(res);
      const finData = labelsArr.map((l) => ({
        ...res[l],
        avgTenure: calculateAverageTenure(res[l].tenureMonths)
      }));
      return {
        labels: labelsArr,
        tenureChartData: finData.map((o) => Number(o.avgTenure)),
        breakdownChartData: finData.map((o) => o.count)
      };
    }
    return { tenureChartData: [], breakdownChartData: [], labels: [] };
  }, [tenure, jobTypes]);

  return (
    <StyledRoot className="breakdownAndTenure chartCard">
      <Box display="flex">
        {companyBreakdown && (
          <Box flex={1}>
            <CompanyBreakdown
              chartLabels={labels}
              chartData={breakdownChartData}
              showLegend={false}
              onLegendReady={(legendData) => setLegend(legendData)}
            />
          </Box>
        )}
        {avgTen && (
          <Box flex={1}>
            <EmployeeTenure chartLabels={labels} chartData={tenureChartData} showLegend={false} />
          </Box>
        )}
      </Box>
      <div>
        {legend && (
          <div className="breakdownAndTenure__legend">
            {legend.map(({ backgroundColor, label }) => (
              <div key={label} className="breakdownAndTenure__legend-item">
                <span style={{ backgroundColor }} className="breakdownAndTenure__legend-color" />
                <span className="breakdownAndTenure__legend-color-label">{label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </StyledRoot>
  );
}

BreakdownAndTenureContainer.propTypes = {};

export default BreakdownAndTenureContainer;
