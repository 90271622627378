import React from 'react';
import { SvgIcon } from 'components';

const MdCareerDevInsights = (props) => (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<polygon
				fill="currentColor"
				points="16.6 12.56 18.16 9.13 21.57 7.59 18.16 6.05 16.6 2.62 15.08 6.05 11.63 7.59 15.08 9.13 16.6 12.56"
			/>
			<polygon
				fill="currentColor"
				points="9.26 21.96 11.47 17.11 16.3 14.93 11.47 12.74 9.26 7.89 7.11 12.74 2.23 14.93 7.11 17.11 9.26 21.96"
			/>
		</SvgIcon>
	)

export default MdCareerDevInsights;
