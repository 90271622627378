import {
  addStatusStylesMixin,
  cardBaseMixin,
  defaultImageBackgroundMixin,
  gridBuilderMixin,
  maxWidthMixin,
} from '../../mixins';

const styles = ({ theme }) => ({
  ...gridBuilderMixin(theme, 3),
  ...maxWidthMixin,
  ...addStatusStylesMixin(theme, true),
  [theme.breakpoints.down('desktopApp')]: {
    ...gridBuilderMixin(theme, 1),
  },
  '& .brand__cardContainer': {
    ...cardBaseMixin({ theme }),
  },
  '& .brand__imageName': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .brand__profileImage': {
    ...defaultImageBackgroundMixin({ theme }),
    marginRight: 10,
  },
  '& .brand__locations': {
    margin: '20px 0',
  },
  '& .brand__scoreContainer': {},
  '& .brand__scoreRow': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    marginBottom: `15px 0 10px`,
    svg: {
      fill: theme.palette.primary.main,
    },
  },
  '& .brand__scoreTitle': {
    color: theme.palette.text.primary,
  },
});

export default styles;
