import { useScrollToTop } from 'hooks';

const ScrollToTop = () => {
  useScrollToTop();
  return null;
}

ScrollToTop.propTypes = {};

export default ScrollToTop;
