import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon18 = (props) => (
    <SvgIcon viewBox="0 0 41.4 40.9" enableBackground="new 0 0 41.4 40.9" x="0" y="0" {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M21.7,21.2c23.4,0,19.4,2.5,19.4,19.7
C22.6,32.8,21.7,36.5,21.7,21.2z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M19.7,21.2c-23.4,0-19.4,2.5-19.4,19.7
C18.8,32.8,19.7,36.5,19.7,21.2z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M21.7,19.7c23.4,0,19.4-2.5,19.4-19.7
C22.6,8.1,21.7,4.4,21.7,19.7z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M19.7,19.7C-3.7,19.7,0.3,17.2,0.3,0C18.8,8.1,19.7,4.4,19.7,19.7
z"
        />
      </g>
    </SvgIcon>
  )

export default GrowBadgeIcon18;
