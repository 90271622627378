import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { useEffect, useRef } from 'react';
import { buildChartConfig, IChartProps } from './chartUtils';

Chart.register(...registerables);

const tempStyles =
  (styleOverrides) =>
  ({ theme }) => ({
    position: 'relative',
    '& .defaultChartTitle': {
      color: theme.palette.primary.main,
      fontSize: 15,
      display: 'inline-block',
    },
    ...styleOverrides({ theme }),
  });

const CustomizableChart = ({
  type,
  label,
  height = 150,
  width = 150,
  datasets,
  chartLabels,
  chartConfig,
  hasChartGrid = {
    x: false,
    y: false,
  },
  hasChartLabels = {
    x: false,
    y: false,
  },
  styles = () => ({}),
  isStacked,
}: IChartProps) => {
  const chartRef = useRef(null);
  const chartRefCtx = useRef(null);
  const StyledBox = styled(Box)(tempStyles(styles));

  useEffect(() => {
    if (chartRef.current) {
      if (chartRefCtx.current) chartRefCtx.current.destroy();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRefCtx.current = new Chart(
        chartRef.current,
        buildChartConfig({
          chartConfig,
          hasChartGrid,
          hasChartLabels,
          chartLabels,
          type,
          isStacked,
          datasets,
        }),
      );
    }
  }, [chartRefCtx.current]);

  if (chartLabels.length !== datasets[0].data.length) {
    return <Box>The data and labels do not match</Box>;
  }

  return (
    <StyledBox>
      {label && (
        <Typography
          className={'defaultChartTitle chartTitle__override'}
          variant={'h4'}
        >
          {label}
        </Typography>
      )}
      <Box
        height={height}
        width={width}
        className={
          'chart__chartWrapper chart__chartWrapper_override'
        }
      >
        <canvas
          id={label}
          height={height}
          width={width}
          ref={chartRef}
        />
      </Box>
    </StyledBox>
  );
};

export default CustomizableChart;
