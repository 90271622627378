import { styled, BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
// import { BoxProps } from '@mui/material/Box/Box';

const StyledBox = styled(Box)(() => ({
  '> *': {
    marginRight: '1rem!important',
    '&:last-child': {
      marginRight: '0!important',
    },
    '&.fullWidth': {
      width: '100%',
      flexBasis: '100%',
    },
  },
}));

interface RowProps extends BoxProps {
  children: React.ReactNode;
  inline?: boolean;
}

const FlexRow = ({
  children,
  inline,
  className,
  ...rest
}: RowProps) => (
  <StyledBox
    display={'flex'}
    alignItems={'center'}
    mb={inline ? 0 : '1rem'}
    flexWrap={'wrap'}
    className={`childContainer ${className}`}
    {...rest}
  >
    {children}
  </StyledBox>
);
export default FlexRow;
