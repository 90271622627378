import React, { useState, useCallback, useMemo } from 'react';
import UpgradeExpiredPlanDialog from 'components/dialogs/Payment/UpgradeExpiredPlanDialog';
import PaymentDialog from 'components/dialogs/Payment/PaymentDialog';
import { ChatDialog } from 'components/Chat';
import { CommonUIContext } from './context';

const initModalsState = {
  updatePlanModal: false,
  paymentModal: false,
  chatModal: false,
  chatModalProps: {},
  updatePlanModalProps: {},
  paymentModalProps: {
    type: ''
  }
};

const CommonUIProvider = ({ children, context }) => {
  const [modals, setModals] = useState(initModalsState);
  const isUpdatePlanModalOpen = modals.updatePlanModal;
  const isPaymentModalOpen = modals.paymentModal;
  const isChatModalOpen = modals.chatModal;

  const manageModals = useCallback((params = {}) => {
    setModals((prev) => ({ ...prev, ...params }));
  }, []);

  const openUpdatePlanModal = useCallback((params = {}, ...rest) => {
    manageModals({
      updatePlanModal: true,
      updatePlanModalProps: { ...params },
      ...rest
    });
  }, []);

  const closeUpdatePlanModal = useCallback((params = {}) => {
    manageModals({ updatePlanModal: false, updatePlanModalProps: {}, ...params });
  }, []);

  const openPaymentModal = useCallback((params = {}, ...rest) => {
    manageModals({
      paymentModal: true,
      paymentModalProps: { ...params },
      ...rest
    });
  }, []);

  const closePaymentModal = useCallback((params = {}) => {
    manageModals({
      paymentModal: false,
      paymentModalProps: { ...initModalsState.paymentModalProps },
      ...params
    });
  }, []);

  const openChatModal = useCallback((params = {}, ...rest) => {
    manageModals({
      chatModal: true,
      chatModalProps: { ...params },
      ...rest
    });
  }, []);

  const closeChatModal = useCallback((params = {}) => {
    manageModals({
      chatModal: false,
      chatModalProps: { ...initModalsState.chatModalProps },
      ...params
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      closeChatModal,
      closePaymentModal,
      closeUpdatePlanModal,
      manageModals,
      openChatModal,
      openPaymentModal,
      openUpdatePlanModal
    }),
    [
      closeChatModal,
      closePaymentModal,
      closeUpdatePlanModal,
      manageModals,
      openChatModal,
      openPaymentModal,
      openUpdatePlanModal
    ]
  );

  const Context = context || CommonUIContext;

  return (
    <Context.Provider value={contextValue}>
      {children}
      {isUpdatePlanModalOpen && (
        <UpgradeExpiredPlanDialog
          isOpen={isUpdatePlanModalOpen}
          onClose={closeUpdatePlanModal}
          openPaymentModal={openPaymentModal}
          closePaymentModal={closePaymentModal}
          {...modals.updatePlanModalProps}
        />
      )}
      <PaymentDialog
        isOpen={isPaymentModalOpen}
        onClose={closePaymentModal}
        {...modals.paymentModalProps}
      />
      {isChatModalOpen && (
        <ChatDialog isOpen={isChatModalOpen} onClose={closeChatModal} {...modals.chatModalProps} />
      )}
    </Context.Provider>
  );
}

export default CommonUIProvider;
