import { MuiIconButton, styled, Tooltip } from 'components';
import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { qaAttr } from 'utils';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { useMediaQueryMatches } from '../../../hooks';

const PREFIX = 'StyledIconButton';
const classes = {
  tooltip: `${PREFIX}-tooltip`,
  tooltipTop: `${PREFIX}-tooltipTop`,
  tooltipBottom: `${PREFIX}-tooltipBottom`,
};

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{
      popper: className,
      tooltip: classes.tooltip,
      tooltipPlacementTop: classes.tooltipTop,
      tooltipPlacementBottom: classes.tooltipBottom,
    }}
  />
))(({ theme }) => ({
  [`& .${classes.tooltip}`]: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.tooltipTop}`]: {
    marginBottom: '8px !important',
  },
  [`& .${classes.tooltipBottom}`]: {
    marginTop: '0 !important',
  },
}));

const StyledIconButton = styled(MuiIconButton)(
  ({ height, width }) => ({
    borderRadius: 48,
    height,
    width,
  }),
);

interface IIconButtonProps {
  analyticParams?: {
    key: string;
    // eslint-disable-next-line
    params: any;
  };
  // eslint-disable-next-line
  component?: any;
  className?: string;
  isRouterLink?: boolean;
  onClick: (e: SyntheticEvent) => void;
  height?: number | string;
  width?: number | string;
  toolTipProps?: Partial<TooltipProps>;
  testID?: string;
  variant:
    | 'contained'
    | 'filled-primary'
    | 'outlined-primary'
    | 'outlined'
    | 'filled-secondary'
    | 'outlined-secondary';
  withTooltip?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

const IconButton = ({
  component = 'button',
  className,
  isRouterLink = false,
  onClick,
  toolTipProps,
  testID,
  withTooltip = false,
  height,
  width,
  ...iconButtonProps
}: IIconButtonProps) => {
  const { isMobile } = useMediaQueryMatches();
  const baseTooltipProps = {
    placement: 'top',
    disableTouchListener: true,
    PopperProps: { disablePortal: true },
    ...toolTipProps,
  };

  const handleClick = (e) => {
    if (onClick) onClick(e);
  };

  const defaultHeightByDevice = isMobile ? 60 : 48;
  const defaultWidthByDevice = isMobile ? 60 : 48;

  if (iconButtonProps.selected) {
    return (
      <StyledIconButton
        component={isRouterLink ? Link : component}
        className={`${className} selected`}
        onClick={handleClick}
        {...(testID && qaAttr(testID))}
        height={height == null ? defaultHeightByDevice : height}
        width={width == null ? defaultWidthByDevice : width}
        {...iconButtonProps}
      />
    );
  }
  if (withTooltip && !iconButtonProps.disabled && !isMobile) {
    return (
      <StyledTooltip {...baseTooltipProps}>
        <span>
          <StyledIconButton
            component={isRouterLink ? Link : component}
            className={className}
            onClick={handleClick}
            height={
              height == null ? defaultHeightByDevice : height
            }
            width={width == null ? defaultWidthByDevice : width}
            {...(testID && qaAttr(testID))}
            {...iconButtonProps}
          />
        </span>
      </StyledTooltip>
    );
  }
  return (
    <StyledIconButton
      component={isRouterLink ? Link : component}
      className={className}
      onClick={handleClick}
      height={height == null ? defaultHeightByDevice : height}
      width={width == null ? defaultWidthByDevice : width}
      {...(testID && qaAttr(testID))}
      {...iconButtonProps}
    />
  );
};

export default IconButton;
