const ResourceDefaultPageBackground = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 205.41 134.46"
  >
    <rect
      x=".5"
      y=".5"
      width="204.41"
      height="133.46"
      rx="6.57"
      ry="6.57"
      fill="#141315"
      stroke="#8783be"
      strokeMiterlimit="10"
    />
    <rect
      x="146.07"
      y="29.5"
      width="33.79"
      height="16.33"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25"
      y="29.5"
      width="29.32"
      height="27.69"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="57.5"
      y="29.5"
      width="29.32"
      height="27.69"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="89.99"
      y="29.5"
      width="29.32"
      height="27.69"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25"
      y="62.16"
      width="29.32"
      height="27.69"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25"
      y="18.8"
      width="51.73"
      height="6.55"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="57.5"
      y="62.16"
      width="29.32"
      height="27.69"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="89.99"
      y="62.16"
      width="29.32"
      height="27.69"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="146.07"
      y="50.62"
      width="33.79"
      height="16.33"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="146.07"
      y="71.73"
      width="33.79"
      height="16.33"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="146.07"
      y="92.85"
      width="33.79"
      height="16.33"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="24.9"
      y="95.48"
      width="15.02"
      height="7.13"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="44.05"
      y="95.48"
      width="15.02"
      height="7.13"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
  </svg>
);
export default ResourceDefaultPageBackground;
