import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';

const StyledRoot = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'fullWidth' &&
    prop !== 'maxWidth' &&
    prop !== 'showFooter',
})(({ theme, fullWidth, maxWidth }) => ({
  position: 'relative',
  // height: '100vh',
  width: '100%',
  margin: '0 auto',

  '& .contentWrapper': {
    width: '100%',
    maxWidth: fullWidth ? '100%' : maxWidth,
    // height: '100%',
    minHeight: '100vh',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    // ...(showFooter && {
    //   paddingBottom: 74
    // }),
    [theme.breakpoints.down('desktopApp')]: {
      paddingBottom: 0,
    },
    [theme.breakpoints.down('tablet')]: {
      paddingTop: theme.spacing(5),
    },
  },
}));

const AppLayout = (props) => {
  const {
    children,
    className,
    fullWidth,
    headerElement,
    maxWidth,
  } = props;
  return (
    <StyledRoot
      className={className}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <div className="contentWrapper">
        {headerElement}
        {children}
      </div>
    </StyledRoot>
  );
};

AppLayout.propTypes = {
  children: PT.node,
  className: PT.string,
  fullWidth: PT.bool,
  headerElement: PT.element,
  maxWidth: PT.oneOfType([PT.string, PT.number]),
};

AppLayout.defaultProps = {
  children: null,
  className: '',
  fullWidth: false,
  headerElement: null,
  maxWidth: 1024,
};

export default AppLayout;
