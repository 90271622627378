import EmbedVideo from '../../shared/Video/EmbedVideo';
import OnboardingLayout from './OnboardingLayout';
import Typography from '@mui/material/Typography';
import CustomStepperButtonRow from '../../shared/StepNavigation/CustomStepperButtonRow';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '../../material-ui';
import { Button } from '../../shared';

const StyledBox = styled(Box)(({ theme }) => ({
  '.video': {
    minHeight: 230,
    maxWidth: 406,
    marginBottom: theme.spacing(3.5),
    borderRadius: theme.spacing(2),
  },
  '.onboardingMediaType': {
    // button: {
    //   paddingLeft: 0,
    // },
  },
  '.resourceDescription': {
    marginBottom: theme.spacing(2),
  },
}));

const OnboardingPage = ({
  next,
  prev,
  resource: resourceOnboarding,
}) => {
  const { resource } = resourceOnboarding;
  const MediaType = React.memo(() => {
    switch (resource.type) {
      case 'video':
        return (
          <EmbedVideo
            src={resource.url}
            iframeProps={{ height: 174 }}
          />
        );
      case 'image':
        return <img src={resource.url} alt={resource.title} />;
      case 'url':
      case 'document':
        return (
          <Button
            sx={{
              marginTop: (theme) => theme.spacing(1),
              marginBottom: (theme) => theme.spacing(1),
            }}
            variant={'outlined-primary'}
            onClick={() => window.open(resource.url)}
          >
            {resource.title}
          </Button>
        );
      default:
        return null;
    }
  });

  return (
    <OnboardingLayout>
      <StyledBox>
        <Typography variant={'h1'}>{resource.title}</Typography>
        <Box className={'onboardingMediaType'}>
          <MediaType />
        </Box>
        <Box style={{ maxWidth: 400 }}>
          <Typography
            variant={'body1'}
            className={'resourceDescription'}
          >
            {resource.description}
          </Typography>
        </Box>
        <CustomStepperButtonRow prev={prev} next={next} />
      </StyledBox>
    </OnboardingLayout>
  );
};

export default OnboardingPage;
