import { useEffect, useMemo, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { find } from 'lodash';
import { GET_CURRENCIES } from 'api';

const FIELD_NAME = 'getAllCurrencies';

export default function useCurrenciesQuery(props) {
  const { autoFetch = true, ...queryOptions } = props || {};

  const [fetch, result] = useLazyQuery(GET_CURRENCIES, {
    ...queryOptions
  });
  const getResultFields = useCallback((res) => res?.data?.[FIELD_NAME], []);
  const currencies = getResultFields(result);

  useEffect(() => {
    if (autoFetch) fetch({ ...queryOptions });
  }, [autoFetch]);

  const getCurrencyById = useCallback(
    (id) => {
      if (id) return find(currencies, ['id', Number(id)]) || {};
      return {};
    },
    [currencies]
  );

  return useMemo(
    () => ({
      fetch,
      currencies,
      fieldName: FIELD_NAME,
      getResultFields,
      getCurrencyById,
      ...result
    }),
    [fetch, currencies, getResultFields, getCurrencyById, result]
  );
}
