import {
  backgroundTransparentBorderMixin,
  baseGap,
  containerPaddingMixin,
  locationDetailHeight,
} from '../helpers';

export const pathEntryStyles = ({ theme }) => ({
  '& .aside-root': {
    overflow: 'initial',
    marginTop: `calc(${baseGap}px + ${locationDetailHeight}px)`,
    height: `calc(100vh - (${baseGap}px * 3) - ${locationDetailHeight}px)`,
    ...backgroundTransparentBorderMixin({ theme }),
    ...containerPaddingMixin(0.5),
    boxSizing: 'border-box',
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(1)
    },
  },
  '& .content-root': { overflow: 'hidden' },
  '& .content-override': { overflow: 'scroll' },
  '&.path-detail-content-container': {
    ...containerPaddingMixin(),
    [theme.breakpoints.down('desktopApp')]: {
      padding: theme.spacing(1)
    },
  },
  // end side bar styles
  // start employee path styles
  '& .path-detail-title-container': {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
});

export const pathAsideStyles = ({ theme }) => ({
  '& .path-stat-chart-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '& img': { height: 45, width: 45 },
    },
  },
  '& .employee-path-stat-title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 5,
  },
  '& .employee-path-row-container': {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 10,
    },
  },
  '& .employee-path-stat-profile': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: 5,
    '& img': {
      height: 25,
      width: 25,
      borderRadius: 50,
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  '& .percent-complete': {
    textAlign: 'center',
    lineHeight: 1,
    paddingTop: 3,
    span: { display: 'block' },
  },
  '& .employee-path-content-container': { flexGrow: 1 },
  '& .employee-path-stat-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: { fill: theme.palette.primary.light, fontSize: 12 },
  },
  '& .no-path-assigned': {
    color: theme.palette.anomaly.main,
  },
  '& .path-progress-container': {
    width: '100%',
    height: 3,
    background: theme.palette.background.paper,
    position: 'relative',
    overflow: 'hidden',
    marginTop: 5,
  },
  '& .path-progress': {
    background: theme.palette.anomaly.main,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
  },
});
