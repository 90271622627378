import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { styled } from 'components';
import { Button } from 'components/shared';
import Typography from '@mui/material/Typography';
import { useJobTypesQuery } from 'hooks';
import { GET_RANDOM_CAREER_PATH_ITEMS } from 'api';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.availableTracks': {},
  '& .availableTracksItem': {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
    '&:not(:last-of-type)': {
      marginBottom: 10,
    },
  },
  '& .availableTracksItem__title': {
    fontWeight: 'bold',
  },
}));

const AvailableTracks = ({ industryId, onPathSelect }) => {
  const [tracks, setTracks] = useState([]);

  const { getJobTypes, findJobTypeById, jobTypes } =
    useJobTypesQuery();

  const [fetchRandomPaths, { data }] = useLazyQuery(
    GET_RANDOM_CAREER_PATH_ITEMS,
  );
  const paths = data?.getRandomCareerPath || [];

  useEffect(() => {
    getJobTypes();
    fetchRandomPaths({ variables: { id: industryId } });
  }, []);

  useEffect(() => {
    if (paths.length) {
      const formattedPaths = paths.map((path) => ({
        ...path,
        startJobTypeName:
          findJobTypeById(path.startJobTypeId, jobTypes)?.name ||
          '',
        endJobTypeName:
          findJobTypeById(path.endJobTypeId, jobTypes)?.name ||
          '',
      }));
      setTracks(formattedPaths);
    }
  }, [paths, jobTypes]);

  return (
    <StyledRoot className="availableTracks">
      {tracks.map((track) => (
        <div key={track.id} className="availableTracksItem">
          <Typography
            variant={'p'}
            color={'primary'}
            className="availableTracksItem__title"
          >
            {track.endJobTypeName}
          </Typography>
          <Button
            variant="filled-primary"
            onClick={() =>
              onPathSelect(
                track.startJobTypeId,
                track.endJobTypeId,
              )
            }
          >
            Start track
          </Button>
        </div>
      ))}
    </StyledRoot>
  );
};

AvailableTracks.propTypes = {
  industryId: PT.number.isRequired,
  onPathSelect: PT.func.isRequired,
};

export default AvailableTracks;
