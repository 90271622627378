export const PAGES = {
  path: 'path',
  badges: 'badges',
  trends: 'trends',
  ratings: 'ratings',
  salary: 'salary',
  connections: 'connections',
  classes: 'classes',
  metrics: 'metrics',
  progress: 'progress',
  quizzes: 'quizzes',
  blogs: 'blogs',
  onboarding: 'onboarding',
};
