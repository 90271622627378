import { Breadcrumbs, Chip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'components';
import { RetentionContext } from 'components/Retention/utils';
import { Spinner } from 'components/shared';
import { getRoutes } from 'utils';

const StyledBreadcrumbs = styled('div')(() => ({
  marginBottom: 24,
}));

const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
  backgroundColor: 'default',
  height: theme.spacing(3),
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover, &:focus': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  '&:active': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
})) as typeof Chip;

const RetentionBreadcrumbs = () => {
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<
    string[]
  >([]);
  const location = useLocation();
  const { pathname } = location;
  const routes = getRoutes();
  const ctx = useContext(RetentionContext);
  const { dashboardLevels, retentionPages } = ctx;
  const navigate = useNavigate();

  useEffect(() => {
    const retentionIndex = pathname.indexOf('retention');
    const paths = pathname
      .slice(retentionIndex, pathname.length)
      .split('/');
    setBreadcrumbPaths(paths);
  }, [pathname]);

  const handleNavigation = (navPath: string) => () => {
    navigate(navPath);
  };

  const getBreadcrumb = (path: string) => {
    const baseProps = {
      href: null,
      onClick: () => null,
      clickable: false,
    } as {
      href?: string;
      onClick: (
        event: React.MouseEvent<Element, MouseEvent>,
      ) => void;
      clickable: boolean;
    };
    const canAccessBrand =
      path === dashboardLevels.brand && ctx.accessBrand;
    const canAccessOrganization =
      path === dashboardLevels.retention &&
      ctx.accessOrganization;
    const atLocationLevel =
      pathname.indexOf(dashboardLevels.location) > 0;

    if (canAccessBrand && atLocationLevel) {
      const parts = pathname.split('/');
      const currentDashboard = parts[parts.length - 1];
      if (
        [
          retentionPages.survey,
          retentionPages.workforce,
        ].includes(currentDashboard)
      ) {
        baseProps.onClick = handleNavigation(
          `${routes.employer.retention_portal.brand}/${retentionPages.path}`,
        );
      } else {
        baseProps.onClick = handleNavigation(
          `${routes.employer.retention_portal.brand}/${currentDashboard}`,
        );
      }

      baseProps.clickable = true;
    } else if (canAccessOrganization) {
      baseProps.onClick = handleNavigation(
        routes.employer.retention_portal.root,
      );
      baseProps.clickable = true;
    } else {
      return <Typography key={path}>{path}</Typography>;
    }

    return (
      <StyledBreadcrumb
        key={path}
        component={'a'}
        label={path}
        {...baseProps}
      />
    );
  };

  if (!dashboardLevels || !retentionPages) {
    return <Spinner />;
  }

  return (
    <StyledBreadcrumbs>
      <Breadcrumbs component="div" aria-label="breadcrumb">
        {breadcrumbPaths.map((breadcrumb) =>
          getBreadcrumb(breadcrumb),
        )}
      </Breadcrumbs>
    </StyledBreadcrumbs>
  );
};

export default RetentionBreadcrumbs;
