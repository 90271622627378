import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, styled } from 'components';
import {
  MdClose,
  MdCheck,
  MdStarBorder,
} from 'components/icons';
import Button, { IconButton } from 'components/shared/Button';
import jobCardStyles from 'styles/Dashboard/EmployeeJobCard';
import styles from 'styles/Dashboard/EmployeeManual';
import navIcon from 'assets/img/navigation_icon.png';
import defaultImg from 'assets/img/job_default.png';
import leftArrow from 'assets/img/left_curved_arrow@1x.png';
import rightArrow from 'assets/img/right_curved_arrow@1x.png';
import pointer from 'assets/img/pointer@1x.png';

const StyledCard = styled('div')(({ theme }) => ({
  ...jobCardStyles({ theme }),
  '&.hoveredCard': {
    backgroundColor: theme.palette.grey['800'],
    boxShadow: '0 10px 20px 0 rgba(255,255,255,0.16)',
    borderRadius: 8,
    border: `2px solid ${theme.palette.primary.light}`,
    '& .card__company': {
      visibility: 'hidden',
    },
    '& .card__actions': {
      display: 'flex',
    },
    '& .card__jobStats': {
      color: theme.palette.text.primary,
    },
  },
}));

const EmployeeDashboardManual = ({
  className,
  isOpen,
  onComplete,
  jobExampleData,
}) => {
  const {
    title = 'Part-Time Gardner',
    imageUrl,
    applied = false,
    payRange = '$14-20/hr',
    distance = 3.6,
    location = 'New York, NY',
    description = 'Looking for a part-time gardner to take care of flowers and bushes.',
    employerProfile = {
      imageUrl: '',
      name: '',
    },
  } = jobExampleData;

  const renderJobCard = () => (
    <StyledCard className="hoveredCard">
      <div className="card__btn">
        <div
          className="card__photoArea"
          style={{
            backgroundImage: `url("${imageUrl || defaultImg}")`,
          }}
        >
          {applied && (
            <div className="status applied">
              <MdCheck
                color="inherit"
                fontSize="inherit"
                className="status__icon"
              />
              Applied
            </div>
          )}
        </div>
        <Box p="22px 18px">
          <h2 className="card__name">{title}</h2>
          <Box mb="22px" className="card__jobStats">
            <Box textAlign="left">
              <span>{payRange}</span>
              <img className="navIcon" src={navIcon} alt="" />
              <span>{`${distance} mi - ${location}`}</span>
            </Box>
          </Box>
          <Box mb="18px" className="card__jobDescription">
            {description && description?.length > 73
              ? `${description.substring(0, 70)} ...`
              : description}
          </Box>
          <div className="card__company">
            <div
              className="card__companyLogo"
              style={{
                backgroundImage: `url("${employerProfile.imageUrl}")`,
              }}
            />
            <Box ml="16px" className="card__companyName">
              {employerProfile.name}
            </Box>
          </div>
        </Box>
      </div>
      <div className="card__actions">
        <div>
          <IconButton
            disableTouchRipple
            variant="outlined"
            color="primary"
            sx={{ padding: '7px' }}
          >
            <MdClose />
          </IconButton>
          <IconButton
            disableTouchRipple
            variant="filled-primary"
            withTooltip
            toolTipProps={{ title: 'Apply', open: true }}
            sx={{ padding: '7px', ml: '16px' }}
          >
            <MdCheck />
            <img className="pointerIcon" src={pointer} alt="" />
          </IconButton>
          <IconButton
            disableTouchRipple
            variant="outlined"
            color="primary"
            sx={{ padding: '7px', ml: '16px' }}
          >
            <MdStarBorder />
          </IconButton>
        </div>
      </div>
    </StyledCard>
  );

  return (
    <Backdrop
      open={isOpen}
      className={className}
      classes={{ root: 'backdrop__root' }}
    >
      <div className="contentContainer">
        <div className="cardContainer">
          {renderJobCard()}
          <div className="tipsContainer">
            <div className="tip">
              <span>Pass</span>
              <img
                width={58}
                height={56}
                src={leftArrow}
                style={{ marginBottom: -4 }}
                alt=""
              />
            </div>
            <div className="tip">
              <img
                width={58}
                height={56}
                src={rightArrow}
                style={{ marginBottom: -4 }}
                alt=""
              />
              <span>Save</span>
            </div>
          </div>
        </div>
        <Box width="100%" maxWidth={265} ml="31px">
          <Box component="h2" mb="12px" className="largeText">
            Quickly apply to jobs
          </Box>
          <Box component="p" mb="12px" className="text">
            Hover over a job card to apply, pass or save a job.
          </Box>
          <Button
            variant="filled-primary"
            sx={{ height: 50, width: 265 }}
            onClick={onComplete}
            testID="got-it-button"
          >
            Got it
          </Button>
        </Box>
      </div>
    </Backdrop>
  );
}

EmployeeDashboardManual.propTypes = {
  className: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  jobExampleData: PropTypes.objectOf(PropTypes.any),
};

EmployeeDashboardManual.defaultProps = {
  jobExampleData: {},
};

const Styled = styled(EmployeeDashboardManual)(styles);

export default memo(Styled);
