import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import {
  Button,
  CheckBox,
  CheckBoxGroup,
  IconButton,
  Input,
  Spinner,
} from '../shared';
import { Box, Dialog, Pagination, styled } from '../material-ui';
import { MdArrowBack } from '../icons';
import { qaAttr } from '../../utils';

const styles = ({ theme }) => ({
  '& .dialog-header': {
    padding: '13px 13px 12px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '5px 17px',
    },
  },
  '& .dialog-title': {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      lineHeight: '18px',
      color: theme.palette.common.white,
    },
  },
  '& .header-action-start': {
    flex: '0 0 44px',
  },
  '& .header-action-end': {
    flex: '0 0 44px',
  },
  '& .dialog-content': {
    padding: '0 17px 15px',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  '& .dialog-container': {
    width: '100%',
  },
  '& .dialog-row': {
    label: {
      marginRight: 15,
      '& > span:first-of-type': {
        height: 40,
        width: 40,
        backgroundColor: theme.palette.common.white,
        marginRight: 10,
        svg: {
          fill: theme.palette.primary.main,
        },
      },
      '& > *:last-of-type': {
        marginLeft: 10,
        div: {
          display: 'flex',
          alignItems: 'center',
          marginLeft: 10,
          img: {
            height: 25,
            width: 25,
          },
        },
      },
    },
  },
  '& .dialog-search-container': {
    padding: '0 17px 15px',
  },
  '& .dialog-button-row': {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 15px 25px',
  },
});

const StyledDialog = styled(Dialog)(styles);

interface ISearchableDialogAttrs {
  title: string;
  rows: Array<{ id: number | string; name: string }>;
  dataIsLoading: boolean;
  onClose: (row?: unknown) => void;
  isOpen: boolean;
  testKey?: string;
  itemsPerPage?: number;
  hasSearch?: boolean;
  hasPagination?: boolean;
  renderRow?: (row: unknown) => JSX.Element;
  multiSelect?: boolean;
  multiSelectLimit?: number;
}
const SearchableDialog = ({
  title,
  rows,
  dataIsLoading,
  onClose,
  isOpen,
  testKey,
  itemsPerPage = 10,
  hasSearch = true,
  hasPagination = true,
  renderRow,
  multiSelect = false,
  multiSelectLimit,
}: ISearchableDialogAttrs) => {
  const [searchText, setSearchText] = useState('');
  const [selectedRow, setSelectedRow] = useState(
    multiSelect ? [] : null,
  );
  const [filteredRows, setFilteredRows] = useState(rows);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [limitReached, setLimitReached] = useState(false);

  const handlePageChange = (e, pageNumber) => {
    setActivePageNumber(pageNumber - 1);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  useEffect(() => {
    if (searchText === '') {
      // reset search
      setFilteredRows(rows);
      return;
    }

    const lazySearch = setTimeout(() => {
      const filteredRowsTemp = rows.filter(
        (row) =>
          row.name
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) >= 0,
      );
      setFilteredRows(filteredRowsTemp);
      setActivePageNumber(0);
    }, 500);
    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(lazySearch);
    };
  }, [searchText]);

  const handleSearchText = (e) => setSearchText(e.target.value);

  const selectRowIds = multiSelect
    ? selectedRow.map((r) => r.id)
    : null;
  const handleSetRow = (row) => {
    if (multiSelect) {
      if (multiSelectLimit === selectedRow.length) {
        setLimitReached(true);
      }
      // first record
      if (selectedRow === []) {
        setSelectedRow([row]);
        return null;
      }
      const currentIndex = selectRowIds.indexOf(row.id);
      // row is included in array, remove it
      if (currentIndex >= 0) {
        const tempRows = selectedRow.filter(
          (r) => r.id !== row.id,
        );
        setSelectedRow(tempRows);
      } else {
        // add item to array
        setSelectedRow([...selectedRow, row]);
      }
      return null;
    }
    setSelectedRow(row);
    return null;
  };

  const filteredRowsSlice = filteredRows.slice(
    activePageNumber * itemsPerPage,
    hasPagination
      ? activePageNumber * itemsPerPage + itemsPerPage
      : filteredRows.length,
  );

  const closeModal = () => {
    onClose(selectedRow);
    setSelectedRow(multiSelect ? [] : null);
  };

  return (
    <StyledDialog open={isOpen} onClose={closeModal}>
      {dataIsLoading ? (
        <Spinner />
      ) : (
        <Box>
          <Box className="dialog-header">
            <Box className="header-action-start">
              <IconButton
                color="primary"
                aria-label="return"
                className="header-action-end"
                onClick={closeModal}
                testID="close-modal-button"
              >
                <MdArrowBack />
              </IconButton>
            </Box>
            <h2
              className="dialog-title"
              {...qaAttr(`searchable-dialog-title-${testKey}`)}
            >
              {title}
              {limitReached && (
                <Typography variant={'body1'}>
                  You can only select {multiSelectLimit} items
                </Typography>
              )}
            </h2>
            <div className="header-action-end" />
          </Box>
          {hasSearch && (
            <Box className={'dialog-search-container'}>
              <Typography variant={'body1'}>Search</Typography>
              <Input
                value={searchText}
                onChange={handleSearchText}
              />
            </Box>
          )}
          <Box className="dialog-content">
            <CheckBoxGroup className={'dialog-container'}>
              {filteredRowsSlice.map((row) => (
                <Box key={row.id} className={'dialog-row'}>
                  <CheckBox
                    key={`checkbox-${row.id}`}
                    checked={
                      multiSelect
                        ? selectRowIds.indexOf(row.id) >= 0
                        : selectedRow?.id === row.id
                    }
                    onChange={() => handleSetRow(row)}
                    label={
                      renderRow ? (
                        renderRow(row)
                      ) : (
                        <Typography variant={'h2'}>
                          {row.name}
                        </Typography>
                      )
                    }
                    value={row.id}
                  />
                </Box>
              ))}
            </CheckBoxGroup>
            {hasPagination &&
              filteredRows.length > itemsPerPage && (
                <Pagination
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '15px',
                  }}
                  count={Math.ceil(
                    filteredRows.length / itemsPerPage,
                  )}
                  size="medium"
                  onChange={handlePageChange}
                />
              )}
          </Box>
          <Box className={'dialog-button-row'}>
            <Button
              variant={'filled-secondary'}
              size={'large'}
              onClick={closeModal}
            >
              Done
            </Button>
          </Box>
        </Box>
      )}
    </StyledDialog>
  );
};

export default SearchableDialog;
