import { gql } from '@apollo/client';

export const ADMIN_TO_EMPLOYER_PROFILE_FRAGMENT = gql`
  fragment AdminToEmployerProfileFragment on AdminToEmployerProfile {
    active
    adminProfileId
    adminUserId
    email
    employerProfileId
    id
    name
    userId
  }
`;

export const JOB_PUBLIC_FIELDS_FRAGMENT = gql`
  fragment JobPublicFieldsFragment on Jobs {
    active
    createdAt
    countryId
    city
    description
    employerProfile {
      name
      imageUrl
    }
    hireDate
    id
    isSchedule
    quickApply
    imageUrl
    location
    payRange
    payMin
    payMax
    payCurrencyId
    payPeriodId
    requirements
    skills {
      skill_id
      name
    }
    stateId
    title
    updatedAt
    zip
  }
`;

export const JOB_CORE_FIELDS_FRAGMENT = gql`
  fragment JobCoreFieldsFragment on Jobs {
    ...JobPublicFieldsFragment
    approved
    applicantAmount
    applicantsCount
    closedDate
    draft
    employerProfileProfileId
    employerProfile {
      name
      imageUrl
      profile_id
      zip
      city
      address
      description
      industry {
        name
        industry_id
      }
    }
    endDate
    greatFit
    greatPay
    interviewType
    industry {
      name
      industry_id
    }
    jobTypeId
    passed
    popularJob
    scheduleStatusCount
    starred
    userInterviewDate
    webUrl
  }
  ${JOB_PUBLIC_FIELDS_FRAGMENT}
`;

export const JOB_QUESTIONS_FRAGMENT = gql`
  fragment JobQuestionsFragment on Jobs {
    questions {
      name
      questions_id
    }
  }
`;

export const USER_TO_JOBS_FRAGMENT = gql`
  fragment UserToJobsFragment on UserToJobs {
    approved
    createdAt
    id
    passed
    starred
    userInterviewDate
    updatedAt
  }
`;

export const SKILL_BADGE_FRAGMENT = gql`
  fragment SkillBadgeFieldsFragment on SkillsBadges {
    id
    name
    badgeUrl
    skillsId
    skillsBadgesToEmployees {
      id
      employeeUserId
      skillsBadgeId
      complete
    }
  }
`;

export const ABILITY_BADGE_FRAGMENT = gql`
  fragment AbilityBadgeFieldsFragment on AbilitiesBadges {
    id
    name
    badgeUrl
    abilitiesId
  }
`;

export const ORGANIZATION_STATS_COMMON_FIELDS = gql`
  fragment OrganizationStatsCommonFields on organizationStatObjectType {
    name
    currentValue
    valueType
    changeOverTime
  }
`;

export const ORGANIZATION_STATS_FRAGMENT = gql`
  fragment OrganizationStatsFieldsFragment on organizationStatsObjectType {
    employeeSatisfaction {
      ...OrganizationStatsCommonFields
    }
    averageRetention {
      ...OrganizationStatsCommonFields
    }
    totalInteractions {
      ...OrganizationStatsCommonFields
    }
    employeeMood {
      ...OrganizationStatsCommonFields
    }
    interactionScore {
      ...OrganizationStatsCommonFields
    }
    skillsCompleted {
      ...OrganizationStatsCommonFields
    }
    averageGrowScore {
      ...OrganizationStatsCommonFields
    }
    averageCompTime {
      ...OrganizationStatsCommonFields
    }
    estimatedCompRate {
      ...OrganizationStatsCommonFields
    }
  }
  ${ORGANIZATION_STATS_COMMON_FIELDS}
`;
