import React from 'react';
import { Box } from '../../../../components';
import ComingSoon from '../../../../components/Retention/utils/ComingSoon';

const BrandHealthEntry = () => (
    <Box>
      <ComingSoon />
    </Box>
  );

export default BrandHealthEntry;
