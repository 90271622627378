export type badgeStatusStage =
  | 'complete'
  | 'pending'
  | 'initial';

export type badgeStatusStageAPIEnum =
  | 'COMPLETE'
  | 'PENDING'
  | 'INITIAL';
export const badgeStatus: { [s: string]: badgeStatusStage } = {
  COMPLETE: 'complete',
  PENDING: 'pending',
  INITIAL: 'initial',
};

export const badgeStatusEnum: {
  [s: string]: badgeStatusStageAPIEnum;
} = {
  COMPLETE: 'COMPLETE',
  PENDING: 'PENDING',
  INITIAL: 'INITIAL',
};

export interface IQueryBase {
  id: number | string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface IPageableBase {
  limit: number;
  offset: number;
  pages: number;
  total: number;
}
