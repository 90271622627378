import React, { memo } from 'react';
import PT from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { styled } from 'components';
import { IconButton } from 'components/shared';
import { MdStar, MdStarBorder } from 'components/icons';
import { getRoutes, getCurrencySymbol } from 'utils';
import { usePayPeriodsQuery, useCurrenciesQuery, useEmployeeJobCommands } from 'hooks';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.jobFitCardV2': {
    width: '100%',
    padding: 15,
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 12,
    display: 'block',
    textAlign: 'left',
    backgroundColor: theme.palette.background.default
  },
  '& .jobFitCardV2__header': {
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between'
  },
  '& .jobFitCardV2__headerActions': {
    flexShrink: 0,
    display: 'flex'
  },
  '& .jobFitCardV2__action': {
    width: 20,
    height: 20,
    padding: 0,
    color: theme.palette.quaternary.main
  },
  '& .jobFitCardV2__title': {
    fontSize: 18,
    fontWeight: 800,
    color: theme.palette.text.primary // @TODO: get from theme (grey 6)
  },
  '& .jobFitCardV2__salary': {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.secondary.main
  }
}));

const JobFitCardV2 = (props) => {
  const { job, ...rest } = props;
  const navigate = useNavigate();
  const {
    id,
    starred = false,
    title,
    payPeriodId,
    payCurrencyId,
    payMax = 0,
    payMin = 0
  } = job || {};

  const { getPayPeriodById } = usePayPeriodsQuery();
  const { getCurrencyById } = useCurrenciesQuery();
  const { starJob, starLoading } = useEmployeeJobCommands();

  const payPeriod = getPayPeriodById(payPeriodId)?.name;
  const currency = getCurrencyById(payCurrencyId)?.name;
  const currencySymbol = getCurrencySymbol(currency) || currency;
  const salary =
    payPeriod && payMin && payMax ? `${payPeriod} - ${currencySymbol}${payMin}-${payMax}` : '';

  const handleStar = (e) => {
    e.stopPropagation();
    starJob(job, !starred);
  };

  const navigateToJob = () => {
    navigate(getRoutes({ id }).employee.job);
  };

  return (
    <StyledRoot className="jobFitCardV2" onClick={navigateToJob} {...rest}>
      <div className="jobFitCardV2__header">
        <div className="jobFitCardV2__title">{title}</div>
        <div className="jobFitCardV2__headerActions">
          <IconButton disabled={starLoading} className="jobFitCardV2__action" onClick={handleStar}>
            {starred ? <MdStar color="inherit" /> : <MdStarBorder color="inherit" />}
          </IconButton>
        </div>
      </div>
      <div className="jobFitCardV2__salary">{salary}</div>
    </StyledRoot>
  );
}

JobFitCardV2.propTypes = {
  job: PT.shape({
    id: PT.number,
    title: PT.string
  }).isRequired
};

export default memo(JobFitCardV2);
