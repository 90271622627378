import React from 'react';
import map from 'lodash/map';
import { styled } from 'components';
import ResourceCard from './ResourceCard';
import { careerDevBlogs } from './employeeBlogsData';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.suggestedResources': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& .resourceCard__img': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const SuggestedResources = () => {
  const arr = careerDevBlogs.slice(0, 2);
  return (
    <StyledRoot className="suggestedResources">
      {map(arr, ({ title, link }) => (
        <ResourceCard
          key={`sugRes__${title}`}
          title={title}
          link={link}
        />
      ))}
    </StyledRoot>
  );
};

SuggestedResources.propTypes = {};

export default SuggestedResources;
