export default ({ theme }) => ({
  zIndex: 2000,
  // '-webkit-filter': 'blur(3px)',
  // filter: 'blur(3px)',
  alignItems: 'normal',
  justifyContent: 'normal',
  backdropFilter: 'blur(2px)',
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  '& .contentContainer': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw'
  },
  '& .deckContainer': {
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .text': {
    marginTop: 16,
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: '0.7',
    color: theme.palette.common.white,
    textShadow: '0 0 14px 0 rgba(255,255,255, 0.5)',
    '& div': {
      fontWeight: 'bold',
    }
  },
  '& .actions': {
    width: '100%',
    padding: '0 16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .actionTip': {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  '& .instructionsWrapper': {
    display: 'flex',
    '& .__borderRight': {
      borderRight: `1px solid ${theme.palette.grey['200']}`,
      paddingRight: 23
    }
  },
  '& .instruction': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 24,
    marginBottom: 24
  },
  '& .swipeIcon': {
    color: theme.palette.common.white
  }
});
