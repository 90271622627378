import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { qaAttr, getRoutes } from 'utils';
import { useNotifications } from 'hooks';
import { Snackbar, styled } from 'components';
import defaultImg from 'assets/img/job_default.png';
import { ButtonBase, IconButton } from '../Button';
import { MdClose, MdChevronRight } from '../../icons';

const ROUTES = getRoutes();

const TYPES = {
  CHAT: 'newMessage',
  TRAININGS: 'newTraining',
  ERROR: 'error'
};

const StyledSnack = styled(Snackbar)(({ theme }) => ({
  '&.snackBarContentWrapper': {
    position: 'absolute'
  },
  '& .snackBarTopRight': {
    top: 10,
    right: 10,
    [theme.breakpoints.down('desktopApp')]: {
      right: 0,
      left: 0,
      justifyContent: 'center'
    }
  },
  '& .snackBarContentContainer': {
    position: 'relative',
    minWidth: 398,
    maxWidth: 406,
    '&:hover .closeBtn': {
      display: 'flex'
    },
    '&.error .snackBarContent': {
      backgroundColor: theme.palette.error.main,
      color: '#FFF'
    },
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: 'auto',
      maxWidth: 400,
      width: '90%'
    }
  },
  '& .snackBarContent': {
    padding: 12,
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper
  },
  '& .image': {
    minWidth: 60,
    width: 60,
    height: 60,
    marginRight: 17,
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'grey'
  },
  '& .newMessageContent': {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  '& .newMessage__title': {
    marginBottom: 6,
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.palette.quaternary.main
  },
  '& .message': {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#FFF'
  },
  '& .closeBtn': {
    position: 'absolute',
    top: -8,
    right: -8,
    zIndex: 1,
    width: 'auto',
    height: 'auto',
    padding: 2,
    display: 'none',
    backgroundColor: theme.palette.tertiary.dark,
    border: '1px solid rgba(255,255,255,0.2)',
    // '&:hover': {
    //   backgroundColor: '#FFF'
    // },
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex'
    }
  },
  '& .decorIcon': {
    color: theme.palette.quaternary.main
  },
  '& .trainingContent': {
    width: '100%',
    padding: '26px 23px'
  },
  '& .trainingContent__title': {
    marginBottom: 15,
    fontWeight: 'bold',
    fontSize: 16,
    color: '#FFF'
  },
  '& .trainingContent__text': {
    fontWeight: 'bold',
    fontSize: 13,
    color: '#FFF'
  },
  '& .trainingContent__link': {
    color: '#FFF',
    textDecoration: 'underline'
  }
}));

const Notification = (props) => {
  const {
    children,
    image,
    message,
    notificationKey,
    onNotificationClose,
    onNotificationClick,
    sender,
    type,
    ...rest
  } = props;
  const { closeSingle } = useNotifications();
  const Content = onNotificationClick ? ButtonBase : 'div';

  function handleClose(e) {
    e.stopPropagation();
    closeSingle(notificationKey);
  }

  function handleNotificationClick() {
    if (onNotificationClick) {
      const { closeCurrent } = onNotificationClick() || {};
      if (closeCurrent) closeSingle(notificationKey);
    }
  }

  function renderContent() {
    if (type === TYPES.CHAT) {
      return (
        <div className="snackBarContentContainer">
          <IconButton
            className="closeBtn"
            onClick={onNotificationClose || handleClose}
            testID="notification-close-button"
          >
            <MdClose color="primary" />
          </IconButton>
          <Content
            className="snackBarContent newMessageContent"
            onClick={handleNotificationClick}
            {...qaAttr('chat-message-notification-button')}
          >
            <div style={{ backgroundImage: `url("${image || defaultImg}")` }} className="image" />
            <div style={{ flex: 1 }}>
              <div className="newMessage__title">{sender}</div>
              <div className="message">{message}</div>
            </div>
            <MdChevronRight className="decorIcon" />
          </Content>
        </div>
      );
    }

    if (type === TYPES.TRAININGS) {
      return (
        <div className="snackBarContentContainer">
          <IconButton
            className="closeBtn"
            onClick={onNotificationClose || handleClose}
            testID="notification-close-button"
          >
            <MdClose color="primary" />
          </IconButton>
          <Content className="snackBarContent trainingContent">
            <div className="trainingContent__title">You have new courses assigned to you.</div>
            <div className="trainingContent__text">
              Click{' '}
              <Link
                className="trainingContent__link"
                to={ROUTES.employee.grow}
                onClick={() => closeSingle(notificationKey)}
              >
                here
              </Link>{' '}
              to view them.
            </div>
          </Content>
        </div>
      );
    }

    if (type === TYPES.ERROR) {
      return (
        <div className="snackBarContentContainer error">
          <IconButton
            className="closeBtn"
            onClick={onNotificationClose || handleClose}
            testID="notification-close-button"
          >
            <MdClose color="primary" />
          </IconButton>
          <Content className="snackBarContent">
            <div className="message">{message}</div>
          </Content>
        </div>
      );
    }

    return null;
  }

  return (
    <StyledSnack
      ContentProps={{ classes: { root: 'snackBarContent' } }}
      classes={{
        root: 'snackBarContentWrapper',
        anchorOriginTopRight: 'snackBarTopRight'
      }}
      message={message}
      ClickAwayListenerProps={{
        touchEvent: false,
        mouseEvent: false
      }}
      {...rest}
    >
      {children || renderContent()}
    </StyledSnack>
  );
}

Notification.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  message: PropTypes.string,
  notificationKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onNotificationClose: PropTypes.func,
  onNotificationClick: PropTypes.func,
  sender: PropTypes.string,
  type: PropTypes.oneOf(['', TYPES.CHAT, TYPES.TRAININGS, TYPES.ERROR])
};

Notification.defaultProps = {
  children: null,
  image: '',
  message: '',
  notificationKey: null,
  onNotificationClose: undefined,
  onNotificationClick: undefined,
  sender: '',
  type: ''
};

export default Notification;
