import React, {
  useEffect,
} from 'react';
import {
  useNotificationsSubscribe,
  useMediaQueryMatches,
} from 'hooks';
import {
  Box,
  styled,
} from 'components';
import styles from 'styles/Dashboard/Header';
import ChatButton from './ChatButton';
import ProfileLink from './ProfileLink';
import LogoutButton from './LogoutButton';
import HeaderTemplate from './HeaderTemplate';
import FaqLink from './FaqLink';
import WorkTorchTIcon from '../icons/WorkTorchTIcon';
import { getRoutes } from '../../utils';
import { useLocation } from 'react-router-dom';

const colorMode = localStorage.getItem('colorMode');

const StyledHeader = styled(Box)(styles);

const EmployeeHeader = () => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { subscribe: subscribeToNotifications, isNewMessage } =
    useNotificationsSubscribe({
      role: 'employee',
    });
  const location = useLocation();
  const routes = getRoutes();

  useEffect(() => {
    subscribeToNotifications();
  }, []);

  const renderLogo = () => (
    <Box className={'worktorchIcon'}>
      <WorkTorchTIcon
        fill={colorMode === 'light' ? '#312E38' : '#fff'}
      />
    </Box>
  );

  const renderLogoutButton = () => (
    <LogoutButton key="header-logout" />
  );

  const renderChatButton = () => (
    <ChatButton
      key="header-chat"
      userRole="employee"
      isNewMessage={isNewMessage}
    />
  );

  const renderProfileButton = () => (
    <ProfileLink key="header-profile" userRole="employee" />
  );
  const renderFaqButton = () => (
    <FaqLink key="header-faq" userRole="employee" />
  );

  const left = isDesktop
    ? [renderLogo()]
    : [renderProfileButton()];
  const right = isDesktop
    ? [
        renderFaqButton(),
        renderChatButton(),
        renderProfileButton(),
      ]
    : [renderLogoutButton(), renderChatButton()];

  if (
    location.pathname === routes.employee.profile &&
    isDesktop
  ) {
    right.push(renderLogoutButton());
  }

  return (
    <StyledHeader>
      <HeaderTemplate
        leftElements={left}
        rightElements={right}
      />
    </StyledHeader>
  );
};

export default EmployeeHeader;
