import { Box, Switch } from 'components';
import {
  useForm,
  Controller,
  FieldValues,
} from 'react-hook-form';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';
import FlexRow from '../../shared/FlexRow';
import Button from '../../shared/Button';
import { IOnboardingResource } from '../../../api/types/queries/onboarding.types';

const ResourceForm = ({
  onboardingResource,
  handleSave,
  handleCancel,
  handleDelete,
}: {
  onboardingResource?: IOnboardingResource;
  handleSave: Required<(formValues: FieldValues) => void>;
  handleCancel: Required<() => void>;
  handleDelete?: (onboardingResourceId: string) => void;
}) => {
  const {
    setValue,
    handleSubmit,
    register,
    formState,
    control,
  } = useForm({
    defaultValues: {
      id: null,
      title: '',
      isPage: false,
      allPositions: false,
      type: '',
      description: '',
      url: '',
    },
  });
  const { errors } = formState;

  useEffect(() => {
    if (onboardingResource) {
      setValue('id', onboardingResource.id);
      setValue('title', onboardingResource.title);
      setValue('isPage', onboardingResource.isPage);
      setValue('allPositions', onboardingResource.allPositions);
      setValue('type', onboardingResource.type);
      setValue('description', onboardingResource.description);
      setValue('url', onboardingResource.url);
    }
  }, [onboardingResource]);

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSave)}>
        <Typography
          variant={'h2'}
          sx={{
            mb: (theme) => theme.spacing(2),
            mt: (theme) => theme.spacing(2),
          }}
        >
          {onboardingResource
            ? `edit ${onboardingResource.title}`
            : 'New Resource'}
        </Typography>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>Title:</Typography>
            <TextField
              {...register('title', {
                required: 'Title is required',
              })}
              error={errors.title != null}
              helperText={errors.title?.message}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>
              Make full Page?
            </Typography>
            <Controller
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
              name="isPage"
              control={control}
            />
          </FormLabel>
          <FormLabel>
            <Typography variant={'h3'}>
              For All Positions?
            </Typography>
            <Controller
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
              name="allPositions"
              control={control}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>Type:</Typography>
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select label="Type" {...field}>
                  <MenuItem value={'video'}>video</MenuItem>
                  <MenuItem value={'document'}>
                    document
                  </MenuItem>
                  <MenuItem value={'image'}>image</MenuItem>
                  <MenuItem value={'url'}>url</MenuItem>
                </Select>
              )}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>Description</Typography>
            <TextField
              multiline
              rows={1}
              maxRows={3}
              {...register('description')}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>Url:</Typography>
            <TextField {...register('url')} />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>
              Upload Resource:
            </Typography>
            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <input
                  type="file"
                  onChange={(e) => {
                    field.onChange(e.target.files);
                  }}
                />
              )}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <Button
            variant={'outlined-primary'}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button variant={'filled-primary'} type={'submit'}>
            Save
          </Button>
          {handleDelete && onboardingResource && (
            <Button
              variant={'filled-primary'}
              color={'error'}
              onClick={() => handleDelete(onboardingResource.id)}
            >
              Delete
            </Button>
          )}
        </FlexRow>
      </form>
    </Box>
  );
};

export default ResourceForm;
