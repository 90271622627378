import { gql } from '@apollo/client';

export const GET_SURVEY_QUESTIONS = gql`
  query GetSurveyQuestions($code: String) {
    getSurveyQuestions(code: $code) {
      id
      question
      surveyCampaignId
    }
  }
`;

export const GET_SURVEY_CAMPAIGN_BY_ID = gql`
  query surveyCampaignById($id: Int) {
    surveyCampaignById(id: $id) {
      name
      surveyCampaignQuestions {
        id
        question
      }
    }
  }
`;

export const SEND_SURVEY_NOTIFICATION = gql`
  query sendSurveyNotification($surveyId: Int) {
    sendSurveyNotification(surveyId: $surveyId) {
      messagesAttempted
      results {
        name
        profileId
        reason
      }
    }
  }
`;

export const GET_SURVEY_CAMPAIGNS = gql`
  query SurveyCampaigns($locationId: Int!) {
  surveyCampaigns(locationId: $locationId) {
    id
    createdAt
    updatedAt
    name
    locationId
    deletedAt
    surveyTypeId
  }
}
`;

export const GET_SURVEY_TYPES = gql`
  query surveyTypes {
    surveyTypes {
      id
      type
    }
  }
`;
