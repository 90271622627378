import { addAlpha } from 'styles/utils';

export default ({ theme }) => ({
  '& .innerContainer': {
    width: '100%',
    border: `1px solid ${theme.palette.common.white}`,
    position: 'relative',
    boxShadow: '0 10px 20px 0 rgb(0, 0, 0, 31%)',
    minHeight: 306,
    alignItems: 'initial',
    borderRadius: 16,
    flexDirection: 'column',
    justifyContent: 'initial',
    '&.withActions': {
      '&:hover, &:focus': {
        '& .motto': {
          // visibility: 'hidden',
        },
        '& .actionsContainer': {
          display: 'flex'
        }
      }
    }
  },
  '& .actionsContainer': {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '60px 18px 25px',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${addAlpha(
      theme.palette.background.default,
      0.9
    )} 30%, ${theme.palette.background.default} 50%)`,
    borderRadius: '0 0 16px 16px'
  },
  '& .dataCell': {
    padding: '9px 16px 9px 0',
    textAlign: 'left',
    '&:last-of-type': {
      marginLeft: 'auto',
      marginRight: 0,
      paddingRight: 0,
      padding: '8px 0'
    }
  },
  '& .dataCell__actions': {
    display: 'none'
  },
  // infoContainer: {
  //   width: '100%',
  //   display: 'flex',
  //   alignItems: 'center'
  // },
  '& .clickablePart': {
    width: '100%',
    flexDirection: 'column',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit'
  },
  '& .avatar': {
    width: '100%',
    height: 172,
    display: 'block',
    borderRadius: 'inherit',
    backgroundColor: theme.palette.background.default,
    '& > img': {
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      objectFit: 'cover'
    }
  },
  // textMain: {
  //   fontSize: 14,
  //   lineHeight: '17px',
  //   letterSpacing: 0,
  //   [theme.breakpoints.down('desktopApp')]: {
  //     fontWeight: 'bold',
  //     fontSize: 16,
  //     lineHeight: '18px'
  //   }
  // },
  // textSecondary: {
  //   fontSize: 14,
  //   lineHeight: '17px',
  //   letterSpacing: 0,
  //   color: theme.palette.grey['300'],
  //   [theme.breakpoints.down('desktopApp')]: {
  //     fontSize: 16,
  //     lineHeight: '18px'
  //   }
  // },
  '& .name': {
    marginBottom: 8,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0
  },
  '& .userInfoContainer': {
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    color: theme.palette.common.white
  },
  '& .motto': {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0
  },
  '& .mottoExcerpt': {
    maxWidth: 250,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
  // statusText: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   fontSize: 14,
  //   fontWeight: 'bold',
  //   lineHeight: '17px',
  //   color: theme.palette.common.white,
  //   '&$error': {
  //     color: theme.palette.error.main
  //   }
  // },
});
