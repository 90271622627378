import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { trimStr } from 'utils';
import { useAlerts, useMediaQueryMatches } from 'hooks';
import { styled, Box } from 'components';
import { useForm } from 'components/form';
import { Button, Input, Spinner } from 'components/shared';
import { MdExclamation } from 'components/icons';
import { POST_USER_NAME, getOnboardingStatistics } from 'api';
import styles from 'styles/Onboarding';
import PrivacyClauseButton from './PrivacyClauseButton';

const NAME = 'name';

const StyledBox = styled(Box)(styles);

const NameForm = ({ calledToasts, setCalledToasts, formKey, form, onChange, onGoToNextStep }) => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { showToast } = useAlerts();

  const [postUserName, { loading }] = useMutation(POST_USER_NAME);
  const { $, set, withValidation } = useForm({
    initial: { [NAME]: form[NAME] },
    validations: {
      [NAME]: 'presence'
    }
  });

  const showAnalyticToast = async () => {
    if (calledToasts.indexOf(formKey) === -1) {
      const info = (await getOnboardingStatistics(form.zip)) || {};
      if (info.numberOfHires) {
        setCalledToasts([...calledToasts, formKey]);
        showToast({
          title: `${info.numberOfHires} people landed a career they love in the last 30 days`,
          icon: () => <MdExclamation />,
          flourishText: '',
          position: { top: false, right: false }
        });
      }
    }
  };

  useEffect(() => {
    showAnalyticToast();
  }, []);

  const handleFieldChange = (field) => (e) => {
    set(field, e.target.value);
    onChange({ ...form, [field]: e.target.value });
  };

  const next = withValidation(async (attrs) => {
    onGoToNextStep(() => postUserName({ variables: { [NAME]: trimStr(attrs[NAME]) } }));
  });

  return (
    <StyledBox>
      <div className="pageContent">
        <Input
          id="name"
          {...$(NAME)}
          placeholder="Sandra Sun"
          autoComplete="off"
          required
          withHelperText
          highlightInputOnError={false}
          disabled={loading}
          className="input"
          FormControlProps={{
            sx: {
              mb: isDesktop ? '20px' : '16px',
              maxWidth: isDesktop ? 'auto' : 286
            }
          }}
          analyticParams={{
            key: 'Employee name focused (onboarding)',
            trigger: 'focus'
          }}
          onChange={handleFieldChange(NAME)}
          testID="onboarding-name-input"
        />
        <Button
          variant="filled-primary"
          endIcon={loading && <Spinner width={24} height={24} />}
          disabled={loading}
          className="nextBtn"
          sx={{ mb: isDesktop ? '25px' : '13px' }}
          onClick={next}
          testID="onboarding-next-button"
        >
          Next
        </Button>
        <PrivacyClauseButton />
      </div>
    </StyledBox>
  );
}

NameForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default NameForm;
