import { Box, Typography } from '@mui/material';
import { Button } from '../../../shared';

interface ISideBarResource {
  title: string;
  imageUrl: string;
  link: string;
}
export const SideBarResource = ({
  title,
  imageUrl,
  link,
}: ISideBarResource) => (
    <Box className={`resource-container`}>
      <Box className={`resource-content`}>
        <img src={imageUrl} alt="" />
        <Typography variant={'h3'} paragraph>
          {title}
        </Typography>
        <a href={link} target={'_blank'} rel="noreferrer">
          <Button variant="filled-secondary">Read</Button>
        </a>
      </Box>
    </Box>
  );
