import React from 'react';
import { SvgIcon } from 'components';

const AddConnectionIcon = (props) => (
    <SvgIcon viewBox="0 0 12.6 12" {...props}>
      <g>
        <path
          fill="currentColor"
          d="M2.7,12c-0.7,0-1.4,0-2.1,0C0,12,0,12,0,11.3c-0.1-1.9,0.3-2.5,2.1-3.2c1.2-0.5,2.4-0.7,3.5-0.7
		c1.3,0,2.6,0.3,3.9,0.9c1.5,0.6,2,1.9,1.6,3.4c-0.1,0.2-0.1,0.2-0.5,0.2c-0.8,0-3.7,0-3.7,0L2.7,12z M5.6,8.2c-1.3,0-2.7,0.3-4,1
		c-0.5,0.2-0.9,0.7-0.5,1.7c0.1,0.1,0.2,0.2,0.3,0.2l2.8,0c1.8,0,3.5,0,5.3,0c0.3,0,0.6-0.3,0.7-0.4c0.1-0.1,0.1-0.3,0.1-0.4
		c-0.1-0.4-0.3-0.8-0.6-1C8.3,8.6,7,8.2,5.6,8.2z"
        />
        <path
          fill="currentColor"
          d="M5.6,6.8C4.9,6.8,4.2,6.5,3.7,6C3.2,5.5,3,4.8,3,4.2c0-1.4,1.2-2.6,2.6-2.6c1.5,0,2.6,1.1,2.6,2.6
		C8.2,4.9,8,5.5,7.5,6C7,6.5,6.3,6.8,5.6,6.8L5.6,6.8z M5.5,2.4c-1,0-1.7,0.8-1.7,1.8c0,0.5,0.2,1,0.5,1.3C4.7,5.8,5.2,6,5.6,6
		c0.5,0,0.9-0.2,1.3-0.5c0.3-0.4,0.5-0.8,0.5-1.3C7.3,3.1,6.6,2.4,5.5,2.4C5.6,2.4,5.5,2.4,5.5,2.4z"
        />
        <g>
          <path
            fill="currentColor"
            d="M11,3.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V2H9.7C9.6,2,9.5,2,9.4,1.9
			C9.3,1.8,9.3,1.8,9.3,1.7s0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.9V0.4c0-0.1,0-0.2,0.1-0.3C10.8,0,10.9,0,11,0
			c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.9h0.9c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3
			C12.4,2,12.4,2,12.3,2h-0.9V3c0,0.1,0,0.2-0.1,0.3C11.2,3.3,11.1,3.3,11,3.3z"
          />
        </g>
      </g>
    </SvgIcon>
  )

export default AddConnectionIcon;
