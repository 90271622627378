import { useMutation, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { Box, FormHelperText, styled } from 'components';
import { useForm } from 'components/form';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { qaAttr, getRoutes } from 'utils';
import { CREATE_SURVEY_CAMPAIGN, GET_SURVEY_TYPES } from 'api';
import {
  ISurvey,
  ISurveyQuestion,
} from 'components/Retention/types';
import { MdAdd, MdDelete } from 'components/icons';
import { Button, Input, Select } from 'components/shared';
import styles from 'styles/Retention/Survey/NewSurvey';

const StyledBox = styled(Box)(styles);

const NewSurvey = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routes = getRoutes();

  const newMultipleChoiceQuestion: ISurveyQuestion = {
    question: '',
    questionType: 'MULTIPLE_CHOICE',
    answers: [{ answer: '' }, { answer: '' }],
  };

  const newTextQuestion: ISurveyQuestion = {
    question: '',
    questionType: 'TEXT',
    answers: [{ answer: '' }],
  };

  // const { survey, setSurvey } = useSurvey();
  const [survey, setSurvey] = useState<ISurvey>({
    name: '',
    recipients: { recipientIds: [] },
    questions: [],
  });
  const [surveyTypeId, setSurveyType] = useState('1');

  const [questionType] = useState<string>('TEXT');

  const [createSurveyError, setCreateSurveyError] = useState<
    string | undefined
  >();

  const { $, set, reset, validate } = useForm({
    initial: { name: '' },
    validations: {
      name: ['presence'],
      'surveyQuestions.*.question': ['presence'],
    },
  });
  const { data: surveyTypes, loading: typesLoading = false } =
    useQuery(GET_SURVEY_TYPES);
  useEffect(() => {
    if (surveyTypes && !typesLoading) {
      const sts = surveyTypes.surveyTypes;
      const stId = sts.find((st) => st.type === 'default');
      if (stId) {
        setSurveyType(stId.id);
      }
    }
  }, [surveyTypes]);

  const [createSurveyCampaignByLocationId] = useMutation(
    CREATE_SURVEY_CAMPAIGN,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  const addQuestion = () => {
    if (survey.questions.length < 5) {
      const questionIdx = survey.questions.length;
      // eslint-disable-next-line no-unused-expressions
      questionType === 'TEXT'
        ? survey.questions.push(newTextQuestion)
        : survey.questions.push(newMultipleChoiceQuestion);
      setSurvey({ ...survey });
      set(`surveyQuestions.${questionIdx}.question`, '');
    }
  };

  const removeQuestion = (questionIdx) => {
    survey.questions.splice(questionIdx, 1);
    setSurvey({ ...survey });
    reset(
      survey.questions.map((q, idx) => ({
        [`surveyQuestions.${idx}.question`]: q.question,
      })),
    );
  };

  const addAnswer = (questionIdx) => {
    survey.questions[questionIdx].answers.push({
      answer: '',
    });
    setSurvey({ ...survey });
  };

  const removeAnswer = (questionIdx, answerIdx) => {
    if (survey.questions[questionIdx].answers.length > 2) {
      survey.questions[questionIdx].answers.splice(answerIdx, 1);
      setSurvey({ ...survey });
    }
  };

  const updateTitle = (e) => {
    survey.name = e.target.value;
    setSurvey({ ...survey });
    set('name', e.target.value);
  };

  const updateQuestion = (questionIdx) => (e) => {
    survey.questions[questionIdx].question = e.target.value;
    setSurvey({ ...survey });
    set(
      `surveyQuestions.${questionIdx}.question`,
      e.target.value,
    );
  };

  const createSurveyCampaign = async () => {
    validate().then(async () => {
      const { data, errors } =
        await createSurveyCampaignByLocationId({
          variables: {
            locationId: parseInt(location.state.locationId),
            name: survey.name,
            surveyTypeId: parseInt(surveyTypeId),
            questions: survey.questions.map((question) => ({
              question: question.question,
            })),
          },
        });
      survey.id = data.createSurveyCampaignByLocationId.id;
      setSurvey({ ...survey });
      if (errors) {
        setCreateSurveyError(errors[0].message);
      }
    });
  };

  const updateAnswers = (questionIdx, answerIdx) => (e) => {
    survey.questions[questionIdx].answers[answerIdx] =
      e.target.value;
    setSurvey({ ...survey });
  };

  const renderAddAnswer = (questionIdx) =>
    survey.questions[questionIdx].answers.length < 5 &&
    survey.questions[questionIdx].questionType ===
      'MULTIPLE_CHOICE' && (
      <Box className="addAnswer">
        <Button
          startIcon={<MdAdd />}
          onClick={() => addAnswer(questionIdx)}
        >
          <Typography>add answer</Typography>
        </Button>
      </Box>
    );

  const renderAnswerDelete = (questionIdx, answerIdx) =>
    survey.questions[questionIdx].answers.length > 2 && (
      <Button
        onClick={() => removeAnswer(questionIdx, answerIdx)}
        className={'delete-answer-button'}
      >
        <MdDelete
          sx={{
            marginTop: '16px',
          }}
        />
      </Button>
    );

  const renderAnswerInput = (questionIdx, answerIdx) => {
    const label: string = `Answer ${answerIdx + 1}`;
    const value: string =
      survey.questions[questionIdx].answers[answerIdx].answer;
    const questionTypeLocal =
      survey.questions[questionIdx].questionType;

    if (questionTypeLocal === 'MULTIPLE_CHOICE') {
      return (
        <Box className="answer">
          <Input
            className={'question-input'}
            label={label}
            value={value}
            placeholder="Write your answer here"
            onChange={updateAnswers(questionIdx, answerIdx)}
          />
          {renderAnswerDelete(questionIdx, answerIdx)}
        </Box>
      );
    }
    if (questionTypeLocal === 'TEXT') {
      return (
        <Box className="answer">
          <Typography>Written Response</Typography>
        </Box>
      );
    }
    return null;
  };

  const renderQuestionContainer = (questionIdx) => {
    const id: string = `surveyQuestions.${questionIdx}.question`;
    const value: string = survey.questions[questionIdx].question;
    return (
      <Box
        key={`${questionIdx}-${id}`}
        className="question-item"
      >
        <Box className="question">
          <Box className="label-row">
            <Typography className="label">
              Question {questionIdx + 1}
            </Typography>{' '}
            <Button
              className={'delete-question-button'}
              onClick={() => removeQuestion(questionIdx)}
            >
              {survey.questions.length > 1 && <MdDelete />}
            </Button>
          </Box>
          <Input
            {...$(`surveyQuestions.${questionIdx}.question`)}
            className={'question-input'}
            inputClassName={'question-input'}
            value={value}
            placeholder="Write your question here"
            onChange={updateQuestion(questionIdx)}
            highlightInputOnError
          />
        </Box>
        {survey.questions[questionIdx].answers.map((a, i) =>
          renderAnswerInput(questionIdx, i),
        )}
        {renderAddAnswer(questionIdx)}
      </Box>
    );
  };

  return (
    <StyledBox>
      <Box className="header">
        <h1>Create new survey</h1>
      </Box>
      <Box className={'navigation-container'}>
        <Box className="navigation">
          <Button
            variant="outlined-secondary"
            onClick={() => {
              navigate(
                `${routes.employer.retention_portal.interactions.survey}`,
                {
                  replace: true,
                  state: {
                    locationId: parseInt(
                      location.state.locationId,
                    ),
                  },
                },
              );
            }}
          >
            <Typography>Cancel</Typography>
          </Button>
          {!survey.id && (
            <Button
              variant="filled-primary"
              onClick={createSurveyCampaign}
            >
              <Typography>Save</Typography>
            </Button>
          )}

          {survey.id && (
            <Button
              variant="filled-primary"
              onClick={() =>
                navigate(
                  `${routes.employer.retention_portal.interactions.send_survey}/${survey.id}`,
                  {
                    replace: true,
                    state: {
                      locationId: parseInt(
                        location.state.locationId,
                      ),
                    },
                  },
                )
              }
            >
              <Typography>Send Survey</Typography>
            </Button>
          )}
        </Box>
      </Box>
      <Box className="new-survey-form">
        {createSurveyError && (
          <FormHelperText error>
            {createSurveyError}
          </FormHelperText>
        )}
        <form>
          <Typography variant={'h4'}>Survey Title</Typography>
          <Input
            {...$('name', updateTitle)}
            className={'question-input'}
            id="name"
            placeholder="Title"
            value={survey.name}
            highlightInputOnError
            required
          />
          {!isEmpty(survey.questions) && (
            <Box className={'question-container'}>
              {survey.questions.map((q, questionIdx) =>
                renderQuestionContainer(questionIdx),
              )}
            </Box>
          )}
          <Box className="survey-types">
            <Select
              id="surveyTypeId"
              name="surveyTypeId"
              value={surveyTypeId}
              label="Survey Type"
              inputVariant="outlined"
              onChange={(e) => setSurveyType(e.target.value)}
              options={map(surveyTypes?.surveyTypes, (o, i) => ({
                value: o.id,
                label: o.type,
                ...qaAttr(`survey-type-${i}`),
              }))}
              required
              withEmptyOption={false}
              containerProps={{
                sx: {
                  maxWidth: 200,
                  width: '100%',
                  marginTop: 2,
                },
              }}
            />
          </Box>
          <Box className="question-options">
            {/*
             TODO add this back in when backend supports MC
            <Select
              value={questionType}
              inputVariant="outlined"
              onChange={(e) => setQuestionType(e.target.value)}
              options={[
                {
                  value: 0,
                  label: 'Select Question Type',
                },
                {
                  value: 1,
                  label: 'Multiple Choice',
                },
                {
                  value: 2,
                  label: 'Text',
                },
              ]}
            />*/}

            {survey.questions.length < 5 ? (
              <Button
                variant="filled-primary"
                startIcon={<MdAdd />}
                onClick={() => addQuestion()}
              >
                Create Question
              </Button>
            ) : (
              <Typography>
                Max 5 question limit reached
              </Typography>
            )}
          </Box>
        </form>
      </Box>
    </StyledBox>
  );
};

export default NewSurvey;
