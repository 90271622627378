import { Typography } from '@mui/material';
import { Box, styled } from 'components';
import growApi from 'components/Grow/api';
import {
  MdAddChart,
  MdArrowBack,
  MdPersonPin,
  MdSettings,
} from 'components/icons';
import { IconButton, Spinner } from 'components/shared';
import InputAlertInput from 'components/shared/InputAlert/InputAlertInput';
import { useAlerts } from 'hooks';
import { get, isEmpty } from 'lodash';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { getProfileId, qaAttr } from 'utils';
import EmployerGrowContext from '../../EmployerGrowContext';
import { makeMutation, makeQueryFetch } from '../../api/util';
import TeamChartColumns from './ChartColumns';
import ChartTitle from './ChartTitle';
import ChartToolbar from './ChartToolbar';

const StyledRoot = styled('div')(({ theme }) => ({
  '& .teamChart__header': {
    padding: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .teamChart__header-title': {
    fontSize: 16,
    borderBottom: `5px solid ${theme.palette.text.primary}`,
    textAlign: 'center',
    padding: 15,
    borderRadius: 5,
    border: `3px solid ${theme.palette.primary.light}`,
  },
  '& .teamChart__header-btn-container': {
    width: 75,
    padding: '0 8px',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .teamChart__namesContainer': {
    display: 'grid',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'inset 0 -3px 4px rgb(0 0 0 / 5%)',
    gridTemplateColumns: 'auto 110px',
    gridTemplateRows: 'auto',
  },
  '& .teamChart__namesInnerContainer': {
    overflow: 'scroll',
    display: 'flex',
  },
  '& .teamChart__chartsNavigator': {
    display: 'flex',
    background: theme.palette.background.paper,
  },
  '& .teamChart__columnsContainer': {
    minHeight: 300,
    maxHeight: 600,
    overflow: 'auto',
    paddingBottom: 10,
    // borderBottom: '4px solid #e4e4e4'
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.common.white,
    },
  },
  '& .teamChart__columns': {
    position: 'relative',
    minHeight: 'inherit',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 240px)',
    gridColumnGap: 10,
    gridAutoFlow: 'column',
  },
}));

const StyledWelcomeRoot = styled('div')(({ theme }) => ({
  '&.chartWelcome': {
    alignSelf: 'center',
    width: '100%',
    textAlign: 'center',
  },
  '& .chartWelcome__title': {
    color: theme.palette.common.white,
    marginBottom: 10,
  },
  '& .chartWelcome__icon': {
    color: theme.palette.common.white,
    marginBottom: 10,
  },
}));

// eslint-disable-next-line react/prop-types
const WelcomeMessaging = ({ employeesCount }) => (
  <StyledWelcomeRoot className="chartWelcome">
    <h1 className="chartWelcome__title">Welcome!</h1>
    <Typography>
      This is the Team Structure Tool for Grow By WorkTorch.
    </Typography>
    {employeesCount > 0 ? (
      <p>
        To get started, click the new chart icon{' '}
        <span className="chartWelcome__icon">
          <MdAddChart style={{ fill: '#4743A2' }} />
        </span>{' '}
        on the right toolbar
      </p>
    ) : (
      <p style={{ maxWidth: 500, margin: '0 auto' }}>
        To get started, click the invite icon{' '}
        <span className="chartWelcome__icon">
          <MdPersonPin style={{ fill: '#4743A2' }} />
        </span>{' '}
        on the right toolbar to invite your employees and get
        structuring!
      </p>
    )}
  </StyledWelcomeRoot>
);

const TeamChart = () => {
  const { setSimpleAlert, setInputAlert } = useAlerts();
  const employerCtx = useContext(EmployerGrowContext);
  const {
    currentChartIdx,
    employerCharts,
    updateEmployerCtx,
    isEditable,
    employerCode,
    employees,
    showSettings,
  } = employerCtx;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const chartNamesRef = useRef(null);
  const chartColumnsRef = useRef(null);

  const setErrorModal = (subtitle) => {
    setSimpleAlert({
      isOpen: true,
      title: 'Grow',
      subtitle,
    });
  };

  const setCurrentChartIdx = (idx) => {
    employerCtx.updateEmployerCtx({
      currentChartIdx: idx,
    });
  };

  const fetchEmployerChartData = async () => {
    setIsDataLoading(true);

    const employerCodeFetch = await makeQueryFetch({
      query: growApi.query.EMPLOYER_CODE_BY_EMPLOYER,
      path: 'employerCodeByEmployer',
    }).then((data) => data.code);
    const data = await makeQueryFetch({
      query: growApi.query.EMPLOYER_STRUCTURE_CHART_BY_ID,
      path: 'employerStructureChartById',
    });
    if (data)
      updateEmployerCtx({ employerCharts: data, employerCode });
    else {
      updateEmployerCtx({ employerCode: employerCodeFetch });
      setErrorModal(
        'To get started create a chart and add your team members',
      );
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    fetchEmployerChartData();
  }, []);

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    const {
      id: destinationColumnId,
      index: destinationColumnIndex,
    } = JSON.parse(destination.droppableId);
    const { id: sourceColumnId, index: sourceColumnIndex } =
      JSON.parse(source.droppableId);
    const newLayout = [...employerCharts];

    const droppableEmp = get(
      newLayout,
      `[${currentChartIdx}].columns[${sourceColumnIndex}].employeeProfiles[${result.source.index}]`,
      null,
    );
    if (!droppableEmp) {
      setErrorModal(
        `There was an error with the employee you just dropped, please try again`,
      );
      return;
    }

    // remove card from source column and store it
    const employeeCard = newLayout[currentChartIdx].columns[
      sourceColumnIndex
    ].employeeProfiles.splice(result.source.index, 1)[0];

    // add to new index at destination column id
    newLayout[currentChartIdx].columns[
      destinationColumnIndex
    ].employeeProfiles.splice(
      result.destination.index,
      0,
      employeeCard,
    );

    const saveCardPlacement = async (colIdx, colId) => {
      const cards = newLayout[currentChartIdx].columns[
        colIdx
      ].employeeProfiles.map(({ id }, index) => ({
        id,
        index,
        columnId: colId,
      }));
      const cardResp = await makeMutation({
        mutation: growApi.mutation.PUT_EMPLOYEE_CARDS_TO_COLUMN,
        variables: { cards },
        path: 'putEmployeeCardsToColumn',
      });

      if (!cardResp.success) {
        setErrorModal(
          'There was an error saving the card you placed, if this persists, please contact support',
        );
      }
    };

    // save chart cards by column
    if (sourceColumnIndex !== destinationColumnIndex) {
      // Not the same column, save destination column as well
      await saveCardPlacement(
        destinationColumnIndex,
        destinationColumnId,
      );
    }

    await saveCardPlacement(sourceColumnIndex, sourceColumnId);

    // setChangesMade(true);
    updateEmployerCtx({ employerCharts: newLayout });
  };

  const handleChartNameChange = (id) => (inputValue) => {
    employerCtx.updateEmployerCtx({
      chartsToUpdate: {
        ...(employerCtx.chartsToUpdate || {}),
        [id]: inputValue,
      },
    });
  };

  const deleteChart = () => {
    const doDeleteChart = async () => {
      try {
        const deleteResp = await makeMutation({
          mutation: growApi.mutation.DELETE_EMPLOYER_CHART_BY_ID,
          variables: {
            chartId: employerCharts[currentChartIdx].id,
          },
          path: 'deleteEmployerChartById',
        });
        if (!deleteResp.success) {
          throw new Error(
            'Deleting the chart has failed. If the problem persists, please contact support',
          );
        } else {
          const newLayout = [...employerCharts];
          newLayout.splice(currentChartIdx, 1);
          updateEmployerCtx({
            employerCharts: newLayout,
            currentChartIdx: 0,
          });
        }
      } catch (err) {
        console.error(err);
        console.error(err);
        setErrorModal(
          'Deleting the chart has failed. If the problem persists, please contact support',
        );
      }
    };

    const alertMessage =
      employerCharts.length === 1
        ? 'Looks like this is the last chart in your team structure setup, are you sure you want to delete it?'
        : `Are you sure you want to delete the "${employerCharts[currentChartIdx].name}" chart?`;

    setSimpleAlert({
      isOpen: true,
      title: 'Delete Chart?',
      subtitle: alertMessage,
      onSuccess: doDeleteChart,
    });
  };

  const copyChart = async () => {
    try {
      const newLayout = [...employerCharts];
      const newChart = await makeMutation({
        mutation: growApi.mutation.COPY_CHART,
        variables: {
          chartId: employerCharts[currentChartIdx].id,
        },
        path: 'copyChart',
      });
      if (newChart) {
        updateEmployerCtx({
          employerCharts: [...newLayout, newChart],
          currentChartIdx: newLayout.length - 1,
        });
      } else {
        throw new Error('There was an error creating the chart');
      }
    } catch (err) {
      console.error(err);
      setErrorModal(
        'There was an error copying the chart, if this persists, please contact support',
      );
    }
  };

  const createChart = () => {
    const newLayout = [...employerCharts];
    let newChartName = '';

    const doCreate = async () => {
      // create new chart
      const newChart = await makeMutation({
        mutation: growApi.mutation.CREATE_CHART,
        variables: {
          name: newChartName,
        },
        path: 'createChart',
      });

      if (newChart) {
        newLayout.push(newChart);
        updateEmployerCtx({
          employerCharts: newLayout,
          currentChartIdx: newLayout.length - 1,
        });
      } else {
        setErrorModal(
          'There was an error creating the chart, if this persists, please contact support',
        );
      }
    };

    const handleNewChartNameOnBlur = (chartInput) => {
      newChartName = chartInput;
    };

    setInputAlert({
      isOpen: true,
      title: 'Create Chart',
      subtitle: '',
      onSuccess: doCreate,
      inputs: (
        <InputAlertInput
          label="New Chart Name"
          handleOnBlur={handleNewChartNameOnBlur}
        />
      ),
    });
  };

  const editChart = () =>
    employerCtx.updateEmployerCtx({ isEditable: true });

  const saveChartAndColumns = async () => {
    const { chartsToUpdate, columnsToUpdate } = employerCtx;
    // update the columns
    if (columnsToUpdate) {
      const formattedColumns = Object.keys(columnsToUpdate).map(
        (key) => ({
          chartId: employerCharts[currentChartIdx].id,
          name: columnsToUpdate[key].name,
          index: columnsToUpdate[key].index,
          id: columnsToUpdate[key].id,
        }),
      );
      const columnUpdateResp = await makeMutation({
        mutation: growApi.mutation.UPDATE_CHART_COLUMNS_BY_INDEX,
        variables: {
          columns: formattedColumns,
        },
        path: 'updateChartColumnsByIndex',
      });
      if (!columnUpdateResp.success) {
        setErrorModal(
          'There was an error saving the changes, if this persists, please contact support',
        );
      }
    }
    // update the charts
    if (chartsToUpdate) {
      const formattedCharts = Object.keys(chartsToUpdate).map(
        (key) => ({
          name: chartsToUpdate[key],
          id: Number(key),
        }),
      );
      const chartUpdateResp = await makeMutation({
        mutation: growApi.mutation.UPDATE_EMPLOYER_CHARTS_BY_ID,
        variables: {
          charts: formattedCharts,
        },
        path: 'updateEmployerChartsById',
      });
      if (!chartUpdateResp.success) {
        setErrorModal(
          'There was an error updating your chart, if this persists, please contact support',
        );
      }
    }
    // remove values from context on update
    updateEmployerCtx({
      chartsToUpdate: {},
      columnsToUpdate: {},
      isEditable: false,
    });
  };

  const createColumn = async () => {
    try {
      const newLayout = [...employerCharts];
      const createColumnResp = await makeMutation({
        mutation: growApi.mutation.CREATE_CHART_COLUMN_BY_INDEX,
        variables: {
          index: newLayout[currentChartIdx].columns.length,
          name: 'Untitled',
          chartId: newLayout[currentChartIdx].id,
        },
        path: 'createChartColumnByIndex',
      });
      newLayout[currentChartIdx].columns.push({
        ...createColumnResp,
        employeeProfiles: [],
      });
      updateEmployerCtx({
        employerCharts: newLayout,
        isEditable: true,
      });

      chartColumnsRef.current.scrollTo({
        left:
          newLayout[currentChartIdx].columns.length * 240 + 240,
        behavior: 'smooth',
      });
    } catch (err) {
      console.error(err);
      setErrorModal(
        'There was an error creating a column, if this persists, please contact support',
      );
    }
  };

  const deleteColumn = (idx, chartIdx, charts) => () => {
    const newLayout = [...charts];
    const doDeleteColumn = async () => {
      if (newLayout[chartIdx].columns.length === 1) {
        // last column in the chart
        setTimeout(() => {
          setSimpleAlert({
            isOpen: true,
            title: 'Wait a Second',
            subtitle: `It looks like you're trying to delete the last column of the chart. If you do not want this chart any longer, please delete the chart with the trash icon on the toolbar.`,
          });
        }, 100);
        return;
      }
      try {
        const updatedChart = await makeMutation({
          mutation:
            growApi.mutation.DELETE_CHART_COLUMN_BY_INDEX,
          variables: {
            chartId: newLayout[chartIdx].id,
            columnIndex: idx,
          },
          path: 'deleteEmployerChartColumnByIndex',
        });
        if (updatedChart) {
          newLayout[chartIdx] = updatedChart;
          updateEmployerCtx({
            employerCharts: newLayout,
            currentChartIdx: chartIdx,
          });
        } else {
          throw new Error('Error deleting charts');
        }
      } catch (err) {
        console.error(err);
        setErrorModal(
          'There was an error deleting your column, if this persists, please contact support',
        );
      }
    };

    setSimpleAlert({
      isOpen: true,
      title: 'Delete Column?',
      subtitle: `Are you sure you want to delete this column? Any employees under it will be automatically moved to the first column.`,
      onSuccess: doDeleteColumn,
    });
  };

  const deleteEmployee = (employeeProfileId) => {
    const doDeleteEmployee = async (
      employeeProfileProfileId,
    ) => {
      const employerProfileId = getProfileId();

      if (employerProfileId && employeeProfileProfileId) {
        await makeMutation({
          mutation:
            growApi.mutation.DELETE_EMPLOYEE_FROM_EMPLOYER_GROW,
          variables: {
            employeeProfileProfileId: parseInt(
              employeeProfileProfileId,
            ),
            employerProfileProfileId: parseInt(
              employerProfileId,
            ),
          },
          path: 'removeEmployee',
        });
        fetchEmployerChartData();
      }
    };

    setSimpleAlert({
      isOpen: true,
      title: 'Delete employee?',
      subtitle:
        'Are you sure you want to remove this employee? It will remove them from your company entirely',
      onSuccess: () => doDeleteEmployee(employeeProfileId),
      onCancel: () => setSimpleAlert({ isOpen: false }),
    });
  };

  useEffect(() => {
    if (!isEmpty(employerCharts))
      employerCtx.updateEmployerCtx({
        isEditable: false,
        deleteColumn,
        deleteEmployee,
      });
  }, [employerCharts]);

  const onNavRight = () => {
    setCurrentChartIdx(currentChartIdx + 1);
    chartNamesRef.current.scrollTo({
      left: chartNamesRef.current.scrollLeft + 240,
      behavior: 'smooth',
    });
  };

  const onNavLeft = () => {
    setCurrentChartIdx(currentChartIdx - 1);
    chartNamesRef.current.scrollTo({
      left: chartNamesRef.current.scrollLeft - 240,
      behavior: 'smooth',
    });
  };

  const commonTooltipProps = {
    placement: 'top',
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#FFF',
        boxShadow: '0 0 4px rgba(0, 0, 0, .25)',
      },
    },
  };

  return (
    <StyledRoot>
      <div className="teamChart__header">
        <h1
          className="teamChart__header-title"
          {...qaAttr('team-structure-title')}
        >
          Employer Code: {employerCode}
        </h1>
        <div className="teamChart__header-btn-container">
          <IconButton color="primary" onClick={showSettings}>
            <MdSettings />
          </IconButton>
        </div>
      </div>
      <Box position="relative">
        {isDataLoading && (
          <div className="chartLoaderContainer">
            <Spinner size={48} />
          </div>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box component="h4" fontSize={16}>
            Team Structure
          </Box>
          <ChartToolbar
            deleteChart={deleteChart}
            copyChart={copyChart}
            createChart={createChart}
            editChart={editChart}
            createColumn={createColumn}
            saveChartAndColumns={saveChartAndColumns}
            fetchEmployerChartData={fetchEmployerChartData}
          />
        </Box>

        <div className="teamChart__namesContainer">
          <div
            className="teamChart__namesInnerContainer"
            ref={chartNamesRef}
          >
            {employerCharts.map((chart, idx) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                style={{
                  backgroundColor:
                    currentChartIdx === idx ? 'rgb(144, 144, 144, 0.38)' : '',
                }}
                onClick={
                  isEditable
                    ? null
                    : () => setCurrentChartIdx(idx)
                }
                key={chart.name}
                {...qaAttr(
                  `team-structure-chart-button-${chart.name}`,
                )}
              >
                <ChartTitle
                  sx={{
                    '& .StyledInput-inputBase__input': {
                      padding: '.75rem',
                    },
                  }}
                  FormControlProps={{
                    sx: {
                      minWidth: 240,
                      borderLeft: '1px solid #e4e4e4',
                    },
                  }}
                  onClick={
                    isEditable
                      ? null
                      : () => setCurrentChartIdx(idx)
                  }
                  titleValue={chart.name}
                  handleOnBlur={handleChartNameChange(chart.id)}
                  testID={`team-structure-chart-input-${chart.name}`}
                />
              </div>
            ))}
          </div>
          <div className="teamChart__chartsNavigator">
            <IconButton
              disabled={
                currentChartIdx === 0 ||
                employerCharts.length === 0
              }
              variant={'outlined-primary'}
              onClick={onNavLeft}
              withTooltip
              toolTipProps={{
                title: 'Previous Chart',
                ...commonTooltipProps,
              }}
              testID="team-structure-prev-chart-button"
            >
              <MdArrowBack />
            </IconButton>
            <IconButton
              disabled={
                currentChartIdx === employerCharts.length - 1 ||
                employerCharts.length === 0
              }
              variant={'outlined-primary'}
              onClick={onNavRight}
              withTooltip
              toolTipProps={{
                title: 'Next Chart',
                ...commonTooltipProps,
              }}
              sx={{
                transform: 'rotate(180deg)',
              }}
              testID="team-structure-prev-next-button"
            >
              <MdArrowBack />
            </IconButton>
          </div>
        </div>

        {employerCharts.length === 0 ? (
          <Box
            height={300}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <WelcomeMessaging
              employeesCount={employees.length}
            />
          </Box>
        ) : (
          <div className="teamChart__columnsContainer">
            <div
              className="teamChart__columns"
              ref={chartColumnsRef}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <TeamChartColumns
                  columns={get(
                    employerCharts,
                    `[${currentChartIdx}].columns`,
                    [],
                  )}
                  chartId={get(
                    employerCharts,
                    '[currentChartIdx].id',
                    null,
                  )}
                />
              </DragDropContext>
            </div>
          </div>
        )}
      </Box>
    </StyledRoot>
  );
};

export default TeamChart;
