import { Box, Switch } from 'components';
import {
  useForm,
  Controller,
  FieldValues,
} from 'react-hook-form';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import FlexRow from '../../shared/FlexRow';
import Button from '../../shared/Button';
import { IOnboardingDocument } from '../../../api/types/queries/onboarding.types';

const DocumentForm = ({
  onboardingDocument,
  handleSave,
  handleCancel,
  handleDelete,
}: {
  onboardingDocument?: IOnboardingDocument;
  handleSave: Required<(formValues: FieldValues) => void>;
  handleCancel: Required<() => void>;
  handleDelete?: (onboardingResourceId: string) => void;
}) => {
  const {
    setValue,
    handleSubmit,
    register,
    reset,
    formState,
    control,
  } = useForm({
    defaultValues: {
      id: null,
      name: '',
      allPositions: false,
      numberOfSignatures: 0,
      numberOfInitials: 0,
      numberOfDates: 0,
    },
  });
  const { errors } = formState;

  useEffect(() => {
    if (onboardingDocument) {
      setValue('id', onboardingDocument.id);
      setValue('name', onboardingDocument.name);
      setValue('allPositions', onboardingDocument.allPositions);
      setValue(
        'numberOfSignatures',
        onboardingDocument.numberOfSignatures,
      );
      setValue(
        'numberOfInitials',
        onboardingDocument.numberOfInitials,
      );
      setValue(
        'numberOfDates',
        onboardingDocument.numberOfDates,
      );
    }
  }, [onboardingDocument]);

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSave)}>
        <Typography
          variant={'h2'}
          sx={{
            mb: (theme) => theme.spacing(2),
            mt: (theme) => theme.spacing(2),
          }}
        >
          {onboardingDocument
            ? `edit ${onboardingDocument.name}`
            : 'New Resource'}
        </Typography>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>Name:</Typography>
            <TextField
              {...register('name', {
                required: 'Name is required',
              })}
              error={errors.name != null}
              helperText={errors.name?.message}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>
              For All Positions?
            </Typography>
            <Controller
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
              name="allPositions"
              control={control}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>
              Number of required signatures
            </Typography>
            <TextField
              disabled={onboardingDocument != null}
              {...register('numberOfSignatures', {
                valueAsNumber: true,
                validate: (val) =>
                  isNaN(val) ? 'Value must be a number' : null,
              })}
              error={errors.numberOfSignatures != null}
              helperText={errors.numberOfSignatures?.message}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>
              Number of required initials
            </Typography>
            <TextField
              disabled={onboardingDocument != null}
              {...register('numberOfInitials', {
                valueAsNumber: true,
                validate: (val) =>
                  isNaN(val) ? 'Value must be a number' : null,
              })}
              error={errors.numberOfInitials != null}
              helperText={errors.numberOfInitials?.message}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <FormLabel>
            <Typography variant={'h3'}>
              Number of required dates
            </Typography>
            <TextField
              disabled={onboardingDocument != null}
              {...register('numberOfDates', {
                valueAsNumber: true,
                validate: (val) =>
                  isNaN(val) ? 'Value must be a number' : null,
              })}
              error={errors.numberOfDates != null}
              helperText={errors.numberOfDates?.message}
            />
          </FormLabel>
        </FlexRow>
        <FlexRow>
          <Button
            variant={'outlined-primary'}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button variant={'filled-primary'} type={'submit'}>
            Save
          </Button>
          {handleDelete && onboardingDocument && (
            <Button
              variant={'filled-primary'}
              color={'error'}
              onClick={() => handleDelete(onboardingDocument.id)}
            >
              Delete
            </Button>
          )}
        </FlexRow>
      </form>
    </Box>
  );
};

export default DocumentForm;
