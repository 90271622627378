import { Box } from 'components';
import Typography from '@mui/material/Typography';
import React from 'react';
import Chip from '@mui/material/Chip';
import FlexRow from '../../../shared/FlexRow';
import { SpringModal } from '../../../shared';
import { IndustriesForm } from '../../../dialogs/components';

const RenderIndustries = ({ industries, register }) => (
  <Box className={'sectionContainer chipContainer'}>
    <Typography variant={'h2'}>Industries</Typography>
    <FlexRow>
      {industries.fields.length === 0 && (
        <Typography variant={'body1'}>No Industries</Typography>
      )}
      {industries.fields.map((field, index) => (
        <Chip
          {...register(`industry.${index}.value`)}
          key={field.id}
          label={field.name}
          variant={'filled'}
          color={'primary'}
          onDelete={() => industries.remove(index)}
        />
      ))}
    </FlexRow>
    {/*Fake on success to get the button to show and close modal.*/}
    <SpringModal
      buttonText={'Add Industries'}
      onSuccess={() => null}
      buttonProps={{
        size: 'small',
        variant: 'outlined',
        color: 'secondary',
      }}
    >
      <IndustriesForm
        form={{ industry: industries.fields }}
        fieldName={'industry'}
        withSubmitBtn={false}
        withSkipBtn={false}
        onChange={({ industry }) =>
          industries.replace(
            industry.map((i) => ({
              ...i,
              // have to reset id because useForm is going
              // to use the id
              industryId: i.id,
            })),
          )
        }
      />
    </SpringModal>
  </Box>
);

export default RenderIndustries;
