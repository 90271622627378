import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Pagination,
  styled,
  Typography,
} from '@mui/material';
import {
  EMPLOYEE_DETAIL_BY_LOCATION,
  POST_EMPLOYEE_TO_LOCATION_BY_EMAIL,
  POST_EMPLOYEE_TO_LOCATION_BY_CSV,
} from 'api';
import EmployeeCard from 'components/Retention/components/Location/EmployeeCard';
import { RetentionContext } from 'components/Retention/utils';
import { Input, Spinner } from 'components/shared';
import { workforceEntryStyles } from 'styles/Retention/Location/workforce';
import isEmpty from 'lodash/isEmpty';
import {
  MdAttachFile,
  MdEmail,
  PersonAddIcon,
} from 'components/icons';
import get from 'lodash/get';
import EmployerDetailContainer from '../../../../components/Retention/components/Location/LocationDashboardRenderer';
import { useAlerts } from '../../../../hooks';

const StyledBox = styled(Box)(workforceEntryStyles);

const addMethods = {
  email: 'email',
  csv: 'csv',
};
const LocationWorkforceEntry = () => {
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [addEmployee, setAddEmployee] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [addMethod, setAddMethod] = useState<
    keyof typeof addMethods | null
  >(null);

  const employeesPerPage: number = 8;

  const { locationId } = useContext(RetentionContext);
  const { setSimpleAlert } = useAlerts();

  const [
    fetchEmployeesByLocation,
    {
      data: employeeData,
      loading: employeeDataLoading = true,
      refetch,
    },
  ] = useLazyQuery(EMPLOYEE_DETAIL_BY_LOCATION, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  useEffect(() => {
    fetchEmployeesByLocation({
      variables: {
        locationId,
        limit: employeesPerPage,
        offset: 0,
      },
    });
  }, []);

  const [
    postEmployeeToLocationByEmail,
    { loading: postEmployeeToLocationByEmailLoading = false },
  ] = useMutation(POST_EMPLOYEE_TO_LOCATION_BY_EMAIL, {
    errorPolicy: 'all',
  });

  const [
    postEmployeeToLocationByCsv,
    { loading: postEmployeeToLocationByCsvLoading = false },
  ] = useMutation(POST_EMPLOYEE_TO_LOCATION_BY_CSV, {
    errorPolicy: 'all',
  });

  const showHideStepper = () => {
    setInputValue('');
    setAddEmployee(!addEmployee);
    setAddMethod(null);
  };

  const setAddBy = (byMethod: keyof typeof addMethods) => {
    setInputValue('');
    setAddMethod(byMethod);
  };

  const cancelAdd = () => {
    setAddEmployee(false);
    setInputValue('');
    setAddMethod(null);
  };

  const handleResponse = (response, key: string) => {
    if (response.errors || response.data[key] == null) {
      setSimpleAlert({
        isOpen: true,
        title: 'Error',
        subtitle:
          response.errors[0].message ||
          'There was an error adding your employee, please try again',
        onCancel: () => setSimpleAlert({ isOpen: false }),
      });
    } else {
      setAddEmployee(false);
      setSimpleAlert({
        isOpen: true,
        title: 'Success',
        subtitle: `Successfully added ${
          key === 'createEmployeesFromCSV'
            ? 'employees'
            : inputValue
        } to your workforce`,
        onCancel: () => setSimpleAlert({ isOpen: false }),
      });
    }
  };

  const addByEmail = async () => {
    const response = await postEmployeeToLocationByEmail({
      variables: {
        locationId,
        employeeEmail: inputValue,
      },
    });

    handleResponse(response, 'addEmployeeToLocation');
    if (
      get(
        response,
        'data.addEmployeeToLocation.employeeId',
        null,
      )
    ) {
      await refetch();
    }
  };

  const addByCSV = async () => {
    const response = await postEmployeeToLocationByCsv({
      variables: { locationId, csvFile: inputValue },
    });
    handleResponse(response, 'createEmployeesFromCSV');
  };

  const { pages = 0, results: employees = [] } = get(
    employeeData,
    'employeeDetailByLocation',
    {},
  );

  const handlePageChange = async (e, pageNumber) => {
    setActivePageNumber(pageNumber);
    await fetchEmployeesByLocation({
      variables: {
        locationId,
        limit: employeesPerPage,
        offset: employeesPerPage * (pageNumber - 1),
      },
    });
  };

  const Loading = React.memo(() => (
    <Box className="loadingWrapper">
      <Spinner />
    </Box>
  ));

  const ChooseMethod = React.memo(() => (
    <Box>
      <Box className="addMemberModalStep">
        <Typography variant="h3" className="uploadTypeTitle">
          Select a method
        </Typography>
        <Box className="buttonWrapper">
          <Button
            className={`addMemberButton ${
              addMethod === addMethods.email ? 'active' : ''
            }`}
            onClick={() => setAddBy(addMethods.email)}
          >
            <MdEmail />
            <Typography className="addOptionTitle">
              Email
            </Typography>
          </Button>
          <Button
            className={`addMemberButton ${
              addMethod === addMethods.csv ? 'active' : ''
            }`}
            onClick={() => setAddBy(addMethods.csv)}
          >
            <MdAttachFile />
            <Typography className="addOptionTitle">
              Upload CSV
            </Typography>
          </Button>
          <Button className="addMemberButton" disabled>
            <Typography
              className="addOptionTitle"
              variant={'body1'}
            >
              Merge
            </Typography>
            <Typography variant="body2" className="subtext">
              Ask your account representative for more
              information
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  ));

  const ByCsv = React.memo(() => (
    <Box className="addMemberModalStep">
      {postEmployeeToLocationByCsvLoading && <Loading />}
      <Box className="buttonWrapper">
        <Input
          type="file"
          label="CSV"
          accept=".csv"
          onChange={(e) => setInputValue(e.target.files[0])}
        />
      </Box>
      <Button
        className={'addButton'}
        onClick={addByCSV}
        variant={'outlined'}
      >
        Upload Employees
      </Button>
    </Box>
  ));

  const WorkforceRows = React.memo(
    () =>
      !addEmployee && (
        <Box className={'employeeCardWrapper'}>
          {employees?.map((employee) => (
            <EmployeeCard
              {...employee}
              enhanced
              key={`employee-card__${employee.id}`}
            />
          ))}
        </Box>
      ),
  );

  return (
    <EmployerDetailContainer>
      <StyledBox>
        <Box className="mainContent">
          <Typography variant="h1" className="locationTitle">
            {employees[0]?.location}
          </Typography>

          <Box className="actionsContainer">
            <Typography variant="h2">Your team</Typography>
            <Box>
              {addEmployee && (
                <Button
                  className="cancelButton"
                  onClick={() => cancelAdd()}
                >
                  Cancel
                </Button>
              )}
              <Button
                disabled={addEmployee}
                variant="primaryCta"
                startIcon={<PersonAddIcon />}
                onClick={showHideStepper}
              >
                Add team member
              </Button>
            </Box>
          </Box>
          {employeeDataLoading && <Loading />}
          {isEmpty(employees) ? (
            <Typography variant={'h3'} mb={2}>
              You don't currently have any employees associated
              to your account
            </Typography>
          ) : (
            <Box>
              <WorkforceRows />
              {pages > 0 && (
                <Pagination
                  count={pages}
                  size="large"
                  page={
                    activePageNumber === 0
                      ? activePageNumber + 1
                      : activePageNumber
                  }
                  onChange={handlePageChange}
                  className="paginationBar"
                />
              )}
            </Box>
          )}
        </Box>

        {addEmployee && (
          <Box className="addMemberModal">
            <Typography variant="h2">Add team member</Typography>
            <ChooseMethod />
            {postEmployeeToLocationByEmailLoading && <Loading />}
            {addMethod === addMethods.email && (
              <Box className="addMemberModalStep">
                <Box className="buttonWrapper">
                  <Input
                    value={inputValue}
                    placeholder="Enter team member email"
                    label="Email"
                    onChange={(e) =>
                      setInputValue(e.target.value)
                    }
                  />
                </Box>
                <Button
                  className={'addButton'}
                  onClick={addByEmail}
                  variant={'outlined'}
                >
                  Add Employee
                </Button>
              </Box>
            )}
            {addMethod === addMethods.csv && <ByCsv />}
          </Box>
        )}
      </StyledBox>
    </EmployerDetailContainer>
  );
};

export default LocationWorkforceEntry;
