import React from 'react';
import {styled, Switch} from 'components';
import {useAppStoreSelector} from '../../store';

const styles = () => ({
  '& .themeToggleContainer': {
    marginRight: 10
  },
  '& .themeToggle': {
    '& .MuiTouchRipple-root': {
      backgroundImage: `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.5 13.5'%3E%3Cpath d='M6.75,10.5c-2.07,0-3.75-1.68-3.75-3.75s1.68-3.75,3.75-3.75,3.75,1.68,3.75,3.75-1.68,3.75-3.75,3.75Zm0-6.37c-1.45,0-2.62,1.18-2.62,2.62s1.18,2.62,2.62,2.62,2.62-1.18,2.62-2.62-1.18-2.62-2.62-2.62Z' fill='%2349469d'/%3E%3Cpath d='M6.75,13.5c-.28,0-.5-.22-.5-.5v-.62c0-.28,.22-.5,.5-.5s.5,.22,.5,.5v.62c0,.28-.22,.5-.5,.5Z' fill='%2349469d'/%3E%3Cpath d='M6.75,1.6c-.28,0-.5-.22-.5-.5V.5c0-.28,.22-.5,.5-.5s.5,.22,.5,.5v.6c0,.28-.22,.5-.5,.5Z' fill='%2349469d'/%3E%3Cpath d='M11.17,11.67c-.13,0-.26-.05-.35-.15l-.44-.44c-.2-.2-.2-.51,0-.71,.2-.19,.51-.2,.71,0l.44,.44c.2,.2,.2,.51,0,.71-.1,.1-.23,.15-.35,.15Z' fill='%2349469d'/%3E%3Cpath d='M2.76,3.26c-.13,0-.26-.05-.35-.15l-.43-.43c-.2-.2-.2-.51,0-.71s.51-.2,.71,0l.43,.43c.2,.2,.2,.51,0,.71-.1,.1-.23,.15-.35,.15Z' fill='%2349469d'/%3E%3Cpath d='M13,7.25h-.62c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h.62c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z' fill='%2349469d'/%3E%3Cpath d='M1.1,7.25H.5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h.6c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z' fill='%2349469d'/%3E%3Cpath d='M10.73,3.27c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l.44-.44c.2-.2,.51-.2,.71,0,.2,.2,.2,.51,0,.71l-.44,.44c-.1,.1-.23,.15-.35,.15Z' fill='%2349469d'/%3E%3Cpath d='M2.33,11.67c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71l.43-.43c.2-.2,.51-.2,.71,0s.2,.51,0,.71l-.43,.43c-.1,.1-.23,.15-.35,.15Z' fill='%2349469d'/%3E%3C/svg%3E")`,
      backgroundSize: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }
  },
  '& .themeToggleChecked': {
    '& .MuiTouchRipple-root': {
      backgroundImage: `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.96 13.01'%3E%3Cpath d='M9.22,.57c.29,1.01,.36,2.11,.15,3.25-.52,2.79-2.77,5.04-5.56,5.56-1.14,.21-2.24,.14-3.25-.15-.42-.12-.74,.39-.46,.73,1.48,1.79,4.31,3.37,6.89,3,3.02-.43,5.49-2.89,5.91-5.91,.36-2.58-1.16-5.46-2.95-6.94-.34-.28-.85,.04-.73,.46Z' fill='%239972EC'/%3E%3C/svg%3E")`,
      backgroundSize: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    '& .MuiTouchRipple-root::before': {
      content: '""'
    }
  }
})

const StyledRoot = styled('div')(styles);

export const ToggleTheme = () => {
  const {toggleColorMode, colorMode} = useAppStoreSelector(({toggleColorMode: tcm, colorMode: cm}) => ({
    toggleColorMode: tcm,
    colorMode: cm
  }));


  return (
    <StyledRoot>
      <div className={'themeToggleContainer'}>
        <Switch
          checked={colorMode === 'dark'}
          color="primary"
          onClick={toggleColorMode}
          style={{
            marginRight: 10
          }}
          inputProps={{
            'aria-label': 'Theme'
          }}
          classes={{root: 'themeToggle', checked: 'themeToggleChecked'}}
        />
      </div>
    </StyledRoot>
  )
}
