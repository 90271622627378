import { gql } from '@apollo/client';
import { ORGANIZATION_STATS_FRAGMENT } from '../fragments';

export const GET_ORGANIZATION_STATS = gql`
  query GetOrganizationStats($organizationId: Int, $startDate: String, $endDate: String) {
    getOrganizationStats(organizationId: $organizationId, startDate: $startDate, endDate: $endDate) {
      brands {
        id
        createdAt
        updatedAt
        name
        logoUrl
        organizationId
        deletedAt
      }
      ...OrganizationStatsFieldsFragment
    }
  }
  ${ORGANIZATION_STATS_FRAGMENT}
`;