import { gql } from '@apollo/client';

export const ADD_SURVEY_RESPONSES = gql`
  mutation addSurveyResponses(
    $surveyCode: String
    $responses: [surveyQuestionEmployeeInputObjectType]
  ) {
    addSurveyResponses(
      surveyCode: $surveyCode
      responses: $responses
    ) {
      surveyId
    }
  }
`;

export const CREATE_SURVEY_CAMPAIGN = gql`
  mutation createSurveyCampaignByLocationId(
    $locationId: Int!
    $name: String!
    $surveyTypeId: Int!
    $questions: [surveyCampaignQuestionInputObjectType]
  ) {
    createSurveyCampaignByLocationId(
      locationId: $locationId
      name: $name
      surveyTypeId: $surveyTypeId
      questions: $questions
    ) {
      id
      name
    }
  }
`;

export const CREATE_OR_UPDATE_SURVEY_QUESTION = gql`
  mutation createOrUpdateSurveyQuestionBySurveyCampaignId(
    $question: String
    $surveyCampaignId: Int
  ) {
    createOrUpdateSurveyQuestionBySurveyCampaignId(
      question: $question
      surveyCampaignId: $surveyCampaignId
    ) {
      id
    }
  }
`;

export const CREATE_SURVEY_BY_CAMPAIGN = gql`
  mutation createSurveyByCampaign($surveyCampaignId: Int) {
    createSurveyByCampaign(surveyCampaignId: $surveyCampaignId) {
      id
    }
  }
`;

export const ADD_EMPLOYEES_TO_SURVEY = gql`
  mutation addEmployeesToSurvey(
    $surveyId: Int!
    $surveyCampaignId: Int!
    $employees: [SurveyEmployeesInputType]
  ) {
    addEmployeesToSurvey(
      surveyId: $surveyId
      surveyCampaignId: $surveyCampaignId
      employees: $employees
    ) {
      surveyCampaignId
    }
  }
`;
