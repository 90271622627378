import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import {
  REQUEST_ABILITY_COMPLETION,
  REQUEST_SKILL_COMPLETION,
} from 'api';
import { useMutation } from '@apollo/client';
import badgeCardStyles from '../../../styles/Badges/badgeCard';

const StyledCard = styled('div')(badgeCardStyles);

interface Badge {
  abilitiesBadgeId: number;
  abilitiesId: number;
  skillsId: number;
  badgeUrl: string;
  name: string;
  description: string;
  status: string;
}

const GrowBadgeCard = ({ badge }: { badge: Badge }) => {
  const [buttonClass, setButtonClass] = useState(badge.status);

  const [skillRequest, { error: requestSkillCompletionError }] =
    useMutation(REQUEST_SKILL_COMPLETION, {
      variables: { skillsId: badge.skillsId },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });

  const [
    abilityRequest,
    { error: requestAbilityCompletionError },
  ] = useMutation(REQUEST_ABILITY_COMPLETION, {
    variables: { abilityId: badge.abilitiesId },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const handleBadgeRequest = async (badgeRequest) => {
    if (badgeRequest.abilitiesId) {
      await abilityRequest({
        variables: {
          abilityId: badge.abilitiesId,
        },
      });
      // eslint-disable-next-line no-unused-expressions
      badgeRequest.status !== 'pending'
        ? setButtonClass('Requested')
        : setButtonClass(badge.status);
    } else if (badgeRequest.skillsId) {
      await skillRequest({
        variables: {
          skillsId: badge.skillsId,
        },
      });
      // eslint-disable-next-line no-unused-expressions
      badgeRequest.status !== 'pending'
        ? setButtonClass('Requested')
        : setButtonClass(badge.status);
    }

    return null;
  };

  const badgeDescription = () => {
    if (badge.description) {
      return badge.description.length > 75
        ? `${badge.description.slice(0, 74)}...`
        : badge.description;
    }
    return 'Description Not Available';
  };

  return (
    <StyledCard>
      <Box className="badgeTitleWrapper">
        <img alt="badge" src={badge.badgeUrl} />
        <Box className="positioningWrapper">
          <Typography variant="h2">{badge.name}</Typography>
        </Box>
        <Typography variant={'body1'} fontSize={12} mt={1}>
          {badgeDescription()}
        </Typography>
      </Box>
      <Button
        className={buttonClass}
        onClick={() => handleBadgeRequest(badge)}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          badge.status === 'initial'
            ? 'Request approval'
            : buttonClass === 'requested'
            ? buttonClass
            : badge.status
        }
      </Button>
      {requestAbilityCompletionError ||
      requestSkillCompletionError ? (
        <Typography className="errorText">
          {requestSkillCompletionError
            ? requestSkillCompletionError.graphQLErrors?.[0]
                ?.message
            : requestAbilityCompletionError.graphQLErrors?.[0]
                ?.message}
        </Typography>
      ) : null}
    </StyledCard>
  );
};
export default GrowBadgeCard;
