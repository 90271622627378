import { gql } from '@apollo/client';

export const GET_EMPLOYER_JOBS = gql`
  query employerJobs($active: Boolean) {
    employerJobs(active: $active) {
      id
      title
      imageUrl
      createdAt
      updatedAt
      draft
      active
      applied
      closedDate
      city
      stateId
      countryId
      zip
      applicants {
        total
        rejectedCount
        needsActionCount
        starredCount
        qmCount
        last7DaysApps
      }
    }
  }
`;

export const GET_EMPLOYER_JOB_METRICS = gql`
  query employerJobMetrics {
    employerJobMetrics {
      applicantApplied
      applicantHired
      applicantNextSteps
      applicantRejected
      applicantStarred
      jobCount
      matchCount
      todo
    }
  }
`;
