const {
  ALLOW_EMPLOYER_SCHEDULE_ASSISTANT = '',
  APP_STORE_URL = '',
  BETA_SCHEDULE_TESTERS = '[]',
  BETA_PAYMENT_TESTERS = '[]',
  EMPLOYEE_TUTORIAL_URL = '',
  EMPLOYER_TUTORIAL_URL = '',
  LANDING_ORIGIN = '',
  PLAY_MARKET_URL = '',
  TRACKING_PARAM = '',
  ALLOW_PAYMENT = '',
  BETA_TEAM_MANAGEMENT_TESTERS = '[]',
  DOMAIN_NAME = '',
} = process.env;
const enableScheduleAssistant =
  ALLOW_EMPLOYER_SCHEDULE_ASSISTANT === 'true';
const enablePayment = ALLOW_PAYMENT === 'true';
const betaScheduleTesters = BETA_SCHEDULE_TESTERS
  ? JSON.parse(BETA_SCHEDULE_TESTERS)
  : [];
const betaPaymentTesters = BETA_PAYMENT_TESTERS
  ? JSON.parse(BETA_PAYMENT_TESTERS)
  : [];
const betaTeamManagementTesters = BETA_TEAM_MANAGEMENT_TESTERS
  ? JSON.parse(BETA_TEAM_MANAGEMENT_TESTERS)
  : [];

// landing urls
const TERMS_URL = `${LANDING_ORIGIN}/privacy/WorkTorch%20Website%20Terms%20of%20Use%202021.pdf`;
const PRIVACY_URL = `${LANDING_ORIGIN}/privacy/WorkTorch%20Website%20Privacy%20Policy.pdf`;
const FAQ_URL = `${LANDING_ORIGIN}/faq`;
const DEMO_REQUEST_URL = `${LANDING_ORIGIN}/employer/#plans`;
const BLOG_URL = `https://blog.${DOMAIN_NAME}/`;
const SUPPORT_EMAIL = `support@${DOMAIN_NAME}`;
const CONTACT_EMAIL = `hello@${DOMAIN_NAME}`;
const PRESS_EMAIL = `press@${DOMAIN_NAME}`;
const CAREERS_URL =
  'https://wortktorch.notion.site/Join-Our-Team-95267cf5dbad407a9844bae05f259008';

// For debugging
const SCHEDULE_TIME_DOUBLE_CONVERT = true; // true = convert slot time to employer tz

const isPaymentEnabled = () => {
  const userId = localStorage.getItem('userId');
  return (
    enablePayment ||
    betaPaymentTesters.indexOf(Number(userId)) !== -1
  );
};

export {
  ALLOW_EMPLOYER_SCHEDULE_ASSISTANT,
  ALLOW_PAYMENT,
  APP_STORE_URL,
  betaPaymentTesters,
  betaScheduleTesters,
  betaTeamManagementTesters,
  BLOG_URL,
  CAREERS_URL,
  DEMO_REQUEST_URL,
  EMPLOYEE_TUTORIAL_URL,
  EMPLOYER_TUTORIAL_URL,
  enablePayment,
  enableScheduleAssistant,
  FAQ_URL,
  isPaymentEnabled,
  LANDING_ORIGIN,
  PLAY_MARKET_URL,
  PRIVACY_URL,
  SCHEDULE_TIME_DOUBLE_CONVERT,
  TERMS_URL,
  TRACKING_PARAM,
  SUPPORT_EMAIL,
  PRESS_EMAIL,
  CONTACT_EMAIL,
};
