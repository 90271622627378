import { useLazyQuery } from '@apollo/client';
import { SEARCH_CONNECTIONS } from '../api/queries/employeeConnections';

export interface IEmployeeConnection {
  email: string;
  user_id: number;
  employeeProfile: {
    careerPathGroupItems: {
      index: number;
      careerPathGroupItemJobType: {
        name: string;
      };
    }[];
    city: string;
    countryId: number;
    imageUrl: string;
    name: string;
    profile_id: number;
    stateId: number;
    zip: string;
  };
}
export interface IEmployeeConnectionsResp {
  searchConnections: {
    nextStepResults: IEmployeeConnection[];
    similarResults: IEmployeeConnection[];
  };
}

const useConnections = () => {
  const [
    fetchEmployeeConnections,
    {
      data: fetchEmployeeConnectionsData,
      loading: fetchEmployeeConnectionsLoading,
      error: fetchEmployeeConnectionsError,
    },
  ] = useLazyQuery<IEmployeeConnectionsResp>(
    SEARCH_CONNECTIONS,
    {},
  );

  return {
    employeeConnections: {
      fetch: fetchEmployeeConnections,
      data: fetchEmployeeConnectionsData,
      loading: fetchEmployeeConnectionsLoading,
      error: fetchEmployeeConnectionsError,
    },
  };
};

export default useConnections;
