export const workforceEntryStyles = ({ theme }) => ({
  '& .loadingWrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '60vh',
    border: `1px solid ${theme.palette.button.primary.main}`,
    borderRadius: 8,
  },
  '& .workforceHeader': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0 10px 0',
  },
  '& .searchContainer': {
    padding: '0 0 10px 0',
  },
  '& .mainContent': {
    width: '100%',
    borderRadius: 8,
    padding: '0 24 24 24',
  },
  '& .employerCardWrapper': {
    display: 'grid',
    gridTemplateColumns: `repeat(5, 1fr)`,
    gridTemplateRow: `repeat(4, 1fr)`,
    gridColumnGap: 16,
    gridRowGap: 16,
  },
  '& .brandTitle': {
    marginBottom: 12,
  },
  '& .moreIconWrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '& .memberImage': {
    height: 48,
    width: 48,
    borderRadius: 248,
    marginBottom: 12,
  },
  '& .memberName': {
    fontSize: 16,
    fontWeight: 700,
  },
  '& .memberTitle': {
    fontSize: 14,
  },
  '& .paginationBar': {
    marginTop: 24,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .actionsContainer': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32,
  },
  '& .memberAddButton': {
    backgroundColor: theme.palette.button.primary.main,
    color: theme.palette.button.primaryCtaText,
  },
  '& .addMemberModal': {
    border: `1px solid ${theme.palette.button.primary.main}`,
    borderRadius: 8,
    padding: 24,
    minHeight: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  '& .addMemberModalStep': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    minWidth: 400,
    minHeight: 124,
  },
  '& .buttonWrapper': {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  '& .addMemberButton': {
    border: `1px solid ${theme.palette.button.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    padding: 16,
    margin: 12,
    fontWeight: 700,
    width: 140,
    minHeight: 140,
    '&:active, &:focus': {
      backgroundColor: theme.palette.button.primary.main,
    },
  },
  '& .addOptionTitle': {
    marginTop: 8,
  },
  '& .uploadTypeTitle': {
    marginBottom: 8,
  },
  '& .selected': {
    backgroundColor: theme.palette.button.primary.main,
  },
  '& .responseWrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    borderRadius: 8,
    minHeight: 312,
    border: `1px solid ${theme.palette.button.primary.main}`,
  },
  '& .subtext': {
    fontSize: 10,
  },
  '& .cancelButton': {
    border: `1px solid ${theme.palette.button.primary.main}`,
    marginRight: 8,
  },
  '& .responseText': {
    marginBottom: 24,
  },
  '& .responseButton': {
    backgroundColor: theme.palette.button.primary.main,
    fontWeight: 700,
  },
  '& .search-input': {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.light,
    borderRadius: 8,
  },
});
