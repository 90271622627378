export default ({ theme }) => ({
  '&.job_metrics_container': {
    margin: '10px 30px 30px',
    padding: 15,
    border: `1px solid rgba(0, 0, 0, 0.25)`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4
  },
  '& .job_metrics_header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .job_metrics_data': {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      justifyContent: 'start'
    }
  },
  '& .job_metric': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      flexBasis: 'calc(100% / 3)',
      borderBottom: `1px solid ${theme.palette.background.paper}`,
      paddingBottom: 7.5,
      marginBottom: 7.5
    }
  },
  '& .job_metric_title': {
    fontWeight: 100,
    fontSize: 16,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 12
    }
  },
  '& .job_metric_data': {
    fontWeight: 800,
    fontSize: 30
  }
});
