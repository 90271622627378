import { addAlpha } from 'styles/utils';

export default ({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .header': {
    marginBottom: 37,
    padding: '17px 18px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .header__title': {
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.grey['100']
  },
  '& .header__action': {
    fontSize: 18,
    color: theme.palette.common.white,
    '&.Mui-disabled': {
      color: theme.palette.grey['600']
    }
  },
  '& .content': {
    flex: 1,
    padding: '0 18px 17px',
    overflow: 'auto'
  },
  '& .footer': {
    paddingBottom: 17,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .days__container': {
    borderTop: `1px solid ${theme.palette.grey['600']}`,
    borderBottom: `1px solid ${theme.palette.grey['600']}`
  },
  '& .expansionPanel__root': {
    borderBottom: theme.palette.grey['300'],
    background: 'transparent',
    '&:before': {
      backgroundColor: theme.palette.grey['600']
    },
    '&.Mui-expanded': {
      margin: 'auto',
      '&:before': {
        opacity: 1
      }
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent'
    }
  },
  '& .expansionPanel__summary': {
    minHeight: 'auto',
    padding: '6px 0',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '16px 0 18px'
    },
    '&.Mui-expanded': {
      minHeight: 'auto'
    },
    '&.Mui-disabled': {
      opacity: 1,
      '& .weekDay, & .openTimesCount': {
        color: theme.palette.grey['300']
      }
    }
  },
  '& .expansionPanel__summaryContent': {
    position: 'relative',
    margin: 0,
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '13px',
    [theme.breakpoints.down('desktopApp')]: {
      justifyContent: 'center'
    },
    '&.Mui-expanded': {
      margin: 'auto'
    }
  },
  '& .expansionPanel__details': {
    padding: '11px 0 17px',
    justifyContent: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 263,
      margin: '0 auto',
      padding: '10px 0 22px'
    }
  },
  '& .expansionPanel__icon': {
    padding: 0,
    marginRight: 0,
    fontSize: 12,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16
    }
  },
  '& .scheduleLoaderContainer': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: addAlpha(theme.palette.background.default, 0.7)
  },
  '& .weekDay': {
    color: theme.palette.text.primary
  },
  '& .openTimesCount': {
    color: theme.palette.primary.light
  },
  '& .timesContainer': {
    width: '100%',
    margin: '0 -5px -5px',
    display: 'flex',
    flexFlow: 'wrap',
    [theme.breakpoints.down('desktopApp')]: {
      margin: '0 -6px 13px'
    }
  },
  '& .timeItem': {
    width: '100%',
    maxWidth: '33.33%',
    padding: '0 5px 5px',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 6px 13px'
    }
  },
  '& .timeButton': {
    // increase specificity
    width: '100%',
    height: 26,
    fontSize: 10,
    lineHeight: '11px',
    color: theme.palette.grey['800'],
    backgroundColor: theme.palette.action.active,
    borderRadius: 25,
    [theme.breakpoints.down('desktopApp')]: {
      height: 30,
      fontSize: 11,
      lineHeight: '12px'
    },
    '&.selected': {
      backgroundColor: theme.palette.tertiary.main
    },
    '&.disabled': {
      backgroundColor: theme.palette.action.disabled,
      color: theme.palette.grey['200'],
      '&.selected': {
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.common.white
      }
    }
  },
  '& .confirm__header': {
    padding: 9
  },
  '& .confirm__closeBtn': {
    width: 33,
    height: 33,
    boxShadow: '0 4px 4px 0 rgba(255,255,255,0.1)'
  },
  '& .confirm__content': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .confirm__title': {
    marginBottom: 8,
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.grey['100']
  },
  '& .confirm__date': {
    marginBottom: 25,
    fontWeight: 'bold',
    fontSize: 13,
    lineHeight: '14px',
    color: theme.palette.primary.light
  }
});
