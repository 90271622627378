import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { qaAttr, getUrlParameter, getRoutes } from 'utils';
import { FormHelperText, styled } from 'components';
import { Button, IconButton, Input, Spinner } from 'components/shared';
import { MdArrowBack, MdCheck } from 'components/icons';
import { useForm } from 'components/form';
import styles from 'styles/Login';
import { ASK_PASSWORD_RESET, POST_NEW_PASSWORD } from 'api';
import LoginLayout from '../Layouts/LoginLayout';

const StyledLoginLayout = styled(LoginLayout)(styles);

const ROUTES = getRoutes();

const PasswordReset = () => {
  const location = useLocation();

  const [requestPwdReset, { error: requestError, loading: requestPwdLoading }] =
    useMutation(ASK_PASSWORD_RESET);
  const [postNewPwd, { error: newPwdError, loading: postPwdLoading }] =
    useMutation(POST_NEW_PASSWORD);
  const backendError =
    requestError?.graphQLErrors?.[0]?.message || newPwdError?.graphQLErrors?.[0]?.message;

  const [isNewPasswordStep, setIsNewPasswordStep] = useState(false); // new password form step
  const [requestResetSuccess, setRequestResetSuccess] = useState(false); // first step success
  const [resetSuccess, setResetSuccess] = useState(false); // last step success

  const { $, set, validate, useConfig } = useForm({
    initial: { email: '', password: '', confirmedPassword: '' },
    validations: {
      email: undefined,
      password: undefined,
      confirmedPassword: undefined
    }
  });

  useConfig(() => {
    if (isNewPasswordStep) {
      return {
        validations: {
          email: undefined,
          password: 'presence',
          confirmedPassword: {
            presence: true,
            equality: {
              attribute: 'password',
              message: "Passwords don't match"
            }
          }
        }
      };
    }
    return {
      validations: {
        email: ['presence', 'email'],
        password: undefined,
        passwordConfirm: undefined
      }
    };
  }, [isNewPasswordStep]);

  useEffect(() => {
    const token = location.search ? getUrlParameter(location.search, 'token') : '';

    if (token) setIsNewPasswordStep(true);
  }, [location]);

  const submitRequestForm = (e) => {
    e.preventDefault();
    validate().then(async ({ email }) => {
      try {
        await requestPwdReset({ variables: { email } });
        setRequestResetSuccess(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const submitResetForm = (e) => {
    e.preventDefault();
    const token = location.search ? getUrlParameter(location.search, 'token') : '';

    if (token) {
      validate().then(async ({ password }) => {
        try {
          await postNewPwd({ variables: { password, token } });
          setResetSuccess(true);
        } catch (error) {
          console.error(error);
        }
      });
    } else {
      throw new Error('Missed token');
    }
  };

  const handleInputChange = (e, { name }) => {
    set(name, e.target.value);
  };

  const renderGoBackButton = () => (
    <IconButton isRouterLink to={ROUTES.login.default} color="primary" testID="go-to-sign-in">
      <MdArrowBack color="inherit" />
    </IconButton>
  );

  const renderContinueButton = (title) => (
    <Button
      isRouterLink
      to={ROUTES.login.default}
      variant="filled-primary"
      sx={{ width: '100%', height: 50 }}
      testID="continue-button"
    >
      {title}
    </Button>
  );

  const renderBackendError = () =>
    backendError && (
      <FormHelperText error className="helperText">
        {backendError}
      </FormHelperText>
    );

  const renderContent = () => {
    if (requestResetSuccess) {
      return (
        <div className="content">
          <div className="subPageHeader">{renderGoBackButton()}</div>
          <div className="resetPwd-content__body">
            <h1 className="pageTitle">Link to reset password has been sent.</h1>
            <p className="resetPwd-text">Please check your email!</p>
            {renderContinueButton('Continue')}
          </div>
        </div>
      );
    }
    if (resetSuccess) {
      return (
        <div className="content">
          <div className="resetPwd-content__body">
            <div className="resetPwd-resetSuccess__iconWrapper">
              <MdCheck fontSize="inherit" color="inherit" />
            </div>
            <h1 className="pageTitle">Password Has Been Reset</h1>
            {renderContinueButton('Take Me To The App')}
          </div>
        </div>
      );
    }
    if (isNewPasswordStep) {
      return (
        <div className="content">
          <h1 className="pageTitle" {...qaAttr('new-password-title')}>
            Reset Password
          </h1>
          <form className="centeredBlock" noValidate onSubmit={submitResetForm}>
            <Input
              id="password"
              {...$('password', handleInputChange)}
              placeholder="New Password"
              type="password"
              withHelperText
              highlightInputOnError={false}
              FormControlProps={{ sx: { mb: '16px' } }}
              analyticParams={{ key: 'Password focused (password reset)', trigger: 'focus' }}
              testID="new-password-input"
            />
            <Input
              id="confirmedPassword"
              {...$('confirmedPassword', handleInputChange)}
              placeholder="Repeat New Password"
              type="password"
              withHelperText
              highlightInputOnError={false}
              FormControlProps={{ sx: { mb: '16px' } }}
              analyticParams={{
                key: 'Password confirmation focused (password reset)',
                trigger: 'focus'
              }}
              testID="new-password-confirm-input"
            />
            {renderBackendError()}
            <Button
              type="submit"
              variant="filled-primary"
              sx={{ width: '100%', height: 50 }}
              disabled={postPwdLoading}
              endIcon={postPwdLoading && <Spinner size={12} />}
              testID="new-password-submit-button"
            >
              Reset
            </Button>
          </form>
        </div>
      );
    }

    return (
      <div className="content">
        <div className="subPageHeader">{renderGoBackButton()}</div>
        <div className="resetPwd-content__body">
          <h1 className="pageTitle resetPwd-title" {...qaAttr('reset-password-title')}>
            Forgot Password
          </h1>
          <form noValidate onSubmit={submitRequestForm}>
            <Input
              id="email"
              {...$('email', handleInputChange)}
              placeholder="Email"
              type="email"
              withHelperText
              highlightInputOnError={false}
              FormControlProps={{ sx: { mb: '16px' } }}
              analyticParams={{ key: 'Email focused (password reset)', trigger: 'focus' }}
              testID="reset-password-input"
            />
            {renderBackendError()}
            <Button
              type="submit"
              variant="filled-primary"
              sx={{ width: '100%', height: 50 }}
              disabled={requestPwdLoading}
              endIcon={requestPwdLoading && <Spinner size={12} />}
              testID="reset-password-submit-button"
            >
              Send Reset Link
            </Button>
          </form>
        </div>
      </div>
    );
  };

  return <StyledLoginLayout className="container">{renderContent()}</StyledLoginLayout>;
}

PasswordReset.propTypes = {};

export default PasswordReset;
