import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ONBOARDINGS,
  ONBOARDING,
  ONBOARDING_ASSIGNMENT,
  ONBOARDING_DOCUMENT,
  ONBOARDING_DOCUMENTS,
  ONBOARDING_RESOURCE,
  ONBOARDING_RESOURCES,
  ONBOARDING_FLOW, ONBOARDING_RESOURCES_MINIMAL_DATA, ONBOARDING_RESOURCE_ONBOARDING
} from "../api/queries/onboarding";
import {
  IOnboardingDocumentCreateResp, IOnboardingDocumentResp,
  IOnboardingDocumentsResp,
  IOnboardingDocumentUpdateResp,
  IOnboardingResourceCreateResp,
  IOnboardingResourceResp,
  IOnboardingResourcesResp,
  IOnboardingResourceUpdateResp,
  IOnboardingResp,
  IOnboardingsResp,
  IOnboardingFlowResp, IOnboardingResourceOnboardingResp, ISaveOnboardingFlowResp
} from "../api/types/queries/onboarding.types";
import {
  CREATE_ONBOARDING_DOCUMENT,
  CREATE_ONBOARDING_RESOURCE, SAVE_ONBOARDING_FLOW,
  UPDATE_ONBOARDING_DOCUMENT,
  UPDATE_ONBOARDING_RESOURCE
} from "../api/mutations/onboarding";

const useEmployerOnboarding = () => {
  const [
    getOnboardingById,
    {
      loading: onboardingByIdLoading,
      error: onboardingByIdError,
      data: onboardingByIdData,
    },
  ] = useLazyQuery<IOnboardingResp>(ONBOARDING, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getOnboardings,
    {
      loading: onboardingsLoading,
      error: onboardingsError,
      data: onboardingsData,
    },
  ] = useLazyQuery<IOnboardingsResp>(ONBOARDINGS, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getOnboardingDocuments,
    {
      loading: onboardingDocumentsLoading,
      error: onboardingDocumentsError,
      data: onboardingDocumentsData,
    },
  ] = useLazyQuery<IOnboardingDocumentsResp>(
    ONBOARDING_DOCUMENTS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [
    getOnboardingDocumentById,
    {
      loading: onboardingDocumentByIdLoading,
      error: onboardingDocumentByIdError,
      data: onboardingDocumentByIdData,
    },
  ] = useLazyQuery<IOnboardingDocumentResp>(ONBOARDING_DOCUMENT, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getOnboardingAssignmentById,
    {
      loading: onboardingAssignmentByIdLoading,
      error: onboardingAssignmentByIdError,
      data: onboardingAssignmentByIdData,
    },
  ] = useLazyQuery(ONBOARDING_ASSIGNMENT, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getOnboardingResources,
    {
      loading: onboardingResourcesLoading,
      error: onboardingResourcesError,
      data: onboardingResourcesData,
    },
  ] = useLazyQuery<IOnboardingResourcesResp>(
    ONBOARDING_RESOURCES,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getOnboardingResourcesMinimal, {
    loading: getOnboardingResourcesMinimalLoading,
    error: getOnboardingResourcesMinimalError,
    data: getOnboardingResourcesMinimalData,
  }] = useLazyQuery<IOnboardingResourcesResp>(ONBOARDING_RESOURCES_MINIMAL_DATA, {
    fetchPolicy: 'cache-and-network'
  })

  const [
    getOnboardingResourceById,
    {
      loading: onboardingResourceByIdLoading,
      error: onboardingResourceByIdError,
      data: onboardingResourceByIdData,
    },
  ] = useLazyQuery<IOnboardingResourceResp>(
    ONBOARDING_RESOURCE,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getOnboardingFlow,
    {
      loading: getOnboardingFlowLoading,
      error: getOnboardingFlowError,
      data: getOnboardingFlowData,
    }] = useLazyQuery<IOnboardingFlowResp>(ONBOARDING_FLOW, {
    fetchPolicy: 'cache-and-network'
  });

  const [getOnboardingResourceOnboarding,
    {
      loading: getOnboardingResourceOnboardingLoading,
      error: getOnboardingResourceOnboardingError,
      data: getOnboardingResourceOnboardingData,
    }] = useLazyQuery<IOnboardingResourceOnboardingResp>(ONBOARDING_RESOURCE_ONBOARDING, {
    fetchPolicy: 'cache-and-network'
  })

  // end queries
  // start mutations

  const [
    createOnboardingResource,
    {
      data: createOnboardingResourceData,
      loading: createOnboardingResourceLoading,
      error: createOnboardingResourceError,
    },
  ] = useMutation<IOnboardingResourceCreateResp>(
    CREATE_ONBOARDING_RESOURCE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [
    updateOnboardingResource,
    {
      loading: updateOnboardingResourceLoading,
      data: updateOnboardingResourceData,
      error: updateOnboardingResourceError,
    },
  ] = useMutation<IOnboardingResourceUpdateResp>(
    UPDATE_ONBOARDING_RESOURCE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [
    createOnboardingDocument,
    {
      createOnboardingDocumentLoading,
      createOnboardingDocumentData,
      createOnboardingDocumentError,
    },
  ] = useMutation<IOnboardingDocumentCreateResp>(
    CREATE_ONBOARDING_DOCUMENT,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [
    updateOnboardingDocument,
    {
      updateOnboardingDocumentLoading,
      updateOnboardingDocumentData,
      updateOnboardingDocumentError,
    },
  ] = useMutation<IOnboardingDocumentUpdateResp>(
    UPDATE_ONBOARDING_DOCUMENT,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [
    saveOnboardingFlow,
    {
      saveOnboardingFlowLoading,
      saveOnboardingFlowData,
      saveOnboardingFlowError,
    },
  ] = useMutation<ISaveOnboardingFlowResp>(SAVE_ONBOARDING_FLOW, {
    fetchPolicy: 'network-only'
  })

  const assignments = {
    oneById: {
      get: getOnboardingAssignmentById,
      loading: onboardingAssignmentByIdLoading,
      error: onboardingAssignmentByIdError,
      data: onboardingAssignmentByIdData,
    },
  };

  const onboarding = {
    oneById: {
      get: getOnboardingById,
      loading: onboardingByIdLoading,
      error: onboardingByIdError,
      data: onboardingByIdData,
    },
    all: {
      get: getOnboardings,
      loading: onboardingsLoading,
      error: onboardingsError,
      data: onboardingsData,
    },
    flow: {
      get: getOnboardingFlow,
      loading: getOnboardingFlowLoading,
      error: getOnboardingFlowError,
      data: getOnboardingFlowData,
      save: saveOnboardingFlow,
      saveLoading: saveOnboardingFlowLoading,
      saveError: saveOnboardingFlowData,
      saveData: saveOnboardingFlowError,
    },
  };

  const documents = {
    all: {
      get: getOnboardingDocuments,
      loading: onboardingDocumentsLoading,
      error: onboardingDocumentsError,
      data: onboardingDocumentsData,
    },
    oneById: {
      get: getOnboardingDocumentById,
      loading: onboardingDocumentByIdLoading,
      error: onboardingDocumentByIdError,
      data: onboardingDocumentByIdData,
    },
    new: {
      create: createOnboardingDocument,
      loading: createOnboardingDocumentLoading,
      data: createOnboardingDocumentData,
      error: createOnboardingDocumentError,
    },
    update: {
      save: updateOnboardingDocument,
      loading: updateOnboardingDocumentLoading,
      data: updateOnboardingDocumentData,
      error: updateOnboardingDocumentError,
    },
  };

  const resources = {
    new: {
      create: createOnboardingResource,
      loading: createOnboardingResourceLoading,
      error: createOnboardingResourceError,
      data: createOnboardingResourceData,
    },
    update: {
      save: updateOnboardingResource,
      loading: updateOnboardingResourceLoading,
      data: updateOnboardingResourceData,
      error: updateOnboardingResourceError,
    },
    oneById: {
      get: getOnboardingResourceById,
      loading: onboardingResourceByIdLoading,
      error: onboardingResourceByIdError,
      data: onboardingResourceByIdData,
    },
    all: {
      get: getOnboardingResources,
      loading: onboardingResourcesLoading,
      error: onboardingResourcesError,
      data: onboardingResourcesData,
    },
    allMinimal: {
      get: getOnboardingResourcesMinimal,
      loading: getOnboardingResourcesMinimalLoading,
      error: getOnboardingResourcesMinimalError,
      data: getOnboardingResourcesMinimalData,
    }
  };

  const resourceOnboardingResource = {
    all: {
      get: getOnboardingResourceOnboarding,
      loading: getOnboardingResourceOnboardingLoading,
      error: getOnboardingResourceOnboardingError,
      data: getOnboardingResourceOnboardingData,
    }
  }

  return {
    assignments,
    onboarding,
    documents,
    resources,
    resourceOnboardingResource
  };
};

export default useEmployerOnboarding;
