import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { GET_PULSE_BY_LOCATION, LOCATION_EVENTS } from 'api';
import { Button, Spinner } from 'components/shared';
import { VerticalBarChart } from 'components/shared/charts';
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'components';
import { getRoutes } from 'utils';
import { pulseEntryStyles } from 'styles/Retention/Location/pulse';
import EmployerDetailContainer from '../../../../components/Retention/components/Location/LocationDashboardRenderer';
import LocationDetailContainer from '../../../../components/Retention/components/Location/LocationDetailContainer';
import LocationUpcomingEventCard from '../../../../components/Retention/components/Location/LocationUpcomingEventCard';
import MainSectionContainer from '../../../../components/Retention/components/Location/MainSectionContainer';
import MetricContainer from '../../../../components/Retention/components/Location/MetricContainer';
import { SideBarResource } from '../../../../components/Retention/components/Location/SideBarResource';
import { RetentionContext } from '../../../../components/Retention/utils';

const StyledBox = styled(Box)(pulseEntryStyles);
const StyledContainer = styled('div')(() => ({
  '& sup': {
    verticalAlign: 'super',
    fontSize: 20,
  },
  '& .active__emps': {
    textAlign: 'center',
  },
}));

const asideResources = () => [
  <SideBarResource
    title="How to Leverage Tech to Hire Quickly — And Why It’s Important"
    imageUrl="https://worktorch-site-images.s3.us-east-2.amazonaws.com/how-to-leverage-tech-to-hire-quickly-and-why-its-important.png"
    link="https://blog.worktorch.io/2022/05/how-to-leverage-tech-to-hire-quickly-and-why-its-important/"
  />,
  <SideBarResource
    title="How to Tailor Job Descriptions So They Stand Out"
    imageUrl="https://worktorch-site-images.s3.us-east-2.amazonaws.com/how-to-tailor-job-descriptions-so-they-stand-out.png"
    link="https://blog.worktorch.io/2022/06/how-to-tailor-job-descriptions-so-they-stand-out/"
  />,
];

const PulseContent = () => {
  const [interactionType, setInteractionType] = useState(null);
  const routes = getRoutes();
  const navigate = useNavigate();

  const ctx = useContext(RetentionContext);
  const { locationId } = ctx;

  const [
    fetchLocationEvents,
    { data: locationEventsData, loading = true },
  ] = useLazyQuery(LOCATION_EVENTS, {
    variables: {
      locationId,
      limit: 3,
      offset: 0,
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  useEffect(() => {
    fetchLocationEvents();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  const upcomingEventsData = locationEventsData?.locationEvents;
  const eventTotalCount = upcomingEventsData?.total;
  const upcomingEvents = upcomingEventsData?.results;

  return (
    <StyledBox>
      <Box className="interaction-container">
        <Box>
          <Typography variant="h3">Interaction</Typography>
        </Box>
        <Box className="action-container">
          <Box className="action-button-container">
            <Button
              className={`message-action-type ${
                interactionType === ctx.interactionTypes.survey
                  ? 'active'
                  : ''
              }`}
              variant="filled-primary"
              onClick={() =>
                setInteractionType(ctx.interactionTypes.survey)
              }
            >
              <Box>Survey</Box>
            </Button>
            <Button
              className={`message-action-type ${
                interactionType ===
                ctx.interactionTypes.anniversary
                  ? 'active'
                  : ''
              }`}
              variant="filled-primary"
              onClick={() =>
                setInteractionType(
                  ctx.interactionTypes.anniversary,
                )
              }
            >
              <Box>Work Anniversary</Box>
            </Button>
            <Button
              className={`message-action-type ${
                interactionType === ctx.interactionTypes.review
                  ? 'active'
                  : ''
              }`}
              variant="filled-primary"
              onClick={() =>
                setInteractionType(ctx.interactionTypes.review)
              }
            >
              <Box>Review Team</Box>
            </Button>
            <Button
              className={`message-action-type ${
                interactionType === ctx.interactionTypes.custom
                  ? 'active'
                  : ''
              }`}
              variant="filled-primary"
              onClick={() =>
                setInteractionType(ctx.interactionTypes.custom)
              }
            >
              <Box>Custom</Box>
            </Button>
          </Box>
          <Button
            variant="outlined-secondary"
            disabled={!interactionType}
            onClick={() =>
              navigate(
                `${routes.employer.retention_portal.interactions.root}`,
                {
                  state: {
                    interactionType,
                    locationId,
                  },
                },
              )
            }
          >
            <Box>Next</Box>
          </Button>
        </Box>
      </Box>
      <Box className="upcoming-events">
        <Box className="upcomingEventsTitleWrapper">
          <Typography variant="h3">Upcoming Events</Typography>

          {eventTotalCount > 3 && (
            <Button
              onClick={() => {
                navigate(
                  `${routes.employer.retention_portal.location}/upcoming-events`,
                );
              }}
            >
              <Typography
                className="viewMoreLink"
                variant="button"
              >
                View more
              </Typography>
            </Button>
          )}
        </Box>
        <Box className="employee-container">
          {upcomingEvents?.map((event) => (
            <LocationUpcomingEventCard
              key={`event__${event.profileId}`}
              event={event}
            />
          ))}
        </Box>
      </Box>
    </StyledBox>
  );
};

const LocationPulseEntry = () => {
  const ctx = useContext(RetentionContext);
  const profileId = localStorage.getItem('profileId');
  const { locationName } = ctx;

  const { data, loading } = useQuery(GET_PULSE_BY_LOCATION, {
    variables: { employerProfileId: Number(profileId) },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const locationData = data?.getPulseByLocation;

  const chartRatings = useMemo(
    () =>
      locationData?.ratings?.map(
        (rating) => rating.scoreCount || 0,
      ),
    [locationData?.ratings],
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <EmployerDetailContainer
      renderAsideResources={asideResources}
      classes={{}}
    >
      <Box>
        <StyledContainer>
          <LocationDetailContainer
            locationName={locationName}
            renderLocationSnapshot={() => [
              locationData ? (
                <MetricContainer
                  title="Interaction Score"
                  data={
                    <>
                      {locationData?.interaction || 0}
                      <sup>%</sup>
                    </>
                  }
                  classes={{ metricDataOverride: 'interaction' }}
                />
              ) : (
                <Spinner />
              ),
              <MetricContainer
                title="Active Employees"
                data={locationData?.employeeCount || 0}
                classes={{ metricDataOverride: 'active__emps' }}
              />,
              <VerticalBarChart
                label="Employee Mood"
                height={80}
                width={125}
                data={chartRatings}
                chartLabels={chartRatings}
                hasChartLabels={{
                  x: true,
                  y: false,
                }}
              />,
            ]}
            renderSnapshotChart={() => <div>Snapshot chart</div>}
          />
        </StyledContainer>
        <MainSectionContainer>
          <PulseContent />
        </MainSectionContainer>
      </Box>
    </EmployerDetailContainer>
  );
};
export default LocationPulseEntry;
