const ResourceTypeTemplateBackground = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 205.41 134.46"
  >
    <rect
      x=".5"
      y=".5"
      width="204.41"
      height="133.46"
      rx="6.57"
      ry="6.57"
      fill="#141315"
      stroke="#8783be"
      strokeMiterlimit="10"
    />
    <rect
      x="25.28"
      y="29.22"
      width="75.18"
      height="35.48"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25.28"
      y="69.76"
      width="55.71"
      height="3.31"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25.28"
      y="76.24"
      width="55.71"
      height="3.31"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25.28"
      y="82.72"
      width="55.71"
      height="3.31"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25.28"
      y="89.19"
      width="55.71"
      height="3.31"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25.28"
      y="95.67"
      width="55.71"
      height="3.31"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="25.28"
      y="19.6"
      width="55.71"
      height="6.46"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <line
      x1="104.68"
      y1="27.53"
      x2="17.39"
      y2="68.64"
      fill="none"
      stroke="#141315"
      strokeMiterlimit="10"
    />
    <line
      x1="17.11"
      y1="26.12"
      x2="104.4"
      y2="67.23"
      fill="none"
      stroke="#141315"
      strokeMiterlimit="10"
    />
    <rect
      x="146.07"
      y="29.5"
      width="33.79"
      height="16.33"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="146.07"
      y="50.62"
      width="33.79"
      height="16.33"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="146.07"
      y="71.73"
      width="33.79"
      height="16.33"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="146.07"
      y="92.85"
      width="33.79"
      height="16.33"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="24.9"
      y="105.05"
      width="15.02"
      height="7.13"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
    <rect
      x="44.05"
      y="105.05"
      width="15.02"
      height="7.13"
      fill="#fff"
      opacity=".05"
      strokeWidth="0"
    />
  </svg>
);

export default ResourceTypeTemplateBackground;
