import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  memo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { getRoutes, qaAttr } from 'utils';
import {
  useNotificationsSubscribe,
  useMatchedRoutes,
  useEmployerProfileQuery,
  useMediaQueryMatches,
} from 'hooks';
import { useAppStoreSelector } from 'store';
import { Box, styled, Popover } from 'components';
import {
  Button,
  ButtonBase,
  IconButton,
  LinkedAccountsSwitchPopup,
  LayoutViewController,
} from 'components/shared';
import { ConfirmationDialog } from 'components/dialogs';
import {
  MdAdd,
  MdArrowDropDown,
  MdFlashOn,
} from 'components/icons';
import { GET_JOB } from 'api';
import styles from 'styles/Dashboard/Header';
import ChatButton from './ChatButton';
import LogoLink from './LogoLink';
import ProfileLink from './ProfileLink';
import ReturnLink from './ReturnLink';
import LogoutButton from './LogoutButton';
import HeaderTemplate from './HeaderTemplate';
import FaqLink from './FaqLink';
import { ToggleTheme } from './ToggleTheme';

const ROUTES = getRoutes();

// eslint-disable-next-line react/prop-types
const EmployerHeader = ({ className }) => {
  // const client = useApolloClient();
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { subscribe: subscribeToNotifications, isNewMessage } =
    useNotificationsSubscribe({
      role: 'employer',
    });
  const applicantsDashboardLayout = useAppStoreSelector(
    (state) => state.applicantsDashboardLayout,
  );
  const setApplicantsDashboardLayout = useAppStoreSelector(
    (state) => state.setApplicantsDashboardLayout,
  );
  const employerJobsFilter = useAppStoreSelector(
    (state) => state.employerJobsFilter,
  );
  const setEmployerJobsFilter = useAppStoreSelector(
    (state) => state.setEmployerJobsFilter,
  );

  const { profile, canPostJob, subscriptionInfo } =
    useEmployerProfileQuery();
  const { allowJobPosting } = profile || {};
  const { planName } = subscriptionInfo || {};

  const [fetchJob, { data: jobData }] = useLazyQuery(GET_JOB, {
    cachePolicy: 'cache-only',
    errorPolicy: 'all',
  });
  const employerJobDetails = jobData?.job || {};

  const [jobsFilterAnchor, setJobsFilterAnchor] = useState(null);
  const [mobileSelectorAnchor, setMobileSelectorAnchor] =
    useState(null);
  const [jobPostingAlertOpen, setJobPostingAlertOpen] =
    useState(false);

  const {
    isEmployerDashboardPage,
    isEmployerApplicantsSearchPage,
    isEmployerSchedulePage,
    isEmployerProfilePage,
    isEmployerPlansPage,
    isEmployerGrowPage,
    isEmployerJobsPage,
    isEmployerJobPage,
    isEmployerNewJobPage,
    isEmployerEditJobPage,
    isApprovedApplicantsPage,
    isStarredApplicantsPage,
    isRejectedApplicantsPage,
    isNextStepsApplicantsPage,
    isEmployerRetentionPage,
    isEmployerOnboardingPage,
  } = useMatchedRoutes();
  const isJobPostingPage =
    isEmployerNewJobPage || isEmployerEditJobPage;

  const isJobApplicantsPage =
    isEmployerJobPage ||
    isApprovedApplicantsPage ||
    isStarredApplicantsPage ||
    isRejectedApplicantsPage ||
    isNextStepsApplicantsPage;

  useEffect(() => {
    subscribeToNotifications();
  }, []);

  useEffect(() => {
    if (isEmployerJobPage)
      fetchJob({ variables: { jobId: Number(idParam) } });
  }, [isEmployerJobPage]);

  const handleJobPostClick = () => {
    if (allowJobPosting) navigate(ROUTES.employer.job_new);
    // eslint-disable-next-line no-use-before-define
    else openJobPostingAlert();
  };

  const openJobsFilter = useCallback(
    (e) => setJobsFilterAnchor(e.currentTarget),
    [],
  );
  const closeJobsFilter = useCallback(
    () => setJobsFilterAnchor(null),
    [],
  );

  const openMobileSelector = useCallback(
    (e) => setMobileSelectorAnchor(e.currentTarget),
    [],
  );
  const closeMobileSelector = useCallback(
    () => setMobileSelectorAnchor(null),
    [],
  );

  const openJobPostingAlert = useCallback(
    () => setJobPostingAlertOpen(true),
    [],
  );
  const closeJobPostingAlert = useCallback(
    () => setJobPostingAlertOpen(false),
    [],
  );

  const selectJobsFilterOption = (e, value) => {
    setEmployerJobsFilter(value);
    closeJobsFilter();
  };

  const renderLogo = (logoProps) => (
    <LogoLink key="header-logo" {...logoProps} />
  );

  const renderLogoutButton = () => (
    <LogoutButton key="header-logout" />
  );

  const renderTryPremiumButton = () => (
    <Button
      key="header-premium"
      isRouterLink
      to={ROUTES.employer.plans}
      variant="outlined-secondary"
      startIcon={<MdFlashOn />}
      className="premiumBtn headerElement"
      testID="try-premium-button"
    >
      Try Premium
    </Button>
  );

  const renderChatButton = () => (
    <ChatButton
      key="header-chat"
      userRole="employer"
      isNewMessage={isNewMessage}
    />
  );

  const renderProfileButton = () => (
    <ProfileLink key="header-profile" userRole="employer" />
  );
  const renderFaqButton = () => (
    <FaqLink key="header-faq" userRole="employer" />
  );
  const renderThemeToggle = () => <ToggleTheme />;

  const renderPostJobButton = () => (
    <Button
      key="header-job-post"
      disabled={!canPostJob}
      variant="filled-primary"
      startIcon={<MdAdd />}
      className="postJobBtn headerElement"
      onClick={handleJobPostClick}
      testID="post-job-button"
    >
      Post a Job
    </Button>
  );

  const renderMobilePostJobButton = () => (
    <div
      key="header-job-post"
      className="mobilePostJobBtnContainer"
    >
      {/* {isInitialEmployerUiTipVisible && renderInitialEmployerUiTip(false)} */}
      <IconButton
        disabled={!canPostJob}
        variant="filled-primary"
        aria-label="post a job"
        className="mobilePostJobBtn"
        onClick={handleJobPostClick}
        testID="post-job-button"
      >
        <MdAdd style={{ fontSize: 30 }} />
      </IconButton>
    </div>
  );

  const renderBackButton = (title = '', btnProps = {}) => (
    <ReturnLink key="header-back" title={title} {...btnProps} />
  );

  const renderJobsFilter = () => (
    <Fragment key="jobsFilter">
      <Button
        variant="text"
        endIcon={<MdArrowDropDown color="inherit" />}
        className="jobsFilterBtn headerElement"
        onClick={openJobsFilter}
        testID="jobs-dashboard-dropdown-button"
      >
        {employerJobsFilter === 0 ? 'My Jobs' : 'Archived Job'}
      </Button>
      <Popover
        open={!!jobsFilterAnchor}
        anchorEl={jobsFilterAnchor}
        classes={{ paper: 'optionsPopupPaper' }}
        anchorOrigin={{
          vertical: 30,
          horizontal: isDesktop ? 'left' : 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isDesktop ? 'left' : 'center',
        }}
        disablePortal
        onClose={closeJobsFilter}
      >
        <ButtonBase
          className="optionsPopupBtn"
          onClick={(e) => selectJobsFilterOption(e, 0)}
          {...qaAttr('my-jobs-dropdown-option-button')}
        >
          {isDesktop ? 'My Jobs' : 'Current Jobs'}
        </ButtonBase>
        <ButtonBase
          className="optionsPopupBtn"
          onClick={(e) => selectJobsFilterOption(e, 1)}
          {...qaAttr('archived-jobs-dropdown-option-button')}
        >
          Archived Jobs
        </ButtonBase>
      </Popover>
    </Fragment>
  );

  const renderJobDetailSubPageSelector = () => {
    const getCurrentSelectedPage = () => {
      if (isEmployerJobPage) return 'Applicants';
      if (isApprovedApplicantsPage) return 'Approved';
      if (isRejectedApplicantsPage) return 'Rejected';
      if (isStarredApplicantsPage) return 'Starred';
      if (isNextStepsApplicantsPage) return 'Next Steps';
      return '';
    };
    const linkProps = {
      isRouterLink: true,
      onClick: closeMobileSelector,
      className: 'selectorBtn',
    };
    const routesWithId = getRoutes({ id: idParam });

    return (
      <Box
        key="jobDetailSubPageSelector"
        display="flex"
        justifyContent="center"
        flex={1}
      >
        <Button
          endIcon={<MdArrowDropDown color="inherit" />}
          className="selectorTriggerBtn"
          onClick={openMobileSelector}
          testID="job-dashboard-mobile-dropdown"
        >
          {getCurrentSelectedPage()}
        </Button>
        <Popover
          open={!!mobileSelectorAnchor}
          anchorEl={mobileSelectorAnchor}
          classes={{ paper: 'selectorPopupPaper' }}
          anchorOrigin={{
            vertical: 30,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disablePortal
          onClose={closeMobileSelector}
        >
          <Button
            to={routesWithId.employer.job}
            {...linkProps}
            testID="dropdown-applicants-link"
          >
            Applicants
          </Button>
          {/*<Button to={`/employer-dashboard/${idParam}/match`} {...linkProps}>Quick Hire Match</Button>*/}
          <Button
            to={routesWithId.employer.job_approved}
            {...linkProps}
            testID="dropdown-approved-link"
          >
            {employerJobDetails?.webUrl ? 'Approved' : 'Hired'}
          </Button>
          <Button
            to={routesWithId.employer.job_rejected}
            {...linkProps}
            testID="dropdown-rejected-link"
          >
            Rejected
          </Button>
          <Button
            to={routesWithId.employer.job_next_steps}
            {...linkProps}
            testID="dropdown-next-step-link"
          >
            Next Steps
          </Button>
          <Button
            to={routesWithId.employer.job_starred}
            {...linkProps}
            testID="dropdown-starred-link"
          >
            Starred
          </Button>
        </Popover>
      </Box>
    );
  };

  const renderEmployersSwitchPopup = () => (
    <div key="adminSwitcher" className="headerElement">
      <LinkedAccountsSwitchPopup />
    </div>
  );

  const renderPlansPageTitle = () => (
    <span>
      Plans: Current Plan -{' '}
      <Box component="span" color="common.white">
        {planName}
      </Box>
    </span>
  );

  const renderHomePageLayout = (params) => {
    const left = isDesktop
      ? [renderLogo()]
      : [renderLogoutButton()];
    const right = isDesktop
      ? [
          renderTryPremiumButton(),
          renderEmployersSwitchPopup(),
          renderFaqButton(),
          renderChatButton(),
          renderProfileButton(),
        ]
      : [renderProfileButton(), renderChatButton()];

    return (
      <HeaderTemplate
        leftElements={left}
        rightElements={right}
        {...params}
      />
    );
  };

  const renderContent = () => {
    const commonLeftElements = [
      renderBackButton('Dashboard', {
        isRouterLink: true,
        to: ROUTES.employer.enterprise,
      }),
    ];
    const commonRightElements = isDesktop
      ? [
          // renderTryPremiumButton(),
          renderEmployersSwitchPopup(),
          renderLogoutButton(),
          renderFaqButton(),
          renderChatButton(),
          renderProfileButton(),
        ]
      : [renderChatButton(), renderLogoutButton()];

    switch (true) {
      case isEmployerDashboardPage:
        return renderHomePageLayout({
          title: 'Dashboard',
          titleQaId: 'enterprise-dashboard-title',
        });
      case isEmployerProfilePage: {
        const right = isDesktop
          ? [
              // renderTryPremiumButton(),
              renderEmployersSwitchPopup(),
              renderLogoutButton(),
              renderThemeToggle(),
              renderFaqButton(),
              renderChatButton(),
              renderProfileButton(),
            ]
          : [renderProfileButton(), renderChatButton()];
        return (
          <HeaderTemplate
            title="Account"
            titleQaId="employer-profile"
            leftElements={commonLeftElements}
            rightElements={right}
          />
        );
      }
      case isEmployerSchedulePage: {
        return (
          <HeaderTemplate
            title="Scheduling"
            titleQaId="employer-schedule"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isEmployerGrowPage: {
        return (
          <HeaderTemplate
            title="WT Grow"
            titleQaId="wt-grow"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isEmployerRetentionPage: {
        return (
          <HeaderTemplate
            title="WT Retention"
            titleQaId="wt-retention"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isEmployerOnboardingPage: {
        return (
          <HeaderTemplate
            title="Onboarding"
            titleQaId="wt-onboarding"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isEmployerApplicantsSearchPage: {
        return (
          <HeaderTemplate
            title={
              isDesktop
                ? 'Search Candidate Database'
                : 'Search Candidates'
            }
            titleQaId="search-database"
            leftElements={commonLeftElements}
            rightElements={commonRightElements}
          />
        );
      }
      case isEmployerJobsPage: {
        const center = isDesktop ? [renderJobsFilter()] : [];
        const right = isDesktop
          ? [
              // renderTryPremiumButton(),
              renderPostJobButton(),
              renderEmployersSwitchPopup(),
              renderFaqButton(),
              renderChatButton(),
              renderProfileButton(),
            ]
          : [renderProfileButton(), renderChatButton()];

        return (
          <HeaderTemplate
            title={isDesktop ? '' : 'Jobs'}
            titleQaId={isDesktop ? '' : 'jobs'}
            leftElements={commonLeftElements}
            rightElements={right}
            centerElements={center}
          />
        );
      }
      case isJobApplicantsPage: {
        const left = [
          renderBackButton('Jobs', {
            isRouterLink: true,
            to: ROUTES.employer.jobs,
          }),
        ];
        let right = [
          renderFaqButton(),
          renderChatButton(),
          renderProfileButton(),
        ];
        if (!isDesktop) {
          if (isEmployerJobPage) {
            right = (
              <LayoutViewController
                value={applicantsDashboardLayout}
                onChange={setApplicantsDashboardLayout}
              />
            );
          } else {
            right = [renderProfileButton(), renderChatButton()];
          }
        }
        const center = isDesktop
          ? []
          : [renderJobDetailSubPageSelector()];

        return (
          <HeaderTemplate
            title={
              isDesktop && employerJobDetails?.title
                ? `Applicants to '${
                    employerJobDetails?.title || ''
                  }'`
                : ''
            }
            titleQaId="applicants-to"
            leftElements={left}
            rightElements={right}
            centerElements={center}
          />
        );
      }
      case isJobPostingPage: {
        const left = [
          renderBackButton('Jobs', {
            isRouterLink: true,
            to: ROUTES.employer.jobs,
          }),
        ];
        const right = isDesktop
          ? [
              renderTryPremiumButton(),
              renderLogoutButton(),
              renderFaqButton(),
              renderChatButton(),
            ]
          : [renderChatButton(), renderLogoutButton()];
        return (
          <HeaderTemplate
            title="Post a Job"
            titleQaId="post-job"
            leftElements={left}
            rightElements={right}
          />
        );
      }
      case isEmployerPlansPage: {
        const left = [
          renderBackButton('Jobs', {
            isRouterLink: true,
            to: ROUTES.employer.jobs,
          }),
        ];
        const right = isDesktop
          ? [
              renderFaqButton(),
              renderChatButton(),
              renderProfileButton(),
            ]
          : [renderProfileButton(), renderChatButton()];
        return (
          <HeaderTemplate
            title={planName ? renderPlansPageTitle() : 'Plans'}
            titleQaId="employer-plans"
            leftElements={left}
            rightElements={right}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={className}>
      {renderContent()}
      {isEmployerJobsPage && renderMobilePostJobButton()}

      <ConfirmationDialog
        isOpen={jobPostingAlertOpen}
        message={
          <Box textAlign="center">
            <p>Please upgrade your account</p>
          </Box>
        }
        confirmBtnName="Ok"
        titleProps={{ style: { padding: '0 8px' } }}
        headerProps={{ alignItems: 'flex-start' }}
        onConfirm={closeJobPostingAlert}
        onClose={closeJobPostingAlert}
        onCancel={closeJobPostingAlert}
      />
    </div>
  );
};

const StyledHeader = styled(EmployerHeader)(styles);

export default memo(StyledHeader);
