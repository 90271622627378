import {
  ChartConfiguration,
} from 'chart.js';

export interface IChartGridProps {
  x: boolean;
  y: boolean;
}

type chartType = 'doughnut' | 'bar' | 'line';
export interface IChartBaseProps {
  label: string;
  height: number;
  width: number;
  chartLabels: string[];
  hasChartGrid?: IChartGridProps;
  hasChartLabels?: IChartGridProps;
  data: number[];
  isStacked?: boolean;
}

export interface ICustomChartConfig {
  chartTypeConfigOptions?: { [key: string]: unknown };
  // This can be a slew of things, ftw ANY!
  chartTypePlugins?: unknown;
}

export interface IChartProps
  extends Omit<IChartBaseProps, 'data'> {
  type: chartType;
  styles?: { [key: string]: unknown };
  chartConfig?: ICustomChartConfig;
}

export interface IChartColors {
  backgroundColor: Array<string>;
  hoverBackgroundColor: Array<string>;
}

export interface IChartDataset {
  data: number[];
  hoverOffset: number;
  label?: string;
  backgroundColor: string[];
  hoverBackgroundColor?: string[];
  borderColor?: string[];
  borderWidth?: number;
}

export interface IBuildChartConfig {
  chartConfig?: ICustomChartConfig;
  chartLabels: string[];
  hasChartGrid: IChartGridProps;
  hasChartLabels: IChartGridProps;
  isStacked?: boolean;
  type: chartType;
  datasets: Array<IChartDataset>;
}

export const buildChartConfig = ({
  chartConfig: customChartConfig = {
    chartTypeConfigOptions: {},
    chartTypePlugins: [],
  },
  chartLabels,
  hasChartLabels,
  hasChartGrid,
  isStacked = false,
  type,
  datasets,
}: IBuildChartConfig) => {
  const {
    // additional time for ts safety :\
    chartTypeConfigOptions = {},
    chartTypePlugins = [],
  } = customChartConfig;
  const theme = localStorage.getItem('colorMode');
  const isDarkMode = theme === 'dark';

  return {
    type,
    data: {
      labels: chartLabels,
      datasets,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          stacked: isStacked,
          beginAtZero: true,
          grid: {
            display: hasChartGrid?.y,
            drawBorder: hasChartGrid?.y,
            // Keep this, how to give it a threshold line
            // color: (ctx) => {
            //   if(ctx.tick.value === 12) {
            //     return 'rgba(0, 255, 0, 1)'
            //   } else {
            //     return 'rgba(0, 0, 0, 0.5)'
            //   }
            // }
          },
          ticks: {
            display: hasChartLabels?.y,
            stepSize: () =>
              // can leverage this to make it more dynamic
              3,
            color: isDarkMode ? '#fff' : '#444',
          },
        },
        x: {
          beginAtZero: true,
          stacked: isStacked,
          grid: {
            display: hasChartGrid?.x,
            drawBorder: hasChartGrid?.x,
            tickLength: 2,
          },
          ticks: {
            display: hasChartLabels?.x,
            color: isDarkMode ? '#fff' : '#444',
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        // custom plugin
        fillDonutHole: {
          backgroundColor: isDarkMode
            ? 'rgba(32, 30, 72, 0.5)'
            : '#fff',
        },
      },
      ...chartTypeConfigOptions,
    },
    plugins: [...chartTypePlugins],
  } as ChartConfiguration;
};

export const baseChartStyles = () => ({
  '& .defaultChartTitle': {
    color: '#9D7FE6',
    fontSize: 15,
    display: 'inline-block',
  },
});

/*
Use Case below
Types above have the props that each of these can consume.
<DonutChart
  chartLabels={['Jan', 'Feb', 'Mar']}
  data={[300, 50, 100]}
  label={'Chart Label - 1'}
  height={250}
  width={250}
/>
<LineChart
  chartLabels={['Jan', 'Feb', 'Mar', 'Apr', 'May']}
  data={[2, 1, 3, 5, 4]}
  label={'Chart Label - 3'}
  height={50}
  width={100}
  hasChartGrid={{
    x: false,
    y: true,
  }}
/>
<VerticalBarChart
  chartLabels={['Jan', 'Feb', 'Mar', 'Apr', 'May']}
  data={[1, 2, 3, 4, 5]}
  label={'Chart Label - 2'}
  height={50}
  width={100}
/>
*/
