import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { GET_EMPLOYMENT_SALARY } from 'api';
import PageHeader from '../../components/Employee/PageHeader';
import StateSalaryCard from '../../components/Employee/salary/StateSalaryCard';
import TopCareerCard from '../../components/Employee/salary/TopCareerCard';
import PriceChangeIcon from "@mui/icons-material/PriceChange";

const StyledRoot = styled('div')(({ theme }) => ({
  '&.salaryPage': {
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: 24
    }
  },
  '& .salaryPage__content': {
    paddingTop: 15
  },
  '& .salary-yourState': {
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 40,
      padding: '0 20px',
      display: 'block'
    },
    '& .salary-yourState__title': {
      display: 'none',
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 10,
        display: 'block',
        fontSize: 16,
        fontWeight: 800,
        lineHeight: '150%'
      }
    },
    '& span': {
      color: theme.palette.text.primary,
      '&:nth-of-type(1)': {
        fontSize: 40,
        [theme.breakpoints.down('desktopApp')]: {
          width: '100%',
          marginBottom: 12,
          display: 'block',
          fontSize: 31,
          fontWeight: 700,
          lineHeight: '130%'
        }
      },
      '&:nth-of-type(2)': {
        fontSize: 40,
        color: theme.palette.primary.light,
        padding: '0 15px',
        [theme.breakpoints.down('desktopApp')]: {
          display: 'none'
        }
      },
      '&:nth-of-type(3)': {
        fontSize: 20
      },
      '&:nth-of-type(4)': {
        color: theme.palette.quaternary.main,
        fontSize: 20,
        marginLeft: 7,
        [theme.breakpoints.down('desktopApp')]: {
          color: theme.palette.common.white
        }
      },
      '&:nth-of-type(3), &:nth-of-type(4)': {
        [theme.breakpoints.down('desktopApp')]: {
          fontSize: 20,
          fontWeight: 700,
          lineHeight: '130%'
        }
      }
    }
  },
  '& .salary-statePay': {
    marginBottom: 15,
    padding: '0 20px',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 40
    },
    '& .sectionTitle': {
      marginBottom: 15,
      '& span': {
        color: theme.palette.success.main
      },
      [theme.breakpoints.down('desktopApp')]: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '130%'
      }
    },
    '&.best': {
      '& .sectionTitle': {
        '& span': {
          color: theme.palette.success.main
        }
      }
    },
    '&.worst': {
      '& .sectionTitle': {
        '& span': {
          color: theme.palette.error.main
        }
      }
    }
  },
  '& .salary-statesContainer': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 16,
    [theme.breakpoints.down('desktopApp')]: {
      gridTemplateColumns: '1fr'
    }
  },
  '& .salary-growingCareersContainer': {
    '& .sectionTitle': {
      marginBottom: 15,
      [theme.breakpoints.down('desktopApp')]: {
        display: 'none'
      },
      '& span': {
        margin: '7px 0',
        display: 'block',
        fontSize: 9
      }
    }
  },
  '& .salary-growingCareers': {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 16,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 40,
      gridTemplateColumns: '1fr'
    }
  }
}));

const Salary = () => {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [fetchSalary, { data }] = useLazyQuery(GET_EMPLOYMENT_SALARY, {
    fetchPolicy: 'cache-and-network'
  });
  const {
    bottomStates = [],
    growingCareers = [],
    myState = {},
    topStates = []
  } = data?.employmentSalary || {};
  const { payAmount, payLabel, state } = myState;

  useEffect(() => {
    fetchSalary();
  }, []);

  const roundPayment = (payment) => {
    if (typeof payment === 'number') return payment.toFixed(2);
    return 0;
  };

  return (
    <StyledRoot className="salaryPage">
      {isDesktop && (
        <PageHeader
          title="SALARY INSIGHTS"
          subtitle="State by State Pay"
          icon={<PriceChangeIcon />}
        />
      )}
      <div className="salaryPage__content">
        <div className="salary-yourState">
          <h3 className="salary-yourState__title">State by state pay</h3>
          <div>
            <span>{state || 'N/A'}</span>
            <span>|</span>
            {payLabel && <span>{payLabel} pay - </span>}
            <span>{`$${roundPayment(payAmount)}`}</span>
          </div>
        </div>
        <div className="salary-statePay best">
          <h3 className="salary-statePay__title sectionTitle light">
            States that Pay the <span>Best</span>
          </h3>
          <div className="salary-statesContainer">
            {topStates.map((obj) => (
              <StateSalaryCard
                key={`topState__${obj.state}`}
                state={obj.state}
                rate={obj.percent}
                hourSalary={roundPayment(obj.payAmount)}
                isAboveAvg={obj.payLabel === 'Above Average'}
              />
            ))}
          </div>
        </div>
        <div className="salary-statePay worst">
          <h3 className="salary-statePay__title sectionTitle light">
            States that Pay the <span>Worst</span>
          </h3>
          <div className="salary-statesContainer">
            {bottomStates.map((obj) => (
              <StateSalaryCard
                key={`bottomState__${obj.state}`}
                state={obj.state}
                rate={obj.percent}
                hourSalary={roundPayment(obj.payAmount)}
                isAboveAvg={obj.payLabel === 'Above Average'}
              />
            ))}
          </div>
        </div>
        <div className="salary-growingCareersContainer">
          <h3 className="sectionTitle light">
            Top 4 growing Careers <span>*In similar Industries</span>
          </h3>
          <div className="salary-growingCareers">
            {growingCareers.map((career) => (
              <TopCareerCard
                key={`careerCard__${career.jobName}`}
                position={career.jobName}
                min={career.lowPay || 0}
                max={career.highPay || 0}
                avg={career.avgPay || 0}
                jobCount={career.jobCount || 0}
              />
            ))}
          </div>
        </div>
      </div>
    </StyledRoot>
  );
}

export default Salary;
