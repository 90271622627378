import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { styled } from 'components';
import { withEmployeeJobActions } from 'hocs';
import styles from 'styles/Dashboard/EmployeeJob';
import { Button } from 'components/shared';
import { getRoutes } from 'utils';
import { useAuth } from 'hooks';
import { GET_SCHEDULE_CONFIRMATION,
  GET_JOB_SCHEDULE,
  GET_JOB,
  CONFIRM_SCHEDULE } from 'api';
import { useQuery, useMutation } from '@apollo/client';
import format from 'date-fns/format';
import { useAppStoreSelector } from 'store';

const ROUTES = getRoutes();

const StyledRoot = styled('div')(({ theme }) => ({
   ...styles({theme}),
  '& .actionsContainer': {
     textAlign: 'center'
  },
  '& .jobConfirmationDetails': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 20,
    [theme.breakpoints.down('desktopApp')]: {
      height: 'calc(60vh - 100px)'
    },
    '& h2': {
      marginBottom: 10
    },
    '& p': {
      marginBottom: 20
    },
    '& .confirmMsg': {
      textAlign: 'center',
      marginBottom: 5,
      '& h3': {
        textAlign: 'center',
        marginBottom: 20,
      }
    },
    '& .confirmationButtons': {
      display: 'inline',
      textAlign: 'center',
      marginTop: 20,
      '& Button': {
        [theme.breakpoints.down('desktopApp')]: {
          marginTop: 10,
        },
      }
    }
  },
}));

const enhance = (Component) => withEmployeeJobActions(Component);

const InterviewConfirmation = () => {
  const { confirmationId } = useParams();
  const { authed: isAuthenticated } = useAuth();
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const todayDate = new Date();

  const setEmployeeJobsTab = useAppStoreSelector((state) => state.setEmployeeJobsTab);

  if(!isAuthenticated){
    navigate(ROUTES.login.default, {
      state: { from: { location: routerLocation } }
    });
  }

  if(!confirmationId){
    navigate(getRoutes().employee.dashboard);
  }

  const { data: getSchedData, errors } = useQuery(
    GET_SCHEDULE_CONFIRMATION,
    {
      variables: { confirmationCode: confirmationId },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });

  const isValidConf = !!getSchedData?.getScheduleStatusConfirmation;
  const isActiveConf = !!getSchedData?.getScheduleStatusConfirmation?.active;
  const confId = ( isValidConf && isActiveConf)  && getSchedData?.getScheduleStatusConfirmation?.id;

  const { data: jobScheduleData } = useQuery(
    GET_JOB_SCHEDULE, {
      variables: { confirmId: confId },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
  });

  const interviewDate = (isValidConf && jobScheduleData) ? new Date(jobScheduleData?.getJobAndSchedule?.interviewDate) : '';
  const jobId = ( isValidConf ) && jobScheduleData?.getJobAndSchedule?.jobId;

  const { data: jobData } = useQuery(GET_JOB, {
    variables: { jobId },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });
  const [ confirmInvSchedule ] = useMutation(CONFIRM_SCHEDULE);

  const jobTitle =  (isValidConf && jobData) ? jobData?.job?.title : 'N.A.';
  const jobEmployer = (isValidConf && jobData) ? jobData?.job?.employerProfile.name : 'N.A.';

  const handleConfirmation = async (statusId) => {
    const confResult = await confirmInvSchedule( {
      variables: {
        employeeToScheduleTimesId: getSchedData?.getScheduleStatusConfirmation?.scheduleId,
        employeeId: getSchedData?.getScheduleStatusConfirmation?.employeeProfileId,
        jobId,
        statusId,
        confirmId: confId
      }
    });
    if(confResult && confResult?.data?.confirmEmployeeToScheduleTimes?.interviewDate){
      if(statusId===2 && jobId && jobId!==0){
        navigate(getRoutes({ id: jobId }).employee.job);
      } else {
        setEmployeeJobsTab(1);
        navigate(getRoutes().employee.dashboard);
      }
    }
  };

  const renderTitleAndTime = () => (
    <>
      <div className="confirmMsg">
        <h3>Please confirm if you will be able to attend your scheduled interview.</h3>
      </div>
      <h2>{jobTitle} at {jobEmployer}</h2>
      <h3 className="interviewDateConfirm">
        {format(interviewDate, "'Interview Scheduled:' MM-dd-yyyy '@' hh:mm aaa")}
      </h3>
    </>
  );

  const renderConfirmation = () => (
    <div className="actionsContainer">
      {!isValidConf && (
        <div className="jobConfirmationDetails">
          <h2>Invalid Confirmation Code</h2>
          <p><b>That confirmation code is invalid. Please make sure the URL you clicked is complete.</b></p>
          {errors && (
            <p>Error: {errors[0].message}</p>
          )}
        </div>
      )}
      {!isActiveConf && isValidConf && (
        <div className="jobConfirmationDetails">
          <h2>That Confirmation Code is no longer active.</h2>
          <p><b>Please make sure the Confirmation Code being used is correct. You may need to go to your My Jobs page and reschedule your interview!</b></p>
        </div>
      )}
      {isValidConf && isActiveConf && interviewDate && interviewDate>todayDate && (
        <div className="jobConfirmationDetails">
          { renderTitleAndTime() }
          <div className="confirmationButtons">
            <Button
              variant="filled-primary"
              className="applyBtn"
              onClick={() => handleConfirmation(1)}
            >
              Confirm Interview
            </Button>
            <Button
              variant="filled-primary"
              className="applyBtn secondaryActionWrapper"
              onClick={() => handleConfirmation(3)}
            >
              Cancel Interview
            </Button>
            <Button
              variant="filled-primary"
              className="applyBtn secondaryActionWrapper"
              isRouterLink={false}
              onClick={() => handleConfirmation(2)}
            >
              Re-Schedule Interview
            </Button>
          </div>
        </div>
      )}
      {isValidConf && interviewDate<todayDate && (
        <div className="jobConfirmationDetails">
          <h2>The interview for {jobTitle} at {jobEmployer} has already passed!</h2>
          <p><b>Please go to your My Jobs page and reschedule your interview!</b></p>
        </div>
      )}
    </div>
  );

  return (
    <StyledRoot className="container">
      <div className="content">
        <div className="jobContent">
          {renderConfirmation()}
        </div>
      </div>
    </StyledRoot>
  );
}

InterviewConfirmation.propTypes = {};
InterviewConfirmation.defaultProps = {};

export default enhance(InterviewConfirmation);
