import React, { useState, useEffect } from 'react';
import {
  Outlet,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  getLocaleTimeZone,
  getRoutes,
  employerStartPage,
  saveRequiredUserValues,
} from 'utils';
import getAgeByBirthDate from 'utils/getAgeByBirthDate';
import { group, identify } from 'utils/segmentAnalytics';
import {
  useTimezoneCommands,
  useAuth,
  useMatchedRoutes,
  useEmployeeProfileQuery,
} from 'hooks';
import { Spinner } from 'components/shared';
import { trackUserOriginByUrlParam } from 'api';
import { PrivateLayout } from 'containers';

// //
const ROUTES =


  getRoutes();

const ProtectedEmployeeRoute = () => {
  const { authed, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { saveTimezone } = useTimezoneCommands();
  const { isPublicRoute } = useMatchedRoutes();
  const { fetch: fetchEmployeeProfile } =
    useEmployeeProfileQuery({ autoFetch: false });

  const [isUserRoleChecked, setIsUserRoleChecked] =
    useState(false);

  const initUserInSegment = (profile) => {
    identify({
      name: profile?.name,
      email: profile?.email,
      profileType: 'employee',
    });
    group(
      {
        gender: profile?.gender,
        age: getAgeByBirthDate(profile?.birth_date),
        race: profile?.race,
        zip: profile?.zip,
        profileComplete: profile?.profileComplete,
        skills: profile?.skills,
        industry: profile?.industry,
      },
      'employee',
    );
  };

  const checkProfileByRole = async () => {
    const { data } = await fetchEmployeeProfile();
    const profile = data?.employeeProfile;

    if (profile) {
      const { profileWizard } = profile;
      // !!! unlike employer, employee can't change timezone in profile settings, so we save current user timezone on every login
      saveTimezone(getLocaleTimeZone(), 'employee');
      initUserInSegment(profile);

      if (profileWizard) {
        saveRequiredUserValues(profile);
        if (idParam)
          trackUserOriginByUrlParam(
            location.search,
            Number(idParam),
          ); // track job id page with special param
        return { checked: true };
      }
      return {
        checked: false,
        cb: () => navigate(ROUTES.onboarding.default),
      };
    }

    return {
      checked: false,
      cb: !location?.state?.notEmployer
        ? // check if user is an employer if he wasn't checked before
          () =>
            navigate(employerStartPage, {
              state: { notEmployee: true },
            })
        : logout,
    };
  };

  const authMiddleware = async () => {
    if (authed) {
      const { checked, cb } = await checkProfileByRole();
      setIsUserRoleChecked(checked);
      if (cb) cb();
    } else {
      if (isPublicRoute) {
        // job is publicly available
        setIsUserRoleChecked(true);
        return;
      }
      navigate(ROUTES.login.default, {
        state: { from: { location } },
      });
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  const renderContent = () =>
    isUserRoleChecked ? (
      <PrivateLayout userRole="employee">
        <Outlet />
      </PrivateLayout>
    ) : (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner size={30} />
      </div>
    );

  return authed || isPublicRoute ? (
    renderContent()
  ) : (
    <Navigate to={ROUTES.login.default} />
  );
}

export default ProtectedEmployeeRoute;
