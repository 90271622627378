import { useEffect, useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_JOB } from 'api';

const FIELD_NAME = 'job';

export default function useJobQuery(props) {
  const { autoFetch = true, jobId, ...queryOptions } = props || {};

  const [fetch, result] = useLazyQuery(GET_JOB, {
    variables: { jobId: Number(jobId) },
    ...queryOptions
  });
  const getResultFields = useCallback((res) => res?.data?.[FIELD_NAME], []);
  const job = getResultFields(result);

  useEffect(() => {
    if (autoFetch) fetch({ ...queryOptions });
  }, [autoFetch]);

  return useMemo(
    () => ({
      fetch,
      job,
      fieldName: FIELD_NAME,
      getResultFields,
      ...result
    }),
    [fetch, getResultFields, job, result]
  );
}
