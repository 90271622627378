// eslint-disable-next-line import/no-extraneous-dependencies,import/no-import-module-exports
import 'regenerator-runtime/runtime';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-import-module-exports
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-import-module-exports
import { render } from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-import-module-exports
import '@fontsource/nunito-sans';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-import-module-exports
import App from './App';

if (module.hot) module.hot.accept();

render(<App />, document.getElementById('root'));
