import React, { memo } from 'react';

export interface IStepProps {
  children: React.ReactNode;
  renderProps?: (props: {
    next: () => void;
    prev: () => void;
  }) => JSX.Element;
  next: () => void;
  prev: () => void;
}
const Step = ({
  children,
  renderProps,
  next,
  prev,
}: IStepProps) => {
  if (renderProps != null) {
    return (
      <div className="stepBlock">
        {renderProps({ next, prev })}
      </div>
    );
  }
  return <div className="stepBlock">{children}</div>;
};

export default memo(Step);
