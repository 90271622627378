import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  ApolloError,
  OperationVariables,
  QueryResult,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import findIndex from 'lodash/findIndex';
import { getProfileId, getUserId, getDisplayName } from 'utils';
import { useJobTypesQuery } from 'hooks';
import {
  CREATE_CAREER_PATH,
  GET_ABILITIES_TYPES_BY_BADGE_ID,
  GET_CAREER_DEV_WITH_HEALTH_SCORE,
  GET_CAREER_PATH,
  GET_EMPLOYEE_RECENT_BADGE,
  GET_SKILLS_TYPES_BY_BADGE_ID,
  SAVE_INITIAL_CAREER_DEV,
  REQUEST_ABILITY_COMPLETION,
  REQUEST_SKILL_COMPLETION,
} from 'api';

const formatBoolean = (value) => {
  if (value == null) return false;
  if (typeof value === 'boolean') return value;
  if (value === 'true') return true;
  if (value === 'false') return false;

  return false;
};

export interface IWithEmployeeGrowProps {
  careerPath: {
    abilitiesBadges: unknown[];
    skillsBadges: unknown[];
    careerPathGroupItems: unknown[];
  };
  careerPathLoading: boolean;
  careerPathCalled: boolean;
  createCareerPath: (args: {
    startJobTypeId: number;
    endJobTypeId: number;
    profileId: number;
  }) => Promise<void>;
  createCareerPathLoading: boolean;
  careerDev: unknown;
  careerDevLoading: boolean;
  careerDevSaveLoading: boolean;
  careerDevSaveError: ApolloError;
  requestAbilityCompletion: (
    abilitiesBadgeId: number,
    complete?: boolean,
  ) => Promise<void>;
  requestSkillCompletion: (
    skillsBadgeId: number,
    complete?: boolean,
  ) => Promise<void>;
  getCareerDev: () => Promise<
    QueryResult<unknown, OperationVariables>
  >;
  getCareerPath: () => Promise<void>;
  getRecentBadges: () => void;
  recentBadges: {
    abilitiesBadges: unknown;
    skillsBadges: unknown;
  };
  saveCareerDev: (args: unknown) => Promise<void>;
}

const withEmployeeGrow = (
  WrappedComponent: React.FC<IWithEmployeeGrowProps>,
) => {
  const WithEmployeeGrow = (props) => {
    const [careerPath, setCareerPath] = useState({
      careerPathGroupItems: [],
      abilitiesBadges: [],
      skillsBadges: [],
    });

    const { getJobTypes, findJobTypeById } = useJobTypesQuery();

    const [
      postCareerDev,
      {
        loading: careerDevSaveLoading,
        error: careerDevSaveError,
      },
    ] = useMutation(SAVE_INITIAL_CAREER_DEV);

    const [
      postCareerPath,
      { loading: createCareerPathLoading },
    ] = useMutation(CREATE_CAREER_PATH);

    const [requestAbilityCompletionMutation] = useMutation(
      REQUEST_ABILITY_COMPLETION,
    );

    const [requestSkillCompletionMutation] = useMutation(
      REQUEST_SKILL_COMPLETION,
    );

    const [
      fetchCareerPath,
      {
        data: careerPathData,
        loading: careerPathLoading,
        called: careerPathCalled,
      },
    ] = useLazyQuery(GET_CAREER_PATH, {
      fetchPolicy: 'cache-and-network',
    });
    const originalCareerPath =
      careerPathData?.getCareerPathByEmployee || {
        careerPathGroupItems: [],
        abilitiesBadges: '',
        skillsBadges: '',
      };

    const [fetchAbilitiesTypesByBadge] = useLazyQuery(
      GET_ABILITIES_TYPES_BY_BADGE_ID,
    );
    const [fetchSkillsTypesByBadge] = useLazyQuery(
      GET_SKILLS_TYPES_BY_BADGE_ID,
    );

    const [
      fetchCareerDev,
      {
        data: careerDevData,
        loading: careerDevLoading,
        called: careerDevCalled,
      },
    ] = useLazyQuery(GET_CAREER_DEV_WITH_HEALTH_SCORE, {
      fetchPolicy: 'cache-and-network',
    });
    const careerDev = useMemo(
      () => careerDevData?.careerDevelopmentByUserId || {},
      [JSON.stringify(careerDevData)],
    );

    const [fetchRecentBadges, { data: recentBadgeData }] =
      useLazyQuery(GET_EMPLOYEE_RECENT_BADGE, {
        fetchPolicy: 'cache-and-network',
      });
    const originRecentBadges =
      recentBadgeData?.getMostRecentBadgeByEmployee;

    const parsedRecentBadges = useMemo(() => {
      const { abilitiesBadges = '', skillsBadges = '' } =
        originRecentBadges || {};
      return {
        abilitiesBadges: abilitiesBadges
          ? JSON.parse(abilitiesBadges)
          : [],
        skillsBadges: skillsBadges
          ? JSON.parse(skillsBadges)
          : [],
      };
    }, [JSON.stringify(originRecentBadges)]);

    const getCareerDev = useCallback(async () => {
      const uid = getUserId();

      if (uid) {
        return fetchCareerDev({
          variables: { userId: Number(uid) },
        });
      }
      throw new Error('getCareerDev: missed userId');
    }, [fetchCareerDev]);

    const getRecentBadges = useCallback(() => {
      const uid = getUserId();
      fetchRecentBadges({
        variables: { employeeUserId: Number(uid) },
      });
    }, [fetchRecentBadges]);

    const getCareerPath = useCallback(async () => {
      const pid = getProfileId();
      fetchCareerPath({
        variables: { employeeProfileId: Number(pid) },
      });
      getRecentBadges();
    }, [fetchCareerPath, getRecentBadges]);

    const requestAbilityCompletion = useCallback(
      async (abilityId) => {
        await requestAbilityCompletionMutation({
          variables: { abilityId },
        });
        const pid = getProfileId();
        await fetchCareerPath({
          variables: { employeeProfileId: Number(pid) },
        });
      },
      [fetchCareerPath, requestAbilityCompletionMutation],
    );

    const requestSkillCompletion = useCallback(
      async (skillId) => {
        await requestSkillCompletionMutation({
          variables: { skillId },
        });
        const pid = getProfileId();
        await fetchCareerPath({
          variables: { employeeProfileId: Number(pid) },
        });
      },
      [fetchCareerPath, requestSkillCompletionMutation],
    );

    const createCareerPath = useCallback(
      async ({ startJobTypeId, endJobTypeId, profileId }) => {
        await postCareerPath({
          variables: {
            startJobTypeId: Number(startJobTypeId),
            endJobTypeId: Number(endJobTypeId),
            profile_id: Number(profileId),
          },
        });
        getCareerPath();
      },
      [getCareerPath, postCareerPath],
    );

    const saveCareerDev = useCallback(
      async (args) => {
        const {
          isNew = false,
          curJobTypeId,
          futureJobTypeId,
          industryId,
          currentlySearching = false,
          showEmployerData = false,
        } = args;
        const pid = getProfileId();

        try {
          await postCareerDev({
            variables: {
              curJobTypeId: !Number(curJobTypeId)
                ? null
                : Number(curJobTypeId),
              futureJobTypeId: Number(futureJobTypeId),
              industryId: Number(industryId),
              currentlySearching,
              showEmployerData,
            },
          });
          getCareerDev();
          if (isNew && curJobTypeId && futureJobTypeId) {
            createCareerPath({
              startJobTypeId: curJobTypeId,
              endJobTypeId: futureJobTypeId,
              profileId: pid,
            });
          }
        } catch (error) {
          console.error('saveCareerDev: ', error);
        }
      },
      [postCareerDev, getCareerDev, createCareerPath],
    );

    const formatCareerPathData = async (
      careerPathDataToParse,
    ) => {
      const {
        careerPathGroupItems = [],
        abilitiesBadges = '',
        skillsBadges = '',
      } = careerPathDataToParse;
      const parsedAbilitiesBadges = abilitiesBadges
        ? JSON.parse(abilitiesBadges)
        : [];
      const parsedSkillsBadges = skillsBadges
        ? JSON.parse(skillsBadges)
        : [];
      const formattedCareerPathGroupItems = [
        ...careerPathGroupItems,
      ]
        .map((o) => ({
          ...o,
          active: formatBoolean(o.active),
          completed: formatBoolean(o.completed),
          abilitiesBadges: [],
          skillsBadges: [],
        }))
        .sort((a, b) => a.index - b.index);

      if (
        formattedCareerPathGroupItems?.[0] &&
        parsedAbilitiesBadges?.[0]?.abilitiesBadgeId === 1
      ) {
        // set default badge
        formattedCareerPathGroupItems[0] = {
          ...formattedCareerPathGroupItems[0],
          abilitiesBadges: [{ ...parsedAbilitiesBadges[0] }],
        };
      }

      if (parsedAbilitiesBadges.length) {
        await Promise.all(
          parsedAbilitiesBadges.map(async (abilBadge) => {
            const { data } = await fetchAbilitiesTypesByBadge({
              variables: {
                abilitiesBadgeId: Number(
                  abilBadge.abilitiesBadgeId,
                ),
              },
              fetchPolicy: 'cache-first',
            });
            const types = data?.getAbilitiesTypesByBadgeId || [];

            types.forEach((typeObj) => {
              console.log('typeObj');
              console.log(typeObj);
              const pathGroupIdx = findIndex(
                formattedCareerPathGroupItems,
                {
                  jobTypeId: typeObj.id,
                },
              );

              if (pathGroupIdx !== -1) {
                formattedCareerPathGroupItems[pathGroupIdx] = {
                  ...formattedCareerPathGroupItems[pathGroupIdx],
                  jobTypeName: typeObj.name,
                  abilitiesBadges: [
                    ...(formattedCareerPathGroupItems[
                      pathGroupIdx
                    ]?.abilitiesBadges || []),
                    { ...abilBadge },
                  ],
                };
              }
            });

            return {
              ...abilBadge,
              types: data?.getAbilitiesTypesByBadgeId || [],
            };
          }),
        );
      }
      if (parsedSkillsBadges.length) {
        await Promise.all(
          parsedSkillsBadges.map(async (skillBadge) => {
            const { data } = await fetchSkillsTypesByBadge({
              variables: {
                skillsBadgeId: Number(skillBadge.skillsBadgeId),
              },
              fetchPolicy: 'cache-first',
            });
            const types = data?.getSkillsTypesByBadgeId || [];

            types.forEach((typeObj) => {
              const pathGroupIdx = findIndex(
                formattedCareerPathGroupItems,
                {
                  jobTypeId: typeObj.id,
                },
              );

              if (pathGroupIdx !== -1) {
                formattedCareerPathGroupItems[pathGroupIdx] = {
                  ...formattedCareerPathGroupItems[pathGroupIdx],
                  jobTypeName: typeObj.name,
                  jobTypeDescription: typeObj.description,
                  skillsBadges: [
                    ...(formattedCareerPathGroupItems[
                      pathGroupIdx
                    ]?.skillsBadges || []),
                    { ...skillBadge },
                  ],
                };
              }
            });

            return {
              ...skillBadge,
              types: data?.getSkillsTypesByBadgeId || [],
            };
          }),
        );
      }

      await Promise.all(
        formattedCareerPathGroupItems.map(async (pathItem) => {
          if (!pathItem.jobTypeName) {
            const { data } = await getJobTypes();
            const types = data?.getJobsTypes || [];
            const type = findJobTypeById(
              pathItem.jobTypeId,
              types,
            );
            console.log('type');
            console.log(type);
            // eslint-disable-next-line no-param-reassign
            if (type) pathItem.jobTypeName = type.name;
          }
        }),
      );

      setCareerPath({
        careerPathGroupItems: formattedCareerPathGroupItems,
        abilitiesBadges: parsedAbilitiesBadges,
        skillsBadges: parsedSkillsBadges,
      });
    };

    useEffect(() => {
      formatCareerPathData(originalCareerPath);
    }, [JSON.stringify(originalCareerPath)]);

    return (
      <WrappedComponent
        {...props}
        careerPath={careerPath}
        careerPathLoading={careerPathLoading}
        careerPathCalled={careerPathCalled}
        createCareerPath={createCareerPath}
        createCareerPathLoading={createCareerPathLoading}
        careerDev={careerDev}
        careerDevLoading={careerDevLoading}
        careerDevCalled={careerDevCalled}
        careerDevSaveLoading={careerDevSaveLoading}
        careerDevSaveError={careerDevSaveError}
        requestSkillCompletion={requestSkillCompletion}
        requestAbilityCompletion={requestAbilityCompletion}
        getCareerDev={getCareerDev}
        getCareerPath={getCareerPath}
        getRecentBadges={getRecentBadges}
        recentBadges={parsedRecentBadges}
        saveCareerDev={saveCareerDev}
      />
    );
  };

  WithEmployeeGrow.displayName = `WithEmployeeGrow(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithEmployeeGrow;
};

export default withEmployeeGrow;
