import { Box, Typography } from '@mui/material';

interface IMetricContainer {
  title: string;
  data?: string | JSX.Element;
  chart?: JSX.Element;
  classes?: { [s: string]: string };
}

const MetricContainer = ({
  title,
  data,
  chart,
  classes = {},
}: IMetricContainer) => (
    <Box className={`${classes.metricContainerOverride}`}>
      <Typography
        variant={'body2'}
        paragraph
        className={`accent1 ${classes.metricTitleOverride}`}
      >
        {title}
      </Typography>
      {chart || (
        <Typography
          className={`${classes.metricDataOverride}`}
          variant={'h1'}
        >
          {data}
        </Typography>
      )}
    </Box>
  );

export default MetricContainer;
