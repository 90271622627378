export const workforceEntryStyles = ({ theme }) => ({
  '& .loadingWrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  '& .mainContent': {
    width: '100%',
    borderRadius: theme.spacing(1),
    padding: '0 24 24 24',
  },
  '& .employeeCardWrapper': {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 1fr)`,
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      '> div': {
        flexBasis: '100%',
      },
    },
  },
  '& .locationTitle': {
    marginBottom: theme.spacing(1.5),
  },
  '& .moreIconWrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '& .paginationBar': {
    marginTop: theme.spacing(3),
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .actionsContainer': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  '& .addMemberModal': {
    border: `1px solid ${theme.palette.button.primary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    minHeight: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  '& .addMemberModalStep': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    minWidth: 400,
    minHeight: 124,
  },
  '& .buttonWrapper': {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  '& .addMemberButton': {
    border: `1px solid ${theme.palette.button.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(2),
    margin: theme.spacing(1.5),
    fontWeight: 700,
    width: 140,
    minHeight: 140,
    '&.active, &:focus': {
      backgroundColor: theme.palette.button.primary.main,
    },
  },
  '& .addOptionTitle': {
    marginTop: theme.spacing(1),
  },
  '& .uploadTypeTitle': {
    marginBottom: theme.spacing(1),
  },
  '& .selected': {
    backgroundColor: theme.palette.button.primary.main,
  },
  '.addButton': {
    marginTop: '10px',
  },
});
