import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { Box, styled } from 'components';
import { GrowRecentBadgeIcon } from 'components/icons';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.badgeCard': {
    width: '100%',
    minHeight: 100,
    padding: 15,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    '&.recent': {
      borderColor: theme.palette.quaternary.main
    }
  },
  '& .badgeCard__title': {
    marginBottom: 3,
    fontSize: 16,
    color: theme.palette.text.primary
  },
  '& .badgeCard__date': {
    fontSize: 9,
    color: theme.palette.common.white
  },
  '& .badgeCard__recentBadge': {
    marginBottom: 10,
    padding: '2.5px 15px',
    fontSize: 10,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 50,
    display: 'inline-block'
  },
  '& .badgeCard__icon': {
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: 50,
      height: 50,
      objectFit: 'contain'
    },
    '& svg': {
      fontSize: 50,
      color: 'rgb(134, 130, 190)' // @TODO: get from theme
    },
    '&.active': {
      '& svg': {
        color: theme.palette.quaternary.main
      }
    }
  },
  '& .pointsContainer': {
    padding: '0 8px'
  },
  '& .points': {
    position: 'relative',
    height: 17,
    padding: 3,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.light,
    backgroundColor: 'rgba(130, 131, 191, 0.5)', // @TODO: get from theme
    '&.active': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white
    },
    '&::before, &::after': {
      content: '""',
      display: 'block',
      height: 17,
      width: 8,
      backgroundColor: 'rgba(130, 131, 191, 0.5)', // @TODO: get from theme
      top: 0,
      left: 0,
      position: 'absolute'
    },
    '&::before': {
      left: '-10px',
      clipPath: 'polygon(0% 20%, 100% 0%, 100% 100%, 0% 90%)'
    },
    '&::after': {
      left: 'auto',
      right: '-10px',
      clipPath: 'polygon(0% 0%, 100% 20%, 100% 90%, 0% 100%)'
    }
  }
}));

const BadgeCard = (props) => {
  const { badgeUrl, className, icon, isRecent, isActive, title } = props;

  const renderBadgeIcon = () => {
    if (badgeUrl) return <img alt="" src={badgeUrl} />;
    if (icon) return icon;
    return null;
  };

  return (
    <StyledRoot
      className={clsx(className, 'badgeCard', isRecent && 'recent', isActive && 'active')}
    >
      <Box mr="6px" display="flex" flexDirection="column" alignItems="center">
        {(isRecent || badgeUrl || icon) && (
          <div className={clsx('badgeCard__icon', (isActive || isRecent) && 'active')}>
            {isRecent ? <GrowRecentBadgeIcon /> : renderBadgeIcon()}
          </div>
        )}
        <div className="pointsContainer">
          <div className={clsx('points', (isActive || isRecent) && 'active')}>+1 pt</div>
        </div>
      </Box>
      <div>
        {isRecent && <div className="badgeCard__recentBadge">Recent Badge 🎉🎉</div>}
        <div className="badgeCard__title">{title}</div>
        {/* <div className="badgeCard__date">Completion: 2/25/2022</div> */}
      </div>
    </StyledRoot>
  );
}

BadgeCard.propTypes = {
  badgeUrl: PT.string,
  className: PT.string,
  icon: PT.node,
  isRecent: PT.bool,
  isActive: PT.bool,
  title: PT.string.isRequired
};

BadgeCard.defaultProps = {
  badgeUrl: '',
  className: '',
  icon: null,
  isRecent: false,
  isActive: false
};

export default BadgeCard;
