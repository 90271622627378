import Typography from '@mui/material/Typography';
import FlexRow from '../../shared/FlexRow';
import { Button } from '../../shared';
import Paper from '@mui/material/Paper';
import { IOnboardingResource } from '../../../api/types/queries/onboarding.types';
import { Box } from '../../material-ui';
import {
  MdAddLink,
  MdAttachFile,
  MdPlayArrow,
  QuestionMark,
} from '../../icons';
import ImageIcon from '@mui/icons-material/Image';
import { styled } from '@mui/material/styles';

interface StyledPaperProps {
  onClick: (a: Partial<IOnboardingResource>) => void | null;
}

const StyledPaper = styled(Paper)<StyledPaperProps>(
  ({ theme, onClick }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 180,
    maxWidth: 200,
    textAlign: 'center',
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    transition: theme.transitions.create(
      ['transform', 'background-color'],
      {
        duration: theme.transitions.duration.standard,
      },
    ),
    '&:hover': onClick
      ? {
          transform: 'scale(1.03)',
          cursor: 'pointer',
          backgroundColor: theme.palette.grey['600'],
        }
      : {},
    '.resourceIcon': {
      height: theme.spacing(5),
      width: theme.spacing(5),
      marginBottom: 10,
      svg: {
        fill: theme.palette.primary.light,
        height: theme.spacing(5),
        width: theme.spacing(5),
      },
    },
    '.resourceDescription': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '.buttonRow': {},
  }),
);

const ResourceCard = ({
  description,
  title,
  type,
  handleDelete,
  handleDownload,
  handleClick,
  variant = 'elevation',
  showDescription = false,
  ...resource
}: IOnboardingResource & {
  handleDelete?: (id: string | number) => void;
  handleDownload?: (id: string | number) => void;
  handleClick?: (resource: Partial<IOnboardingResource>) => void;
  variant: 'elevation' | 'outlined';
  showDescription: boolean;
}) => {
  const Icon = (() => {
    switch (type) {
      case 'video':
        return MdPlayArrow;
      case 'document':
        return MdAttachFile;
      case 'image':
        return ImageIcon;
      case 'url':
        return MdAddLink;
      default:
        return QuestionMark;
    }
  })();

  return (
    <StyledPaper
      variant={variant}
      onClick={
        handleClick
          ? () =>
              handleClick({
                description,
                title,
                ...resource,
              })
          : null
      }
    >
      <Box className={'resourceIcon'}>
        <Icon />
      </Box>
      <Typography variant={'h3'}>{title}</Typography>
      {showDescription && description && (
        <Typography
          className={'resourceDescription'}
          variant={'body1'}
        >
          {(() => {
            const cutLength = 50;
            const descLength = description.length;
            if (descLength > cutLength) {
              return `${description.slice(0, cutLength)}...`;
            }
            return description;
          })()}
        </Typography>
      )}
      <FlexRow className={'buttonRow'}>
        {handleDelete && (
          <Button
            variant={'filled-primary'}
            onClick={() => handleDelete(resource.id)}
          >
            Delete?
          </Button>
        )}
        {handleDownload && (
          <Button variant={'filled-primary'}>Download?</Button>
        )}
      </FlexRow>
    </StyledPaper>
  );
};

export default ResourceCard;
