import { flexCenter, flexRow } from '../../../mixins';
import { addAlpha } from '../../../utils';

export default ({ theme }) => ({
  // start path card
  display: 'flex',
  flexWrap: 'wrap',
  padding: '15px 0 0',
  gap: 15,
  '& .path-data-card': {
    flexBasis: 'calc(100% / 2 - 15px)',
    width: 'calc(100% / 2 - 15px)',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 8,
    padding: 12,
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down('desktopApp')]: {
      flexBasis: '100%',
      width: '100%',
    },
  },
  '& .pathsLoading': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: addAlpha(
      theme.palette.background.default,
      0.75,
    ),
    ...flexCenter,
    top: 0,
    left: 0,
  },
  '& .path-title-container': {
    ...flexRow,
  },
  '& .path-level-sub-title': {
    color: theme.palette.text.secondary,
    marginBottom: 5,
  },
  '& .path-level-title': {
    marginBottom: 10,
  },
  '& .path-level-job-title': {
    color: theme.palette.text.secondary,
    marginBottom: 5,
  },
  '& .path-level-assigned-count': {
    color: theme.palette.tertiary.main,
    paddingBottom: 5,
  },
  '& .path-level-assigned-count-assigned': {
    color: theme.palette.text.primary,
  },
  '& .path-level-action-row': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    button: {
      marginTop: theme.spacing(1.5),
      '&:first-of-type': {
        marginRight: theme.spacing(1),
      },
    },
  },
  [theme.breakpoints.down('desktopApp')]: {
    '.pathTrashIcon': {
      fontSize: 15,
    },
  },
});
