import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { styled } from 'components';
import {
  MdAddChart,
  MdCopy,
  MdDelete,
  MdEdit,
  MdPersonPin,
  MdSave,
  NewColumn,
} from 'components/icons';
import {
  IconButton,
  Input,
  InputAlert,
} from 'components/shared';
import { useForm } from 'components/form';
import PropTypes from 'prop-types';
import growApi from 'components/Grow/api';
import { getProfileId } from 'utils';
import { useAlerts } from 'hooks';
import EmployerGrowContext from '../../EmployerGrowContext';

const StyledRoot = styled('div')(() => ({
  '&.chartToolbar': {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
  },
}));

const ChartToolbar = ({
  deleteChart,
  copyChart,
  createChart,
  editChart,
  createColumn,
  saveChartAndColumns,
  fetchEmployerChartData,
}) => {
  const { setSimpleAlert } = useAlerts();
  const { isEditable, employees } =
    useContext(EmployerGrowContext);

  const { $, withValidation, reset, set } = useForm({
    initial: { email: '' },
    validations: {
      email: ['presence', 'email'],
    },
  });
  const [isEmailModalOpen, setIsEmailModalOpen] =
    useState(false);

  const [
    addEmployeeByEmail,
  ] = useMutation(
    growApi.mutation.ADD_EMPLOYEE_BY_EMAIL_TO_EMPLOYER_CODE,
  );

  const [
    addEmployeeToCharts,
  ] = useMutation(growApi.mutation.ADD_EMPLOYEE_TO_CHARTS);

  const setErrorModal = (subtitle) => {
    setSimpleAlert({
      isOpen: true,
      title: 'Grow',
      subtitle,
      onCancel: () => setSimpleAlert({ isOpen: false }),
    });
  };

  const handleEmployeeAdd = withValidation(
    async (validAttrs) => {
      if (validAttrs.email) {
        try {
          const response = await addEmployeeByEmail({
            variables: { email: validAttrs.email },
          });
          const employeeProfileProfileId =
            response?.data?.postEmployeeToEmployerCode
              ?.profile_id;
          const employerProfileProfileId = getProfileId();

          if (
            employeeProfileProfileId &&
            employerProfileProfileId
          ) {
            await addEmployeeToCharts({
              variables: {
                employeeProfileProfileId: parseInt(
                  employeeProfileProfileId,
                ),
                employerProfileProfileId: parseInt(
                  employerProfileProfileId,
                ),
              },
            });
            fetchEmployerChartData();
          }
        } catch (error) {
          if (error?.message) setErrorModal(error?.message);
          console.error(
            '%c[handleEmployeeAdd error]',
            'color:blue;',
            error,
          );
        } finally {
          setIsEmailModalOpen(false);
        }
      }
    },
  );

  const handleChange = (e) => {
    set('email', e.target.value);
  };

  const tooltipProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',
        color: '#7f7f7f',
        boxShadow: '0 0 4px rgba(0, 0, 0, .25)',
      },
    },
  };

  const isDisabled = isEditable || employees.length === 0;

  return (
    <StyledRoot className="chartToolbar">
      <InputAlert
        isOpen={isEmailModalOpen}
        title="Add Employee"
        inputs={
          <Input
            {...$('email', handleChange)}
            id="employee-email"
            label="Email"
            placeholder="example@g.com"
            type="email"
            required
            withHelperText
          />
        }
        onCancel={() => setIsEmailModalOpen(false)}
        onSuccess={handleEmployeeAdd}
      />

      <IconButton
        disabled={isEditable}
        color="primary"
        withTooltip
        toolTipProps={{
          title: 'Add Employee',
          placement: 'bottom',
          ...tooltipProps,
        }}
        onClick={() => {
          reset();
          setIsEmailModalOpen(true);
        }}
        testID="chart-toolbar-add-button"
      >
        <MdPersonPin />
      </IconButton>
      <IconButton
        disabled={isDisabled}
        color="primary"
        onClick={deleteChart}
        withTooltip
        toolTipProps={{
          title: 'Delete Current Chart',
          placement: 'bottom',
          ...tooltipProps,
        }}
        testID="chart-toolbar-delete-button"
      >
        <MdDelete />
      </IconButton>
      <IconButton
        disabled={isDisabled}
        color="primary"
        onClick={copyChart}
        withTooltip
        toolTipProps={{
          title: 'Copy Chart',
          placement: 'bottom',
          ...tooltipProps,
        }}
        testID="chart-toolbar-copy-button"
      >
        <MdCopy />
      </IconButton>
      <IconButton
        disabled={isDisabled}
        color="primary"
        onClick={createChart}
        withTooltip
        toolTipProps={{
          title: 'New Chart',
          placement: 'bottom',
          ...tooltipProps,
        }}
        testID="chart-toolbar-new-chart-button"
      >
        <MdAddChart />
      </IconButton>
      <IconButton
        disabled={isDisabled}
        color="primary"
        onClick={createColumn}
        withTooltip
        toolTipProps={{
          title: 'New Column',
          placement: 'bottom',
          ...tooltipProps,
        }}
        testID="chart-toolbar-new-col-button"
      >
        <NewColumn />
      </IconButton>
      <IconButton
        disabled={employees.length === 0}
        color={isEditable ? 'default' : 'primary'}
        variant={isEditable ? 'filled-primary' : ''}
        onClick={isEditable ? saveChartAndColumns : editChart}
        withTooltip
        toolTipProps={{
          title: isEditable ? 'Save Chart' : 'Edit Chart',
          placement: 'bottom',
          ...tooltipProps,
        }}
        testID="chart-toolbar-edit-button"
      >
        {isEditable ? <MdSave /> : <MdEdit />}
      </IconButton>
    </StyledRoot>
  );
}

ChartToolbar.propTypes = {
  deleteChart: PropTypes.func,
  copyChart: PropTypes.func,
  createChart: PropTypes.func,
  editChart: PropTypes.func,
  createColumn: PropTypes.func,
  saveChartAndColumns: PropTypes.func,
  fetchEmployerChartData: PropTypes.func,
};

ChartToolbar.defaultProps = {
  deleteChart: () => {},
  copyChart: () => {},
  createChart: () => {},
  editChart: () => {},
  createColumn: () => {},
  saveChartAndColumns: () => {},
  fetchEmployerChartData: () => {},
};

export default ChartToolbar;
