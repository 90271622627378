import { Capacitor } from '@capacitor/core';

export const usePlatform = () => {
  const isNative = Capacitor.isNativePlatform();
  const platform = Capacitor.getPlatform();
  const isNativePlatform = Capacitor.isNativePlatform();
  const isPluginAvailable = (pluginName: string) =>
    isPluginAvailable(pluginName);

  return {
    isNative,
    platform,
    isNativePlatform,
    isPluginAvailable,
  };
};

export default usePlatform;
