import React, { useState, useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { Box, styled } from 'components';
import { useLazyQuery, useMutation } from '@apollo/client';
import map from 'lodash/map';
import { Typography } from '@mui/material';
import { getRoutes } from 'utils';
import { useAuth } from 'hooks';
import { Button, Input } from 'components/shared';

import { GET_SURVEY_QUESTIONS, ADD_SURVEY_RESPONSES } from 'api';
import {
  MdArrowForward,
  MdCheckCircle,
} from '../../components/icons';

import StepNavigation, {
  Step,
} from '../../components/shared/StepNavigation/index';

const ROUTES = getRoutes();
const StyledRoot = styled('div')(() => ({
  '&.container': {
    overflow: 'auto',
    paddingTop: 3,
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .headingCenter': {
    margin: 10,
    textAlign: 'center',
  },
  '& .questionResponse': {
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    borderRadius: 8,
    padding: 15,
  },
  '& .survey-success': {
    textAlign: 'center',
  },
  '& .survey-error': {
    textAlign: 'center',
  },
  '& .submitIcon': {
    width: '2em',
    height: '2em',
  },
  '& .submittedThnx': {
    marginBottom: 10,
  },
}));

const SurveyView = () => {
  const { authed: isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const routerLocation = useLocation();

  if (!isAuthenticated) {
    navigate(ROUTES.login.default, {
      state: { from: { location: routerLocation } },
    });
  }

  const { code } = useParams();
  const [questions, setQuestions] = useState([]);
  const [msgRef, setMsgRef] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [doSubmit, setDoSubmit] = useState(false);

  const [getQuestions, { data }] = useLazyQuery(
    GET_SURVEY_QUESTIONS,
    {
      variables: { code },
      fetchPolicy: 'no-cache',
    },
  );

  const [
    postResponses,
  ] = useMutation(ADD_SURVEY_RESPONSES, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (resData) => {
      if (resData?.addSurveyResponses?.surveyId) {
        setShowSuccess(true);
      }
    },
  });

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    if (data?.getSurveyQuestions) {
      setQuestions(data.getSurveyQuestions);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (doSubmit) {
      postResponses({
        variables: {
          surveyCode: code,
          responses: answers,
        },
      });
    }
  }, [doSubmit]);

  const getAnswers = async (qId) => {
    const answersTemp = [...answers];
    if (answersTemp.length > 0) {
      const obj = answersTemp.find(
        (x) => x.surveyCampaignQuestionId === qId,
      );
      if (obj) {
        const index = answersTemp.indexOf(obj);
        answersTemp.fill(
          (obj.response = msgRef),
          index,
          index + 1,
        );
      } else {
        answersTemp.push({
          surveyCampaignQuestionId: qId,
          response: msgRef,
        });
      }
    } else {
      answersTemp.push({
        surveyCampaignQuestionId: qId,
        response: msgRef,
      });
    }
    setAnswers(answersTemp);
    setMsgRef('');
  };
  const submitAnswers = async () => {
    setDoSubmit(true);
  };

  return (
    <StyledRoot className="container">
      <Box className="survey-dashboard">
        {!data && !showSuccess && (
          <Box className="survey-error">
            <Typography variant={'h2'} paragraph>
              Invalid Survey Code!
            </Typography>
            <Typography variant={'body1'}>
              That survey code is invalid. Please make sure the
              URL and Code are valid and complete. Please contact
              support if you need assistance.
            </Typography>
          </Box>
        )}
        {showSuccess && (
          <Box className="survey-success">
            <MdCheckCircle
              color="primary"
              className="submitIcon"
            />
            <Typography variant={'h2'} paragraph>
              Submitted!
            </Typography>
            <Typography
              variant={'body1'}
              className={'submittedThnx'}
            >
              Thank you for taking the survey.
            </Typography>
            <Button
              variant="outlined-primary"
              endIcon={<MdArrowForward />}
              isRouterLink
              to={ROUTES.employee.profile}
            >
              Go to my account
            </Button>
          </Box>
        )}
        {questions && !showSuccess && (
          <Box>
            <StepNavigation
              submitCallback={() => submitAnswers()}
            >
              {map(questions, ({ question, id }, i) => (
                <Step
                  stepCallback={() => getAnswers(id)}
                  key={id}
                >
                  <Typography
                    variant={'h3'}
                    className={'headingCenter'}
                  >
                    {i + 1} of {questions.length} Questions
                  </Typography>
                  <Typography variant={'h2'}>
                    {question}
                  </Typography>
                  <Box>
                    <Input
                      value={msgRef}
                      label="Your answer will remain anonymous"
                      multiline
                      maxRows={5}
                      className="questionResponse"
                      onChange={(e) => setMsgRef(e.target.value)}
                      testID={`qr_${id}`}
                      id={`qr_${id}`}
                    />
                  </Box>
                </Step>
              ))}
            </StepNavigation>
          </Box>
        )}
      </Box>
    </StyledRoot>
  );
};

export default SurveyView;
