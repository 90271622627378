import { useParams, useLocation } from 'react-router-dom';
import { getRoutes } from 'utils';

export default function useMatchedRoutes() {
  const { pathname } = useLocation();
  const { id, companyId, confirmationId, code } = useParams();
  const routesWithId = getRoutes({
    id,
    companyId,
    confirmationId,
    code,
  });
  const { employer, employee } = routesWithId;
  const matches = {
    // EMPLOYER
    isEmployerProfilePage: employer.profile === pathname,
    isEmployerDashboardPage: employer.enterprise === pathname,
    isEmployerApplicantsSearchPage:
      employer.enterprise_search === pathname,
    isEmployerSchedulePage:
      employer.enterprise_schedule === pathname,
    isEmployerGrowPage: employer.enterprise_grow === pathname,
    isEmployerPlansPage: employer.plans === pathname,
    isEmployerJobsPage: employer.jobs === pathname,
    isEmployerJobPage: employer.job === pathname,
    isEmployerNewJobPage: employer.job_new === pathname,
    isEmployerEditJobPage: employer.job_edit === pathname,
    isEmployerRetentionPage:
      pathname.indexOf(employer.retention_portal.root) >= 0,
    isApprovedApplicantsPage: employer.job_approved === pathname,
    isStarredApplicantsPage: employer.job_starred === pathname,
    isRejectedApplicantsPage: employer.job_rejected === pathname,
    isNextStepsApplicantsPage:
      employer.job_next_steps === pathname,
    // EMPLOYEE
    isEmployeeProfilePage: employee.profile === pathname,
    isEmployeeGrowPage: employee.grow === pathname,
    isEmployeeSkillsProgressPage:
      employee.progressSkills === pathname,
    isEmployeeConnectionsProgressPage:
      employee.progressConnections === pathname,
    isEmployeeHiredProgressPage:
      employee.progressHired === pathname,
    isEmployeeDashboardPage: employee.dashboard === pathname,
    isEmployeeJobPage: employee.job === pathname,
    isCompanyJobsPage: employee.companyJobs === pathname,
    isConfirmationPage:
      employee.interviewConfirmation === pathname,
    isSurveyPage: employee.survey === pathname,
    isEmployerOnboardingPage:
      pathname.indexOf(employer.onboarding.root) >= 0,
  };

  matches.isPublicRoute =
    matches.isEmployeeJobPage || matches.isCompanyJobsPage;

  return matches;
}
