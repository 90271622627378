import React from 'react';
import { styled } from 'components';
import { Button } from 'components/shared';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.classCard': {
    padding: 15,
    borderRadius: 7.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down(1024)]: {
      borderRadius: 8,
    },
  },
  '& .classCard__body': {
    marginBottom: 30,
    [theme.breakpoints.down(1024)]: {
      marginBottom: 16,
    },
  },
  '& .classCard__title': {
    marginBottom: 21,
    [theme.breakpoints.down(1024)]: {
      marginBottom: 12,
    },
  },
  '& .classCard__description': {
    fontSize: 14,
    [theme.breakpoints.down(1024)]: {
      fontSize: 14,
    },
  },
  '.buttonContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      width: '100%',
      marginTop: 10,
    },
    a: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  button: {
    backgroundColor: theme.palette.background.default,
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.button.primary.hover,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.button.primary.disabled,
      color: theme.palette.button.primary.contrastText,
    },
  },
}));

interface IClassProps {
  complete: boolean;
  link?: string;
  name: string;
  taken: boolean;
}

const ClassCard = ({
  complete,
  taken,
  name,
  description,
  onTake,
  link,
}: IClassProps) => (
  <StyledRoot className="classCard">
    <div className="classCard__body">
      <Typography variant={'h3'}>{name}</Typography>
      {description && (
        <p className="classCard__description">{description}</p>
      )}
    </div>
    <Box className={'buttonContainer'}>
      {link && (
        <a href={link} target={'_blank'} rel="noreferrer">
          <Button variant={'contained'} color={'primary'}>
            Take Class {taken ? ' Again' : ''}
          </Button>
        </a>
      )}
      {!complete && (
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={onTake}
          disabled={taken}
        >
          {taken ? 'Requested' : 'Request Completion'}
        </Button>
      )}
    </Box>
  </StyledRoot>
);

export default ClassCard;
