import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { qaAttr } from 'utils';
import { Backdrop, styled } from 'components';
import { SwipeRightIcon, SwipeLeftIcon } from 'components/icons';
import Button from 'components/shared/Button';
import styles from 'styles/Dashboard/EmployeeMobileManual';

const EmployeeDashboardMobileManual = ({ className, deckHeight, isOpen, onComplete }) => (
    <Backdrop open={isOpen} className={className}>
      <div className="contentContainer">
        <div
          className="deckContainer"
          style={{ minHeight: deckHeight > window.innerHeight - 123 ? 100 : deckHeight }}
        >
          <div className="instructionsWrapper">
            <div className="instruction __borderRight">
              <SwipeLeftIcon className="swipeIcon" sx={{fontSize: 48}}/>
              <div className="text"><div>Not interested?</div>Swipe left to pass</div>
            </div>
            <div className="instruction">
              <SwipeRightIcon className="swipeIcon" sx={{fontSize: 48}}/>
              <div className="text"><div>Want to apply?</div>Swipe right to apply</div>
            </div>
          </div>
          <Button
              variant="filled-primary"
              sx={{ height: 50, width: 150 }}
              onClick={onComplete}
              {...qaAttr('got-it-button')}
            >
              Got it
            </Button>
        </div>
      </div>
    </Backdrop>
  )

EmployeeDashboardMobileManual.propTypes = {
  className: PropTypes.string.isRequired,
  deckHeight: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired
};

const Styled = styled(EmployeeDashboardMobileManual)(styles);

export default memo(Styled);
