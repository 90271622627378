import React from 'react';
import {
  IRetentionContext,
  ISurveyContext,
  dashboardLevels,
  retentionPages,
  interactionTypes,
  interactionNames,
} from '../types';

export const baseRetentionContext: IRetentionContext = {
  setRetentionContext: () => null,
  retentionPages,
  dashboardLevels,
  interactionTypes,
  interactionNames,
  organizationId: null,
  organizationName: null,
  accessOrganization: false,
  brandId: null,
  brandName: null,
  accessBrand: false,
  locationId: null,
  locationName: null,
  accessLocation: false,
};

export const RetentionContext = React.createContext(
  baseRetentionContext,
);

export const baseSurveyContext: ISurveyContext = {
  setSurveyContext: () => null,
  setSurvey: () => null,
  survey: {
    name: '',
    recipients: { recipientIds: [] },
    questions: [],
  },
};

export const SurveyContext = React.createContext(
  baseSurveyContext,
);
