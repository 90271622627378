import { addAlpha } from 'styles/utils';
import { darken } from '@mui/material';

export default ({ theme }) => ({
  overflow: 'auto',
  padding: '0 30px',
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('desktopApp')]: {
    padding: '0 16px 100px',
  },
  '& .content': {
    // paddingTop: 20,
  },
  '& .actionsContainer': {
    marginBottom: 18,
    [theme.breakpoints.down('desktopApp')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      marginBottom: 0,
      padding: '8px 12px',
      boxShadow: '0px -5px 5px #00000026',
      background: theme.palette.background.paper,
    },
  },
  '& .actionsBtnsWrapper': {
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      justifyContent: 'start',
      alignItems: 'center',
    },
  },
  '& .goBackBtn': {
    [theme.breakpoints.down('desktopApp')]: {
      position: 'absolute',
      top: 5,
      left: 5,
      zIndex: 1,
      backgroundColor: theme.palette.background.default,
    },
  },
  '& .row': {
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .label': {
      marginBottom: 0,
    },
    '& .text': {
      marginBottom: 0,
      textAlign: 'right',
    },
  },
  '& .rowColumnRight': {
    flex: 1,
    marginLeft: 15,
    textAlign: 'right',
  },
  '& .text': {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.common.white,
    maxWidth: 500,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 16,
    },
  },
  '& .label': {
    marginBottom: 12,
    fontSize: 12,
    lineHeight: '13px',
    fontWeight: 800,
    letterSpacing: 0.5,
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 10,
      lineHeight: '22px',
      fontSize: 14,
    },
  },
  '& .job__title': {
    marginBottom: 33,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '24px',
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 20,
      fontSize: 18,
      lineHeight: '22px',
    },
  },
  '& .job__employer': {
    marginBottom: 12,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '22px',
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {},
  },
  '& .job__employer ~ div > div:first-of-type': {
    background: theme.palette.background.white,
    padding: 10,
  },
  '& .job__photo': {
    marginBottom: 18,
    objectFit: 'cover',
    [theme.breakpoints.down('desktopApp')]: {
      borderRadius: 25,
    },
  },
  '& .applyBtn': {
    minWidth: 118,
    height: 45,
    padding: '0 12px',
    borderRadius: 8,
    fontSize: 14,
    '&.applied': {
      backgroundColor: theme.palette.success.main,
      borderColor: theme.palette.success.main,
      color: darken(theme.palette.success.main, 0.8),
    },
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: 100,
      height: 36,
      padding: '0 6px',
    },
  },
  '& .cancelBtn': {
    minWidth: 118,
    height: 45,
    marginLeft: 18,
    padding: '0 12px',
    borderRadius: 8,
    fontSize: 14,
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: 100,
      height: 36,
      padding: '0 6px',
      marginLeft: 12,
    },
  },
  '& .secondaryActionWrapper': {
    marginLeft: 18,
    [theme.breakpoints.down('desktopApp')]: {
      marginLeft: 12,
    },
  },
  '& .secondaryActionWrapper_labeled': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .secondaryAction__label': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'block',
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.common.white,
    },
  },
  '& .secondaryAction': {
    [theme.breakpoints.down('desktopApp')]: {
      width: 36,
      height: 36,
      marginBottom: 4,
      fontSize: 20,
    },
  },
  '& .loaderOverlay': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: addAlpha(
      theme.palette.background.default,
      0.7,
    ),
  },
  '& .interviewDate': {
    marginBottom: 6,
    fontSize: 12,
    textAlign: 'left',
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {
      textAlign: 'center',
    },
  },
  '& .shareButton': {
    height: 45,
    minWidth: 118,
    padding: '0 15px',
    fontSize: 14,
    borderRadius: 8,
  },
  '& .inlineBlock': {
    display: 'inline-block',
  },
  '& .mapLink': {
    minHeight: 46,
    width: 247,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.common.white,
  },
  '& .quickApplyBtn': {
    marginRight: 18,
  },
});
