import { gql } from "@apollo/client";

export const SET_COMPANY_THEME = gql`
  mutation SetCompanyTheme(
    $primaryColor: String!
    $secondaryColor: String!
    $tertiaryColor: String!
    $quaternaryColor: String!
  ) {
    setCompanyTheme(
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      tertiaryColor: $tertiaryColor
      quaternaryColor: $quaternaryColor
    ) {
      organizationId
      primaryColor
      secondaryColor
      tertiaryColor
      quaternaryColor
      id
      createdAt
      updatedAt
      deletedAt
    }
  }
`
