import { gql } from '@apollo/client';

export const CREATE_CAREER_PATH_GROUP = gql`
  mutation CreateCareerPathGroup(
    $careerPathGroupItems: [CareerPathGroupItemsInputType]
    $organizationId: Int
    $name: String
  ) {
    createCareerPathGroup(
      careerPathGroupItems: $careerPathGroupItems
      organizationId: $organizationId
      name: $name
    ) {
      careerPathGroup {
        id
        endJobTypeId
        endJobName
        name
        createdAt
        startJobName
        startJobTypeId
        updatedAt
      }
      careerPathGroupItems {
        careerPathGroupItemJobType {
          jobTypeRefId
          id
          name
        }
        careerPathGroupId
        completed
        id
      }
    }
  }
`;

export const ADD_SKILLS_TO_CAREER_PATHS = gql`
  mutation AddSkillsToCareerPath(
    $careerPathGroupItemId: Int
    $hardSkills: HardSkillsInputType
    $softSkills: SoftSkillsInputType
  ) {
    addSkillsToCareerPath(
      careerPathGroupItemId: $careerPathGroupItemId
      hardSkills: $hardSkills
      softSkills: $softSkills
    ) {
      abilitiesToJobTypes {
        abilitiesBadgeId
        createdAt
        id
        jobTypeId
        updatedAt
      }
      skillsToJobTypes {
        createdAt
        id
        jobTypeId
        skillsId
        updatedAt
      }
    }
  }
`;

export const ASSIGN_EMPLOYEES_TO_CAREER_PATH_GROUP = gql`
  mutation AssignEmployeesToCareerPathGroup(
    $careerPathGroupId: Int
    $employeeIds: [Int]
  ) {
    assignEmployeesToCareerPathGroup(
      careerPathGroupId: $careerPathGroupId
      employeeIds: $employeeIds
    ) {
      abilitiesBadgesToEmployees {
        createdAt
        abilitiesBadgeId
        status
        employeeUserId
        id
        updatedAt
      }
      employeeToCareerPathGroupItems {
        careerPathGroupItemId
        active
        createdAt
        id
        updatedAt
        employeeProfileId
        completed
      }
      skillsBadgesToEmployees {
        status
        createdAt
        updatedAt
        skillsBadgeId
        id
        employeeUserId
      }
    }
  }
`;

export const DELETE_CAREER_PATH_GROUP = gql`
  mutation DeleteCareerPathGroup($careerPathGroupId: Int) {
    deleteCareerPathGroup(
      careerPathGroupId: $careerPathGroupId
    ) {
      deletedCareerPathGroupId
    }
  }
`;

export const MARK_ABILITY_BADGE_COMPLETE = gql`
  mutation MarkAbilityBadgeComplete(
    $abilityBadgeId: Int!
    $employeeUserId: Int!
    $status: badgeStatus!
  ) {
    markAbilityBadgeComplete(
      abilityBadgeId: $abilityBadgeId
      employeeUserId: $employeeUserId
      status: $status
    ) {
      message
      success
    }
  }
`;

export const MARK_SKILL_BADGE_COMPLETE = gql`
  mutation MarkSkillBadgeComplete(
    $skillBadgeId: Int!
    $employeeUserId: Int!
    $status: badgeStatus!
  ) {
    markSkillBadgeComplete(
      skillBadgeId: $skillBadgeId
      employeeUserId: $employeeUserId
      status: $status
    ) {
      message
      success
    }
  }
`;
