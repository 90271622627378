import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import { addAlpha } from 'styles/utils';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.topCareerCard': {
    padding: 15,
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundColor: 'transparent'
  },
  '& .topCareerCard__title': {
    color: theme.palette.text.primary,
    marginBottom: 10,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 12,
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '130%'
    }
  },
  '& .topCareerCard__range': {
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('desktopApp')]: {},
    '& span': {
      '&:nth-of-type(1), &:nth-of-type(3)': {
        fontSize: 12,
        [theme.breakpoints.down('desktopApp')]: {
          fontSize: 16,
          fontWeight: 800,
          lineHeight: '150%'
        }
      },
      '&:nth-of-type(2)': {
        margin: '0 15px',
        fontSize: 18,
        color: theme.palette.primary.light,
        [theme.breakpoints.down('desktopApp')]: {
          fontSize: 20,
          fontWeight: 700,
          lineHeight: '130%'
        }
      }
    }
  },
  '& .topCareerCard__divider': {
    position: 'relative',
    width: '60%',
    height: 1,
    margin: '10px auto',
    background: `linear-gradient(to right, ${addAlpha(theme.palette.primary.light, 0)} 0%, ${
      theme.palette.primary.light
    } 50%, ${addAlpha(theme.palette.primary.light, 0)} 100%)`,
    '&::before': {
      content: "''",
      position: 'absolute',
      top: -2.5,
      left: '50%',
      transform: 'translateX(-50%)',
      height: 5,
      width: 5,
      margin: '0 auto',
      borderRadius: 5,
      backgroundColor: theme.palette.primary.light,
      [theme.breakpoints.down('desktopApp')]: {
        height: 13,
        width: 13,
        top: -6.5
      }
    }
  },
  '& .topCareerCard__rangeLabel': {
    fontSize: 12,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .topCareerCard__action': {
    padding: '10px 25px',
    borderRadius: 50,
    border: `1px solid ${theme.palette.primary.light}`,
    outline: 'none',
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('desktopApp')]: {
      color: theme.palette.grey['200']
    }
  }
}));

const TopCareerCard = (props) => {
  const { avg, min, max, position } = props;
  return (
    <StyledRoot className="topCareerCard">
      <div className="topCareerCard__title">{position}</div>
      <div className="topCareerCard__range">
        <span>{`$${min}`}</span>
        <span>{`$${avg}`}</span>
        <span>{`$${max}`}</span>
      </div>
      <div className="topCareerCard__divider" />
      <div className="topCareerCard__rangeLabel">National Avg.</div>
    </StyledRoot>
  );
}

TopCareerCard.propTypes = {
  avg: PT.number.isRequired,
  min: PT.number.isRequired,
  max: PT.number.isRequired,
  position: PT.string.isRequired
};

export default TopCareerCard;
