import React, { useContext, useEffect } from 'react';
import { Box, styled } from 'components';
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  Favorite,
  ForkRight,
  Group,
  MonitorHeart,
} from '@mui/icons-material';
import { entryStyles } from '../../../../styles/Retention/Location';
import { RetentionNavigation } from '../../../../components/Retention/components/RetentionNavigation';
import { RetentionContext } from '../../../../components/Retention/utils';
import { getRoutes } from '../../../../utils';

const {
  ALLOW_RETENTION_LOCATION_HEALTH,
  ALLOW_RETENTION_LOCATION_PULSE,
  ALLOW_RETENTION_LOCATION_PATH,
} = process.env;

const StyledBox = styled(Box)(entryStyles);

const LocationEntry = () => {
  const ctx = useContext(RetentionContext);
  const navigate = useNavigate();
  const routes = getRoutes();
  const location = useLocation();
  const healthDashboard = `${routes.employer.retention_portal.location}/${ctx.retentionPages.health}`;
  const pathDashboard = `${routes.employer.retention_portal.location}/${ctx.retentionPages.path}`;
  const pulseDashboard = `${routes.employer.retention_portal.location}/${ctx.retentionPages.pulse}`;
  const workforceDashboard = `${routes.employer.retention_portal.location}/${ctx.retentionPages.workforce}`;

  useEffect(() => {
    // if landing on location without a path [ctx.retentionPages] then navigate to the `/path`
    const pathnameParts = location.pathname.split('/');
    if (
      !Object.values(ctx.retentionPages).includes(
        pathnameParts[pathnameParts.length - 1],
      )
    ) {
      navigate(pathDashboard);
    }
  }, []);

  return (
    <StyledBox className="retention-navigation-wrapper">
      <Box className={'retention-navigation'}>
        <RetentionNavigation
          buttons={[
            {
              disabled:
                ALLOW_RETENTION_LOCATION_PATH === 'false' ||
                location.pathname === pathDashboard,
              label: 'Location Path Dashboard',
              onClick: () => navigate(pathDashboard),
              testId: 'navigate-to-location-path-dashboard',
              Icon: ForkRight,
              selected: location.pathname === pathDashboard,
            },
            {
              disabled:
                ALLOW_RETENTION_LOCATION_PULSE === 'false' ||
                location.pathname === pulseDashboard,
              label: 'Location Pulse Dashboard',
              onClick: () => navigate(pulseDashboard),
              testId: 'navigate-to-location-pulse-dashboard',
              Icon: MonitorHeart,
              selected: location.pathname === pulseDashboard,
            },
            {
              disabled:
                ALLOW_RETENTION_LOCATION_HEALTH === 'false' ||
                location.pathname === healthDashboard,
              label: 'Location Health Dashboard',
              onClick: () => navigate(healthDashboard),
              testId: 'navigate-to-location-health-dashboard',
              Icon: Favorite,
              selected: location.pathname === healthDashboard,
            },
            {
              disabled: false,
              label: 'Location Workforce Dashboard',
              onClick: () => navigate(workforceDashboard),
              testId: 'navigate-to-location-workforce-dashboard',
              Icon: Group,
              selected: location.pathname === workforceDashboard,
            },
          ]}
        />
      </Box>
      <Outlet />
    </StyledBox>
  );
};

export default LocationEntry;
