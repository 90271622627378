import { gql } from '@apollo/client';

export const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences(
      $preferences: surveyInputObjectType
  ) {
    updatePreferences(preferences: $preferences) {
      id
      createdAt
      updatedAt
      userId
      preferences
      deletedAt
    }
  }
`
