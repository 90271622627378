import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { GET_PULSE_BY_LOCATIONS } from 'api';
import { Box } from '../../../../components';
import BrandDashboardRenderer from '../../../../components/Retention/components/Brand/BrandDashboardRenderer';
import PulseCard from '../../../../components/Retention/components/Brand/PulseCard';
import { IEmployerBrandRenderArgs } from '../../../../components/Retention/types';
import { RetentionContext } from '../../../../components/Retention/utils';

const BrandPulseEntry = () => {
  const { brandName, brandId, setRetentionContext } =
    useContext(RetentionContext);

  const [getPulseByLocations, { data, loading = true }] =
    useLazyQuery(GET_PULSE_BY_LOCATIONS, {
      variables: {
        brandId,
        order: 'teamSize',
        direction: 'ASC',
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
    });

  useEffect(() => {
    getPulseByLocations();
  }, []);

  return (
    <Box>
      <BrandDashboardRenderer
        data={data}
        loading={loading}
        title={brandName}
        render={({
          cardsData,
          navigate,
          locationRoute,
        }: IEmployerBrandRenderArgs) => {
          const handleNavigation = (
            id: number,
            location: string,
          ) => {
            setRetentionContext({
              locationId: id,
              locationName: location,
            });
            navigate(`${locationRoute}/pulse`);
          };
          return cardsData?.getPulseByLocations?.locations.map(
            (props) => (
              <PulseCard
                {...props}
                key={props.id}
                handleNavigation={handleNavigation}
              />
            ),
          );
        }}
      />
    </Box>
  );
};

export default BrandPulseEntry;
