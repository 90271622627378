import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon6 = (props) => (
    <SvgIcon viewBox="0 0 28.63 33.64" {...props}>
      <path
        d="M18.1,0c7.99,.34,12.26,6.3,9.87,15.21l-3.82,14.28-11.29,4.16,6.17-23.01c1.17-4.35,.74-7.99-.93-10.63Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M7.44,6.69c4.3,.18,7.52,2,9.21,5.02l-4.39,16.38-7.53,2.77,3.63-13.55c1.16-4.35,.74-7.99-.93-10.63Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M.17,13.38c2.64,.11,4.88,.84,6.6,2.09l-3.01,11.24-3.76,1.39,1.1-4.09c1.17-4.35,.74-7.99-.93-10.63Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon6;
