import {
  isNANP,
  isValidCanadianPostalCode,
  isValidUSZipCode,
} from 'utils';
import dayjs from 'dayjs';
import format from 'date-fns/format';

export const validateZip =
  ({ countryId }) =>
  (e) => {
    // check if country has been set.;
    if (countryId === 1) {
      // zip should only be numeric
      return isValidUSZipCode(e)
        ? true
        : 'Zip code can only be numeric when in U.S.';
    }

    // zip can be alphanumeric
    return isValidCanadianPostalCode(e)
      ? true
      : 'Invalid Zip Format';
  };

export const validatePhone = (e) =>
  isNANP(e) ? true : 'Phone format is invalid';

// this is kinda gross but get the job done. Sorry future me!
export const validateDateBefore =
  (values) => (field, index) => (date) => {
    // if the field is not a current role and the end date exists
    if (values[field][index].endDate) {
      return dayjs(date).isBefore(values[field][index].endDate)
        ? true
        : 'Start Date must predate End Date';
    }
    return true;
  };

// this is kinda gross but get the job done. Sorry future me!
export const validateDateAfter =
  (values) => (field, index) => (date) => {
    if (!values[field][index].startDate) {
      return 'Start Date must be provided';
    }
    if (values[field][index].currentJob && date == null) {
      return true;
    }
    return dayjs(date).isAfter(
      dayjs(values[field][index].startDate),
    )
      ? true
      : 'End date must be after Start Date';
  };

export const validateCurrent =
  (values) => (field, index) => (isCurrent) => {
    if (isCurrent && values[field][index].endDate != null) {
      return 'Both End Date and Current cannot be provided, please choose one or the other';
    }
    return true;
  };

export const formatEducation = (education) =>
  education.map((edu) => ({
    ...edu,
    startDate: dayjs(edu.startDate).format('YYYY'),
    endDate: edu.endDate
      ? dayjs(edu.endDate).format('YYYY')
      : edu.endDate,
  }));

export const formatExperience = (experience) =>
  experience.map((exp) => ({
    ...exp,
    startDate: dayjs(exp.startDate).format('YYYY-MM-DD'),
    endDate: exp.endDate
      ? dayjs(exp.endDate).format('YYYY-MM-DD')
      : exp.endDate,
  }));

export const formatCertificates = (certificates) =>
  certificates.map(
    ({ validatedByEmployerProfileId, ...cert }) => ({
      ...cert,
      // remove employer verification if not available
      ...(validatedByEmployerProfileId
        ? { validatedByEmployerProfileId }
        : {}),
    }),
  );

export const formatIndustries = (industries) => industries.map(ind => ({
  id: ind.id,
  name: ind.name
}))
