import React, { Fragment, useState, useEffect } from 'react';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';
import { utcToZonedTime } from 'date-fns-tz';
import { map, filter, reduce, orderBy, findIndex } from 'lodash';
import { getProfileId, getLocaleTimeZone } from 'utils';
import { useEmployerProfileQuery, useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { IconButton, Spinner } from 'components/shared';
import { MdChevronLeft, MdChevronRight } from 'components/icons';
import styles from 'styles/Dashboard/EmployerShedule';
import { GET_EMPLOYER_SCHEDULED_INTERVIEWS } from 'api';
import ScheduledEventCard from './ScheduledEventCard';

const StyledRoot = styled('div')(styles);

const DATE_FORMAT = 'MM-dd-yyyy';

const getWeekBorders = (date = new Date()) => ({
  start: startOfWeek(date),
  end: endOfWeek(date)
});

const initWeekInterviews = (weekStartDate, preFilled = []) => {
  const arr = [];
  arr.length = 7;
  const week = map(arr, (_, i) => {
    const date = new Date(weekStartDate.valueOf());
    date.setDate(weekStartDate.getDate() + i);
    return {
      date,
      formattedDay: format(date, DATE_FORMAT),
      schedules: []
    };
  });
  return preFilled.length ? preFilled : week;
};

const ScheduledInterviews = () => {
  const client = useApolloClient();
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [weekBorders, setWeekBorders] = useState(getWeekBorders);
  const [allInterviews, setAllInterviews] = useState([]);
  const [weekInterviews, setWeekInterviews] = useState([]);
  const [dayIdx, setDayIdx] = useState(0);

  const [fetchInterviews, { loading: fetchInterviewsLoading = true }] = useLazyQuery(
    GET_EMPLOYER_SCHEDULED_INTERVIEWS,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      onCompleted: (data) => {
        const result = data?.getScheduleEmployer;
        if (result) {
          setAllInterviews(filter(result, 'interviewDate') || []);
        }
      }
    }
  );

  const { profile } = useEmployerProfileQuery();
  const { name = '', timeZone = getLocaleTimeZone() } = profile || {};

  const getInterviews = () => {
    const profileId = getProfileId();
    if (profileId) fetchInterviews({ variables: { employerProfileId: parseInt(profileId) } });
  };

  useEffect(() => {
    getInterviews();
  }, []);

  useEffect(() => {
    if (weekBorders.start) {
      if (!allInterviews.length) {
        setWeekInterviews([]);
        return;
      }

      const weekStartInMs = weekBorders.start.valueOf();
      const weekEndInMs = weekBorders.end.valueOf();
      const currentWeekInterviews = filter(
        allInterviews,
        (o) =>
          utcToZonedTime(parseISO(o.interviewDate), timeZone).valueOf() >= weekStartInMs &&
          utcToZonedTime(parseISO(o.interviewDate), timeZone).valueOf() <= weekEndInMs
      );
      const ordered = orderBy(currentWeekInterviews, [(o) => o.interviewDate.valueOf()], ['asc']);
      const formattedInterviews = reduce(
        ordered,
        (res, val) => {
          const nextRes = [...res];
          const date = utcToZonedTime(val.interviewDate, timeZone); // convert to employer tz
          const dayIndex = date.getDay();
          const weekDayDate = new Date(weekBorders.start.valueOf());
          weekDayDate.setDate(weekDayDate.getDate() + dayIndex);

          if (nextRes[dayIndex].formattedDay) {
            nextRes[dayIndex].schedules.push({
              ...val,
              date,
              formattedTime: [format(date, 'hh:mm'), format(date, 'a')]
            });
          } else {
            nextRes[dayIndex] = {
              date: weekDayDate,
              formattedDay: format(date, DATE_FORMAT),
              schedules: [
                {
                  ...val,
                  date,
                  formattedTime: [format(date, 'hh:mm'), format(date, 'a')]
                }
              ]
            };
          }

          return nextRes;
        },
        initWeekInterviews(weekBorders.start)
      );

      setWeekInterviews(formattedInterviews);
    }
  }, [JSON.stringify(allInterviews), weekBorders.start, timeZone]);

  const showNextWeek = () =>
    setWeekBorders(getWeekBorders(weekBorders.end.setDate(weekBorders.end.getDate() + 1)));

  const showPrevWeek = () =>
    setWeekBorders(getWeekBorders(weekBorders.start.setDate(weekBorders.start.getDate() - 1)));

  const showNextDay = () => {
    if (dayIdx < 6) setDayIdx(dayIdx + 1);
    else {
      showNextWeek();
      setDayIdx(0);
    }
  };

  const showPrevDay = () => {
    if (dayIdx > 0) setDayIdx(dayIdx - 1);
    else {
      showPrevWeek();
      setDayIdx(6);
    }
  };

  const onInterviewCancel = (employeeProfileId, jobId) => {
    const interviews = [...allInterviews].filter(
      (o) =>
        !(
          String(o.employeeProfileId) === String(employeeProfileId) &&
          String(o.jobId) === String(jobId)
        )
    );
    setAllInterviews(interviews);
  };

  const onHire = (hired, employeeProfileId, jobId) => {
    const interviewIdx = findIndex(allInterviews, {
      jobId: String(jobId),
      employeeProfileId: String(employeeProfileId)
    });

    if (interviewIdx !== -1) {
      const newObj = { ...allInterviews[interviewIdx], hiredStatus: String(hired) };
      const interviewsCp = [...allInterviews];
      interviewsCp.splice(interviewIdx, 1, newObj);
      setAllInterviews(interviewsCp);
    }
  };

  const mobileWeekControllers = () => {
    const curDay = addDays(weekBorders.start, dayIdx);
    return (
      <Box mb="26px" display="flex" justifyContent="center">
        <div className="interviews__weekController">
          <IconButton
            className="interviews__weekController__action"
            onClick={showPrevDay}
            testID="prev-week-button"
          >
            <MdChevronLeft fontSize="inherit" color="inherit" />
          </IconButton>
          <div className="interviews__weekController__range">{format(curDay, 'MMM d, yyyy')}</div>
          <IconButton
            className="interviews__weekController__action"
            onClick={showNextDay}
            testID="next-week-button"
          >
            <MdChevronRight fontSize="inherit" color="inherit" />
          </IconButton>
        </div>
      </Box>
    );
  };

  const desktopWeekControllers = () => (
    <div className="interviews__weekController">
      <IconButton
        // disabled={isCurrentWeek}
        className="interviews__weekController__action"
        onClick={showPrevWeek}
        testID="prev-week-button"
      >
        <MdChevronLeft fontSize="inherit" color="inherit" />
      </IconButton>
      <div className="interviews__weekController__range">
        {`${format(weekBorders.start, 'MMM dd')} - ${format(weekBorders.end, 'MMM dd')}`}
      </div>
      <IconButton
        className="interviews__weekController__action"
        onClick={showNextWeek}
        testID="next-week-button"
      >
        <MdChevronRight fontSize="inherit" color="inherit" />
      </IconButton>
    </div>
  );

  const renderDayInterviews = (interviews, i) =>
    map(interviews, (obj, s_i) => {
      const { date, employeeProfileId, jobId, user_id } = obj;
      const cacheId = client.cache.identify(obj);
      const isPast = isBefore(date, utcToZonedTime(new Date(), timeZone));
      const numJobId = Number(jobId);
      const numEmployeeUserId = Number(user_id);
      const numEmployeeProfileId = Number(employeeProfileId);

      return (
        <Fragment key={`slot__${i}_${s_i}`}>
          <ScheduledEventCard
            key={`event__${i}_${s_i}`}
            classes={
              {
                // container: classes.interviews__timeSlotWrapper
              }
            }
            afterHire={onHire}
            afterCancel={onInterviewCancel}
            cacheId={cacheId}
            companyTitle={name}
            employeeUserId={numEmployeeUserId}
            employeeProfileId={numEmployeeProfileId}
            employeeImageUrl={obj.employeeImage}
            employeeName={obj.employeeName}
            employeeRate={obj.rate}
            formattedTime={obj.formattedTime.join(' ')}
            hireStatus={obj.hiredStatus}
            interviewId={obj.id}
            interviewDate={date}
            interviewOriginIsoDate={obj.interviewDate}
            interviewTimeZone={timeZone}
            interviewAddress={obj.jobLocation}
            isPast={isPast}
            jobId={numJobId}
            jobTitle={obj.jobTitle}
            status={obj.status}
            statusEnum={obj.statusEnum}
          />
        </Fragment>
      );
    });

  const renderDesktopSlots = () =>
    map(weekInterviews, ({ formattedDay, schedules }, i) => (
      <div key={`day__${i}`} className="interviews__daySlot">
        <h4 className="interviews__dayTitle">{formattedDay}</h4>
        {schedules.length ? (
          <div className="interviews__timeSlots">{renderDayInterviews(schedules, i)}</div>
        ) : (
          <div>N/A</div>
        )}
      </div>
    ));

  const renderMobileSlots = () => {
    const { schedules = [] } = weekInterviews[dayIdx] || {};

    return schedules.length ? (
      <div className="interviews__timeSlots">{renderDayInterviews(schedules)}</div>
    ) : (
      <Box textAlign="center">N/A</Box>
    );
  };

  const renderContent = () => (isDesktop ? renderDesktopSlots() : renderMobileSlots());

  return (
    <StyledRoot className="interviews__container">
      <p>
        This is your schedule for the current week. Use the arrows below to change weeks and view
        future scheduled interviews!
      </p>
      <div className="interviews__contentContainer">
        {isDesktop ? desktopWeekControllers() : mobileWeekControllers()}
        {fetchInterviewsLoading ? (
          <div style={{ textAlign: 'center' }}>
            <Spinner size={48} />
          </div>
        ) : (
          <div className="interviews__daysContainer">
            {weekInterviews.length ? renderContent() : <div>No interviews yet</div>}
          </div>
        )}
      </div>
    </StyledRoot>
  );
}

ScheduledInterviews.propTypes = {};

export default ScheduledInterviews;
