import { gql } from "@apollo/client";

export const GET_COMPANY_THEME = gql`
  query GetCompanyTheme {
    getCompanyTheme {
      organizationId
      primaryColor
      secondaryColor
      tertiaryColor
      quaternaryColor
      id
      createdAt
      updatedAt
      deletedAt
    }
  }
`

export const GET_COMPANY_THEME_BY_EMPLOYEE = gql`
  query GetCompanyThemeByEmployee {
    getCompanyThemeByEmployee {
      organizationId
      primaryColor
      secondaryColor
      tertiaryColor
      quaternaryColor
      id
      createdAt
      updatedAt
      deletedAt
    }
  }
`
