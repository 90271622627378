import { addAlpha } from 'styles/utils';

export default ({ theme }) => ({
  '& .filledLogo': {
    fill: theme.palette.text.primary,
  },
  '.worktorchIcon': {
    height: 50,
    width: 50,
    // this 7.5px is the same as the padding on the employee navigation
    marginLeft: theme.spacing(1),
    svg: {
      height: 50,
      width: 50,
    },
  },
  '& .premiumBtn': {
    width: 168,
    height: 44,
    fontSize: 14,
    '& .StyledButton-startIcon': {
      marginRight: 6,
      marginLeft: -3,
    },
  },
  '& .postJobBtn': {
    position: 'relative',
    '& .StyledButton-startIcon': {
      marginRight: 21,
      marginLeft: 0,
    },
  },
  '& .mobilePostJobBtnContainer': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'block',
    },
  },
  '& .mobilePostJobBtn': {
    position: 'fixed',
    bottom: 42,
    left: '50%',
    zIndex: 50,
    transform: 'translateX(-50%)',
    width: 45,
    height: 45,
    display: 'none',
    boxShadow: '0 2px 4px 0 rgba(71,67,162,0.3)',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
    },
  },
  '& .jobsFilterBtn': {
    width: 'auto !important',
    marginLeft: 0,
    marginRight: 0,
    color: theme.palette.text.primary,
  },
  '& .optionsPopupPaper': {
    minWidth: 255,
    [theme.breakpoints.down('desktopApp')]: {
      borderRadius: 5,
    },
  },
  '& .optionsPopupBtn': {
    width: '100%',
    height: 50,
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: theme.palette.common.white,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${addAlpha(
        theme.palette.grey['200'],
        0.5,
      )}`,
    },
    [theme.breakpoints.down('desktopApp')]: {
      height: 50,
      fontSize: 16,
      lineHeight: '18px',
    },
  },
  '& .selectorPopupPaper': {
    maxWidth: 253,
    boxShadow: `0 8px 24px 0 ${theme.palette.background.paper}`,
    [theme.breakpoints.down('desktopApp')]: {
      borderRadius: 5,
    },
  },
  '& .selectorTriggerBtn': {
    color: theme.palette.common.white,
    '& .StyledButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  '& .selectorBtn': {
    width: '100%',
    height: 50,
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: theme.palette.common.white,
    borderRadius: '0px !important',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${addAlpha(
        theme.palette.grey['200'],
        0.5,
      )}`,
    },
    [theme.breakpoints.down('desktopApp')]: {
      height: 50,
      fontSize: 16,
      lineHeight: '18px',
    },
  },
  '& .employeeCareerDevButton': {
    height: 44,
    width: 180,
    marginLeft: 15,
    fontSize: 14,
  },
  '& .employeeJobsTabs': {
    minHeight: 'auto',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  '& .employeeJobsTab': {
    minHeight: 'auto',
    minWidth: 'auto',
    height: 38,
    padding: 0,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    textTransform: 'capitalize',
    opacity: 1,
    color: theme.palette.common.white,
    borderRadius: 19,
    '&:not(:first-of-type)': {
      marginLeft: 20,
    },
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      '&:not(:first-of-type)': {
        marginLeft: 24,
      },
    },
    '&.Mui-selected': {
      padding: '0 34px',
      color: theme.palette.grey['700'],
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down('desktopApp')]: {
        padding: '0 24px',
      },
    },
  },
  '& .employee-grow-header': {
    '& .returnLink': {
      borderColor: theme.palette.common.white,
      color: theme.palette.text.primary,
    },
    '& .title': {
      color: theme.palette.text.primary,
    },
    '& .chatButton, .profileLink, .faqButton': {
      borderColor: theme.palette.primary.light,
    },
    '& .growIconButton': {
      backgroundColor: theme.palette.primary.light,
    },
  },
});
