import React, { useEffect, useContext } from 'react';
import { Box, styled } from 'components';
import styles from 'styles/Retention/Organization/OrganizationOverview';
import { useLazyQuery } from '@apollo/client';
import {
  GET_EMPLOYER_JOB_METRICS,
  GET_ORGANIZATION_STATS,
} from 'api';
import { Button, Spinner } from 'components/shared';
import get from 'lodash/get';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MdArrowForward } from '../../../icons';
import { getRoutes } from '../../../../utils';
import { RetentionContext } from '../../utils';

const StyledRoot = styled(Box)(styles);

const RenderOverviewSection = ({ organizationId }) => {
  const [
    fetchRetentionMetrics,
    {
      data: retentionMetricsData,
      loading: retentionMetricsLoading = true,
    },
  ] = useLazyQuery(GET_ORGANIZATION_STATS, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  useEffect(() => {
    fetchRetentionMetrics();
  }, []);

  if (retentionMetricsLoading) {
    return <Spinner />;
  }

  const orgStats = retentionMetricsData?.getOrganizationStats;

  if (orgStats == null) {
    return (
      <Typography variant={'h3'}>
        There are no stats for your organization at this time
        come back in a little while.
      </Typography>
    );
  }

  return (
    <Box className="statBlockRow">
      {Object.values(orgStats).map((metric) =>
        get(metric, 'name', null) ? (
          <Box
            key={metric.name}
            className="statBlock"
          >
            <Typography className="statTitle" variant={'body1'}>
              {metric.name}
            </Typography>
            <Box className="stats">
              <Box className="overallStat">
                {metric.currentValue}
              </Box>
            </Box>
          </Box>
        ) : null,
      )}
    </Box>
  );
};

const RenderJobOverviewSection = () => {
  const [
    fetchJobMetrics,
    {
      data: jobMetricsData = {},
      loading: jobsMetricsLoading = true,
    },
  ] = useLazyQuery(GET_EMPLOYER_JOB_METRICS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  useEffect(() => {
    fetchJobMetrics();
  }, []);

  if (jobsMetricsLoading) {
    return <Spinner />;
  }

  const jobMetrics = jobMetricsData?.employerJobMetrics;

  const metricData = [
    { title: 'Applied', value: jobMetrics?.applicantApplied },
    { title: 'Matches', value: jobMetrics?.matchCount },
    { title: "Todo's", value: jobMetrics?.todo },
    { title: 'Jobs', value: jobMetrics?.jobCount },
    { title: 'Hired', value: jobMetrics?.applicantHired },
    {
      title: 'Next Steps',
      value: jobMetrics?.applicantNextSteps,
    },
    { title: 'Rejected', value: jobMetrics?.applicantRejected },
    { title: 'Saved', value: jobMetrics?.applicantStarred },
  ];

  return (
    <div className="statBlockRow">
      {jobMetrics &&
        metricData.map(({ title, value }) => (
            <Box key={title} className="statBlock jobStatBlock">
              <p className="statTitle">{title}</p>
              <Box className="stats">
                <Box className="overallStat">{value}</Box>
              </Box>
            </Box>
          ))}
    </div>
  );
};

const OrganizationOverview = () => {
  const ctx = useContext(RetentionContext);
  const routes = getRoutes();
  const navigate = useNavigate();

  return (
    <StyledRoot className={'wrapper'}>
      <Box className="overviewSection">
        <Box className="titleBlock">
          <h2>Retention</h2>
        </Box>
        <RenderOverviewSection
          organizationId={ctx.organizationId}
        />
        <Box className="titleBlock">
          <Typography variant={'h2'}>Jobs</Typography>
          <Button
            endIcon={<MdArrowForward />}
            variant={'text'}
            onClick={() => {
              navigate(routes.employer.enterprise_grow, {
                state: {
                  navigateToRegionalManagement: true,
                },
              });
            }}
          >
            View All Jobs
          </Button>
        </Box>
        <RenderJobOverviewSection />
      </Box>
    </StyledRoot>
  );
};

export default OrganizationOverview;
