import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Box, styled } from 'components';
import { RetentionContext } from 'components/Retention/utils';
import React, { useContext, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Button } from 'components/shared';
import { MdChevronLeft, MdChevronRight } from 'components/icons';
import { getRoutes } from 'utils';
import interactionsStyles from 'styles/Retention/Interactions';

const StyledBox = styled(Box)(interactionsStyles);

const Interactions = () => {
  const location = useLocation();
  const ctx = useContext(RetentionContext);
  const navigate = useNavigate();
  const routes = getRoutes();

  const [currentPathProps, setCurrentPathProps] = useState<{
    title: string;
    subTitle?: string;
    buttonText?: string;
    link?: string;
  } | null>({
    title: '',
    subTitle: '',
    buttonText: '',
    link: '',
  });

  if (!location?.state) {
    navigate(
      `${routes.employer.retention_portal.location}/${ctx.retentionPages.pulse}`,
    );
  }

  const surveyPageRoute = `${routes.employer.retention_portal.interactions.survey}`;

  const interactionRoutes =
    routes.employer.retention_portal.interactions;

  useEffect(() => {
    switch (location.state.interactionType) {
      case ctx.interactionTypes.survey:
        setCurrentPathProps({
          title: 'Survey',
          subTitle:
            'Select a survey template or start a new Survey',
          buttonText: 'Create New Survey',
          link: interactionRoutes.new_survey,
        });
        navigate(interactionRoutes.survey, {
          state: location.state,
        });
        break;
      case ctx.interactionTypes.custom:
        setCurrentPathProps({
          title: 'Custom Message',
        });
        navigate(interactionRoutes.custom, {
          state: location.state,
        });
        break;
      case ctx.interactionTypes.review:
        setCurrentPathProps({
          title: 'Review Team Member',
        });
        navigate(interactionRoutes.review, {
          state: location.state,
        });
        break;
      case ctx.interactionTypes.anniversary:
        setCurrentPathProps({
          title: 'Work Anniversary',
        });
        navigate(interactionRoutes.anniversary, {
          state: location.state,
        });
        break;
      default:
        return null;
    }
    return null;
  }, []);

  return (
    <StyledBox>
      <Box className="header">
        <Box className="navButtonWrapper">
          <Button
            onClick={() =>
              navigate(
                `${routes.employer.retention_portal.location}/pulse`,
              )
            }
            variant="outlined-secondary"
            startIcon={<MdChevronLeft />}
          >
            Back
          </Button>
          {currentPathProps.link &&
            location.pathname === surveyPageRoute && (
              <Button
                variant="filled-primary"
                endIcon={<MdChevronRight />}
                onClick={() =>
                  navigate(currentPathProps.link, {
                    replace: true,
                    state: location.state,
                  })
                }
              >
                {currentPathProps.buttonText}
              </Button>
            )}
        </Box>
        {location.pathname === surveyPageRoute ? (
          <Box className={'interaction-title-container'}>
            <Box className={'titleWrapper'}>
              <Typography variant="h1">
                {currentPathProps.title}
              </Typography>
              {currentPathProps.subTitle && (
                <Typography variant="h4">
                  {currentPathProps.subTitle}
                </Typography>
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
      <Outlet />
    </StyledBox>
  );
};

export default Interactions;
