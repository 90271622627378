import { useLazyQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { Box } from 'components';
import { Spinner } from 'components/shared';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect } from 'react';
import { GET_PATH_BY_LOCATIONS } from 'api';
import BrandDashboardRenderer from '../../../../components/Retention/components/Brand/BrandDashboardRenderer';
import PathCard from '../../../../components/Retention/components/Brand/PathCard';
import { IEmployerBrandRenderArgs } from '../../../../components/Retention/types';
import { RetentionContext } from '../../../../components/Retention/utils';

const BrandPathEntry = () => {
  const { brandName, brandId, setRetentionContext } =
    useContext(RetentionContext);

  const [getPathByLocations, { data, loading = true }] =
    useLazyQuery(GET_PATH_BY_LOCATIONS, {
      variables: {
        brandId,
        limit: 400,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
    });

  useEffect(() => {
    getPathByLocations();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box>
      <BrandDashboardRenderer
        data={data}
        loading={loading}
        title={brandName}
        render={({
          cardsData,
          navigate,
          locationRoute,
        }: IEmployerBrandRenderArgs) => {
          const pathByLocationsData =
            cardsData?.getPathByLocations?.results;
          if (isEmpty(pathByLocationsData)) {
            return (
              <Typography variant={'h3'}>
                There are no locations for your Brand. Please
                contact support to resolve this issue
              </Typography>
            );
          }

          const handleNavigation = (
            id: number,
            name: string,
          ) => {
            setRetentionContext({
              locationId: id,
              locationName: name,
            });
            navigate(`${locationRoute}/path`);
          };
          return pathByLocationsData.map((props, idx) => {
            // only way to do this since we do not have a ton of data in places
            const indexKey = `${props.employerName}-${idx}`;
            return (
              <PathCard
                {...props}
                key={indexKey}
                handleNavigation={handleNavigation}
              />
            );
          });
        }}
      />
    </Box>
  );
};

export default BrandPathEntry;
