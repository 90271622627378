import Typography from '@mui/material/Typography';
import { Box } from '../material-ui';

const CenteredError = ({
  errorMessage,
  RenderComponent,
}: {
  errorMessage?: string;
  RenderComponent: JSX.Element;
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      textAlign: 'center',
    }}
  >
    {errorMessage && (
      <Typography variant="h2">{errorMessage}</Typography>
    )}
    {RenderComponent && <RenderComponent />}
  </Box>
);

export default CenteredError;
