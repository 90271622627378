import React from 'react';
import { Box } from 'components';
import OverviewAccordion from '../../../../components/Retention/components/Organization/OverviewAccordion';
import OrganizationOverview from '../../../../components/Retention/components/Organization/OrganizationOverview';
import OrganizationBrands from '../../../../components/Retention/components/Organization/OrganizationBrands';
import OrganizationLocations from '../../../../components/Retention/components/Organization/OrganizationLocations';

const OrganizationEntry = () => (
  <Box className={'organization-dashboard'}>
    <OverviewAccordion title="Overview">
      <OrganizationOverview />
    </OverviewAccordion>
    <OverviewAccordion title="Brands">
      <OrganizationBrands />
    </OverviewAccordion>
    <OverviewAccordion title="Locations">
      <OrganizationLocations />
    </OverviewAccordion>
  </Box>
);

export default OrganizationEntry;
