import React, { useEffect, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import LiveChat from 'react-livechat';
import TagManager from 'react-gtm-module';
import { authVar, client } from 'api';
import {
  AdapterDayjs,
  CssBaseline,
  LocalizationProvider,
  ThemeProvider,
} from 'components';
import { AppRouter } from 'router';
import { useAppStore, useAppStoreSelector } from 'store';
import theme from 'styles/theme';
import { fbPixel } from 'utils/fbpixel';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import {
  createTheme,
  StyledEngineProvider,
  Theme,
} from '@mui/material';

const {
  LIVE_CHAT_LICENSE,
  GTM_ID,
  FB_PIXEL_ID,
  GOOGLE_CLIENT_ID,
  RUM_AWS_GUEST_ROLE_ARN,
  RUM_AWS_IPOOL_ID,
  RUM_AWS_APP_ID,
  RUM_AWS_SAMPLE_RATE,
  NODE_ENV,
  COLLECT_RUM_ANALYTICS,
} = process.env;

const NUM_LIVE_CHAT_LICENSE = Number(LIVE_CHAT_LICENSE);

if (GTM_ID) TagManager.initialize({ gtmId: GTM_ID });
if (FB_PIXEL_ID) fbPixel();

const appLogout = useAppStore.getState().logout;
authVar({ logout: () => appLogout() });

try {
  if (
    NODE_ENV === 'production' ||
    // This will fail if you set COLLECT_RUM_ANALYTICS to
    // true and do not have the correct RUM envs
    (NODE_ENV === 'development' &&
      COLLECT_RUM_ANALYTICS === 'true')
  ) {
    const config: AwsRumConfig = {
      sessionSampleRate: Number(RUM_AWS_SAMPLE_RATE) || 0,
      guestRoleArn: RUM_AWS_GUEST_ROLE_ARN,
      identityPoolId: RUM_AWS_IPOOL_ID,
      endpoint: 'https://dataplane.rum.us-east-2.amazonaws.com',
      telemetries: ['errors', 'http'],
      allowCookies: true,
      enableXRay: false,
    };

    const APPLICATION_ID: string = RUM_AWS_APP_ID;
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-2';

    new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
  }
} catch (error) {
  console.error('error when trying awsRum');
  console.error(error);
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const App = () => {
  const { colorMode, colors, setTheme } = useAppStoreSelector(
    ({
      colorMode: baseColorMode,
      colors: baseColors,
      setTheme: setThemeStore,
    }) => ({
      colorMode: baseColorMode,
      colors: baseColors,
      setTheme: setThemeStore,
    }),
  );
  useEffect(() => {
    const themeColors = localStorage.getItem('themeColors');
    if (themeColors != null) {
      // set initial theme
      setTheme(JSON.parse(themeColors));
    }
  }, []);

  const themeMemo: Theme = useMemo(
    () => createTheme(theme(colors[colorMode])),
    [colors, colorMode],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ApolloProvider client={client}>
        <ThemeProvider theme={themeMemo}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline />
              <AppRouter />
            </LocalizationProvider>
          </GoogleOAuthProvider>
          {LIVE_CHAT_LICENSE && (
            <LiveChat license={NUM_LIVE_CHAT_LICENSE} />
          )}
        </ThemeProvider>
      </ApolloProvider>
    </StyledEngineProvider>
  );
};

export default App;
