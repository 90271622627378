import Typography from '@mui/material/Typography';
import React from 'react';
import { styled } from '@mui/material/styles';
import OnboardingLayout from './OnboardingLayout';
import { Box } from '../../material-ui';
import CustomStepperButtonRow from '../../shared/StepNavigation/CustomStepperButtonRow';
import { Button } from '../../shared';

const StyledBox = styled(Box)(({ theme }) => ({
  '.video': {
    minHeight: 230,
    maxWidth: 406,
    marginBottom: 40,
    borderRadius: 14,
  },
  '.resourcesContainer': {
    display: 'grid',
    gridTemplateColumns: `calc(33% - ${theme.spacing(
      1,
    )}) calc(33% - ${theme.spacing(
      1,
    )}) calc(33% - ${theme.spacing(1)})`,
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('desktopApp')]: {
      gridTemplateColumns: `calc(50% - ${theme.spacing(
        1,
      )}) calc(50% - ${theme.spacing(1)})`,
    },
  },
  '.resourcesCard': {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1.5),
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    '& > div:last-of-type': {
      marginTop: theme.spacing(1.5),
    },
  },
}));

const Resources = ({ next, prev, resources }) => {
  const handleResource = (resource) => () => {
    console.log(resource);
  };

  return (
    <OnboardingLayout>
      <StyledBox>
        <Typography variant={'h1'}>Resources</Typography>
        <Box className={'resourcesContainer'}>
          {resources.map(({ resource }) => (
            <Box
              key={`resources-card-${resource.id}`}
              className={'resourcesCard'}
            >
              <Box>
                <Typography variant={'h3'}>
                  {resource.title}
                </Typography>
                {/*<Typography variant={'body1'}>*/}
                {/*  {resource.description}*/}
                {/*</Typography>*/}
              </Box>
              <Box>
                <Button
                  variant={'outlined-primary'}
                  onClick={handleResource(resource)}
                >
                  {['image', 'document'].includes(resource.type)
                    ? 'Download'
                    : 'view'}
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
        <CustomStepperButtonRow prev={prev} next={next} />
      </StyledBox>
    </OnboardingLayout>
  );
};

export default Resources;
