import React from 'react';
import { SvgIcon } from 'components';

const GrowBadgeIcon14 = (props) => (
    <SvgIcon viewBox="0 0 39.58 36.41" {...props}>
      <polygon
        points="39.58 27.87 33.24 16.88 26.81 16.39 31.4 24.34 8.1 22.58 11.61 28.66 26.04 29.75 15.69 35.73 24.78 36.41 39.58 27.87"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="0 8.54 6.35 19.53 12.77 20.02 8.18 12.07 31.48 13.84 27.97 7.75 13.54 6.66 23.89 .69 14.8 0 0 8.54"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )

export default GrowBadgeIcon14;
