import { Typography } from '@mui/material';
import React from 'react';
import { v4 } from 'uuid';
import { Box, styled } from 'components';
import { ISurvey } from 'components/Retention/types';
import styles from 'styles/Retention/Survey/Review';

const StyledBox = styled(Box)(styles);

interface ReviewProps {
  survey: ISurvey;
}

const Review = ({ survey }: ReviewProps) => (
    <StyledBox>
      <Typography variant={'h1'}>Review</Typography>
      <Box className="form-container">
        <Typography variant={'h4'} className={'to-recipients'}>
          To: {survey.recipients.recipientIds.length} Recipients
        </Typography>
        <Typography variant={'h4'} className={'survey-title'}>
          Title: {survey.name}
        </Typography>
        <Box className={'review-questions'}>
          {survey.questions.map((q, idx) => (
            <Box
              key={`question-container__${v4()}`}
              className={'question-container'}
            >
              <Box className="header">
                <Typography>
                  Question {idx + 1}: {q.question}
                </Typography>
              </Box>
              {q.answers.map((a, aIdx) => (
                <Box
                  key={`answers__${v4()}`}
                  className="answers"
                >
                  <Typography>
                    Answer {aIdx + 1}: Written Response
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </StyledBox>
  );

export default Review;
