import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { getUserId } from 'utils';
import { SET_TIMEZONE } from 'api';
import useEmployeeProfileQuery from './useEmployeeProfileQuery';
import useEmployerProfileQuery from './useEmployerProfileQuery';

function useTimezoneCommands(props = {}) {
  const [setTimezone] = useMutation(SET_TIMEZONE, {
    fetchPolicy: 'no-cache',
    ...props,
  });

  const { refetch: refetchEmployeeProfile } =
    useEmployeeProfileQuery({ autoFetch: false });
  const { refetch: refetchEmployerProfile } =
    useEmployerProfileQuery({ autoFetch: false });

  const saveTimezone = useCallback(
    async (timeZone, role, userId?: number) => {
      if (timeZone && role) {
        // let refetchQueries = [];
        // if (role === 'employer') refetchQueries = [GET_EMPLOYER_PROFILE];
        // if (role === 'employee') refetchQueries = [GET_USER_PROFILE];

        await setTimezone({
          variables: {
            timeZone,
            user_id: userId || Number(getUserId()),
          },
          // @TODO: refetchQueries doesn't work for current apollo version. Use it instead of profile hooks
          // refetchQueries
        });
        if (role === 'employer') await refetchEmployerProfile();
        if (role === 'employee') await refetchEmployeeProfile();
      } else {
        throw new Error('saveTimezone: missed params');
      }
    },
    [
      refetchEmployeeProfile,
      refetchEmployerProfile,
      setTimezone,
    ],
  );

  return useMemo(() => ({ saveTimezone }), [saveTimezone]);
}

export default useTimezoneCommands;
