import { Box } from 'components';
import Typography from '@mui/material/Typography';
import React from 'react';
import FlexRow from '../../../shared/FlexRow';
import Chip from '@mui/material/Chip';
import { SpringModal } from '../../../shared';
import { SkillsForm } from '../../../dialogs/components';

const RenderSkills = ({ skills, register }) => (
  <Box className={'sectionContainer chipContainer'}>
    <Typography variant={'h2'}>Skills</Typography>
    <FlexRow>
      {skills.fields.length === 0 && (
        <Typography variant={'body1'}>No Skills</Typography>
      )}
      {skills.fields.map((field, index) => (
        <Chip
          {...register(`skills.${index}.value`)}
          label={field.name}
          key={field.id}
          variant={'filled'}
          color={'primary'}
          onDelete={() => skills.remove(index)}
        />
      ))}
    </FlexRow>

    {/*Fake on success to get the button to show and close modal.*/}
    <SpringModal
      buttonText={'Add Skills'}
      onSuccess={() => null}
      buttonProps={{
        size: 'small',
        variant: 'outlined',
        color: 'secondary',
      }}
    >
      <SkillsForm
        form={{ skills: skills.fields }}
        fieldName={'skills'}
        withSubmitBtn={false}
        withSkipBtn={false}
        onChange={({ skills: skillsToAdd }) =>
          skills.replace(skillsToAdd)
        }
      />
    </SpringModal>
  </Box>
);

export default RenderSkills;
