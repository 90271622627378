import React from 'react';
import PT from 'prop-types';
import { useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { GrowRecentBadgeIcon } from 'components/icons';
import PageHeader from '../../Employee/PageHeader';
import ScoreCard from './ScoreCard';

const StyledRoot = styled('div')(({ theme }) => ({
  '& .scorePage__cardsContainer': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 16,
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 20px 24px',
      gridTemplateColumns: '1fr',
    },
  },
}));

const ScorePage = (props) => {
  const { sectionScores } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  return (
    <StyledRoot>
      {isDesktop && (
        <PageHeader
          title="GROW SCORE"
          subtitle="See how you stack up"
          icon={<GrowRecentBadgeIcon />}
        />
      )}
      <div className="scorePage__cardsContainer">
        {sectionScores.map(
          ({ attributes, careerScoreType, percent }) => (
            <ScoreCard
              key={`scoreCard__${careerScoreType.name}`}
              score={percent}
              scoreType={careerScoreType.name}
              scoreAttrs={attributes}
            />
          ),
        )}
      </div>
    </StyledRoot>
  );
};

ScorePage.propTypes = {
  sectionScores: PT.arrayOf(
    PT.shape({
      attributes: PT.arrayOf(
        PT.shape({
          complete: PT.bool,
          label: PT.string,
          value: PT.number,
        }),
      ),
      careerScoreTypeId: PT.number,
      careerScoreType: PT.shape({
        name: PT.string,
      }),
      impact: PT.shape({
        name: PT.string,
      }),
      percent: PT.number,
      score: PT.number,
    }),
  ).isRequired,
};

export default ScorePage;
