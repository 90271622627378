import { makeForm } from 'components/form';
import {
  DEFAULT_SCHEDULE_CRITERIA,
  MIN_COUNT,
  getInitialEmployerSchedule
} from 'utils/schedules/schedules';

const [FormProvider, useSchedulesForm] = makeForm({
  initial: {
    schedules: [getInitialEmployerSchedule()],
    criteria: { ...DEFAULT_SCHEDULE_CRITERIA }
  },
  validations: {
    'schedules.*.count': { numericality: { greaterThanOrEqual: MIN_COUNT } },
    'schedules.*.length': 'presence',
    'schedules.*.startDate': {
      rules: ['presence', 'date'],
      deps: ['schedules.*.endDate']
    }
  }
});

export { FormProvider, useSchedulesForm };
