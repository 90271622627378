import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import { IconButton } from 'components/shared';
import { MdArrowBack } from 'components/icons';

const StyledRoot = styled('div', {
  shouldForwardProp: (propName) => propName !== 'withLeftOffset' && propName !== 'withRightOffset'
})(({ theme, withLeftOffset, withRightOffset }) => ({
  '&.dialogHeader': {
    padding: '13px 13px 12px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    ...(withRightOffset && { paddingRight: 45 }),
    ...(withLeftOffset && { paddingLeft: 45 })
  },
  '& .dialogTitle': {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      lineHeight: '18px',
      color: theme.palette.common.white
    }
  }
}));

const ChatDialogHeader = (props) => {
  const { title, withCloseButton, closeButtonIcon, leftElement, rightElement, onClose } = props;

  return (
    <StyledRoot
      className="dialogHeader"
      withLeftOffset={!leftElement && !withCloseButton && rightElement}
      withRightOffset={!rightElement && (leftElement || withCloseButton)}
    >
      {withCloseButton && (
        <IconButton color="primary" onClick={onClose} testID="chat-close-button">
          {closeButtonIcon || <MdArrowBack />}
        </IconButton>
      )}
      {leftElement}
      <h2 className="dialogTitle">{title}</h2>
      {rightElement}
    </StyledRoot>
  );
}

ChatDialogHeader.propTypes = {
  closeButtonIcon: PT.element,
  leftElement: PT.element,
  onClose: PT.func,
  rightElement: PT.element,
  title: PT.string,
  withCloseButton: PT.bool
};

ChatDialogHeader.defaultProps = {
  closeButtonIcon: null,
  leftElement: null,
  onClose: () => {},
  rightElement: null,
  title: '',
  withCloseButton: false
};

export default ChatDialogHeader;
