import { PaletteOptions, ThemeOptions } from '@mui/material';
import { BreakpointOverrides } from '@mui/system/createTheme/createBreakpoints';
import components from './themeComponents';
import { isNative, scaleFont } from './utils';

declare module '@mui/system/createTheme/createBreakpoints' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
    desktopApp: true;
    largeDesktop: true;
  }
}

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    breakpoint?: BreakpointOverrides;
  }
}

const theme: (palette: PaletteOptions) => ThemeOptions = (
  palette,
) => ({
  spacing: isNative ? 8 : 6,
  palette,
  components: components(palette),
  breakpoints: {
    values: {
      desktopApp: 1024,
      mobile: 0,
      tablet: 481,
      laptop: 768,
      desktop: 1024,
      largeDesktop: 1200,
    },
  },
  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(', '),
    h1: {
      fontSize: scaleFont(32),
      fontWeight: 800,
      color: palette.text.primary,
    },
    h2: {
      fontSize: scaleFont(20),
      fontWeight: 800,
      color: palette.text.primary,
    },
    h3: {
      fontSize: scaleFont(15),
      fontWeight: 800,
      color: palette.text.primary,
    },
    h4: {
      fontSize: scaleFont(13),
      fontWeight: 800,
      color: palette.text.primary,
    },
    h5: {
      fontSize: scaleFont(10),
      fontWeight: 800,
      color: palette.text.primary,
    },
    button: {
      fontSize: scaleFont(11),
    },
    body1: {
      fontSize: scaleFont(15),
      fontWeight: 200,
      color: palette.text.primary,
    },
    body2: {
      fontSize: scaleFont(12),
      fontWeight: 400,
      color: palette.text.primary,
    },
    subtitle1: {
      fontSize: scaleFont(31),
      fontWeight: 'bold',
      color: palette.text.primary,
    },
    subtitle2: {
      color: palette.text.primary,
    },
    caption: {
      color: palette.text.primary,
    },
  },
  mixins: {
    helperText: {
      margin: '8px 0',
      fontSize: scaleFont(16),
      fontWeight: 'bold',
      lineHeight: '17px',
    },
  },
});

export default theme;
