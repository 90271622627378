export default ({ theme }) => ({
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('desktopApp')]: {
    padding: theme.spacing(2),
  },
});

export const dashboardHeaderStyles = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .dashboard-title': {},
});

export const comingSoonStyles = ({ theme }) => ({
  '& .coming__soon__wrapper': {
    width: '100%',
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .icon__wrapper': {
      display: 'flex',
      fontSize: 64,
      color: theme.palette.secondary.main,
    },
    '& .coming__soon_text__wrapper': {
      maxWidth: 640,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 24,
      '& .coming__soon__text': {
        color: theme.palette.text.primary,
        marginBottom: 16,
        textAlign: 'center',
        '& span': {
          fontSize: 18,
        },
      },
    },
  },
});
