import { useMutation, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  EMPLOYEE_REVIEW_BY_CODE,
  UPDATE_EMPLOYEE_REVIEW,
} from '../../api/queries/reviews';
import { styled } from '../../components';
import { MdChevronRight } from '../../components/icons';
import { Button, Spinner } from '../../components/shared';
import Avatar from '../../components/shared/Avatar';
import SmileRating from '../../components/shared/Rating/SmileRating';
import { useAuth } from '../../hooks';
import { getRoutes } from '../../utils';

const StyledRoot = styled('div')(() => ({
  '&.container': {
    height: '100%',
    margin: 'auto',
  },
  '& .reviewDashboard': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '10px',
  },
  '& .line1': {
    padding: '10px 5px 5px 5px',
    marginBottom: '15px',
  },
  '& .line2': {
    padding: '0 5px 10px 5px',
    marginBottom: '15px',
  },
  '& .review-error': {
    textAlign: 'center',
  },
}));

const ROUTES = getRoutes();

const ReviewView = () => {
  const { authed: isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const routerLocation = useLocation();

  if (!isAuthenticated) {
    navigate(ROUTES.login.default, {
      state: { from: { location: routerLocation } },
    });
  }

  const [rating, setRating] = useState<number | null>(null);
  const [complete, setComplete] = useState<boolean>(false);
  const { code } = useParams();

  const { data: reviewData, loading: isLoading } = useQuery(
    EMPLOYEE_REVIEW_BY_CODE,
    {
      variables: {
        code,
      },
    },
  );

  const [submitRating] = useMutation(UPDATE_EMPLOYEE_REVIEW, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (resData) => {
      if (resData?.updateEmployeeReviewEmployee?.reviewId) {
        setComplete(true);
      }
    },
  });

  const onSubmitRating = () => {
    if (rating) {
      submitRating({
        variables: {
          reviewId: reviewData?.employeeReviewByCode.reviewId,
          rating,
          ratingReasonId: null,
        },
      });
    }
  };

  const rateChanged = (rate: number) => {
    setRating(rate);
  };

  const showComplete = () => (
    <Box className="reviewDashboard">
      <Typography variant={'h1'} className="line1">
        Employee Rating Complete
      </Typography>
      <Typography variant={'h2'} className="line2">
        Thank you!
      </Typography>
      <Button
        endIcon={<MdChevronRight />}
        variant="filled-primary"
        className="buttonRight"
        onClick={() => navigate(ROUTES.employee.dashboard)}
        disabled={!rating}
      >
        <Typography>Go to dashboard</Typography>
      </Button>
    </Box>
  );

  return (
    <StyledRoot className="container">
      {isLoading ? (
        <Spinner size={26} />
      ) : (
        <Box>
          {complete ? (
            showComplete()
          ) : (
            <Box className="reviewDashboard">
              <Avatar
                width={75}
                height={75}
                type="EMPLOYEE"
                url={
                  reviewData?.employeeReviewByCode.reviewee
                    .imageUrl
                }
              />
              <Typography className="line1" variant="h3">
                {reviewData?.employeeReviewByCode.reviewee.name}
              </Typography>
              <Box>
                <Typography className="line2" variant="h4">
                  How would you rate{' '}
                  {
                    reviewData?.employeeReviewByCode.reviewee
                      .name
                  }
                  ?
                </Typography>
                <SmileRating onRateChange={rateChanged} />
                <Box>
                  <Button
                    variant="filled-primary"
                    className="buttonRight"
                    onClick={() => onSubmitRating()}
                    disabled={!rating}
                  >
                    <Typography>Submit</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </StyledRoot>
  );
};

export default ReviewView;
