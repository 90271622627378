import React, { useEffect, useRef, useState } from 'react';
import PT from 'prop-types';
import {
  CategoryScale,
  Chart,
  BarController,
  BarElement,
  Legend,
  LinearScale,
  LineController
} from 'chart.js';
import { styled } from 'components';
import { getBarChartData } from 'components/Grow/employer/charts/util';
import { Spinner } from 'components/shared';

Chart.register(CategoryScale, BarController, BarElement, Legend, LinearScale, LineController);

const StyledRoot = styled('div')(() => ({
  '&.tenureChart': {},
  '& .tenureChart__content': {
    flex: 1,
    paddingTop: 20,
    maxWidth: 'calc(1024px / 3 - 100)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(0.875)'
  },
  '& .tenureChart__canvas': {
    width: '100%',
    height: '100%'
  }
}));

const EmployeeTenure = ({ showLegend, chartLabels, chartData }) => {
  const [isDataLoading] = useState(false);
  const chartCtxRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartCtxRef.current && chartData != null) {
      if (chartRef.current) chartRef.current.destroy();

      chartRef.current = new Chart(chartCtxRef.current, {
        type: 'bar',
        data: {
          ...getBarChartData({ data: chartData, labels: chartLabels, rootLabel: '# of Months' })
        },
        options: {
          indexAxis: 'x',
          plugins: {
            legend: {
              display: showLegend,
              position: 'bottom',
              labels: {
                color: '#ffffff'
              }
            }
          },
          scales: {
            x: {
              grid: {
                color: 'rgba(255,255,255,0.1)',
                borderColor: 'white'
              },
            },
            y: {
              grid: {
                color: 'rgba(255,255,255,0.1)',
                borderColor: 'white'
              }
            }
          }
        }
      });
    }
  }, [chartCtxRef.current, chartData]);

  return (
    <StyledRoot className="tenureChart chartCard">
      {isDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Avg Tenure</h4>
      <div className="tenureChart__content">
        <canvas id="tenure-chart" height="225" className="tenureChart__canvas" ref={chartCtxRef} />
      </div>
    </StyledRoot>
  );
}

EmployeeTenure.propTypes = {
  showLegend: PT.bool
};

EmployeeTenure.defaultProps = {
  showLegend: true
};

export default EmployeeTenure;
