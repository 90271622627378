import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { EMPLOYEE_TUTORIAL_URL } from 'utils';
import { Box, styled } from 'components';
import {
  CheckBox,
  Button,
  IconButton,
  Spinner,
} from 'components/shared';
import { MdClose } from 'components/icons';
import { GET_GROW_INDUSTRIES } from 'api';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useGrowJobTypes from './useGrowJobTypes';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.introContainer': {
    flex: 1,
    height: '100%',
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'auto',
  },
  '& .introContentContainer': {
    paddingTop: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      flex: 1,
      marginBottom: 40,
    },
  },
  '& .introModalBackground': {
    width: 500,
    padding: '3rem',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 0 46px rgba(0, 0, 0, .25)',
    borderRadius: 17,
    position: 'relative',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 348,
      padding: '1rem',
    },
  },
  '& .introSelectContainer': {
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '& .introCheckbox': {
    '& .StyledCheckbox-label': {
      marginLeft: 15,
      fontSize: 14,
      fontWeight: 700,
    },
    '& .StyledCheckbox-checkbox': {
      width: 24,
      height: 24,
      border: '1px solid #D9D9D9',
      borderRadius: 8,
    },
  },
  '& .introActionContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .title': {
    marginBottom: 14,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 'bold',
    lineHeight: '23px',
    color: '#FFF',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 6,
      fontSize: 26,
      lineHeight: '29px',
    },
  },
  '& .subTitle': {
    maxWidth: 294,
    marginBottom: 23,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '13px',
    color: '#FFF',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 320,
      fontSize: 15,
      lineHeight: '17px',
    },
  },
  '& .videoTitle': {
    maxWidth: 304,
    marginBottom: 9,
    textAlign: 'center',
    fontSize: 9,
    lineHeight: '10px',
    color: '#FFF',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 320,
      marginBottom: 12,
      fontSize: 13,
      lineHeight: '14px',
    },
  },
  '& .videoContainer': {
    position: 'relative',
    width: 388,
    height: 226,
    marginBottom: 18,
    backgroundColor: '#000',
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%',
      maxWidth: 300,
      height: 188,
      marginBottom: 23,
    },
  },
  '& .videoCloseBtn': {
    position: 'absolute',
    top: -5,
    right: -5,
    width: 25,
    height: 25,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('desktopApp')]: {
      width: 21,
      height: 21,
    },
  },
  '& .introError': {
    margin: '10px 0',
    textAlign: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 13,
    },
  },
}));

const GrowInitialSetup = ({
  onSave,
  loading,
  isSetupFailed,
}) => {
  const [chosenIndustry, setChosenIndustry] = useState(null);
  const [currentJob, setCurrentJob] = useState(null);
  const [futureJob, setFutureJob] = useState(null);
  const [currentlySearching, setCurrentlySearching] =
    useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(true);

  const {
    getJobTypesByIndustryId,
    getPathGroups,
    currentJobTypesOptions,
    futureJobTypesOptions,
    typesLoading,
  } = useGrowJobTypes({
    curJobTypeId: currentJob,
    futureJobTypeId: futureJob,
  });

  const [
    fetchIndustries,
    { data: industriesData, loading: industriesLoading },
  ] = useLazyQuery(GET_GROW_INDUSTRIES);
  const industries = industriesData?.industries || [];

  const isJobTypeSelectLoading =
    typesLoading || industriesLoading;

  useEffect(() => {
    fetchIndustries();
  }, []);

  useEffect(() => {
    if (chosenIndustry) {
      getJobTypesByIndustryId(Number(chosenIndustry));
      if (currentJob) getPathGroups(currentJob);
    }
  }, [chosenIndustry]);

  const onSubmit = () => {
    onSave({
      isNew: true,
      industryId: chosenIndustry,
      curJobTypeId: currentJob,
      futureJobTypeId: futureJob,
      currentlySearching,
    });
  };

  const handleIndustryChange = async (e) => {
    setCurrentJob('');
    setFutureJob('');
    setChosenIndustry(e.target.value);
  };

  const isButtonDisabled =
    (Number(currentJob) && currentlySearching) ||
    !futureJob ||
    !chosenIndustry ||
    loading;

  return !industries ? null : (
    <StyledRoot className="introContainer">
      <div className="introContentContainer">
        <h1 className="title">Congratulations</h1>
        <p className="subTitle">
          on starting your journey to a better career and
          ultimately a better life!
        </p>
        {isVideoVisible && (
          <>
            <p className="videoTitle">
              Check out this video to see how you can improve
              your career
            </p>
            <div className="videoContainer">
              <IconButton
                variant="outlined"
                className="videoCloseBtn"
                onClick={() => setIsVideoVisible(false)}
              >
                <MdClose
                  color="primary"
                  style={{ fontSize: 18 }}
                />
              </IconButton>
              <iframe
                title="tutorial"
                width="100%"
                height="100%"
                src={EMPLOYEE_TUTORIAL_URL}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </>
        )}
        <div className="introModalBackground">
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
          >
            <div className="introSelectContainer">
              <Select
                label={'Choose an Industry'}
                value={chosenIndustry}
                required
                variant={'outlined'}
                placeholder={'Choose an industry'}
                onChange={handleIndustryChange}
                testID="wt-grow-industry-select"
                size={'small'}
                fullWidth
              >
                {industries.map((ind) => (
                  <MenuItem value={ind.industry_id}>
                    {ind.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="introSelectContainer">
              <Select
                label="Where I'm at:"
                value={currentJob}
                required
                variant={'outlined'}
                placeholder="Choose a position"
                onChange={(e) => setCurrentJob(e.target.value)}
                testID="wt-grow-current-job-select"
                size={'small'}
                disabled={
                  isJobTypeSelectLoading ||
                  !chosenIndustry ||
                  (!currentJob && currentlySearching)
                }
                fullWidth
              >
                {currentJobTypesOptions.map((opt) => (
                  <MenuItem
                    value={opt.optionProps.value}
                    disabled={opt.optionProps.disabled || false}
                  >
                    {opt.optionProps.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="introSelectContainer">
              <Select
                id="futureJob"
                value={futureJob}
                label="Where I'm going:"
                required
                variant={'outlined'}
                placeholder="Choose a position"
                onChange={(e) => setFutureJob(e.target.value)}
                testID="wt-grow-future-job-select"
                size={'small'}
                disabled={
                  isJobTypeSelectLoading || !chosenIndustry
                }
                fullWidth
              >
                {futureJobTypesOptions.map((opt) => (
                  <MenuItem
                    value={opt.optionProps.value}
                    disabled={opt.optionProps.disabled || false}
                  >
                    {opt.optionProps.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Box>
          <div className="introActionContainer">
            <div>
              <CheckBox
                value={currentlySearching}
                checked={currentlySearching}
                label="Currently searching for a career"
                className="introCheckbox"
                onChange={() => {
                  setCurrentJob('');
                  setCurrentlySearching(!currentlySearching);
                }}
                testID="currently-searching"
              />
            </div>
            <Button
              disabled={isButtonDisabled}
              endIcon={loading && <Spinner size={12} />}
              sx={{ marginTop: '40px', width: 224 }}
              variant="filled-primary"
              onClick={onSubmit}
              testID="wt-grow-submit-button"
            >
              See your path
            </Button>
            {isSetupFailed && (
              <p className="introError">
                Failed to create your career development, please
                try again. If this problem persist, please
                contact support
              </p>
            )}
          </div>
        </div>
      </div>
    </StyledRoot>
  );
};

GrowInitialSetup.propTypes = {
  isSetupFailed: PropTypes.bool,
  loading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
};

GrowInitialSetup.defaultProps = {
  isSetupFailed: false,
  loading: false,
};

export default GrowInitialSetup;
