import React from 'react';

const NotificationsContext = React.createContext({
  closeAll: () => {},
  closeSingle: () => {},
  isOpen: false,
  show: () => {},
  onClose: () => {},
  onExited: () => {},
  snackInfo: undefined,
  snackPack: [],
  setSnackPack: () => {},
  setIsOpen: () => {},
  setSnackInfo: () => {}
});

export default NotificationsContext;
