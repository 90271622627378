export const upcomingEventsEntryStyles = ({ theme }) => ({
  '& .mainContent': {
    borderRadius: 8,
    padding: '0 24 24 24',
    minHeight: '60vh',
    maxWidth: 800,
  },
  '& .employeeCardWrapper': {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 1fr)`,
    gridTemplateRow: `repeat(4, 1fr)`,
    gridColumnGap: 16,
    gridRowGap: 16,
  },
  '& .parentWrapper': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 360,
    padding: 12,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 8,
  },
  '& .locationTitle': {
    marginBottom: 16,
  },
  '& .subTitle': {
    marginBottom: 32,
  },
  '& .moreIconWrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '& .memberImage': {
    height: 48,
    width: 48,
    borderRadius: 248,
    marginBottom: 12,
  },
  '& .eventName': {
    fontSize: 16,
    fontWeight: 700,
  },
  '& .eventTitle': {
    fontSize: 14,
  },
  '& .paginationBar': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  '& .cardStart': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .cardEnd': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  '& .textWrapper': {
    marginLeft: 8,
  },
  '& .messageIcon': {
    color: theme.palette.secondary.main,
  },
  '& .messageStatus': {
    color: theme.palette.text.secondary,
  },
  '& .returnButton': {
    marginBottom: 24,
  },
});
