const basePadding = 8;
export const maxWindowWidth = 1024;

export const maxWidthMixin = {
  width: '100%',
  maxWidth: maxWindowWidth,
};

export const cardBaseMixin = ({ theme }) => ({
  padding: basePadding * 1.75,
  borderRadius: basePadding,
  border: `1px solid ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.background.paper,
});

export const flexRow = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const gridBuilderMixin = (
  theme,
  gridColumns: number,
) => ({
  display: 'grid',
  gridTemplateColumns: Array(gridColumns)
    .fill(gridColumns)
    .map(
      () => `calc(${100 / gridColumns}% - ${basePadding * 2}px)`,
    )
    .join(' '),
  columnGap: basePadding * 2,
  rowGap: basePadding * 2,
});

export const defaultImageBackgroundMixin = ({ theme }) => ({
  height: 50,
  width: 50,
  borderRadius: 50,
  display: 'block',
  backgroundColor: theme.palette.primary.main,
});

export const addStatusStylesMixin = (theme, isText = false) => ({
  '& .needsAttention': {
    backgroundColor: isText
      ? 'transparent'
      : theme.palette.anomaly.main,
    borderRadius: 50,
    color: theme.palette.anomaly.main,
  },
  '& .decentWork': {
    backgroundColor: isText
      ? 'transparent'
      : theme.palette.quaternary.main,
    borderRadius: 50,
    color: theme.palette.quaternary.main,
  },
  '& .inGoodShape': {
    backgroundColor: isText
      ? 'transparent'
      : theme.palette.success.main,
    borderRadius: 50,
    color: theme.palette.success.main,
  },
});
